import { twMerge as cx } from 'tailwind-merge';
import Journey from './components/Journey';
import Links from './components/Links';
import LinkCreate from './components/Links/LinkCreate';

import { BBBContainer } from '@/components';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';

export default function LinksPage() {
  const { data, status } = useOnboarding('links');

  if (status === 'success' && data?.lastStep) {
    if (data.lastStep === 5) return <_LinksPage isNewUsers />;
  }

  return <_LinksPage />;
}

function _LinksPage({ isNewUsers = false }: { isNewUsers?: boolean }) {
  return (
    <BBBContainer
      hasHeading
      pageTitle="Links"
      pageDescription="Add sections to your profile and control their visibility"
      rightComponent={
        isNewUsers ? (
          <div className="pointer-events-none">
            <LinkCreate />
          </div>
        ) : (
          <LinkCreate />
        )
      }
      className={cx(isNewUsers ? 'z-[150]' : '')}
      id="linklist-container"
    >
      <div
        className={cx(
          isNewUsers ? 'pointer-events-none' : 'pointer-events-auto'
        )}
      >
        <Links />
        <Journey />
      </div>
    </BBBContainer>
  );
}
