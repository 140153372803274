import React, { useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import { MergeStep2Type, MergeType } from './GeneralInfoCard';

import { BBBCheckbox, BBBModal } from '@/components';
import useResponsive from '@/hooks/common/useResponsive';
import useMergeCustomers from '@/hooks/customers/customer/useMergeCustomers';
import { formatDisplayName } from '@/utils/customers';

type Props = {
  merge: MergeStep2Type;
  setMerge: React.Dispatch<React.SetStateAction<MergeType | undefined>>;
};

function MergeStep2({ merge, setMerge }: Props) {
  const isTablet = useResponsive('lg');
  const isMobile = useResponsive('sm');

  const [selected, setSelected] = useState<{
    imageUrlCustomerId: string;
    fullNameCustomerId: string;
    phoneNumberCustomerId: string;
    emailCustomerId: string;
    companyCustomerId: string;
    cityCustomerId: string;
    instagramCustomerId: string;
  }>({
    imageUrlCustomerId: merge.target.id,
    fullNameCustomerId: merge.target.id,
    phoneNumberCustomerId: merge.target.id,
    emailCustomerId: merge.target.id,
    companyCustomerId: merge.target.id,
    cityCustomerId: merge.target.id,
    instagramCustomerId: merge.target.id,
  });

  const { mutate: mergeCustomers, isLoading: loadingMerge } =
    useMergeCustomers();

  return (
    <BBBModal
      title="Merge Customers"
      subtitle="Select the data fields that you want to include for the customer’s details. Former data will be deleted."
      show={merge.step === 2}
      footer
      cancelText="Back"
      submitText="Merge"
      size={!isTablet ? '3xl' : undefined}
      handleSave={() => {
        mergeCustomers(
          {
            customerIdSource: merge.source.id,
            customerIdTarget: merge.target.id,
            ...selected,
          },
          {
            onSuccess: () => {
              setMerge(undefined);
            },
          }
        );
      }}
      onCancel={() => setMerge({ step: 1, data: merge.target })}
      onHide={() => setMerge(undefined)}
      loadingSave={loadingMerge}
    >
      {!isMobile && (
        <div className="flex items-center mb-6">
          <div className="flex-1">Profile picture</div>
          <div className="flex-1 flex justify-center">
            <ProfilePicture
              {...merge.source}
              imageClassName="w-16 h-16"
              containerClassName="cursor-pointer"
              onClick={() =>
                setSelected((prev) => ({
                  ...prev,
                  imageUrlCustomerId: merge.source.id,
                }))
              }
              checked={merge.source.id === selected?.imageUrlCustomerId}
            />
          </div>
          <div className="flex-1 flex justify-center">
            <ProfilePicture
              {...merge.target}
              onClick={() =>
                setSelected((prev) => ({
                  ...prev,
                  imageUrlCustomerId: merge.target.id,
                }))
              }
              checked={merge.target.id === selected?.imageUrlCustomerId}
              imageClassName="w-16 h-16"
              containerClassName="cursor-pointer"
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-6 mb-6">
        <div className="col-span-2 md:col-span-1">Full name</div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                fullNameCustomerId: merge.source.id,
              }))
            }
            checked={merge.source.id === selected?.fullNameCustomerId}
          >
            {formatDisplayName(
              merge.source.phoneNumber,
              merge.source.firstName,
              merge.source.lastName
            )}
          </MergeItem>
        </div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                fullNameCustomerId: merge.target.id,
              }))
            }
            checked={merge.target.id === selected?.fullNameCustomerId}
          >
            {formatDisplayName(
              merge.target.phoneNumber,
              merge.target.firstName,
              merge.target.lastName
            )}
          </MergeItem>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-6 mb-6">
        <div className="col-span-2 md:col-span-1">Phone number</div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                phoneNumberCustomerId: merge.source.id,
              }))
            }
            checked={merge.source.id === selected?.phoneNumberCustomerId}
          >
            {merge.source.phoneNumber || '-'}
          </MergeItem>
        </div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                phoneNumberCustomerId: merge.target.id,
              }))
            }
            checked={merge.target.id === selected?.phoneNumberCustomerId}
          >
            {merge.target.phoneNumber || '-'}
          </MergeItem>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-6 mb-6">
        <div className="col-span-2 md:col-span-1">Instagram</div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                instagramCustomerId: merge.source.id,
              }))
            }
            checked={merge.source.id === selected?.instagramCustomerId}
          >
            {merge.source.instagramId || '-'}
          </MergeItem>
        </div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                instagramCustomerId: merge.target.id,
              }))
            }
            checked={merge.target.id === selected?.instagramCustomerId}
          >
            {merge.target.instagramId || '-'}
          </MergeItem>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-6 mb-6">
        <div className="col-span-2 md:col-span-1">Email</div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                emailCustomerId: merge.source.id,
              }))
            }
            checked={merge.source.id === selected?.emailCustomerId}
          >
            {merge.source.email || '-'}
          </MergeItem>
        </div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                emailCustomerId: merge.target.id,
              }))
            }
            checked={merge.target.id === selected?.emailCustomerId}
          >
            {merge.target.email || '-'}
          </MergeItem>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-6 mb-6">
        <div className="col-span-2 md:col-span-1">Company</div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                companyCustomerId: merge.source.id,
              }))
            }
            checked={merge.source.id === selected?.companyCustomerId}
          >
            {merge.source.addresses.length
              ? merge.source.addresses[0].company || '-'
              : '-'}
          </MergeItem>
        </div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                companyCustomerId: merge.target.id,
              }))
            }
            checked={merge.target.id === selected?.companyCustomerId}
          >
            {merge.target.addresses.length
              ? merge.target.addresses[0].company || '-'
              : '-'}
          </MergeItem>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-6 mb-6">
        <div className="col-span-2 md:col-span-1">City</div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                cityCustomerId: merge.source.id,
              }))
            }
            checked={merge.source.id === selected?.cityCustomerId}
          >
            {merge.source.addresses.length
              ? merge.source.addresses[0].city || '-'
              : '-'}
          </MergeItem>
        </div>
        <div>
          <MergeItem
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                cityCustomerId: merge.target.id,
              }))
            }
            checked={merge.target.id === selected?.cityCustomerId}
          >
            {merge.target.addresses.length
              ? merge.target.addresses[0].city || '-'
              : '-'}
          </MergeItem>
        </div>
      </div>
    </BBBModal>
  );
}

export default MergeStep2;

function MergeItem({
  children,
  checked,
  className,
  ...props
}: {
  children: React.ReactNode;
  checked?: boolean;
} & JSX.IntrinsicElements['div']) {
  return (
    <div
      className={cx(
        'cursor-pointer transition-[border] hover:border-neutral-60 border border-neutral-30 rounded-lg px-2.5 py-2.5 text-center',
        checked && 'border-neutral-60'
      )}
      {...props}
    >
      <div className="flex justify-between items-center">
        <div className="grow truncate">{children}</div>
        {checked && <BBBCheckbox checked />}
      </div>
    </div>
  );
}
