import { twMerge as cx } from 'tailwind-merge';

import { BBBSpinner } from '@/components';
import useBranding from '@/hooks/bitApp/design/useBranding';
import useBrandingQuery from '@/hooks/bitApp/design/useBranding';
import useCompanyNavigation from '@/hooks/bitApp/design/useCompanyNavigation';
import { useAppSelector } from '@/hooks/rtk/store';

type Props = {
  className?: string;
};

export default function BottomNavigation({ className }: Props) {
  const { isInitialLoading, data, error } = useCompanyNavigation();
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);

  const { data: brandingData } = useBranding();

  if (brandingData?.navigationMode !== 'bottom') return null;

  if (isInitialLoading) {
    return <BBBSpinner />;
  }

  if (error) {
    return null;
  }

  return (
    <div
      className={cx(' bg-white', className)}
      style={{
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.10)',
      }}
    >
      <div className="flex items-center px-2 py-3">
        {data?.active.map((item) => (
          <div
            key={`${item.navigationId}-${item.companyId}`}
            style={{
              flex: 1,
            }}
            className={cx(
              'flex flex-col items-center gap-2',
              activePreview === item.navigation?.name?.toLocaleLowerCase()
                ? 'opacity-100'
                : 'opacity-50'
            )}
          >
            <div>
              <img
                src={item.navigation.icon || undefined}
                alt="icon-bottom"
                style={{
                  width: '1.375em',
                  height: '1.375em',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="md:block hidden text-[7px] text-primary-main font-semibold">
              {item.navigation.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
