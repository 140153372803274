import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { PlusCircle, X } from 'react-feather';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import ModalShoppableAds from './ModalShoppableAds';
import ShoppableProductRenderer from './ShoppableProductRenderer';

import AlertIcon2 from '@/assets/icons/AlertIcon2';
import {
  BBBCard,
  BBBFooterAction,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components';
import { BBBRadio } from '@/components/BBBRadio';
import { BBBTooltip } from '@/components/BBBTooltip';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { isProd } from '@/config/env';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from '@/hooks/bitLink/links/useLinklistMutation';
import { useProductQuery } from '@/hooks/bitLink/shopify/useCollectionQuery';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import useShopifyConnectModal from '@/hooks/shopify/useShopifyConnectModal';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { setForm } from '@/stores/bitLink';
import { toast } from '@/utils/common/toast';

const schema = yup.object().shape({
  textPrimary: yup.string().label('Title').required('Title is required'),
  textSecondary: yup.string().label('Type').required(),
  items: yup.string().label('Items').required('Product is required'),
  active: yup.bool().required(),
  display: yup
    .mixed()
    .oneOf(['show-product-price', 'hide-product-price'])
    .required('Display is required'),
});

export type ShoppableType = 'collection' | 'product';

export type ShoppableForm = {
  active: boolean;
  textPrimary: string;
  textSecondary: ShoppableType;
  items: string | undefined;
  id: number | undefined;
  display: string;
};

function FormShoppabbleAds() {
  const [show, setShow] = useState(false);
  const [showTip, setShowTip] = useState(false);

  const form = useAppSelector((state) => state.bitLink.form);
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('sm');

  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITLINK');

  const { handleSubmit, formState, watch, reset, setValue, control } =
    useCustomForm<ShoppableForm>({
      resolver: yupResolver(schema),
      defaultValues: {
        textPrimary: '',
        items: '',
        active: true,
        display: 'show-product-price',
      },
    });

  const productItems = watch('items')?.split(',');

  useEffect(() => {
    reset({
      textPrimary: form?.textPrimary || '',
      textSecondary: form?.textSecondary as ShoppableType,
      active: !shopifyIntegration ? false : form?.active ?? true,
      items: form?.items || undefined,
      id: form?.id,
      display: form?.display || 'show-product-price',
    });
  }, [
    form?.active,
    form?.display,
    form?.id,
    form?.items,
    form?.textPrimary,
    form?.textSecondary,
    reset,
    setValue,
    shopifyIntegration,
  ]);

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();
  const { setShow: setShowConnect } = useShopifyConnectModal();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();

  const deleteLinklist = useLinklistDeleteMutation();

  const handleDeleteForm = () => {
    if (form?.id) {
      deleteLinklist(form?.id);
    } else {
      dispatch(setForm(null));
    }
  };

  const onSubmit = async (data: Partial<ShoppableForm>) => {
    const finalData = {
      ...data,
      type: 'shop' as const,
    };

    if (!form?.id) {
      addLinklist(finalData);
    } else {
      editLinklist({ id: form?.id, data: finalData });
    }
  };

  const handleSaveModals = (items: string, type: ShoppableType | undefined) => {
    setShow(false);
    setValue('items', items || '');
    setValue('textSecondary', type || 'product');
  };

  const handleChangeSwitch = (value: boolean | undefined) => {
    const changeFn = () => setValue('active', value || false);
    if (!shopifyIntegration) {
      setShowConnect({
        appType: 'BITLINK',
        onSuccess: changeFn,
      });
    } else {
      changeFn();
    }
  };

  const productsQuery = useProductQuery();

  const { data: productData } = productsQuery;

  const isHasHiddenArchiveDraftProduct = isProd
    ? productData?.pages
        ?.flatMap((item) => item.data)
        ?.some(
          (item) =>
            item.status === 'ARCHIVED' ||
            item.status === 'DRAFT' ||
            item.onlineStoreUrl
        )
    : productData?.pages
        ?.flatMap((item) => item.data)
        ?.some((item) => item.status === 'ARCHIVED' || item.status === 'DRAFT');

  const items = watch('items')?.split(',');

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (
      form?.textSecondary === 'collection' ||
      watch('textSecondary') === 'collection'
    ) {
      return toast.error(
        `Oops! Collections can't be rearranged yet. Try using individual products!`
      );
    }

    const items = Array.from(productItems || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setValue('items', items.join(','));
  };

  useEffect(() => {
    if (isHasHiddenArchiveDraftProduct) {
      setShowTip(true);
    }
  }, [isHasHiddenArchiveDraftProduct]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {show && (
        <ModalShoppableAds
          onHide={() => {
            setShow(false);
          }}
          onSave={handleSaveModals}
          form={watch()}
          show={show}
        />
      )}
      <BBBCard
        className={cx(
          'mb-cardBottom',
          isMobile ? 'p-0 border-none' : undefined
        )}
        title="Shop"
        rightButton={
          !isMobile && (
            <Controller
              control={control}
              name="active"
              render={({ field }) => (
                <BBBPrimarySwitch
                  checked={field.value}
                  onChange={(value) => handleChangeSwitch(value)}
                  containerClassName="flex justify-end mb-3"
                />
              )}
            />
          )
        }
      >
        <BBBTextInput
          placeholder="Enter your title"
          isHookForm
          label="Title"
          control={control}
          controlName="textPrimary"
          error={formState.errors.textPrimary?.message as string}
        />
        <div className="mb-4.5 mt-5 text-primary-main">
          <div className="mb-1.5 font-medium text-ls">
            Product or Collection
          </div>
          <div className="text-sm mb-2.5">
            You can add product or collection from your Shopify store (maximum 6
            products)
          </div>

          <div
            className={cx(
              'max-w-full overflow-auto flex gap-4',
              isMobile ? 'flex-col' : 'flex-row'
            )}
          >
            <AddProduct onChangeShow={setShow} />
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="product-list" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    id="product-list"
                    className="w-full flex gap-4"
                  >
                    {items?.map((p, index) => (
                      <ShoppableProductRenderer
                        key={p}
                        type={watch('textSecondary') as ShoppableType}
                        items={p}
                        index={index}
                        onRemove={(items) => {
                          setValue(
                            'items',
                            watch('items')
                              ?.split(',')
                              .filter((item) => item !== items)
                              .join(',') || undefined
                          );
                        }}
                      />
                    ))}
                    {isMobile && watch('items') && (
                      <RemoveAllProduct
                        onClick={() => {
                          setValue('items', undefined);
                        }}
                      />
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {!isMobile && watch('items') && (
            <RemoveAllProduct
              onClick={() => {
                setValue('items', undefined);
              }}
            />
          )}

          {formState.errors.items?.message && (
            <div className="text-danger-main">
              {formState.errors.items?.message}
            </div>
          )}
          {showTip && (
            <div className="relative rounded-2xl p-3 mb-4 bg-info-surface flex items-center text-sm justify-center gap-4 mt-5">
              <AlertIcon2 size={20} type="info" />
              <div className="text-primary-main flex">
                Some products require more information before they can be
                published.
                <a
                  href={`https://admin.shopify.com/store/${shopifyIntegration?.domain.replace(
                    '.myshopify.com',
                    ''
                  )}/products`}
                  target="_blank"
                  className="font-medium underline ml-1"
                  rel="noreferrer"
                >
                  <BBBTooltip
                    show
                    content="Some products are still hidden, in archive, or in draft. It won’t show in your bitLink product list until more information is added."
                  >
                    Go to Shopify dashboard
                  </BBBTooltip>
                </a>
                <X
                  size={12}
                  className="cursor-pointer flex-none self-center mx-2"
                  onClick={() => setShowTip(false)}
                />
              </div>
            </div>
          )}
        </div>
        <Controller
          control={control}
          name="display"
          render={({ field }) => (
            <div className="flex flex-col gap-2">
              <p className="text-neutral-70 font-medium text-ls">Display</p>
              <BBBRadio
                options={[
                  {
                    label: 'Show product price',
                    description: 'Display products with the price',
                    value: 'show-product-price',
                  },
                  {
                    label: 'Hide product price',
                    description: 'Hide display price for all products',
                    value: 'hide-product-price',
                  },
                ]}
                value={field.value}
                onChange={field.onChange}
              />
            </div>
          )}
        />
        <BBBFooterAction
          containerClassName="justify-end"
          withoutDelete={form?.id ? undefined : true}
          onCancel={() => {
            dispatch(setForm(null));
          }}
          loadingSave={isLoading || isLoadingEdit}
          onSave={handleSubmit(onSubmit)}
          onDelete={handleDeleteForm}
        />
      </BBBCard>
    </form>
  );
}

function RemoveAllProduct({ onClick }: { onClick: () => void }) {
  return (
    <span
      className="underline text-sm text-primary-main cursor-pointer mt-2"
      onClick={onClick}
    >
      Remove all product
    </span>
  );
}

function AddProduct({
  onChangeShow,
}: {
  onChangeShow: (value: boolean) => void;
}) {
  const isMobile = useResponsive('sm');
  return (
    <ShopifyAwareWrapper
      appType="BITLINK"
      fallbackToChildren
      className={isMobile ? 'order-1' : undefined}
    >
      {({ connectMiddleware }) =>
        isMobile ? (
          <div
            className="bg-neutral-10 border-2 border-dashed border-primary-main/50 py-3 text-center rounded-lg text-neutral-50 opacity-50 text-[10px]"
            onClick={() => connectMiddleware(() => onChangeShow(true))}
          >
            Add product
          </div>
        ) : (
          <div
            className="flex-none w-36 h-36 flex flex-col gap-2.5 items-center justify-center bg-neutral-10 cursor-pointer rounded-lg text-neutral-50 text-[10px] border border-dashed border-primary-main/50"
            onClick={() => connectMiddleware(() => onChangeShow(true))}
          >
            <PlusCircle size={18} color="#9E9E9E" />
            <div>Add Product</div>
          </div>
        )
      }
    </ShopifyAwareWrapper>
  );
}

export default FormShoppabbleAds;
