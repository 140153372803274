import { useCallback, useState } from 'react';
import useColumn from './hooks/useColumn';

import BBBLimitAlert from '@/components/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/BBBLimitAlert/UpgradeText';
import BBBTableV2 from '@/components/BBBTableV2/BBBTableV2';
import useBulkDeleteModels from '@/hooks/bitAi/models/useBulkDeleteModels';
import useSortingModels from '@/hooks/bitAi/models/useSortingModels';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';

const mobileColumns = ['name'];

export default function Models() {
  const isMobile = useResponsive('sm');
  const toggleConfirmation = useConfirmationModal();
  const [search, setSearch] = useState('');

  const { data: dataSortingModels, isInitialLoading: loadingSortingModels } =
    useSortingModels({ skipPrebuilt: true });

  const { mutate: deleteModel } = useBulkDeleteModels();

  const handleDeleteBulkModel = useCallback(
    (modelIds: string[]) =>
      toggleConfirmation({
        title: 'Delete model?',
        description: `Once deleted you're not able to recover it`,
        onAccept: (hide) => {
          deleteModel({ modelId: modelIds });
          hide();
        },
        deleteModal: true,
        submitText: 'Yes',
        cancelText: 'Cancel',
      }),
    [deleteModel, toggleConfirmation]
  );

  const _columns = useColumn();
  const columns = isMobile
    ? _columns.filter(
        (column) =>
          mobileColumns.includes(column?.accesor || '') ||
          column.isAdditionalColumn
      )
    : _columns;

  return (
    <div className="w-full flex flex-col">
      <PricingAlert />
      <BBBTableV2
        data={dataSortingModels}
        headers={columns}
        loadingBody={loadingSortingModels}
        isFilterable
        isSelectable
        onActionChange={(event, row) => {
          if (event === 'delete') {
            handleDeleteBulkModel(row);
          }
        }}
        isSearchable
        searchPlaceholder="Search model"
        searchValue={search}
        onChangeSearch={setSearch}
        dataId="id"
        linkDestination={(row) => `/bitai/model/${row.id}`}
      />
    </div>
  );
}

function PricingAlert() {
  const { data: pricingFeatureData, limit } = useWithCRMPricing(
    'BITAI',
    'advance_custom_model'
  );

  const { data: pricingData } = usePricingByApp('BITAI');

  if (!pricingData || !pricingFeatureData || pricingData.pricingName === 'pro1')
    return null;

  return (
    <BBBLimitAlert
      usage={pricingFeatureData.usage}
      appType="BITAI"
      module={pricingFeatureData.pricingFeature.label}
      limit={limit}
      currentPlan={{
        label: pricingData.pricingModule.label,
        name: pricingData.pricingName,
      }}
      className="mb-4 rounded-lg"
      customReachingTresholdLabel={
        <>
          {limit - pricingFeatureData.usage} active custom model left{' '}
          <UpgradeText asModal appType="BITAI" /> for more.
        </>
      }
      customExceedTresholdLabel={
        <>
          On a free plan, you can only have {limit} custom model.{' '}
          <UpgradeText asModal appType="BITAI" /> for more.
        </>
      }
      customTip="Models are managed collections that are actively used in the sorting process."
      customTresholdFn={() => 0}
    />
  );
}
