import { createId } from '@paralleldrive/cuid2';
import { useConnectedChannels } from './channels';

import InstagramIcon2 from '@/assets/icons/InstagramIcon2';
import WhatsAppCloudApiIcon from '@/assets/icons/WhatsAppCloudApiIcon';
import WhatsAppIcon4 from '@/assets/icons/WhatsAppIcon4';

export const availableSources = (type: 'livechat' | 'crm' = 'crm') =>
  (
    [
      {
        label: 'WhatsApp Business App',
        id: createId(),
        name: 'whatsapp-business',
        icon: <WhatsAppIcon4 />,
        visible: true,
      },
      {
        label: 'WhatsApp Cloud API',
        id: createId(),
        name: 'whatsapp-cloud',
        icon: <WhatsAppCloudApiIcon />,
        visible: type === 'crm',
      },
      {
        label: 'Instagram',
        id: createId(),
        name: 'instagram',
        icon: <InstagramIcon2 />,
        visible: false, // will turned on when feature are ready
      },
    ] as const
  ).filter((data) => data.visible);

export type AvailableSources = ReturnType<
  typeof availableSources
>[number]['name'];

const mapSourceNaming: Record<
  AvailableSources,
  'whatsapp' | 'whatsapp_meta' | 'instagram' | 'jatis'
> = {
  instagram: 'instagram',
  'whatsapp-business': 'whatsapp',
  'whatsapp-cloud': 'whatsapp_meta',
};

const useAvailableSources = (
  type: 'livechat' | 'crm' = 'crm',
  checkConnectedChannels?: boolean
) => {
  const data = availableSources(type);

  const { data: connectedChannels } = useConnectedChannels({
    enabled: checkConnectedChannels,
  });

  if (!checkConnectedChannels) return data;

  return data.filter(
    (_data) =>
      connectedChannels?.find(
        (connectedChannel) =>
          connectedChannel.integration.name === mapSourceNaming[_data.name]
      )?.status === 'CONNECTED'
  );
};

export default useAvailableSources;
