import { Controller, useForm } from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';
import Wrapper from '../components/Wrapper';

import { BBBCard, BBBCheckbox, BBBPrimarySwitch } from '@/components';
import {
  useLoginSetting,
  useUpsertLoginSetting,
} from '@/hooks/bitLogin/login/login-settings';
import useSettings from '@/hooks/bitLogin/useSettings';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import useUpsertSocialAppearance from '@/hooks/bitLogin/useUpsertSocialAppearance';

interface LoginSetting {
  type: string;
  active: boolean;
  mandatory?: boolean;
}

function SignUp() {
  const { status: statusEnabled, data } = useSettings();

  const isDisabled = statusEnabled !== 'success' || !data?.verified;

  return (
    <Wrapper
      hasHeading
      pageTitle="Sign Up"
      pageDescription="Capture more data of customer"
      module="sign-up"
    >
      <div className={cx(isDisabled && 'opacity-50 pointer-events-none')}>
        <_SignUp />
      </div>
    </Wrapper>
  );
}

function _SignUp() {
  const { control } = useForm();
  const { data: socialAppearance } = useSocialAppearance();
  const { data: loginSetting } = useLoginSetting();

  const { mutate: updateAppearance } = useUpsertSocialAppearance();
  const { mutate: updateLoginSetting } = useUpsertLoginSetting();

  const getSetting = (type: string): Partial<LoginSetting> =>
    loginSetting?.find((setting: LoginSetting) => setting.type === type) || {};

  const { active: phoneNumberStatus, mandatory: phoneNumberMandatory } =
    getSetting('phoneNumber');
  const { active: genderStatus, mandatory: genderMandatory } =
    getSetting('gender');
  const { active: dateOfBirthStatus, mandatory: dateOfBirthMandatory } =
    getSetting('dateOfBirth');
  const { active: addressStatus, mandatory: addressMandatory } =
    getSetting('address');

  const isDisabled = !socialAppearance?.captureAdditionalData;

  return (
    <>
      <BBBCard
        title="Capture more data"
        desc="Add divider between social and regular login for smoother experience."
        className="mb-cardBottom"
        rightButton={
          <BBBPrimarySwitch
            checked={!!socialAppearance?.captureAdditionalData}
            onChange={(val) => updateAppearance({ captureAdditionalData: val })}
          />
        }
      />
      <SettingCard
        title="Phone Number"
        desc="Phone number section will appear on the customer sign up page."
        status={phoneNumberStatus}
        mandatory={phoneNumberMandatory}
        type="phoneNumber"
        isDisabled={isDisabled}
        control={control}
        updateLoginSetting={updateLoginSetting}
      />
      <SettingCard
        title="Gender"
        desc="Gender section will appear on the customer sign up page."
        status={genderStatus}
        mandatory={genderMandatory}
        type="gender"
        isDisabled={isDisabled}
        control={control}
        updateLoginSetting={updateLoginSetting}
      />
      <SettingCard
        title="Date of Birth"
        desc="Date of Birth section will appear on the customer sign up page."
        status={dateOfBirthStatus}
        mandatory={dateOfBirthMandatory}
        type="dateOfBirth"
        isDisabled={isDisabled}
        control={control}
        updateLoginSetting={updateLoginSetting}
      />
      <SettingCard
        title="Address"
        desc="Address section will appear on the customer sign up page."
        status={addressStatus}
        mandatory={addressMandatory}
        type="address"
        isDisabled={isDisabled}
        control={control}
        updateLoginSetting={updateLoginSetting}
      />
    </>
  );
}

const SettingCard = ({
  title,
  desc,
  status,
  mandatory,
  type,
  isDisabled,
  control,
  updateLoginSetting,
}: {
  title: string;
  desc: string;
  status: boolean | undefined;
  mandatory: boolean | undefined;
  type: string;
  isDisabled: boolean;
  control: any;
  updateLoginSetting: (data: {
    type: string;
    active?: boolean;
    mandatory?: boolean;
  }) => void;
}) => {
  return (
    <BBBCard
      title={title}
      desc={desc}
      className={`mb-3 ${isDisabled ? 'opacity-40 pointer-events-none' : ''}`}
      rightButton={
        <BBBPrimarySwitch
          checked={!!status}
          onChange={(val) =>
            updateLoginSetting({ type, active: val, mandatory: true })
          }
        />
      }
    >
      {status && mandatory !== undefined && (
        <Controller
          control={control}
          name={`${type}Mandatory`}
          render={({ field }) => (
            <BBBCheckbox
              checked={!!mandatory}
              onValueChange={(checked) => {
                field.onChange(checked);
                updateLoginSetting({ type, mandatory: checked });
              }}
              label="Mandatory"
            />
          )}
        />
      )}
    </BBBCard>
  );
};

export default SignUp;
