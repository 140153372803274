import { useCallback, useEffect, useMemo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash-es/isEqual';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import MessageType, {
  defaultMessageType,
  messageTypeValidation,
  MessageTypeValue,
} from './MessageType';

import { BBBCard, BBBPrimarySwitch, BBBTextInput } from '@/components';
import { chatbotTypes } from '@/constants/bitChat/chatbot';
import useRule from '@/hooks/bitChat/chatbot/useRule';
import useUpsertChatbot from '@/hooks/bitChat/chatbot/useUpsertChatbot';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { formatChatbotPayload } from '@/utils/bitChat';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import { convertHtmlToEditorState } from '@/utils/common/rich';

const schema = yup.object().shape({
  reservedReplyKeywords: yup.string().required('Keywords is required'),
  reservedReplyId: yup.string(),
  reservedReplyActive: yup.boolean().required(),
  reservedReplyMessage: yup.object().when('active', {
    is: (value: boolean) => !!value,
    then: (rule) => rule.shape(messageTypeValidation()),
  }),
});

export type ReservedReplyForm = {
  reservedReplyKeywords: string;
  reservedReplyId?: string;
  reservedReplyActive: boolean;
  reservedReplyMessage: MessageTypeValue;
};

type IsChatbotLegacy = {
  /**
   * @description
   * condition for chatbot (legacy)
   */
  isLegacy?: boolean;
};

export const reservedReplyDefaultValues: ReservedReplyForm = {
  reservedReplyActive: false,
  reservedReplyMessage: defaultMessageType,
  reservedReplyKeywords: '',
};

export default function ReservedReply({
  chatbotType,
  isLegacy,
}: {
  chatbotType: 'transfer_to_cs' | 'unsubscribe';
} & IsChatbotLegacy) {
  const { handleSubmit, control, reset, watch } =
    useCustomForm<ReservedReplyForm>({
      resolver: yupResolver(schema),
      defaultValues: reservedReplyDefaultValues,
    });

  const { data: ruleData } = useRule(chatbotType);

  const dataFromApi = useMemo(
    () => ({
      reservedReplyKeywords:
        ruleData?.[0]?.keywords.join(',') ||
        (chatbotType === 'transfer_to_cs' ? 'CS,cs' : 'STOP,stop'),
      reservedReplyId: ruleData?.[0]?.id,
      reservedReplyActive: ruleData?.[0]?.reply.status ?? false,
      reservedReplyMessage: {
        userMessage: ruleData?.[0]?.reply.message
          ? convertHtmlToEditorState(ruleData[0].reply.message)
          : reservedReplyDefaultValues.reservedReplyMessage.userMessage,
        messageType: ruleData?.[0]?.reply?.type
          ? chatbotTypes.find((p) => p.value === ruleData[0].reply.type) || null
          : reservedReplyDefaultValues.reservedReplyMessage.messageType,
        files: ruleData?.[0]?.reply.imageUrl
          ? convertRemoteUrlToFileType(ruleData[0].reply.imageUrl)
          : reservedReplyDefaultValues.reservedReplyMessage.files,
      },
    }),
    [chatbotType, ruleData]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { mutate: upsertChatbot, isLoading: loadingUpsert } =
    useUpsertChatbot();

  const onSubmit = useCallback(
    (
      {
        reservedReplyActive: active,
        reservedReplyKeywords: keywords,
        reservedReplyMessage: message,
        reservedReplyId: id,
      }: ReservedReplyForm,
      hide: () => void
    ) =>
      upsertChatbot(
        formatChatbotPayload({
          active,
          id,
          message,
          module: chatbotType,
        }),
        {
          onSuccess: hide,
        }
      ),
    [chatbotType, upsertChatbot]
  );

  const _isEqual = isEqual(dataFromApi, watch());

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    toggle('save-reserved-reply', {
      show: !_isEqual,
      text: loadingUpsert ? 'Saving changes...' : 'Unsaved changes',
      variant: loadingUpsert ? 'loading' : 'actionable',
      isCancelable: true,
      onAccept: (hide) => {
        handleSubmit((payload) => onSubmit(payload, hide))();
      },
      onCancel: reset,
    });
  }, [_isEqual, handleSubmit, loadingUpsert, onSubmit, reset, toggle]);

  return (
    <_ReservedReply
      chatbotType={chatbotType}
      control={control}
      isLegacy={isLegacy}
    />
  );
}

export function _ReservedReply({
  chatbotType,
  control,
  isLegacy,
}: {
  chatbotType: 'transfer_to_cs' | 'unsubscribe';
  control: Control<ReservedReplyForm>;
} & IsChatbotLegacy) {
  const isMobile = useResponsive('sm');

  const formState = useFormState({ control });

  const reservedReplyActive = useWatch({
    control,
    name: 'reservedReplyActive',
  });

  return (
    <BBBCard
      title={
        chatbotType === 'transfer_to_cs'
          ? 'Customer service trigger'
          : 'Unsubscribe'
      }
      className={`${isMobile && 'mb-[150px] rounded-none'}`}
      desc={
        chatbotType === 'transfer_to_cs'
          ? 'It will sent customer’s message to waiting section on live chat'
          : 'Your customers able to unsubscribe the campaign. Once they unsubscribe they will not received campaign that you made with bitCRM'
      }
      rightButton={
        <Controller
          control={control}
          name="reservedReplyActive"
          render={({ field }) => (
            <BBBPrimarySwitch
              checked={field.value || false}
              onChange={field.onChange}
            />
          )}
        />
      }
    >
      <div
        className={cx(
          /**
           * @description
           * Based on this https://asmaraku.atlassian.net/browse/BBB2-1529
           * the chatbot cannot be edit but can turn-on
           */
          isLegacy
            ? 'opacity-50 pointer-events-none'
            : 'opacity-100 pointer-events-auto'
        )}
      >
        <BBBTextInput
          label="Keywords"
          placeholder={
            chatbotType === 'unsubscribe'
              ? 'Type keywords to trigger Unsubscribe message'
              : 'Type keywords to trigger CS'
          }
          isHookForm
          control={control}
          controlName="reservedReplyKeywords"
          disabled
          error={formState.errors.reservedReplyKeywords?.message}
        />
        <Controller
          control={control}
          name="reservedReplyMessage"
          render={({ field }) => (
            <MessageType
              value={field.value}
              onValueChange={field.onChange}
              errors={{
                userMessage:
                  formState.errors.reservedReplyMessage?.userMessage?.message,
                files: formState.errors.reservedReplyMessage?.files?.message,
                messageType:
                  formState.errors.reservedReplyMessage?.messageType?.message,
              }}
            />
          )}
        />
      </div>
    </BBBCard>
  );
}
