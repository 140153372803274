import { useEffect, useMemo, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { LoginSetting } from '@/api/services/bitLogin/login/setting';
import {
  BBBButton,
  BBBCard,
  BBBSelect,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextInput,
} from '@/components';
import { useLoginSetting2 } from '@/hooks/bitLogin/login/login-settings';
import {
  useDecryptedData,
  useProxyCallback,
} from '@/hooks/bitLogin/login/proxy';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import { formatPhonePayload, phoneValidator } from '@/utils/common/phone';

type Gender = 'male' | 'female' | 'preferNotToSay';

type FormSchema = {
  phone: BBBTelInputValue | null;
  gender?: {
    label: string;
    value: Gender;
  } | null;
  dob: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  province?: string;
  zip?: string;
};

const genderOptions: {
  label: string;
  value: Gender;
}[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Others',
    value: 'preferNotToSay',
  },
];

const baseSchema = {
  gender: yup.mixed().label('Gender'),
  dob: yup.string().label('Date of birth'),
  address1: yup.string().label('Address1'),
  address2: yup.string().label('Address2'),
  city: yup.string().label('City'),
  country: yup.string().label('Country'),
  province: yup.string().label('Province'),
  zip: yup.string().label('Zip'),
};

export default function SignupProxy() {
  const query = useQuerySearchParams();

  const code = query.get('code');
  const domain = query.get('domain');

  if (!code || !domain) throw new Error('Invalid code');

  return <_SignupProxy domain={domain} code={code} />;
}

function _SignupProxy({ domain, code }: { domain: string; code: string }) {
  const { data: loginSetting } = useLoginSetting2(domain);

  const [step, setStep] = useState(0);

  const { data: decryptedData } = useDecryptedData(code);

  const { mutate: loginAction, isLoading: loadingProxyCallback } =
    useProxyCallback();

  const defaultPhone = useDefaultCountryCode();

  const onSubmit = (data: FormSchema) => {
    loginAction({
      ...decryptedData!,
      shop: domain,
      phoneNumber: formatPhonePayload(data.phone),
      gender: data.gender?.value,
      dateOfBirth: data.dob,
      city: data.city,
      region: data.country,
    });
  };

  const settingsMap = loginSetting?.reduce((acc, setting) => {
    acc[setting.type] = setting;

    return acc;
  }, {} as Record<string, LoginSetting>);

  const defaultValues = useMemo(() => {
    return {
      phone: defaultPhone,
      dob: '',
      gender: null,
      address1: '',
      address2: '',
      city: '',
      country: '',
      province: '',
      zip: '',
    };
  }, [defaultPhone]);

  const schema = yup.object().shape({
    phone: phoneValidator(!settingsMap?.phoneNumber.mandatory),
    gender: settingsMap?.gender.mandatory
      ? baseSchema.gender.required()
      : baseSchema.gender,
    dob: settingsMap?.dateOfBirth.mandatory
      ? baseSchema.dob.required()
      : baseSchema.dob,
    address1: baseSchema.address1,
    address2: baseSchema.address2,
    city: baseSchema.city,
    country: baseSchema.country,
    province: baseSchema.province,
    zip: baseSchema.zip,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useCustomForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setStep(0);
    }
  }, [errors]);

  return (
    <BBBCard className=" my-auto w-full max-w-md flex flex-col justify-center">
      {step === 0 && (
        <>
          {settingsMap?.phoneNumber.active && (
            <BBBTelInput
              isHookForm
              label="Phone number"
              placeholder="123456789"
              control={control}
              controlName="phone"
              error={errors.phone?.message}
              containerClassname="mb-5"
            />
          )}
          {settingsMap?.gender.active && (
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <BBBSelect
                  value={field.value}
                  options={genderOptions}
                  placeholder="What is your gender?"
                  optionLabel="label"
                  label="Gender"
                  optionValue="value"
                  onValueChange={field.onChange}
                  isSearchable
                  error={errors.gender?.message}
                  containerClassName="mb-5"
                />
              )}
            />
          )}
          {settingsMap?.dateOfBirth.active && (
            <BBBTextInput
              label="Date of Birth"
              labelClassname="text-primary-main"
              placeholder="When were you born?"
              type="date"
              isHookForm
              control={control}
              controlName="dob"
              error={errors.dob?.message}
              containerClassname="mb-5"
            />
          )}
        </>
      )}

      {step === 1 && (
        <>
          <div
            className="flex items-center gap-2 mb-5 cursor-pointer"
            onClick={() => setStep(0)}
          >
            <ArrowLeft />
            Back
          </div>
          <BBBTextInput
            isHookForm
            control={control}
            controlName="address1"
            error={errors.address1?.message}
            placeholder="Address 1"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="address2"
            error={errors.address2?.message}
            placeholder="Address 2"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="city"
            error={errors.city?.message}
            placeholder="City"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="country"
            error={errors.country?.message}
            placeholder="Country"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="province"
            error={errors.province?.message}
            placeholder="Province"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="zip"
            error={errors.zip?.message}
            placeholder="Postal / Zip Code"
            containerClassname="mb-5"
          />
        </>
      )}

      {step === 0 && <BBBButton onClick={() => setStep(1)}>Next</BBBButton>}
      {step === 1 && (
        <BBBButton
          width="full"
          onClick={handleSubmit(onSubmit)}
          loadingState={loadingProxyCallback}
        >
          Submit
        </BBBButton>
      )}
    </BBBCard>
  );
}
