import { createId } from '@paralleldrive/cuid2';
import {
  ButtonCtaType,
  MessageTemplateButtonType,
} from 'types/bitCRM/template';

export const statusOptions = [
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
];

export const PREFILLED_ONBOARDING = {
  id: createId(),
  campaignName: 'Payment reminders',
  body: `Hi, {{order.customer.name}}. We wanted to remind you that your payment for your recent purchase is due soon. Please make sure to complete the payment by the specified due date to avoid any delays in processing your order. If you have any questions or need assistance, feel free to reach out to our customer support team.`,
};

export const buttonTypeOptions: {
  label: string;
  value: MessageTemplateButtonType;
}[] = [
  {
    label: 'Reply button',
    value: 'reply_button',
  },
  {
    label: 'WhatsApp Form',
    value: 'whatsapp_form',
  },
  {
    label: 'Call to action',
    value: 'call_to_action',
  },
];

export const callToActionsOptions: {
  label: string;
  value: ButtonCtaType;
}[] = [
  {
    label: 'Web URL',
    value: 'web_url',
  },
  {
    label: 'Specific product',
    value: 'product',
  },
  {
    label: 'Specific collections',
    value: 'collections',
  },
];
