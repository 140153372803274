import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import { BBBPrimarySwitch } from '@/components';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { bitloginChannels } from '@/constants/bitLogin/appearance';
import useSocialConfig from '@/hooks/bitLogin/useSocialConfig';
import useUpsertSocialConfig from '@/hooks/bitLogin/useUpsertSocialConfig';
import useResponsive from '@/hooks/common/useResponsive';
import { SocialConfig } from '@/types/bitLogin/v2';

type Props = {
  livePreview?: boolean;
  withSwitchLabel?: boolean;
  type: 'web' | 'mobile';
  socialConfig?: SocialConfig[];
  isLoadingSocialConfig?: boolean;
  containerClassName?: string;
};

export default function SocialLoginButtons({
  withSwitchLabel,
  type,
  livePreview,
  containerClassName,
}: Props) {
  const isMobile = useResponsive('sm');

  const { data: socialConfig, isInitialLoading: isLoadingSocialConfig } =
    useSocialConfig();

  const { mutate: updateOauth } = useUpsertSocialConfig();

  return (
    <div className={cx('flex flex-col gap-6', containerClassName)}>
      {(livePreview
        ? bitloginChannels.filter((integration) => {
            const socialProviderConfig = socialConfig?.find(
              (config) => config.provider === integration.name
            );
            return socialProviderConfig?.enabledMobile;
          })
        : bitloginChannels
      ).map((integration) => {
        const socialProviderConfig = socialConfig?.find(
          (config) => config.provider === integration.name
        );

        return (
          <div
            className={cx('flex justify-between gap-4 items-center')}
            key={integration.id}
          >
            <div className="flex flex-col gap-4">
              <div
                className={cx(
                  `flex items-center select-none justify-center w-[259px] gap-2 py-3 rounded-xl border`,
                  integration.name === 'GOOGLE' &&
                    'bg-transparent border border-[#000000]',
                  integration.name === 'FACEBOOK' &&
                    'bg-[#1877F2] text-white border-transparent',
                  integration.name === 'APPLE' &&
                    'bg-[#000000] text-white border-transparent',
                  integration.name === 'WHATSAPP' &&
                    'bg-[#25D366] text-white border-transparent',
                  integration.name === 'MICROSOFT' &&
                    'bg-[#2F2F2F] text-white border-transparent',
                  integration.name === 'TWITTER' &&
                    'bg-[#000000] text-white border-transparent',
                  integration.name === 'AMAZON' &&
                    'bg-[#FF9900] border-transparent',
                  integration.name === 'MAGIC_LINK' &&
                    'bg-transparent border border-[#000000]',
                  integration.name === 'QR_CODE' &&
                    'bg-transparent border border-[#000000]'
                )}
              >
                <div>{integration.logo}</div>
                <div>{integration.label}</div>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              {!isMobile && withSwitchLabel && integration.switchLabel}
              {!livePreview &&
                (isLoadingSocialConfig ? (
                  <Skeleton width={50} height={30} />
                ) : (
                  <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
                    {({ connectMiddleware }) => (
                      <BBBPrimarySwitch
                        checked={
                          type === 'web'
                            ? socialProviderConfig?.enabledWeb
                            : socialProviderConfig?.enabledMobile
                        }
                        onChange={(val) =>
                          connectMiddleware(() =>
                            updateOauth({
                              [type === 'web' ? 'enabledWeb' : 'enabledMobile']:
                                val,
                              provider: integration.name as Exclude<
                                typeof integration.name,
                                'MAGIC_LINK' | 'QR_CODE'
                              >,
                            })
                          )
                        }
                        disabled={integration.disabled}
                      />
                    )}
                  </ShopifyAwareWrapper>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
