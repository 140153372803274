import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import getProduct from 'graphql/query/getProduct';
import useGraphQLRequest from '../useGraphQLRequest';

import ShopifyProducts from '@/types/shopify/ShopifyProduct';
import { generateShopifyId } from '@/utils/bitApp';

const useProduct = (
  id: string | null | undefined,
  options?: Omit<
    UseQueryOptions<ShopifyProducts, unknown, ShopifyProducts, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const { request, enabled } = useGraphQLRequest();

  return useQuery<ShopifyProducts>(
    ['bitapp-shopify-product', id],
    async () => {
      const data = await request({
        query: getProduct,
        variables: {
          id: generateShopifyId('Product', id),
        },
      });
      return data?.product;
    },
    {
      enabled: !!enabled && !!id,
      ...options,
    }
  );
};

export default useProduct;
