function ShopifyLogo(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      fill="none"
      viewBox="0 0 19 22"
      {...props}
    >
      <g clipPath="url(#clip0_3074_1145)">
        <path
          fill="#fff"
          d="M16.64 4.32L19 20.482l-6.677 1.463-12.32-2.338S1.584 7.218 1.643 6.78c.08-.576.1-.596.704-.788l1.95-.612C4.713 3.587 6.044.059 8.83.059c.364 0 .785.198 1.125.653l.101-.003c1.196 0 1.876 1.032 2.261 2.155l.646-.202c.096-.028.343-.067.47.061l1.28 1.288s1.66.125 1.738.132a.21.21 0 01.189.177zm-5.003-1.242c-.264-.818-.674-1.528-1.29-1.607.153.446.249 1.008.249 1.706l-.001.227 1.042-.326zM9.582 1.61c-.68.295-1.456 1.078-1.871 2.7.738-.233 1.475-.464 2.153-.676V3.51c0-.842-.11-1.466-.282-1.901zM8.754.816c-2 0-3.125 2.662-3.598 4.295l1.702-.534c.404-2.141 1.36-3.213 2.254-3.639a.623.623 0 00-.358-.122zm1.303 7.064s-.556-.327-1.682-.327c-2.923 0-4.371 1.978-4.371 4.022 0 2.428 2.391 2.494 2.391 3.972 0 .357-.25.846-.862.846-.938 0-2.05-.967-2.05-.967l-.566 1.896s1.081 1.334 3.196 1.334c1.763 0 3.07-1.345 3.07-3.433 0-2.654-2.915-3.088-2.915-4.22 0-.209.065-1.033 1.361-1.033.884 0 1.605.39 1.605.39l.823-2.48z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3074_1145">
          <path fill="#fff" d="M0 0H19V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShopifyLogo;
