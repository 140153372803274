import { useEffect, useMemo, useState } from 'react';
import { Edit3, PlusCircle, Trash2 } from 'react-feather';
import { useForm } from 'react-hook-form';
import { createId } from '@paralleldrive/cuid2';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import AskRating, {
  askRatingDefaultValues,
  AskRatingForm,
  askRatingTypes,
  channelFormTypes,
  mapChannelFormToMessageType,
} from './AskRating';
import {
  _ReservedReply,
  reservedReplyDefaultValues,
  ReservedReplyForm,
} from './ReservedReply';

import { SettingsFallback } from '@/api/services/whatsApp/settings';
import {
  BBBCard,
  BBBModal,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components';
import { timeUnitOptionsObj } from '@/constants/bitChat';
import { chatbotTypes } from '@/constants/bitChat/chatbot';
import useChatbotBulkAction from '@/hooks/bitChat/chatbot/useChatbotBulkAction';
import useRule from '@/hooks/bitChat/chatbot/useRule';
import useUpsertChatbot from '@/hooks/bitChat/chatbot/useUpsertChatbot';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { AutoReply, ChatbotType } from '@/types/bitChat/chatbot';
import { DeepPartial } from '@/types/utils/deepPartial';
import { formatChatbotPayload } from '@/utils/bitChat';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from '@/utils/common/rich';

export type ChatbotSettingsForm = AskRatingForm & ReservedReplyForm;

const chatbotSettingsDefaultValue = {
  ...askRatingDefaultValues,
  ...reservedReplyDefaultValues,
};

export default function ChatbotSettings() {
  const { data: ratingData } = useRule('ask_for_rating');
  const { data: unsubscribeData } = useRule('unsubscribe');

  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const chatbotBulkAction = useChatbotBulkAction();

  const { mutate: upsertChatbot, loading: loadingUpdateRating } =
    chatbotBulkAction({
      action: 'update',
    });

  const { mutate: upsertUnsubscribe, isLoading: loadingUpsertUnsubcribe } =
    useUpsertChatbot();

  const { control, reset, watch, handleSubmit } = useForm<ChatbotSettingsForm>({
    defaultValues: chatbotSettingsDefaultValue,
  });

  const dataFromApi = useMemo<Partial<ChatbotSettingsForm>>(() => {
    const chatbotRule = ratingData?.find(
      (rule) => rule.chatbotType === 'ASK_FOR_RATING_BOT'
    );

    const askRatingMessageData = askRatingTypes
      .map((askRating) =>
        channelFormTypes.map((channelForm) => {
          const rule = ratingData?.find(
            (rule) =>
              rule.chatbotType ===
                (askRating === 'cs'
                  ? 'ASK_FOR_RATING'
                  : 'ASK_FOR_RATING_BOT') &&
              rule.type === mapChannelFormToMessageType[channelForm]
          );

          return [
            [
              `${askRating}Message${channelForm}`,
              {
                userMessage: rule?.reply.message
                  ? convertHtmlToEditorState(rule.reply.message)
                  : chatbotSettingsDefaultValue[
                      `${askRating}Message${channelForm}`
                    ].userMessage,
              },
            ] as const,
            [
              `${askRating}${channelForm}Active`,
              rule?.reply.status || false,
            ] as const,
            [`${askRating}${channelForm}Id`, rule?.id] as const,
          ];
        })
      )
      .flat()
      .flat();

    return {
      ...Object.fromEntries(askRatingMessageData),
      autoResolveTime: chatbotRule?.reply.autoResolveTime?.toString() || '',
      autoResolveUnit: chatbotRule?.reply.autoResolveType
        ? timeUnitOptionsObj[chatbotRule.reply.autoResolveType]
        : null,
      reservedReplyKeywords:
        unsubscribeData?.[0]?.keywords.join(',') || 'STOP,stop',
      reservedReplyId: unsubscribeData?.[0]?.id,
      reservedReplyActive: unsubscribeData?.[0]?.reply.status ?? false,
      reservedReplyMessage: {
        userMessage: unsubscribeData?.[0]?.reply.message
          ? convertHtmlToEditorState(unsubscribeData[0].reply.message)
          : reservedReplyDefaultValues.reservedReplyMessage.userMessage,
        messageType: unsubscribeData?.[0]?.reply?.type
          ? chatbotTypes.find(
              (p) => p.value === unsubscribeData[0].reply.type
            ) || null
          : reservedReplyDefaultValues.reservedReplyMessage.messageType,
        files: unsubscribeData?.[0]?.reply.imageUrl
          ? convertRemoteUrlToFileType(unsubscribeData[0].reply.imageUrl)
          : reservedReplyDefaultValues.reservedReplyMessage.files,
      },
    };
  }, [ratingData, unsubscribeData]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const onSubmit = ({
      autoResolveTime,
      autoResolveUnit,
      reservedReplyActive,
      reservedReplyKeywords: keywords,
      reservedReplyMessage,
      reservedReplyId,
      ...props
    }: ChatbotSettingsForm) => {
      const payload: DeepPartial<AutoReply>[] = askRatingTypes
        .map((askRatingType) =>
          channelFormTypes.map((channelFormType) => {
            const chatbotType: ChatbotType =
              askRatingType === 'chatbot'
                ? 'ASK_FOR_RATING_BOT'
                : 'ASK_FOR_RATING';

            return {
              id: props[`${askRatingType}${channelFormType}Id`],
              type: mapChannelFormToMessageType[channelFormType],
              groupName:
                askRatingType === 'chatbot'
                  ? 'ask_for_rating_bot'
                  : 'ask_for_rating',
              chatbotType,
              reply: {
                type: 'TEXT',
                status: props[`${askRatingType}${channelFormType}Active`],
                message:
                  convertEditorStateToHtml(
                    props[`${askRatingType}Message${channelFormType}`]
                      .userMessage
                  ) ?? '',
                ...(askRatingType === 'chatbot' && {
                  autoResolveTime: Number(autoResolveTime),
                  autoResolveType: autoResolveUnit?.value,
                }),
              },
            };
          })
        )
        .flat();

      upsertChatbot(payload);
      upsertUnsubscribe(
        formatChatbotPayload({
          active: reservedReplyActive,
          id: reservedReplyId,
          message: reservedReplyMessage,
          module: 'unsubscribe',
        })
      );
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('save-chatbot', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant:
        loadingUpdateSettings || loadingUpdateRating || loadingUpsertUnsubcribe
          ? 'loading'
          : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSettings,
    loadingUpdateRating,
    reset,
    toggle,
    updateSettings,
    upsertChatbot,
    upsertUnsubscribe,
    watch(),
    loadingUpsertUnsubcribe,
  ]);

  return (
    <>
      {/**@ts-ignore */}
      <AskRating type="cs" control={control} />
      {/**@ts-ignore */}
      <AskRating type="chatbot" control={control} />
      <Fallback />
      {/**@ts-ignore */}
      <_ReservedReply chatbotType="unsubscribe" control={control} />
    </>
  );
}

function Fallback() {
  const query = useQuerySearchParams();
  const subSection = query.get('sub_section');

  const { data } = useSettings();
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const fallback = data?.fallback;

  const [createFallbackData, setCreateFallbackData] =
    useState<SettingsFallback>();
  const [showCreateFallback, setShowCreateFallback] = useState(false);

  const confirm = useConfirmationModal();

  const [showTransition, setShowTransition] = useState(false);

  useEffect(() => {
    if (subSection === 'fallback') {
      document
        .getElementById('chatbot-fallback')
        ?.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        setShowTransition(true);
      }, 500);
      setTimeout(() => {
        setShowTransition(false);
      }, 3000);
    }
  }, [subSection]);

  return (
    <>
      {showCreateFallback && (
        <FallbackModal
          loadingSave={loadingUpdateSettings}
          data={createFallbackData}
          onClose={() => {
            setCreateFallbackData(undefined);
            setShowCreateFallback(false);
          }}
          onSave={({ id, message, name }) => {
            if (fallback?.some((_fallback) => _fallback.id === id)) {
              updateSettings({
                fallback: fallback.map((_fallback) => {
                  if (_fallback.id === id) {
                    return { id, message, name };
                  }
                  return _fallback;
                }),
              });
            } else {
              updateSettings({
                fallback: [...(fallback ?? []), { id, message, name }],
              });
            }
          }}
        />
      )}
      <BBBCard
        title="Fallback"
        className={cx(
          'mb-5 transition-colors',
          showTransition && 'border-secondary-main'
        )}
        desc="Send a fallback message when your customer inputs an invalid reply to the chatbot"
        id="chatbot-fallback"
      >
        {!fallback?.length ? (
          <div className="text-neutral-50 mb-6">
            You don’t have any fallback template yet, create one!
          </div>
        ) : (
          fallback.map((field) => (
            <div
              className="mb-6 last:mb-0 flex items-center gap-6 cursor-pointer"
              onClick={() => {
                setShowCreateFallback(true);
                setCreateFallbackData(field);
              }}
              key={field.id}
            >
              <div className="grow group">
                <div className="text-primary-main mb-2 group-hover:underline flex items-center gap-1">
                  {field.name}
                  <span className="text-[#535353] opacity-0 group-hover:opacity-100 transition-opacity">
                    <Edit3 size={10} />
                  </span>
                </div>
                <div className="text-neutral-50 text-sm line-clamp-1">
                  {field.message}
                </div>
              </div>
              <Trash2
                className="text-neutral-30 hover:text-danger-hover cursor-pointer flex-none"
                onClick={(e) => {
                  confirm({
                    title: 'Delete fallback',
                    description:
                      'Are you sure want to delete this fallback? Once you deleted it cannot be undone.',
                    submitText: 'Delete fallback',
                    deleteModal: true,
                    loadingSave: loadingUpdateSettings,
                    onAccept: (hide) => {
                      updateSettings(
                        {
                          fallback: fallback.filter(
                            (_fallback) => _fallback.id !== field.id
                          ),
                        },
                        {
                          onSuccess: () => {
                            hide();
                          },
                        }
                      );
                    },
                  });
                  return e.stopPropagation();
                }}
              />
            </div>
          ))
        )}
        <div
          className="flex items-center cursor-pointer gap-2 text-neutral-40"
          onClick={() => {
            setShowCreateFallback(true);
            setCreateFallbackData(undefined);
          }}
        >
          <PlusCircle size={20} />
          <div className="grow">Create new fallback template</div>
        </div>
      </BBBCard>
    </>
  );
}

function FallbackModal({
  data,
  onClose,
  onSave,
  loadingSave,
}: {
  data?: SettingsFallback;
  onClose: () => void;
  onSave: (params: SettingsFallback) => void;
  loadingSave: boolean;
}) {
  const [fallbackName, setFallbackName] = useState('');
  const [fallbackMessage, setFallbackMessage] = useState('');
  const [id, setId] = useState<string>(createId());

  useEffect(() => {
    setFallbackName(data?.name || '');
    setFallbackMessage(data?.message || '');
    setId(data?.id || createId());
  }, [data?.id, data?.name, data?.message]);

  return (
    <BBBModal
      show
      title={!data ? 'Create fallback' : 'Edit fallback'}
      submitText="Save fallback"
      cancelText="Discard"
      footer
      disableSave={!fallbackMessage || !fallbackName}
      onHide={onClose}
      handleSave={() => {
        onSave({ name: fallbackName, message: fallbackMessage, id });
        onClose();
      }}
    >
      <BBBTextInput
        label="Fallback name"
        placeholder="Type your fallback name"
        value={fallbackName}
        onChange={({ target: { value } }) => setFallbackName(value)}
      />
      <BBBTextAreaInput
        label="Message"
        placeholder="Type fallback message"
        value={fallbackMessage}
        onChange={({ target: { value } }) => setFallbackMessage(value)}
        rows={5}
      />
    </BBBModal>
  );
}
