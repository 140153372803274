import { useState } from 'react';
import { Info } from 'react-feather';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { twMerge as cx } from 'tailwind-merge';

import {
  BBBCard,
  BBBCustomTooltipChart,
  BBBCustomTooltipChartProps,
  BBBTooltip,
  BBBTooltipProps,
  IBBBCard,
} from '@/components';
import { Paths } from '@/types/utils/nestedObjectPath';
import { cn } from '@/utils/styles';

type MultipleLine<T> =
  | {
      isMultipleLine: true;
      lineDataKey: {
        key: string;
        color: string;
        type: CurveType;
      }[];
    }
  | {
      isMultipleLine?: false;
      lineDataKey: Paths<T>;
    };

export type BBBGraphChartProps<
  T extends {
    [k: string]: unknown;
  }
> = {
  containerClassName?: string;
  title?: string;
  data: T[] | undefined;
  xAxisDataKey: XAxisProps['dataKey'];
  yAxisDataKey?: Paths<T>;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;

  tooltipProps?: BBBCustomTooltipChartProps;
  customTitle?: React.ReactNode;
  tooltip?: BBBTooltipProps['content'];
  customHeader?: (
    onKeyChange: (key: string | undefined) => void
  ) => React.ReactNode;
  customFooter?: (
    onKeyChange: (key: string | undefined) => void
  ) => React.ReactNode;
} & IBBBCard &
  MultipleLine<T>;

const BBBGraphChart = <
  T extends {
    [k: string]: unknown;
  }
>({
  containerClassName,
  title,
  data,
  xAxisDataKey,
  yAxisDataKey,
  tooltip,
  customTitle,
  customHeader,
  customFooter,

  lineDataKey,
  isMultipleLine,

  xAxisProps,
  yAxisProps,
  tooltipProps,
  ...props
}: BBBGraphChartProps<T>) => {
  const isMultipleControlled = isMultipleLine !== undefined;

  const [opacityKey, setOpacityKey] = useState<string>();

  const handleOpacityKey = (key: string | undefined) => {
    if (isMultipleControlled) {
      setOpacityKey(key);
    }
  };

  const isEmpty = data?.every(
    (_data) => !Object.keys(_data).filter((key) => key !== xAxisDataKey).length
  );

  const Title = tooltip ? (
    <div className="flex gap-1 items-center w-fit">
      {customTitle || (
        <p className="text-base font-normal whitespace-nowrap">{title}</p>
      )}{' '}
      <BBBTooltip show={!!tooltip} content={tooltip} className="bottom-[95%]">
        <Info size={14} color="#9E9E9E" />
      </BBBTooltip>
    </div>
  ) : (
    customTitle || (
      <p className="text-base font-normal whitespace-nowrap">{title}</p>
    )
  );

  const commonCardProps = {
    titleClassName: 'text-base font-normal',
    className: cx(containerClassName),
    title: Title,
  };

  if (isEmpty) {
    return (
      <BBBCard {...commonCardProps}>
        <div className="flex items-center justify-center flex-col h-44">
          <div className="font-semibold">No sessions in this date range</div>
          <div className="text-sub-heading">
            Try selecting a different date range
          </div>
        </div>
      </BBBCard>
    );
  }

  return (
    <BBBCard
      {...commonCardProps}
      rightButton={customHeader?.(handleOpacityKey)}
      {...props}
    >
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          width={500}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="8" />
          <XAxis
            dataKey={xAxisDataKey}
            axisLine={false}
            tickMargin={10}
            className="text-sm text-primary-main"
            {...xAxisProps}
          />
          <YAxis
            dataKey={yAxisDataKey}
            axisLine={false}
            tickMargin={10}
            className="text-sm text-primary-main"
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            {...yAxisProps}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<BBBCustomTooltipChart {...tooltipProps} />}
          />
          {isMultipleLine ? (
            lineDataKey.map((key, idx) => (
              <Line
                key={idx}
                type="monotone"
                dataKey={key.key}
                opacity={
                  opacityKey === '' || opacityKey === undefined
                    ? 1
                    : opacityKey === key.key
                    ? 1
                    : 0.3
                }
                stroke={key.color}
                activeDot={{ r: 4 }}
                dot={false}
                legendType="rect"
                strokeWidth={2}
                strokeDasharray={key.type === 'basis' ? '5 5' : '0'}
              />
            ))
          ) : (
            <Line
              type="monotone"
              dataKey={lineDataKey}
              opacity={opacityKey === lineDataKey ? 1 : 0.5}
              stroke="#2699FB"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <div
        className={cx(
          typeof customFooter === undefined || typeof customHeader === undefined
            ? 'mt-0 hidden'
            : 'w-full flex justify-end mt-6'
        )}
      >
        {customFooter?.(handleOpacityKey)}
      </div>
    </BBBCard>
  );
};

export default BBBGraphChart;
