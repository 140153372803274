function StampedIcon({ size = 24 }: { size?: number | string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 358 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M160.463 31.1964L166.272 28.3449C166.52 28.223 166.81 28.4037 166.81 28.6802V35.1994H173.157V41.8634H166.81V53.922C166.81 55.1913 167.17 56.2068 167.889 56.9684C168.651 57.6876 169.666 58.0473 170.935 58.0473C171.599 58.0473 172.229 58.0354 172.839 57.73V64.0766C172.543 64.2035 172.205 64.3093 171.824 64.3939C171.105 64.6055 170.28 64.7113 169.349 64.7113C167.91 64.7113 166.577 64.4785 165.35 64.0131C164.166 63.5054 163.129 62.7861 162.241 61.8553C161.394 60.9244 160.717 59.8244 160.21 58.555C159.744 57.2857 159.511 55.8471 159.511 54.2393V41.8634H155.069V35.8341H160.463V31.1964Z"
        fill="#171838"
      />
      <path
        d="M189.655 64.7113C187.836 64.7113 186.101 64.3516 184.451 63.6323C182.801 62.8707 181.341 61.8341 180.072 60.5225C178.845 59.1685 177.851 57.5819 177.089 55.7625C176.37 53.9431 176.01 51.9545 176.01 49.7967C176.01 47.6388 176.37 45.6502 177.089 43.8309C177.851 42.0115 178.845 40.446 180.072 39.1344C181.341 37.7804 182.801 36.7438 184.451 36.0245C186.101 35.2629 187.836 34.8821 189.655 34.8821C190.925 34.8821 192.067 35.0514 193.083 35.3898C194.098 35.7283 194.965 36.088 195.685 36.4688C196.489 36.9342 197.229 37.4631 197.906 38.0554V35.1994H204.57V64.3939H197.906V61.5379C197.229 62.1303 196.489 62.6592 195.685 63.1246C194.965 63.5477 194.098 63.9285 193.083 64.267C192.067 64.5632 190.925 64.7113 189.655 64.7113ZM197.906 49.7967C197.906 47.385 197.208 45.4598 195.812 44.0213C194.415 42.5827 192.681 41.8634 190.607 41.8634C188.534 41.8634 186.799 42.5827 185.403 44.0213C184.007 45.4598 183.309 47.385 183.309 49.7967C183.309 52.2084 184.007 54.1335 185.403 55.5721C186.799 57.0107 188.534 57.73 190.607 57.73C192.681 57.73 194.415 57.0107 195.812 55.5721C197.208 54.1335 197.906 52.2084 197.906 49.7967Z"
        fill="#171838"
      />
      <path
        d="M224.254 34.8821C225.735 34.8821 227.005 35.0937 228.062 35.5168C229.12 35.8976 229.988 36.3207 230.665 36.7861C231.426 37.3361 232.04 37.9708 232.505 38.6901C233.182 37.9708 233.965 37.3361 234.853 36.7861C235.615 36.3207 236.525 35.8976 237.582 35.5168C238.64 35.0937 239.804 34.8821 241.073 34.8821C244.246 34.8821 246.785 35.8976 248.689 37.9285C250.593 39.9594 251.545 42.9635 251.545 46.9407V64.3939H244.246V47.5754C244.246 45.7983 243.76 44.4021 242.787 43.3866C241.856 42.3711 240.756 41.8634 239.486 41.8634C238.217 41.8634 237.096 42.3711 236.123 43.3866C235.192 44.4021 234.726 45.7983 234.726 47.5754V64.3939H227.428V47.5754C227.428 45.7983 226.941 44.4021 225.968 43.3866C225.037 42.3711 223.937 41.8634 222.668 41.8634C221.398 41.8634 220.277 42.3711 219.304 43.3866C218.373 44.4021 217.908 45.7983 217.908 47.5754V64.3939H210.609V35.1994H217.273V38.0554C217.866 37.4631 218.521 36.9342 219.241 36.4688C219.833 36.088 220.552 35.7283 221.398 35.3898C222.245 35.0514 223.197 34.8821 224.254 34.8821Z"
        fill="#171838"
      />
      <path
        d="M272.172 34.8821C273.991 34.8821 275.726 35.2629 277.376 36.0245C279.026 36.7438 280.464 37.7804 281.691 39.1344C282.961 40.446 283.955 42.0115 284.674 43.8309C285.436 45.6502 285.817 47.6388 285.817 49.7967C285.817 51.9545 285.436 53.9431 284.674 55.7625C283.955 57.5819 282.961 59.1685 281.691 60.5225C280.464 61.8341 279.026 62.8707 277.376 63.6323C275.726 64.3516 273.991 64.7113 272.172 64.7113C270.945 64.7113 269.866 64.5632 268.935 64.267C268.046 63.9708 267.263 63.6535 266.586 63.315C265.825 62.8919 265.148 62.4053 264.556 61.8553V71.1944L257.803 74.6992C257.554 74.8282 257.257 74.6478 257.257 74.3676V35.1994H263.921V38.0554C264.556 37.4631 265.296 36.9342 266.142 36.4688C266.861 36.088 267.729 35.7283 268.744 35.3898C269.76 35.0514 270.902 34.8821 272.172 34.8821ZM263.921 49.7967C263.921 52.2084 264.619 54.1335 266.015 55.5721C267.412 57.0107 269.146 57.73 271.22 57.73C273.293 57.73 275.027 57.0107 276.424 55.5721C277.82 54.1335 278.518 52.2084 278.518 49.7967C278.518 47.385 277.82 45.4598 276.424 44.0213C275.027 42.5827 273.293 41.8634 271.22 41.8634C269.146 41.8634 267.412 42.5827 266.015 44.0213C264.619 45.4598 263.921 47.385 263.921 49.7967Z"
        fill="#171838"
      />
      <path
        d="M316.29 55.5086C315.91 56.6087 315.317 57.7088 314.513 58.8089C313.752 59.909 312.8 60.9033 311.657 61.7918C310.557 62.638 309.288 63.3362 307.849 63.8862C306.453 64.4362 304.93 64.7113 303.28 64.7113C301.291 64.7113 299.408 64.3305 297.631 63.5689C295.897 62.765 294.352 61.686 292.998 60.3321C291.687 58.9781 290.629 57.4126 289.825 55.6356C289.063 53.8162 288.683 51.8699 288.683 49.7967C288.683 47.7235 289.063 45.7983 289.825 44.0213C290.629 42.2019 291.687 40.6152 292.998 39.2613C294.352 37.9073 295.897 36.8496 297.631 36.088C299.408 35.2841 301.291 34.8821 303.28 34.8821C305.184 34.8821 306.982 35.2418 308.675 35.961C310.367 36.6803 311.848 37.6958 313.117 39.0074C314.387 40.3191 315.381 41.9057 316.1 43.7674C316.862 45.5868 317.243 47.5965 317.243 49.7967C317.243 50.1352 317.221 50.4737 317.179 50.8121C317.137 51.1083 317.094 51.4045 317.052 51.7007C317.01 52.0392 316.967 52.3565 316.925 52.6527H296.616C296.954 54.0912 297.674 55.2971 298.774 56.2702C299.874 57.2434 301.376 57.73 303.28 57.73C304.253 57.73 305.057 57.6242 305.692 57.4126C306.369 57.2011 306.94 56.9472 307.405 56.651C307.913 56.3125 308.336 55.9318 308.675 55.5086H316.29ZM303.28 41.8634C301.376 41.8634 299.81 42.4134 298.583 43.5135C297.399 44.6136 296.637 45.9676 296.299 47.5754H310.261C309.923 45.9676 309.14 44.6136 307.913 43.5135C306.728 42.4134 305.184 41.8634 303.28 41.8634Z"
        fill="#171838"
      />
      <path
        d="M333.729 64.7113C331.909 64.7113 330.175 64.3516 328.525 63.6323C326.874 62.8707 325.415 61.8341 324.145 60.5225C322.918 59.1685 321.924 57.5819 321.163 55.7625C320.443 53.9431 320.084 51.9545 320.084 49.7967C320.084 47.6388 320.443 45.6502 321.163 43.8309C321.924 42.0115 322.918 40.446 324.145 39.1344C325.415 37.7804 326.874 36.7438 328.525 36.0245C330.175 35.2629 331.909 34.8821 333.729 34.8821C334.914 34.8821 335.971 35.0514 336.902 35.3898C337.833 35.686 338.637 36.0034 339.314 36.3418C340.075 36.7649 340.752 37.2304 341.345 37.7381V27.7059L348.096 24.1582C348.345 24.0275 348.643 24.2079 348.643 24.4889V64.3939H341.979V61.5379C341.303 62.1303 340.562 62.6592 339.758 63.1246C339.039 63.5477 338.171 63.9285 337.156 64.267C336.141 64.5632 334.998 64.7113 333.729 64.7113ZM341.979 49.7967C341.979 47.385 341.281 45.4598 339.885 44.0213C338.489 42.5827 336.754 41.8634 334.681 41.8634C332.608 41.8634 330.873 42.5827 329.477 44.0213C328.08 45.4598 327.382 47.385 327.382 49.7967C327.382 52.2084 328.08 54.1335 329.477 55.5721C330.873 57.0107 332.608 57.73 334.681 57.73C336.754 57.73 338.489 57.0107 339.885 55.5721C341.281 54.1335 341.979 52.2084 341.979 49.7967Z"
        fill="#171838"
      />
      <path
        d="M124.245 35.5348C124.245 34.0173 124.561 32.5631 125.194 31.1721C125.868 29.7811 126.796 28.5797 127.976 27.5681C129.198 26.5143 130.652 25.6712 132.339 25.039C134.067 24.4067 136.006 24.0905 138.156 24.0905C140.137 24.0905 141.928 24.4067 143.53 25.039C145.174 25.6712 146.586 26.5564 147.766 27.6945C148.946 28.8326 149.853 30.1815 150.485 31.7411C151.117 33.2586 151.433 34.9236 151.433 36.7361H144.162C144.162 34.9658 143.635 33.5747 142.581 32.5631C141.528 31.5514 140.052 31.0456 138.156 31.0456C136.259 31.0456 134.783 31.5093 133.73 32.4366C132.676 33.364 132.149 34.3756 132.149 35.4716C132.149 36.6518 132.655 37.5581 133.666 38.1904C134.678 38.8227 135.943 39.3917 137.46 39.8975C138.977 40.4034 140.621 40.9303 142.392 41.4782C144.204 42.0262 145.869 42.7639 147.387 43.6912C148.904 44.6186 150.169 45.841 151.18 47.3584C152.192 48.8338 152.698 50.7728 152.698 53.1754C152.698 54.6086 152.34 55.9996 151.623 57.3485C150.949 58.6973 149.979 59.9197 148.715 61.0157C147.45 62.0695 145.911 62.9336 144.099 63.608C142.329 64.2403 140.347 64.5565 138.156 64.5565C135.921 64.5565 133.919 64.2403 132.149 63.608C130.421 62.9758 128.945 62.0906 127.723 60.9525C126.5 59.7722 125.552 58.4023 124.878 56.8426C124.245 55.2409 123.929 53.4916 123.929 51.5947H131.517C131.517 53.3651 132.107 54.8193 133.287 55.9575C134.467 57.0534 136.09 57.6014 138.156 57.6014C140.39 57.6014 142.055 57.1377 143.151 56.2104C144.246 55.2409 144.794 54.1238 144.794 52.8593C144.794 51.6369 144.289 50.6885 143.277 50.014C142.265 49.3396 141.001 48.7495 139.483 48.2436C137.966 47.6957 136.301 47.1688 134.488 46.6629C132.718 46.1571 131.074 45.4616 129.556 44.5764C128.039 43.6912 126.774 42.5531 125.763 41.1621C124.751 39.7289 124.245 37.8532 124.245 35.5348Z"
        fill="#171838"
      />
      <path
        d="M353.726 61.3309V61.9749H352.803V64.3557H352.13V61.9749H351.213V61.3309H353.726Z"
        fill="#171838"
      />
      <path
        d="M357.493 61.3309V64.3557H356.824V62.5312L355.863 64.1169H355.785L354.824 62.536V64.3557H354.15V61.3309H354.843L355.824 62.9459L356.8 61.3309H357.493Z"
        fill="#171838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6683 34.116L39.508 0.754846L4a9.4847 0.75492L38.645 34.116L28.6683 34.116Z"
        fill="#FF672E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8831 64.2232L0.504432 43.6049L3.58747 34.1165L31.9661 54.7348L28.8831 64.2232Z"
        fill="#FFD600"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4931 73.2917L29.1144 93.91L21.0431 88.0458L49.4218 67.4275L57.4931 73.2917Z"
        fill="#FFB800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0096 48.8178L85.8493 82.179L77.778 88.043L66.9383 54.6819L75.0096 48.8178Z"
        fill="#FFAA05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.2194 24.6273L92.2974 24.6273L95.3803 34.1158L60.3023 34.1158L57.2194 24.6273Z"
        fill="#FF883B"
      />
    </svg>
  );
}

export default StampedIcon;
