import { ApiResponse } from 'api/types';
import api from 'config/api';
import { DeepPartial } from 'redux';
import { ChannelIntegrations } from 'types/bitChat/integrations';
import { Flow } from 'types/whatsApp/flow';
import { IntegrationSources } from 'types/whatsApp/shape';
import { arrayMove } from 'utils/common/array';

export default {
  getFlows: (companyId: number, params?: { search?: string }) =>
    api.whatsApp.v1
      .get<ApiResponse<Flow[]>>(`/flow/${companyId}`, { params })
      .then((res) => sortByPriorities(res.data.data)),
  getFlowById: (companyId: number, id: string) =>
    api.whatsApp.v1
      .get<ApiResponse<Flow>>(`/flow/${companyId}/${id}`)
      .then((res) => res.data.data),
  deleteFlow: (companyId: number, id: string) =>
    api.whatsApp.v1.delete(`/flow/${companyId}/${id}`),
  duplicateFlow: (companyId: number, id: string) =>
    api.whatsApp.v1.post<ApiResponse<Flow>>(
      `/flow/${companyId}/${id}/duplicate`
    ),
  updateFlow: (payload: DeepPartial<Flow>) =>
    api.whatsApp.v1
      .post<ApiResponse<Flow>>(`/flow`, payload)
      .then((res) => res.data.data),
  toggleFlow: (companyId: number, payload: Pick<Flow, 'id' | 'status'>) =>
    api.whatsApp.v1
      .post<ApiResponse<Flow>>(`/flow/toggle/${companyId}`, payload)
      .then((res) => res.data.data),
  selectWelcomeAction: (
    companyId: number,
    payload: {
      id: string | number;
      type: 'away_message' | 'welcome_message';
      source: ChannelIntegrations;
    }
  ) => api.whatsApp.v1.post(`/flow/${companyId}/welcome-action`, payload),
  checkKeywordAvailability: (
    companyId: number,
    payload: CheckKeywordAvailabilityParams
  ) =>
    api.whatsApp.v1
      .post<ApiResponse<boolean>>(`/flow/${companyId}/keywords-check`, payload)
      .then((res) => res.data.data),
  getSuggestedKeywords: (companyId: number) =>
    api.whatsApp.v1
      .get<ApiResponse<string[]>>(`/flow/${companyId}/suggested-keywords`)
      .then((res) => res.data.data),
  getCountNewFlow: (companyId: number) =>
    api.whatsApp.v1
      .get<ApiResponse<number>>(
        `/flow/${companyId}/count-new-chatbot
    `
      )
      .then((res) => res.data.data),
};

function sortByPriorities(flows: Flow[]) {
  const welcomeActionFlow = flows.find(
    (flow) =>
      flow.triggerType === 'welcome_action' &&
      flow.nodes.length &&
      flow.nodes[0].data &&
      ((flow.nodes[0].jobType === 'run_flow' && flow.nodes[0].data.flow) ||
        (flow.nodes[0].jobType === 'assign_ticket' && flow.nodes[0].data.agent))
  )?.nodes[0].data;

  const welcomeActionFlowId = welcomeActionFlow
    ? 'flow' in welcomeActionFlow
      ? welcomeActionFlow.flow.id
      : undefined
    : undefined;

  const awayMessageFlow = flows.find(
    (flow) =>
      flow.triggerType === 'away_message' &&
      flow.nodes.length &&
      flow.nodes[0].data &&
      ((flow.nodes[0].jobType === 'run_flow' && flow.nodes[0].data.flow) ||
        (flow.nodes[0].jobType === 'assign_ticket' && flow.nodes[0].data.agent))
  )?.nodes[0].data;

  const awayMessageFlowId = awayMessageFlow
    ? 'flow' in awayMessageFlow
      ? awayMessageFlow.flow.id
      : undefined
    : undefined;

  if (awayMessageFlowId) {
    const targetIndex = flows.findIndex(
      (flow) => flow.id === awayMessageFlowId
    );

    if (targetIndex > -1) {
      arrayMove(flows, targetIndex, 0);
    }
  }

  if (welcomeActionFlowId) {
    const targetIndex = flows.findIndex(
      (flow) => flow.id === welcomeActionFlowId
    );

    if (targetIndex > -1) {
      arrayMove(flows, targetIndex, 0);
    }
  }

  return flows;
}

export type CheckKeywordAvailabilityParams = {
  id?: string;
  keyword: string;
  source: IntegrationSources;
};
