import RefreshCounter from './RefreshCounter';

import BBBLogo from '@/assets/icons/BBBLogo';
import { BBBButton } from '@/components';

function ServerError() {
  return (
    <div className="max-w-md mx-auto px-4 md:px-0 flex min-h-full flex-col justify-center items-center">
      <BBBLogo className="object-contain mb-16" />
      <div className="text-2xl font-medium mb-4">Whoops...</div>
      <div className="text-center mb-4">
        Our team has acknowledged an issue resulting in high demand, and we are
        currently working to increase our capacity. We apologize for any
        inconvenience caused and appreciate your patience as we work to resolve
        the issue.
      </div>
      <div className="text-neutral-50 mb-6">
        This page will refresh automatically in{' '}
        <span>
          <RefreshCounter resetErrorBoundary={resetError} />
        </span>
        s...
      </div>
      <div className="flex gap-4">
        <BBBButton text="Refresh" onClick={() => resetError()} />
      </div>
    </div>
  );
}

export default ServerError;

function resetError() {
  // eslint-disable-next-line no-self-assign
  window.location.href = window.location.href;
}
