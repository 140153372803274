import { useEffect, useMemo, useState } from 'react';
import { Trash2 } from 'react-feather';
import { Control, Controller, FieldError, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual, omit } from 'lodash-es';
import * as yup from 'yup';
import Popup from '.';

import {
  BBBAlert,
  BBBSelect,
  BBBSlider,
  BBBSpinner,
  BBBTextInput,
} from '@/components';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { env } from '@/config/env';
import { useAddCollection } from '@/hooks/bitApp/design/collections';
import useDeleteSection from '@/hooks/bitApp/design/useDeleteSection';
import { useSectionDetail } from '@/hooks/bitApp/design/useSection';
import useCollection from '@/hooks/bitApp/shopify/useCollection';
import useCollections from '@/hooks/bitApp/shopify/useCollections';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setChangedPopup, setPopupError } from '@/stores/bitApp';
import ShopifyCollection from '@/types/shopify/ShopifyCollections';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';
import { handleCancelLiveSection } from '@/utils/bitApp';

export type CollectionForms = {
  collectionId: number;
  title: string | null;
  displayed_number: number;
  collections: null | ShopifyCollection;
};

type CollectionContentProps = {
  data: InfiniteData<ShopifyConnection<ShopifyCollection>> | undefined;
  control: Control<CollectionForms>;
  title: string | undefined;
  errors: FormState<CollectionForms>['errors'];
  isInitialLoading: boolean;
  isLoadingSpecific: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  setTitle: (val: string | undefined) => void;
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required('Title is required'),
    collections: yup
      .mixed<ShopifyCollection>()
      .required(`collection is required`),
    displayed_number: yup.number().required('displayed number is required'),
    collectionId: yup.number().required('Collection id is required'),
  })
  .required();

function EditCollections() {
  const dispatch = useAppDispatch();
  const toggleConfirmation = useConfirmationModal();
  const { selected } = useAppSelector((state) => state.bitApp);

  const { data: dataSection, isLoading: isLoadingSection } =
    useSectionDetail(selected);

  const collectionDataFromApi = useMemo(
    () => dataSection?.collections,
    [dataSection?.collections]
  );

  const formDefaultValue: CollectionForms = useMemo(
    () => ({
      collectionId: collectionDataFromApi?.id || -1,
      title: '',
      displayed_number: 10,
      collections: null,
    }),
    [collectionDataFromApi?.id]
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
    trigger,
  } = useCustomForm<CollectionForms>({
    defaultValues: formDefaultValue,
    resolver: yupResolver(schema),
  });

  const isHasNotError = isEmpty(errors);

  const [title, setTitle] = useState<string | undefined>();

  const memoizedParams = useMemo(
    () => ({
      title,
    }),
    [title]
  );

  const collectionsQuery = useCollections(memoizedParams);
  const { data, isInitialLoading, hasNextPage, fetchNextPage } =
    collectionsQuery;

  const { isInitialLoading: isLoadingSpecific, data: dataSpecificCollection } =
    useCollection(collectionDataFromApi?.idCollection?.toString() as string);

  const client = useQueryClient();

  const { mutate: save, isLoading: isLoadingSave } = useAddCollection();

  const deleteSection = useDeleteSection();

  const formIsEqual = useMemo(
    () =>
      isEqual(
        {
          ...omit(watch(), 'collections'),
          collections: watch().collections?.id,
        },
        {
          collectionId: collectionDataFromApi?.id,
          title: collectionDataFromApi?.title || '',
          displayed_number: collectionDataFromApi?.displayedNumber,
          collections: dataSpecificCollection?.id,
        }
      ),
    [
      collectionDataFromApi?.displayedNumber,
      collectionDataFromApi?.id,
      collectionDataFromApi?.title,
      dataSpecificCollection?.id,
      watch(),
    ]
  );

  const handleCancel = () => {
    if (!formIsEqual) {
      return toggleConfirmation({
        title: 'Unsaved changes',
        description: 'You have unsaved changes. Are you sure want to continue?',
        cancelText: 'Back to edit',
        submitText: 'Discard changes',
        onAccept: (hide) => {
          handleCancelLiveSection();
          hide();
        },
      });
    }
    handleCancelLiveSection();
  };

  const handleDeleteSection = () => {
    deleteSection();
  };

  const onSubmit = async (data: CollectionForms) =>
    save(
      {
        ...data,
        shopifyCollectionId:
          data.collections &&
          parseInt(
            data.collections?.id?.split('gid://shopify/Collection/')[1],
            10
          ),
        sectionId: selected,
        displayedNumber: data?.displayed_number || 0,
        collections: undefined,
      },
      {
        onSuccess: () => {
          client.invalidateQueries([
            'bitapp-shopify-collections',
            collectionDataFromApi?.idCollection?.toString() as string,
          ]);
        },
      }
    );

  useEffect(() => {
    if (!isEmpty(collectionDataFromApi)) {
      reset({
        title: collectionDataFromApi?.title || '',
        displayed_number: collectionDataFromApi?.displayedNumber || 10,
        collectionId: collectionDataFromApi?.id,
        collections: dataSpecificCollection || null,
      });
    } else {
      reset(formDefaultValue);
    }
  }, [
    collectionDataFromApi?.title,
    collectionDataFromApi?.displayedNumber,
    collectionDataFromApi?.id,
    formDefaultValue,
    setValue,
    collectionDataFromApi,
    reset,
    dataSpecificCollection,
  ]);

  useEffect(() => {
    if (!isHasNotError) {
      dispatch(setPopupError(selected));
    } else {
      dispatch(setPopupError(null));
    }
  }, [dispatch, isHasNotError, selected]);

  useEffect(() => {
    if (!formIsEqual) {
      dispatch(setChangedPopup(true));
    } else {
      dispatch(setChangedPopup(false));
    }
  }, [dispatch, formIsEqual]);

  return (
    <Popup
      onSubmit={() => {
        trigger();
        handleSubmit(onSubmit)();
      }}
      onCancel={handleCancel}
      isLoadingSubmit={isLoadingSave || isLoadingSection}
      disabledSubmit={formIsEqual}
      className={
        !isHasNotError ? 'shadow shadow-danger-main border-danger-main' : ''
      }
    >
      {isLoadingSection ? (
        <div className="w-full h-full flex items-center justify-center">
          <BBBSpinner />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="w-full flex justify-between">
            <p className="text-primary text-2xl">Edit collections</p>
            <Trash2
              className="text-danger-main cursor-pointer hover:text-red-400"
              onClick={handleDeleteSection}
            />
          </div>
          <div className="h-[1px] bg-neutral-30" />
          <BBBTextInput
            label={
              <p className="text-primary-main text-ls">
                Collections title <span className="text-danger-main">*</span>
              </p>
            }
            placeholder="Enter your title"
            isHookForm
            control={control}
            controlName="title"
            hasMaxCharLabel
            maxChar={60}
            error={errors.title?.message}
            containerClassname="mb-0"
          />
          <CollectionContent
            data={data}
            control={control}
            title={title}
            errors={errors}
            isInitialLoading={isInitialLoading}
            isLoadingSpecific={isLoadingSpecific}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
            setTitle={setTitle}
          />
          {!isHasNotError && (
            <BBBAlert type="danger">
              <p className="text-center text-sm leading-4">
                Missing information! Please complete all required fields to
                continue saving
              </p>
            </BBBAlert>
          )}
        </div>
      )}
    </Popup>
  );
}

function CollectionContent({
  data,
  control,
  title,
  errors,
  isInitialLoading,
  isLoadingSpecific,
  fetchNextPage,
  hasNextPage,
  setTitle,
}: CollectionContentProps) {
  return (
    <ShopifyAwareWrapper
      appType="BITAPP"
      size="full"
      customThumbnail={(connectButton) => (
        <div className="flex flex-col gap-6">
          <BBBAlert type="info">
            <p className="text-center text-primary-main leading-5">
              Connect to Shopify to start adding your collections!
            </p>
          </BBBAlert>
          {connectButton}
        </div>
      )}
    >
      {() => (
        <_CollectionContent
          data={data}
          control={control}
          title={title}
          errors={errors}
          isInitialLoading={isInitialLoading}
          isLoadingSpecific={isLoadingSpecific}
          fetchNextPage={fetchNextPage}
          hasNextPage={!!hasNextPage}
          setTitle={setTitle}
        />
      )}
    </ShopifyAwareWrapper>
  );
}

function _CollectionContent({ ...props }: CollectionContentProps) {
  const {
    data,
    control,
    title,
    errors,
    isInitialLoading,
    isLoadingSpecific,
    fetchNextPage,
    hasNextPage,
    setTitle,
  } = props;

  const mapCollectionOption = (collection: ShopifyCollection) => ({
    title: <CollectionOption collection={collection} />,
    id: collection.id,
  });

  const memoizedCurrentPages = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.edges
          .sort((a, b) =>
            a.node.title.localeCompare(b.node.title, 'en', {
              sensitivity: 'base',
            })
          )
          .map((edge) => mapCollectionOption(edge.node))
      ),
    [data?.pages]
  );

  return (
    <div className="flex flex-col gap-6">
      <Controller
        name="collections"
        control={control}
        render={({ field: { value, onChange } }) => (
          <BBBSelect
            label={
              <p className="text-primary-main text-ls">
                Collections title <span className="text-danger-main">*</span>
              </p>
            }
            options={memoizedCurrentPages}
            // @ts-ignore
            value={value}
            onValueChange={onChange}
            placeholder="Choose collection"
            error={(errors.collections as FieldError)?.message}
            optionValue="id"
            optionLabel="title"
            loading={isInitialLoading || isLoadingSpecific}
            isPaginated
            fetchNext={fetchNextPage}
            hasMore={!!hasNextPage}
            isClearable
            isSearchable
            search={title}
            onChangeSearch={setTitle}
          />
        )}
      />
      <div className="flex flex-col gap-1.5">
        <p className="text-ls text-primary-main">
          Number of products displayed
        </p>
        <div className="flex gap-2.5 items-center justify-center">
          <Controller
            name="displayed_number"
            control={control}
            render={({ field: { value, onChange } }) => (
              <BBBSlider
                onChange={(val) => onChange(val)}
                min={4}
                step={2}
                value={value}
                max={10}
              />
            )}
          />
          <BBBTextInput
            type="number"
            isHookForm
            control={control}
            controlName="displayed_number"
            placeholder="0"
            inputClassName="w-16"
            value={0}
            disabled
          />
        </div>
      </div>
    </div>
  );
}

function CollectionOption({
  collection,
}: {
  collection: ShopifyCollection | undefined;
}) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          collection?.image
            ? collection?.image.url
            : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={collection?.id}
      />
      <p className="text-sm text-primary-main">{collection?.title}</p>
    </div>
  );
}

export default EditCollections;
