import { env } from '@/config/env';
import { AppType } from '@/types/systemLogin';

export const gatewayUrl = env.REACT_APP_API_BBBGATEWAY_BASEURL;
export const bitlinkProxyUrl = env.REACT_APP_API_BITLINK_PROXY_BASEURL;

// Start baseUrl region
export const systemLoginBaseUrl = `${gatewayUrl}/system-login`;
export const bitAppBaseUrl = `${gatewayUrl}/bitapp`;
export const bitCrmBaseUrl = `${gatewayUrl}/bitcrm`;
export const bitLoginBaseUrl = `${gatewayUrl}/bitlogin`;
export const bitChatBaseUrl = `${gatewayUrl}/bitchat`;
export const bitLinkBaseUrl = `${gatewayUrl}/bitlink`;
export const bitAIBaseUrl = `${gatewayUrl}/bitai`;
export const bitAIV2NewBaseUrl = `${gatewayUrl}/bitai/v2`;
export const integrationsBaseurl = `${gatewayUrl}/integrations`;
export const pricingBaseUrl = `${gatewayUrl}/pricing`;
export const whatsAppBaseUrl = `${gatewayUrl}/whatsapp`;
export const instagramBaseUrl = `${gatewayUrl}/instagram`;
export const facebookBaseUrl = `${gatewayUrl}/whatsapp`;
export const customersBaseUrl = `${gatewayUrl}/customer`;
export const pixelBaseUrl = `${gatewayUrl}/pixel`;
export const aiBaseUrl = `${gatewayUrl}/ai`;
// End baseUrl region

// Start apiBaseUrl region
export const systemLoginApiBaseUrl = `${systemLoginBaseUrl}`;
export const bitAppApiBaseUrl = `${bitAppBaseUrl}/api`;
export const bitLinkApiBaseUrl = `${bitLinkBaseUrl}/api`;
export const bitLoginApiBaseUrl = `${bitLoginBaseUrl}/api`;
export const bitCrmApiV1BaseUrl = `${bitCrmBaseUrl}`;
export const bitCrmApiV2BaseUrl = `${bitCrmBaseUrl}/api/v2`;
export const bitChatApiBaseUrl = `${bitChatBaseUrl}`;
export const bitAiApiV0BaseUrl = `${bitAIBaseUrl}/api`;
export const bitAIApiV1BaseUrl = `${bitAIBaseUrl}/api/v1`;
export const bitAIApiV2BaseUrl = `${bitAIBaseUrl}/api/v2`;
export const bitAIApiV2NewBaseUrl = `${bitAIV2NewBaseUrl}/api`;
export const integrationsApiBaseUrl = `${integrationsBaseurl}/api`;
export const whatsAppApiV1BaseUrl = `${whatsAppBaseUrl}`;
export const whatsAppApiV2BaseUrl = `${whatsAppBaseUrl}/api/v2`;
export const whatsAppApiV3BaseUrl = `${whatsAppBaseUrl}/api/v3`;
export const instagramApiV1BaseUrl = `${instagramBaseUrl}`;
export const facebookApiV1BaseUrl = `${facebookBaseUrl}/meta`;
export const customersApiBaseUrl = `${customersBaseUrl}/api`;
export const pixelApiV1BaseUrl = `${pixelBaseUrl}/api/v1`;
export const aiApiV1BaseUrl = `${aiBaseUrl}/v1`;
export const pricingApiBaseUrl = `${pricingBaseUrl}/api`;

// End apiBaseUrl region

export const mapAppTypeToApiBaseUrl: Record<AppType, string> = {
  BITAI: bitAiApiV0BaseUrl,
  BITAPP: bitAppApiBaseUrl,
  BITCRM: bitCrmApiV2BaseUrl,
  BITCHAT: bitChatApiBaseUrl,
  BITLINK: bitLinkApiBaseUrl,
  BITLOGIN: bitLoginApiBaseUrl,
};
