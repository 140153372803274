import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { AppearanceForm } from 'pages/BitLink/Appearance';
import { twMerge as cx } from 'tailwind-merge';

import {
  BBBCard,
  BBBColorPicker,
  BBBImageUpload,
  BBBSlider,
  BBBTextInput,
} from '@/components';
import { ColorPickerPopup } from '@/components/BBBColorPicker/BBBColorPicker';
import useCropImageModal from '@/hooks/common/useCropImageModal';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import { BackgroundType } from '@/types/bitLink/v2';
import { FileType } from '@/types/utils/file';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

type Props = {
  control: Control<AppearanceForm>;
  setValue: UseFormSetValue<AppearanceForm>;
};

const BACKGROUND_TYPES: Record<BackgroundType, BackgroundType> = {
  SOLID: 'SOLID',
  GRADIENT: 'GRADIENT',
  IMAGE: 'IMAGE',
};

function Background({ control, setValue }: Props) {
  const bgImage = useWatch({
    control,
    name: 'presetScope.bgImage',
  });

  const bgImageOpacity = useWatch({
    control,
    name: 'bgImageOpacity',
  });

  const { errors } = useFormState({
    control,
  });

  return (
    <BBBCard
      className="mb-cardBottom"
      title={<p className="text-neutral-70 font-medium">Background</p>}
    >
      <Controller
        control={control}
        name="presetScope"
        render={({ field: fieldPresetScope }) => (
          <>
            <_Backgrounds
              control={control}
              value={fieldPresetScope.value.bgType}
              onChange={(val) =>
                fieldPresetScope.onChange({
                  ...fieldPresetScope.value,
                  bgType: val,
                })
              }
              backgroundOpacity={bgImageOpacity}
              setValue={setValue}
            />

            <div
              className={`${
                fieldPresetScope.value.bgType === 'IMAGE' && 'flex-col'
              } flex gap-5 mt-5`}
            >
              {fieldPresetScope.value.bgType === 'SOLID' ? (
                <div className="flex flex-col gap-1.5">
                  <p className="text-primary-main text-ls font-medium">Color</p>
                  <Controller
                    control={control}
                    name="presetScope.bgColor"
                    render={({ field }) => (
                      <BBBColorPicker
                        color={field.value || '#DDDDDD'}
                        onChangeColor={field.onChange}
                        width="42px"
                        height="42px"
                        label="Background color"
                      />
                    )}
                  />
                </div>
              ) : null}

              {fieldPresetScope.value.bgType === 'GRADIENT' ? (
                <div className="flex items-end gap-6">
                  <div className="flex flex-col gap-1.5">
                    <p className="text-primary-main text-ls font-medium">
                      Color
                    </p>
                    <Controller
                      control={control}
                      name="presetScope.bgColor"
                      render={({ field }) => (
                        <BBBColorPicker
                          color={field.value || '#DDDDDD'}
                          onChangeColor={field.onChange}
                          width="42px"
                          height="42px"
                          label="Top gradient color"
                        />
                      )}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="presetScope.bgColorBottom"
                    render={({ field }) => (
                      <BBBColorPicker
                        color={field.value || '#FFFFFF'}
                        onChangeColor={field.onChange}
                        width="42px"
                        height="42px"
                        label="Bottom gradient color"
                      />
                    )}
                  />
                </div>
              ) : null}

              {fieldPresetScope.value.bgType === 'IMAGE' ? (
                <div className="flex flex-col gap-1.5">
                  <p className="text-primary-main text-ls font-medium">
                    Background Image
                  </p>
                  <Controller
                    control={control}
                    name="presetScope.bgImage"
                    render={({ field }) => (
                      <BBBImageUpload
                        crop={{ aspect: 1080 / 1920 }}
                        containerClassName="mb-3"
                        imageUrl={bgImage?.remoteUrl}
                        onChangeImage={(value) =>
                          field.onChange(convertRemoteUrlToFileType(value))
                        }
                        shape="square"
                        imageClassName="object-cover rounded-lg"
                        imageStyles={{
                          opacity: (bgImageOpacity || 0) / 100,
                        }}
                        errors={errors?.presetScope?.bgImage?.message}
                      />
                    )}
                  />

                  <div className="lg:w-1/2">
                    <div className="mb-2 mt-4 text-primary-main text-ls font-medium">
                      Image Opacity (%)
                    </div>
                    <div className="flex gap-3 items-center">
                      <Controller
                        control={control}
                        name="bgImageOpacity"
                        render={({ field }) => (
                          <BBBSlider
                            min={1}
                            max={100}
                            value={field.value || 0}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <BBBTextInput
                        type="number"
                        isHookForm
                        control={control}
                        controlName="bgImageOpacity"
                        placeholder="0"
                        inputClassName="w-16"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      />
    </BBBCard>
  );
}

function _Backgrounds({
  control,
  setValue,
  value,
  onChange,
  backgroundOpacity,
}: {
  control: Control<AppearanceForm>;
  setValue: UseFormSetValue<AppearanceForm>;
  value: BackgroundType | null;
  onChange: (val: BackgroundType | null) => void;
  backgroundOpacity: number | null | undefined;
}) {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [colorPicker, setColorPicker] = useState(false);

  const bgColor = useWatch({
    control,
    name: 'presetScope.bgColor',
  });

  const bgColorBottom = useWatch({
    control,
    name: 'presetScope.bgColorBottom',
  });

  const bgImage = useWatch({
    control,
    name: 'presetScope.bgImage',
  });

  const ensureColorLength = (color: string): string => {
    if (color.length) {
      color = color.replace('#', '');
      while (color.length < 6) {
        color += color.charAt(color.length - 1);
      }
    }

    return `#${color}`;
  };

  useOutsideAlerterv2(pickerRef!, () => {
    if (colorPicker) {
      setColorPicker(false);
      setValue('presetScope.bgColor', ensureColorLength(bgColor || '#DDDDDD'));
    }
  });

  return (
    <>
      <div className="flex relative gap-14 justify-center">
        {Object.values(BACKGROUND_TYPES)
          ?.filter((item) => item !== 'IMAGE')
          .map((type) => (
            <div key={type} className="flex flex-col items-center">
              <div
                className={cx(
                  `relative cursor-pointer lg:w-36 lg:h-[14.3rem] rounded-lg border`,
                  type === value &&
                    'outline outline-primary-main outline-1 outline-offset-4'
                )}
                onClick={() => {
                  if (type === value) {
                    setColorPicker(true);
                    return;
                  } else {
                    onChange?.(type);
                  }
                }}
                style={
                  type === 'SOLID'
                    ? { background: bgColor || '#DDDDDD' }
                    : {
                        backgroundImage: `linear-gradient(${bgColor}, ${bgColorBottom})`,
                      }
                }
              />
              <p className="mt-2 text-sm text-primary-color">
                {type === 'SOLID' ? 'Solid color' : 'Gradient'}
              </p>
            </div>
          ))}
        <div className="flex flex-col items-center">
          <Controller
            control={control}
            name="presetScope.bgImage"
            render={({ field }) => (
              <ImageBackground
                value={bgImage}
                onChange={field.onChange}
                bgType={value}
                onChangeBgType={(bgType) => onChange?.(bgType)}
                bgImageOpacity={backgroundOpacity}
              />
            )}
          />
          <div className="mt-2 text-sm text-primary-color">Image</div>
        </div>
        {colorPicker && (
          <Controller
            control={control}
            name="presetScope.bgColor"
            render={({ field }) => (
              <ColorPickerPopup
                className={`${
                  value === 'SOLID' ? 'left-[calc(18em)]' : 'left-[calc(30em)]'
                } -top-5 h-fit z-[999]`}
                color={field.value || '#DDDDDD'}
                onChangeColor={(val) => {
                  setValue('presetScope.bgColor', val);
                }}
                ref={pickerRef}
                setShow={setColorPicker}
              />
            )}
          />
        )}
      </div>
    </>
  );
}

function ImageBackground({
  value,
  onChange,
  onChangeBgType,
  bgType,
  bgImageOpacity,
}: {
  value: FileType | undefined | null;
  onChange?: (val: FileType | undefined | null) => void;
  onChangeBgType?: (val: BackgroundType) => void;
  bgType: BackgroundType | null | undefined;
  bgImageOpacity: number | null | undefined;
}) {
  const toggleCropImageModal = useCropImageModal();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      if (files.length) {
        toggleCropImageModal({
          handleDownload: (url) => onChange?.(convertRemoteUrlToFileType(url)),
          file: files[0],
          aspect: 1080 / 1920,
        });
      }
    },
    accept: {
      'image/*': [],
    },
  });

  return (
    <div onClick={() => onChangeBgType?.('IMAGE')}>
      <div
        {...getRootProps()}
        className={cx(
          `cursor-pointer h-fit flex justify-between items-center lg:w-36 lg:h-[14.3rem] rounded-lg overflow-hidden hover:scale-105 transition-transform`,
          bgType === 'IMAGE' &&
            'outline outline-primary-main outline-1 outline-offset-4'
        )}
      >
        <input {...getInputProps()} />
        {!value ? (
          <>
            <ThumbnailImageBackground />
          </>
        ) : (
          <img
            src={value?.remoteUrl}
            className="object-contain"
            style={{
              opacity: (bgImageOpacity || 0) / 100,
            }}
          />
        )}
      </div>
    </div>
  );
}

function ThumbnailImageBackground() {
  return (
    <svg
      width="128"
      height="196"
      viewBox="0 0 128 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43769 195.393C3.48217 194.582 1.91797 193.018 1.10704 191.062L1.56891 190.871C1.20249 189.987 1 189.018 1 188V183.909H0.5V175.727H1V167.545H0.5V159.364H1V151.182H0.5V143H1V134.818H0.5V126.636H1V118.455H0.5V110.273H1V102.091H0.5V93.9091H1V85.7273H0.5V77.5454H1V69.3636H0.5V61.1818H1V53H0.5V44.8182H1V36.6364H0.5V28.4545H1V20.2727H0.5V12.0909H1V8C1 6.98214 1.20249 6.01284 1.56891 5.12922L1.10704 4.93769C1.91797 2.98217 3.48217 1.41797 5.4377 0.607044L5.62922 1.06891C6.51284 0.702487 7.48214 0.5 8.5 0.5H12.4643V0H20.3929V0.5H28.3214V0H36.25V0.5H44.1786V0H52.1071V0.5H60.0357V0H67.9643V0.5H75.8929V0H83.8214V0.5H91.75V0H99.6786V0.5H107.607V0H115.536V0.5H119.5C120.518 0.5 121.487 0.702487 122.371 1.06891L122.562 0.607043C124.518 1.41797 126.082 2.98217 126.893 4.9377L126.431 5.12922C126.798 6.01284 127 6.98214 127 8V12.0909H127.5V20.2727H127V28.4545H127.5V36.6364H127V44.8182H127.5V53H127V61.1818H127.5V69.3637H127V77.5455H127.5V85.7273H127V93.9091H127.5V102.091H127V110.273H127.5V118.455H127V126.636H127.5V134.818H127V143H127.5V151.182H127V159.364H127.5V167.545H127V175.727H127.5V183.909H127V188C127 189.018 126.798 189.987 126.431 190.871L126.893 191.062C126.082 193.018 124.518 194.582 122.562 195.393L122.371 194.931C121.487 195.298 120.518 195.5 119.5 195.5H115.536V196H107.607V195.5H99.6786V196H91.75V195.5H83.8214V196H75.8929V195.5H67.9643V196H60.0357V195.5H52.1071V196H44.1786V195.5H36.25V196H28.3214V195.5H20.3929V196H12.4643V195.5H8.5C7.48214 195.5 6.51284 195.298 5.62922 194.931L5.43769 195.393Z"
        fill="white"
      />
      <path
        d="M5.43769 195.393C3.48217 194.582 1.91797 193.018 1.10704 191.062L1.56891 190.871C1.20249 189.987 1 189.018 1 188V183.909H0.5V175.727H1V167.545H0.5V159.364H1V151.182H0.5V143H1V134.818H0.5V126.636H1V118.455H0.5V110.273H1V102.091H0.5V93.9091H1V85.7273H0.5V77.5454H1V69.3636H0.5V61.1818H1V53H0.5V44.8182H1V36.6364H0.5V28.4545H1V20.2727H0.5V12.0909H1V8C1 6.98214 1.20249 6.01284 1.56891 5.12922L1.10704 4.93769C1.91797 2.98217 3.48217 1.41797 5.4377 0.607044L5.62922 1.06891C6.51284 0.702487 7.48214 0.5 8.5 0.5H12.4643V0H20.3929V0.5H28.3214V0H36.25V0.5H44.1786V0H52.1071V0.5H60.0357V0H67.9643V0.5H75.8929V0H83.8214V0.5H91.75V0H99.6786V0.5H107.607V0H115.536V0.5H119.5C120.518 0.5 121.487 0.702487 122.371 1.06891L122.562 0.607043C124.518 1.41797 126.082 2.98217 126.893 4.9377L126.431 5.12922C126.798 6.01284 127 6.98214 127 8V12.0909H127.5V20.2727H127V28.4545H127.5V36.6364H127V44.8182H127.5V53H127V61.1818H127.5V69.3637H127V77.5455H127.5V85.7273H127V93.9091H127.5V102.091H127V110.273H127.5V118.455H127V126.636H127.5V134.818H127V143H127.5V151.182H127V159.364H127.5V167.545H127V175.727H127.5V183.909H127V188C127 189.018 126.798 189.987 126.431 190.871L126.893 191.062C126.082 193.018 124.518 194.582 122.562 195.393L122.371 194.931C121.487 195.298 120.518 195.5 119.5 195.5H115.536V196H107.607V195.5H99.6786V196H91.75V195.5H83.8214V196H75.8929V195.5H67.9643V196H60.0357V195.5H52.1071V196H44.1786V195.5H36.25V196H28.3214V195.5H20.3929V196H12.4643V195.5H8.5C7.48214 195.5 6.51284 195.298 5.62922 194.931L5.43769 195.393Z"
        stroke="#9E9E9E"
        strokeDasharray="8 8"
      />
      <path
        d="M92.1365 106.302L83.8812 97.0073L73.4594 85.2674C73.1073 84.871 72.6651 84.5519 72.1645 84.3327C71.664 84.1136 71.1174 84 70.564 84C70.0106 84 69.464 84.1136 68.9635 84.3327C68.4629 84.5519 68.0207 84.871 67.6686 85.2674L57.2467 97.0073L57.1179 97.1494L53.4614 93.0292C53.1093 92.6328 52.6671 92.3136 52.1665 92.0945C51.6659 91.8754 51.1194 91.7618 50.566 91.7618C50.0126 91.7618 49.466 91.8754 48.9654 92.0945C48.4649 92.3136 48.0227 92.6328 47.6706 93.0292L40.6958 100.888L35.8635 106.302C35.4087 106.811 35.1195 107.429 35.0298 108.085C34.9401 108.741 35.0537 109.407 35.3572 110.005C35.6606 110.603 36.1414 111.108 36.7432 111.462C37.345 111.815 38.0428 112.002 38.7548 112H89.2452C89.9572 112.002 90.655 111.815 91.2568 111.462C91.8586 111.108 92.3394 110.603 92.6428 110.005C92.9463 109.407 93.0599 108.741 92.9702 108.085C92.8805 107.429 92.5913 106.811 92.1365 106.302Z"
        fill="#707070"
      />
    </svg>
  );
}

export default Background;
