import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Plus } from 'react-feather';
import { createId } from '@paralleldrive/cuid2';
import { isEqual } from 'lodash-es';
import { defaultBlocks, defaultConditions } from '../Customers';
import AiTagOptions from './AiTagOptions';
import TagOptions from './TagOptions';
import VisitedSitesOptions from './VisitedSitesOptions';

import {
  BBBButton,
  BBBCard,
  BBBSelect,
  BBBSpinner,
  BBBTextInput,
  IBBBCard,
  MultipleOnValueChangeParam,
  SingleOnValueChangeParam,
} from '@/components';
import {
  Block,
  Condition,
  ConditionConjunction,
  ConditionFilter,
  Errors,
  Filter,
  filters,
  Operator,
  operators,
} from '@/constants/customers';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useHistory from '@/hooks/common/useHistory';
import { useCreateSegment, useSegment } from '@/hooks/customers/segment';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';
import { AiTag, Segments, Tags, VisitedSite } from '@/types/customers';

const mapOperatorKeys: {
  [k in Operator['value']]: Filter['value'][];
} = {
  equals: ['aiTags', 'customerTags', 'visited'],
  notEquals: ['aiTags', 'customerTags'],
  in: ['customerTags'],
  notIn: ['customerTags'],
  contains: [],
  startsWith: [],
  endsWith: [],
};

const mapOperatorToInput: {
  [k in Operator['value']]?: {
    [j in Filter['value']]?:
      | 'text'
      | 'customer-tag-options'
      | 'ai-tag-options'
      | 'visited-options';
  };
} = {
  equals: {
    customerTags: 'customer-tag-options',
    visited: 'visited-options',
    aiTags: 'ai-tag-options',
    firstName: 'text',
    lastName: 'text',
    email: 'text',
    phoneNumber: 'text',
  },
  notEquals: {
    customerTags: 'customer-tag-options',
    aiTags: 'ai-tag-options',
    firstName: 'text',
    lastName: 'text',
    email: 'text',
    phoneNumber: 'text',
  },
  in: { customerTags: 'customer-tag-options' },
  notIn: { customerTags: 'customer-tag-options' },
  contains: {
    firstName: 'text',
    lastName: 'text',
    email: 'text',
    phoneNumber: 'text',
  },
  startsWith: {
    firstName: 'text',
    lastName: 'text',
    email: 'text',
    phoneNumber: 'text',
  },
  endsWith: {
    firstName: 'text',
    lastName: 'text',
    email: 'text',
    phoneNumber: 'text',
  },
};

type Props = {
  segmentId: string | undefined;
  conditions: Condition[];
  blocks: Block[];
  name: string;
  id: string | undefined;
  setConditions: React.Dispatch<React.SetStateAction<Condition[]>>;
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<string | undefined>>;
  initialLoadComplete: boolean;
  setIsInitialLoadComplete: React.Dispatch<React.SetStateAction<boolean>>;
};

function Segmentation({
  segmentId,
  conditions,
  blocks,
  name,
  id,
  setBlocks,
  setConditions,
  setId,
  setName,
  initialLoadComplete,
  setIsInitialLoadComplete,
}: Props) {
  const [errors, setErrors] = useState<Errors>({
    name: false,
    conditions: [],
  });

  const history = useHistory();

  const isSubmitDirty = useRef(false);

  const { mutate: createSegments, isLoading: loadingCreateSegment } =
    useCreateSegment();

  const {
    data: segmentData,
    isInitialLoading: loadingSegmentData,
    status: statusSegment,
  } = useSegment(segmentId);

  const { toggle } = useConfirmationBanner();

  const dataFromApi = useMemo(() => {
    const _conditions = segmentData?.data.query.conditions || defaultConditions;
    const conditions = _conditions.map(
      //@ts-ignore
      (condition) =>
        'key' in condition
          ? {
              ...condition,
              operator: {
                ...condition.operator,
                label:
                  operators.find(
                    (opt) => opt.value === condition.operator.value
                  )?.label || condition.operator.label,
              },
            }
          : condition
    );

    return {
      id: segmentData?.data?.id,
      name: segmentData?.data?.name || '',
      query: {
        conditions,
        blocks: segmentData?.data.query.blocks || defaultBlocks,
      },
    };
  }, [
    segmentData?.data.id,
    segmentData?.data.name,
    segmentData?.data.query.blocks,
    segmentData?.data.query.conditions,
  ]);

  const setDefault = useCallback(() => {
    function set() {
      setId(dataFromApi.id);
      setName(dataFromApi.name);
      setConditions(dataFromApi.query.conditions);
      setBlocks(dataFromApi.query.blocks);
    }
    if (statusSegment === 'success') {
      set();
      setIsInitialLoadComplete(true);
    } else {
      set();
    }
  }, [
    statusSegment,
    setId,
    dataFromApi.id,
    dataFromApi.name,
    dataFromApi.query.conditions,
    dataFromApi.query.blocks,
    setName,
    setConditions,
    setBlocks,
    setIsInitialLoadComplete,
  ]);

  useEffect(() => {
    setDefault();
  }, [setDefault]);

  const handleCreateSegment = useCallback(() => {
    const nameError = !name;
    const hasFilledConditions =
      conditions.filter(
        (condition) =>
          'key' in condition &&
          condition.key &&
          condition.operator &&
          condition.value
      ).length >= 1;

    function submit() {
      createSegments(
        {
          name,
          query: {
            conditions,
            blocks,
          },
          id,
        },
        {
          onSuccess: (data) =>
            history.push({
              pathname: `/customers`,
              search: `?segment=${encodeURIComponent(data.name)}`,
            }),
        }
      );
    }

    if (hasFilledConditions && !nameError) {
      submit();
    } else {
      const conditionErrors = !conditions.length
        ? true
        : conditions
            .filter(
              (condition) =>
                'key' in condition &&
                (!condition.key || !condition.operator || !condition.value)
            )
            .map((_condition) => {
              const condition = _condition as ConditionFilter;
              return { id: condition.id, key: condition.key?.value };
            });

      setErrors(() => ({ name: nameError, conditions: conditionErrors }));

      isSubmitDirty.current = true;

      if (
        (Array.isArray(conditionErrors)
          ? !conditionErrors.length
          : !conditionErrors) &&
        !nameError
      ) {
        submit();
      }
    }
  }, [blocks, conditions, createSegments, history, id, name]);

  useEffect(() => {
    if (initialLoadComplete) {
      const formData: Partial<Segments> = {
        id,
        name,
        query: {
          conditions,
          blocks,
        },
      };

      const isFormEqual = isEqual(formData, dataFromApi);

      toggle('create-segment', {
        show:
          (segmentId !== undefined ? statusSegment === 'success' : true) &&
          !isFormEqual,
        text: !segmentId ? 'Unsaved segmentation' : 'Unsaved changes',
        isCancelable: true,
        cancelLabel: !segmentId ? 'Discard segmentation' : 'Discard changes',
        acceptLabel: !segmentId ? 'Save segmentation' : 'Save changes',
        variant: loadingCreateSegment ? 'loading' : 'actionable',
        onCancel: setDefault,
        onAccept: handleCreateSegment,
      });
    }
  }, [
    blocks,
    conditions,
    dataFromApi,
    handleCreateSegment,
    id,
    initialLoadComplete,
    loadingCreateSegment,
    name,
    segmentData,
    segmentId,
    setDefault,
    statusSegment,
    toggle,
  ]);

  useEffect(() => {
    if (isSubmitDirty.current) {
      setErrors((prev) => ({ ...prev, name: !name }));
    }
  }, [name]);

  useEffect(() => {
    if (isSubmitDirty.current) {
      setErrors((prev) => ({
        ...prev,
        conditions: !conditions.length
          ? true
          : conditions
              .filter((conditionAtCurrent) => {
                return (
                  'key' in conditionAtCurrent &&
                  (!conditionAtCurrent.key?.value ||
                    !conditionAtCurrent.operator?.value ||
                    !conditionAtCurrent.value)
                );
              })
              .map((_cond) => {
                const cond = _cond as ConditionFilter;
                return {
                  id: cond.id,
                  key: cond.key?.value,
                };
              }),
      }));
    }
  }, [conditions]);

  if (loadingSegmentData) {
    return <BBBSpinner />;
  }

  return (
    <BBBCard title="Setup" className="mb-5">
      <BBBTextInput
        placeholder="Enter segmentation name"
        label="Segmentation name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={errors.name ? 'Segmentation name is required' : undefined}
        hasMaxCharLabel
        maxChar={60}
        containerClassname="w-1/2 mb-5"
      />
      <div className="mb-5">
        <div className="font-medium text-primary-main">Condition</div>
      </div>

      <SegmentationInput
        conditions={conditions}
        setConditions={setConditions}
        blocks={blocks}
        setBlocks={setBlocks}
        errors={errors}
      />
      {!conditions.length && (
        <BBBButton
          text={
            <div className="flex items-center gap-2.5">
              Add condition <Plus size={16} />
            </div>
          }
          variant="secondary"
          className="mb-5"
          onClick={() => {
            setConditions(defaultConditions);
            setBlocks(defaultBlocks);
          }}
        />
      )}
      {typeof errors.conditions === 'boolean' && (
        <div className="text-red-500">Add at least 1 condition</div>
      )}
    </BBBCard>
  );
}

export default Segmentation;

type SegmentationInputProps = {
  conditions: Condition[];
  setConditions: React.Dispatch<React.SetStateAction<Condition[]>>;
  parentId?: string;
  blockId?: string;
  blocks: Block[];
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  errors: Errors;
  level?: number;
  index?: number;
};

function SegmentationInput({
  conditions,
  setConditions,
  parentId,
  blocks,
  setBlocks,
  blockId,
  errors,
  level = 0,
  index,
}: SegmentationInputProps) {
  const parentCondition = conditions.find((cond) => cond.id === parentId);
  const conditionsAtCurrentBlock = conditions.filter(
    (condition) =>
      condition.parentId === parentId &&
      ('blockId' in condition ? condition.blockId === blockId : true)
  );

  return (
    <>
      {conditionsAtCurrentBlock.map((condition) => {
        return (
          <Fragment key={condition.id}>
            {!!index && index > 0 && parentCondition && (
              <div className="my-4 flex items-center gap-2">
                <span className="text-neutral-40">
                  {(parentCondition as ConditionConjunction).conjunction}
                </span>
                <div className="grow border-t border-neutral-40"></div>
              </div>
            )}
            <ConditionWrapper
              type={level === 1 ? 'card' : 'div'}
              className="mb-4.5"
            >
              {level === 1 && (
                <div className="flex items-center pb-3 border-b border-neutral-30">
                  <div className="grow font-medium ">Customers who</div>
                  <TrashWithTransition
                    onClick={() => {
                      setConditions((prev) => {
                        const targetFilter = prev.filter(
                          (seg) =>
                            !(
                              seg.id === condition.id ||
                              seg.parentId === condition.id
                            )
                        );
                        if (
                          targetFilter.length === 1 &&
                          'conjunction' in targetFilter[0] &&
                          targetFilter[0].conjunction === 'AND'
                        )
                          return [];

                        return targetFilter;
                      });
                      setBlocks((prev) =>
                        prev.filter((b) => b.conjunctionId !== condition.id)
                      );
                    }}
                  />
                </div>
              )}
              {'key' in condition ? (
                <ConditionWithKey
                  condition={condition}
                  setConditions={setConditions}
                  setBlocks={setBlocks}
                  segmentKeyOptions={filters}
                  errors={errors}
                  conditions={conditions}
                  blockId={blockId}
                  parentId={parentId}
                />
              ) : (
                <ConditionConnector
                  condition={condition}
                  key={condition.id}
                  level={level}
                  setConditions={setConditions}
                  setBlocks={setBlocks}
                  errors={errors}
                  conditions={conditions}
                  blocks={blocks}
                />
              )}
            </ConditionWrapper>
          </Fragment>
        );
      })}
    </>
  );
}

function ConditionWrapper(
  props: (
    | ({ type: 'div' } & JSX.IntrinsicElements['div'])
    | ({ type: 'card' } & IBBBCard)
  ) & {
    children: ReactNode;
  }
) {
  if (props.type === 'div') {
    const { children, type, ...divProps } = props;
    return <div {...divProps}>{children}</div>;
  }

  const { children, type, ...cardProps } = props;

  return <BBBCard {...cardProps}>{children}</BBBCard>;
}

function ConditionConnector({
  condition,
  level,
  setConditions,
  setBlocks,
  errors,
  conditions,
  blocks,
}: {
  condition: ConditionConjunction;
  level: number;
  setConditions: React.Dispatch<React.SetStateAction<Condition[]>>;
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  errors: Errors;
  conditions: Condition[];
  blocks: Block[];
}) {
  const handleAddCondition = (conditionId: string) => {
    if (level === 0) {
      const blockId = createId();
      const orConjunctionId = createId();
      const orBlockId = createId();

      setBlocks((prev) => [
        ...prev,
        {
          conjunctionId: orConjunctionId,
          id: blockId,
        },
        {
          conjunctionId: conditionId,
          id: orBlockId,
        },
      ]);

      setConditions((prev) => [
        ...prev,
        {
          conjunction: 'OR',
          id: orConjunctionId,
          parentId: conditionId,
          blockId: orBlockId,
        },
        {
          key: filters.find((opt) => opt.value === 'customerTags')!,
          operator: operators.find((opt) => opt.value === 'equals')!,
          value: null,
          parentId: orConjunctionId,
          blockId,
          id: createId(),
        },
      ]);
    } else {
      const blockId = createId();
      setBlocks((prev) => [
        ...prev,
        {
          conjunctionId: conditionId,
          id: blockId,
        },
      ]);
      setConditions((prev) => [
        ...prev,
        {
          key: filters.find((opt) => opt.value === 'customerTags')!,
          operator: operators.find((opt) => opt.value === 'equals')!,
          value: null,
          parentId: conditionId,
          blockId,
          id: createId(),
        },
      ]);
    }
  };

  return (
    <div key={condition.conjunction}>
      {blocks
        .filter((b) => b.conjunctionId === condition.id)
        .map((b, index) => (
          <div key={b.id}>
            <SegmentationInput
              conditions={conditions}
              setConditions={setConditions}
              parentId={condition.id}
              blocks={blocks}
              setBlocks={setBlocks}
              blockId={b.id}
              errors={errors}
              level={level + 1}
              index={index}
            />
          </div>
        ))}
      <BBBButton
        text={
          <div className="flex items-center gap-2.5">
            {condition.conjunction} <Plus size={16} />
          </div>
        }
        variant="secondary"
        onClick={() => handleAddCondition(condition.id)}
      />
    </div>
  );
}

function ConditionWithKey({
  condition,
  segmentKeyOptions: _segmentKeyOptions,
  setConditions,
  setBlocks,
  errors,
  conditions,
  blockId,
  parentId,
}: {
  condition: ConditionFilter;
  segmentKeyOptions: Filter[];
  setConditions: React.Dispatch<React.SetStateAction<Condition[]>>;
  errors: Errors;
  conditions: Condition[];
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  blockId?: string;
  parentId?: string;
}) {
  const { key, operator } = condition;

  const segmentKeyOptions = operator
    ? _segmentKeyOptions.filter((_key) =>
        mapOperatorKeys[operator.value].includes(_key.value)
      )
    : _segmentKeyOptions;

  const inputType =
    key?.value && operator?.value
      ? mapOperatorToInput[operator.value]?.[key.value]
      : undefined;

  const handleChangeTagOption = (
    value:
      | MultipleOnValueChangeParam<Tags>
      | SingleOnValueChangeParam<Tags>
      | SingleOnValueChangeParam<AiTag>
      | SingleOnValueChangeParam<VisitedSite>,
    conditionId: string
  ) => {
    setConditions((prev) =>
      prev.map((segmentPrev) => {
        if (segmentPrev.id === conditionId) {
          return {
            ...segmentPrev,
            value: value || null,
          };
        }
        return segmentPrev;
      })
    );
  };

  const handleRemoveCondition = () => {
    setConditions((prev) => prev.filter((seg) => !(seg.blockId === blockId)));
    setBlocks((prev) => prev.filter((b) => b.id !== blockId));
  };

  return (
    <>
      <div className="flex gap-4 items-center mt-3">
        <BBBSelect
          placeholder="Operator"
          options={operators}
          optionLabel="label"
          optionValue="value"
          value={operator}
          onValueChange={(opt) => {
            setConditions((prev) =>
              prev.map((segmentPrev) => {
                if (segmentPrev.id === condition.id) {
                  return {
                    ...segmentPrev,
                    operator: opt || null,
                    key: null,
                  };
                }
                return segmentPrev;
              })
            );
          }}
          containerClassName="w-36"
        />
        <BBBSelect
          placeholder="Choose parameter"
          options={segmentKeyOptions}
          optionLabel="label"
          optionValue="value"
          containerClassName="w-64"
          value={key}
          onValueChange={(opt) => {
            setConditions((prev) =>
              prev.map((segmentPrev) => {
                if (segmentPrev.id === condition.id) {
                  return {
                    ...segmentPrev,
                    key: opt || null,
                    operator:
                      opt?.value === 'aiTags' ||
                      opt?.value === 'customerTags' ||
                      opt?.value === 'visited'
                        ? (segmentPrev as ConditionFilter).operator
                        : null,
                    value: null,
                  };
                }
                return segmentPrev;
              })
            );
          }}
        />
        {inputType &&
          (inputType === 'text' ? (
            <BBBTextInput
              placeholder={`Enter ${key?.value} value`}
              containerClassname="mb-0"
              value={(condition.value as string | null) || ''}
              onChange={({ target: { value } }) => {
                setConditions((prev) =>
                  prev.map((segmentPrev) => {
                    if (segmentPrev.id === condition.id) {
                      return {
                        ...segmentPrev,
                        value,
                      };
                    }
                    return segmentPrev;
                  })
                );
              }}
            />
          ) : inputType === 'customer-tag-options' ? (
            <TagOptions
              {...(condition.operator?.value === 'in' ||
              condition.operator?.value === 'notIn'
                ? {
                    isMulti: true,
                    value: condition.value as Tags[] | null,
                    onValueChange: (opt) =>
                      handleChangeTagOption(opt, condition.id),
                  }
                : {
                    value: condition.value as Tags | null,
                    onValueChange: (opt) =>
                      handleChangeTagOption(opt, condition.id),
                  })}
              containerClassName="w-80"
              isSearchable
              withSearchIcon
            />
          ) : inputType === 'ai-tag-options' ? (
            <AiTagOptions
              value={condition.value as AiTag | null}
              onValueChange={(opt) => handleChangeTagOption(opt, condition.id)}
              containerClassName="w-80"
              isSearchable
            />
          ) : inputType === 'visited-options' ? (
            <VisitedSitesOptions
              value={condition.value as VisitedSite | null}
              onValueChange={(opt) => handleChangeTagOption(opt, condition.id)}
              containerClassName="w-80"
              isSearchable
            />
          ) : null)}
        {conditions.filter(
          (condition) => condition.parentId === parentId && 'key' in condition
        ).length > 1 && (
          <TrashWithTransition
            className="cursor-pointer"
            onClick={() => handleRemoveCondition()}
          />
        )}
      </div>
      <ErrorMsg errors={errors} conditionId={condition.id} />
    </>
  );
}

function ErrorMsg({
  errors,
  conditionId,
}: {
  errors: Errors;
  conditionId: string;
}) {
  if (Array.isArray(errors.conditions)) {
    if (errors.conditions.some((cond) => cond.id === conditionId)) {
      const currentError = errors.conditions.find(
        (cond) => cond.id === conditionId
      );

      if (!currentError?.key) return null;

      return (
        <div className="text-red-500">
          {['customerTags', 'aiTags'].includes(currentError.key)
            ? 'Add at least one customer or AI tag to continue'
            : 'Missing required values'}
        </div>
      );
    }

    return null;
  }

  return <div className="text-red-500">Add at least 1 condition</div>;
}
