import React, { useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Copy, RefreshCw } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import { startCase, truncate } from 'lodash-es';
import ProductItem from './ProductItem';

import { Order } from '@/api/services/customer/order';
import { BBBCard, BBBTag, BBBTooltip } from '@/components';
import { useCompanyIntegrations } from '@/hooks/bitChat/integration/integrations';
import { useOrderHistory } from '@/hooks/customers/order';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import { formatDomainStoreToNameStore } from '@/utils/bitAi';
import { formatCurrency } from '@/utils/common/currency';
import { formatDate2 } from '@/utils/common/date';
import { toast } from '@/utils/common/toast';
import { getOrderName } from '@/utils/customers';

export default function OrderHistory() {
  return <OrderHistory1 />;
}

function OrderHistory1() {
  const isGroup = useIsGroupChat();

  if (isGroup) {
    return (
      <>
        <Header />
        <Info
          message={
            <p className="text-center">
              Order history data are not <br />
              available for this channel
            </p>
          }
        />
      </>
    );
  }

  return <_OrderHistory />;
}

function _OrderHistory() {
  const orderQuery = useOrderHistory();

  return (
    <>
      <Header totalOrder={orderQuery.data?.length} />
      <__OrderHistory data={orderQuery.data} />
    </>
  );
}

function __OrderHistory({ data }: { data: Order[] | undefined }) {
  const [expandedOrders, setExpandedOrders] = useState<Record<string, boolean>>(
    {}
  );
  const [loadMore, setLoadMore] = useState(false);

  const sortedProducts = loadMore ? data : data?.slice(0, 5);

  const ordersLength = data?.length ?? 0;

  if (data?.length === 0) {
    return <Info message="No order history" />;
  }

  return (
    <BBBCard>
      {sortedProducts?.map((order) => {
        const orderName = getOrderName(order);

        const OrderName = () => (
          <>
            Order{' '}
            {orderName.length > 14 ? orderName.slice(0, 7) + '...' : orderName}
          </>
        );

        return (
          <div
            className="cursor-pointer group mb-2"
            onClick={() =>
              setExpandedOrders((prev) => ({
                ...prev,
                [order.id]: !prev[order.id],
              }))
            }
            key={order.id}
          >
            <motion.div className="flex justify-between mt-2">
              <div className="flex gap-1">
                <BBBTooltip
                  content={`Order ${orderName}`}
                  show={orderName.length > 14}
                >
                  {order.orderUrl ? (
                    <a
                      href={order.orderUrl}
                      target="_blank"
                      className="max-w-[9rem] text-secondary-main font-semibold underline break-words"
                      rel="noreferrer"
                    >
                      <OrderName />
                    </a>
                  ) : (
                    <div className="max-w-[9rem] text-secondary-main font-semibold underline break-words">
                      <OrderName />
                    </div>
                  )}
                </BBBTooltip>
                <div className="text-primary-60 whitespace-nowrap">
                  ({formatDate2(order.createdAt)})
                </div>
              </div>
              <div>
                {!expandedOrders[order.id] ? (
                  <ChevronDown className="text-neutral-60 group-hover:text-neutral-50" />
                ) : (
                  <ChevronUp className="text-neutral-60 group-hover:text-neutral-50" />
                )}
              </div>
            </motion.div>

            <AnimatePresence>
              {expandedOrders[order.id] && (
                <>
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {order.fulfillmentStatus && (
                      <BBBTag
                        icon={
                          <div className="rounded-full h-2 w-2 bg-neutral-50" />
                        }
                        text={startCase(order.fulfillmentStatus.toLowerCase())}
                        className="mt-2 w-fit bg-neutral-20"
                      />
                    )}
                    <div className="flex flex-col gap-2 my-2">
                      <p className="text-neutral-50">
                        Created on:{' '}
                        <span className="text-primary-main">
                          {formatDate2(order.createdAt)}
                        </span>
                      </p>
                      <p className="text-neutral-50">
                        Total:{' '}
                        <span className="text-primary-main">
                          {formatCurrency(order.total, order.currency)}{' '}
                        </span>
                      </p>
                    </div>
                    <OrderDetails
                      order={order}
                      expandedOrders={expandedOrders}
                      setExpandedOrders={setExpandedOrders}
                    />
                  </motion.section>
                </>
              )}
            </AnimatePresence>
          </div>
        );
      })}
      {ordersLength >= 5 && <ShowMore show={loadMore} setShow={setLoadMore} />}
    </BBBCard>
  );
}

function OrderDetails({
  order,
  expandedOrders,
  setExpandedOrders,
}: {
  order: Order;
  expandedOrders: Record<string, boolean>;
  setExpandedOrders: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}) {
  const embedCodeRef = useRef<HTMLDivElement>(null);

  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    if (embedCodeRef.current !== null) {
      navigator.clipboard.writeText(embedCodeRef.current.innerText).then(() => {
        setCopySuccess('Copied!');
      });
    }
    e.stopPropagation();
  };

  const lineItems = Array.from(
    order.lineItems?.sort((a, b) => b.price - a.price)
  ).slice(0, expandedOrders[order.id] ? order.lineItems.length : 1);

  return (
    <div className="flex flex-col gap-1">
      <p className="text-primary-main font-semibold ">Order details</p>
      <div className="flex flex-col gap-2">
        {lineItems.map((data) => (
          <ProductItem
            key={data.id}
            setExpandedOrders={setExpandedOrders}
            expandedOrders={expandedOrders}
            order={order}
            product={data.product}
            lineItem={data}
          />
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <p className="text-neutral-50">
          Fullfilled on:{' '}
          <span className="text-primary-main">
            {formatDate2(order.createdAt)}
          </span>
        </p>
        {!!order?.shippingLines.length && (
          <p className="text-neutral-50">
            <span className="text-neutral-50">Tracking Number:</span>
            <span className="inline-flex items-center justify-center">
              <span className="text-primary-main">
                {truncate(String(order.shippingLines[0].carrierIdentifier), {
                  length: 9,
                })}
                <span hidden ref={embedCodeRef}>
                  {order.shippingLines[0].carrierIdentifier}
                </span>
              </span>
              <span className="text-primary-main  mr-2">
                {order.shippingLines[0].title}
              </span>
              <Copy
                className="flex-none cursor-pointer text-[#2B6AAF]"
                width={20}
                height={20}
                onClick={(e) => {
                  copyToClipboard(e);
                  toast.success(`Copied to ${copySuccess} to clipboard`);
                }}
              />
            </span>
          </p>
        )}
      </div>
    </div>
  );
}

function ShowMore({
  show,
  setShow,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="text-info-main flex justify-center">
      {show ? (
        <p
          className="text-center cursor-pointer"
          onClick={() => setShow(!show)}
        >
          Show less
        </p>
      ) : (
        <div
          className="cursor-pointer flex items-center gap-2"
          onClick={() => setShow(!show)}
        >
          <RefreshCw size={16} />
          Load more
        </div>
      )}
    </div>
  );
}

function Header({
  totalOrder,
}: Partial<{
  totalOrder: number;
}>) {
  const companyIntegrationsQuery = useCompanyIntegrations();

  const domainStore = companyIntegrationsQuery?.data?.find(
    (integration) => integration.integration.name === 'shopify'
  )?.extra?.domain;

  return (
    <BBBCard
      title={
        <>
          Order history{' '}
          {!!totalOrder && (
            <span className="w-[20px] flex items-center justify-center h-[20px] bg-primary-main text-neutral-10 text-[0.625rem] rounded-full font-bold">
              {totalOrder}
            </span>
          )}
        </>
      }
      rightTitleButton={
        <>
          <p
            className="text-sm text-neutral-50 underline cursor-pointer"
            onClick={() =>
              window.open(
                `https://admin.shopify.com/store/${formatDomainStoreToNameStore(
                  domainStore
                )}/orders`
              )
            }
          >
            See all orders
          </p>
        </>
      }
    />
  );
}

function Info({ message }: { message: string | React.ReactNode }) {
  return (
    <BBBCard>
      <div className="flex flex-col gap-2 py-2.5 items-center justify-center">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="25" fill="#DDDDDD" />
          <path
            d="M16.6249 23.3132C16.6664 22.8057 16.9008 22.3322 17.2815 21.987C17.6622 21.6417 18.1613 21.4501 18.6794 21.4502H31.1027C31.6207 21.4501 32.1198 21.6417 32.5005 21.987C32.8812 22.3322 33.1156 22.8057 33.1571 23.3132L33.9844 33.4382C34.0072 33.7168 33.971 33.9971 33.8781 34.2613C33.7853 34.5255 33.6377 34.7679 33.4448 34.9734C33.252 35.1788 33.0179 35.3428 32.7573 35.455C32.4968 35.5671 32.2155 35.6251 31.931 35.6252H17.851C17.5666 35.6251 17.2852 35.5671 17.0247 35.455C16.7641 35.3428 16.5301 35.1788 16.3372 34.9734C16.1443 34.7679 15.9968 34.5255 15.9039 34.2613C15.8111 33.9971 15.7749 33.7168 15.7976 33.4382L16.6249 23.3132Z"
            stroke="#757575"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.0119 24.4875V19.425C29.0119 18.3509 28.5777 17.3207 27.8048 16.5612C27.032 15.8017 25.9837 15.375 24.8907 15.375C23.7977 15.375 22.7495 15.8017 21.9766 16.5612C21.2037 17.3207 20.7695 18.3509 20.7695 19.425V24.4875"
            stroke="#757575"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="text-neutral-50">{message}</p>
      </div>
    </BBBCard>
  );
}
