import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import services from '@/api/services';
import { Settings } from '@/api/services/whatsApp/settings';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useSettings = (
  options?: Omit<
    UseQueryOptions<
      Settings,
      unknown,
      Settings,
      (string | number | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-settings', activeCompany],
    () => services.whatsApp.settings.getSettings(activeCompany),
    options
  );
};

export default useSettings;

export const useDefaultFallback = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-settings', activeCompany],
    () => services.whatsApp.settings.getSettings(activeCompany),
    {
      select: (data) =>
        data.fallback?.find((f) => f.name === 'Default Pre-built Fallback'),
    }
  );
};
