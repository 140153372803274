import React, { useMemo, useState } from 'react';
import { ChevronDown, Circle, Edit2 } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { generalAiAgentOption } from 'constants/bitChat/agent';
import { motion } from 'framer-motion';
import { startCase } from 'lodash-es';
import AgentOptions, {
  AgentOption,
} from 'pages/BitChat/components/AgentOptions';
import CustomerNotes from 'pages/Customers/CustomerDetail/DetailCard/Notes';
import { twMerge as cx } from 'tailwind-merge';
import { Ticket } from 'types/whatsApp/ticket';
import TruncatedTags from 'components/TruncatedTags/TruncatedTags';
import { getOrderName } from 'utils/customers';
import TicketTags from '../TicketTags';
import AITagging from './AITagging';
import LineItem from './LineItem';
import Notes from './Notes';

import BroadcastIcon from '@/assets/icons/BroadcastIcon';
import ChatIcon from '@/assets/icons/ChatIcon';
import CopyIcon from '@/assets/icons/CopyIcon';
import EmailIcon from '@/assets/icons/EmailIcon';
import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import LocationIcon from '@/assets/icons/LocationIcon';
import MergeIcon from '@/assets/icons/MergeIcon';
import PhoneIcon from '@/assets/icons/PhoneIcon';
import StarIcon from '@/assets/icons/StarIcon';
import TicketIcon from '@/assets/icons/TicketIcon';
import UserIcon2 from '@/assets/icons/UserIcon2';
import { BBBCard, BBBModal } from '@/components';
import InfoList from '@/components/InfoList/InfoList';
import { Link } from '@/components/Link';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useUserCompanyDetail from '@/hooks/auth/useUserCompanyDetail';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomerByPhoneNumber from '@/hooks/customers/customer/useCustomerByPhoneNumber';
import { useOrderDetail } from '@/hooks/customers/order';
import useTicket from '@/hooks/whatsApp/useTicket';
import useUpdateTicket from '@/hooks/whatsApp/useUpdateTicket';
import { formatUserDisplayName } from '@/utils/auth';
import { formatDate2 } from '@/utils/common/date';
import { formatInternational } from '@/utils/common/phone';
import { toast } from '@/utils/common/toast';
import { formatAddressLocation, formatDisplayName } from '@/utils/customers';

type Props = {
  ticket: Pick<Ticket, 'id' | 'sources'>;
  onHide?: () => void;
};

function TicketModal({ onHide, ticket }: Props) {
  const confirm = useConfirmationModal();

  const [collapsedOrder, setCollapsedOrder] = useState(true);
  const [collapsedTimeline, setCollapsedTimeline] = useState(true);

  const { data: ticketData, isInitialLoading: loadingTicket } = useTicket(
    ticket.sources,
    ticket.id
  );
  const { data: isRestricted } = useIsModuleRestricted(
    'BITCHAT_EDIT_TICKET_DIRECT'
  );

  const ticketAgent = ticketData?.agents?.[0]?.userId;

  const { data: userCompanyData } = useUserCompanyDetail(
    ticketAgent
      ? typeof ticketAgent === 'number' && ticketAgent > 0
        ? ticketAgent?.toString()
        : undefined
      : undefined
  );

  const { data: customerData } = useCustomerByPhoneNumber(
    ticketData?.clientNumber
  );
  const { data: orderData, isInitialLoading: loadingOrder } = useOrderDetail(
    ticketData?.orderId
  );
  const { mutate: updateTicket } = useUpdateTicket();

  const userCompanyDataFormatted = useMemo<AgentOption | undefined>(
    () =>
      //@ts-ignore
      ticketAgent
        ? typeof ticketAgent === 'number'
          ? userCompanyData
            ? {
                ...userCompanyData,
                formattedName: formatUserDisplayName(userCompanyData?.user),
              }
            : generalAiAgentOption
          : undefined
        : undefined,
    [ticketAgent, userCompanyData]
  );

  const addresses = formatAddressLocation(customerData?.addresses);

  return (
    <BBBModal
      title={`Ticket #${ticketData?.id ?? ''}`}
      show
      size="4xl"
      footer
      submitText="Go to chat"
      submitLink={
        ticketData
          ? `/bitchat/livechat?sources=${ticketData.sources}&chatId=${ticketData.clientNumber}`
          : undefined
      }
      disableSave={!ticketData}
      cancelText="Close"
      onHide={onHide}
    >
      <div className="flex md:flex-row flex-col gap-6">
        <div className="flex-1">
          <InfoList withGap labelClassName="xl:w-32" label="Created at">
            <div className="text-neutral-60">
              {ticketData?.createdAt ? formatDate2(ticketData?.createdAt) : '-'}
            </div>
          </InfoList>
          <InfoList withGap labelClassName="xl:w-32" label="Assignee">
            <AgentOptions
              placeholder="Assign ticket"
              value={userCompanyDataFormatted}
              onValueChange={(opt) =>
                confirm({
                  title: `Assign ticket to ${opt!.formattedName}`,
                  description: `Are you sure to assign this ticket to ${
                    opt!.formattedName
                  }? ${
                    opt!.formattedName
                  } will receive notification for this action`,
                  submitText: `Assign to ${opt!.formattedName}`,
                  onAccept: (hide) => {
                    if (opt && 'userId' in opt) {
                      updateTicket(
                        {
                          userId: opt!.userId,
                          source: ticket.sources,
                          ticketId: ticket.id,
                          userAccent: opt!.user.accent,
                          userProfilePicture:
                            opt!.user.profile?.profilePicture || null,
                          userDisplayName: formatUserDisplayName(opt!.user),
                          userEmail: opt!.user.email,
                        },
                        {
                          onSuccess: (_, payload) => {
                            hide();
                            toast.success(
                              `Ticket #${payload.ticketId} assigned to ${
                                opt?.user.profile?.firstName || opt?.user.email
                              }`
                            );
                          },
                        }
                      );
                    }
                  },
                })
              }
              isDisabled={isRestricted}
            />
          </InfoList>
          <InfoList withGap labelClassName="xl:w-32" label="Status">
            <div className="text-neutral-60">{ticketData?.status || '-'}</div>
          </InfoList>
          {!!ticketData?.rating && (
            <InfoList withGap labelClassName="xl:w-32" label="Review">
              <div className="text-neutral-60">{ticketData?.rating || '-'}</div>
            </InfoList>
          )}
          <InfoList withGap labelClassName="xl:w-32" label="Category">
            <TicketTags type="ticket" sources={ticket.sources} id={ticket.id} />
          </InfoList>
          <InfoList withGap labelClassName="xl:w-32" label="Sentiment">
            <div className="text-neutral-60">
              {startCase(ticketData?.sentiment) || '-'}
            </div>
          </InfoList>
          <AITagging withGap tags={ticketData?.aiTags} />
          <InfoList
            labelClassName="xl:w-32"
            withoutDivider
            label="Notes"
            isChildrenNewLine
            withGap
          >
            <Notes
              value={ticketData?.notes || ''}
              id={ticket.id}
              sources={ticket.sources}
              buttonContainerClassName="flex justify-end"
              disabled={isRestricted}
            />
          </InfoList>

          {loadingOrder ? (
            <>
              <div className="flex justify-between items-center mb-3">
                <Skeleton width={200} />
                <Skeleton width={20} />
              </div>
              <Skeleton
                width={90}
                count={2}
                height={20}
                containerClassName="flex items-center gap-3 mb-3"
              />
              <Skeleton width={100} />
              <Skeleton width={200} />
            </>
          ) : (
            !!orderData && (
              <div className="mt-5">
                <div className="flex justify-between items-center mb-3">
                  <div className="text-secondary-main font-semibold underline">
                    Order {getOrderName(orderData)}
                  </div>
                  <ChevronDown
                    className={cx(
                      'cursor-pointer transform transition-transform duration-300',
                      !collapsedOrder && 'rotate-180'
                    )}
                    onClick={() => setCollapsedOrder((prev) => !prev)}
                  />
                </div>
                <div className="flex gap-1 mb-2">
                  {orderData.fulfillmentStatus && (
                    <div className="flex items-center gap-1 p-2 rounded bg-neutral-20">
                      <Circle size={10} />
                      {orderData.fulfillmentStatus}
                    </div>
                  )}
                  {orderData.financialStatus && (
                    <div className="flex items-center gap-1 p-2 rounded bg-neutral-20">
                      <Circle size={10} />
                      {startCase(orderData.financialStatus)}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <span className="text-neutral-50">
                    Created on:{' '}
                    <span className="text-primary-main">
                      {formatDate2(orderData.createdAt)}
                    </span>
                  </span>
                </div>
                <div className="mb-5">
                  <span className="text-neutral-50">
                    Total:{' '}
                    <span className="text-primary-main">
                      {orderData.currency} {orderData.total}
                    </span>
                  </span>
                </div>
                <motion.div
                  animate={{
                    height: collapsedOrder ? 'auto' : 0,
                    opacity: collapsedOrder ? 1 : 0,
                  }}
                  transition={{ type: 'tween', duration: 0.3 }}
                >
                  <div className="text-primary-main font-semibold mb-3">
                    Order details
                  </div>
                  {Array.from(orderData.lineItems).map((lineItem) => (
                    <LineItem
                      currency={orderData.currency}
                      product={lineItem.product}
                      key={lineItem.id}
                      price={lineItem.price}
                      quantity={lineItem.quantity}
                    />
                  ))}
                  {/* <div className="mt-2">
                  <span className="text-neutral-50">
                    Fulfilled on:{' '}
                    <span className="text-primary-main">08/04/2022</span>
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text-neutral-50">
                    Tracking Number:{' '}
                    <span className="text-primary-main">224466884412</span>
                  </span>
                </div> */}
                </motion.div>
              </div>
            )
          )}
        </div>
        <div className="border border-l"></div>
        <div className="flex-1">
          <Link
            to={`/customers/${customerData?.id}`}
            className="text-neutral-70 text-xl mb-4 flex items-center gap-2 hover:underline"
            target="_blank"
          >
            {ticketData?.clientNumber &&
              formatDisplayName(
                ticketData?.clientNumber,
                customerData?.firstName,
                customerData?.lastName
              )}
            <span>
              <LinkOutIcon color="#9E9E9E" size={10} />
            </span>
          </Link>
          <InfoList
            withGap
            labelClassName="xl:w-8"
            withoutDivider
            label={<div className="font-extrabold">ID</div>}
          >
            <div
              className="text-neutral-60 flex items-center gap-1.5 hover:cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(customerData!.id!).then(() => {
                  toast.success('Copied');
                });
              }}
            >
              {customerData?.id ?? '-'}
              {!!customerData?.id && <CopyIcon color="#9E9E9E" />}
            </div>
          </InfoList>
          {customerData?.phoneNumber && (
            <InfoList
              withGap
              labelClassName="xl:w-8"
              withoutDivider
              label={<PhoneIcon />}
            >
              <div
                className="text-neutral-60 flex items-center gap-1.5 hover:cursor-pointer"
                onClick={() => {
                  navigator.clipboard
                    .writeText(customerData!.phoneNumber!)
                    .then(() => {
                      toast.success('Copied');
                    });
                }}
              >
                {formatInternational(customerData?.phoneNumber)}
                {!!customerData?.phoneNumber && <CopyIcon color="#9E9E9E" />}
              </div>
            </InfoList>
          )}
          {customerData?.email && (
            <InfoList
              withGap
              labelClassName="xl:w-8"
              withoutDivider
              label={<EmailIcon />}
            >
              <div
                className="text-neutral-60 flex items-center gap-1.5 hover:cursor-pointer"
                onClick={() => {
                  navigator.clipboard
                    .writeText(customerData!.email!)
                    .then(() => {
                      toast.success('Copied');
                    });
                }}
              >
                {customerData?.email ?? '-'}
                {!!customerData?.email && <CopyIcon color="#9E9E9E" />}
              </div>
            </InfoList>
          )}
          {customerData?.addresses[0]?.city ||
            (customerData?.addresses[0]?.country && (
              <InfoList
                withGap
                labelClassName="xl:w-8"
                withoutDivider
                label={<LocationIcon />}
              >
                <div className="text-neutral-60">{addresses}</div>
              </InfoList>
            ))}
          {!!customerData?.customerTags.length && (
            <div className="mb-5">
              <TruncatedTags
                items={customerData.customerTags.map((tag) => tag.tagName)}
              />
            </div>
          )}

          {customerData && (
            <CustomerNotes
              containerClassName="mb-3"
              id={customerData.id}
              notes={customerData.notes}
              disabled={isRestricted}
            />
          )}

          <div className="flex justify-between items-center mb-4">
            <div className="text-xl">Ticket timeline</div>
            <ChevronDown
              className={cx(
                'cursor-pointer transform transition-transform duration-300',
                !collapsedTimeline && 'rotate-180'
              )}
              onClick={() => setCollapsedTimeline((prev) => !prev)}
            />
          </div>

          {loadingTicket ? (
            <>
              {Array.from({ length: 3 }).map((_, i) => (
                <div className="flex items-center gap-2 mb-6 last:mb-0" key={i}>
                  <Skeleton width={20} height={20} borderRadius={999} />
                  <Skeleton width={200} height={10} className="mr-4" />
                  <Skeleton width={50} height={10} />
                </div>
              ))}
            </>
          ) : (
            <motion.div
              animate={{
                height: collapsedTimeline ? 'auto' : 0,
                opacity: collapsedTimeline ? 1 : 0,
              }}
              transition={{
                type: 'tween',
                duration: 0.3,
              }}
            >
              <Timeline data={ticketData?.timeline ?? []} />
            </motion.div>
          )}
        </div>
      </div>
    </BBBModal>
  );
}

export function Timeline({
  data,
  asCard,
  expandFirstIndex,
  containerClassName,
  connectorClassName,
}: {
  data: {
    description: string | React.ReactNode;
    createdAt: string;
    type: string;
  }[];
  asCard?: boolean;
  expandFirstIndex?: boolean;
  containerClassName?: string;
  connectorClassName?: string;
}) {
  return (
    <>
      {data.map((timeline, index) => (
        <div
          className={cx(
            'w-full flex mb-6 last:mb-0 items-center gap-2',
            containerClassName
          )}
          key={index}
        >
          <div
            className={cx(
              'w-5 h-5 relative rounded-full flex items-center justify-center',
              timeline.type === 'ticket_created' ||
                timeline.type === 'customer_created' ||
                timeline.type === 'customer_edited' ||
                timeline.type === 'conversation_started' ||
                timeline.type === 'campaign_received' ||
                timeline.type === 'rating_given' ||
                timeline.type === 'customer_merged'
                ? 'bg-[#2B6AAF]'
                : timeline.type === 'ticket_rating' ||
                  timeline.type === 'conversation_resolved' ||
                  timeline.type === 'ticket_resolved'
                ? 'bg-success-main'
                : 'bg-neutral-40'
            )}
          >
            {timeline.type === 'customer_created' ? (
              <UserIcon2 />
            ) : timeline.type === 'customer_edited' ? (
              <Edit2 size={8} className="text-neutral-10" />
            ) : timeline.type === 'conversation_started' ||
              timeline.type === 'conversation_resolved' ? (
              <ChatIcon width={10} height={10} />
            ) : timeline.type === 'campaign_received' ? (
              <BroadcastIcon />
            ) : timeline.type === 'customer_merged' ? (
              <MergeIcon />
            ) : timeline.type === 'ticket_rating' ||
              timeline.type === 'rating_given' ? (
              <StarIcon />
            ) : timeline.type === 'ticket_resolved' ||
              timeline.type == 'ticket_created' ? (
              <TicketIcon />
            ) : null}
            {expandFirstIndex && index === 0 && (
              <div
                className={cx(
                  'absolute bottom-[calc(100%+4px)] left-1/2 transform -translate-x-1/2 h-[28px] w-[2px] z-10 bg-neutral-30',
                  connectorClassName
                )}
              ></div>
            )}
            {index < data.length - 1 && (
              <div
                className={cx(
                  'absolute top-6 bottom-0 left-1/2 transform -translate-x-1/2 h-[28px] w-[2px] z-10 bg-neutral-30',
                  connectorClassName
                )}
              ></div>
            )}
          </div>
          {!asCard ? (
            <>
              <div className="grow text-sm">{timeline.description}</div>
              <div className="text-xs border-l p-2 text-neutral-50">
                {formatDate2(timeline.createdAt)}
              </div>
            </>
          ) : (
            <>
              <BBBCard className="w-full flex items-center">
                <div className="grow text-sm">{timeline.description}</div>
                <div className="text-xs border-l p-2 text-neutral-50">
                  {formatDate2(timeline.createdAt)}
                </div>
              </BBBCard>
            </>
          )}
        </div>
      ))}
    </>
  );
}

export default TicketModal;
