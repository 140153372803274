import { ReactNode, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import {
  BBBSelect,
  MultipleOnValueChangeCallback,
  SingleOnValueChangeCallback,
} from '@/components';
import useCreateTag from '@/hooks/customers/customer/useCreateTag';
import useTags from '@/hooks/customers/customer/useTags';
import { Tags } from '@/types/customers';

type Props = (
  | {
      value: Tags | null;
      onValueChange: SingleOnValueChangeCallback<Tags>;
      isMulti?: false;
      renderCustomSelectedValues?: (
        values: Tags | null | undefined
      ) => ReactNode;
    }
  | {
      value: Tags[] | null;
      onValueChange: MultipleOnValueChangeCallback<Tags>;
      isMulti: true;
      renderCustomSelectedValues?: (
        values: Tags[] | null | undefined
      ) => ReactNode;
    }
) & {
  containerClassName?: string;
  placeholder?: string;
  label?: string;
  isSearchable?: boolean;
  isCreatable?: boolean;
  error?: string;
  withClearableOption?: boolean;
  searchOnOptions?: boolean;
  persistShowOptionsOnClick?: boolean;
  withSearchIcon?: boolean;
  sensitive?: boolean;
};

export default function TagOptions({
  containerClassName,
  placeholder = 'Search customer tag',
  isSearchable,
  isCreatable,
  searchOnOptions,
  withSearchIcon,
  sensitive,
  ...props
}: Props) {
  const [search, setSearch] = useState<string>();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: loadingTags,
    isFetching,
  } = useTags({ search, sensitive });
  const { mutate: createTag } = useCreateTag();

  const flatennedData = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <BBBSelect
      options={flatennedData}
      optionLabel="name"
      optionValue="name"
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      isFetchingNextPage={isFetching}
      placeholder={placeholder}
      containerClassName={cx(
        'md:min-w-[107px] md:max-w-[256px]',
        containerClassName
      )}
      loading={loadingTags}
      withoutChevronIcon
      {...(isSearchable
        ? {
            isSearchable: true,
            search,
            onChangeSearch: setSearch,
            searchOnOptions,
            enableToggleOption: true,
            withSearchIcon,
          }
        : {
            isSearchable,
          })}
      {...(isCreatable
        ? {
            isCreatable: true,
            onCreateOption: (val) => {
              createTag(
                { name: val },
                {
                  onSuccess: (data) => {
                    if (props.isMulti) {
                      props.onValueChange([...(props.value ?? []), data]);
                    } else {
                      props.onValueChange(data);
                    }
                  },
                }
              );
            },
          }
        : {
            isCreatable: false,
          })}
      {...props}
    />
  );
}
