import { useEffect } from 'react';
import { Eye } from 'react-feather';
import { useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AuthCard from '../Card';
import SocialLogin from '../SocialLogin';

import { BBBButton, BBBPasswordInput, BBBTextInput } from '@/components';
import { Link } from '@/components/Link';
import { LoginPayload } from '@/hooks/auth/useLogin';
import useCustomForm from '@/hooks/common/useCustomForm';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email should be valid email format')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

type LoginCardProps = {
  data?: { email?: string; password?: string };
  loadingSubmit?: boolean;
  onSubmit: (payload: LoginPayload) => void;
  hideRegisterCTA?: boolean;
  onCreateNewAccount?: () => void;
  isLoginPage?: boolean;
} & (
  | {
      withBack?: false;
      onBack?: never;
    }
  | {
      withBack: true;
      onBack: () => void;
    }
);

const defaultValues = {
  email: '',
  password: '',
};

function LoginCard({
  loadingSubmit,
  data,
  onSubmit,
  hideRegisterCTA,
  onCreateNewAccount,
  withBack,
  onBack,
  isLoginPage,
}: LoginCardProps) {
  const { search } = useLocation();
  const { handleSubmit, formState, control, reset } = useCustomForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (!isLoginPage)
      reset({
        email: data?.email || defaultValues.email,
        password: data?.password || defaultValues.password,
      });
  }, [data, reset, isLoginPage]);

  return (
    <AuthCard
      title={`Welcome to bitbybit`}
      description="Super Apps to grow your e-commerce"
    >
      <SocialLogin onSubmit={onSubmit} loading={loadingSubmit} />
      <div className="flex flex-col gap-2 mb-10">
        <BBBTextInput
          type="email"
          label="Email"
          placeholder="Type your email"
          isHookForm
          disabled={!!data?.email}
          control={control}
          controlName="email"
          error={formState.errors.email?.message}
        />
        <BBBPasswordInput
          id="password"
          type="password"
          label="Password"
          placeholder="Type your password"
          isHookForm
          isFixedSuffix
          fixedSuffixLabel={<Eye cursor="pointer" />}
          control={control}
          controlName="password"
          error={formState.errors.password?.message}
          containerClassname="mb-0"
        />
        <div className="flex justify-end">
          <Link to="/reset-password" className="text-primary-main underline">
            Forgot password?
          </Link>
        </div>
      </div>

      <div className="flex flex-col justify-center gap-2">
        <BBBButton
          loadingState={loadingSubmit}
          text="Login"
          width="full"
          onClick={handleSubmit(onSubmit)}
        />
        {!hideRegisterCTA && (
          <span className="mt-6">
            Don’t have an account?{' '}
            <span className="underline cursor-pointer">
              {onCreateNewAccount ? (
                <span onClick={onCreateNewAccount}>Create a new account</span>
              ) : (
                <Link to={'/register' + search}>Create a new account</Link>
              )}
            </span>
          </span>
        )}
        {withBack && (
          <BBBButton
            variant="secondary"
            text="Back"
            className="mt-2"
            width="full"
            onClick={onBack}
          />
        )}
      </div>
    </AuthCard>
  );
}

export default LoginCard;
