import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import services from '@/api/services';
import { GetAutoReplyParams } from '@/api/services/bitChat/chatbot';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { AutoReply } from '@/types/bitChat/chatbot';
import { ApiResponse } from '@/types/bitCRM/api';

const useInfiniteChatbot = (
  params?: Partial<GetAutoReplyParams>,
  options?: Omit<
    | Omit<
        UseInfiniteQueryOptions<
          ApiResponse<AutoReply>,
          unknown,
          ApiResponse<AutoReply>,
          ApiResponse<AutoReply>,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery<ApiResponse<AutoReply>>(
    ['infinite-chatbot-autoreplies', activeCompany, params],
    ({ pageParam = 0 }) =>
      services.bitChat.chatbot.getAutoReply({
        ...params,
        limit: params?.limit || 10,
        page: pageParam,
        companyId: activeCompany,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.last ? undefined : lastPage.number + 1,
      ...options,
    }
  );
};

export default useInfiniteChatbot;
