import { HelpCircle } from 'react-feather';
import { _PricingInfo } from './PricingInfo';

import CircleCheckIcon from '@/assets/icons/CircleCheckIcon';
import CircleXIcon from '@/assets/icons/CircleXIcon';
import { BBBTooltip } from '@/components/BBBTooltip';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { formatFreeWithUsagePlan } from '@/utils/pricing';

function BitAppStatus() {
  const { data } = usePricingByApp('BITAPP');

  if (!data) return null;

  return (
    <_PricingInfo
      app="BITAPP"
      subscriptionPlan={data.pricingModule.label || undefined}
    >
      <div className="flex items-center gap-2 mb-4">
        <div className="text-neutral-50 text-xs grow">bitbybit logo</div>
        <div className="text-neutral-50 text-xs font-semibold">
          {formatFreeWithUsagePlan(data.pricingName) === 'free' ? (
            <CircleXIcon />
          ) : (
            <CircleCheckIcon />
          )}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="flex items-center grow gap-2">
          <div className="text-neutral-50 text-xs">Product reviews</div>
          <BBBTooltip
            show
            position="right"
            content="Product Reviews Integrations helps you leverage positive customer reviews to boost conversion rates and strengthen your brand."
          >
            <HelpCircle size="0.625rem" />
          </BBBTooltip>
        </div>

        <div className="text-neutral-50 text-xs font-semibold">
          {formatFreeWithUsagePlan(data.pricingName) === 'free' ? (
            <CircleXIcon />
          ) : (
            <CircleCheckIcon />
          )}
        </div>
      </div>
    </_PricingInfo>
  );
}

export default BitAppStatus;
