import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { EditorState } from 'draft-js';
import * as yup from 'yup';
import CampaignMessage from '../../../components/CampaignMessage';
import { CTAButtonType } from '../../../components/CTA';
import CampaignWrapper from '../../../components/Wrapper';
import AIGenerate from './components/AIGenerate';
import ButtonType from './components/ButtonType';
import Footer from './components/Footer';

import {
  BBBCard,
  BBBFileUpload,
  BBBPrimarySwitch,
  BBBSelect,
  BBBSpinner,
  BBBTextInput,
} from '@/components';
import { Redirect } from '@/components/Redirect';
import {
  buttonTypeOptions,
  callToActionsOptions,
} from '@/constants/bitCRM/template';
import { acceptedCrmFileTypes } from '@/constants/crm';
import { ChannelRoutes, mapRoutesToSource } from '@/constants/whatsApp';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import useUpdateWACloudTemplate from '@/hooks/bitChat/settings/useUpdateWACloudTemplate';
import useWACloudTemplate from '@/hooks/bitChat/settings/useWACloudTemplate';
import {
  useCreateOrUpdateTemplate,
  useLanguages,
  useTemplate,
} from '@/hooks/bitCRM/template/template';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useConnectChannelConfirmation } from '@/hooks/whatsApp/channels';
import { useForms } from '@/hooks/whatsApp/form';
import { AvailableSources } from '@/hooks/whatsApp/useAvailableSources';
import {
  ButtonCtaType,
  CampaignTemplate,
  MessageTemplateButtonType,
  MessageTemplateType,
  TemplateBodyParameters,
} from '@/types/bitCRM/template';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { FormTemplate } from '@/types/whatsApp/form';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { convertRemoteUrlToFileType, isTemplateNoAction } from '@/utils/bitCRM';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from '@/utils/common/rich';
import { richValidation } from '@/utils/common/validation';

type ButtonTypeForm = {
  label: string;
  value: MessageTemplateButtonType;
};

type BringCustomerToForm = {
  label: string;
  value: ButtonCtaType;
};

type WhatsAppFormType = {
  metaId: FormTemplate['metaId'];
  name: FormTemplate['name'];
};

export type FormSchema = {
  id?: string;
  campaignName: string;
  fileUrl?: Nullable<FileType>;
  buttons?: CTAButtonType[];
  language?: { label: string; value: string } | null;
  hasRedirect?: boolean;
  body: {
    message: EditorState;
    parameters: TemplateBodyParameters[];
  };
  hasButtonType?: boolean;
  buttonType?: ButtonTypeForm | null;
  buttonText?: string | null;
  whatsAppForm?: WhatsAppFormType | null;
  bringCustomerTo?: BringCustomerToForm | null;
  customUrl?: string | null;
  collection?: string | null;
  product?: string | null;
};

const schema = yup.object().shape({
  id: yup.string(),
  campaignName: yup.string().required().label('Template name'),
  hasRedirect: yup.bool().required(),
  fileUrl: yup.mixed<Nullable<FileType>>(),
  buttons: yup.array().when('hasRedirect', {
    is: true,
    then: (rule) =>
      rule.of(
        yup.object().shape({
          ctaId: yup.string().required(),
          label: yup.string().required().label('Text'),
          value: yup.string().required().label('URL'),
        })
      ),
  }),
  body: yup.object().shape({
    message: richValidation,
    parameters: yup.array().of(
      yup.object().shape({
        value: yup.string().required('Param is required'),
      })
    ),
  }),
  hasButtonType: yup.bool(),
  buttonType: yup
    .mixed<ButtonTypeForm>()
    .label('Button type')
    .when('hasButtonType', {
      is: true,
      then: (rule) => rule.required(),
    }),
  buttonText: yup
    .string()
    .label('Button text')
    .nullable()
    .when('hasButtonType', {
      is: true,
      then: (rule) => rule.required(),
    }),
  whatsAppForm: yup
    .mixed<Pick<FormTemplate, 'id' | 'name'>>()
    .label('Whatsapp form')
    .when('buttonType', {
      is: (val: ButtonTypeForm | null) => val?.value === 'whatsapp_form',
      then: (rule) => rule.required(),
    }),
  bringCustomerTo: yup.mixed<BringCustomerToForm>().when('buttonType', {
    is: (val: ButtonTypeForm | null) => val?.value === 'call_to_action',
    then: (rule) => rule.required(),
  }),
  customUrl: yup
    .string()
    .nullable()
    .label('URL')
    .when('bringCustomerTo', {
      is: (val: BringCustomerToForm | null) => val?.value === 'web_url',
      then: (rule) => rule.required(),
    }),
  collection: yup
    .string()
    .nullable()
    .label('Collection')
    .when('bringCustomerTo', {
      is: (val: BringCustomerToForm | null) => val?.value === 'collections',
      then: (rule) => rule.required(),
    }),
  product: yup
    .string()
    .nullable()
    .label('Product')
    .when('bringCustomerTo', {
      is: (val: BringCustomerToForm | null) => val?.value === 'product',
      then: (rule) => rule.required(),
    }),
});

export const typePayload = (
  type: 'campaign' | 'automation' | 'chat',
  source: ChannelRoutes
) =>
  `${type.toUpperCase()}_${
    mapRoutesToSource[source as ChannelRoutes]
  }` as MessageTemplateType;

const defaultFormSchema: FormSchema = {
  campaignName: '',
  fileUrl: undefined,
  buttons: [
    {
      ctaId: createId(),
      label: '',
      value: '',
    },
  ],
  hasRedirect: false,
  body: {
    message: emptyEditor,
    parameters: [],
  },
  language: null,
  id: undefined,
  hasButtonType: false,
  buttonType: null,
  buttonText: null,
  whatsAppForm: null,
  bringCustomerTo: null,
  customUrl: null,
  collection: null,
  product: null,
};

const formatFormToCRMPayload = (
  data: FormSchema & {
    type: MessageTemplateType;
  }
) =>
  ({
    id: data.id === 'new' ? undefined : data.id,
    templateName: data.campaignName,
    language: data.language?.value,
    ...(data.type.includes('WHATSAPP_OFFICIAL') ||
    data.type.includes('WHATSAPP_CLOUD_API')
      ? {
          footer: 'Powered by bitbybit',
          body: {
            message: convertEditorStateToHtml(data.body.message),
            parameters: data.body.parameters.map((sample) => ({
              sample: sample.placeholder,
            })),
          },
          params: data.body.parameters,
          button: undefined,
          header: data.fileUrl?.remoteUrl
            ? {
                type: 'MEDIA',
                message: 'header message',
                imageUrl: data.fileUrl.remoteUrl,
              }
            : undefined,
          hasButtonType: data.hasButtonType,
          buttonType: data.buttonType?.value,
          buttonText: data.buttonText,
          customUrl: data.customUrl,
          ctaType: data.bringCustomerTo?.value,
          collectionId: data.collection,
          productId: data.product,
          whatsAppFormId: data.whatsAppForm?.metaId,
        }
      : {
          message: convertEditorStateToHtml(data.body.message),
          hasMedia: data.fileUrl ? true : false,
          mediaUrl: data.fileUrl?.remoteUrl,
          buttonLink: undefined,
        }),
    type: data.type,
  } as Partial<CampaignTemplate>);

const formatFormToChatPayload = (
  data: FormSchema
): Partial<WACloudTemplate> => ({
  message: convertEditorStateToHtml(data.body.message),
  language: data.language?.value,
  name: data.campaignName,
});

type ChatProps = {
  source: 'whatsapp-cloud';
  type: 'chat';
};

type CRMProps = {
  source: AvailableSources;
  type: 'campaign' | 'automation';
  data?: CampaignTemplate | undefined;
};

type Props = CRMProps | ChatProps;

export default function WhatsAppTemplate(
  props:
    | {
        source: AvailableSources;
        type?: 'campaign' | 'automation';
      }
    | {
        source: Extract<AvailableSources, 'whatsapp-cloud'>;
        type?: 'chat';
      }
) {
  const query = useQuerySearchParams();

  const type = (query.get('type') || props.type) as Props['type'];

  if (!['campaign', 'automation', 'chat'].includes(type)) {
    throw new Error('Invalid type query specified');
  }

  return <_WhatsAppTemplateCheckType {...({ ...props, type } as Props)} />;
}

function _WhatsAppTemplateCheckType({ ...props }: Props) {
  if (props.type === 'chat') return <_WhatsAppTemplateChat {...props} />;

  return <WhatsAppTemplateDefault {...props} />;
}

function _WhatsAppTemplateChat(props: ChatProps) {
  const { data } = useWACloudTemplate();

  //@ts-ignore
  return <_WhatsAppTemplate {...props} data={data} />;
}

function WhatsAppTemplateDefault(props: CRMProps) {
  return <_WhatsAppTemplateCheckConnection {...props} />;
}

function _WhatsAppTemplateCheckConnection({ ...props }: CRMProps) {
  const { data: whatsAppCloudApiIntegrationData } = useWhatsappCloudIntegration(
    { enabled: props.source === 'whatsapp-cloud' }
  );

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  if (props.source === 'whatsapp-cloud' && !isWhatsAppCloudConnected) {
    return <Redirect to={'/bitcrm/template'} />;
  }

  return <_WhatsAppTemplateCheckTemplateStatus {...props} />;
}

function _WhatsAppTemplateCheckTemplateStatus({ ...props }: CRMProps) {
  const { id } = useParams<{ id: string }>();

  const { data, isInitialLoading: loadingTemplate, status } = useTemplate(id);

  if (loadingTemplate) {
    return <BBBSpinner />;
  }

  if (status === 'success' && isTemplateNoAction(data)) {
    return <Redirect to="/bitcrm/template" />;
  }

  return <_WhatsAppTemplate {...props} data={data} />;
}

function _WhatsAppTemplate({
  source,
  type,
  data,
}:
  | (CRMProps & {
      data?: CampaignTemplate | undefined;
    })
  | (ChatProps & {
      data?: WACloudTemplate | undefined;
    })) {
  const { id } = useParams<{ id: string }>();

  const { data: cloudApiIntegration } = useWhatsappCloudIntegration({
    enabled: type === 'chat',
  });

  const { handleSubmit, formState, control, setValue, watch, reset } =
    useCustomForm<FormSchema>({
      resolver: yupResolver(schema),
      defaultValues: defaultFormSchema,
    });

  const { mutate: updateOrCreate, isLoading: loadingSave } =
    useCreateOrUpdateTemplate();

  const { mutate: updateWACloudTemplate, isLoading: loadingUpdateWACloud } =
    useUpdateWACloudTemplate();

  const { data: languageData, isInitialLoading: isLoadingLanguage } =
    useLanguages({
      enabled: source === 'whatsapp-cloud',
    });

  const whatsAppFormsQuery = useForms();

  const dataFromApi = useMemo<FormSchema>(() => {
    const mediaUrl =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP' ||
          data?.type === 'AUTOMATION_WHATSAPP'
        ? data?.mediaUrl
        : data?.header?.imageUrl;
    const message =
      type === 'chat'
        ? data?.message
        : data?.type === 'CAMPAIGN_WHATSAPP' ||
          data?.type === 'AUTOMATION_WHATSAPP'
        ? data?.message
        : data?.body?.message;
    const id = data?.id;
    const campaignName =
      (type === 'chat' ? data?.name : data?.templateName) || '';
    const language =
      type === 'chat'
        ? languageData?.find((opt) => opt.value === data?.language) || null
        : data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API' ||
          data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API'
        ? languageData?.find((opt) => opt.value === data?.language) || null
        : undefined;
    const buttons =
      type === 'chat'
        ? defaultFormSchema.buttons
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? data.button?.buttons?.length
          ? data?.button.buttons.map((data) => ({
              ctaId: data.ctaType,
              label: data.text,
              value: data.action,
            }))
          : defaultFormSchema.buttons
        : data?.buttonLink
        ? [
            {
              ctaId: createId(),
              label: data.buttonLink.text,
              value: data.buttonLink.action,
            },
          ]
        : defaultFormSchema.buttons;
    const hasRedirect =
      type === 'chat'
        ? false
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? (data.button?.buttons?.length ?? 0) > 0
        : data?.buttonLink
        ? true
        : false;
    const body = {
      message: convertHtmlToEditorState(message),
      parameters:
        type === 'chat'
          ? []
          : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
            data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
          ? data.params || []
          : [],
    };
    const hasButtonType =
      type === 'chat'
        ? false
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? data.hasButtonType
        : false;
    const buttonType =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? buttonTypeOptions.find((opt) => opt.value === data.buttonType) || null
        : null;
    const buttonText =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? data.buttonText
        : null;
    const whatsAppForm =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? whatsAppFormsQuery.data?.find(
            (form) => form.metaId === data.whatsAppFormId
          )
        : null;
    const bringCustomerTo =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? callToActionsOptions.find((opt) => opt.value === data.ctaType)
        : null;
    const customUrl =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? data.customUrl
        : null;
    const collection =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? data.collectionId
        : null;
    const product =
      type === 'chat'
        ? null
        : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? data.productId
        : null;

    return {
      id,
      campaignName,
      fileUrl: mediaUrl ? convertRemoteUrlToFileType(mediaUrl) : undefined,
      language,
      buttons,
      hasRedirect,
      body,
      hasButtonType,
      buttonType,
      buttonText,
      whatsAppForm,
      bringCustomerTo,
      customUrl,
      collection,
      product,
    };
  }, [data, languageData, type, whatsAppFormsQuery.data]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const confirm = useConfirmationModal();
  const confirmConnect = useConnectChannelConfirmation();

  function submitTemplate(data: FormSchema, isDraft: boolean) {
    confirm({
      title: 'Submit template',
      description:
        'Your template will undergo review by Meta and typically takes 1-3 days for approval. Are you ready to submit the template now?',
      onAccept: (hide) => {
        updateWACloudTemplate({ ...formatFormToChatPayload(data), isDraft });
        hide();
      },
      submitText: 'Submit',
    });
  }

  const _handleSave = (
    data: FormSchema,
    isDraft: boolean,
    leaveCb?: () => void
  ) => {
    if (type === 'chat') {
      if (!cloudApiIntegration) {
        confirmConnect('whatsapp_meta', 'template');
      } else {
        submitTemplate(data, isDraft);
      }
    } else {
      const payload = formatFormToCRMPayload({
        ...data,
        id,
        type: typePayload(type, source),
      });

      updateOrCreate({
        ...payload,
        isDraft,
        leaveCb,
      });
    }
  };

  const _handleSubmit = () => {
    handleSubmit((data) => _handleSave(data, false))();
  };

  const handleSubmitDraft = (leaveCb?: () => void) => {
    _handleSave(watch(), true, leaveCb);
  };

  const isMobile = useResponsive('sm');

  return (
    <CampaignWrapper
      title={`${
        type === 'automation'
          ? 'Automation'
          : type === 'campaign'
          ? 'Campaign'
          : 'Chat'
      } - ${
        source === 'whatsapp-business'
          ? 'WhatsApp Business App'
          : source === 'whatsapp-cloud'
          ? 'WhatsApp Cloud API'
          : 'WhatsApp Official API'
      } Template`}
      description={`Prepare your message before you send it to customers`}
      control={control}
      type="template-wa-reply"
    >
      <BBBCard
        id="create-template"
        title="Create template"
        desc="Create your template here. Use variables and media according to your needs."
        className={isMobile ? 'rounded-none' : ''}
      >
        <BBBTextInput
          placeholder="Type your template name"
          label="Template name"
          isHookForm
          control={control}
          controlName="campaignName"
          error={formState.errors.campaignName?.message}
          containerClassname="mb-6"
        />
        {source === 'whatsapp-cloud' && (
          <Controller
            name="language"
            control={control}
            render={({ field: { value, onChange } }) => (
              <BBBSelect
                options={languageData}
                placeholder="Choose language"
                onValueChange={onChange}
                value={value}
                label="Languages"
                optionLabel="label"
                optionValue="value"
                loading={isLoadingLanguage}
                error={formState.errors.language?.message}
                containerClassName="mb-6"
                isSearchable
              />
            )}
          />
        )}

        {type === 'campaign' && (
          <AIGenerate
            onGenerated={(data) =>
              setValue('body', {
                message: convertHtmlToEditorState(data),
                parameters: [],
              })
            }
          />
        )}

        <Controller
          control={control}
          name="body"
          render={({ field }) => (
            <CampaignMessage
              editorState={field.value.message}
              onChangeEditorState={(message) =>
                field.onChange({ ...field.value, message })
              }
              source={source}
              params={field.value.parameters}
              onChangeParams={(parameters) => {
                field.onChange({ ...field.value, parameters });
              }}
              onChange={({ params, editorState }) => {
                field.onChange({
                  ...field.value,
                  parameters: params,
                  message: editorState,
                });
              }}
              withoutDynamicVariable={type === 'chat'}
              //@ts-ignore
              error={formState.errors.body?.message?.message}
              type={
                type === 'automation' || type === 'campaign' ? type : undefined
              }
              //@ts-ignore
              paramErrors={formState.errors.body?.parameters?.map(
                (param) => param?.value?.message || ''
              )}
            />
          )}
        />

        {type !== 'chat' && (
          <div id="template-media" className="mb-5">
            <Controller
              name="fileUrl"
              control={control}
              render={({ field }) => (
                <BBBFileUpload
                  isSingle
                  files={field.value}
                  onChangeFile={field.onChange}
                  accept={acceptedCrmFileTypes}
                  withLabel
                />
              )}
            />
          </div>
        )}

        {source === 'whatsapp-cloud' && type !== 'chat' && (
          <div className="flex flex-col gap-6">
            <Controller
              name="hasButtonType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <BBBPrimarySwitch
                  checked={value}
                  onChange={onChange}
                  label="Button"
                  description="Add reply, redirect, or form button to your campaign"
                />
              )}
            />
            {watch('hasButtonType') && (
              <>
                <Controller
                  name="buttonType"
                  control={control}
                  render={({ field }) => (
                    <BBBSelect
                      options={buttonTypeOptions}
                      placeholder="Select button type"
                      onValueChange={(val) => {
                        field.onChange(val);
                        if (val?.value === 'call_to_action') {
                          setValue('bringCustomerTo', callToActionsOptions[0]);
                        }
                      }}
                      value={field.value}
                      label="Button type"
                      optionLabel="label"
                      optionValue="value"
                      error={formState.errors.buttonType?.message}
                    />
                  )}
                />
                <ButtonType control={control} setValue={setValue} />
              </>
            )}
          </div>
        )}
      </BBBCard>
      <Footer
        control={control}
        dataFromApi={dataFromApi}
        onSubmit={_handleSubmit}
        onSubmitDraft={handleSubmitDraft}
        loadingSave={loadingSave || loadingUpdateWACloud}
      />
    </CampaignWrapper>
  );
}
