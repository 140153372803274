import { BBBCard, BBBModal, CustomModalProp, Link } from '@/components';
import { channelOptions } from '@/constants/bitChat/channel';
import {
  useInstagramIntegrations,
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import { useConnectChannelConfirmation } from '@/hooks/whatsApp/channels';

type Props = {
  isConfirm?: boolean;
} & CustomModalProp;

export default function ChooseChannelModal({ isConfirm, ...props }: Props) {
  return (
    <BBBModal
      title="Choose channel"
      subtitle="Select the channel that you want for your template"
      className="h-[18rem]"
      {...props}
    >
      <div className="flex justify-center flex-col lg:flex-row lg:items-center gap-5">
        {channelOptions.map((option) => {
          const linkTarget = `/bitchat/flows/new?trigger=message&source=${option.value}`;

          if (option.value === 'whatsapp_meta') {
            return (
              <WhatsAppCloudOption
                isConfirm={isConfirm || false}
                linkTarget={linkTarget}
                key={option.value}
              />
            );
          }

          if (option.value === 'whatsapp') {
            return (
              <WhatsAppBusinessOption
                linkTarget={linkTarget}
                key={option.value}
              />
            );
          }

          if (option.value === 'instagram') {
            return (
              <InstagramOption
                isConfirm={isConfirm || false}
                linkTarget={linkTarget}
                key={option.value}
              />
            );
          }

          return null;
        })}
      </div>
    </BBBModal>
  );
}

function WhatsAppCloudOption({
  linkTarget,

  isConfirm,
}: {
  linkTarget: string;
  isConfirm: boolean;
}) {
  const { data } = useWhatsappCloudIntegration();

  const confirm = useConnectChannelConfirmation();
  const connect = useConnectIntegration();

  if (data?.status === 'CONNECTED') {
    return (
      <Link to={linkTarget}>
        <ChannelOption
          {...channelOptions.find(
            (channel) => channel.value === 'whatsapp_meta'
          )!}
        />
      </Link>
    );
  }

  return (
    <ChannelOption
      {...channelOptions.find((channel) => channel.value === 'whatsapp_meta')!}
      onClick={() => {
        isConfirm
          ? confirm('whatsapp_meta', 'Chatbot')
          : connect({ name: 'whatsapp_meta' });
      }}
    />
  );
}

function InstagramOption({
  linkTarget,
  isConfirm,
}: {
  linkTarget: string;
  isConfirm: boolean;
}) {
  const { data } = useInstagramIntegrations();

  const confirm = useConnectChannelConfirmation();
  const connect = useConnectIntegration();

  const channelOption = channelOptions.find(
    (channel) => channel.value === 'instagram'
  )!;

  if (data?.status === 'CONNECTED') {
    return (
      <Link to={linkTarget}>
        <ChannelOption {...channelOption} />
      </Link>
    );
  }

  return (
    <ChannelOption
      {...channelOption}
      onClick={() => {
        isConfirm
          ? confirm('instagram', 'Chatbot')
          : connect({ name: 'instagram' });
      }}
    />
  );
}

function WhatsAppBusinessOption({ linkTarget }: { linkTarget: string }) {
  const { data } = useWhatsappBusinessIntegration();

  const connect = useConnectIntegration();

  const channelOption = channelOptions.find(
    (channel) => channel.value === 'whatsapp'
  )!;

  if (data?.status === 'CONNECTED') {
    return (
      <Link to={linkTarget}>
        <ChannelOption {...channelOption} />
      </Link>
    );
  }

  return (
    <ChannelOption
      {...channelOption}
      onClick={() => {
        connect({ name: 'whatsapp' });
      }}
    />
  );
}

function ChannelOption({
  label,
  icon,
  onClick,
}: typeof channelOptions[number] & {
  onClick?: () => void;
}) {
  return (
    <BBBCard
      className="hover:border-neutral-40 h-36 cursor-pointer w-full lg:w-40 lgh-36 flex flex-col justify-center items-center"
      onClick={onClick}
    >
      <div className="flex-none w-6 h-6">{icon}</div>
      <div className="mt-3 text-center">{label}</div>
    </BBBCard>
  );
}
