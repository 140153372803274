import { ReactNode } from 'react';
import { FileText, Image, MapPin, Slash, Video } from 'react-feather';
import { shallowEqual } from 'react-redux';
import { twMerge as cx } from 'tailwind-merge';
import AgentInfo from './AgentInfo';
import ChatPreviewProfilePicture from './ProfilePicture';

import CheckIconDouble from '@/assets/icons/CheckIconDouble';
import CommentIcon from '@/assets/icons/CommentIcon';
import { BBBRenderHtml } from '@/components';
import BBBBadge from '@/components/BBBBadge/BBBBadge';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { Chat as ChatType } from '@/types/whatsApp/chat';
import { Ticket } from '@/types/whatsApp/ticket';
import { formatWhatsAppTime, toWhatsAppFormat } from '@/utils/bitCRM';
import { formatInternational } from '@/utils/common/phone';

type Props = {
  defaultProfilePicture?: string | ReactNode;
  profilePictureUpdated?: boolean;
  displayName?: string;
  lastMsg?: string;
  timestamp?: string;
  unreadCount?: number;
  onClick?: () => void;
  className?: string;
  agentId?: number;
  tags?: Ticket['tags'];
  clientNumber: ChatType['clientNumber'];
  sources: ChatType['sources'];
  agentAccent?: string;
  agentName?: string;
  agentProfilePicture?: string;
  status?: ChatType['status'];
  lastMessageType?: ChatType['lastMessageType'];
  lastMessageFromCustomer?: ChatType['lastMessageFromCustomer'];
  type?: string;
  messageId?: string;
  ack?: number;
};

export default function Chat({
  defaultProfilePicture,
  profilePictureUpdated,
  displayName,
  lastMsg,
  timestamp,
  unreadCount,
  onClick,
  className,
  agentId,
  tags,
  sources: source,
  agentAccent,
  agentName,
  agentProfilePicture,
  status,
  lastMessageType,
  lastMessageFromCustomer,
  clientNumber,
  type,
  messageId,
  ack,
}: Props) {
  const userId = useUserId();

  const selectedChat = useAppSelector((s) => {
    const selectedChat = s.bitCRM.selectedChat;
    return selectedChat
      ? {
          clientNumber: selectedChat.clientNumber,
          sources: selectedChat.sources,
          messageId: selectedChat.messageId,
        }
      : undefined;
  }, shallowEqual);

  const isTyping = useAppSelector(
    (s) => s.bitCRM.liveChatMemo[`${clientNumber}-${source}`]?.isTyping || false
  );
  const id = selectedChat
    ? [
        `chat-preview`,
        selectedChat.clientNumber === 'ONBOARDING'
          ? 'ONBOARDING'
          : `${selectedChat.clientNumber}_${selectedChat.sources}`,
      ].join('-')
    : undefined;

  return (
    <div
      className={cx(
        `flex gap-4 px-2 pt-2 cursor-pointer overflow-hidden`,
        (
          messageId
            ? selectedChat?.messageId === messageId
            : selectedChat?.clientNumber === clientNumber &&
              selectedChat?.sources === source
        )
          ? 'bg-gray-300/50'
          : 'hover:bg-gray-300/20',
        className
      )}
      onClick={() => {
        onClick?.();
      }}
      id={id}
      tabIndex={0}
    >
      <ChatPreviewProfilePicture
        clientNumber={clientNumber}
        profilePictureUpdated={profilePictureUpdated}
        profilePicture={defaultProfilePicture}
        sources={source}
      />
      <div className="grow flex flex-col relative border-b">
        <div className={cx('flex justify-between gap-3 items-center')}>
          <div className="flex gap-1 items-center justify-center">
            <div className="line-clamp-1 font-bold">
              {formatInternational(displayName)}
            </div>
            {!!tags?.length && (
              <BBBBadge
                type={tags?.[0]?.Tags?.color}
                className="w-[10px] h-[10px] p-0 border-[1.5px]"
              />
            )}
          </div>
          <div className="text-sm text-neutral-50">
            {formatWhatsAppTime(timestamp)}
          </div>
        </div>
        {isTyping ? (
          <span className="text-neutral-50 italic mt-2">User is typing...</span>
        ) : (
          <div className="flex gap-2 mt-2">
            {lastMsg === 'REVOKE' ? (
              <span className="flex-1 flex items-center gap-1 text-[#757575] italic">
                <Slash size={12} /> Message was revoked
              </span>
            ) : (
              <div className="text-[#757575] flex items-center grow gap-1">
                {!lastMessageFromCustomer && (
                  <div className="flex-none">
                    <CheckIconDouble read={ack === 3} />
                  </div>
                )}
                {lastMessageType === 'IMAGE' ? (
                  <>
                    <Image size="1rem" className="flex-none" />{' '}
                    <div className="line-clamp-1">Image</div>
                  </>
                ) : lastMessageType === 'VIDEO' ? (
                  <>
                    <Video size="1rem" className="flex-none" />{' '}
                    <div className="line-clamp-1">Video</div>
                  </>
                ) : lastMessageType === 'DOCUMENT' ? (
                  <>
                    <FileText size="1rem" className="flex-none" />{' '}
                    <div className="line-clamp-1">Document</div>
                  </>
                ) : lastMessageType === 'LOCATION' ||
                  lastMessageType === 'LIVE_LOCATION' ? (
                  <>
                    <MapPin size="1rem" className="flex-none" />{' '}
                    <div className="line-clamp-1">Location</div>
                  </>
                ) : lastMessageType === 'FORM' ||
                  lastMessageType === 'FORM_RESPONSE' ? (
                  <>
                    <FileText size="1rem" className="flex-none" />{' '}
                    <div className="line-clamp-1 italic">
                      {lastMessageType === 'FORM'
                        ? 'WhatsApp form sent'
                        : 'WhatsApp form submitted'}
                    </div>
                  </>
                ) : !lastMessageType || lastMessageType === 'TEXT' ? (
                  <LastMessageText lastMsg={lastMsg} />
                ) : null}
              </div>
            )}
            {unreadCount ? (
              <div
                className={cx(
                  `flex-none font-bold bg-secondary-main rounded-full small text-white flex justify-center items-center`,
                  type === 'comment' ? 'px-2' : 'w-6 h-6'
                )}
              >
                {type === 'comment' ? (
                  <>
                    <CommentIcon width={12} height={12} color={'#ffffff'} />
                    <span className="ml-1">{unreadCount}</span>
                  </>
                ) : (
                  unreadCount
                )}
              </div>
            ) : null}
          </div>
        )}
        {!!agentId &&
          (status === 'Assigned' ? (
            <span className="text-sm italic text-neutral-50 mt-2 mb-2.5">
              Assigned to: {agentId === userId ? 'You' : agentName}
            </span>
          ) : (
            <div className="mt-2 mb-2.5">
              <AgentInfo
                agentId={agentId}
                accent={agentAccent}
                displayName={agentName || undefined}
                profilePicture={agentProfilePicture}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

function LastMessageText({ lastMsg }: { lastMsg: string | undefined }) {
  return (
    <BBBRenderHtml
      className="pointer-events-none line-clamp-1 break-words"
      content={toWhatsAppFormat(lastMsg || '', false)}
    />
  );
}
