import { useMemo } from 'react';
import Progress from '../components/Progress';
import StatusBadge from '../components/StatusBadge';

import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/Link';
import { integrationsMeta } from '@/constants/integrations';
import {
  mapSourceToKnownIntegrations,
  mapSourceToRoutes,
} from '@/constants/whatsApp';
import { Campaign } from '@/types/bitCRM/campaign';
import { formatDate2 } from '@/utils/common/date';

const useColumn = () => {
  return useMemo<HeadersTypes<Campaign>>(() => {
    return [
      {
        accesor: 'campaignName',
        isSortable: true,
        columnWidth: '20%',
        renderHeader: () => (
          <div className="flex gap-4 items-center justify-center">
            Campaign name
          </div>
        ),
        render: (info) => {
          return (
            <div className="flex gap-4 items-center line-clamp-1">
              {info.status === 'DRAFT' || info.status === 'SCHEDULED' ? (
                <Link
                  className="text-primary-main group-hover:underline"
                  to={{
                    pathname: `/bitcrm/campaign/${
                      mapSourceToRoutes[info.type]
                    }/${info.id}`,
                  }}
                >
                  {info.campaignName || '-'}
                </Link>
              ) : info.status === 'SENT' ? (
                <Link
                  className="text-primary-main group-hover:underline"
                  to={{
                    pathname: `/bitcrm/campaign/${
                      mapSourceToRoutes[info.type]
                    }/${info.id}/log`,
                    state: { data: info },
                  }}
                >
                  {info.campaignName}
                </Link>
              ) : (
                <p className="text-primary-main">{info.campaignName}</p>
              )}
            </div>
          );
        },
      },
      {
        accesor: 'type',
        isSortable: true,
        columnWidth: '18%',
        render: (info, value) => (
          <span className="line-clamp-1">
            {integrationsMeta[mapSourceToKnownIntegrations[value]].title}
          </span>
        ),
        renderHeader: () => 'Type',
      },
      {
        accesor: 'status',
        renderHeader: () => 'Status',
        isSortable: true,
        columnWidth: '10%',
        render: (info) => <StatusBadge status={info.status} />,
      },
      {
        accesor: 'analytics',
        isSortable: true,
        renderHeader: () => 'Progress',
        columnWidth: '30%',
        render: (info) => <Progress {...info} />,
      },
      {
        accesor: 'updatedAt',
        renderHeader: () => 'Last updated',
        isSortable: true,
        columnWidth: '18%',
        sortOrder: 'desc',
        render: (info, value) => <>{formatDate2(value)}</>,
      },
    ];
  }, []);
};

export default useColumn;
