import { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { UseMutateFunction } from '@tanstack/react-query';
import SparklesIcon from 'assets/icons/SparklesIcon';
import ThunderIcon from 'assets/icons/ThunderIcon';
import ThunderIcon2 from 'assets/icons/ThunderIcon2';
import { activeTimeOptions } from 'constants/bitChat/flows';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import {
  useFlows,
  useSelectWelcomeAction,
  useToggleFlow,
} from 'hooks/whatsApp/flow';
import { twMerge as cx } from 'tailwind-merge';
import { ChannelIntegrations } from 'types/bitChat/integrations';
import { Flow as FlowType } from 'types/whatsApp/flow';
import ChooseChannelModal from '../components/ChooseChannelModal';
import ShortcutAction, {
  CustomerTagsForm,
  CustomerTagsPayload,
  TicketCategoryForm,
  TicketCategoryPayload,
} from './components/ShortcutAction';

import MoonIcon from '@/assets/icons/MoonIcon';
import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBModal,
  BBBPrimarySwitch,
  BBBSelect,
  BBBSpinner,
} from '@/components';
import NewBadge from '@/components/Badge/NewBadge';
import BBBBadge from '@/components/BBBBadge/BBBBadge';
import SearchInput from '@/components/SearchInput';
import { aiAgentLabel, TRACKO_AGENT_ID } from '@/constants/bitChat/agent';
import {
  ChannelIntegrationValues,
  integrationsMeta,
} from '@/constants/integrations';
import { mapSourceToKnownIntegrations } from '@/constants/whatsApp';
import {
  useInstagramIntegrations,
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import {
  useConnectChannelConfirmation,
  useConnectedToAnyChannels,
  useDefaultConnectedChannels,
} from '@/hooks/whatsApp/channels';
import { ChannelOptions } from '@/pages/Analytics';

const activeTimeFilterOptions = [
  { label: 'All time', value: 'all' },
  ...activeTimeOptions,
];

export default function Flow() {
  const [search, setSearch] = useState('');

  const [time, setTime] = useState<
    typeof activeTimeFilterOptions[number] | null
  >(activeTimeFilterOptions[0]);

  const querySearchParams = useQuerySearchParams();
  const { data: defaultChannelIntegrations } = useDefaultConnectedChannels();

  const channel = (querySearchParams.get('channel') ||
    defaultChannelIntegrations) as ChannelIntegrationValues;

  const queryKey = {
    search,
    channel: channel,
    time: time?.value === 'all' ? undefined : time?.value,
  };

  const { data: flows, status } = useFlows(queryKey, {
    enabled: !!defaultChannelIntegrations,
  });

  const { data: waCloudIntegration } = useWhatsappCloudIntegration();
  const { data: waBusinessIntegration } = useWhatsappBusinessIntegration();
  const { data: instagramIntegration } = useInstagramIntegrations();
  const confirmConnect = useConnectChannelConfirmation();

  const { mutate: toggleFlow } = useToggleFlow(queryKey);

  const [step, setStep] = useState<number | null>(null);

  const shownFlows = flows?.filter(
    (flow) => !['welcome_action', 'away_message'].includes(flow.triggerType)
  );

  const chosenWelcomeAction = flows
    ? getChosenWelcomeAction(flows, 'welcome_action')
    : undefined;

  const chosenAwayAction = flows
    ? getChosenWelcomeAction(flows, 'away_message')
    : undefined;

  const isWelcomeActionActive =
    flows?.find((flow) => flow.triggerType === 'welcome_action')?.status ===
    'ACTIVE';

  const isAwayActionActive =
    flows?.find((flow) => flow.triggerType === 'away_message')?.status ===
    'ACTIVE';

  return (
    <BBBContainer
      pageTitle={
        <div className="flex items-center gap-2">
          Chatbot <NewBadge />
        </div>
      }
      pageDescription="Automate your message by creating a chatbot"
      hasHeading
      rightComponent={
        <div className="flex items-center gap-3">
          <ChannelOptions />
          <BBBButton
            text="Create new chatbot"
            icon={<Plus />}
            iconPosition="right"
            onClick={() => setStep(2)}
          />
        </div>
      }
    >
      {step === 2 && (
        <ChooseChannelModal show isConfirm onHide={() => setStep(null)} />
      )}
      {status === 'loading' ? (
        <div className="flex justify-center flex-col gap-1 items-center">
          <BBBSpinner height={16} />
          Loading more data
        </div>
      ) : (
        <>
          <WelcomeMessage
            flows={flows}
            toggleFlow={toggleFlow}
            source={queryKey.channel}
            chosenWelcomeAction={chosenWelcomeAction}
            chosenAwayAction={chosenAwayAction}
          />
          <BBBCard>
            {!shownFlows?.length ? (
              <div className="py-24">
                <div className="flex justify-center mb-6">
                  <div className="bg-neutral-30 w-32 h-32 flex items-center justify-center rounded-full">
                    <ThunderIcon2 />
                  </div>
                </div>
                <div className="text-center text-neutral-40 text-xl mb-6">
                  Create new chatbot to optimize your customer experience
                </div>
                <div className="flex justify-center">
                  <BBBButton
                    text="Create new chatbot"
                    onClick={() => setStep(2)}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center mb-5 gap-2">
                  <SearchInput
                    placeholder="Search chatbot"
                    containerClassName="grow"
                    value={search}
                    onValueChange={setSearch}
                  />
                  <BBBSelect
                    options={activeTimeFilterOptions}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Time"
                    className="w-36"
                    value={time}
                    onValueChange={(value) => setTime(value || null)}
                  />
                </div>
                {shownFlows.map((flow) => {
                  const Icon =
                    integrationsMeta[mapSourceToKnownIntegrations[flow.channel]]
                      .icon;

                  return (
                    <Link
                      key={flow.id}
                      className="flex group items-center gap-3 pb-3 border-b border-[#EBEBEB] mb-5 last:mb-0"
                      to={`/bitchat/flows/${
                        flow.id
                      }?${querySearchParams.toString()}`}
                    >
                      {flow.status === 'DRAFT' ? (
                        <BBBBadge text="Draft" type="info" />
                      ) : flow.triggerType === 'message' ? (
                        <ThunderIcon />
                      ) : flow.triggerType === 'welcome_action' ? null : (
                        <SparklesIcon color="#8F68C0" />
                      )}
                      <div className="grow flex items-center gap-2">
                        <div className="text-xl group-hover:underline">
                          {flow.name}
                        </div>
                        {chosenWelcomeAction &&
                          'flow' in chosenWelcomeAction &&
                          chosenWelcomeAction.flow.id === flow.id &&
                          isWelcomeActionActive && (
                            <BBBBadge type="info" text="Welcome action" />
                          )}
                        {chosenAwayAction &&
                          'flow' in chosenAwayAction &&
                          chosenAwayAction.flow.id === flow.id &&
                          isAwayActionActive && (
                            <BBBBadge type="ai" text="Away action" />
                          )}
                      </div>
                      <div className="flex-none">{Icon && <Icon />}</div>
                      <BBBPrimarySwitch
                        checked={flow.status === 'ACTIVE'}
                        onChange={(value) => {
                          if (
                            value &&
                            ((flow.channel === 'WHATSAPP' &&
                              waBusinessIntegration?.status !== 'CONNECTED') ||
                              (flow.channel === 'WHATSAPP_META' &&
                                waCloudIntegration?.status !== 'CONNECTED') ||
                              (flow.channel === 'INSTAGRAM' &&
                                instagramIntegration?.status !== 'CONNECTED'))
                          ) {
                            if (flow.channel === 'INSTAGRAM') {
                              confirmConnect('instagram', 'Chatbot');
                            } else if (flow.channel === 'WHATSAPP') {
                              confirmConnect('whatsapp', 'Chatbot');
                            } else if (flow.channel === 'WHATSAPP_META') {
                              confirmConnect('whatsapp_meta', 'Chatbot');
                            }
                          } else {
                            toggleFlow({
                              id: flow.id,
                              status: value ? 'ACTIVE' : 'INACTIVE',
                              name: flow.name,
                            });
                          }
                        }}
                      />
                    </Link>
                  );
                })}
              </>
            )}
          </BBBCard>
        </>
      )}
    </BBBContainer>
  );
}

type ToggleFlowMutation = UseMutateFunction<
  FlowType,
  unknown,
  Pick<FlowType, 'id' | 'name' | 'status'>,
  {
    previousFlowsData: unknown;
    previousFlowData: unknown;
  }
>;

type WelcomeActionData = (
  | {
      flow: {
        id: string;
        name: string;
        status: FlowType['status'];
      };
    }
  | {
      agent: {
        id: number;
        name: string;
      };
    }
) & {
  ticketCategory?: TicketCategoryPayload;
  customerTags?: CustomerTagsPayload;
};

function WelcomeMessage({
  flows,
  toggleFlow,
  source,
  chosenAwayAction,
  chosenWelcomeAction,
}: {
  flows?: FlowType[];
  toggleFlow: ToggleFlowMutation;
  source: ChannelIntegrations;
  chosenWelcomeAction?: WelcomeActionData;
  chosenAwayAction?: WelcomeActionData;
}) {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<
    'welcome-message' | 'away-message'
  >();

  return (
    <BBBCard className="mb-5 flex items-center gap-5">
      {showWelcomeMessage && (
        <WelcomeMessageModal
          type={showWelcomeMessage}
          onHide={() => setShowWelcomeMessage(undefined)}
          source={source}
          flows={flows}
          chosenAwayAction={chosenAwayAction}
          chosenWelcomeAction={chosenWelcomeAction}
        />
      )}
      <WelcomeMessageCard
        type="welcome-message"
        onClick={() => setShowWelcomeMessage('welcome-message')}
        flows={flows}
        toggleFlow={toggleFlow}
        data={chosenWelcomeAction}
        channel={source}
      />
      <WelcomeMessageCard
        type="away-message"
        onClick={() => setShowWelcomeMessage('away-message')}
        flows={flows}
        toggleFlow={toggleFlow}
        data={chosenAwayAction}
        channel={source}
      />
    </BBBCard>
  );
}

function WelcomeMessageCard({
  type,
  onClick,
  flows,
  toggleFlow,
  data,
  channel,
}: {
  type: 'welcome-message' | 'away-message';
  onClick: () => void;
  flows?: FlowType[];
  toggleFlow: ToggleFlowMutation;
  data?: WelcomeActionData;
  channel: ChannelIntegrations;
}) {
  const { data: connectedToChannel, isLoading: loadingCheckConnection } =
    useConnectedToAnyChannels(channel);
  const confirmConnect = useConnectChannelConfirmation();
  const { data: settingsData } = useSettings();
  const confirm = useConfirmationModal();

  const isOperationalHourSetup =
    settingsData?.operationalHours &&
    (((settingsData.operationalHoursType === 'weekday' ||
      settingsData.operationalHoursType === 'everyday') &&
      settingsData.operationalHourStart &&
      settingsData.operationalHourEnd) ||
      (settingsData.operationalHoursType === 'custom' &&
        settingsData.operationHourCustomDays?.length));

  const promptEnableOperationHours = () => {
    confirm({
      title: "You haven't set up operational hours",
      description:
        "In order to activate away message, you need to set up your company's operational hours",
      submitText: 'Set operational hours',
      submitLink: '/bitchat/settings?section=ops-hours',
      onAccept: (hide) => {
        hide();
      },
    });
  };

  const activeFlow = data
    ? 'flow' in data
      ? flows?.find((flow) => data.flow.id === flow.id)
      : data.agent.id === TRACKO_AGENT_ID
    : undefined;

  const welcomeActionFlow = flows?.find((flow) =>
    type === 'welcome-message'
      ? flow.triggerType === 'welcome_action'
      : flow.triggerType === 'away_message'
  );

  function validateCb(cb: () => void) {
    if (!connectedToChannel) {
      confirmConnect(channel, 'chatbot');
    } else {
      if (type === 'away-message') {
        if (!isOperationalHourSetup) {
          promptEnableOperationHours();
        } else {
          cb();
        }
      } else {
        cb();
      }
    }
  }

  if (loadingCheckConnection) {
    return (
      <Skeleton
        height={'6rem'}
        width={'100%'}
        containerClassName="grow"
        borderRadius={'0.5rem'}
      />
    );
  }

  return (
    <div
      onClick={() => {
        validateCb(onClick);
      }}
      className={cx(
        'flex-1 group rounded-lg transition hover:outline-[1.5px] outline-1 outline p-6 flex gap-4 cursor-pointer',
        type === 'welcome-message'
          ? 'outline-info-main hover:bg-info-hover'
          : 'outline-ai-main hover:bg-ai-hover'
      )}
    >
      <div className="flex-none">
        {type === 'welcome-message' ? <WelcomeMessageIcon /> : <MoonIcon />}
      </div>
      <div>
        <div className="flex items-center gap-1.5 mb-1.5">
          <div className="text-xl text-primary-main font-medium">
            {type === 'welcome-message' ? 'Welcome action' : 'Away action'}
          </div>
          <div className="opacity-0 transition group-hover:opacity-100">
            <SettingsIcon />
          </div>
        </div>
        <div className="text-neutral-50 text-sm">
          {type === 'welcome-message'
            ? 'This message welcomes new contacts when they reach out for the first time. It will trigger every 12 hours.'
            : 'This message will be triggered when your contact send a message outside of your operational hours.'}
        </div>
      </div>
      <div className="self-center">
        <BBBPrimarySwitch
          checked={welcomeActionFlow?.status === 'ACTIVE'}
          onChange={(val) => {
            validateCb(() => {
              if (activeFlow && welcomeActionFlow) {
                toggleFlow({
                  id: welcomeActionFlow.id,
                  status: val ? 'ACTIVE' : 'INACTIVE',
                  name: welcomeActionFlow.name,
                });
              } else {
                onClick();
              }
            });
          }}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
}

const aiAgentOptions = [
  { name: aiAgentLabel('Assign to AI Agent'), id: TRACKO_AGENT_ID },
];

function WelcomeMessageModal({
  type,
  onHide,
  source,
  flows: __flows,
  chosenAwayAction,
  chosenWelcomeAction,
}: {
  type: 'welcome-message' | 'away-message';
  onHide: () => void;
  source: ChannelIntegrations;
  flows?: FlowType[];
  chosenAwayAction?: WelcomeActionData;
  chosenWelcomeAction?: WelcomeActionData;
}) {
  const [selectedFlow, setSelectedFlow] = useState<
    | { name: string; id: string }
    | {
        name: JSX.Element;
        id: number;
      }
    | null
  >();

  const [ticketCategory, setTicketCategory] =
    useState<TicketCategoryForm>(null);
  const [customerTags, setCustomerTags] = useState<CustomerTagsForm>(null);

  const { mutate: selectWelcomeAction, isLoading: loadingSelectWelcomeAction } =
    useSelectWelcomeAction();

  const reservedFlows = [
    ...(chosenAwayAction && 'flow' in chosenAwayAction
      ? [chosenAwayAction.flow.id]
      : []),
    ...(chosenWelcomeAction && 'flow' in chosenWelcomeAction
      ? [chosenWelcomeAction.flow.id]
      : []),
  ];

  const _flows = __flows?.filter(
    (flow) => !['welcome_action', 'away_message'].includes(flow.triggerType)
  );

  const flows = _flows
    ? [
        ...aiAgentOptions,
        ...(reservedFlows.length
          ? _flows.filter((flow) => !reservedFlows.includes(flow.id))
          : _flows),
      ]
    : undefined;

  useEffect(() => {
    if (type === 'away-message' && chosenAwayAction) {
      setSelectedFlow(
        'flow' in chosenAwayAction
          ? chosenAwayAction.flow
          : chosenAwayAction.agent.id === TRACKO_AGENT_ID
          ? aiAgentOptions[0]
          : null
      );

      setTicketCategory(chosenAwayAction.ticketCategory);
      setCustomerTags(chosenAwayAction.customerTags);
    }
  }, [chosenAwayAction, type]);

  useEffect(() => {
    if (type === 'welcome-message' && chosenWelcomeAction) {
      setSelectedFlow(
        'flow' in chosenWelcomeAction
          ? chosenWelcomeAction.flow
          : chosenWelcomeAction.agent.id === TRACKO_AGENT_ID
          ? aiAgentOptions[0]
          : null
      );

      setTicketCategory(chosenWelcomeAction.ticketCategory);
      setCustomerTags(chosenWelcomeAction.customerTags);
    }
  }, [chosenWelcomeAction, type]);

  return (
    <BBBModal
      show
      title={
        type === 'welcome-message'
          ? 'Choose welcome action'
          : 'Choose away action'
      }
      onHide={onHide}
      footer
      submitText="Save"
      disableSave={!selectedFlow?.id}
      handleSave={() => {
        selectWelcomeAction(
          {
            id: selectedFlow!.id,
            type: type === 'away-message' ? 'away_message' : 'welcome_message',
            source,
            customerTags:
              selectedFlow!.id !== TRACKO_AGENT_ID
                ? null
                : customerTags?.length
                ? customerTags.map((tag) => ({
                    id: tag.id,
                    name: tag.name,
                  }))
                : null,
            ticketCategory:
              selectedFlow!.id !== TRACKO_AGENT_ID
                ? null
                : ticketCategory
                ? { label: ticketCategory.label, id: ticketCategory.id }
                : null,
          },
          {
            onSuccess: () => {
              onHide();
            },
          }
        );
      }}
      loadingSave={loadingSelectWelcomeAction}
    >
      <BBBSelect
        placeholder={
          type === 'welcome-message'
            ? 'Choose welcome action'
            : 'Choose away action'
        }
        label={
          type === 'away-message'
            ? 'During outside operation hours, run this flow:'
            : undefined
        }
        //@ts-ignore
        options={flows}
        optionLabel="name"
        optionValue="id"
        value={selectedFlow}
        onValueChange={setSelectedFlow}
      />
      {selectedFlow?.id === TRACKO_AGENT_ID && (
        <ShortcutAction
          customerTags={customerTags}
          ticketCategory={ticketCategory}
          onChangeCustomerTags={setCustomerTags}
          onChangeTicketCategory={setTicketCategory}
          {...(type === 'welcome-message'
            ? {
                defaultCustomerTags: chosenWelcomeAction?.customerTags,
                defaultTicketCategory: chosenWelcomeAction?.ticketCategory,
              }
            : {
                defaultCustomerTags: chosenAwayAction?.customerTags,
                defaultTicketCategory: chosenAwayAction?.ticketCategory,
              })}
        />
      )}
    </BBBModal>
  );
}

function WelcomeMessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="24"
      fill="none"
      viewBox="0 0 18 24"
    >
      <path
        fill="#2699FB"
        d="M0 3.95v16.1c0 .305.126.597.351.813.226.215.53.337.849.337h3.6v-2.3H2.4V5.1h2.4V2.8H1.2c-.318 0-.623.12-.849.337A1.126 1.126 0 000 3.95zm17.09-1.116l-9.6-2.3a1.25 1.25 0 00-1.029.21 1.16 1.16 0 00-.34.402A1.11 1.11 0 006 1.65v20.7c0 .174.04.347.12.504.08.158.197.295.34.403a1.25 1.25 0 001.03.208l9.6-2.3c.26-.062.49-.205.655-.407A1.12 1.12 0 0018 20.05V3.95c0-.257-.09-.506-.255-.708a1.206 1.206 0 00-.655-.408zM12 12.216a1.13 1.13 0 01-.37.78 1.23 1.23 0 01-.83.32 1.23 1.23 0 01-.83-.32 1.13 1.13 0 01-.37-.78v-.434c0-.305.127-.597.352-.813.225-.215.53-.336.849-.336.318 0 .623.121.848.337.225.216.351.508.351.813v.433z"
      ></path>
    </svg>
  );
}

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="#9E9E9E"
        d="M8.009.188c.55.006 1.099.07 1.636.19a.563.563 0 01.437.486l.127 1.145a1.038 1.038 0 001.446.837l1.05-.461a.562.562 0 01.637.13 7.35 7.35 0 011.654 2.844.562.562 0 01-.204.62l-.93.686a1.034 1.034 0 000 1.67l.931.686a.563.563 0 01.204.62 7.349 7.349 0 01-1.653 2.844.562.562 0 01-.636.13l-1.055-.462a1.036 1.036 0 00-1.444.835l-.128 1.145a.563.563 0 01-.428.485 7.127 7.127 0 01-3.305 0 .564.564 0 01-.429-.485l-.126-1.143a1.037 1.037 0 00-1.444-.832l-1.055.462a.562.562 0 01-.637-.132 7.35 7.35 0 01-1.652-2.847.563.563 0 01.204-.62l.932-.686a1.035 1.035 0 000-1.67l-.932-.685a.563.563 0 01-.204-.62 7.35 7.35 0 011.653-2.844.563.563 0 01.638-.13l1.05.461a1.04 1.04 0 001.447-.838L5.921.864a.562.562 0 01.437-.487 8.073 8.073 0 011.65-.19zm0 1.125a6.75 6.75 0 00-1.016.087l-.082.733a2.165 2.165 0 01-3.017 1.744l-.674-.295a6.225 6.225 0 00-1.01 1.737l.598.44a2.16 2.16 0 010 3.482l-.599.441c.24.632.582 1.22 1.011 1.742l.679-.298a2.161 2.161 0 013.013 1.739l.081.738c.668.112 1.35.112 2.016 0l.083-.738a2.16 2.16 0 013.013-1.742l.678.297c.43-.52.77-1.108 1.011-1.738l-.598-.441a2.16 2.16 0 010-3.482l.597-.44a6.225 6.225 0 00-1.011-1.739l-.673.295a2.163 2.163 0 01-3.017-1.743L9.01 1.4a6.76 6.76 0 00-1.001-.087zm-.01 3.375a2.813 2.813 0 110 5.625 2.813 2.813 0 010-5.626zm0 1.125a1.688 1.688 0 100 3.375 1.688 1.688 0 000-3.376z"
      ></path>
    </svg>
  );
}

function getChosenWelcomeAction(
  flows: FlowType[],
  type: 'welcome_action' | 'away_message'
): WelcomeActionData | undefined {
  return flows.find(
    (flow) =>
      flow.triggerType === type &&
      flow.nodes.length &&
      flow.nodes[0].data &&
      ((flow.nodes[0].jobType === 'run_flow' && flow.nodes[0].data.flow) ||
        (flow.nodes[0].jobType === 'assign_ticket' && flow.nodes[0].data.agent))
  )?.nodes[0].data;
}
