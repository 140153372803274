import { useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { PlusCircle, Trash2 } from 'react-feather';
import {
  Control,
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '../..';

import BBBDraggableIcon from '@/assets/icons/BBBDraggableIcon';
import { BBBCard, BBBPrimarySwitch, BBBTextInput } from '@/components';
import useInfiniteChatbot from '@/hooks/bitChat/chatbot/useInfiniteChatbot';
import QuickResponseOptions from '@/pages/BitChat/WaWidget/components/QuickResponse/QuickResponseOptions';
import { AutoReply } from '@/types/bitChat/chatbot';

type Props = {
  control: Control<FormSchema>;
  loading: boolean;
};

export default function QuickResponses({ control, loading }: Props) {
  const enabled = useWatch({
    control,
    name: 'quickResponseEnabled',
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'quickResponses',
  });

  const { errors } = useFormState({
    control,
  });

  const {
    data: chatbotData,
    fetchNextPage: fetchNextPageChatbot,
    hasNextPage: hasNextPageChatbot,
  } = useInfiniteChatbot();

  const unifiedChatbotData = useMemo<AutoReply[] | undefined>(
    () => chatbotData?.pages.flatMap((page) => page.content),
    [chatbotData?.pages]
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;
      if (!destination) return;

      move(source.index, destination.index);
    },
    [move]
  );

  return (
    <BBBCard
      title="Quick responses"
      className="mb-cardBottom"
      desc="Answer your customer message automatically with chatbot"
      rightButton={
        <>
          {loading ? (
            <Skeleton width={100} height={20} />
          ) : (
            <Controller
              control={control}
              name="quickResponseEnabled"
              render={({ field }) => (
                <BBBPrimarySwitch
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          )}
        </>
      }
      id="widget-quick-responses"
    >
      <div
        className={cx(
          'flex flex-col',
          enabled ? 'opacity-100' : 'opacity-25 cursor-not-allowed'
        )}
      >
        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
          <Droppable droppableId="quick-response-id">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="flex items-center gap-5 mb-3">
                  <div className="opacity-0 bg-red-100">
                    <BBBDraggableIcon color="#CACACA" />
                  </div>
                  <p className="text-neutral-70 flex-1 font-medium">
                    Trigger button text
                  </p>
                  <p className="text-neutral-70 flex-1 font-medium">
                    Trigger in chatbot
                  </p>
                  <div className="opacity-0">
                    <Trash2 />
                  </div>
                </div>
                {fields.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={`flex gap-5 w-full mb-3 items-center ${
                          snapshot.isDragging && 'opacity-50'
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div {...provided.dragHandleProps}>
                          <BBBDraggableIcon color="#CACACA" />
                        </div>
                        <div className="w-full">
                          <BBBTextInput
                            placeholder="Enter trigger button text"
                            containerClassname="mb-0"
                            disabled={!enabled}
                            isHookForm
                            control={control}
                            controlName={`quickResponses.${index}.buttonText`}
                            error={
                              errors.quickResponses?.[index]?.buttonText
                                ?.message
                            }
                          />
                        </div>
                        <div className="w-full">
                          <Controller
                            control={control}
                            name={`quickResponses.${index}.chatbot`}
                            render={({ field }) => {
                              return (
                                <QuickResponseOptions
                                  id={
                                    item.chatbot ? item.chatbot.id : undefined
                                  }
                                  placeholder="Select chatbot"
                                  options={unifiedChatbotData || []}
                                  optionValue="id"
                                  optionLabel="groupName"
                                  isPaginated
                                  value={field.value}
                                  onValueChange={(val) => {
                                    field.onChange(val);
                                  }}
                                  hasMore={!!hasNextPageChatbot}
                                  fetchNext={fetchNextPageChatbot}
                                  error={
                                    errors.quickResponses?.[index]?.chatbot
                                      ?.message
                                  }
                                />
                              );
                            }}
                          />
                        </div>
                        <div>
                          <Trash2
                            height={24}
                            width={24}
                            className={cx(
                              'text-danger-main hover:text-red-400',
                              enabled ? 'cursor-pointer' : 'cursor-not-allowed'
                            )}
                            onClick={() => {
                              enabled ? remove(index) : null;
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex items-center gap-5 text-neutral-40">
          <div className="opacity-0">
            <BBBDraggableIcon />
          </div>
          <div
            className={cx(
              'grow flex items-center gap-2 cursor-pointer',
              !enabled && 'pointer-events-none'
            )}
            onClick={() =>
              append({
                buttonText: '',
                chatbot: null,
              })
            }
          >
            <PlusCircle />
            <div className="grow">Add new quick responses</div>
          </div>
        </div>
      </div>
    </BBBCard>
  );
}
