import { shallowEqual } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import EditCircleImageGrid from '../Popup/EditCircleImageGrid';
import EditCollections from '../Popup/EditCollections';
import EditImageSlider from '../Popup/EditImageSlider';
import BottomNavigation from './BottomNavigation';
import CartPage from './CartPage';
import CategoryPage from './CategoryPage';
import Homepage from './Homepage';
import PageNavigator from './PageNavigator';
import ProductPage from './ProductPage';

import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import { BBBDeviceFrame } from '@/components/BBBDeviceFrame';
import useBranding from '@/hooks/bitApp/design/useBranding';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppSelector } from '@/hooks/rtk/store';

export default function LivePreview() {
  const popup = useAppSelector((state) => state.bitApp.popup, shallowEqual);
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);
  const isTablet = useResponsive('lg');

  return (
    <>
      {isTablet ? null : (
        <div
          className={cx(
            'flex flex-none w-[62%] h-screen justify-center items-center gap-10 py-11 px-5 overflow-y-auto'
          )}
        >
          <div className="h-full grow justify-center items-center flex-col">
            <div id="bitapp-diff-section" />
            <div className="min-h-full flex grow flex-col items-center justify-center py-10">
              <PageNavigator className="mb-8" />
              <div className="flex-none">
                {activePreview === 'splash' ? (
                  <SplashIcon />
                ) : activePreview === 'app-icon' ? (
                  <AppIcon />
                ) : (
                  <RootApp />
                )}
              </div>
            </div>
          </div>
          <AnimatePresence mode="wait" exitBeforeEnter initial={false}>
            {popup?.type == 'banners' ? (
              <EditImageSlider />
            ) : popup?.type === 'imagegrids' ? (
              <EditCircleImageGrid />
            ) : popup?.type === 'collections' ? (
              <EditCollections />
            ) : null}
          </AnimatePresence>
        </div>
      )}
    </>
  );
}

type RootAppProps = {
  className?: string;
};

export function RootApp({ className }: RootAppProps) {
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);

  return (
    <BBBDeviceFrame className={className}>
      {(activePreview === 'home' || activePreview === 'category') && (
        <div className="flex flex-col h-full">
          <div className="grow relative">
            <div
              className="absolute inset-0 overflow-auto !scrollbar-hide"
              id="mobile-content"
            >
              {activePreview === 'home' ? <Homepage /> : <CategoryPage />}
            </div>
          </div>
          <BottomNavigation />
        </div>
      )}
      {activePreview === 'product' && <ProductPage />}
      {activePreview === 'cart' && <CartPage />}
    </BBBDeviceFrame>
  );
}

type Props = {
  deviceClassName?: string;
  className?: string;
};

export function SplashIcon({ deviceClassName, className }: Props) {
  const { data: brandingData } = useBranding();

  return (
    <div className={cx('w-100 flex gap-2 justify-center', className)}>
      <BBBDeviceFrame className={deviceClassName}>
        <div className="flex items-center justify-center flex-col w-full h-full">
          {brandingData?.splashScreen ? (
            <img
              src={brandingData?.splashScreen}
              className="w-full h-full object-cover"
              alt="splash-logo"
            />
          ) : (
            <BBBThumbnail width={120} height={120} />
          )}
        </div>
      </BBBDeviceFrame>
    </div>
  );
}
export function AppIcon({ deviceClassName, className }: Props) {
  const { data: brandingData } = useBranding();

  return (
    <div className={cx('w-100 flex gap-2 justify-center', className)}>
      <BBBDeviceFrame className={deviceClassName}>
        <div className="grid grid-cols-3 py-14 px-10 gap-4">
          {Array.from({ length: 8 }).map((_, i) =>
            i === 0 ? (
              <div className="flex items-center flex-col gap-2" key={i}>
                {brandingData?.appIcon ? (
                  <img
                    src={brandingData?.appIcon}
                    className="w-[4rem] h-[4rem] object-cover rounded self-center"
                    alt="app-icon"
                  />
                ) : (
                  <BBBThumbnail shape="square" width="4rem" height="4rem" />
                )}
                <div className="text-xs text-primary-main text-center font-bold">
                  {brandingData?.name || 'Your app'}
                </div>
              </div>
            ) : (
              <div className="flex items-center flex-col gap-2" key={i}>
                <div className="w-[4rem] h-[4rem] bg-gray-200 rounded-lg" />
                <div className="text-xs text-primary-main text-center">
                  App name
                </div>
              </div>
            )
          )}
        </div>
      </BBBDeviceFrame>
    </div>
  );
}
