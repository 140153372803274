import BrandingCard from '../components/BrandingCard';
import { AppIcon, RootApp, SplashIcon } from '../components/LivePreview';
import PageNavigator from '../components/LivePreview/PageNavigator';
import PreviewProduct from '../components/LivePreview/ProductPage';
import Journey from '../Journey';

import { BBBDeviceFrame } from '@/components';
import useUpsertBranding from '@/hooks/bitApp/design/useUpsertBranding';
import useOnboarding from '@/hooks/bitApp/onboarding/useOnboarding';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppSelector } from '@/hooks/rtk/store';
import { convertEditorStateToHtml } from '@/utils/common/rich';

function Branding() {
  const { data, status } = useOnboarding('design');

  if (status === 'success' && data?.lastStep) {
    if (data.lastStep === 10) return <_Branding isNewUsers />;
  }

  return <_Branding />;
}

function _Branding({ isNewUsers }: { isNewUsers?: boolean }) {
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);
  const isTablet = useResponsive('lg');

  const { toggle } = useConfirmationBanner();

  const { mutate: save } = useUpsertBranding();

  return (
    <>
      <Journey />
      {isTablet ? (
        <div className="flex flex-col h-full">
          <div className="flex justify-center py-4 border-b">
            <PageNavigator />
          </div>
          <div className="grow relative">
            <div className="absolute inset-0">
              <div className="flex flex-col h-full">
                <div className="h-1/2 flex-none overflow-auto">
                  {activePreview === 'product' ? (
                    <div className="flex justify-center h-full py-3">
                      <BBBDeviceFrame className="bg-transparent border-2 h-full rounded-[1em]">
                        <PreviewProduct />
                      </BBBDeviceFrame>
                    </div>
                  ) : activePreview === 'splash' ? (
                    <SplashIcon
                      className="h-full py-3"
                      deviceClassName="h-full border-2 w-36 rounded-[1em]"
                    />
                  ) : activePreview === 'app-icon' ? (
                    <AppIcon
                      className="h-full py-3"
                      deviceClassName="h-full border-2 w-36 rounded-[1em]"
                    />
                  ) : (
                    <div className="flex h-full py-3 justify-center">
                      <RootApp className="h-full rounded-[1em] border-2" />
                    </div>
                  )}
                </div>
                <div className="h-1/2 flex-none overflow-auto">
                  <BrandingCard className="border-none" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <BrandingCard
            onDetectDiff={(val, { watch, reset }) =>
              toggle('bitapp-branding-diff', {
                onAccept: (hide) => {
                  save({
                    ...watch(),
                    description: convertEditorStateToHtml(watch('description'))
                      ?.length
                      ? convertEditorStateToHtml(watch('description'))
                      : '',
                  });
                  hide();
                },
                onCancel: reset,
                show: !val,
                text: 'Unsaved changes',
                variant: 'actionable',
                isCancelable: true,
              })
            }
            className="mb-5"
          />
        </>
      )}
    </>
  );
}

export default Branding;
