import { useRef } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import { useFlowChannel } from '../../hooks';
import useStore from '../../store';

import { BBBCard } from '@/components';
import {
  triggerConditionOptions,
  triggerConditionOptionsWithoutBack,
} from '@/constants/bitChat/flows';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';

export type CreateConnectionProps = {
  onAddState?: (state: string) => void;
  nodeId: string;
  state: 'hover' | 'clicked' | null;
  onChangeState: (val: 'hover' | 'clicked' | null) => void;
};

export default function CreateConnection(props: CreateConnectionProps) {
  const reactFlowRef = document.querySelector('.react-flow__pane');

  if (!reactFlowRef) return null;

  return <_CreateConnection {...props} reactFlowRef={reactFlowRef} />;
}

function _CreateConnection({
  onAddState,
  reactFlowRef,
  nodeId,
  state,
  onChangeState,
}: CreateConnectionProps & {
  reactFlowRef: Element;
}) {
  const addStateRef = useRef<HTMLDivElement | null>(null);
  const onChangeActiveAddState = useStore((s) => s.onChangeActiveAddState);

  const currentNode = useStore(
    (s) => s.nodes.find((node) => node.id === nodeId)?.type
  );

  const channel = useFlowChannel();

  const triggerOptions =
    currentNode !== 'child1'
      ? triggerConditionOptionsWithoutBack
      : channel === 'whatsapp_meta'
      ? triggerConditionOptionsWithoutBack
      : triggerConditionOptions;

  useOutsideAlerter(
    addStateRef,
    () => {
      onChangeState(null);
      onChangeActiveAddState(null);
    },
    undefined,
    reactFlowRef
  );

  return (
    <div
      className={cx(
        'absolute pointer-events-none opacity-0  left-full translate-x-3 top-1/2 -translate-y-1/2 z-[1000] cursor-pointer transition',
        (state === 'hover' || state === 'clicked') && 'opacity-100'
      )}
    >
      <div className="flex items-center">
        {state === 'clicked' && (
          <>
            <div className="w-28 h-[2px] bg-primary-main -translate-x-[1px]"></div>
            <BBBCard
              title="Add state"
              className="pointer-events-auto z-[1000]"
              ref={addStateRef}
            >
              <div className="flex items-center justify-center gap-4">
                {triggerOptions.map((condition) => (
                  <div
                    key={condition.value}
                    className="hover:border-secondary-border pointer-events-auto w-24 h-24 py-4.5 flex border border-neutral-30 rounded-lg items-center justify-center flex-col"
                    onClick={(e) => {
                      onAddState?.(condition.value);
                      onChangeState(null);
                      onChangeActiveAddState(null);
                      e.stopPropagation();
                    }}
                  >
                    <div className="mb-4">{condition.icon}</div>
                    <div className="text-center text-sm text-neutral-40">
                      {condition.label}
                    </div>
                  </div>
                ))}
              </div>
            </BBBCard>
          </>
        )}
      </div>
    </div>
  );
}
