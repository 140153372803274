import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';

import { Settings } from '@/api/services/whatsApp/settings';
import { BBBCard, BBBPrimarySwitch, BBBSelect } from '@/components';
import {
  customOperationalHour,
  generateTimeOptions,
  operationalHoursTypeOptions,
} from '@/constants/bitChat/settings';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';

type SettingsForm = Pick<
  Settings,
  'operationalHours' | 'operationHourCustomDays'
> & {
  operationalHoursType: typeof operationalHoursTypeOptions[number];
  operationalHourStart: {
    label: string;
    value: string;
  };
  operationalHourEnd: {
    label: string;
    value: string;
  };
};

const timeOptions = generateTimeOptions();

function OperationHours() {
  const { data } = useSettings();
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const { control, reset, watch, handleSubmit } = useCustomForm<SettingsForm>(
    {}
  );

  const operationalHours = watch('operationalHours');
  const operationHourCustomDays = watch('operationHourCustomDays');
  const operationalHourType = watch('operationalHoursType');

  const dataFromApi = useMemo<Partial<SettingsForm>>(
    () => ({
      operationalHours: data?.operationalHours || false,
      operationalHoursType:
        operationalHoursTypeOptions.find(
          (item) => item.value === data?.operationalHoursType
        ) || operationalHoursTypeOptions[0],
      operationalHourStart: generateTimeOptions().find(
        (item) => item.value === data?.operationalHourStart
      ) || {
        label: '09:00',
        value: '09:00',
      },
      operationalHourEnd: generateTimeOptions().find(
        (item) => item.value === data?.operationalHourEnd
      ) || {
        label: '18:00',
        value: '18:00',
      },
      operationHourCustomDays:
        data?.operationHourCustomDays &&
        data?.operationHourCustomDays?.length > 0
          ? data?.operationHourCustomDays
          : customOperationalHour,
    }),
    [
      data?.operationalHours,
      data?.operationHourCustomDays,
      data?.operationalHoursType,
      data?.operationalHourStart,
      data?.operationalHourEnd,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const onSubmit = ({
      operationalHours,
      operationalHourStart,
      operationalHourEnd,
      operationalHoursType,
      operationHourCustomDays,
    }: Partial<SettingsForm>) => {
      updateSettings({
        operationalHoursType: operationalHoursType?.value,
        operationalHourStart: operationalHourStart?.label,
        operationalHourEnd: operationalHourEnd?.label,
        operationHourCustomDays:
          operationalHoursType?.value !== 'custom'
            ? null
            : operationHourCustomDays,
        operationalHours,
      });
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('save-settings', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant: loadingUpdateSettings ? 'loading' : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSettings,
    reset,
    toggle,
    watch(),
    updateSettings,
  ]);

  return (
    <BBBCard
      title="Operational hours"
      desc="Adjust your team operational hours to fit your customer needs"
      rightButton={
        <Controller
          control={control}
          name="operationalHours"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      }
      className="mb-cardBottom"
    >
      {operationalHours && (
        <div
          className={cx(
            'flex gap-5 items-center',
            operationalHourType?.value === 'custom' &&
              'flex-col items-start justify-center'
          )}
        >
          <Controller
            control={control}
            name="operationalHoursType"
            render={({ field }) => (
              <BBBSelect
                options={operationalHoursTypeOptions}
                optionLabel="label"
                optionValue="value"
                value={field.value}
                onValueChange={field.onChange}
                containerClassName="flex-1 w-3/5"
              />
            )}
          />
          {operationalHourType?.value === 'custom' ? (
            <div className="w-full flex flex-col gap-6">
              {operationHourCustomDays?.map((item) => (
                <div
                  key={item.operationalDay}
                  className="flex w-full items-center gap-5"
                >
                  <div
                    className={cx(
                      'w-full flex items-center gap-5',
                      !item.isOperationalActive &&
                        'opacity-50 pointer-events-none'
                    )}
                  >
                    <p className="text-neutral-60 flex-grow max-w-[200px]">
                      {item.operationalDay}
                    </p>
                    <Controller
                      control={control}
                      name="operationHourCustomDays"
                      render={({ field }) => (
                        <BBBSelect
                          options={timeOptions}
                          placeholder="Select time"
                          optionLabel="label"
                          optionValue="value"
                          value={{
                            label: item.operationalStart,
                            value: item.operationalStart,
                          }}
                          onValueChange={(opt) => {
                            field.onChange(
                              operationHourCustomDays?.map((day) => {
                                if (
                                  day.operationalDay === item.operationalDay
                                ) {
                                  return {
                                    ...day,
                                    operationalStart: opt?.value || '',
                                  };
                                }
                                return day;
                              })
                            );
                          }}
                          containerClassName="flex-grow"
                        />
                      )}
                    />
                    -
                    <Controller
                      control={control}
                      name="operationHourCustomDays"
                      render={({ field }) => (
                        <BBBSelect
                          options={timeOptions}
                          placeholder="Select time"
                          optionLabel="label"
                          optionValue="value"
                          value={{
                            label: item.operationalStartEnd,
                            value: item.operationalStartEnd,
                          }}
                          onValueChange={(opt) => {
                            field.onChange(
                              operationHourCustomDays?.map((day) => {
                                if (
                                  day.operationalDay === item.operationalDay
                                ) {
                                  return {
                                    ...day,
                                    operationalStartEnd: opt?.value || '',
                                  };
                                }
                                return day;
                              })
                            );
                          }}
                          containerClassName="flex-grow"
                        />
                      )}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="operationHourCustomDays"
                    render={({ field }) => (
                      <BBBPrimarySwitch
                        checked={item.isOperationalActive}
                        onChange={() => {
                          field.onChange(
                            operationHourCustomDays?.map((day) => {
                              if (day.operationalDay === item.operationalDay) {
                                return {
                                  ...day,
                                  isOperationalActive: !day.isOperationalActive,
                                };
                              }
                              return day;
                            })
                          );
                        }}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex gap-5 items-center">
              <Controller
                control={control}
                name="operationalHourStart"
                render={({ field }) => (
                  <BBBSelect
                    options={timeOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
              -
              <Controller
                control={control}
                name="operationalHourEnd"
                render={({ field }) => (
                  <BBBSelect
                    options={timeOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </BBBCard>
  );
}

export default OperationHours;
