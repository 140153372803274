import React, { useMemo, useState } from 'react';
import { Calendar, Info } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import BBBAnalyticsSelectRange from '../SelectRange';

import {
  BBBBarChart,
  BBBCard,
  BBBContainer,
  BBBOverviewBox,
  BBBSpinner,
  BBBTooltip,
} from '@/components';
import {
  previewAnalyticsProductClicks,
  previewAnalyticsProducts,
  previewTopLinks,
} from '@/constants/analytics/bitLink';
import {
  useProductEventClicks,
  useProductEventSummary,
  useProductLinkClick,
} from '@/hooks/bitLink/analytics';
import useLinkById from '@/hooks/bitLink/links/useLinkById';
import useCollection from '@/hooks/bitLink/shopify/useCollection';
import useProduct from '@/hooks/bitLink/shopify/useProduct';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import { selectedDate } from '@/types/datePicker';
import getRangeValue from '@/utils/common/date';

function ShopPerformance() {
  const { id } = useParams<{
    id: string;
  }>();

  const isPreview =
    id === previewTopLinks?.find((item) => item.type === 'shop')?.id;

  const query = useQuerySearchParams();
  const _startDate = query.get('startDate');
  const _endDate = query.get('endDate');

  const startDate = dayjs(_startDate).toDate();
  const endDate = dayjs(_endDate).toDate();

  const [selectedDay, setSelectedDay] = useState<selectedDate>(startDate);
  const [selectedEndDay, setSelectedEndDay] = useState<selectedDate>(endDate);
  const [activeRange, setActiveRange] = useState(false);

  const rangeValue = getRangeValue(selectedDay, selectedEndDay);

  const { data: linkData, isLoading: linkDataLoading } = useLinkById(
    parseInt(id),
    {
      enabled: !!id && !isPreview,
    }
  );

  const { data: productEventClicks, isLoading: productEventClicksLoading } =
    useProductEventClicks(
      linkData?.id,
      {
        startDate: dayjs(selectedDay).toISOString(),
        endDate: dayjs(selectedEndDay).toISOString(),
      },
      {
        enabled: !!linkData?.id && !isPreview,
      }
    );

  const { data: productEventSummary, isLoading: productEventSummaryLoading } =
    useProductEventSummary(
      linkData?.id,
      {
        startDate: dayjs(selectedDay).toISOString(),
        endDate: dayjs(selectedEndDay).toISOString(),
      },
      {
        enabled: !!linkData?.id && !isPreview,
      }
    );

  const { data: collectionData, isInitialLoading: isLoadingCollection } =
    useCollection(linkData?.items || '-', {
      enabled: linkData?.textSecondary === 'collection' && !isPreview,
    });

  const totalProducts = useMemo(
    () =>
      linkData?.textSecondary === 'collection'
        ? collectionData?.products?.edges?.length
        : linkData?.items?.split(',').length,
    [
      collectionData?.products?.edges?.length,
      linkData?.items,
      linkData?.textSecondary,
    ]
  );

  const toggleShowRange = () => {
    setActiveRange((prev) => !prev);
  };

  const isLoading =
    linkDataLoading ||
    productEventClicksLoading ||
    productEventSummaryLoading ||
    isLoadingCollection;

  return (
    <>
      {!isPreview && isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <BBBContainer
          hasHeading
          pageTitle={
            isPreview ? 'Valentine Hampers 2024' : linkData?.textPrimary || '-'
          }
          pageDescription="Track your shop performance"
          hasBack
          backUrl="/analytics?tab=bitlink"
          rightComponent={
            <div className="relative cursor-pointer">
              <BBBCard onClick={toggleShowRange} className="!px-4 !py-[10px]">
                <div className="flex gap-3">
                  <Calendar width={24} height={24} />
                  <div className="font-bold text-primary-main">
                    {rangeValue?.label}
                  </div>
                </div>
              </BBBCard>
              {activeRange && (
                <BBBAnalyticsSelectRange
                  selectedDay={selectedDay}
                  selectedEndDay={selectedEndDay}
                  setSelectedDay={setSelectedDay}
                  setSelectedEndDay={setSelectedEndDay}
                  active={activeRange}
                  onChangeActive={setActiveRange}
                />
              )}
            </div>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-5 ">
            <BBBOverviewBox
              title="Total product listed"
              value={isPreview ? 12 : totalProducts || 0}
            />
            <BBBOverviewBox
              title="Avg. clicks per day"
              value={
                isPreview ? 9 : productEventSummary?.average?.toFixed(2) || 0
              }
            />
            <BBBOverviewBox
              title="Total clicks"
              value={isPreview ? 32 : productEventSummary?.current || 0}
            />
            <div className="col-span-1 md:col-span-3">
              <ProductClicks
                items={linkData?.items || '-'}
                type={linkData?.textSecondary || '-'}
                startDate={dayjs(selectedDay).toISOString()}
                endDate={dayjs(selectedEndDay).toISOString()}
                isPreview={isPreview}
              />
            </div>
            <div className="col-span-1 md:col-span-3">
              <BBBBarChart
                title="Clicks"
                lineDataKey="clicks"
                xAxisProps={{
                  dataKey: 'date',
                }}
                data={
                  isPreview ? previewAnalyticsProductClicks : productEventClicks
                }
              />
            </div>
          </div>
        </BBBContainer>
      )}
    </>
  );
}

function ProductClicks({
  items,
  type,
  startDate,
  endDate,
  isPreview,
}: {
  items: string;
  type: string;
  startDate: string;
  endDate: string;
  isPreview: boolean;
}) {
  return (
    <BBBCard
      title={
        <div className="flex gap-1 items-center w-fit">
          <p className="text-base font-normal whitespace-nowrap">
            Products clicks
          </p>
          <BBBTooltip
            show
            content="Tracks the amount of clicks for each product listed"
          >
            <Info size={14} color="#9E9E9E" />
          </BBBTooltip>
        </div>
      }
    >
      <div className="flex flex-col gap-2.5">
        {isPreview
          ? previewAnalyticsProducts?.map((item) => (
              <ProductList
                key={item?.id}
                id={item?.id}
                title={item?.title}
                url={item?.onlineStoreUrl}
                startDate={startDate}
                endDate={endDate}
              />
            ))
          : items
              ?.split(',')
              .map((item) =>
                type === 'collection' ? (
                  <CollectionRenderer
                    key={item}
                    items={item}
                    type={type}
                    isPreview={isPreview}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ) : (
                  <ProductRenderer
                    key={item}
                    items={item}
                    type={type}
                    isPreview={isPreview}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )
              )}
      </div>
    </BBBCard>
  );
}

function CollectionRenderer({
  items,
  type,
  isPreview,
  startDate,
  endDate,
}: {
  items: string;
  type: string;
  isPreview: boolean;
  startDate: string;
  endDate: string;
}) {
  const { data: collectionData, isInitialLoading: isLoadingCollection } =
    useCollection(items || '-', {
      enabled: type === 'collection' && !isPreview,
    });

  const products = collectionData?.products?.edges?.flatMap(
    (item) => item?.node
  );

  return (
    <>
      {products?.map((item) => (
        <ProductList
          key={item?.id}
          id={item?.id}
          title={item?.title}
          url={item?.onlineStoreUrl}
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoadingCollection}
        />
      ))}
    </>
  );
}

function ProductRenderer({
  items,
  type,
  isPreview,
  startDate,
  endDate,
}: {
  items: string;
  type: string;
  isPreview: boolean;
  startDate: string;
  endDate: string;
}) {
  const { data: productData, isInitialLoading: isLoadingProducts } = useProduct(
    items,
    {
      enabled: type === 'product' && !isPreview,
    }
  );

  return (
    <ProductList
      key={productData?.id}
      id={productData?.id}
      title={productData?.title}
      url={productData?.onlineStoreUrl}
      startDate={startDate}
      endDate={endDate}
      isLoading={isLoadingProducts}
    />
  );
}

function ProductList({
  id,
  title,
  url,
  startDate,
  endDate,
  isLoading,
}: {
  id: string | undefined;
  title: string | undefined;
  url: string | undefined;
  startDate: string;
  endDate: string;
  isLoading?: boolean;
}) {
  const { data: productTotalLinkClick, isLoading: isLoadingProductTotalClick } =
    useProductLinkClick(
      encodeURIComponent(id || '-'),
      {
        startDate,
        endDate,
      },
      {
        enabled: !!id,
      }
    );

  if (isLoading) {
    return (
      <div className="w-full flex flex-col gap-1 pb-2.5 border-b border-neutral-30 last:border-transparent">
        <Skeleton width={20} height={5} />
        <div className="flex w-full justify-between text-sm">
          <Skeleton width={80} height={5} />
          {isLoadingProductTotalClick && <Skeleton width={20} height={10} />}
        </div>
      </div>
    );
  }

  return (
    <div
      key={id}
      className="w-full flex flex-col gap-1 pb-2.5 border-b border-neutral-30 last:border-transparent"
    >
      <p className="uppercase text-secondary-main text-sm">
        {startCase(title)}
      </p>
      <div className="w-full flex justify-between">
        <a
          href={url || '#'}
          target="_blank"
          rel="noreferrer"
          className="text-primary-main text-sm underline"
        >
          {url || '-'}
        </a>
        <p className="text-primary-main font-medium">{productTotalLinkClick}</p>
      </div>
    </div>
  );
}

function Loader({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center gap-1',
        className
      )}
    >
      <BBBSpinner height={18} />
      <p className="text-neutral-60">Loading more data</p>
    </div>
  );
}

export default ShopPerformance;
