import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  BBBButton,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
} from '@/components';
import languages from '@/constants/common/languages';
import { useAIGeneratedTemplate } from '@/hooks/bitCRM/template/template';
import useCustomForm from '@/hooks/common/useCustomForm';
import { Mutable } from '@/types/utils/mutable';

const schema = yup.object().shape({
  isExperimental: yup.bool().required().label('Experimental flag'),
  messageContext: yup
    .string()
    .label('Message context')
    .when('isExperimental', {
      is: (val: boolean) => !!val,
      then: (rule) => rule.required(),
    }),
  messageLanguage: yup
    .mixed<typeof languages[number]>()
    .label('Message language')
    .when('isExperimental', {
      is: (val: boolean) => !!val,
      then: (rule) => rule.required(),
    }),
});

type FormSchema = {
  isExperimental?: boolean;
  messageContext?: string;
  messageLanguage?: typeof languages[number];
};

const defaultFormSchema: FormSchema = {
  isExperimental: false,
  messageContext: '',
  messageLanguage: languages.find((lang) => lang.code === 'en'),
};

type Props = {
  onGenerated?: (val: string) => void;
};

function AIGenerate({ onGenerated }: Props) {
  const { handleSubmit, formState, control, watch } = useCustomForm<FormSchema>(
    {
      resolver: yupResolver(schema),
      defaultValues: defaultFormSchema,
    }
  );

  const generateCount = useRef(0);

  const { mutate: generate, isLoading } = useAIGeneratedTemplate();
  const onSubmit = (data: FormSchema) => {
    generate(
      {
        language: data.messageLanguage!.name!,
        context: data.messageContext!,
      },
      {
        onSuccess: (data) => {
          onGenerated?.(data);
          generateCount.current += 1;
        },
      }
    );
  };

  return (
    <div className="mb-6">
      <Controller
        control={control}
        name="isExperimental"
        render={({ field }) => (
          <BBBPrimarySwitch
            checked={field.value}
            onChange={field.onChange}
            label="Try to generate your message with AI! (Optional)"
            containerClassName="mb-4"
          />
        )}
      />
      {watch('isExperimental') && (
        <>
          <BBBTextInput
            label="Message context"
            placeholder="ie: Create campaign message for store who sell flowers on valentine"
            containerClassname="mb-4"
            isHookForm
            control={control}
            controlName="messageContext"
            error={formState.errors.messageContext?.message}
          />
          <Controller
            control={control}
            name="messageLanguage"
            render={({ field }) => (
              <BBBSelect
                options={languages as Mutable<typeof languages>}
                optionLabel="name"
                isSearchable
                value={field.value}
                onValueChange={field.onChange}
                optionValue="code"
                label="Message language"
                placeholder="Select language"
                containerClassName="mb-4"
                error={formState.errors.messageLanguage?.message}
              />
            )}
          />

          <BBBButton
            text={
              isLoading
                ? 'Generating message'
                : generateCount.current > 0
                ? 'Re-generate message'
                : 'Generate message with AI'
            }
            variant="secondary"
            size="sm"
            onClick={() => handleSubmit(onSubmit)()}
            loadingState={isLoading}
          />
        </>
      )}
    </div>
  );
}

export default AIGenerate;
