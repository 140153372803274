import { useCallback, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { createSelector } from '@reduxjs/toolkit';
import { motion } from 'framer-motion';
import CoPilotProductRecommendation from './ProductRecommendation';

import { BBBButton } from '@/components/BBBButton';
import useProductById from '@/hooks/bitChat/livechat/useProductById';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import {
  activeMessageSelector,
  liveChatMemoSelector,
} from '@/hooks/whatsApp/useActiveChatMemo';
import useRecommendMessage from '@/hooks/whatsApp/useRecommendMessage';
import { upsertLivechatMemo } from '@/stores/bitCRM';
import { convertHtmlToEditorState } from '@/utils/common/rich';

export const hideCoPilotSelector = createSelector(
  [liveChatMemoSelector],
  (data) => data?.hideCopilot
);

function getPosition(element: HTMLElement) {
  const { top, left } = element.getBoundingClientRect();

  return {
    x: left,
    y: top,
  };
}

function getDistanceBetweenElements(a: HTMLElement, b: HTMLElement) {
  const aPosition = getPosition(a);
  const bPosition = getPosition(b);

  return { x: aPosition.x - bPosition.x, y: aPosition.y - bPosition.y };
}

type Props = {
  coPilotQuickActionRef: HTMLDivElement | null | undefined;
};

export default function CoPilot(props: Props) {
  return <CoPilotCheckAgent {...props} />;
}

function CoPilotCheckAgent(props: Props) {
  const agentId = useAppSelector((s) => s.bitCRM.selectedChat?.ticket?.userId);
  const userId = useUserId();

  if (!(userId === agentId)) return null;

  return <CoPilotCheckSettings {...props} />;
}

function CoPilotCheckSettings(props: Props) {
  const { data: settingsData } = useSettings();

  if (!settingsData?.copilot) return null;

  return <_CoPilot {...props} />;
}

function _CoPilot({ coPilotQuickActionRef }: Props) {
  const dispatch = useAppDispatch();
  const hideCoPilot = useAppSelector(hideCoPilotSelector);
  const activeMessages = useAppSelector(activeMessageSelector);

  const activeMessagesText = activeMessages
    .getCurrentContent()
    .getPlainText(' ');

  const { data: recommendedMessage } = useRecommendMessage();

  const {
    data: productDetail,
    isInitialLoading: isLoadingProductDetail,
    isError: isErrorProductDetail,
  } = useProductById(recommendedMessage?.data.parsedTags?.product?.id, {
    enabled: !!recommendedMessage?.data.parsedTags?.product,
  });

  const handleUseResponse = useCallback(() => {
    dispatch(
      upsertLivechatMemo({
        hideCopilot: true,
        messageToSend: convertHtmlToEditorState(
          recommendedMessage?.data.recommendations
        ),
        recommendedProduct: productDetail
          ? {
              ...productDetail,
              url: recommendedMessage?.data.parsedTags?.product?.url,
            }
          : undefined,
      })
    );
  }, [
    dispatch,
    productDetail,
    recommendedMessage?.data.parsedTags?.product?.url,
    recommendedMessage?.data.recommendations,
  ]);

  useEffect(() => {
    if (activeMessagesText === '/ai') {
      handleUseResponse();
    }
  }, [activeMessagesText, handleUseResponse]);

  const [distance, setDistance] = useState<{ x: number; y: number }>();
  const [coPilotRef, setCoPilotRef] = useState<HTMLDivElement | null>();

  useEffect(() => {
    const dist =
      coPilotRef && coPilotQuickActionRef
        ? getDistanceBetweenElements(coPilotQuickActionRef, coPilotRef)
        : undefined;

    setDistance(dist);
  }, [coPilotQuickActionRef, coPilotRef]);

  return (
    <>
      <motion.div
        animate={
          hideCoPilot
            ? {
                opacity: 0,
                scale: 0,
                x: (distance?.x ?? 0) + 200,
                y: distance?.y,
                width: 0,
                height: 0,
                fontSize: 0,
                zIndex: 99999,
              }
            : {
                height: 'auto',
                width: '100%',
                fontSize: 'inherit',
                scale: 1,
                opacity: 1,
              }
        }
        transition={{
          duration: 0.5,
          type: 'tween',
        }}
        ref={useCallback((ref) => setCoPilotRef(ref), [setCoPilotRef])}
      >
        <div className="py-4 px-6 mx-11 my-5 rounded-2xl border-secondary-main border border-dashed">
          <div className="flex gap-2 items-center mb-2 ">
            <div className="grow font-medium">bitChat Co-Pilot</div>

            <motion.div
              className="text-neutral-40 cursor-pointer"
              layoutId="x"
              tabIndex={0}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() =>
                dispatch(
                  upsertLivechatMemo({
                    hideCopilot: true,
                  })
                )
              }
            >
              <X />
            </motion.div>
          </div>
          <div>
            <motion.div
              className="mb-4"
              animate={{ opacity: !hideCoPilot ? 1 : 0 }}
              transition={{
                duration: 1,
                type: 'tween',
              }}
            >
              {recommendedMessage?.data.recommendations}
            </motion.div>
            <div className="flex items-center gap-2">
              <div className="grow">
                {recommendedMessage?.data.parsedTags?.product && (
                  <CoPilotProductRecommendation
                    loading={isLoadingProductDetail}
                    error={isErrorProductDetail}
                    imgUrl={productDetail?.image?.src}
                    title={productDetail?.title}
                  />
                )}
              </div>
              <BBBButton
                variant="secondary"
                text="Use suggestion"
                onClick={handleUseResponse}
                tabIndex={0}
                onMouseDown={(e) => e.preventDefault()}
                id="use-ai-response"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
