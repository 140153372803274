import { useParams } from 'react-router-dom';
import AddTag from './AddTag';

import { BBBCard } from '@/components';
import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';
import useDeleteTag from '@/hooks/customers/customer/useDeleteTag';
import { CustomerTags } from '@/types/customers';

type Props = {
  customerTags?: CustomerTags[];
};

function TagsCard({ customerTags }: Props) {
  const { id } = useParams<{ id: string }>();

  const { mutate: deleteTag } = useDeleteTag();

  return (
    <BBBCard title="Tags">
      <AddTag customerId={id} />
      {!!customerTags?.length && (
        <TruncatedTags
          items={customerTags.map((tag) => tag.tagName)}
          onDelete={(tagName) =>
            deleteTag({
              tagName,
              customerId: id,
            })
          }
        />
      )}
    </BBBCard>
  );
}

export default TagsCard;
