import { HelpCircle } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import CircleCheckIcon from '@/assets/icons/CircleCheckIcon';
import { BBBTooltip } from '@/components/BBBTooltip';
import colors from '@/constants/common/colors';
import { TRESHOLD_2 as _TRESHOLD_2 } from '@/constants/pricing';

export type BBBPercentageBarProps = {
  usage: number;
  limit: number;
  description?: string;
  className?: string;
  withoutInfo?: boolean;
  defaultColor?: string;
  activeColor?: string;
  hasTip?: boolean;
  tip?: string;
};

function BBBPercentageBar({ className, ...props }: BBBPercentageBarProps) {
  return (
    <div className={cx('flex flex-col col-span-1', className)}>
      <Info type="usage-rate" {...props} />
    </div>
  );
}

export function Info(
  props: (
    | {
        type: 'usage-rate';
        usage: number;
        limit: number;
        activeColor?: string;
        defaultColor?: string;
      }
    | { type: 'one-time'; value: string | JSX.Element }
  ) & {
    description?: string;
    hasTip?: boolean;
    tip?: string;
    withoutInfo?: boolean;
    className?: string;
  }
) {
  const aboveTreshold =
    props.type === 'usage-rate' &&
    props.usage / props.limit > _TRESHOLD_2(props.limit);

  return (
    <div className={props.className}>
      {!props.withoutInfo && (
        <div className="text-xs text-neutral-50 flex justify-between">
          <div className="flex items-center gap-1">
            {props.description}{' '}
            {props.hasTip && (
              <BBBTooltip content={props.tip} show position="right">
                <HelpCircle size="0.625rem" />
              </BBBTooltip>
            )}
          </div>
          {props.type === 'usage-rate' ? (
            props.limit === Infinity ? (
              <CircleCheckIcon />
            ) : (
              <span>
                <span
                  className={cx(
                    'font-semibold text-neutral-60',
                    aboveTreshold && 'text-danger-main'
                  )}
                >
                  {props.usage}
                </span>{' '}
                of {props.limit}{' '}
              </span>
            )
          ) : (
            <span className="font-semibold text-neutral-60">{props.value}</span>
          )}
        </div>
      )}
      {props.type === 'usage-rate' && props.limit !== Infinity && (
        <div className="w-full h-1 bg-[#DBDBDB] rounded-full mt-2">
          <div
            className={cx('h-full  rounded-full')}
            style={{
              width: `${
                (props.usage / props.limit) * 100 > 100
                  ? 100
                  : (props.usage / props.limit) * 100
              }%`,
              backgroundColor: aboveTreshold
                ? colors.danger.main
                : props.defaultColor || '#262627',
            }}
          />
        </div>
      )}
    </div>
  );
}

export default BBBPercentageBar;
