import { twMerge as cx } from 'tailwind-merge';
import ShopifyLogo from './ShopifyLogo';

import { BBBButton, BBBButtonTypes } from '@/components';
import useShopifyConnectModal from '@/hooks/shopify/useShopifyConnectModal';
import { AppType } from '@/types/systemLogin';

function ConnectShopifyButton({
  appType,
  text,
  className,
  onSuccess,
  ...rest
}: BBBButtonTypes & {
  appType: AppType;
  onSuccess?: () => void;
}) {
  const { setShow } = useShopifyConnectModal();

  return (
    <BBBButton
      text={text || 'Connect to Shopify'}
      className={cx('bg-[#008060] hover:bg-[#008060]/50', className)}
      onClick={() => {
        setShow({ appType, defaultMode: 'ask-permission', onSuccess });
      }}
      icon={<ShopifyLogo className="mr-3" />}
      {...rest}
    />
  );
}

export default ConnectShopifyButton;
