import { ApiResponse } from '../types';

import api from '@/config/api';
import { AppType } from '@/types/integrations';
import { appPriority, pricingPlanPriority } from '@/utils/app';

export default {
  getCompanyPricings: (id: number) =>
    api.pricing
      .get<
        ApiResponse<
          (CompanyPricing & {
            pricingModule: PricingApp;
          })[]
        >
      >(`/pricing/company/${id}/all`)
      .then((res) => sortCompanyPricing(res.data.data)),
  getCompanyPricing: ({ id }: { id: number }, query?: { app: AppType }) =>
    api.pricing
      .get<ApiResponse<CompanyPricing | null>>(`/pricing/company/${id}/`, {
        params: query,
      })
      .then((res) => res.data.data),
  getPricings: (query?: { app: AppType; type: PlanType }) =>
    api.pricing
      .get<ApiResponse<PricingApp[]>>(`/pricing`, { params: query })
      .then((res) => res.data.data),
  getPricingFeatures: ({ id }: { id: number }, query?: { app: AppType }) =>
    api.pricing
      .get<ApiResponse<CompanyPricingFeature[]>>(
        `/pricing/company/${id}/features`,
        {
          params: query,
        }
      )
      .then((res) => res.data.data),
  getPricingFeature: (
    { id, featureName }: { id: number; featureName: PricingFeatureNames },
    query?: { app: AppType }
  ) =>
    api.pricing
      .get<ApiResponse<CompanyPricingFeature>>(
        `/pricing/company/${id}/features/${featureName}`,
        {
          params: query,
        }
      )
      .then((res) => res.data.data),
};

export type PricingPlan =
  | 'free'
  | 'standard'
  | 'pro'
  | 'pro1'
  | 'enterprise'
  | 'freeWithUsageCharge';

export type PlanType = 'ANNUAL' | 'EVERY_30_DAYS';
export type PricingApp = {
  name: PricingPlan;
  label: string;
  price: number;
  app: AppType;
  type: PlanType;
};

export type CompanyPricing = {
  pricingName: PricingPlan;
  app: AppType;
  type: PlanType;
  companyId: number;
  lastBillingCycle: Date | null;
  pricingModule: PricingApp;
  companyPricingFeatures: CompanyPricingFeature[];
  status: PricingSubscriptionStatus | null;
  hasFreeTrialQuota: boolean;
};

export type CompanyPricingFeature = {
  companyId: number;
  usage: number;
  featureName: PricingFeatureNames;
  app: AppType;
  pricingFeature: PricingFeature;
  companyPricing: CompanyPricing;
};

export type PricingFeature = {
  name: PricingFeatureNames;
  app: AppType;
  label: string;
  isReset: boolean;
  pricingFeatureDetails: PricingFeatureDetail[];
};

export type PricingFeatureDetail = {
  pricingFeatureName: PricingFeatureNames;
  app: AppType;
  pricingName: PricingPlan;
  limit: number;
};

export type PricingFeatureNames =
  | 'default'
  | 'conversation'
  | 'ai_tagging'
  | 'chatbot_auto_reply'
  | 'active_automations'
  | 'automation_messages'
  | 'campaign_messages'
  | 'automatic_updates'
  | 'managed_collections'
  | 'advance_custom_model';

export type PricingSubscriptionStatus =
  | 'active'
  | 'cancelled'
  | 'pending'
  | 'declined'
  | 'frozen';

const sortCompanyPricing = (data: CompanyPricing[]): CompanyPricing[] => {
  return data.sort((a, b) => {
    const pricingPriorityA = pricingPlanPriority[a.pricingName];
    const pricingPriorityB = pricingPlanPriority[b.pricingName];

    if (pricingPriorityA !== pricingPriorityB) {
      return pricingPriorityA - pricingPriorityB;
    }

    const appPriorityA = appPriority[a.app];
    const appPriorityB = appPriority[b.app];

    return appPriorityA - appPriorityB;
  });
};
