type Props = {
  expanded?: boolean;
};

function ExpandIcon({ expanded }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.25 3h-16a2 2 0 00-2 2v14a2 2 0 002 2h16a2 2 0 002-2V5a2 2 0 00-2-2z"
      ></path>
      <path
        fill={expanded ? 'transparent' : '#404040'}
        d="M2.25 3H10.25V20H2.25z"
      ></path>
      <path stroke="#404040" strokeWidth="2" d="M9.25 3v18"></path>
    </svg>
  );
}

export default ExpandIcon;
