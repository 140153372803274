import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function BitAppIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' ? (
        <BitAppIconDefault />
      ) : mode === 'hover' ? (
        <BitAppIconHover />
      ) : (
        <BitAppIconActive />
      )}
    </>
  );
}

function BitAppIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g fill="#404040" clipPath="url(#clip0_6824_92222)">
        <path d="M17.875 1.625A1.625 1.625 0 0119.5 3.25v19.5a1.625 1.625 0 01-1.625 1.625h-9.75A1.625 1.625 0 016.5 22.75V3.25a1.625 1.625 0 011.625-1.625h9.75zM8.125 0a3.25 3.25 0 00-3.25 3.25v19.5A3.25 3.25 0 008.125 26h9.75a3.25 3.25 0 003.25-3.25V3.25A3.25 3.25 0 0017.875 0h-9.75z"></path>
        <path d="M13 22.75a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92222">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BitAppIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g fill="#FD823E" clipPath="url(#clip0_6824_92240)">
        <path d="M17.875 1.625A1.625 1.625 0 0119.5 3.25v19.5a1.625 1.625 0 01-1.625 1.625h-9.75A1.625 1.625 0 016.5 22.75V3.25a1.625 1.625 0 011.625-1.625h9.75zM8.125 0a3.25 3.25 0 00-3.25 3.25v19.5A3.25 3.25 0 008.125 26h9.75a3.25 3.25 0 003.25-3.25V3.25A3.25 3.25 0 0017.875 0h-9.75z"></path>
        <path d="M13 22.75a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92240">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BitAppIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#404040"
        fillRule="evenodd"
        d="M5.827.952A3.25 3.25 0 018.125 0h9.75a3.25 3.25 0 013.25 3.25v19.5a3.25 3.25 0 01-3.25 3.25h-9.75a3.25 3.25 0 01-3.25-3.25V3.25c0-.862.342-1.689.952-2.298zM13 22.75a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BitAppIcon;
