import { useMutation, useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useAppSelector } from 'hooks/rtk/store';
import { WhatsAppCloudAccount } from 'types/whatsApp/chat';

const QUERY_KEY = 'bitchat-whatsapp-business-profile' as const;

export const useProfile = () => {
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);

  return useQuery([QUERY_KEY, apiKey], () =>
    services.whatsApp.businessProfile.getProfile({ key: apiKey! })
  );
};

export const useUpdateProfile = () => {
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);

  return useMutation((data: Omit<WhatsAppCloudAccount, 'key'>) =>
    services.whatsApp.businessProfile.updateProfile({
      key: apiKey!,
      ...data,
    })
  );
};
