import { createId } from '@paralleldrive/cuid2';

import AnalyticsIcon from '@/assets/icons/AnalyticsIcon';
import BitAiIcon from '@/assets/icons/BitAIIcon';
import BitAppIcon from '@/assets/icons/BitAppIcon';
import BitChatIcon from '@/assets/icons/BitChatIcon';
import BitCRMIcon from '@/assets/icons/BitCRMIcon';
import BitLinkIcon from '@/assets/icons/BitLinkIcon';
import BitLoginIcon from '@/assets/icons/BitLoginIcon';
import CustomerIcon from '@/assets/icons/CustomerIcon';
import HomeIcon from '@/assets/icons/HomeIcon';
import IntegrationsIcon from '@/assets/icons/IntegrationsIcon';
import PricingIcon from '@/assets/icons/PricingIcon';
import { env } from '@/config/env';
import { isEmbed } from '@/hooks/common/useAppEmbed';
import { isAppInternal } from '@/hooks/common/useAppInternal';
import { sortAppOrder } from '@/utils/app';

export type sidebarDataTypeSectionV2 = {
  disabled?: boolean;
  path: string;
  title: string;
  icon?: ({ mode }: { mode?: 'default' | 'hover' | 'active' }) => JSX.Element;
  parentPath?: string;
  redirectPath?: string;
  isBeta?: boolean;
  isNew?: boolean;
};

export type sidebarDataType = {
  id: string;
  disabled: boolean;
  child: string[];
};

export const sidebarDatav2: sidebarDataTypeSectionV2[] = [
  {
    disabled: false,
    title: 'Home',
    path: '/home',
    icon: HomeIcon,
  },
  {
    title: 'bitLink',
    path: '/bitlink',
    icon: BitLinkIcon,
    redirectPath: '/bitlink/links',
  },
  {
    title: 'Links',
    parentPath: '/bitlink',
    path: '/bitlink/links',
    disabled: false,
  },
  {
    title: 'Appearance',
    parentPath: '/bitlink',
    path: '/bitlink/appearance',
    disabled: false,
  },
  {
    title: 'Social Media',
    parentPath: '/bitlink',
    path: '/bitlink/social',
    disabled: false,
  },
  {
    title: 'Subscription',
    parentPath: '/bitlink',
    path: '/bitlink/subscription',
    disabled: false,
  },
  {
    title: 'bitApp',
    path: '/bitapp',
    icon: BitAppIcon,
    redirectPath: '/bitapp/design',
  },
  {
    title: 'Design',
    parentPath: '/bitapp',
    path: '/bitapp/design',
    disabled: false,
  },
  {
    title: 'Notification',
    parentPath: '/bitapp',
    path: '/bitapp/notification',
    disabled: false,
  },
  {
    title: 'Preview',
    parentPath: '/bitapp',
    path: '/bitapp/preview',
    disabled: false,
  },
  {
    title: 'Publish',
    parentPath: '/bitapp',
    path: '/bitapp/publish',
    disabled: false,
  },
  {
    title: 'Login Options',
    parentPath: '/bitapp',
    path: '/bitapp/login-options',
    disabled: false,
  },
  {
    title: 'Submission (Internal)',
    parentPath: '/bitapp',
    path: '/bitapp/submission',
    disabled: !isAppInternal,
  },
  {
    disabled: false,
    title: 'bitCRM',
    path: '/bitcrm',
    icon: BitCRMIcon,
    redirectPath: '/bitcrm/onboarding',
  },
  {
    title: 'Campaign',
    parentPath: '/bitcrm',
    path: '/bitcrm/campaign',
    disabled: isEmbed && Boolean(env.REACT_APP_EMBED_PREVIEW_MODE),
  },
  // {
  //   title: 'Automation (Legacy)',
  //   parentPath: '/bitcrm',
  //   path: '/bitcrm/automation',
  //   disabled: false,
  // },
  {
    title: 'Automation',
    parentPath: '/bitcrm',
    path: '/bitcrm/automation-new',
    disabled: false,
    isNew: true,
  },
  {
    title: 'Template',
    parentPath: '/bitcrm',
    path: '/bitcrm/template',
  },
  {
    disabled: false,
    title: 'bitChat',
    path: '/bitchat',
    icon: BitChatIcon,
    redirectPath: '/bitchat/onboarding',
  },
  {
    title: 'Livechat',
    parentPath: '/bitchat',
    path: '/bitchat/livechat',
  },
  {
    title: 'Ticket',
    parentPath: '/bitchat',
    path: '/bitchat/ticket',
  },
  {
    title: 'Quick reply template',
    parentPath: '/bitchat',
    path: '/bitchat/quickreply',
  },
  {
    title: 'Chatbot',
    parentPath: '/bitchat',
    path: '/bitchat/flows',
  },
  {
    title: 'WhatsApp form',
    parentPath: '/bitchat',
    path: '/bitchat/whatsapp-form',
  },
  {
    title: 'Chat widget',
    parentPath: '/bitchat',
    path: '/bitchat/wa-widget',
  },
  {
    title: 'Settings',
    parentPath: '/bitchat',
    path: '/bitchat/settings',
  },
  {
    title: 'bitLogin',
    path: '/bitlogin',
    icon: BitLoginIcon,
    redirectPath: '/bitlogin/social',
  },
  {
    title: 'Social Login',
    parentPath: '/bitlogin',
    path: '/bitlogin/social',
    disabled: false,
  },
  {
    title: 'Appearance',
    parentPath: '/bitlogin',
    path: '/bitlogin/appearance',
    disabled: false,
  },
  {
    title: 'Sign Up',
    parentPath: '/bitlogin',
    path: '/bitlogin/signup',
    disabled: false,
  },
  {
    title: 'OAuth Settings',
    parentPath: '/bitlogin',
    path: '/bitlogin/oauth-settings',
    disabled: false,
  },
  {
    title: 'bitAI',
    path: '/bitai',
    icon: BitAiIcon,
    redirectPath: '/bitai/onboarding',
  },
  {
    title: 'Sorting',
    parentPath: '/bitai',
    path: '/bitai/sorting',
    disabled: false,
    redirectPath: '/bitai/sorting',
  },
  {
    title: 'Model',
    parentPath: '/bitai',
    path: '/bitai/model',
    disabled: false,
  },
  {
    title: 'Analytics',
    path: '/analytics',
    icon: AnalyticsIcon,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    icon: IntegrationsIcon,
  },
  {
    title: 'Pricing',
    path: '/pricing',
    disabled: false,
    icon: PricingIcon,
  },
  {
    disabled: false,
    title: 'Customers',
    path: '/customers',
    icon: CustomerIcon,
  },
];

export const sidebarData: sidebarDataType[] = [
  {
    id: createId(),
    disabled: false,
    child: ['/home'],
  },
  {
    id: createId(),
    disabled: false,
    child: [
      '/bitchat',
      '/bitcrm',
      '/bitapp',
      '/bitlink',
      '/bitlogin',
      '/bitai',
    ].sort((a, b) => {
      const aSubData = sidebarDatav2.find((data) => data.path === a)!;
      const bSubData = sidebarDatav2.find((data) => data.path === b)!;
      return sortAppOrder(aSubData.title, bSubData.title);
    }),
  },
  {
    id: createId(),
    disabled: false,
    child: ['/analytics', '/integrations', '/pricing'],
  },
  {
    id: createId(),
    disabled: false,
    child: ['/customers'],
  },
];
