import { ReactNode, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { motion, MotionStyle } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { AppType } from 'types/systemLogin';
import { groupOrder } from 'utils/app';
import { formatInternational } from 'utils/common/phone';

import CheckIcon2 from '@/assets/icons/CheckIcon2';
import { BBBButton, BBBButtonTypes, BBBCard, BBBContainer } from '@/components';
import BetaBadge from '@/components/Badge/BetaBadge';
import colors from '@/constants/common/colors';
import {
  integrationsMeta,
  KnownIntegration,
  mapDescriptionIntegrationApp,
  shownIntegrations,
} from '@/constants/integrations';
import {
  useShopifyIntegrations as useShopifyIntegrationsBitAi,
  useToggleIntegration as useToggleIntegrationBitAi,
} from '@/hooks/bitAi/integration/integrations';
import {
  useShopifyIntegrations as useShopifyIntegrationsBitApp,
  useStampedIntegrations,
  useToggleIntegration as useToggleIntegrationBitApp,
  useYotpoIntegrations,
} from '@/hooks/bitApp/integration/integrations';
import { useRecentPublish } from '@/hooks/bitApp/publish';
import {
  useFacebookIntegrations,
  useInstagramIntegrations,
  useShopifyIntegrations as useShopifyIntegrationsBitChat,
  useToggleIntegration as useToggleIntegrationBitChat,
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import {
  useShopifyIntegrations as useShopifyIntegrationsBitCRM,
  useToggleIntegration as useToggleIntegrationBitCRM,
} from '@/hooks/bitCRM/integration/integrations';
import {
  useShopifyIntegrations as useShopifyIntegrationsBitLink,
  useToggleIntegration as useToggleIntegrationBitLink,
} from '@/hooks/bitLink/integration/integrations';
import {
  useShopifyIntegrations as useShopifyIntegrationsBitLogin,
  useToggleIntegration as useToggleIntegrationBitLogin,
} from '@/hooks/bitLogin/integration/integrations';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import { useAppSelector } from '@/hooks/rtk/store';

const mapAppTypeToShopifyCard: Record<
  string,
  (props: Pick<IntegrationCardProps, 'v'>) => JSX.Element
> = {
  bitChat: ShopifyCardBitChat,
  bitCRM: ShopifyCardBitCRM,
  bitLink: ShopifyCardBitLink,
  bitApp: ShopifyCardBitApp,
  bitLogin: ShopifyCardBitLogin,
  bitAi: ShopifyCardBitAi,
};

const mapAppTypeToAppName: Record<AppType, string> = {
  BITCHAT: 'bitChat',
  BITCRM: 'bitCRM',
  BITAPP: 'bitApp',
  BITLINK: 'bitLink',
  BITLOGIN: 'bitLogin',
  BITAI: 'bitAi',
};

export type DefaultCardProps = Pick<
  IntegrationCardProps,
  'v' | 'className' | 'onConnect' | 'onClick' | 'withPulse'
>;

export type ShopifyCardProps = {
  appType: AppType;
  onDisconnect?: () => void;
  loading: boolean;
  isDisconnecting?: boolean;
  isConnected: boolean;
  domain?: string;
} & Pick<IntegrationCardProps, 'v'>;

export default function Integrations() {
  return (
    <BBBContainer
      hasHeading
      pageTitle="Integration"
      pageDescription="Connect your apps and enhance productivity. "
    >
      <div className="text-xl mb-4">E-Commerce</div>
      <div className="grid grid-cols-3 mb-5 gap-5">
        {groupOrder.map((app) => {
          const ShopifyCard = mapAppTypeToShopifyCard[app];
          return <ShopifyCard key={app} />;
        })}
      </div>
      <div className="text-xl mb-4">Communication channels</div>
      <div className="grid grid-cols-3 mb-5 gap-5">
        {shownIntegrations.map((integration) => {
          const Card = mapKnownIntegrationsToCard[integration];

          if (!Card) return null;

          return <Card key={integration} />;
        })}
      </div>
      <div className="text-xl mb-4">Product reviews</div>
      <div className="grid grid-cols-3 mb-5 gap-5">
        <StampedCard />
        <YotpoCard />
      </div>
    </BBBContainer>
  );
}

function YotpoCard() {
  const { data, status } = useYotpoIntegrations();
  const { mutate: toggleIntegration } = useToggleIntegrationBitApp();

  const connectIntegration = useConnectIntegration();

  return (
    <IntegrationCard
      name="yotpo"
      isConnected={data?.status === 'CONNECTED'}
      onConnect={() => {
        connectIntegration({ name: 'yotpo' });
      }}
      loading={status === 'loading'}
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
    />
  );
}

function StampedCard() {
  const { mutate: toggleIntegration } = useToggleIntegrationBitApp();
  const connectIntegration = useConnectIntegration();

  const { data, status } = useStampedIntegrations();

  return (
    <IntegrationCard
      name="stamped"
      isConnected={data?.status === 'CONNECTED'}
      onConnect={() => {
        connectIntegration({ name: 'stamped' });
      }}
      loading={status === 'loading'}
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
    />
  );
}

export function FacebookCard({ onConnect, ...props }: DefaultCardProps) {
  const { mutate: toggleIntegration } = useToggleIntegrationBitChat();

  const { data, status } = useFacebookIntegrations();

  const connectIntegration = useConnectIntegration();

  const isConnecting = useAppSelector((state) =>
    state.common.connectIntegration?.name === 'facebook'
      ? state.common.connectIntegration?.meta?.isLoadingConnect
      : false
  );

  return (
    <IntegrationCard
      name="facebook"
      description={
        data?.status === 'CONNECTED' && (
          <>
            Connected to{' '}
            <span className="font-bold">{data.extra?.fbUsername}</span>
          </>
        )
      }
      isConnected={data?.status === 'CONNECTED'}
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      isConnecting={isConnecting}
      loading={status === 'loading'}
      onConnect={() => {
        if (typeof onConnect !== 'undefined') {
          onConnect();
        } else {
          connectIntegration({ name: 'facebook' });
        }
      }}
      {...props}
    />
  );
}

export function InstagramCard({ onConnect, ...props }: DefaultCardProps) {
  const { mutate: toggleIntegration } = useToggleIntegrationBitChat();
  const isConnecting = useAppSelector((state) =>
    state.common.connectIntegration?.name === 'instagram'
      ? state.common.connectIntegration?.meta?.isLoadingConnect
      : false
  );

  const { data, status } = useInstagramIntegrations();
  const connectIntegration = useConnectIntegration();

  return (
    <IntegrationCard
      name="instagram"
      description={
        data?.status === 'CONNECTED' && (
          <>
            Connected to{' '}
            <span className="font-bold">
              {data.extra?.igUsername || data.extra?.number}
            </span>
          </>
        )
      }
      isConnected={data?.status === 'CONNECTED'}
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={status === 'loading'}
      onConnect={() => {
        if (typeof onConnect !== 'undefined') {
          onConnect();
        } else {
          connectIntegration({ name: 'instagram' });
        }
      }}
      isConnecting={isConnecting}
      {...props}
    />
  );
}

export function WhatsAppBusinessCard({
  onConnect,
  ...props
}: DefaultCardProps) {
  const { mutate: toggleIntegration } = useToggleIntegrationBitChat();
  const connectIntegration = useConnectIntegration();

  const { data, status } = useWhatsappBusinessIntegration();

  return (
    <IntegrationCard
      name="whatsapp"
      description={
        data?.status === 'CONNECTED' && (
          <>
            Connected to{' '}
            <span className="font-bold">
              {formatInternational(data.extra?.number)}
            </span>
          </>
        )
      }
      isConnected={data?.status === 'CONNECTED'}
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={status === 'loading'}
      onConnect={() => {
        if (typeof onConnect !== 'undefined') {
          onConnect();
        } else {
          connectIntegration({ name: 'whatsapp' });
        }
      }}
      {...props}
    />
  );
}

export function WhatsAppCloudApiCard({
  onConnect,
  ...props
}: DefaultCardProps) {
  const { mutate: toggleIntegration } = useToggleIntegrationBitChat();
  const connectIntegration = useConnectIntegration();

  const { data, status } = useWhatsappCloudIntegration();

  return (
    <IntegrationCard
      name="whatsapp_meta"
      description={
        data?.status === 'CONNECTED' && (
          <>
            Connected to{' '}
            <span className="font-bold">
              {formatInternational(data.extra?.number)}
            </span>
          </>
        )
      }
      isConnected={data?.status === 'CONNECTED'}
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={status === 'loading'}
      onConnect={() => {
        if (typeof onConnect !== 'undefined') {
          onConnect();
        } else {
          connectIntegration({ name: 'whatsapp_meta' });
        }
      }}
      {...props}
    />
  );
}

function ShopifyCardBitApp() {
  const { mutate: toggleIntegration, isLoading: loadingToggle } =
    useToggleIntegrationBitApp();

  const { data, isLoading } = useShopifyIntegrationsBitApp();

  return (
    <ShopifyCard
      appType="BITAPP"
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={isLoading}
      isDisconnecting={loadingToggle}
      isConnected={data?.status === 'CONNECTED'}
      domain={data?.extra?.domain}
    />
  );
}

function ShopifyCardBitLogin() {
  const { mutate: toggleIntegration, isLoading: loadingToggle } =
    useToggleIntegrationBitLogin();

  const { data, isLoading } = useShopifyIntegrationsBitLogin();

  return (
    <ShopifyCard
      appType="BITLOGIN"
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={isLoading}
      isDisconnecting={loadingToggle}
      isConnected={data?.status === 'CONNECTED'}
      domain={data?.extra?.domain}
    />
  );
}

function ShopifyCardBitLink() {
  const { mutate: toggleIntegration, isLoading: loadingToggle } =
    useToggleIntegrationBitLink();

  const { data, isLoading } = useShopifyIntegrationsBitLink();

  return (
    <ShopifyCard
      appType="BITLINK"
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={isLoading}
      isDisconnecting={loadingToggle}
      isConnected={data?.status === 'CONNECTED'}
      domain={data?.extra?.domain}
    />
  );
}

export function ShopifyCardBitAi(props: Pick<IntegrationCardProps, 'v'>) {
  const { mutate: toggleIntegration, isLoading: loadingToggle } =
    useToggleIntegrationBitAi();

  const { data, isLoading } = useShopifyIntegrationsBitAi();

  return (
    <ShopifyCard
      appType="BITAI"
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={isLoading}
      isDisconnecting={loadingToggle}
      isConnected={data?.status === 'CONNECTED'}
      domain={data?.extra?.domain}
      {...props}
    />
  );
}

export function ShopifyCardBitChat(props: Pick<IntegrationCardProps, 'v'>) {
  const { mutate: toggleIntegration, isLoading: loadingToggle } =
    useToggleIntegrationBitChat();

  const { data, isLoading } = useShopifyIntegrationsBitChat();

  return (
    <ShopifyCard
      appType="BITCHAT"
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={isLoading}
      isDisconnecting={loadingToggle}
      isConnected={data?.status === 'CONNECTED'}
      domain={data?.extra?.domain}
      {...props}
    />
  );
}

export function ShopifyCardBitCRM(props: Pick<IntegrationCardProps, 'v'>) {
  const { mutate: toggleIntegration, isLoading: loadingToggle } =
    useToggleIntegrationBitCRM();

  const { data, isLoading } = useShopifyIntegrationsBitCRM();

  return (
    <ShopifyCard
      appType="BITCRM"
      onDisconnect={() => {
        if (data) {
          toggleIntegration({
            integrationId: data.integrationId,
            status: 'DISCONNECTED',
          });
        }
      }}
      loading={isLoading}
      isDisconnecting={loadingToggle}
      isConnected={data?.status === 'CONNECTED'}
      domain={data?.extra?.domain}
      {...props}
    />
  );
}

function ShopifyCard({
  appType,
  onDisconnect,
  loading,
  isDisconnecting,
  isConnected,
  domain,
  ...props
}: ShopifyCardProps) {
  const app = mapAppTypeToAppName[appType];
  const connectIntegration = useConnectIntegration();

  const toggleConfirmation = useConfirmationModal();

  return (
    <IntegrationCard
      name="shopify"
      title={`Shopify 🔗 ${app}`}
      description={
        isConnected ? (
          <>
            Connected to <span className="font-bold">{domain}</span>
          </>
        ) : (
          mapDescriptionIntegrationApp[appType]
        )
      }
      isConnected={isConnected}
      loading={loading}
      onDisconnect={() => {
        if (typeof onDisconnect !== 'undefined') {
          toggleConfirmation({
            description:
              'Are you sure want to disconnect this integration? You are able to connect again later',
            title: 'Confirm Integration Disconnect',
            onAccept: (hide) => {
              onDisconnect();
              hide();
            },
            submitText: 'Yes',
            cancelText: 'Cancel',
          });
        }
      }}
      onConnect={() => {
        connectIntegration({
          appType,
          name: 'shopify',
        });
      }}
      isDisconnecting={isDisconnecting}
      {...props}
    />
  );
}

type IntegrationCardProps = {
  description?: ReactNode;
  title?: string;
  logo: ReactNode;
  isConnected: boolean;
  loading?: boolean;
  onConnect?: () => void;
  onDisconnect?: () => void;
  v?: 'automation' | 'v3';
  onClick?: () => void;
  className?: string;
  isConnecting?: boolean;
  isComingSoon?: boolean;
  isDisconnecting?: boolean;
  connectBtnVariant?: BBBButtonTypes['variant'];
  connectBtnLabel?: BBBButtonTypes['text'];
  connectBtnHref?: string;
  withPulse?: boolean;
  beta?: boolean;
};

function IntegrationCard({
  name,
  description,
  title,
  ...props
}: { name: KnownIntegration } & Omit<IntegrationCardProps, 'logo'>) {
  const meta = integrationsMeta[name];

  return (
    <_IntegrationCard
      description={
        !props.v || props.v === 'automation'
          ? description || meta.description
          : meta.description
      }
      title={title || meta.title}
      logo={meta.icon && <meta.icon />}
      {...props}
    />
  );
}

function _IntegrationCard({
  description,
  title,
  logo,
  isConnected,
  loading,
  onConnect,
  onDisconnect,
  v,
  onClick,
  className,
  isConnecting,
  isComingSoon,
  isDisconnecting,
  connectBtnVariant,
  connectBtnLabel,
  connectBtnHref,
  withPulse,
  beta,
}: IntegrationCardProps) {
  const ConnectBtn = (
    <BBBButton
      text={
        isComingSoon
          ? 'Coming soon'
          : isConnecting
          ? 'Connecting'
          : connectBtnLabel || 'Connect'
      }
      onClick={(e) => {
        onConnect?.();
        e.stopPropagation();
      }}
      size={v === 'automation' || v === 'v3' ? 'sm' : undefined}
      className="pointer-events-auto flex-none"
      disabled={isComingSoon || isConnecting}
      variant={v === 'v3' ? 'secondary' : connectBtnVariant}
      loadingState={loading}
    />
  );

  const ActionButton = isConnected ? (
    v === 'automation' ? null : v === 'v3' ? (
      <div className="flex items-center gap-1">
        <div className="text-success-main">Connected</div>
        <CheckIcon2 color={colors.success.main} />
      </div>
    ) : (
      <BBBButton
        text="Disconnect"
        variant="danger-outline"
        onClick={(e) => {
          onDisconnect?.();
          e.stopPropagation();
        }}
        loadingState={isDisconnecting}
      />
    )
  ) : connectBtnHref ? (
    <Link to={connectBtnHref}>{ConnectBtn}</Link>
  ) : (
    ConnectBtn
  );

  const ref = useRef<HTMLDivElement>(null);

  if (v === 'automation' || v === 'v3') {
    return (
      <BBBCard
        className={cx(
          'transition-colors cursor-pointer flex items-center gap-2.5',
          v === 'automation' &&
            (!isConnected
              ? 'pointer-events-none'
              : 'hover:border-secondary-main'),
          className
        )}
        onClick={onClick}
        ref={ref}
      >
        {withPulse && (
          <PulseEffect
            width={ref?.current?.clientWidth}
            height={55}
            style={{
              top: -8,
              left: -6,
            }}
          />
        )}
        <div className="flex-none">{logo}</div>
        <div className="grow">
          {title} {beta && <BetaBadge />}
          {description && <div className="text-sm">{description}</div>}
        </div>{' '}
        {ActionButton}
      </BBBCard>
    );
  }

  return (
    <BBBCard
      title={
        <div className="flex items-center gap-3">
          <div className="flex-none rounded-lg px-3.5 py-2.5 border border-neutral-30">
            {logo}
          </div>{' '}
          {title} {beta && <BetaBadge />}
        </div>
      }
      className="flex flex-col items-start"
    >
      {loading ? (
        <>
          <Skeleton width={200} height={16} className="mb-4" />
          <Skeleton width={100} height={20} className="mb-4" />
        </>
      ) : (
        <>
          <div className="mb-4 text-neutral-50 grow">{description}</div>
          {ActionButton}
        </>
      )}
    </BBBCard>
  );
}

export function SandboxWhatsAppCard({ onConnect, ...props }: DefaultCardProps) {
  return (
    <IntegrationCard
      name="sandbox-whatsapp"
      isConnected
      onConnect={() => {
        if (typeof onConnect !== 'undefined') {
          onConnect();
        }
      }}
      {...props}
    />
  );
}

export function MobilePushNotificationCard({
  onConnect,
  ...props
}: DefaultCardProps) {
  const { data: publishData, isInitialLoading: loadingRecentPublish } =
    useRecentPublish();

  const isEitherLive = !!publishData;

  return (
    <IntegrationCard
      name="app-notification"
      isConnected={isEitherLive}
      loading={loadingRecentPublish}
      onConnect={() => {
        if (typeof onConnect !== 'undefined') {
          onConnect();
        }
      }}
      connectBtnLabel="Launch your mobile app"
      connectBtnHref="/bitapp/publish"
      {...props}
    />
  );
}

export function PulseEffect({
  n = 3,
  duration = 0.5,
  delay = 0.5,
  width = 71,
  height = 43,
  gap = 8,
  style,
}: {
  n?: number;
  duration?: number;
  delay?: number;
  width?: number;
  height?: number;
  gap?: number;
  style?: MotionStyle;
}) {
  return (
    <motion.div
      className="absolute"
      style={{ display: 'grid', placeItems: 'center', ...style }}
    >
      {Array.from({ length: n }, (_, i) => {
        const pulseDelay = delay * i;
        const pulseRepeatDelay = pulseDelay;
        const pulseDuration = duration + delay * (n - i);
        return (
          <motion.div
            key={i}
            className="rounded-xl"
            style={{
              border: `2px solid ${i === 0 ? 'tranparent' : '#fd813e93'}`,
              gridArea: '1 / 1 / 2 / 2',
              width: `${width + gap * i}px`,
              height: `${height + gap * i}px`,
              aspectRatio: '1/1',
              zIndex: n - i,
            }}
            {...(i !== 0 && {
              initial: { opacity: 0, scale: 1 },
              animate: { opacity: [0, 1, 0], scale: 1.01 },
              transition: {
                duration: pulseDuration,
                delay: pulseDelay,
                ease: [0.05, 0.6, 0.3, 0.3],
                repeat: Infinity,
                repeatDelay: pulseRepeatDelay,
              },
            })}
          />
        );
      })}
    </motion.div>
  );
}

export const mapKnownIntegrationsToCard: Partial<
  {
    [k in Exclude<KnownIntegration, 'shopify'>]: (
      params: DefaultCardProps
    ) => JSX.Element;
  } & {
    shopify: (params: ShopifyCardProps) => JSX.Element;
  }
> = {
  'app-notification': MobilePushNotificationCard,
  whatsapp: WhatsAppBusinessCard,
  whatsapp_meta: WhatsAppCloudApiCard,
  yotpo: YotpoCard,
  stamped: StampedCard,
  instagram: InstagramCard,
  facebook: FacebookCard,
  shopify: ShopifyCard,
  'sandbox-whatsapp': SandboxWhatsAppCard,
};
