import { useMemo } from 'react';
import { startCase } from 'lodash-es';

import WhatsAppCloudApiIcon from '@/assets/icons/WhatsAppCloudApiIcon';
import WhatsAppIcon4 from '@/assets/icons/WhatsAppIcon4';
import { BBBTooltip } from '@/components';
import BBBBadge from '@/components/BBBBadge/BBBBadge';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/Link';
import { integrationsMeta } from '@/constants/integrations';
import { mapSourceToKnownIntegrations } from '@/constants/whatsApp';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { Accent } from '@/types/utils/color';
import { getTemplateUrl, isTemplateNoAction } from '@/utils/bitCRM';
import { formatDate2 } from '@/utils/common/date';

const mapStatusToText = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  WAITING: 'Waiting',
  SUBMITTED: 'Submitted',
  ERROR: 'Error',
};

const mapStatusToBadgeVariant: Record<string, Accent> = {
  APPROVED: 'success',
  REJECTED: 'danger',
  WAITING: 'info',
  SUBMITTED: 'info',
  ERROR: 'danger',
};

const mapSourceToIcon = {
  WHATSAPP: <WhatsAppIcon4 />,
  WHATSAPP_CLOUD_API: <WhatsAppCloudApiIcon />,
};

const useColumn = () => {
  return useMemo<HeadersTypes<CampaignTemplate>>(() => {
    return [
      {
        accesor: 'templateName',
        isSortable: true,
        renderHeader: () => 'Template name',
        columnWidth: '20%',
        render: (info) => (
          <div className="line-clamp-1">
            {!isTemplateNoAction(info) ? (
              <Link
                to={getTemplateUrl(info.type, info.id)}
                className="text-primary-main group-hover:underline"
              >
                {info.templateName || '-'}
              </Link>
            ) : (
              info.templateName || '-'
            )}
          </div>
        ),
      },
      {
        accesor: 'type',
        renderHeader: () => 'Type',
        isSortable: true,
        columnWidth: '14%',
        render: (info) => {
          const separatorIdx = info.type.indexOf('_');
          const type = info.type.substring(0, separatorIdx);
          const source = info.type.substring(
            separatorIdx + 1,
            info.type.length
          );
          return (
            <div className="text-primary-main flex items-center gap-2.5">
              <span className="w-24">{startCase(type.toLowerCase())} </span>
              <BBBTooltip
                content={
                  integrationsMeta[mapSourceToKnownIntegrations[source]].title
                }
                show
              >
                {mapSourceToIcon[source as keyof typeof mapSourceToIcon]}
              </BBBTooltip>
            </div>
          );
        },
      },
      {
        accesor: 'message',
        renderHeader: () => 'Message',
        columnWidth: '38%',
        render: (info) => {
          return (
            <div className="line-clamp-1 text-primary-main whitespace-pre-line">
              <BBBTooltip
                content={
                  <div className="line-clamp-4">
                    {info.type === 'CAMPAIGN_WHATSAPP' ||
                    info.type === 'AUTOMATION_WHATSAPP'
                      ? info.message.replace(/<[^>]+>/g, '')
                      : info.body?.message?.replace(/<[^>]+>/g, '') || ''}
                  </div>
                }
                show={
                  info.type === 'CAMPAIGN_WHATSAPP' ||
                  info.type === 'AUTOMATION_WHATSAPP'
                    ? info.message.replace(/<[^>]+>/g, '').length > 50
                    : info.body?.message &&
                      info.body?.message?.replace(/<[^>]+>/g, '').length > 50
                    ? true
                    : false
                }
              >
                {info.type === 'CAMPAIGN_WHATSAPP' ||
                info.type === 'AUTOMATION_WHATSAPP'
                  ? info.message.replace(/<[^>]+>/g, '')
                  : info.body?.message?.replace(/<[^>]+>/g, '') || ''}
              </BBBTooltip>
            </div>
          );
        },
      },
      {
        accesor: 'status',
        renderHeader: () => 'Status',
        isSortable: true,
        columnWidth: '9%',
        render: (info) => {
          const status = info.status;
          return (
            <>
              {status ? (
                <BBBBadge
                  className="px-2 py-1 text-sm"
                  type={mapStatusToBadgeVariant[status]}
                  text={
                    mapStatusToText[status as keyof typeof mapStatusToText] ||
                    status
                  }
                />
              ) : (
                <BBBBadge
                  className="px-2 py-1 text-sm"
                  type="neutral"
                  text="Draft"
                />
              )}
            </>
          );
        },
      },
      {
        accesor: 'updatedAt',
        renderHeader: () => 'Last Updated',
        isSortable: true,
        columnWidth: '15%',
        sortOrder: 'desc',
        render: (info) => {
          const updatedAt = info.updatedAt;
          return (
            <>
              {!updatedAt ? null : (
                <p className="text-primary-main">
                  {formatDate2(updatedAt, '12-hour', 'chat')}
                </p>
              )}
            </>
          );
        },
      },
    ];
  }, []);
};

export default useColumn;
