import { createId } from '@paralleldrive/cuid2';

import { BBBButton, BBBTooltip } from '@/components';
import { EVERYONE_AGENT_ID, staticAgents } from '@/constants/bitChat/agent';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { useUserId } from '@/hooks/rtk/selector';
import { useUpdateChat } from '@/hooks/whatsApp/chat';
import { Chat, ChatTicketHumanAgent } from '@/types/whatsApp/chat';
import { formatTicketAgentPayload } from '@/utils/chat';

type Props = {
  className?: string;
  id?: string;
  ticket: Chat['ticket'];
  status: Chat['status'];
};

export default function HandleTicket({
  className,
  id = createId(),
  ticket,
  status,
}: Props) {
  const userId = useUserId();

  const { mutate: updateChat, isLoading: loadingUpdateChat } = useUpdateChat();

  const {
    isReachingTreshold,
    validatePricing,
    loading: loadingPricing,
  } = useWithCRMPricing('BITCHAT', 'conversation');

  const { data: isRestricted, status: moduleRestrictionStatus } =
    useIsModuleRestricted('BITCHAT_HANDLE_TICKET');

  const agentId = ticket?.userId;
  const ticketStatus = status;

  return (
    <div className={className} id={`handle-ticket-${id}`}>
      <BBBButton
        text={
          <>
            {isReachingTreshold ? (
              'Upgrade now'
            ) : ticket?.userId ? (
              userId === ticket.userId ? (
                ticketStatus === 'Assigned' ? (
                  'Handle ticket'
                ) : (
                  'Resolve ticket'
                )
              ) : (
                <TakeOverAgentText
                  agentId={ticket.userId}
                  agentName={
                    (ticket as ChatTicketHumanAgent).agentName ||
                    (ticket as ChatTicketHumanAgent).agentEmail
                  }
                />
              )
            ) : (
              'Handle ticket'
            )}
          </>
        }
        variant={
          userId === agentId && ticketStatus === 'Active'
            ? 'secondary'
            : 'primary'
        }
        loadingState={
          loadingUpdateChat || moduleRestrictionStatus === 'loading'
        }
        onClick={() => {
          validatePricing(() => {
            if (agentId) {
              if (userId === agentId) {
                if (ticketStatus === 'Assigned') {
                  updateChat({ status: 'join' });
                } else {
                  updateChat({ status: 'close' });
                }
              } else {
                updateChat({
                  status: 'took_over',
                  oldUser: formatTicketAgentPayload(ticket!),
                });
              }
            } else {
              updateChat({ status: 'join' });
            }
          });
        }}
        width="full"
        disabled={isRestricted || loadingPricing}
      />
    </div>
  );
}

function TakeOverAgentText({
  agentId,
  agentName,
}: {
  agentId: number;
  agentName?: string;
}) {
  const takeOverAgentText = `Take over chat from ${
    !staticAgents.includes(agentId)
      ? `${agentName}`
      : agentId === EVERYONE_AGENT_ID
      ? 'everyone'
      : 'AI agent'
  }`;

  return (
    <div className="flex items-center gap-2 justify-center">
      <BBBTooltip content={takeOverAgentText} show>
        <span className="whitespace-pre-wrap line-clamp-1">
          {takeOverAgentText}
        </span>
      </BBBTooltip>
    </div>
  );
}
