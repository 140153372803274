import { twMerge as cx } from 'tailwind-merge';

import { BBBCheckbox } from '@/components';
import { Customer } from '@/types/customers';

function ProfilePicture({
  checked,
  containerClassName,
  imageClassName,
  imageUrl,
  firstName,
  lastName,
  email,
  ...props
}: Partial<Pick<Customer, 'imageUrl' | 'firstName' | 'lastName' | 'email'>> & {
  containerClassName?: string;
  imageClassName?: string;
  checked?: boolean;
} & Omit<JSX.IntrinsicElements['div'], 'className'>) {
  return (
    <div className={cx('flex-none relative', containerClassName)} {...props}>
      {checked && (
        <div className="absolute top-0 left-3/4">
          <BBBCheckbox checked />
        </div>
      )}
      {!imageUrl ? (
        <div
          className={cx(
            'w-20 h-20 bg-[#2B6AAF] rounded-full flex items-center justify-center',
            imageClassName
          )}
        >
          <div className="text-3xl text-neutral-10 font-semibold">
            {[firstName?.slice(0, 1), lastName?.slice(0, 1)]
              .filter(Boolean)
              .join('')}
          </div>
        </div>
      ) : (
        <img
          className={cx('w-20 h-20 rounded-full object-cover', imageClassName)}
          src={imageUrl}
        />
      )}
    </div>
  );
}

export default ProfilePicture;
