import InfiniteScroll from 'react-infinite-scroll-component';
import { DropdownTypes } from '../BBBSelect.type';

import { BBBBottomSheet, BBBSpinner } from '@/components';
import { Nullable } from '@/types/utils/nullable';

function BBBSelectDropdownOptionMobile<
  T extends {
    [k: string]: any;
  }
>({
  isMenuOpen,
  isPaginated,
  data,
  fetchNext,
  hasMore,
  placeholder,
  onClose,
  children,
}: {
  isMenuOpen: boolean | undefined;
  data: Nullable<T[]>;
  onClose?: () => void;
  children?: React.ReactNode;
} & DropdownTypes<T>) {
  return (
    <BBBBottomSheet
      show={isMenuOpen}
      onClose={onClose}
      className="p-0"
      headerClassName="p-3"
    >
      <div className="max-h-96 overflow-auto" id="bottom-sheet-scrollable">
        {isPaginated ? (
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={fetchNext}
            hasMore={hasMore}
            loader={<BBBSpinner width={3} height={16} />}
            scrollableTarget={'bottom-sheet-scrollable'}
            scrollThreshold={0}
          >
            {children}
          </InfiniteScroll>
        ) : (
          <div className="flex flex-col">{children}</div>
        )}
      </div>
    </BBBBottomSheet>
  );
}

export default BBBSelectDropdownOptionMobile;
