import { useMutation } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { downloadXlsxFile } from '@/utils/common/file';
import { toast } from '@/utils/common/toast';

const useDownloadAgents = (
  params: Parameters<typeof services.bitChat.analytics.downloadAgents>[1]
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useMutation(
    async () =>
      services.bitChat.analytics.downloadAgents(activeCompany, {
        startDate: params.startDate,
        endDate: params.endDate,
        search: params.search,
      }),
    {
      onSuccess: (data) => {
        downloadXlsxFile(data, 'agents');
        toast.success('Your file is being downloaded');
      },
    }
  );
};

export default useDownloadAgents;
