import {
  ParseError,
  parsePhoneNumberWithError,
  PhoneNumber,
} from 'libphonenumber-js';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';

import { BBBTelInputValue } from '@/components';

export const formatInternational = (phoneNumber?: string | null) => {
  let parsedPhoneNumber: PhoneNumber | undefined;
  let error = '';

  try {
    parsedPhoneNumber = parsePhoneNumberWithError(`+${phoneNumber}`);
  } catch (err) {
    if (err instanceof ParseError) {
      error = err.message;
    } else {
      throw error;
    }
  }

  return phoneNumber
    ? error
      ? phoneNumber
      : parsedPhoneNumber!.formatInternational()
    : '-';
};

export function formatPhonePayload(telInputValue: BBBTelInputValue): string;
export function formatPhonePayload(
  telInputValue: BBBTelInputValue | null | undefined
): undefined;
export function formatPhonePayload(
  telInputValue: BBBTelInputValue | null | undefined
) {
  if (telInputValue && telInputValue.phoneNumber) {
    return telInputValue.countryCode + telInputValue.phoneNumber;
  }

  return undefined;
}

export const phoneValidator = (optional?: boolean) =>
  yup
    .mixed<BBBTelInputValue>()
    .test('phone', 'Phone number is invalid', (value) =>
      optional
        ? value?.phoneNumber
          ? isPossiblePhoneNumber(`+${value?.countryCode}${value?.phoneNumber}`)
          : true
        : isPossiblePhoneNumber(`+${value?.countryCode}${value?.phoneNumber}`)
    );
