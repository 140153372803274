import React, { useMemo, useState } from 'react';
import { Calendar } from 'react-feather';
import { TicketPerfomance } from 'api/services/whatsApp/analytics';
import dayjs from 'dayjs';
import BBBAnalyticsSelectRange from '../../SelectRange';

import { BBBCard, BBBContainer, BBBSelect } from '@/components';
import BBBBadge from '@/components/BBBBadge/BBBBadge';
import BBBTableV2 from '@/components/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useTicketPerformance from '@/hooks/whatsApp/useTicketPerformance';
import TicketModal from '@/pages/BitChat/Ticket/TicketModal/TicketModal';
import { selectedDate } from '@/types/datePicker';
import { Ticket } from '@/types/whatsApp/ticket';
import { formatUserDisplayName } from '@/utils/auth';
import getRangeValue, { formatDate2 } from '@/utils/common/date';
import { formatDuration } from '@/utils/common/time';

export default function TicketPerformance() {
  const query = useQuerySearchParams();
  const _startDate = query.get('startDate');
  const _endDate = query.get('endDate');

  const startDate = dayjs(_startDate).toDate();
  const endDate = dayjs(_endDate).toDate();

  const [selectedDay, setSelectedDay] = useState<selectedDate>(startDate);
  const [selectedEndDay, setSelectedEndDay] = useState<selectedDate>(endDate);
  const [selectedTicket, setSelectedTicket] =
    useState<Pick<Ticket, 'id' | 'sources'>>();

  const [activeRange, setActiveRange] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [agents, setAgents] = useState<number>();
  const [tags, setTags] = useState<string>();
  const [sort, setSort] = useState<string | undefined>(`lastActivity:desc`);

  const rangeValue = getRangeValue(selectedDay, selectedEndDay);

  const companyUsersQuery = useCompanyUsers({
    limit: 10,
  });

  const { data: userCompanies, hasNextPage, fetchNextPage } = companyUsersQuery;

  const { data: settingsData, isInitialLoading: isLoadingSettingsData } =
    useSettings();

  const userCompaniesData = useMemo(
    () =>
      userCompanies?.pages.flatMap((page) =>
        page.data.map((uC) => ({
          label: formatUserDisplayName(uC.user) ?? `-`,
          value: uC.userId,
        }))
      ),
    [userCompanies]
  );

  const ticketTagsData = useMemo(
    () =>
      settingsData?.ticketTags?.map((tag) => ({
        label: tag.label,
        value: tag.id,
      })),
    [settingsData]
  );

  const toggleShowRange = () => {
    setActiveRange((prev) => !prev);
  };

  const { data, isLoading } = useTicketPerformance({
    startDate: dayjs(selectedDay).toISOString(),
    endDate: dayjs(selectedEndDay).toISOString(),
    limit: pageSize,
    page: pageIndex + 1,
    ...(agents && { agents }),
    ...(tags && { tags }),
    ...(search && { search }),
    ...(sort && { sort }),
  });

  const columns = useMemo<HeadersTypes<TicketPerfomance>>(() => {
    return [
      {
        accesor: 'id',
        isSortable: true,
        renderHeader: () => 'Ticket ID',
        render: (row) => (
          <p
            className="text-primary-main hover:underline cursor-pointer transition-all"
            onClick={() =>
              setSelectedTicket({
                id: row.id,
                sources: row.sources,
              })
            }
          >
            #{row.id}
          </p>
        ),
      },
      {
        accesor: 'tag.Tags.label',
        renderHeader: () => 'Category',
        isSortable: true,
        render: (row) => (
          <BBBBadge
            key={row.tag.Tags.label}
            text={row.tag.Tags.label}
            type={row.tag.Tags.color}
          />
        ),
      },
      {
        accesor: 'responseTime.hours',
        renderHeader: () => 'Response time',
        isSortable: true,
        render: (row) => (
          <p className="text-primary-main">
            {formatDuration(row.responseTime)}
          </p>
        ),
      },
      {
        accesor: 'resolutionTime.hours',
        renderHeader: () => 'Resolution time',
        isSortable: true,
        render: (row) => (
          <p className="text-primary-main">
            {formatDuration(row.resolutionTime)}
          </p>
        ),
      },
      {
        accesor: 'rating',
        renderHeader: () => 'Rating',
        isSortable: true,
        columnWidth: 80,
        render: (row) => (
          <p className="text-primary-main">{row.rating || '-'}</p>
        ),
      },
      {
        accesor: 'lastAgent',
        renderHeader: () => 'Last Agent Handled',
        isSortable: true,
        render: (row) => (
          <p className="text-primary-main">
            {row.lastAgent ? row.lastAgent?.agentName : '-'}
          </p>
        ),
      },
      {
        accesor: 'lastActivity',
        renderHeader: () => 'Last Activity',
        isSortable: true,
        sortOrder: 'desc',
        render: (row) => {
          return (
            <p className="text-primary-main">{formatDate2(row.lastActivity)}</p>
          );
        },
      },
    ];
  }, []);

  return (
    <BBBContainer
      hasHeading
      pageTitle="Ticket performance"
      pageDescription="Track your ticket performance"
      hasBack
      backUrl="/analytics?tab=bitchat"
      rightComponent={
        <div className="relative cursor-pointer">
          <BBBCard onClick={toggleShowRange} className="!px-4 !py-[10px]">
            <div className="flex gap-3">
              <Calendar width={24} height={24} />
              <div className="font-bold text-primary-main">
                {rangeValue?.label}
              </div>
            </div>
          </BBBCard>
          {activeRange && (
            <BBBAnalyticsSelectRange
              selectedDay={selectedDay}
              selectedEndDay={selectedEndDay}
              setSelectedDay={setSelectedDay}
              setSelectedEndDay={setSelectedEndDay}
              active={activeRange}
              onChangeActive={setActiveRange}
            />
          )}
        </div>
      }
    >
      <BBBTableV2
        data={data?.data || []}
        dataId="id"
        headers={columns}
        loadingBody={isLoading}
        isSearchable
        searchPlaceholder="Search ticket"
        searchValue={search}
        onChangeSearch={setSearch}
        isFilterable
        renderFilterSection={() => (
          <>
            <BBBSelect
              placeholder="Agent"
              optionLabel="label"
              optionValue="value"
              options={userCompaniesData}
              onValueChange={(val) => setAgents(val?.value)}
              isPaginated
              hasMore={!!hasNextPage}
              fetchNext={fetchNextPage}
            />
            <BBBSelect
              placeholder="Category"
              optionLabel="label"
              optionValue="value"
              options={ticketTagsData}
              onValueChange={(val) => setTags(val?.value)}
            />
          </>
        )}
        isPaginate
        pagination={{
          page: pageIndex + 1,
          limit: pageSize,
          onChange: (page) => setPageIndex(page - 1),
          total: data?.pageable?.totalElements || 0,
        }}
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(page) => {
          setPageSize(page!);
          setPageIndex(0);
        }}
        isAsyncSort
        onClickSortHeader={(key, sort) => {
          setSort(`${key}:${sort}`);
        }}
      />
      {selectedTicket && (
        <TicketModal
          ticket={selectedTicket}
          onHide={() => setSelectedTicket(undefined)}
        />
      )}
    </BBBContainer>
  );
}
