import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '../..';

import {
  BBBCard,
  BBBImageUpload,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
} from '@/components';
import { textColorOptions } from '@/constants/bitChat/waWidget';

type Props = {
  control: Control<FormSchema>;
  error: FieldErrors<FormSchema>;
  loading: boolean;
};

export default function Appearance({ control, error, loading }: Props) {
  const enabled = useWatch({
    control,
    name: 'showCard',
  });

  return (
    <BBBCard
      title="Widget Appearance"
      className="mb-cardBottom"
      id="widget-appearance"
      rightButton={
        <>
          {loading ? (
            <Skeleton width={100} height={20} />
          ) : (
            <Controller
              control={control}
              name="showCard"
              render={({ field }) => (
                <BBBPrimarySwitch
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          )}
        </>
      }
    >
      <div
        className={cx(
          'transition-opacity',
          !enabled && 'opacity-25 pointer-events-none'
        )}
      >
        <Controller
          control={control}
          name="storeLogo"
          render={({ field }) => (
            <BBBImageUpload
              label="Profile picture"
              containerClassName="mb-5"
              imageUrl={field.value}
              onChangeImage={field.onChange}
              crop={{ circular: true }}
            />
          )}
        />
        <BBBTextInput
          label="Widget header"
          labelClassname="mb-3"
          isHookForm
          control={control}
          placeholder="Enter widget header"
          containerClassname="mb-5"
          error={error?.storeName?.message}
          controlName="storeName"
          disabled={loading}
          hasMaxCharLabel
          maxChar={20}
        />
        <BBBTextInput
          label="Widget sub-header"
          labelClassname="mb-3"
          isHookForm
          control={control}
          placeholder="Enter widget sub-header"
          containerClassname="mb-5"
          error={error?.storeDescription?.message}
          controlName="storeDescription"
          disabled={loading}
          hasMaxCharLabel
          maxChar={40}
        />
        <Controller
          control={control}
          name="textColor"
          render={({ field }) => {
            return (
              <BBBSelect
                label="Text & icon color"
                labelClassName="mb-3"
                optionLabel="label"
                optionValue="value"
                placeholder="Light"
                value={field.value}
                isDisabled={loading}
                onValueChange={field.onChange}
                options={textColorOptions}
              />
            );
          }}
        />
      </div>
    </BBBCard>
  );
}
