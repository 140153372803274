import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function AnalyticsIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <AnalyticsIconDefault />
      ) : (
        <AnalyticsIconActive />
      )}
    </>
  );
}

function AnalyticsIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      fill="none"
      viewBox="0 0 23 20"
    >
      <g fill="#FD823E" clipPath="url(#clip0_6824_92315)">
        <path d="M22.25 17.428H.75c-.414 0-.75.34-.75.76v1.05c0 .42.336.76.75.76h21.5c.414 0 .75-.34.75-.76v-1.05c0-.42-.336-.76-.75-.76zM2.275 14.223c.3-.012.587-.133.809-.339l2.103-1.824 3.166-2.742 1.468 1.69.302.355a1.146 1.146 0 00.812.341c.302-.009.59-.13.81-.341l1.004-.93 2.419-2.249 2.92-2.71 2.523-2.344c.41-.379.83-.75 1.231-1.14a1.17 1.17 0 00.016-1.66 1.163 1.163 0 00-1.62 0l-1.005.93-2.42 2.251-2.918 2.71-2.524 2.353-.351.321-1.436-1.654c-.1-.119-.204-.233-.305-.351a1.139 1.139 0 00-.809-.342 1.287 1.287 0 00-.812.342l-2.1 1.82-3.327 2.864-.768.667a1.17 1.17 0 00-.335.82c.002.308.122.603.335.823a1.147 1.147 0 00.812.34z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92315">
          <path fill="#fff" d="M0 0H23V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function AnalyticsIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      fill="none"
      viewBox="0 0 23 20"
    >
      <g fill="#404040" clipPath="url(#clip0_6824_92313)">
        <path d="M22.25 17.428H.75c-.414 0-.75.34-.75.76v1.05c0 .42.336.76.75.76h21.5c.414 0 .75-.34.75-.76v-1.05c0-.42-.336-.76-.75-.76zM2.275 14.223c.3-.012.587-.133.809-.339l2.103-1.824 3.166-2.742 1.468 1.69.302.355a1.146 1.146 0 00.812.341c.302-.009.59-.13.81-.341l1.004-.93 2.419-2.249 2.92-2.71 2.523-2.344c.41-.379.83-.75 1.231-1.14a1.17 1.17 0 00.016-1.66 1.163 1.163 0 00-1.62 0l-1.005.93-2.42 2.251-2.918 2.71-2.524 2.353-.351.321-1.436-1.654c-.1-.119-.204-.233-.305-.351a1.139 1.139 0 00-.809-.342 1.287 1.287 0 00-.812.342l-2.1 1.82-3.327 2.864-.768.667a1.17 1.17 0 00-.335.82c.002.308.122.603.335.823a1.147 1.147 0 00.812.34z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92313">
          <path fill="#fff" d="M0 0H23V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AnalyticsIcon;
