export default function MessageIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M8.834 10.667a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 5.333a1 1 0 011-1h13.333a1 1 0 110 2H9.833a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M7.167 5A1.667 1.667 0 005.5 6.667v19.816l2.752-3.441a3.669 3.669 0 012.862-1.375h14.72A1.666 1.666 0 0027.5 20V6.667A1.667 1.667 0 0025.833 5H7.167zm-2.593-.926A3.667 3.667 0 017.167 3h18.666A3.667 3.667 0 0129.5 6.667V20a3.667 3.667 0 01-3.667 3.667H11.115a1.666 1.666 0 00-1.3.624l-3.108 3.885a1.803 1.803 0 01-2.001.576A1.8 1.8 0 013.5 27.054V6.667c0-.973.386-1.905 1.074-2.593z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
