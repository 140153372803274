import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import { Control, Controller, useFieldArray, useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { createId, isCuid } from '@paralleldrive/cuid2';
import BBBDraggableIcon from 'assets/icons/BBBDraggableIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useDefaultCountryCode from 'hooks/common/useDefaultCountryCode';
import { useDiscardOrLeaveModal } from 'hooks/modal';
import { useConnectedToAnyChannels } from 'hooks/whatsApp/channels';
import {
  useCreateForm,
  useFormElements,
  useTestForm,
} from 'hooks/whatsApp/form';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { isEqual, omit } from 'lodash-es';
import { ExploreSection } from 'pages/BitLink/Link/components/Links/LinkCreate';
import { twMerge as cx } from 'tailwind-merge';
import {
  FormOption,
  FormTemplate,
  FormTemplateElements,
  FormTemplateElementType,
} from 'types/whatsApp/form';
import {
  BBBAlert,
  BBBButton,
  BBBCard,
  BBBCheckbox,
  BBBContainer,
  BBBImageUpload,
  BBBModal,
  BBBSpinner,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextAreaInput,
  BBBTextInput,
} from 'components';
import { formatPhonePayload } from 'utils/common/phone';
import TrashWithTransition from '../Chatbot/components/TrashWithTransition';
import { HeightPreservingItem } from '../Ticket';

export const sectionOptions: (
  | {
      label: string;
      id: string;
      name: FormTemplateElementType;
      icon?: ((props: { size?: number }) => JSX.Element) | JSX.Element;
      description?: string;
      parent: string;
      __typename: 'child';
    }
  | {
      label: string;
      id: string;
      name: string;
      icon?: ((props: { size?: number }) => JSX.Element) | JSX.Element;
      description?: string;
      __typename: 'parent';
    }
)[] = [
  {
    label: 'Text',
    id: createId(),
    name: 'Text',
    icon: HeadingIcon,
    __typename: 'parent',
  },
  {
    label: 'Large heading',
    id: createId(),
    name: 'TextHeading',
    icon: HeadingIcon,
    parent: 'Text',
    __typename: 'child',
  },
  {
    label: 'Small heading',
    id: createId(),
    name: 'TextSubheading',
    icon: HeadingIcon,
    parent: 'Text',
    __typename: 'child',
  },
  {
    label: 'Caption',
    id: createId(),
    name: 'TextCaption',
    icon: HeadingIcon,
    parent: 'Text',
    __typename: 'child',
  },
  {
    label: 'Body',
    id: createId(),
    name: 'TextBody',
    icon: HeadingIcon,
    parent: 'Text',
    __typename: 'child',
  },
  {
    label: 'Media',
    id: createId(),
    name: 'Media',
    icon: MediaIcon,
    __typename: 'parent',
  },
  {
    label: 'Image',
    id: createId(),
    name: 'Image',
    icon: MediaIcon,
    parent: 'Media',
    __typename: 'child',
  },
  {
    label: 'Text answer',
    id: createId(),
    name: 'TextAnswer',
    icon: ShortAnswerIcon,
    __typename: 'parent',
  },
  {
    label: 'Short answer',
    id: createId(),
    name: 'TextInput',
    icon: ShortAnswerIcon,
    parent: 'TextAnswer',
    __typename: 'child',
  },
  {
    label: 'Paragraph answer',
    id: createId(),
    name: 'TextArea',
    icon: ShortAnswerIcon,
    parent: 'TextAnswer',
    __typename: 'child',
  },
  {
    label: 'Datetime input',
    id: createId(),
    name: 'DatePicker',
    icon: DatetimeIcon,
    parent: 'TextAnswer',
    __typename: 'child',
  },
  {
    label: 'Selection',
    id: createId(),
    name: 'Selection',
    icon: DropdownIcon,
    __typename: 'parent',
  },
  {
    label: 'Dropdown',
    id: createId(),
    name: 'Dropdown',
    icon: DropdownIcon,
    parent: 'Selection',
    __typename: 'child',
  },
  {
    label: 'Multiple choice',
    id: createId(),
    name: 'CheckboxGroup',
    icon: MultipleChoiceIcon,
    parent: 'Selection',
    __typename: 'child',
  },
  {
    label: 'Single choice',
    id: createId(),
    name: 'RadioButtonsGroup',
    icon: SingleChoiceIcon,
    parent: 'Selection',
    __typename: 'child',
  },
  {
    label: 'Opt in',
    id: createId(),
    name: 'OptIn',
    icon: SwitchIcon,
    parent: 'Selection',
    __typename: 'child',
  },
];

type FormDetailElement = {
  title: string;
  active: boolean;
  elementId: string;
  type: FormTemplateElementType;
  required: boolean;
  options: FormOption[];
  media: string | null;
};

export default function FormDetail() {
  const { id } = useParams<{ id: string }>();
  const { data: isConnected, status: queryStatus } = useConnectedToAnyChannels(
    'whatsapp_meta',
    { enabled: id === 'new' }
  );

  if (id === 'new') {
    if (queryStatus === 'loading') return <BBBSpinner />;

    if (queryStatus === 'error' || !isConnected)
      return <Redirect to={'/bitchat/whatsapp-form'} />;

    return <_FormDetail />;
  }

  return <_FormDetail />;
}

function _FormDetail() {
  const { id } = useParams<{ id: string }>();
  const formQuery = useFormElements(id);

  if (id !== 'new') {
    if (formQuery.status === 'loading') return <BBBSpinner />;

    if (formQuery.status === 'error' || formQuery.data.status === 'PUBLISHED')
      return <Redirect to={'/bitchat/whatsapp-form'} />;

    return <__FormDetail data={formQuery.data} />;
  }

  return <__FormDetail />;
}

function __FormDetail({
  data: formDetail,
}: {
  data?: FormTemplate & {
    element: FormTemplateElements[];
  };
}) {
  const { id } = useParams<{ id: string }>();
  const query = useQuerySearchParams();

  const prefilledName =
    id === 'new' ? (query.get('prefill_name') as string) : '';

  const [active, setActive] = useState(false);
  const [showTestForm, setShowTestForm] = useState(false);

  const [showCreate, setShowCreate] = useState<
    FormTemplateElementType | { data: FormDetailElement; index: number }
  >();

  const { control, reset, watch, handleSubmit } = useForm<{
    id?: string;
    name: string;
    elements: FormDetailElement[];
  }>({
    defaultValues: { id: undefined, name: prefilledName, elements: [] },
  });

  const { fields, move, update, append, remove } = useFieldArray({
    control,
    name: 'elements',
  });

  const { mutate: createForm, isLoading: loadingCreateForm } = useCreateForm();

  const dataFromApi = useMemo(
    () => ({
      id: formDetail?.id,
      name: formDetail?.name || prefilledName,
      elements:
        formDetail?.element
          .filter(
            (_el) => !!sectionOptions.find((opt) => opt.name === _el.type)
          )
          .map((_el) => ({
            title: _el.title,
            elementId: _el.id,
            type: _el.type,
            required: !!_el.required,
            options: _el.options,
            media: _el.media,
          })) || [],
    }),
    [formDetail?.element, formDetail?.id, formDetail?.name, prefilledName]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const modalType = showCreate
    ? typeof showCreate === 'object'
      ? showCreate.data.type
      : showCreate
    : undefined;

  const { toggle } = useConfirmationBanner();

  const isFormEqual = isEqual(watch(), dataFromApi);

  const submitForm = useCallback(
    (options?: { onSuccess: () => void }) => {
      handleSubmit((data) => {
        createForm(
          {
            ...data,
            elements: data.elements.map(
              ({ elementId, options, type, media, ..._el }, index) => {
                return {
                  ..._el,
                  elementId: isCuid(elementId) ? undefined : elementId,
                  options: options || undefined,
                  index,
                  type,
                  ...(type === 'Image' && {
                    media,
                  }),
                };
              }
            ),
            isTest: false,
          },
          options
        );
      })();
    },
    [createForm, handleSubmit]
  );

  const [handleDiscard] = useDiscardOrLeaveModal({
    isFormEqual,
    module: id !== 'new' ? 'changes' : 'form',
    onSave: (hide, leaveCb) => {
      hide();
      submitForm({
        onSuccess: () => {
          hide();
          leaveCb?.();
        },
      });
    },
    loadingSave: loadingCreateForm,
  });

  useEffect(() => {
    toggle('form-elements', {
      text: loadingCreateForm ? 'Saving form...' : `Unsaved form`,
      show: !isFormEqual,
      variant: loadingCreateForm ? 'loading' : 'actionable',
      isCancelable: true,
      cancelLabel: 'Discard form',
      acceptLabel: 'Save as draft',
      onCancel: handleDiscard,
      onAccept: () => {
        submitForm();
      },
    });
  }, [
    dataFromApi,
    handleDiscard,
    isFormEqual,
    loadingCreateForm,
    reset,
    submitForm,
    toggle,
  ]);

  const containerRef = document.getElementById('whatsapp-form-container');

  const handleDelete =
    typeof showCreate === 'object'
      ? () => {
          remove(showCreate.index);
          setShowCreate(undefined);
        }
      : undefined;

  return (
    <BBBContainer
      hasHeading
      pageTitle="Create WhatsApp Form"
      pageDescription="Send a form to your customers directly through WhatsApp"
      rightComponent={
        <div className="relative flex items-center gap-2">
          {id !== 'new' && (
            <BBBButton
              text="Test form"
              variant="secondary"
              onClick={() => setShowTestForm(true)}
            />
          )}
          <BBBButton text="Add section" onClick={() => setActive(true)} />
          <ExploreSection
            sectionOptions={sectionOptions}
            active={active}
            setActive={setActive}
            onClickSection={(section) => {
              setShowCreate(section.name as FormTemplateElementType);
              setActive(false);
            }}
          />
        </div>
      }
      hasBack
      backUrl="/bitchat/whatsapp-form"
      id="whatsapp-form-container"
      className="mb-24"
    >
      {showTestForm && (
        <TestFormModal formId={id} onHide={() => setShowTestForm(false)} />
      )}
      {modalType &&
        (modalType === 'TextHeading' ||
        modalType === 'TextSubheading' ||
        modalType === 'TextInput' ||
        modalType === 'DatePicker' ||
        modalType === 'TextCaption' ||
        modalType === 'TextBody' ||
        modalType === 'TextArea' ||
        modalType === 'OptIn' ? (
          <TextInputModal
            type={modalType}
            onHide={() => setShowCreate(undefined)}
            onSave={(data) => {
              if (typeof showCreate === 'object') {
                update(showCreate.index, data);
              } else {
                append(data);
              }
            }}
            data={typeof showCreate === 'object' ? showCreate.data : undefined}
            onDelete={handleDelete}
          />
        ) : modalType === 'Dropdown' ||
          modalType === 'CheckboxGroup' ||
          modalType === 'RadioButtonsGroup' ? (
          <OptionModal
            type={modalType}
            onHide={() => setShowCreate(undefined)}
            onSave={(data) => {
              if (typeof showCreate === 'object') {
                update(showCreate.index, data);
              } else {
                append(data);
              }
            }}
            data={typeof showCreate === 'object' ? showCreate.data : undefined}
            onDelete={handleDelete}
          />
        ) : modalType === 'Image' ? (
          <MediaModal
            onHide={() => setShowCreate(undefined)}
            onSave={(data) => {
              if (typeof showCreate === 'object') {
                update(showCreate.index, data);
              } else {
                append(data);
              }
            }}
            data={typeof showCreate === 'object' ? showCreate.data : undefined}
            onDelete={handleDelete}
          />
        ) : null)}
      <BBBCard title="Form name" className="mb-4">
        <BBBTextInput
          placeholder="Enter form name"
          isHookForm
          control={control}
          controlName="name"
        />
      </BBBCard>
      <style>
        {`
      .height-preserving-container:empty {
        min-height: calc(var(--child-height));
        box-sizing: border-box;
        margin-bottom: 1rem;
        }
    `}
      </style>
      {!fields.length ? (
        <div className="flex flex-col justify-center items-center h-[12.5rem]">
          <div className="w-[3.125rem] h-[3.125rem] rounded-full flex items-center justify-center bg-neutral-30 mb-2">
            <RectangleIconIcon />
          </div>
          <div className="text-neutral-50">Add section to your form</div>
        </div>
      ) : (
        <DragDropContext
          onDragEnd={(result) => {
            if (result.destination) {
              move(result.source.index, result.destination.index);
            }
          }}
        >
          <Droppable
            droppableId={`form-detail-droppable`}
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => {
              const index = rubric.source.index;
              const element = fields[index];

              return (
                <ElementCard
                  onClick={() => {
                    setShowCreate({
                      data: omit(element, 'id'),
                      index,
                    });
                  }}
                  provided={provided}
                  element={element}
                  isDragging={snapshot.isDragging}
                />
              );
            }}
          >
            {(providedDroppable) => (
              <div
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
              >
                {containerRef && (
                  <Virtuoso
                    data={fields}
                    customScrollParent={containerRef}
                    components={{
                      //@ts-ignore
                      Item: HeightPreservingItem,
                    }}
                    itemContent={(index, form) => {
                      return (
                        <Draggable
                          index={index}
                          draggableId={form.elementId.toString()}
                          key={form.elementId.toString()}
                        >
                          {(provided) => (
                            <ElementCard
                              onClick={() => {
                                setShowCreate({
                                  data: omit(form, 'id'),
                                  index,
                                });
                              }}
                              provided={provided}
                              element={form}
                              isDragging={false}
                            />
                          )}
                        </Draggable>
                      );
                    }}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </BBBContainer>
  );
}

function ElementCard({
  provided,
  element,
  onClick,
  isDragging,
}: {
  provided: DraggableProvided;
  element: FormDetailElement;
  onClick: () => void;
  isDragging: boolean;
}) {
  const sectionData = sectionOptions.find((opt) => opt.name === element.type)!;

  const Icon = sectionData.icon;

  return (
    <BBBCard
      className={cx(
        'mb-4 flex items-center gap-5 cursor-pointer',
        isDragging && 'is-dragging'
      )}
      ref={provided.innerRef}
      {...provided.draggableProps}
      onClick={onClick}
    >
      {element.type === 'Image' ? (
        <img src={element.media!} className="w-10 h-10" />
      ) : typeof Icon === 'function' ? (
        <Icon size={40} />
      ) : (
        Icon
      )}
      <div className="grow">
        <div className="text-neutral-60 text-sm font-medium mb-1.5">
          {element.type === 'Image' ? 'Image' : element.title}
        </div>
        <div className="text-neutral-50 text-sm">{sectionData!.label}</div>
      </div>
      <div {...provided.dragHandleProps}>
        <BBBDraggableIcon />
      </div>
    </BBBCard>
  );
}

function TestFormModal({
  onHide,
  formId,
}: {
  onHide: () => void;
  formId: string;
}) {
  const { mutate: testForm, isLoading: loadingTest } = useTestForm();

  const phoneDefaultValue = useDefaultCountryCode();

  const [phone, setPhone] = useState<BBBTelInputValue>();

  useEffect(() => {
    setPhone(phoneDefaultValue);
  }, [phoneDefaultValue]);

  const isValidPhone = phone
    ? isPossiblePhoneNumber(`+${phone.countryCode}${phone.phoneNumber}`)
    : false;

  return (
    <BBBModal
      title="Test form"
      show
      onHide={onHide}
      footer
      submitText="Send testing form"
      disableSave={!isValidPhone}
      handleSave={() =>
        testForm(
          { id: formId, number: formatPhonePayload(phone)! },
          {
            onSuccess: () => {
              onHide();
            },
          }
        )
      }
      loadingSave={loadingTest}
    >
      <BBBAlert
        type="danger"
        message={
          <>
            Make sure that you are within the 24-hour customer service window to
            receive the message.{' '}
            <a
              className="underline text-info-main"
              href="https://developers.facebook.com/docs/whatsapp/pricing/#customer-service-windows"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </>
        }
        className="mb-5"
      />
      <BBBTelInput
        value={phone}
        onChange={setPhone}
        label="Send testing form to this number"
      />
    </BBBModal>
  );
}

function MediaModal({
  onHide,
  data,
  onSave,
  onDelete,
}: {
  onHide: () => void;
  onSave: (data: FormDetailElement) => void;
  data?: FormDetailElement;
  onDelete?: () => void;
}) {
  const { control, handleSubmit, reset } = useForm<FormDetailElement>({
    defaultValues: {
      title: '',
      required: false,
      type: 'Image',
      elementId: createId(),
      media: null,
    },
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  return (
    <BBBModal
      title="Image"
      show
      footer
      submitText="Save"
      handleDelete={onDelete}
      handleSave={() => {
        handleSubmit((data) => {
          onSave(data);
          onHide();
        })();
      }}
      onHide={onHide}
    >
      <Controller
        control={control}
        name="media"
        render={({ field }) => (
          <BBBImageUpload
            imageClassName="object-cover"
            shape="block"
            imageUrl={field.value}
            onChangeImage={field.onChange}
          />
        )}
      />
    </BBBModal>
  );
}

const mapTextInputModalAttrs: Record<
  | 'TextHeading'
  | 'TextSubheading'
  | 'TextInput'
  | 'TextCaption'
  | 'TextBody'
  | 'DatePicker'
  | 'TextArea'
  | 'OptIn',
  { title: string; label: string; placeholder: string; maxChar: number }
> = {
  TextHeading: {
    title: 'Large heading',
    label: 'Heading text',
    placeholder: 'Enter heading text',
    maxChar: 80,
  },
  TextSubheading: {
    title: 'Small heading',
    label: 'Heading text',
    placeholder: 'Enter heading text',
    maxChar: 80,
  },
  TextInput: {
    title: 'Short answer',
    label: 'Short answer title',
    placeholder: 'Enter short answer title',
    maxChar: 20,
  },
  TextCaption: {
    title: 'Caption',
    label: 'Caption text',
    placeholder: 'Enter caption text',
    maxChar: 4000,
  },
  TextBody: {
    title: 'Body',
    label: 'Body text',
    placeholder: 'Enter body text',
    maxChar: 4000,
  },
  DatePicker: {
    title: 'Date picker',
    label: 'Date picker title',
    placeholder: 'Enter date picker title',
    maxChar: 20,
  },
  TextArea: {
    title: 'Paragraph answer',
    label: 'Paragraph answer title',
    placeholder: 'Enter paragraph answer title',
    maxChar: 30,
  },
  OptIn: {
    title: 'Opt-in',
    label: 'Label',
    placeholder: 'Enter label text',
    maxChar: 300,
  },
};

function TextInputModal({
  type,
  onHide,
  onSave,
  data,
  onDelete,
}: {
  type:
    | 'TextHeading'
    | 'TextSubheading'
    | 'TextInput'
    | 'TextCaption'
    | 'TextBody'
    | 'DatePicker'
    | 'TextArea'
    | 'OptIn';
  onHide: () => void;
  onSave: (data: FormDetailElement) => void;
  data?: FormDetailElement;
  onDelete?: () => void;
}) {
  const { control, handleSubmit, reset } = useForm<FormDetailElement>({
    defaultValues: {
      title: '',
      required: false,
      type,
      elementId: createId(),
    },
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const attrs = mapTextInputModalAttrs[type];

  return (
    <BBBModal
      title={attrs.title}
      show
      onHide={onHide}
      footer
      submitText="Save"
      handleDelete={onDelete}
      handleSave={() => {
        handleSubmit((data) => {
          onSave(data);
          onHide();
        })();
      }}
    >
      {type === 'TextBody' || type === 'TextCaption' || type === 'OptIn' ? (
        <BBBTextAreaInput
          isHookForm
          control={control}
          controlName="title"
          label={attrs.label}
          placeholder={attrs.placeholder}
          rows={5}
          maxChar={attrs.maxChar}
          hasMaxCharLabel
        />
      ) : (
        <BBBTextInput
          label={attrs.label}
          placeholder={attrs.placeholder}
          isHookForm
          control={control}
          controlName="title"
          maxChar={attrs.maxChar}
          hasMaxCharLabel
        />
      )}

      {(type === 'DatePicker' ||
        type === 'TextInput' ||
        type === 'TextArea' ||
        type === 'OptIn') && (
        <Controller
          control={control}
          name="required"
          render={({ field }) => (
            <BBBCheckbox
              label="Required"
              checked={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
      )}
    </BBBModal>
  );
}

const portal = document.createElement('div');
document.body.appendChild(portal);

const mapOptionModalAttrs: Record<
  'Dropdown' | 'CheckboxGroup' | 'RadioButtonsGroup',
  {
    title: string;
    label: string;
    placeholder: string;
    maxChar: {
      title: number;
      options: number;
    };
  }
> = {
  Dropdown: {
    title: 'Dropdown options',
    label: 'Dropdown title',
    placeholder: 'Enter dropdown title',
    maxChar: {
      title: 20,
      options: 80,
    },
  },
  CheckboxGroup: {
    title: 'Multiple choice',
    label: 'Multiple choice title',
    placeholder: 'Enter multiple choice title',
    maxChar: {
      title: 30,
      options: 30,
    },
  },
  RadioButtonsGroup: {
    title: 'Single choice',
    label: 'Single choice title',
    placeholder: 'Enter single choice title',
    maxChar: {
      title: 30,
      options: 30,
    },
  },
};

function OptionModal({
  type,
  onHide,
  onSave,
  data,
  onDelete,
}: {
  type: 'Dropdown' | 'CheckboxGroup' | 'RadioButtonsGroup';
  onHide: () => void;
  onSave: (data: FormDetailElement) => void;
  data?: FormDetailElement;
  onDelete?: () => void;
}) {
  const { control, handleSubmit, reset } = useForm<FormDetailElement>({
    defaultValues: {
      title: '',
      type,
      options: [],
      required: true,
      elementId: createId(),
    },
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'options',
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const attrs = mapOptionModalAttrs[type];

  return (
    <BBBModal
      show
      title={attrs.title}
      footer
      onHide={onHide}
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            ...data,
            options: data.options.map((opt, index) => ({
              ...opt,
              index,
            })),
          });
          onHide();
        })();
      }}
      handleDelete={onDelete}
    >
      <BBBTextInput
        label={attrs.title}
        placeholder={attrs.placeholder}
        containerClassname="mb-5"
        isHookForm
        control={control}
        controlName="title"
        maxChar={attrs.maxChar.title}
        hasMaxCharLabel
      />
      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination) {
            move(result.source.index, result.destination?.index);
          }
        }}
      >
        <Droppable droppableId={`${type}-droppable`}>
          {(providedDroppable) => (
            <div
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {fields.map((field, index) => (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided, snapshot) => (
                    <PortalAwareItem
                      provided={provided}
                      onRemove={() => remove(index)}
                      control={control}
                      index={index}
                      snapshot={snapshot}
                      maxChar={attrs.maxChar.options}
                    />
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <BBBButton
        variant="secondary"
        text="Add option"
        icon={<PlusIcon />}
        iconPosition="right"
        className="mb-5"
        onClick={() => {
          append({ text: '', index: fields.length });
        }}
      />

      <Controller
        control={control}
        name="required"
        render={({ field }) => (
          <BBBCheckbox
            label="Required"
            checked={field.value}
            onValueChange={field.onChange}
          />
        )}
      />
    </BBBModal>
  );
}

function PortalAwareItem({
  provided,
  index,
  control,
  onRemove,
  snapshot,
  maxChar,
}: {
  provided: DraggableProvided;
  index: number;
  control: Control<FormDetailElement>;
  onRemove: () => void;
  snapshot: DraggableStateSnapshot;
  maxChar: number;
}) {
  const child = (
    <div
      className="flex items-center gap-2 mb-5"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div {...provided.dragHandleProps}>
        <BBBDraggableIcon />
      </div>
      <BBBTextInput
        isHookForm
        control={control}
        controlName={`options.${index}.text`}
        containerClassname="grow mb-0"
        placeholder={`Option ${index + 1}`}
        maxChar={maxChar}
        hasMaxCharLabel
      />
      <TrashWithTransition onClick={onRemove} />
    </div>
  );

  if (!snapshot.isDragging) return child;

  return createPortal(child, portal);
}

function HeadingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        fill="#000"
        d="M4.77.886L1.384 10H0L3.9 0h.892L4.77.886zM7.61 10L4.215.886 4.195 0h.892L9 10H7.609zm-.176-3.702v1.085H1.686V6.298h5.747zM14.252 8.538V4.567c0-.304-.068-.568-.204-.792a1.285 1.285 0 00-.596-.527c-.266-.124-.595-.186-.987-.186-.366 0-.688.057-.964.172a1.668 1.668 0 00-.643.449.928.928 0 00-.228.599H9.18c0-.276.079-.55.235-.82.157-.271.382-.516.675-.735.297-.223.653-.399 1.066-.527.418-.133.883-.2 1.395-.2.617 0 1.16.095 1.63.285.476.19.847.478 1.114.863.271.38.407.858.407 1.433v3.594c0 .256.024.53.07.82.053.29.129.54.228.748v.114h-1.513a2.291 2.291 0 01-.172-.606 4.457 4.457 0 01-.063-.713zm.25-3.358l.016.927h-1.465c-.413 0-.782.03-1.106.093a2.695 2.695 0 00-.815.263c-.22.12-.387.269-.502.45a1.108 1.108 0 00-.172.62c0 .242.06.463.18.663s.3.359.541.478c.246.114.546.17.902.17a2.58 2.58 0 001.175-.256 2.4 2.4 0 00.808-.627c.204-.247.313-.487.33-.72l.618.634c-.036.2-.136.42-.298.663a3.266 3.266 0 01-.65.7 3.576 3.576 0 01-.957.548A3.388 3.388 0 0111.87 10c-.575 0-1.08-.102-1.513-.307a2.518 2.518 0 01-1.003-.82A2.025 2.025 0 019 7.711c0-.413.089-.777.267-1.09.177-.32.433-.583.768-.792a3.839 3.839 0 011.207-.485c.47-.11.995-.164 1.575-.164h1.686z"
      ></path>
    </svg>
  );
}

function ShortAnswerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M.6 14.534c0-.302.244-.546.546-.546h15.708a.546.546 0 110 1.092H1.146a.546.546 0 01-.546-.546zM1.146 2.228a.546.546 0 100 1.092H3.96v7.014a.546.546 0 001.092 0V3.32h2.562a.546.546 0 000-1.092H1.146zM11.226.8c.301 0 .546.244.546.546v10.668a.546.546 0 11-1.092 0V1.346c0-.302.244-.546.546-.546z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function DropdownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#262627"
        d="M8.735 11.158a.867.867 0 01-1.47 0l-2.77-4.432A.867.867 0 015.23 5.4h5.54c.68 0 1.095.749.735 1.326l-2.77 4.432z"
      ></path>
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M8 14.674A6.673 6.673 0 108 1.327a6.673 6.673 0 000 13.347zM8 15.8A7.8 7.8 0 108 .2a7.8 7.8 0 000 15.6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function MultipleChoiceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M12.733 2H5.267A3.267 3.267 0 002 5.267v7.466A3.267 3.267 0 005.267 16h7.466A3.267 3.267 0 0016 12.733V5.267A3.267 3.267 0 0012.733 2zM5.267.6A4.667 4.667 0 00.6 5.267v7.466A4.667 4.667 0 005.267 17.4h7.466a4.667 4.667 0 004.667-4.667V5.267A4.667 4.667 0 0012.733.6H5.267z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M13.514 4.904c.29.195.373.597.185.898l-4.31 6.904a1.022 1.022 0 01-1.614.178L4.383 9.368a.666.666 0 010-.918.61.61 0 01.886 0l3.209 3.326 4.17-6.68a.613.613 0 01.866-.193z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function SingleChoiceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M8 14.674A6.673 6.673 0 108 1.327a6.673 6.673 0 000 13.347zM8 15.8A7.8 7.8 0 108 .2a7.8 7.8 0 000 15.6z"
        clipRule="evenodd"
      ></path>
      <circle cx="8" cy="8" r="4.8" fill="#000"></circle>
    </svg>
  );
}

function SwitchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      fill="none"
      viewBox="0 0 18 12"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M.35 6A5.65 5.65 0 016 .35h6a5.65 5.65 0 010 11.3H6A5.65 5.65 0 01.35 6zM6 1.65a4.35 4.35 0 100 8.7h6a4.35 4.35 0 000-8.7H6z"
        clipRule="evenodd"
      ></path>
      <path fill="#000" d="M15 6a3 3 0 11-6 0 3 3 0 016 0z"></path>
    </svg>
  );
}

function DatetimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M12.733 2H5.267A3.267 3.267 0 002 5.267v7.466A3.267 3.267 0 005.267 16h7.466A3.267 3.267 0 0016 12.733V5.267A3.267 3.267 0 0012.733 2zM5.267.6A4.667 4.667 0 00.6 5.267v7.466A4.667 4.667 0 005.267 17.4h7.466a4.667 4.667 0 004.667-4.667V5.267A4.667 4.667 0 0012.733.6H5.267z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#262627"
        d="M12.743 5.467a.28.28 0 01.372.265v5.997a.575.575 0 01-1.149 0V6.702l-1.107.375a.45.45 0 01-.293-.852l2.177-.758zM5.777 8.816c0-.278.226-.503.504-.503h.183c.267 0 .488-.046.663-.138a.902.902 0 00.395-.382c.086-.162.13-.348.13-.557 0-.22-.04-.407-.12-.563a.804.804 0 00-.353-.367 1.226 1.226 0 00-.596-.129 1.22 1.22 0 00-.553.124.94.94 0 00-.386.343c-.166.256-.39.525-.694.525h-.026c-.319 0-.589-.265-.49-.568.048-.147.116-.285.204-.414.194-.286.458-.51.791-.672a2.542 2.542 0 011.135-.248c.448 0 .84.074 1.173.224.337.146.6.365.787.658.187.292.281.654.281 1.087 0 .197-.046.397-.138.6-.092.204-.23.39-.41.558-.181.165-.407.3-.677.405-.27.102-.584.153-.94.153h-.727a.136.136 0 01-.136-.136zm.41.393a.409.409 0 11.192-.769l.149.08c.074.04.156.06.24.062.352.009.656.056.912.141.289.095.524.227.705.396.182.165.313.354.396.567.086.213.129.439.129.677 0 .324-.059.614-.177.868a1.76 1.76 0 01-.49.639 2.192 2.192 0 01-.75.396 3.068 3.068 0 01-.924.133c-.299 0-.585-.041-.858-.124a2.34 2.34 0 01-.734-.367 1.821 1.821 0 01-.515-.615 1.658 1.658 0 01-.112-.29c-.087-.306.183-.568.5-.568s.555.276.718.548c.098.156.235.278.41.367.178.09.381.134.61.134.242 0 .45-.043.625-.13a.895.895 0 00.4-.38 1.22 1.22 0 00.143-.61c0-.271-.052-.49-.157-.659a.925.925 0 00-.448-.372 1.742 1.742 0 00-.687-.124h-.278z"
      ></path>
    </svg>
  );
}

function RectangleIconIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="1.5625rem"
      fill="none"
      viewBox="0 0 32 25"
    >
      <rect
        width="30"
        height="8"
        x="1"
        y="1.5"
        stroke="#757575"
        strokeWidth="2"
        rx="2"
      ></rect>
      <rect
        width="30"
        height="8"
        x="1"
        y="15.5"
        stroke="#757575"
        strokeWidth="2"
        rx="2"
      ></rect>
    </svg>
  );
}

function MediaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#fff"
        d="M0 4a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14 1.3H4A2.7 2.7 0 001.3 4v10A2.7 2.7 0 004 16.7h10a2.7 2.7 0 002.7-2.7V4A2.7 2.7 0 0014 1.3zM4 0a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V4a4 4 0 00-4-4H4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M5.831 9.454a.35.35 0 00-.56.02L1.533 14.87l-1.068-.74L4.2 8.734a1.65 1.65 0 012.645-.092l2.524 3.155a.35.35 0 00.498.05l1.514-1.262a1.65 1.65 0 012.223.1l3.855 3.855-.92.92-3.854-3.855a.35.35 0 00-.471-.021L10.7 12.846a1.65 1.65 0 01-2.345-.237L5.831 9.454z"
        clipRule="evenodd"
      ></path>
      <path fill="#000" d="M15 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"></path>
    </svg>
  );
}
