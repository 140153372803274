import { useRef } from 'react';
import { PlusCircle } from 'react-feather';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { FormSchema } from '../..';

import { BBBCard, BBBTelInput, BBBTextInput } from '@/components';

type Props = {
  control: Control<FormSchema>;
  loading: boolean;
  error?: FieldErrors<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  disableTelInput?: boolean;
};

function WhatsAppNumber({ control, loading, error, disableTelInput }: Props) {
  const whatsAppContactPrefilledMessageRef = useRef<HTMLInputElement | null>(
    null
  );

  return (
    <BBBCard
      title="Contact us"
      className="mb-cardBottom"
      id="widget-contact-us"
    >
      <div className="flex flex-col gap-3">
        <BBBTelInput
          label="WhatsApp phone number"
          placeholder="123456789"
          disabled={loading || disableTelInput}
          isHookForm
          control={control}
          controlName="whatsappPhone"
          error={error?.whatsappPhone?.message}
          id="test-phone"
        />
        <BBBTextInput
          label="WhatsApp button text"
          disabled={loading}
          isHookForm
          control={control}
          placeholder="Enter WhatsApp button text"
          controlName="whatsappButtonText"
          error={error?.whatsappButtonText?.message}
        />
        <Controller
          control={control}
          name="whatsappPrefilledText"
          render={({ field }) => (
            <div className="flex flex-col">
              <BBBTextInput
                ref={whatsAppContactPrefilledMessageRef}
                label="Pre-filled message body (optional)"
                disabled={loading}
                placeholder="Enter pre-filled message"
                error={error?.whatsappPrefilledText?.message}
                value={field.value}
                onChange={({ target: { value } }) => field.onChange(value)}
              />
              <div
                className="flex items-center gap-1 cursor-pointer"
                onClick={() => {
                  const input = whatsAppContactPrefilledMessageRef.current;

                  if (input) {
                    const newMessage = field.value || '';
                    const cursorPos = input.selectionStart || 0;
                    const beforeCursor = newMessage.slice(0, cursorPos);
                    const afterCursor = newMessage.slice(cursorPos);
                    const updatedMessage = `${beforeCursor}{{url}}${afterCursor}`;

                    field.onChange(updatedMessage);

                    input.value = updatedMessage;
                    const newCursorPos = cursorPos + '{{url}}'.length;
                    input.setSelectionRange(newCursorPos, newCursorPos);
                    input.focus();
                  }
                }}
              >
                <PlusCircle size={12} />
                <p className="text-neutral-900 text-sm underline">
                  Add page URL variable
                </p>
              </div>
            </div>
          )}
        />
      </div>
    </BBBCard>
  );
}

export default WhatsAppNumber;
