import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function BitChatIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' ? (
        <BitChatIconDefault />
      ) : mode === 'hover' ? (
        <BitChatIconHover />
      ) : (
        <BitChatIconActive />
      )}
    </>
  );
}

function BitChatIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      fill="none"
      viewBox="0 0 23 24"
    >
      <path
        fill="#404040"
        d="M20.125 1.5c.381 0 .747.158 1.017.44.269.28.42.662.42 1.06v12c0 .398-.151.78-.42 1.06-.27.282-.636.44-1.017.44h-3.594c-.446 0-.886.108-1.285.317-.4.208-.747.51-1.015.883L11.5 21.5l-2.731-3.8a2.908 2.908 0 00-1.015-.883c-.399-.209-.839-.317-1.285-.317H2.875c-.381 0-.747-.158-1.016-.44A1.534 1.534 0 011.438 15V3c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44h17.25zM2.875 0C2.112 0 1.381.316.842.879A3.067 3.067 0 000 3v12c0 .796.303 1.559.842 2.121.54.563 1.27.879 2.033.879h3.594c.223 0 .443.054.643.158.2.104.373.256.507.442l2.731 3.8c.134.186.308.337.507.441a1.388 1.388 0 001.286 0c.2-.104.373-.255.507-.442l2.731-3.799c.134-.186.308-.338.507-.442.2-.104.42-.158.643-.158h3.594c.762 0 1.494-.316 2.033-.879.54-.562.842-1.325.842-2.121V3c0-.796-.303-1.559-.842-2.121A2.815 2.815 0 0020.125 0H2.875z"
      ></path>
      <path
        fill="#404040"
        d="M7.188 9c0 .398-.152.78-.422 1.06-.27.282-.635.44-1.016.44s-.747-.158-1.016-.44A1.534 1.534 0 014.313 9c0-.398.15-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.016.44.27.28.421.662.421 1.06zm5.75 0c0 .398-.152.78-.421 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.017-.44a1.534 1.534 0 01-.42-1.06c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.017.44.269.28.42.662.42 1.06zm5.75 0c0 .398-.152.78-.421 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.017-.44a1.534 1.534 0 01-.42-1.06c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.017.44.269.28.42.662.42 1.06z"
      ></path>
    </svg>
  );
}

function BitChatIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      fill="none"
      viewBox="0 0 23 24"
    >
      <path
        fill="#404040"
        fillRule="evenodd"
        d="M.842.879A2.816 2.816 0 012.875 0h17.25c.762 0 1.494.316 2.033.879C22.698 1.44 23 2.204 23 3v12c0 .796-.303 1.559-.842 2.121-.54.563-1.27.879-2.033.879h-3.594c-.223 0-.443.054-.643.158-.2.104-.373.256-.507.442l-2.731 3.8a1.453 1.453 0 01-.507.441 1.388 1.388 0 01-1.286 0 1.453 1.453 0 01-.507-.442L7.619 18.6a1.453 1.453 0 00-.507-.442c-.2-.104-.42-.158-.643-.158H2.875a2.816 2.816 0 01-2.033-.879A3.067 3.067 0 010 15V3C0 2.204.303 1.441.842.879zM7.187 9c0 .398-.15.78-.42 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.016-.44A1.534 1.534 0 014.313 9c0-.398.15-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.016.44.27.28.421.662.421 1.06zm5.75 0c0 .398-.151.78-.42 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.017-.44a1.534 1.534 0 01-.42-1.06c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.017.44.269.28.42.662.42 1.06zm5.75 0c0 .398-.151.78-.42 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.017-.44a1.534 1.534 0 01-.42-1.06c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.017.44.269.28.42.662.42 1.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function BitChatIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      fill="none"
      viewBox="0 0 23 24"
    >
      <path
        fill="#FD823E"
        d="M20.125 1.5c.381 0 .747.158 1.017.44.269.28.42.662.42 1.06v12c0 .398-.151.78-.42 1.06-.27.282-.636.44-1.017.44h-3.594c-.446 0-.886.108-1.285.317-.4.208-.747.51-1.015.883L11.5 21.5l-2.731-3.8a2.908 2.908 0 00-1.015-.883c-.399-.209-.839-.317-1.285-.317H2.875c-.381 0-.747-.158-1.016-.44A1.534 1.534 0 011.438 15V3c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44h17.25zM2.875 0C2.112 0 1.381.316.842.879A3.067 3.067 0 000 3v12c0 .796.303 1.559.842 2.121.54.563 1.27.879 2.033.879h3.594c.223 0 .443.054.643.158.2.104.373.256.507.442l2.731 3.8c.134.186.308.337.507.441a1.388 1.388 0 001.286 0c.2-.104.373-.255.507-.442l2.731-3.799c.134-.186.308-.338.507-.442.2-.104.42-.158.643-.158h3.594c.762 0 1.494-.316 2.033-.879.54-.562.842-1.325.842-2.121V3c0-.796-.303-1.559-.842-2.121A2.815 2.815 0 0020.125 0H2.875z"
      ></path>
      <path
        fill="#FD823E"
        d="M7.188 9c0 .398-.152.78-.422 1.06-.27.282-.635.44-1.016.44s-.747-.158-1.016-.44A1.534 1.534 0 014.313 9c0-.398.15-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.016.44.27.28.421.662.421 1.06zm5.75 0c0 .398-.152.78-.421 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.017-.44a1.534 1.534 0 01-.42-1.06c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.017.44.269.28.42.662.42 1.06zm5.75 0c0 .398-.152.78-.421 1.06-.27.282-.636.44-1.017.44-.381 0-.747-.158-1.017-.44a1.534 1.534 0 01-.42-1.06c0-.398.151-.78.42-1.06.27-.282.636-.44 1.017-.44.381 0 .747.158 1.017.44.269.28.42.662.42 1.06z"
      ></path>
    </svg>
  );
}

export default BitChatIcon;
