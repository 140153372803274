import { useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBButton } from '@/components/BBBButton';
import { Link } from '@/components/Link';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import usePricingTreshold from '@/hooks/pricing/usePricingTreshold';
import { AppType } from '@/types/systemLogin';
import { formatDate2 } from '@/utils/common/date';

function TresholdAlert({ appType }: { appType: AppType }) {
  const [show, setShow] = useState(true);

  const {
    halfLimitTreshold,
    lowerLimitTreshold,
    limitTreshold,
    pricingData,
    next,
  } = usePricingTreshold(appType);

  const { isMaximumLevel } = useIsMaximumLevel(appType);

  const exceedMinimumTreshold = !!(
    halfLimitTreshold ||
    lowerLimitTreshold?.count ||
    limitTreshold?.count
  );

  if (
    isMaximumLevel &&
    (appType === 'BITLINK' || appType === 'BITLOGIN' || appType === 'BITAI'
      ? true
      : halfLimitTreshold)
  )
    return null;

  return (
    <>
      {show && exceedMinimumTreshold ? (
        <div
          className={cx(
            'border border-primary-main py-4 px-3.5 rounded-2xl',
            (limitTreshold?.count || lowerLimitTreshold?.count) &&
              'border-2 border-secondary-main'
          )}
        >
          <div className="text-xs text-center mb-1.5">
            {limitTreshold?.count ? (
              limitTreshold.count > 1 ? (
                <>
                  <span className="font-bold">
                    You’ve reached {limitTreshold.count} of your limits.
                  </span>{' '}
                  {limitTreshold.feature?.filter((feat) => feat.isReset).length
                    ? `${limitTreshold.feature
                        .filter((feat) => feat.isReset)!
                        .map((feature) => feature.featureName)
                        .join(' & ')} limit will refresh on ${
                        pricingData?.lastBillingCycle &&
                        formatDate2(pricingData.lastBillingCycle)
                      }`
                    : !isMaximumLevel
                    ? next.name === 'Enterprise'
                      ? 'Upgrade to Enterprise'
                      : `Upgrade to ${next.name} plan, from $${next.price}/month.`
                    : ''}
                </>
              ) : (
                <>
                  You’ve reached your {limitTreshold.feature![0].limit}{' '}
                  {limitTreshold.feature![0].featureName} limit this month!{' '}
                  <span className="font-bold">
                    {limitTreshold.feature![0].isReset
                      ? `Refresh on ${
                          pricingData?.lastBillingCycle &&
                          formatDate2(pricingData.lastBillingCycle)
                        }`
                      : !isMaximumLevel
                      ? next.name === 'Enterprise'
                        ? 'Upgrade to Enterprise'
                        : `Upgrade to ${next.name} plan, from $${next.price}/month`
                      : ''}{' '}
                  </span>
                </>
              )
            ) : lowerLimitTreshold?.count ? (
              lowerLimitTreshold.count > 1 ? (
                !isMaximumLevel ? (
                  <>
                    <span className="font-bold">
                      You’ve almost reached {lowerLimitTreshold.count} of your
                      limits.
                    </span>{' '}
                    Upgrade for more benefits
                  </>
                ) : (
                  <>
                    {lowerLimitTreshold.feature
                      ?.map(
                        (feature) =>
                          `${feature.remaining} ${feature.featureName} left for ${pricingData?.pricingModule.label} plan.`
                      )
                      .join('. ')}
                  </>
                )
              ) : (
                <>
                  {lowerLimitTreshold.feature![0].remaining}{' '}
                  {lowerLimitTreshold.feature![0].featureName} left for{' '}
                  {pricingData?.pricingModule.label} plan.{' '}
                  {!isMaximumLevel
                    ? next.name === 'Enterprise'
                      ? 'Upgrade to Enterprise'
                      : `Upgrade to ${next.name} features, from $${next.price}/month.`
                    : ''}
                </>
              )
            ) : (
              <>
                Limits are approaching?{' '}
                <span className="font-bold">Upgrade now</span> to get unlimited
                features!
              </>
            )}
          </div>
          {!isMaximumLevel &&
            (limitTreshold?.count ? (
              <Link to={`/pricing?tab=${appType.toLowerCase()}`}>
                <BBBButton text="See plans" width="full" size="sm" />
              </Link>
            ) : (
              <div className="flex gap-3 justify-center items-center">
                <div
                  className="text-neutral-50 text-xs underline cursor-pointer"
                  onClick={() => setShow(false)}
                >
                  Not now
                </div>
                <Link to={`/pricing?tab=${appType.toLowerCase()}`}>
                  <BBBButton text="See plans" size="sm" />
                </Link>
              </div>
            ))}
        </div>
      ) : (
        <Link to={`/pricing?tab=${appType.toLowerCase()}`}>
          <BBBButton className="w-full" text="Upgrade" />
        </Link>
      )}
    </>
  );
}

export default TresholdAlert;
