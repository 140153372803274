import { useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Check, PlusCircle } from 'react-feather';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import useColumn from './hooks/useColumnManage';

import {
  ModelCondition,
  UpsertModelPayload,
} from '@/api/services/bitAi/v2-new/model';
import { BBBButton, BBBContainer, BBBTextInput } from '@/components';
import BBBTableV2 from '@/components/BBBTableV2/BBBTableV2';
import useSortingModel from '@/hooks/bitAi/models/useSortingModel';
import useUpsertModel from '@/hooks/bitAi/models/useUpsertModel';
import useCustomForm from '@/hooks/common/useCustomForm';
import { arrayMove } from '@/utils/common/array';

const schema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required('Name is required'),
  rules: yup.array().of(
    yup.object().shape({
      index: yup.number().required('Priority Order is required'),
      qty: yup.number().required('Number Used is required'),
      condition: yup.mixed<ModelCondition>().required('Sort Order is required'),
    })
  ),
});

export default function ManageModel() {
  const { id } = useParams<{
    id: string;
  }>();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useCustomForm<UpsertModelPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      rules: [],
      id,
    },
  });

  const {
    data: dataSortingModel,
    isInitialLoading: loadingSortingModel,
    status: statusSortingModel,
  } = useSortingModel(id);

  const { mutate: upsertModel, isLoading: isSaving } = useUpsertModel();

  const rules = watch('rules');

  const reorderData = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const startIndex = source.index;
    const endIndex = destination.index;

    const newData = [...rules];

    arrayMove(newData, startIndex, endIndex);

    setValue('rules', newData);
  };

  const addSection = () => {
    setValue('rules', [
      ...rules,
      {
        id: createId(),
        qty: 0,
        condition: 'HIGHEST_DISCOUNT',
        index: rules.length,
      },
    ]);
  };

  const onSubmit = (data: UpsertModelPayload) => {
    upsertModel(data);
  };

  const columns = useColumn({
    onUpdate: (rowId, columnId, value) => {
      setValue(
        'rules',
        rules.map((rule) => {
          if (rule.id === rowId) {
            return {
              ...rule,
              [columnId]: value,
            };
          }

          return rule;
        })
      );
    },
    onRemove: (rowId) => {
      setValue(
        'rules',
        rules.filter((rule) => rule.id !== rowId)
      );
    },
  });

  useEffect(() => {
    reset({
      name: dataSortingModel?.name || '',
      rules: dataSortingModel?.rules || [],
      id: dataSortingModel?.id,
    });
  }, [
    dataSortingModel?.id,
    dataSortingModel?.name,
    dataSortingModel?.rules,
    id,
    reset,
  ]);

  return (
    <BBBContainer
      hasBack
      backUrl={'/bitai/model'}
      hasHeading
      pageTitle={
        statusSortingModel === 'loading' || loadingSortingModel
          ? undefined
          : `${id === 'new' ? 'New Model' : dataSortingModel?.name}`
      }
    >
      <BBBTableV2
        data={rules}
        dataId="id"
        loadingBody={loadingSortingModel}
        headers={columns}
        renderEmptyMessage={() =>
          'Add new section by pressing the button below'
        }
        isDraggable
        footer={
          <div className="d-flex py-3 px-4 justify-content-end">
            <BBBButton
              variant="secondary"
              width="full"
              text="Add new section"
              icon={<PlusCircle className="mr-3" />}
              onClick={() => {
                addSection();
              }}
            />
          </div>
        }
        onDragEnd={reorderData}
        renderCustomHeader={() => (
          <div className="pl-2 pt-2 w-1/4 flex items-center gap-4 justify-between">
            <BBBTextInput
              placeholder="Copy of Sorting Model 001"
              inputClassName="text-2xl"
              isHookForm
              control={control}
              controlName="name"
              error={errors.name?.message}
              autoFocus
            />
            <Check
              cursor="pointer"
              className={cx(
                !rules.length
                  ? 'opacity-50 pointer-events-none'
                  : 'opacity-100 cursor-pointer'
              )}
              onClick={() => handleSubmit(onSubmit)()}
            />
          </div>
        )}
      />
      <div className="flex justify-end gap-4 mt-4">
        <Link to={'/bitai/model'}>
          <BBBButton variant="secondary" text="Discard" />
        </Link>
        <BBBButton
          variant="primary"
          text="Save"
          onClick={() => handleSubmit(onSubmit)()}
          loadingState={isSaving || loadingSortingModel}
        />
      </div>
    </BBBContainer>
  );
}
