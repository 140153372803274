import {
  previewBitChatAnalytics,
  totalConversation,
  totalMessage,
} from 'constants/analytics/bitChat';
import { ChannelIntegrationValues } from 'constants/integrations';
import {
  useConversationPeakHours,
  useMessagePeakHours,
  useNewCustomers,
  useTotalConversations,
  useTotalMessages,
  useUniqueCustomers,
} from 'hooks/bitChat/analytics/analytics-v3';
import { BBBOverviewBox } from 'components';
import BBBBarChartMultiTab from 'components/Analytics/BBBBarChart/BBBBarChartMultiTab';

type Props = {
  isPreview?: boolean;
  startDate: string;
  endDate: string;
  channel?: ChannelIntegrationValues;
  loading?: boolean;
};

const multiTabOptions = [
  {
    label: 'Conversations',
    value: 'conversations',
  },
  {
    label: 'Message',
    value: 'message',
  },
];

export default function Conversations({
  isPreview,
  startDate,
  endDate,
  channel,
  loading,
}: Props) {
  const { data: totalMessages, isLoading: loadingTotalMessages } =
    useTotalMessages(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: uniqueCustomers, isLoading: loadingUniqueCustomers } =
    useUniqueCustomers(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: newCustomers, isLoading: loadingNewCustomers } =
    useNewCustomers(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: totalConversations, isLoading: loadingTotalConversations } =
    useTotalConversations(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: messagePeakHours } = useMessagePeakHours(
    {
      startDate,
      endDate,
      channel,
    },
    { enabled: !loading && !isPreview }
  );

  const { data: conversationPeakHours } = useConversationPeakHours(
    {
      startDate,
      endDate,
      channel,
    },
    { enabled: !loading && !isPreview }
  );

  return (
    <>
      <p className="text-primary-color text-xl">Conversations</p>
      <div className="w-full gap-4 grid grid-cols-4">
        <BBBOverviewBox
          title="Total conversations"
          value={
            isPreview
              ? previewBitChatAnalytics.totalConversation.count
              : totalConversations
          }
          loading={isPreview ? false : loading || loadingTotalConversations}
        />
        <BBBOverviewBox
          title="Total message"
          value={
            isPreview
              ? previewBitChatAnalytics.totalMessage.count
              : totalMessages
          }
          loading={isPreview ? false : loading || loadingTotalMessages}
        />
        <BBBOverviewBox
          title="Total customers"
          value={
            isPreview
              ? previewBitChatAnalytics.totalCustomer.count
              : uniqueCustomers
          }
          loading={isPreview ? false : loading || loadingUniqueCustomers}
        />
        <BBBOverviewBox
          title="New customers"
          value={
            isPreview ? previewBitChatAnalytics.newCustomer.count : newCustomers
          }
          loading={isPreview ? false : loading || loadingNewCustomers}
        />
      </div>
      <BBBBarChartMultiTab
        tooltip="Track peak hours of your customers activity"
        lineDataKey={(activeTab) =>
          activeTab.value === 'conversations' ? 'value' : 'value'
        }
        xAxisDataKey={(activeTab) =>
          activeTab.value === 'conversations' ? 'label' : 'label'
        }
        data={(activeTab) =>
          isPreview
            ? activeTab.value === 'conversations'
              ? totalConversation
              : totalMessage
            : activeTab.value === 'conversations'
            ? conversationPeakHours
            : messagePeakHours
        }
        options={multiTabOptions}
      />
    </>
  );
}
