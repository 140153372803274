import { useCallback, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import useColumn from '../hooks/useColumn';

import { BBBButton, BBBSelect, SingleOnValueChangeParam } from '@/components';
import BBBTableV2 from '@/components/BBBTableV2/BBBTableV2';
import useCampaignBulkAction from '@/hooks/bitCRM/campaign/useCampaignBulkAction';
import useCampaignLog from '@/hooks/bitCRM/campaign/useCampaignLog';
import useCampaignLogFilter from '@/hooks/bitCRM/campaign/useCampaignLogFilter';
import useResponsive from '@/hooks/common/useResponsive';
import { CampaignLog } from '@/types/bitCRM/campaign';

const MessageLog = ({ id }: { id: string }) => {
  const isTablet = useResponsive('lg');

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterStatus, setFilterStatus] = useState<
    SingleOnValueChangeParam<{
      value?: string | null;
      label?: string | null;
    }>
  >();
  const [selected, setSelected] = useState<CampaignLog[]>([]);
  const [sort, setSort] = useState<string | undefined>();

  const { data: logData, isInitialLoading: loadingLog } = useCampaignLog(id, {
    size: pageSize,
    page: pageIndex,
    status: filterStatus?.value,
    search,
    sort,
  });

  const { data: dataFilters, isInitialLoading: loadingFilters } =
    useCampaignLogFilter();

  const campaignBulkAction = useCampaignBulkAction();

  const handleBulkDeleteCampaignLog = useCallback(
    (selectedRows: string[]) => campaignBulkAction(id, 'delete', selectedRows),
    [campaignBulkAction, id]
  );

  const defaultColumns = useColumn();

  return (
    <>
      <div className={cx(`my-6 ${isTablet && 'px-4 hidden'}`)}>
        <p className="text-2xl text-primary-main">Message Log</p>
      </div>
      {!isTablet && (
        <BBBTableV2
          loadingBody={loadingLog}
          data={logData?.content ?? []}
          headers={defaultColumns}
          dataId="id"
          isSelectable
          withoutActionOption
          onChangeSelectable={setSelected}
          selected={selected}
          searchPlaceholder="Search on message log"
          isPaginate
          isSearchable
          searchValue={search}
          onChangeSearch={setSearch}
          pagination={{
            page: pageIndex + 1,
            limit: pageSize,
            onChange: (page) => setPageIndex(page - 1),
            total: logData?.totalElements || 0,
            maxShown: 3,
          }}
          isShowLimit
          limitValue={pageSize}
          onLimitChange={(page) => {
            setPageSize(page!);
            setPageIndex(0);
          }}
          isFilterable
          renderFilterSection={() =>
            selected.length ? (
              <BBBButton
                variant="danger-outline"
                text={`Delete (${selected.length})`}
                onClick={() =>
                  handleBulkDeleteCampaignLog(
                    selected.map((_selected) => _selected.id)
                  )
                }
              />
            ) : (
              <BBBSelect
                options={
                  dataFilters?.map((status) => ({
                    label: status,
                    value: status,
                  })) ?? []
                }
                loading={loadingFilters}
                placeholder="Filter by status"
                optionLabel="label"
                optionValue="value"
                value={filterStatus}
                onValueChange={(opt) =>
                  setFilterStatus(opt?.value === 'all' ? null : opt)
                }
              />
            )
          }
          isColumnHeightFixed
          isAsyncSort
          onClickSortHeader={(key, sort) => {
            setSort(`${key}:${sort}`);
          }}
        />
      )}
    </>
  );
};

export default MessageLog;
