import React, { useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Controller, FieldError } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { setActiveDesign, setActivePreview } from 'stores/bitApp';
import * as yup from 'yup';

import {
  BBBModal,
  BBBSelect,
  BBBTextInput,
  SingleOnValueChangeParam,
} from '@/components';
import { CountryOptions } from '@/constants/systemLogin/countries';
import industries from '@/constants/systemLogin/industries';
import useAddUserCompany from '@/hooks/auth/useAddUserCompany';
import useAuthenticatedUserCompanies from '@/hooks/auth/useAuthenticatedUserCompanies';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useCompany } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setActiveCompany, setLoadingChangeCompany } from '@/stores/auth';
import { setActiveStatus } from '@/stores/bitCRM';
import { setSelectedLivechat } from '@/stores/bitCRM';
import { Company } from '@/types/systemLogin';
import { handleCancelLiveSection } from '@/utils/bitApp';
import { _localStorage } from '@/utils/common/localStorage';
import { toast } from '@/utils/common/toast';

export default function CompanyList() {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const history = useHistory();

  const query = useAuthenticatedUserCompanies({ search });

  const {
    data: companiesData,
    isInitialLoading: isLoadingCompanies,
    hasNextPage,
    fetchNextPage,
  } = query;

  const selectedCompany = useCompany();

  const [showAddCompany, setShowAddCompany] = useState(false);

  const companyOptions = useMemo<
    Partial<Company & { logo?: string | React.ReactNode }>[]
  >(
    () => [
      ...(companiesData?.pages
        .flatMap((page) => page.data)
        ?.map((userCompany) => userCompany.company) || []),
      {
        name: 'Add company',
        logo: <PlusCircle />,
        id: -1,
      },
    ],
    [companiesData?.pages]
  );

  const handleChangeCompany = (
    opt: SingleOnValueChangeParam<
      Partial<Company & { logo?: string | React.ReactNode }>
    >
  ) => {
    if (!opt?.id) return;

    if (opt.id === -1) {
      setShowAddCompany(true);
      return;
    }

    dispatch(setLoadingChangeCompany(true));

    setTimeout(() => {
      _localStorage.setItem('activeCompany', opt.id!.toString());
      dispatch(setActiveCompany(opt.id!));
      dispatch(setSelectedLivechat(null));
      dispatch(setLoadingChangeCompany(false));
      dispatch(setActivePreview('home'));
      dispatch(setActiveDesign('layout'));
      handleCancelLiveSection();

      _localStorage.removeItem('activeStatus1');
      dispatch(setActiveStatus(null));

      history.push('/');
    }, 500);
  };

  return (
    <>
      {showAddCompany && (
        <CompanyCreationModal
          show={showAddCompany}
          onChangeShow={(val) => setShowAddCompany(val)}
        />
      )}

      <BBBSelect
        containerClassName="mb-4 mx-4"
        options={companyOptions}
        value={selectedCompany}
        onValueChange={handleChangeCompany}
        optionLabel="name"
        optionIcon="logo"
        loading={isLoadingCompanies}
        optionValue="id"
        placeholder="Select Store"
        isSearchable
        search={search}
        onChangeSearch={setSearch}
        isPaginated
        fetchNext={fetchNextPage}
        hasMore={!!hasNextPage}
      />
    </>
  );
}

const companyCreateSChema = yup.object().shape({
  company_name: yup.string().required('Company name is required'),
  industries: yup.mixed().required('Industry is required'),
  country: yup.mixed().required('Country is required'),
});

type CompanyCreationFormSchema = {
  industries?: {
    label: string;
    value: string;
  };
  country?: {
    label: string;
    value: string;
  };
  company_name: string;
};

type CompanyCreationModalProps = {
  onChangeShow: (data: boolean) => void;
  show: boolean;
};

function CompanyCreationModal({
  show,
  onChangeShow,
}: CompanyCreationModalProps) {
  const { handleSubmit, formState, control, reset } =
    useCustomForm<CompanyCreationFormSchema>({
      resolver: yupResolver(companyCreateSChema),
      defaultValues: {
        company_name: '',
        industries: undefined,
        country: undefined,
      },
    });

  const { mutate: addCompany, isLoading } = useAddUserCompany();

  const onSubmit = (data: CompanyCreationFormSchema) => {
    addCompany(
      {
        country: data.country?.value,
        name: data.company_name,
        industry: data.industries?.label,
      },
      {
        onSuccess: (_, payload) => {
          toast.success(`Succesfully added ${payload.name} to your company.`);
          onChangeShow(false);
          reset();
        },
      }
    );
  };

  return (
    <BBBModal
      show={show}
      onHide={() => onChangeShow(false)}
      title="Create company"
      footer
      handleSave={() => handleSubmit(onSubmit)()}
      disableSave={isLoading}
    >
      <BBBTextInput
        isHookForm
        control={control}
        controlName="company_name"
        placeholder="What is your company name?"
        label="Company name"
        error={formState.errors.company_name?.message}
      />
      <Controller
        name="country"
        control={control}
        render={({ field }) => (
          <BBBSelect
            value={field.value}
            options={CountryOptions}
            placeholder="What country are you from?"
            optionLabel="label"
            containerClassName="mb-3"
            optionValue="value"
            onValueChange={field.onChange}
            isSearchable
            label="Country"
            error={(formState.errors.country as FieldError)?.message}
          />
        )}
      />
      <Controller
        name="industries"
        control={control}
        render={({ field }) => (
          <BBBSelect
            value={field.value}
            options={industries}
            optionLabel="label"
            containerClassName="mb-3"
            optionValue="value"
            onValueChange={field.onChange}
            isSearchable
            label="Industry"
            placeholder="What industry are you in?"
            error={(formState.errors.industries as FieldError)?.message}
          />
        )}
      />
    </BBBModal>
  );
}
