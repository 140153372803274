import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { customOperationalHour } from '@/constants/bitChat/settings';
import { IntegrationName } from '@/types/bitChat/integrations';
import { Accent } from '@/types/utils/color';
import { DeepPartial } from '@/types/utils/deepPartial';
import { Flow } from '@/types/whatsApp/flow';

export default {
  getSettings: (companyId: number) =>
    api.whatsApp.v1
      .get<ApiResponse<Settings>>(`/settings/${companyId}`)
      .then((res) => res.data.data),
  updateSettings: (companyId: number, payload: DeepPartial<Settings>) =>
    api.whatsApp.v1
      .post<Partial<Settings>>(`/settings/${companyId}`, payload)
      .then((res) => res.data),
  deleteAiTag: (companyId: number, aiTagId: string) =>
    api.bitChat.delete(`/settings/${companyId}/ai-tag/${aiTagId}`),
};

export type SettingsTag = {
  label: string;
  color: Accent;
  id: string;
  name?: string;
};

export type SettingsAITagging = {
  id: string;
  label: string;
  description: string;
  values: string;
};

export type SettingsFallback = { name: string; message: string; id: string };

export type Settings = {
  companyId: number;
  operationalHours: boolean;
  operationalHoursType: string;
  operationalHourStart: string | null;
  operationalStartEnd: string | null;
  operationalHourEnd: string | null;
  operationHourCustomDays: typeof customOperationalHour | null;
  copilot: boolean;
  notificationPopUp: boolean;
  notificationSound: boolean;
  notificationEmail: boolean;
  individualChat: boolean;
  groupChat: boolean;
  assignedTicket: boolean;
  assignedTicketEmail: boolean;
  abandonedTicketEmail: boolean;
  abandonedTicketTime: number;
  abandonedTicketUnit: string;
  ticketChat: boolean;
  createadAt: string;
  updatedAt: string;
  autoResolveTicket: boolean;
  autoResolveTime?: number;
  autoResolveType: string;
  ticketTags?: SettingsTag[];
  aiTags?: SettingsAITagging[];
  fallback: SettingsFallback[] | null;
  recoName: string;
  recoActive: boolean;
  trackoName: string;
  trackoActive: boolean;
  roboName: string;
  roboActive: boolean;
  recoInstruction: string | null;
  trackoInstruction: string | null;
  roboInstruction: string | null;
  aiDescription: string | null;
  welcomeMessageActive: boolean;
  awayMessageActive: boolean;
  welcomeMessage: Flow | null;
  awayMessage: Flow | null;
};

export type WACloudTemplate = {
  id: string;
  companyId: number;
  businessId: string | null;
  templateId: string | null;
  name: string;
  source: IntegrationName;
  language: string;
  message: string;
  status: MessageTemplateStatus;
  isDraft: boolean;
  createdAt: Date;
  updatedAt: Date;
};

type MessageTemplateStatus =
  | 'APPROVED'
  | 'WAITING'
  | 'REJECTED'
  | 'DRAFT'
  | 'ERROR'
  | 'PREPARING';
