export default function ConditionIcon({
  size = 24,
}: {
  size?: number | string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 23 28"
    >
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M14.567 7.617a4.217 4.217 0 014.216-4.216H22a1 1 0 001-1V1.334a1 1 0 00-1-1h-3.217A7.283 7.283 0 0011.5 7.617a4.217 4.217 0 01-4.217 4.217H1a1 1 0 00-1 1v1.833a1 1 0 001 1h6.283a4.217 4.217 0 014.217 4.217 7.283 7.283 0 007.283 7.283H22a1 1 0 001-1v-1.066a1 1 0 00-1-1h-3.217a4.217 4.217 0 01-4.216-4.217 7.278 7.278 0 00-3.354-6.133 7.278 7.278 0 003.354-6.134z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
