import { useEffect, useState } from 'react';
import InitialSteps from './InitialSteps';
import PreviewAdditionalForm from './PreviewAdditionalForm';
import PreviewLogin from './PreviewLogin';

import { BBBContainer, BBBContainerTypes } from '@/components/BBBContainer';
import {
  BBBOnboardingModal,
  BBBStepOnboarding,
} from '@/components/BBBOnboardingModal';
import {
  BITLOGIN_APPEARANCE_STEPS,
  BITLOGIN_SOCIAL_STEPS,
} from '@/constants/bitLogin/stepsOnboarding';
import useOnboarding from '@/hooks/bitLogin/onboarding/useOnboarding';
import useUpdateOnboarding from '@/hooks/bitLogin/onboarding/useUpdateOnboarding';
import useResponsive from '@/hooks/common/useResponsive';
import { EnumModuleKey } from '@/types/onboarding';

type Props = BBBContainerTypes & {
  module: 'appearance' | 'social-login' | 'sign-up';
};

export default function Wrapper({ children, module, ...props }: Props) {
  const isMobile = useResponsive('sm');
  const showPreviewLogin = !isMobile && module !== 'sign-up';
  const showPreviewAdditionalForm = !isMobile && module === 'sign-up';

  return (
    <div className="flex min-h-full">
      <div className="flex-[2_2_0%] relative">
        <div className="absolute inset-0">
          <BBBContainer
            className={isMobile ? 'border-transparent' : undefined}
            {...props}
          >
            <InitialSteps className="mb-cardBottom" />
            {children}
          </BBBContainer>
        </div>
      </div>
      {showPreviewLogin && <PreviewLogin />}
      {showPreviewAdditionalForm && <PreviewAdditionalForm />}
      {(module === 'social-login' || module === 'appearance') && (
        <Onboarding
          steps={
            module === 'social-login'
              ? BITLOGIN_SOCIAL_STEPS
              : BITLOGIN_APPEARANCE_STEPS
          }
          moduleKey={
            module === 'social-login'
              ? 'social-login-config'
              : 'social-login-appearance'
          }
        />
      )}
    </div>
  );
}

type OnboardingProps = {
  steps: BBBStepOnboarding[];
  moduleKey: EnumModuleKey;
};

function Onboarding({ steps, moduleKey }: OnboardingProps) {
  const [stepIndex, setStepIndex] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const { mutate: updateOnboarding } = useUpdateOnboarding();
  const { data, status } = useOnboarding(moduleKey);

  const handleNext = () => {
    updateOnboarding({
      moduleKey,
      lastStep: stepIndex + 1,
    });
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      setShowOnboarding(false);
    }
  };

  const handleBack = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
      updateOnboarding({
        moduleKey,
        lastStep: stepIndex - 1,
      });
    }
  };

  useEffect(() => {
    if (status === 'success') {
      const lastStep = data?.lastStep ?? 0;
      if (lastStep < steps.length) {
        setStepIndex(lastStep ?? 0);
        setShowOnboarding(true);
      } else {
        setShowOnboarding(false);
      }
    }
  }, [data?.lastStep, status, steps.length]);

  return (
    <BBBOnboardingModal
      steps={steps}
      show={showOnboarding}
      handleBackButton={handleBack}
      onHide={() => setShowOnboarding(false)}
      currentStep={stepIndex}
      handleButton={handleNext}
    />
  );
}
