import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Facebook, Instagram, Mail, Twitter, Youtube } from 'react-feather';
import { FaTiktok } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { useQueryClient } from '@tanstack/react-query';
import useUpdateSocialMedia, {
  useUpdateSocialMediaPosition,
} from 'hooks/bitLink/socmed/useUpdateSocialMedia';
import useResponsive from 'hooks/common/useResponsive';
import { useAppSelector } from 'hooks/rtk/store';
import { isEqual } from 'lodash-es';
import { capitalize } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import { twMerge as cx } from 'tailwind-merge';
import Link2 from '../Link/components/Links/Link2';
import LinkEdit from '../Link/components/Links/LinkEdit';

import WhatsAppIcon2 from '@/assets/icons/WhatsAppIcon2';
import { BBBAlert, BBBBottomSheet, BBBCard, BBBContainer } from '@/components';
import useBranding from '@/hooks/bitLink/appearance/useBranding';
import useEditBranding from '@/hooks/bitLink/appearance/useEditBranding';
import useSocialMedia from '@/hooks/bitLink/socmed/useSocialMedia';
import {
  Branding,
  SocialMedia,
  SocialMediaKind,
  SocialMediaType,
} from '@/types/bitLink/v2';
import { formatInternational } from '@/utils/common/phone';

function SocialMediaSkeleton() {
  return (
    <>
      {Array.from({ length: 4 }).map(() => (
        <>
          <BBBCard className="mb-3">
            <div className="flex items-center gap-3">
              <Skeleton circle width={30} height={30} />
              <div className="grow">
                <Skeleton />
              </div>
              <Skeleton width={40} />
            </div>
          </BBBCard>
        </>
      ))}
    </>
  );
}

export const socialSection = {
  instagram: { icon: <Instagram color="#262627" />, label: 'Instagram' },
  facebook: { icon: <Facebook color="#262627" />, label: 'Facebook' },
  twitter: { icon: <Twitter color="#262627" />, label: 'Twitter' },
  tiktok: { icon: <FaTiktok color="#262627" size={16} />, label: 'Tiktok' },
  youtube: { icon: <Youtube color="#262627" />, label: 'Youtube' },
  email: { icon: <Mail color="#262627" />, label: 'Email' },
  whatsapp: {
    icon: <WhatsAppIcon2 color="#262627" width={24} height={24} />,
    label: 'whatsapp',
  },
} as const;

const socialMediaOrder = [
  {
    label: 'top',
    icon: <TopIcon />,
  },
  {
    label: 'bottom',
    icon: <BottomIcon />,
  },
] as const;

const BitLinkSocialMedia = () => {
  const [editingSection, setEditingSection] = useState<SocialMediaType>();
  const isMobile = useResponsive('sm');

  const { mutate: editSocial, isLoading } = useUpdateSocialMedia();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const client = useQueryClient();
  const { mutate: rearrangeSocial } = useUpdateSocialMediaPosition();

  const { data: brandingData, isInitialLoading: isLoadingBranding } =
    useBranding();

  const { mutate: editBranding, isLoading: isLoadingEditBranding } =
    useEditBranding();

  const { data: socialMediaData, isInitialLoading: isLoadingSocial } =
    useSocialMedia();

  const [newSocialMediaData, setNewSocialMediaData] = useState<
    (SocialMedia & {
      socialMediaKind: SocialMediaKind;
    })[]
  >();

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (
      source.droppableId === 'social' &&
      destination?.droppableId === 'social'
    ) {
      const cloneSocial = cloneDeep(
        (client.getQueryData([
          'bitlink-social',
          activeCompany,
        ]) as (SocialMedia & {
          socialMediaKind: SocialMediaKind;
        })[]) || []
      );
      const [reorderedItem] = cloneSocial.splice(source.index, 1);
      cloneSocial.splice(destination?.index ?? -99, 0, reorderedItem);
      rearrangeSocial(
        cloneSocial.map((data, index) => ({
          socialMediaKindId: data?.socialMediaKindId,
          position: index + 1,
        }))
      );
    }
  };

  useEffect(() => {
    setNewSocialMediaData(
      cloneDeep(
        socialMediaData?.sort((a, b) =>
          (a.position || -1) > (b.position || -1) ? 1 : -1
        ) || []
      )
    );
  }, [socialMediaData]);

  const isBrandingDataChanged = useCallback(
    (name: SocialMediaType) =>
      !isEqual(
        newSocialMediaData?.find(
          (newData) => newData.socialMediaKind.name === name
        ),
        cloneDeep(
          socialMediaData?.find(
            (oldData) => oldData.socialMediaKind.name === name
          )
        )
      ),
    [newSocialMediaData, socialMediaData]
  );

  const handleEditSocial = (
    name: SocialMediaType,
    value: { [k in 'active' | 'username']?: SocialMedia[k] }
  ) => {
    setNewSocialMediaData((prev) => {
      const previousSocialSection = Array.from(prev || []);
      const foundedSection =
        prev?.findIndex((social) => social.socialMediaKind.name === name) ?? -1;
      if (foundedSection >= 0) {
        previousSocialSection[foundedSection] = {
          ...previousSocialSection[foundedSection],
          ...value,
        };
      }
      return previousSocialSection;
    });
  };

  const handleChangeActiveDirectly = (
    name: SocialMediaType,
    active: boolean | undefined
  ) => {
    const mediaToSubmit = newSocialMediaData?.find(
      (data) => data.socialMediaKind.name === name
    );
    if (mediaToSubmit) {
      if (!mediaToSubmit.username && active) {
        handleEditSocial(name, {
          active: true,
        });
        setEditingSection(name);
      } else {
        editSocial({
          media: mediaToSubmit.socialMediaKindId,
          username: mediaToSubmit.username || '',
          active: active || false,
        });
      }
    }
  };

  const handleSubmitBranding = useCallback(
    (name: SocialMediaType) => {
      const mediaToSubmit = newSocialMediaData?.find(
        (data) => data.socialMediaKind.name === name
      );
      if (mediaToSubmit) {
        editSocial(
          {
            media: mediaToSubmit.socialMediaKindId,
            username: mediaToSubmit.username || '',
            active: true,
          },
          {
            onSuccess: () => {
              setEditingSection(undefined);
            },
          }
        );
      }
    },
    [editSocial, newSocialMediaData]
  );

  const handleCancelSocial = useCallback(
    (name: SocialMediaType) => {
      const newSocialCloned = Array.from(newSocialMediaData || []);
      const canceledSocialIndex =
        newSocialMediaData?.findIndex(
          (newSocial) => newSocial.socialMediaKind.name === name
        ) ?? -1;
      if (canceledSocialIndex >= 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newSocialCloned[canceledSocialIndex] = socialMediaData?.find(
          (oldSocial) => oldSocial.socialMediaKind.name === name
        );
      }
      setEditingSection(undefined);
      setNewSocialMediaData(newSocialCloned);
    },
    [newSocialMediaData, socialMediaData]
  );

  const handleEditPlacement = (value: Branding['sosmed_placement']) => {
    editBranding({
      sosmed_placement: value,
    });
  };

  const currentActiveSocial = useMemo(
    () =>
      newSocialMediaData?.find(
        (social) => social.socialMediaKind.name === editingSection
      ),
    [editingSection, newSocialMediaData]
  );

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <BBBContainer
        hasHeading
        pageTitle="Social"
        pageDescription="Addded social media will be automatically shown on your link"
      >
        {isLoadingSocial || isLoadingBranding ? (
          <SocialMediaSkeleton />
        ) : !socialMediaData?.length ? (
          <BBBAlert type="warning" message="No social media data found" />
        ) : (
          <>
            {isMobile && !!editingSection && (
              <BBBBottomSheet
                show
                title={`Add ${currentActiveSocial!.socialMediaKind.name}`}
                onClose={() => setEditingSection(undefined)}
              >
                <LinkEdit
                  disableDrag
                  onCancel={() =>
                    handleCancelSocial(
                      currentActiveSocial!.socialMediaKind.name
                    )
                  }
                  key={currentActiveSocial!.socialMediaKind.name}
                  isSubmitDisabled={
                    isLoading ||
                    !isBrandingDataChanged(
                      currentActiveSocial!.socialMediaKind.name
                    ) ||
                    !currentActiveSocial!.username
                  }
                  isActive={currentActiveSocial!.active || false}
                  onChangeActive={(val) =>
                    handleEditSocial(
                      currentActiveSocial!.socialMediaKind.name,
                      {
                        active: val || false,
                      }
                    )
                  }
                  onSubmit={() =>
                    handleSubmitBranding(
                      currentActiveSocial!.socialMediaKind.name
                    )
                  }
                  onValueChange={(social, value) =>
                    handleEditSocial(social, {
                      username: value,
                    })
                  }
                  section={currentActiveSocial!}
                />
              </BBBBottomSheet>
            )}

            <Droppable droppableId="social">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {newSocialMediaData?.map((section, index) =>
                    editingSection === section?.socialMediaKind?.name &&
                    !isMobile ? (
                      <LinkEdit
                        index={index}
                        onCancel={() =>
                          handleCancelSocial(section.socialMediaKind.name)
                        }
                        key={section.socialMediaKind.name}
                        isSubmitDisabled={
                          isLoading ||
                          !isBrandingDataChanged(
                            section.socialMediaKind.name
                          ) ||
                          !section.username
                        }
                        isActive={section.active || false}
                        onChangeActive={(val) =>
                          handleEditSocial(section.socialMediaKind.name, {
                            active: val || false,
                          })
                        }
                        onSubmit={() =>
                          handleSubmitBranding(section.socialMediaKind.name)
                        }
                        onValueChange={(social, value) =>
                          handleEditSocial(social, {
                            username: value,
                          })
                        }
                        section={section}
                      />
                    ) : (
                      <Link2
                        logo={socialSection[section.socialMediaKind.name].icon}
                        title={
                          section.username ? (
                            <p className="text-primary-main text-sm font-medium">
                              {section.socialMediaKind.name === 'whatsapp'
                                ? formatInternational(section.username)
                                : section.username}
                            </p>
                          ) : (
                            <p className="text-neutral-40 text-sm">
                              {section.socialMediaKind.name === 'email'
                                ? 'Add email address'
                                : section.socialMediaKind.name === 'whatsapp'
                                ? 'Add your WhatsApp number'
                                : 'Add username or URL'}
                            </p>
                          )
                        }
                        key={section.socialMediaKind.name}
                        index={index}
                        onClick={() =>
                          setEditingSection(section.socialMediaKind.name)
                        }
                        id={section.socialMediaKind.name}
                        isActive={section.active || false}
                        onChangeActive={(val) =>
                          handleChangeActiveDirectly(
                            section.socialMediaKind.name,
                            val
                          )
                        }
                      />
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <SocialMediaOrder
              active={brandingData?.sosmed_placement}
              onChange={(val) => handleEditPlacement(val)}
            />
          </>
        )}
      </BBBContainer>
    </DragDropContext>
  );
};

function SocialMediaOrder({
  active,
  onChange,
}: {
  active: Branding['sosmed_placement'] | undefined;
  onChange: (val: Branding['sosmed_placement']) => void;
}) {
  return (
    <BBBCard title="Social media position" className="mt-4 w-fit">
      <div className="flex gap-5">
        {socialMediaOrder?.map((order) => (
          <div
            key={order.label}
            className="flex flex-col items-center gap-2.5"
            onClick={() => onChange(order.label)}
          >
            <div
              className={cx(
                'py-3 w-40 rounded-lg border border-neutral-30 cursor-pointer',
                active === order.label && 'border-primary-main'
              )}
            >
              {order.icon}
            </div>
            <p className="text-primary-main text-center text-sm">
              {capitalize(order.label)}
            </p>
          </div>
        ))}
      </div>
    </BBBCard>
  );
}

function TopIcon() {
  return (
    <svg
      width="66"
      height="98"
      viewBox="0 0 66 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
    >
      <rect
        x="0.5"
        y="0.5"
        width="65"
        height="97"
        rx="7.5"
        fill="#F8F8F8"
        stroke="#DDDDDD"
      />
      <circle cx="33.0247" cy="18.7734" r="8" fill="#D4D4D4" />
      <rect x="7" y="47" width="52" height="12" rx="6" fill="#D4D4D4" />
      <rect x="10" y="32" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="22" y="32" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="34" y="32" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="46" y="32" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="7" y="62" width="52" height="12" rx="6" fill="#D4D4D4" />
    </svg>
  );
}
function BottomIcon() {
  return (
    <svg
      width="66"
      height="98"
      viewBox="0 0 66 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
    >
      <rect
        x="0.5"
        y="0.5"
        width="65"
        height="97"
        rx="7.5"
        fill="#F8F8F8"
        stroke="#DDDDDD"
      />
      <circle cx="33.0247" cy="18.7734" r="8" fill="#D4D4D4" />
      <rect x="7" y="47" width="52" height="12" rx="6" fill="#D4D4D4" />
      <rect x="10" y="63" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="22" y="63" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="34" y="63" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="46" y="63" width="10" height="10" rx="5" fill="#FD823E" />
      <rect x="7" y="32" width="52" height="12" rx="6" fill="#D4D4D4" />
    </svg>
  );
}

export default BitLinkSocialMedia;
