import { useHistory } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { CheckKeywordAvailabilityParams } from '@/api/services/whatsApp/flow';
import { useActiveCompany } from '@/hooks/rtk/selector';
import useStore from '@/pages/BitChat/Chatbot/store';
import { ChannelIntegrations } from '@/types/bitChat/integrations';
import { Tags } from '@/types/customers';
import { DeepPartial } from '@/types/utils/deepPartial';
import { Flow } from '@/types/whatsApp/flow';
import { SettingsTag } from '@/types/whatsApp/settings';
import { toast } from '@/utils/common/toast';

export const useFlows = (
  params?: {
    search?: string;
    channel: ChannelIntegrations;
    time?: string | undefined;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['flows', activeCompany, params],
    () => services.whatsApp.flow.getFlows(activeCompany, params),
    options
  );
};

export const useFlows1 = (
  params?: {
    search?: string;
    channel: ChannelIntegrations;
    time?: string | undefined;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['flows', activeCompany, params],
    () => services.whatsApp.flow.getFlows(activeCompany, params),
    {
      ...options,
      select: (data) =>
        data?.filter(
          (flow) =>
            !['welcome_action', 'away_message'].includes(flow.triggerType)
        ),
    }
  );
};

export const useFlow = (id?: string) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['flow', id],
    () => services.whatsApp.flow.getFlowById(activeCompany, id!),
    {
      enabled: !!id && id !== 'new',
    }
  );
};

export const useDeleteFlow = () => {
  const history = useHistory();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => services.whatsApp.flow.deleteFlow(activeCompany, id),
    {
      onSuccess: () => {
        history.push(`/bitchat/flows`);
        queryClient.invalidateQueries(['flows']);
      },
    }
  );
};

export const useDuplicateFlow = () => {
  const history = useHistory();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const setLoadingDuplicateFlow = useStore((s) => s.setLoadingDuplicateFlow);

  return useMutation(
    (id: string) => services.whatsApp.flow.duplicateFlow(activeCompany, id),
    {
      onMutate: () => {
        setLoadingDuplicateFlow(true);
      },
      onSuccess: ({ data }) => {
        history.push(`/bitchat/flows/${data.data.id}`);
        queryClient.invalidateQueries(['flows']);
        setTimeout(() => {
          setLoadingDuplicateFlow(false);
        }, 3000);
      },
      onError: () => {
        setTimeout(() => {
          setLoadingDuplicateFlow(false);
        }, 3000);
      },
    }
  );
};

export const useUpdateFlow = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    (
      payload: DeepPartial<Flow> & {
        skipRedirect?: boolean;
      }
    ) =>
      services.whatsApp.flow.updateFlow({
        ...payload,
        companyId: activeCompany,
      }),
    {
      onSuccess: (data, payload) => {
        toast.success(
          payload.id
            ? `Sucesfully update chatbot`
            : `Chatbot ${payload.name} created`
        );

        queryClient.invalidateQueries(['flows']);
        queryClient.invalidateQueries(['flow']);

        if (!payload.skipRedirect && !payload.id) {
          history.push(`/bitchat/flows/${data.id}`);
        }
      },
    }
  );
};

export const useToggleFlow = (queryKey?: Record<string, any>) => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, ...payload }: Pick<Flow, 'id' | 'status' | 'name'>) =>
      services.whatsApp.flow.toggleFlow(activeCompany, payload),
    {
      onSuccess: (data, { name, ...payload }) => {
        toast.success(
          `Chatbot ${name} has been ${
            payload.status === 'ACTIVE' ? 'activated' : 'inactivated'
          }`
        );
      },
      onMutate: async ({ status, id }) => {
        await queryClient.cancelQueries({
          queryKey: ['flows', activeCompany, queryKey],
        });

        await queryClient.cancelQueries({
          queryKey: ['flow', id],
        });

        const previousFlowsData = queryClient.getQueryData([
          'flows',
          activeCompany,
          queryKey,
        ]);

        queryClient.setQueryData<Flow[]>(
          ['flows', activeCompany, queryKey],
          (old) => {
            return old?.map((prev) =>
              prev.id === id
                ? {
                    ...prev,
                    status,
                  }
                : prev
            );
          }
        );

        const previousFlowData = queryClient.getQueryData(['flow', id]);

        queryClient.setQueryData<Flow>(['flow', id], (old) => {
          if (old) {
            old.status = status;
          }
          return old;
        });

        return { previousFlowsData, previousFlowData };
      },
      onSettled: () => {
        queryClient.invalidateQueries(['flows']);
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['flows', queryKey],
          context?.previousFlowsData
        );
      },
    }
  );
};

export const useSelectWelcomeAction = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: {
      id: string | number;
      type: 'away_message' | 'welcome_message';
      source: ChannelIntegrations;
      ticketCategory: Pick<SettingsTag, 'label' | 'id'> | null;
      customerTags: Pick<Tags, 'id' | 'name'>[] | null;
    }) => services.whatsApp.flow.selectWelcomeAction(activeCompany, payload),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries(['flows']);
        toast.success(
          `Sucesfully update ${
            payload.type === 'welcome_message'
              ? 'welcome action'
              : 'away action'
          }`
        );
      },
    }
  );
};

export const useCheckKeywordAvailability = () => {
  const activeCompany = useActiveCompany();

  return useMutation((payload: CheckKeywordAvailabilityParams) =>
    services.whatsApp.flow.checkKeywordAvailability(activeCompany, payload)
  );
};

export const useSuggestedKeywords = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['flow-suggestedKeywords', activeCompany], () =>
    services.whatsApp.flow.getSuggestedKeywords(activeCompany)
  );
};

export const useHasNewChatbot = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['count-chatbot', activeCompany],
    () => services.whatsApp.flow.getCountNewFlow(activeCompany),
    {
      select: (data) => data > 0,
    }
  );
};
