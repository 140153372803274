import { useEffect, useRef } from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import isEqual from 'lodash-es/isEqual';
import { FormSchema } from '..';

import { BBBSelect, BBBTextInput } from '@/components';
import { useParametersOptions } from '@/hooks/bitCRM/template/template';
import { AvailableSources } from '@/hooks/whatsApp/useAvailableSources';
import { AutomationFormSchema } from '@/pages/BitCRM/Automation/New/validation';
import {
  CampaignTemplate,
  TemplateBodyParameters,
} from '@/types/bitCRM/template';

type Props = {
  control: Control<FormSchema, any> | Control<AutomationFormSchema, any>;
  formContext: 'automation' | 'campaign';
  channel: AvailableSources;
  setValue: UseFormSetValue<FormSchema> | UseFormSetValue<AutomationFormSchema>;
};

export default function Params({
  control,
  formContext,
  channel,
  setValue,
}: Props) {
  const { fields: fieldParams } = useFieldArray({
    //@ts-ignore
    control,
    name: 'param',
  });

  const { errors } = useFormState({
    //@ts-ignore
    control,
  });

  const template = useWatch({
    //@ts-ignore
    control,
    name: formContext === 'campaign' ? 'template' : 'body.template',
  }) as CampaignTemplate | null;

  const params = useWatch({
    //@ts-ignore
    control,
    name: 'param',
  }) as (TemplateBodyParameters | undefined)[] | null;

  const prevParamsRef = useRef<(TemplateBodyParameters | undefined)[]>();

  useEffect(() => {
    const newParams =
      template?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
      template?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
        ? template.params?.map((param, idx) => {
            return params?.[idx] || param;
          })
        : undefined;

    if (!isEqual(newParams, prevParamsRef.current)) {
      //@ts-ignore
      setValue('param', newParams);
      prevParamsRef.current = newParams;
    }
  }, [params, setValue, template?.params, template?.type]);

  const { data: parameterOptions } = useParametersOptions(formContext, {
    enabled: channel === 'whatsapp-cloud',
  });

  return (
    <div className="flex flex-col gap-2 my-2">
      {fieldParams?.map((item, index) => (
        <div key={item.id}>
          <div className="flex gap-2">
            <BBBTextInput
              placeholder="Enter param value here"
              readOnly
              value={`{{${index + 1}}}`}
              containerClassname="mb-0 w-24"
              label="Parameter"
            />
            <Controller
              //@ts-ignore
              control={control}
              name={`param.${index}`}
              render={({ field }) => (
                <BBBSelect
                  options={parameterOptions}
                  optionLabel="label"
                  optionValue="value"
                  containerClassName="grow mb-0"
                  placeholder="Select variable"
                  value={field.value}
                  onValueChange={field.onChange}
                  label="Variable"
                  error={errors.param?.[index]?.value?.message}
                />
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
