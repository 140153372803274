import { useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { PlusCircle, Trash2 } from 'react-feather';

import {
  CollectionSortingRow,
  Product as ProductType,
} from '@/api/services/bitAi/v2-new/sorting';
import BBBDraggableIcon from '@/assets/icons/BBBDraggableIcon';
import { BBBTooltip } from '@/components';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { env } from '@/config/env';

const useColumnManage = ({
  onShowAddProductModal,
  onRearrangeProducts,
  onDeleteProduct,
}: {
  onShowAddProductModal: (val: string) => void;
  onRearrangeProducts: (params: {
    startIndex: number;
    endIndex: number;
    draggableId: string;
  }) => void;
  onDeleteProduct: (params: { productId: string; rowId: string }) => void;
}) => {
  const reorderData = useCallback(
    (result: DropResult) => {
      const { source, destination, type, draggableId } = result;

      if (!destination) return;

      const startIndex = source.index;
      const endIndex = destination.index;

      if (type.startsWith('droppableSub')) {
        onRearrangeProducts({ startIndex, endIndex, draggableId });

        return;
      }
    },
    [onRearrangeProducts]
  );

  return useMemo<HeadersTypes<CollectionSortingRow>>(
    () => [
      {
        accesor: 'condition',
        renderHeader: () => 'Product ordering',
        headerClassName: 'pl-8',
        render: (row, value) => <p className="text-primary-main">{value}</p>,
      },
      {
        accesor: 'qty',
        renderHeader: () => 'Product Qty',
        render: (row) => (
          <p className="text-primary-main">
            {row.qty === -1 ? '*Custom*' : row.qty}
          </p>
        ),
      },
      {
        isAdditionalColumn: true,
        renderHeader: () => 'Feature Product',
        headerAlign: 'center',
        render: (row) => {
          return (
            <DragDropContext onDragEnd={reorderData}>
              <Droppable
                droppableId={`${row.id}`}
                type={`droppableSub-${row.id}`}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {row.featuredProduct.map((product, index) => (
                      <Draggable
                        draggableId={`${row.id}-${product.id}`}
                        index={index}
                        key={`${row.id}-${product.id}`}
                      >
                        {(provided) => (
                          <Product
                            provided={provided}
                            onClickDelete={() => {
                              onDeleteProduct({
                                productId: product.id,
                                rowId: row.id,
                              });
                            }}
                            {...product}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <div
                      className="grow flex items-center gap-2 cursor-pointer py-2"
                      onClick={() => {
                        onShowAddProductModal(row.id);
                      }}
                    >
                      <PlusCircle size="16px" />
                      <div className="grow">Add product</div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          );
        },
      },
    ],
    [onDeleteProduct, onShowAddProductModal, reorderData]
  );
};

function Product({
  provided,
  onClickDelete,
  ...product
}: ProductType & {
  provided: DraggableProvided;
  onClickDelete: () => void;
}) {
  return (
    <div
      className="flex items-center gap-4 py-2 pr-2 rounded-lg cursor-pointer group"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div>
        <BBBDraggableIcon color="#DDDDDD" />
      </div>
      <div className="flex w-full grow justify-between items-center">
        <div className="flex items-center gap-2">
          <img
            src={
              product.imageUrl ||
              `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
            }
            alt={product.title}
            className="w-6 h-6 rounded object-cover"
          />
          <BBBTooltip
            content={product.title}
            offset={{ top: -20 }}
            show={product.title ? product.title.length > 40 : false}
          >
            <p className="text-primary-main line-clamp-1 w-[12rem]">
              {product.title}{' '}
            </p>
          </BBBTooltip>
        </div>
        <Trash2
          className="text-danger-main min-w-5 min-h-5 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
          onClick={() => onClickDelete()}
          height={20}
          width={20}
        />
      </div>
    </div>
  );
}

export default useColumnManage;
