import { useMemo } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import CreateLink from '../Form/Create';
import Link from './Link';

import { BBBAlert, BBBBottomSheet } from '@/components';
import useLinklistQuery from '@/hooks/bitLink/links/useLinklistQuery';
import useRearrangeLinklist from '@/hooks/bitLink/links/useRearrangeLinklist';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import UpsellCard from '@/pages/BitLink/components/UpsellCard';
import { JOYRIDE_STEPS_COUNT } from '@/pages/BitLink/Link/components/Journey';
import { setForm } from '@/stores/bitLink';
import { Linklists } from '@/types/bitLink/v2';

export default function Links() {
  const { data, status } = useOnboarding('links');

  if (status === 'success' && data?.lastStep) {
    if (data.lastStep < JOYRIDE_STEPS_COUNT) return <_Links isNewUsers />;
  }

  return <_Links />;
}

function _Links({ isNewUsers }: { isNewUsers?: boolean }) {
  const form = useAppSelector((state) => state.bitLink.form);
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('sm');
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const client = useQueryClient();
  const { mutate: rearrangeLinklist } = useRearrangeLinklist();

  const mapBottomSheetTitle: Record<string, string> = useMemo(() => {
    const prefix = form?.id ? 'Edit' : 'Create';
    return {
      link: `${prefix} link`,
      text: `${prefix} text`,
      divider: `${prefix} divider`,
      youtube: `${prefix} Youtube`,
      shop: `${prefix} shop`,
      whatsapp: `${prefix} WhatsApp`,
      image: `${prefix} image`,
    };
  }, [form?.id]);

  const {
    isInitialLoading: loadingSection,
    data: sections,
    fetchNextPage,
    hasNextPage,
  } = useLinklistQuery();

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      source.droppableId === 'linklist' &&
      destination?.droppableId === 'linklist'
    ) {
      const rerrangedList =
        client
          .getQueryData<
            | InfiniteData<{
                data: Linklists[];
                meta: {
                  page?: number | undefined;
                  limit: number;
                };
              }>
            | undefined
          >(['linklist', activeCompany])
          ?.pages.flatMap((page) => page.data) || [];

      const { destination, source } = result;
      const [reorderedItem] = rerrangedList.splice(source.index, 1);
      rerrangedList.splice(destination?.index || -99, 0, reorderedItem);
      rearrangeLinklist(rerrangedList);
    }
  };

  if (loadingSection) {
    return (
      <Skeleton
        count={10}
        height={50}
        width={500}
        containerClassName="flex flex-col items-center"
        highlightColor="white"
      />
    );
  }

  const data = sections?.pages.flatMap((page) => page.data);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="linklist">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            id="linklists"
          >
            {isMobile && form && (
              <BBBBottomSheet
                show
                title={mapBottomSheetTitle[form.type!]}
                onClose={() => dispatch(setForm(null))}
              >
                <CreateLink key={form.id} />
              </BBBBottomSheet>
            )}
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              scrollableTarget={'linklist-container'}
              dataLength={data?.length || 0}
              loader={undefined}
              className="!overflow-hidden"
            >
              <AnimatePresence mode="popLayout">
                {!isMobile && !form?.id && <CreateLink />}
                {data?.length === 0 ? (
                  <BBBAlert
                    type="warning"
                    message="You have no items to show. Begin by creating new section from button
      above!"
                  />
                ) : (
                  data?.map((item, index: number) => (
                    <>
                      {form?.id === item.id && !isMobile ? (
                        <CreateLink key={item.id} />
                      ) : (
                        <Link
                          index={index}
                          item={item}
                          key={item.id}
                          onClick={() => dispatch(setForm(item))}
                        />
                      )}
                      {!isNewUsers && index === 2 && <UpsellCard />}
                    </>
                  ))
                )}
              </AnimatePresence>
            </InfiniteScroll>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
