import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import 'react-image-crop/dist/ReactCrop.css';

import {
  BBBCard,
  BBBFileUpload,
  BBBFooterAction,
  BBBImageUpload,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components';
import { BBBRadio } from '@/components/BBBRadio';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from '@/hooks/bitLink/links/useLinklistMutation';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setForm } from '@/stores/bitLink';
import { Linklists } from '@/types/bitLink/v2';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

type Props = {
  imagePreview: string | null | undefined | Nullable<FileType>;
  link: string;
  title: string | null | undefined;
  active: boolean;
  display: string;
};

export default function FormImage() {
  const isMobile = useResponsive('sm');
  const formLink = useAppSelector((state) => state.bitLink.form);
  const schema = useMemo(
    () =>
      yup.object().shape({
        link: yup.string().url().required('Link URL is required!'),
        title: yup.string().optional(),
        imagePreview: isMobile
          ? yup.mixed<FileType>().required('Image is required!')
          : yup.string().nullable().required('Image is required!'),
        active: yup.bool().required('Active is required'),
        display: yup
          .mixed()
          .oneOf(['image-only', 'with-background'])
          .required('Display is required'),
      }),
    [isMobile]
  );

  const { setValue, formState, control, reset, handleSubmit, watch } =
    useCustomForm<Props>({
      resolver: yupResolver(schema),
      defaultValues: {
        imagePreview: '',
        title: '',
        link: '',
        active: true,
        display: 'image-only',
      },
    });

  const formImage = useAppSelector((state) => state.bitLink.form);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue(
      'imagePreview',
      isMobile
        ? convertRemoteUrlToFileType(formImage?.media)
        : formImage?.media || ''
    );
    setValue('link', formImage?.textSecondary || '');
    setValue('title', formImage?.textPrimary || '');
    setValue('display', formImage?.display || 'image-only');
    setValue('active', formImage?.active ?? true);
  }, [formImage, isMobile, setValue]);

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();

  const deleteLinklist = useLinklistDeleteMutation();

  const handleDeleteForm = () => {
    if (formImage?.id) {
      deleteLinklist(formImage?.id);
    } else {
      dispatch(setForm(null));
    }
  };

  const onSubmit = async (data: Props) => {
    const requestData: Partial<Linklists> = {
      type: 'image' as const,
      textSecondary: data.link,
      active: data.active,
      media:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof data.imagePreview === 'string'
          ? data.imagePreview
          : data.imagePreview?.remoteUrl,
      textPrimary: data.title,
      display: data.display,
    };

    if (!formImage?.id) {
      addLinklist(requestData);
    } else {
      editLinklist({ id: formImage?.id, data: requestData });
    }
  };

  return (
    <BBBCard
      id="form-image"
      title="Image"
      rightButton={
        !isMobile && (
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )
      }
      className={cx('mb-cardBottom md:p-6', isMobile && 'border-none p-0')}
    >
      {!isMobile ? (
        <Controller
          control={control}
          name="imagePreview"
          render={({ field }) => (
            <BBBImageUpload
              imageClassName="rounded object-cover"
              crop={{
                aspect: 1,
                customRatio: true,
              }}
              shape="block"
              width={'10rem'}
              height={'6rem'}
              containerClassName="my-5"
              buttonText="Upload Image"
              imageUrl={field.value as string}
              onChangeImage={field.onChange}
              errors={formState.errors.imagePreview?.message}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name="imagePreview"
          render={({ field }) => (
            <BBBFileUpload
              isSingle
              files={field.value as Nullable<FileType>}
              crop={{ customRatio: true }}
              onChangeFile={field.onChange}
              containerClassName="mb-4 grow"
            />
          )}
        />
      )}
      <BBBTextInput
        label="Link URL"
        placeholder="Enter your link URL"
        control={control}
        isHookForm
        controlName="link"
        containerClassname="my-5"
        error={formState.errors.link?.message}
      />
      <BBBTextInput
        label="Title (optional)"
        placeholder="Enter your title"
        control={control}
        isHookForm
        controlName="title"
        containerClassname="my-5"
        error={formState.errors.title?.message}
        hasMaxCharLabel
        maxChar={100}
      />
      <Controller
        control={control}
        name="display"
        render={({ field }) => (
          <div className="flex flex-col gap-2">
            <p className="text-neutral-70">Display</p>
            <BBBRadio
              options={[
                {
                  label: 'Image only',
                  description: 'Only show the image',
                  value: 'image-only',
                },
                {
                  label: 'With background',
                  description: 'Use button background for your image',
                  value: 'with-background',
                },
              ]}
              value={field.value}
              onChange={field.onChange}
            />
          </div>
        )}
      />
      <BBBFooterAction
        containerClassName="justify-end"
        withoutDelete={formLink?.id ? undefined : true}
        onCancel={() => {
          dispatch(setForm(null));
          reset();
        }}
        discardLabel="Cancel"
        onSave={() => handleSubmit(onSubmit)()}
        loadingSave={isLoading || isLoadingEdit}
        onDelete={handleDeleteForm}
      />
    </BBBCard>
  );
}
