import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import bitLinkSlice from './bitLink/index';
import authSlice from './auth';
import bitAppSlice from './bitApp';
import bitCRMSlice from './bitCRM';
import commonSlice from './common';

const combinedReducer = combineReducers({
  auth: authSlice,
  bitApp: bitAppSlice,
  bitLink: bitLinkSlice,
  bitCRM: bitCRMSlice,
  common: commonSlice,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state, action: AnyAction) => {
  return combinedReducer(state, action);
};

export const rootStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof rootStore.dispatch;
