import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { ArrowLeft, ArrowRight } from 'react-feather';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';

import { BBBSelect } from '@/components';

const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);
const years = range(1990, dayjs().year() + 100);
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export type BBBDatePickerProps = {
  selectedDay: ReactDatePickerProps['selected'];
  selectedEndDay?: ReactDatePickerProps['selected'];
  selectedTime?: TimePickerValue | null;
  onChangeDate: (
    date: Date | [Date | null, Date | null] | null | undefined
  ) => void;
  setSelectedTime?: React.Dispatch<React.SetStateAction<TimePickerValue>>;
  isRange?: boolean;
  showTimePicker?: boolean;
  disableBefore?: boolean;
  disableAfter?: boolean;
  disabled?: boolean;
  className?: string;
};

const mappedYears = years.map((p) => ({
  label: p,
  value: p,
}));

function BBBDatePicker({
  selectedDay,
  selectedTime = new Date(),
  setSelectedTime,
  selectedEndDay = null,
  isRange = false,
  showTimePicker = true,
  onChangeDate,
  disableBefore = true,
  disableAfter,
  disabled,
  className,
}: BBBDatePickerProps) {
  return (
    <DatePicker
      readOnly={disabled}
      calendarClassName={cx(disabled && 'pointer-events-none', className)}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
      }) => (
        <div
          className={cx(
            'flex items-center justify-between gap-5 mx-3',
            disabled && 'pointer-events-none'
          )}
        >
          <div className="flex items-center w-full">
            <ArrowLeft
              color="#404040"
              size={24}
              onClick={decreaseMonth}
              cursor="pointer"
            />

            <div className="grow">
              <BBBSelect
                options={months.map((value) => ({
                  label: value,
                  value: value,
                }))}
                optionLabel="label"
                optionValue="value"
                value={
                  months.map((value) => ({
                    label: value,
                    value: value,
                  }))[dayjs(date).month()]
                }
                onValueChange={(opt) =>
                  changeMonth?.(months.indexOf(opt?.value as string))
                }
                selectedDropdownClassName="py-3"
              />
            </div>
          </div>
          <div className="flex items-center w-full">
            <div className="grow">
              <BBBSelect
                options={mappedYears}
                optionLabel="label"
                optionValue="value"
                value={
                  mappedYears[
                    mappedYears.findIndex(
                      (value) => value.value === dayjs(date).year()
                    )
                  ]
                }
                onValueChange={(opt) => changeYear?.(opt?.value as number)}
                selectedDropdownClassName="py-3"
              />
            </div>

            <ArrowRight
              color="#404040"
              size={24}
              onClick={increaseMonth}
              cursor="pointer"
            />
          </div>
        </div>
      )}
      minDate={disableBefore ? new Date() : undefined}
      maxDate={disableAfter ? new Date() : undefined}
      selected={selectedDay}
      onChange={(dates) => {
        onChangeDate(dates);
      }}
      startDate={selectedDay}
      endDate={selectedEndDay}
      selectsRange={isRange}
      inline
    >
      {showTimePicker && selectedTime && (
        <div className="mt-2">
          <div className="body font-bold">Time</div>
          <TimePicker
            onChange={setSelectedTime}
            value={selectedTime}
            disableClock
          />
        </div>
      )}
    </DatePicker>
  );
}

export default BBBDatePicker;
