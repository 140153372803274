import { useLocation } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';

import { Link } from '@/components';

export type BBBTopNavItemProps = {
  className?: string;
  path: string;
  title: string;
};

const BBBTopNavItem = ({ className, path, title }: BBBTopNavItemProps) => {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={cx(
        'text-neutral-60',
        location.pathname.startsWith(path) && 'font-bold',
        className
      )}
    >
      {title}
    </Link>
  );
};

export default BBBTopNavItem;
