import { BarChart } from 'react-feather';

import { BBBPercentageBar } from '@/components/BBBPercentageBar';
import { MessageNewAssociation } from '@/types/whatsApp/v3';

type Props = {
  isQuotedLevel?: boolean;
  chat: MessageNewAssociation;
};

export default function ChatBubblePool({ isQuotedLevel, chat }: Props) {
  return (
    <>
      {isQuotedLevel ? (
        <div className="flex gap-2 items-center text-neutral-20">
          <BarChart size={16} /> {chat.pollMsg!.title}
        </div>
      ) : (
        <>
          <div className="font-medium mb-4">{chat.pollMsg!.title}</div>
          {chat.pollMsg!.options.map((option) => (
            <div
              key={option.name}
              className="flex gap-2 mb-4 last:mb-0 min-w-[256px]"
            >
              <div className="grow">
                <div className="grow flex">
                  <div className="grow">{option.name}</div>
                  <div>{option.count}</div>
                </div>
                <BBBPercentageBar
                  withoutInfo
                  usage={option.count}
                  limit={option.count}
                  activeColor="black"
                  defaultColor="#cbd5e1"
                />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
