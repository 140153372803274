import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useAddCategory } from 'hooks/bitApp/category';
import { useAppSelector } from 'hooks/rtk/store';
import { setCategory } from 'stores/bitApp';
import { BBBButton } from 'components';
import { destinationOptions } from '.';

function AddCategory() {
  const dispatch = useDispatch();
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);

  const { isLoading } = useAddCategory();

  if (activePreview !== 'category') {
    return null;
  }

  return createPortal(
    <div className="bg-neutral-10 shadow shadow-[0px -3px 6px 0px #9B9B9B26] py-5 px-9">
      <BBBButton
        onClick={() =>
          dispatch(
            setCategory({
              id: 0,
              title: '',
              active: true,
              collection: '',
              subCategories: [],
              destination: destinationOptions[0],
              customUrl: null,
              position: 0,
            })
          )
        }
        size="full"
        text="Add category"
        loadingState={isLoading}
      />
    </div>,
    document.getElementById('bitapp-footer-container') || document.body
  );
}

export default AddCategory;
