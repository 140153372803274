import { useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { PlanType, PricingFeatureNames } from 'api/services/pricing';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import { useActiveCompany } from 'hooks/rtk/selector';
import { AppType } from 'types/integrations';

export const usePricings = (
  {
    app,
    type,
  }: {
    app: AppType;
    type: PlanType;
  },
  options?: { enabled?: boolean }
) => {
  return useQuery(
    ['pricings-v2', { app, type }],
    () => services.pricing.getPricings({ app, type }),
    options
  );
};

export const usePricing = (
  { app }: { app: AppType },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricing-v2', { app, activeCompany }],
    () => services.pricing.getCompanyPricing({ id: activeCompany }, { app }),
    options
  );
};

export const useCompanyPricings = (options?: { enabled?: boolean }) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricings-v2-global', activeCompany],
    () => services.pricing.getCompanyPricings(activeCompany),
    options
  );
};

export const usePricingFeatures = (
  { app }: { app: AppType },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricing-features-v2', { app, activeCompany }],
    () => services.pricing.getPricingFeatures({ id: activeCompany }, { app }),
    options
  );
};

export const usePricingFeature = (
  {
    app,
    featureName,
  }: {
    app: AppType;
    featureName: PricingFeatureNames;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricing-feature-v2', { app, activeCompany, featureName }],
    () =>
      services.pricing.getPricingFeature(
        { id: activeCompany, featureName },
        { app }
      ),
    options
  );
};

export const mapQueryToPlanType: Record<string, PlanType> = {
  annual: 'ANNUAL',
  monthly: 'EVERY_30_DAYS',
};

export const mapPlanTypeToHumanReadable: Record<PlanType, string> = {
  ANNUAL: 'year',
  EVERY_30_DAYS: 'month',
};

export const usePlanTypeQueryParams = () => {
  const queryParam = useQuerySearchParams();

  const plan = queryParam.get('duration') || 'monthly';

  const planType = mapQueryToPlanType[plan];

  return planType;
};
