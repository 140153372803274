import { useMutation, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { useAppSelector } from 'hooks/rtk/store';
import ShopifyCollection from 'types/shopify/ShopifyCollections';
import { afterSubmit } from 'utils/bitApp';
import { toast } from 'utils/common/toast';

export const useAddCollection = (params?: { isDropEnd?: boolean }) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const { selected } = useAppSelector((state) => state.bitApp);
  const client = useQueryClient();
  return useMutation(
    async (
      data: Partial<
        Parameters<typeof services.bitApp.collections.saveCollection>[1] & {
          collections: ShopifyCollection | null;
        }
      >
    ) => {
      return services.bitApp.collections.saveCollection(activeCompany!, {
        ...data,
        active: true,
      });
    },
    {
      onSuccess: () => {
        if (!params?.isDropEnd) {
          afterSubmit();
          toast.success('Changes saved.');
        }
        client.invalidateQueries(['sections', activeCompany]);
        client.invalidateQueries(['section', selected]);
      },
    }
  );
};
