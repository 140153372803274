import { useState } from 'react';
import { env } from 'config/env';
import { useProduct, useProducts } from 'hooks/bitCRM/shopify';
import ShopifyProducts from 'types/shopify/ShopifyProduct';
import { BBBSelect } from 'components';

type Props = {
  className?: string;
  label: string | React.ReactNode;
  value: string | null | undefined;
  onChange: (val?: string | null | undefined) => void;
  error?: string;
};

function ShopifyProductListsOptions({
  className,
  label,
  value,
  onChange,
  error,
}: Props) {
  const [search, setSearch] = useState<string>();

  const productQuery = useProducts({
    search,
  });

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = productQuery;

  const parseProductId = value
    ? parseInt(value.replace('gid://shopify/Product/', ''), 10)
    : undefined || 0;

  const { data: productData } = useProduct(parseProductId, {
    enabled: !!value,
  });

  const products = data?.pages.flatMap((page) =>
    page.products.map((product) => ({
      id: product.id,
      title: <ProductOption product={product} />,
    }))
  );

  return (
    <BBBSelect
      containerClassName={className}
      label={label}
      options={products}
      optionLabel="title"
      optionValue="id"
      placeholder="Choose product"
      loading={isInitialLoading}
      search={search}
      isSearchable
      onChangeSearch={setSearch}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      //@ts-ignore
      value={
        productData
          ? {
              id: productData.id,
              title: <ProductOption product={productData} />,
            }
          : undefined
      }
      onValueChange={(val) => onChange(val?.id)}
      error={error}
    />
  );
}
function ProductOption({ product }: { product: ShopifyProducts }) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          product?.featuredImage?.url ||
          `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={product.id}
        loading="lazy"
      />
      <p className="text-sm text-primary-main">{product.title}</p>
    </div>
  );
}

export default ShopifyProductListsOptions;
