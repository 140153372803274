import { useState } from 'react';
import { Calendar } from 'react-feather';
import { useHistory, useLocation } from 'react-router';
import { mapParamToAppType } from 'constants/common/app';
import {
  ChannelIntegration,
  channelIntegrationsOptions,
} from 'constants/integrations';
import dayjs from 'dayjs';
import { useDefaultConnectedChannels } from 'hooks/whatsApp/channels';
import { selectedDate } from '../../types/datePicker';
import BitChatAnalytics from './BitChatAnalytics';
import BitCRMAnalytics from './BitCRMAnalytics';
import BitLinkAnalytics from './BitLinkAnalytics';
import BitLoginAnalytics from './BitLoginAnalytics';
import SelectRange from './SelectRange';

import AlertIcon2 from '@/assets/icons/AlertIcon2';
import {
  BBBCard,
  BBBContainer,
  BBBNavigatorTab,
  BBBSelect,
} from '@/components';
import UpgradeText from '@/components/BBBLimitAlert/UpgradeText';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { AppType } from '@/types/integrations';
import { sortAppOrder } from '@/utils/app';
import getRangeValue from '@/utils/common/date';

type Menu = {
  id: number;
  name: string;
  key: AppType;
};

const menus: Menu[] = [
  {
    id: 2,
    name: 'bitLink',
    key: 'BITLINK',
  },
  {
    id: 3,
    name: 'bitCRM',
    key: 'BITCRM',
  },
  {
    id: 5,
    name: 'bitChat',
    key: 'BITCHAT',
  },
  {
    id: 4,
    name: 'bitLogin',
    key: 'BITLOGIN',
  },
].sort((a, b) => sortAppOrder(a.name, b.name)) as Menu[];

export default function BBBAnalytics() {
  const history = useHistory();
  const query = useQuerySearchParams();
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const tab = query.get('tab') as AppType;

  //@ts-ignore
  const _active = tab ? mapParamToAppType[tab] : menus[0].key;

  const active = tab
    ? menus.find((nav) => nav.key === _active) ?? menus[0]
    : menus[0];

  const [selectedDay, setSelectedDay] = useState<selectedDate>(
    dayjs().startOf('day').subtract(7, 'day').toDate()
  );
  const [selectedEndDay, setSelectedEndDay] = useState<selectedDate>(
    dayjs().endOf('day').subtract(1, 'day').toDate()
  );

  const [activeRange, setActiveRange] = useState(false);

  const rangeValue = getRangeValue(selectedDay, selectedEndDay);

  const toggleShowRange = () => {
    setActiveRange((prev) => !prev);
  };

  const { data: pricingData, isLoading: loadingPricing } = usePricingByApp(
    active.key
  );

  const props = {
    startDate: dayjs(selectedDay).toISOString(),
    endDate: dayjs(selectedEndDay).toISOString(),
    isPreview: pricingData?.pricingName === 'free',
    loading: loadingPricing,
  };

  return (
    <BBBContainer
      hasHeading
      pageTitle="Analytics"
      rightComponent={
        <div className="relative cursor-pointer">
          <BBBCard onClick={toggleShowRange} className="!px-4 !py-[10px]">
            <div className="flex gap-3">
              <Calendar width={24} height={24} />
              <div className="font-bold text-primary-main">
                {rangeValue?.label}
              </div>
            </div>
          </BBBCard>
          {activeRange && (
            <SelectRange
              selectedDay={selectedDay}
              selectedEndDay={selectedEndDay}
              setSelectedDay={setSelectedDay}
              setSelectedEndDay={setSelectedEndDay}
              active={activeRange}
              onChangeActive={setActiveRange}
            />
          )}
        </div>
      }
    >
      <BBBNavigatorTab
        optionLabel="name"
        optionValue="key"
        navigationLists={menus}
        value={active}
        onChange={(val) => {
          searchParams.set('tab', val.key.toLowerCase());
          history.push(`${pathname}?${searchParams.toString()}`);
        }}
        className="mb-8"
        rightComponent={<>{active.key === 'BITCHAT' && <ChannelOptions />}</>}
      />
      {pricingData?.pricingName === 'free' && (
        <BBBCard className="mb-4 bg-secondary-surface border-secondary-border flex items-center gap-4">
          <AlertIcon2 size={30} />
          <div className="grow">
            <div className="font-medium mb-2">Sample data</div>
            <div className="text-sm">
              You’re bitbybit’s sample data on analytics page. To see analytics
              with your data,{' '}
              <UpgradeText label="upgrade now." appType={active.key} />
            </div>
          </div>
        </BBBCard>
      )}
      {active.key === 'BITLINK' ? (
        <BitLinkAnalytics {...props} />
      ) : active.key === 'BITCRM' ? (
        <BitCRMAnalytics {...props} />
      ) : active.key === 'BITLOGIN' ? (
        <BitLoginAnalytics {...props} />
      ) : active.key === 'BITCHAT' ? (
        <BitChatAnalytics {...props} />
      ) : null}
    </BBBContainer>
  );
}

export function ChannelOptions({
  channel: channelFromProps,
  onChangeChannel,
  defaultPlaceholder,
  label,
  className,
  placeholder,
}: {
  channel?: ChannelIntegration | null;
  onChangeChannel?: (val: ChannelIntegration | null) => void;
  defaultPlaceholder?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
}) {
  const querySearchParams = useQuerySearchParams();

  const { data: connectedChannels } = useDefaultConnectedChannels();

  const channelQuery = querySearchParams.get('channel') || connectedChannels;

  const channel =
    typeof channelFromProps !== 'undefined'
      ? channelFromProps
      : channelIntegrationsOptions.find((chan) => chan.value === channelQuery);

  const history = useHistory();
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);

  return (
    <BBBSelect
      //@ts-ignore
      options={channelIntegrationsOptions}
      optionLabel="label"
      optionValue="value"
      selectedLabel="label2"
      showChevronOnHover={!defaultPlaceholder}
      dropdownOptionsWrapperClassName="w-64"
      dropdownOptionsX="right"
      value={channel}
      label={label}
      containerClassName={className}
      onValueChange={(opt) => {
        if (typeof onChangeChannel !== 'undefined') {
          onChangeChannel(opt!);
        } else {
          searchParams.set('channel', opt!.value);
          history.push(`${pathname}?${searchParams.toString()}`);
        }
      }}
      placeholder={placeholder}
    />
  );
}
