import { useMemo } from 'react';
import Progress from '../components/Progress';
import StatusBadge from '../components/StatusBadge';

import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/Link';
import { mapSourceToRoutes } from '@/constants/whatsApp';
import { Campaign } from '@/types/bitCRM/campaign';
import { formatDate2 } from '@/utils/common/date';

const useColumnTablet = () => {
  return useMemo<HeadersTypes<Campaign>>(() => {
    return [
      {
        accesor: 'campaignName',
        isSortable: true,
        renderHeader: () => (
          <div className="flex gap-4 items-center justify-center">Campaign</div>
        ),
        render: (info) => {
          return (
            <div className="flex gap-4 items-center">
              <div className="flex flex-col gap-[10px]">
                {info.status === 'SCHEDULED' || info.status === 'DRAFT' ? (
                  <Link
                    className="text-primary-main group-hover:underline"
                    to={{
                      pathname: `/bitcrm/campaign/${
                        mapSourceToRoutes[info.type]
                      }/${info.id}`,
                    }}
                  >
                    {info.campaignName}
                  </Link>
                ) : info.status === 'SENT' ? (
                  <Link
                    className="text-primary-main group-hover:underline"
                    to={{
                      pathname: `/bitcrm/campaign/${
                        mapSourceToRoutes[info.type]
                      }/${info.id}/log`,
                      state: { data: info },
                    }}
                  >
                    {info.campaignName}
                  </Link>
                ) : (
                  <p className="text-primary-main">{info.campaignName}</p>
                )}
                <Progress {...info} />
                <div className="text-[#757575] text-sm mt-1">
                  Last updated: {formatDate2(info.updatedAt)}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        accesor: 'status',
        renderHeader: () => (
          <div className="flex gap-4 items-center justify-center">Status</div>
        ),
        isSortable: true,
        render: (info) => <StatusBadge status={info.status} />,
        columnWidth: 120,
      },
    ];
  }, []);
};

export default useColumnTablet;
