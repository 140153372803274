import BBBLimitAlert from '@/components/BBBLimitAlert/BBBLimitAlert';
import InfoList from '@/components/InfoList/InfoList';
import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';

type Props = {
  tags: string[] | undefined;
  withGap?: boolean;
};

function AITagging({ tags, withGap }: Props) {
  const { data, limit } = useWithCRMPricing('BITCHAT', 'ai_tagging');
  const { data: pricingData } = usePricingByApp('BITCHAT');

  const sortedTags = tags?.sort((a, b) => {
    if (a.includes(':') && !b.includes(':')) return -1;
    if (!a.includes(':') && b.includes(':')) return 1;
    return 0;
  });

  return (
    <InfoList
      label="AI tagging"
      withGap={withGap}
      isChildrenNewLine={!!sortedTags?.length}
    >
      {data && pricingData && (
        <BBBLimitAlert
          usage={data.usage}
          appType="BITCHAT"
          module={data.pricingFeature.label}
          limit={limit}
          currentPlan={{
            label: pricingData.pricingModule.label,
            name: pricingData.pricingName,
          }}
          withoutTip
          className="mb-4 rounded-lg"
        />
      )}
      {!sortedTags?.length ? (
        '-'
      ) : (
        <TruncatedTags items={sortedTags} withoutDelete maxLines={2} />
      )}
    </InfoList>
  );
}

export default AITagging;
