import { useHistory } from 'react-router';

import { PricingPlan } from '@/api/services/pricing';
import { BBBModal } from '@/components/BBBModal';
import useRedirectUpgradePlan from '@/hooks/pricing/useRedirectUpgradePlan';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { CommonStore, setPricingAlert } from '@/stores/common';
import { AppType } from '@/types/systemLogin';

const mapPricingPlanLabel = (app: AppType): Record<PricingPlan, string> => ({
  free: 'Free',
  freeWithUsageCharge: 'Free with usage charge',
  standard: app === 'BITCHAT' ? 'Basic' : 'Standard',
  pro: app === 'BITCHAT' ? 'Standard' : 'Pro',
  pro1: 'Pro',
  enterprise: 'Enterprise',
});

function PricingModalAlert() {
  const props = useAppSelector((state) => state.common.pricingAlert);

  if (!props) return null;

  return <_PricingModalAlert {...props} />;
}

export default PricingModalAlert;

function _PricingModalAlert({
  appType,
  type = 'redirect',
  onSuccess,
  desiredPlan,
  show,
}: NonNullable<CommonStore['pricingAlert']>) {
  const dispatch = useAppDispatch();
  const { data } = useShopifyIntegrationByApp(appType);

  const destroy = () => {
    dispatch(setPricingAlert(null));
  };

  const hide = () => {
    dispatch(setPricingAlert({ show: false }));
  };

  const { fn: redirectUpgradeUrl } = useRedirectUpgradePlan({
    onSuccess: () => {
      onSuccess?.();
      destroy();
    },
    appType,
  });

  const history = useHistory();

  if (!show) return null;

  return (
    <BBBModal
      show
      title="Upgrade plan to use this feature"
      centerTitle
      centerBody
      footer
      centerFooter
      submitText="Upgrade now"
      handleSave={() => {
        if (type === 'prompt') {
          if (!data?.domain) {
            throw new Error('Type prompt requires shopify integration');
          }
          redirectUpgradeUrl(desiredPlan, data.domain);
          hide();
        } else {
          history.push(`/pricing?tab=${appType.toLowerCase()}`);
          destroy();
        }
      }}
      onHide={destroy}
    >
      This feature is only available on{' '}
      {mapPricingPlanLabel(appType)[desiredPlan]} plan. Don&apos;t let limited
      features restrict your growth. Upgrade today and take your business to the
      next level.
    </BBBModal>
  );
}
