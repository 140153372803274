import React, { useCallback } from 'react';
import { FaApple, FaFacebook } from 'react-icons/fa';
import { GrGoogle } from 'react-icons/gr';
import { twMerge as cx } from 'tailwind-merge';
import Wrapper from '../components/Wrapper';
import UpsellCard from '../UpsellCard';

import {
  BBBButton,
  BBBCard,
  BBBCheckbox,
  BBBColorPicker,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import {
  buttonColors,
  buttonFormat,
  buttonPositions,
  buttonStyles,
  iconPreviewApperance,
  sizes,
} from '@/constants/bitLogin/appearance';
import useSettings from '@/hooks/bitLogin/useSettings';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import useUpsertSocialAppearance from '@/hooks/bitLogin/useUpsertSocialAppearance';
import useResponsive from '@/hooks/common/useResponsive';

function Appearance() {
  const { status: statusEnabled, data } = useSettings();

  const isDisabled = statusEnabled !== 'success' || !data?.verified;

  return (
    <Wrapper
      hasHeading
      pageTitle="Appearance"
      pageDescription="Adjust your social login appearance"
      module="appearance"
    >
      <div className={cx(isDisabled && 'opacity-50 pointer-events-none')}>
        <_Appearance />
      </div>
    </Wrapper>
  );
}

function _Appearance() {
  const isMobile = useResponsive('sm');
  const { data: socialAppearance } = useSocialAppearance();

  const { mutate: updateAppearance } = useUpsertSocialAppearance();

  const isActiveButtonColor = useCallback(
    (type: string) => socialAppearance?.colorScheme === type,
    [socialAppearance?.colorScheme]
  );

  const isActiveButtonStyle = useCallback(
    (radius: string) => (socialAppearance?.style === radius ? 'active' : ''),
    [socialAppearance?.style]
  );

  const isActiveButtonPosition = useCallback(
    (position: string) =>
      socialAppearance?.position === position ? 'active' : '',
    [socialAppearance?.position]
  );

  return (
    <>
      <BBBCard
        title="Button style"
        headerClassName="font-medium"
        className="mb-cardBottom"
      >
        <div className="flex lg:flex-row flex-col lg:gap-10 gap-2.5">
          <p className="text-primary-main w-10">Shape</p>
          <div className="flex flex-grow gap-5 justify-center items-center">
            <div className="w-full lg:flex grid grid-cols-2 lg:gap-8 gap-x-2.5 gap-y-4 items-center mb-6 border border-neutral-30 rounded-lg px-6 py-5">
              {buttonStyles.map((buttonStyle) => (
                <ShopifyAwareWrapper
                  key={buttonStyle.id}
                  appType="BITLOGIN"
                  fallbackToChildren
                  as={React.Fragment}
                >
                  {({ connectMiddleware }) => (
                    <div
                      className={cx(
                        'py-3 flex-1 flex items-center justify-center bg-[#d9d9d9] shadow hover:outline outline-1 hover:outline-offset-4 hover:cursor-pointer',
                        isActiveButtonStyle(buttonStyle.name) &&
                          'outline outline-offset-4 cursor-pointer',
                        buttonStyle.name === 'rounded'
                          ? 'rounded'
                          : buttonStyle.name === 'fullRounded'
                          ? 'rounded-full'
                          : ''
                      )}
                      onClick={() =>
                        connectMiddleware(() =>
                          updateAppearance({ style: buttonStyle.name })
                        )
                      }
                    >
                      {buttonStyle.label}
                    </div>
                  )}
                </ShopifyAwareWrapper>
              ))}

              <div className="flex items-center justify-center lg:gap-4 gap-0">
                <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
                  {({ connectMiddleware }) => (
                    <BBBCheckbox
                      checked={!!socialAppearance?.hasShadow}
                      label="Shadow"
                      onValueChange={(checked) =>
                        connectMiddleware(() =>
                          updateAppearance({ hasShadow: checked })
                        )
                      }
                    />
                  )}
                </ShopifyAwareWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col lg:gap-10 gap-2.5">
          <p className="text-primary-main w-10">Format</p>
          <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-4 mb-6 w-full px-6 py-5 border border-neutral-30 rounded-lg place-content-center">
            {buttonFormat.map((format) => (
              <ShopifyAwareWrapper
                appType="BITLOGIN"
                fallbackToChildren
                key={format}
                as={React.Fragment}
              >
                {({ connectMiddleware }) => (
                  <div
                    className={cx(
                      'rounded-lg',
                      format !== 'wrapperFlexContainerOnlyLogo'
                        ? 'hover:outline outline-1 hover:outline-offset-4 hover:cursor-pointer'
                        : '',
                      format === 'contentFlex' ||
                        format === 'contentFlexReverse' ||
                        format === 'contentFlexCenter' ||
                        format === 'contentFlexCenterReverse'
                        ? cx(
                            'flex items-center bg-[#D9D9D9] rounded-lg px-4.5 py-3',
                            format === 'contentFlexReverse' ||
                              format === 'contentFlexCenterReverse'
                              ? 'flex-row-reverse'
                              : '',
                            format === 'contentFlexCenter' ||
                              format === 'contentFlexCenterReverse'
                              ? 'justify-center gap-2'
                              : ''
                          )
                        : 'flex gap-3',
                      format !== 'wrapperFlexContainerOnlyLogo' &&
                        (socialAppearance?.format === format
                          ? 'outline outline-offset-4'
                          : 'hover:scale-105 transition-transform cursor-pointer'),
                      format === 'wrapperFlexContainerOnlyLogo' &&
                        'justify-center col-span-2'
                    )}
                    onClick={() =>
                      connectMiddleware(() => updateAppearance({ format }))
                    }
                  >
                    {format === 'wrapperFlexContainerOnlyLogo' ? (
                      <div
                        className={cx(
                          'rounded-lg flex lg:w-2/5 w-fit justify-center gap-3 hover:outline outline-1 hover:outline-offset-4 hover:cursor-pointer',
                          socialAppearance?.format === format
                            ? 'outline outline-offset-4'
                            : 'hover:scale-105 transition-transform cursor-pointer'
                        )}
                      >
                        {iconPreviewApperance.map(({ icon }, idx) => (
                          <div
                            key={idx}
                            className="bg-[#D9D9D9] rounded-lg p-4 flex grow justify-center"
                          >
                            {icon}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        {iconPreviewApperance[0].icon}
                        <div
                          className={cx(
                            format === 'contentFlex' ||
                              format === 'contentFlexReverse'
                              ? 'grow text-center'
                              : ''
                          )}
                        >
                          Button text
                        </div>
                      </>
                    )}
                  </div>
                )}
              </ShopifyAwareWrapper>
            ))}
          </div>
        </div>

        <div className="flex lg:flex-row flex-col lg:gap-10 gap-2.5">
          <p className="text-primary-main w-10">Size</p>
          <div className="flex lg:flex-row flex-col w-full border border-neutral-30 rounded-lg gap-6 items-center px-6 py-5">
            {sizes.map((size) => (
              <ShopifyAwareWrapper
                appType="BITLOGIN"
                fallbackToChildren
                key={size.name}
                as={React.Fragment}
              >
                {({ connectMiddleware }) => (
                  <div
                    className={cx(
                      'w-full flex hover:outline outline-1 hover:outline-offset-4 hover:cursor-pointer items-center bg-[#D9D9D9] rounded-lg px-4.5 flex-1',
                      size.name === 'small'
                        ? 'py-2'
                        : size.name === 'medium'
                        ? 'py-3'
                        : 'py-4',
                      socialAppearance?.size === size.name
                        ? 'outline outline-offset-4'
                        : 'hover:scale-105 transition-transform cursor-pointer'
                    )}
                    onClick={() =>
                      connectMiddleware(() =>
                        updateAppearance({ size: size.name })
                      )
                    }
                  >
                    <GrGoogle />
                    <div className="grow flex justify-center">
                      <div className="text-center">{size.label}</div>
                    </div>
                  </div>
                )}
              </ShopifyAwareWrapper>
            ))}
          </div>
        </div>
      </BBBCard>
      <BBBCard
        title="Button color"
        className="mb-cardBottom"
        headerClassName="font-medium"
      >
        <div className="grid md:grid-cols-4 lg:gap-8 gap-4 mb-6 border border-neutral-30 p-5 rounded-lg">
          {buttonColors.map((button) => (
            <ShopifyAwareWrapper
              appType="BITLOGIN"
              fallbackToChildren
              key={button.id}
              as={React.Fragment}
            >
              {({ connectMiddleware }) => (
                <div
                  className={cx(
                    `flex-1 p-[10px] border flex text-center justify-center hover:outline outline-1 hover:outline-primary-main hover:outline-offset-4 items-center rounded-xl shadow outline outline-transparent hover:cursor-pointer hover:scale-105 transition-transform cursor-pointer`,
                    isActiveButtonColor(button.name) &&
                      'outline-offset-4 outline-primary-main pointer-events-none',
                    button.name === 'matchOutline'
                      ? 'border-[#1877f2]'
                      : button.name === 'white'
                      ? 'border-primary-main'
                      : 'border-transparent',
                    button.name === 'custom' &&
                      'border-2 border-neutral-40 border-dashed'
                  )}
                  style={{
                    backgroundColor:
                      button.name === 'custom'
                        ? socialAppearance?.backgroundColor
                        : button.name === 'match'
                        ? '#1877f2'
                        : button.name === 'matchOutline'
                        ? 'transparent'
                        : button.name === 'black'
                        ? '#262627'
                        : button.name === 'white'
                        ? 'transparent'
                        : '',
                    color:
                      button.name === 'custom'
                        ? socialAppearance?.textColor
                        : button.name === 'match' || button.name === 'black'
                        ? 'white'
                        : '',
                  }}
                  onClick={() =>
                    connectMiddleware(() =>
                      updateAppearance({
                        colorScheme: button.name,
                      })
                    )
                  }
                >
                  {button.label}
                </div>
              )}
            </ShopifyAwareWrapper>
          ))}
          {socialAppearance?.colorScheme === 'custom' && (
            <div className="flex md:flex-row flex-col gap-6">
              <BBBColorPicker
                label="Button color"
                color={socialAppearance.backgroundColor}
                onChangeColor={(color) =>
                  updateAppearance({ backgroundColor: color })
                }
                width={42}
                height={42}
              />
              <BBBColorPicker
                label="Button outline"
                color={socialAppearance.outlineColor}
                onChangeColor={(color) =>
                  updateAppearance({ outlineColor: color })
                }
                className="!border-2 !border-primar-main !border-dashed"
                width={42}
                height={42}
              />
              <BBBColorPicker
                label="Button text color"
                color={socialAppearance.textColor}
                onChangeColor={(color) =>
                  updateAppearance({ textColor: color })
                }
                width={42}
                height={42}
              />
            </div>
          )}
        </div>
      </BBBCard>
      <UpsellCard />
      <BBBCard
        title="Button text"
        className="mb-cardBottom"
        headerClassName="font-medium"
      >
        <div className="flex lg:flex-row lg:items-center flex-col w-full gap-5">
          <BBBTextInput
            containerClassname="grow w-full m-0"
            hasMaxCharLabel
            maxChar={18}
            isFixedSuffix={isMobile ? false : true}
            fixedSuffixLabel="Google/Facebook/Apple/WhatsApp"
            value={socialAppearance?.buttonText || ''}
            onChange={({ target: { value } }) =>
              updateAppearance({
                buttonText: value,
              })
            }
          />
          <BBBButton
            variant="secondary"
            className="w-fit"
            text="Reset text"
            size={isMobile ? 'sm' : 'md'}
            onClick={() => updateAppearance({ buttonText: 'Continue with' })}
          />
        </div>
      </BBBCard>
      <BBBCard
        title="Divider"
        desc="Add divider between social and regular login for smoother experience."
        className="mb-cardBottom"
        headerClassName="font-medium"
        rightButton={
          <BBBPrimarySwitch
            checked={!socialAppearance?.hideDivider}
            onChange={(val) =>
              updateAppearance({
                hideDivider: !val,
              })
            }
          />
        }
      >
        <div
          className={
            socialAppearance?.hideDivider
              ? 'opacity-50 pointer-events-none'
              : ''
          }
        >
          <div className="flex flex-wrap justify-evenly lg:gap-24 gap-8 py-6 mb-5">
            {buttonPositions.map((position) => (
              <ShopifyAwareWrapper
                appType="BITLOGIN"
                fallbackToChildren
                key={position.id}
                as={React.Fragment}
              >
                {({ connectMiddleware }) => (
                  <div
                    className={cx(
                      `w-[241px] h-full p-4 hover:outline hover:outline-offset-4 outline-1 outline-primary hover:cursor-pointer flex gap-4 flex-col bg-[#dddddd] rounded-lg`,
                      isActiveButtonPosition(position.name) &&
                        'outline outline-offset-4 cursor-pointer'
                    )}
                    onClick={() =>
                      connectMiddleware(() =>
                        updateAppearance({
                          position: position.name,
                        })
                      )
                    }
                  >
                    {position.name === 'top' ? (
                      <div className="w-full bg-white rounded-lg py-2 flex gap-2 justify-center">
                        <GrGoogle width={12} height={12} />
                        <div className="text-xs">Login with Google</div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2">
                        <div className="w-full bg-white rounded-lg py-2 px-3 flex gap-2">
                          <div className="text-primary-main text-xs">Email</div>
                        </div>
                        <div className="w-full bg-white rounded-lg py-2 px-3 flex gap-2">
                          <div className="text-primary-main text-xs">
                            Password
                          </div>
                        </div>
                      </div>
                    )}
                    <p className="mx-auto text-xs">Or</p>
                    {position.name === 'bottom' ? (
                      <div className="w-full bg-white rounded-lg py-2 flex gap-2 justify-center">
                        <GrGoogle width={12} height={12} />
                        <div className="text-xs">Login with Google</div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2">
                        <div className="w-full bg-white rounded-lg py-2 px-3 flex gap-2">
                          <div className="text-primary-main text-xs">Email</div>
                        </div>
                        <div className="w-full bg-white rounded-lg py-2 px-3 flex gap-2">
                          <div className="text-primary-main text-xs">
                            Password
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </ShopifyAwareWrapper>
            ))}
          </div>
          <div className="mt-5">
            <BBBPrimarySwitch
              checked={socialAppearance?.dividerText !== 'OR'}
              onChange={() => {
                updateAppearance({
                  dividerText:
                    socialAppearance?.dividerText === 'OR' ? '' : 'OR',
                });
              }}
              label="Custom divider text"
              description="Customize your divider text, fit your customers type"
            />
          </div>
          {socialAppearance?.dividerText !== 'OR' && (
            <div className="mt-5">
              <BBBTextInput
                label="Divider text"
                placeholder="Enter divider text"
                hasMaxCharLabel
                maxChar={10}
                value={socialAppearance?.dividerText ?? ''}
                onChange={({ target: { value } }) =>
                  updateAppearance({
                    dividerText: value,
                  })
                }
              />
            </div>
          )}
        </div>
      </BBBCard>
    </>
  );
}

export default Appearance;
