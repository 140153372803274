import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import useHistory from '@/hooks/common/useHistory';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { hideConfirmationModal } from '@/stores/common';
import { toast } from '@/utils/common/toast';

const useCancelInvite = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const dispatch = useAppDispatch();
  const history = useHistory();
  return useMutation(
    async (payload: { userId: number }) => {
      const data = await api.systemLogin.post(
        `/invitation/user/${payload.userId}/company/${activeCompany}/cancel`
      );
      return data;
    },
    {
      onSuccess: () => {
        dispatch(hideConfirmationModal());
        toast.success(`Invitation has been canceled`);
        history.push(`/settings`);
      },
    }
  );
};

export default useCancelInvite;
