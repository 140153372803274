import { Fragment, useRef, useState } from 'react';
import { ChevronRight, Plus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { AnimatePresence } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import { BitLinkIconDefault } from '@/assets/icons/BitLinkIcon';
import { BBBBottomSheet, BBBButton, BBBButtonTypes } from '@/components';
import BBBCard, { BBBCardWithMotion } from '@/components/BBBCard/BBBCard';
import StickyFooter from '@/components/StickyFooter/StickyFooter';
import sectionOptions from '@/constants/bitLink/links/sectionOptions';
import useLinklistQuery from '@/hooks/bitLink/links/useLinklistQuery';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setForm } from '@/stores/bitLink';

export default function LinkCreate({
  isNewUsers = false,
}: {
  isNewUsers?: boolean;
}) {
  const refOptions = useRef<HTMLDivElement>(null);
  const isMobile = useResponsive('sm');
  const dispatch = useAppDispatch();

  useOutsideAlerterv2(refOptions, () => {
    setActive(false);
  });

  const [active, setActive] = useState(false);

  const { isInitialLoading: loadingSection } = useLinklistQuery();

  return (
    <div className="relative">
      {loadingSection ? (
        <Skeleton width={100} height={20} />
      ) : isMobile ? (
        <StickyFooter>
          <div className="p-3 bg-white">
            <ExploreSectionButton
              onClick={() => setActive(true)}
              className="w-full"
            />
          </div>
        </StickyFooter>
      ) : (
        <div className="flex gap-3">
          <ExploreSectionButton onClick={() => setActive(true)} />
          <AddLinkButton />
        </div>
      )}

      {isMobile ? (
        <BBBBottomSheet
          show={active}
          title="Create new section"
          onClose={() => setActive(false)}
        >
          {sectionOptions.map((section) => (
            <CreateNewSectionOption
              name={section.name}
              label={section.label}
              id={section.id}
              icon={section.icon}
              key={section.id}
              onClick={() => setActive(false)}
              isDisabled={isNewUsers}
            />
          ))}
        </BBBBottomSheet>
      ) : (
        <ExploreSection
          isNewUsers={isNewUsers}
          active={active}
          setActive={setActive}
          sectionOptions={sectionOptions}
          onClickSection={(section) => {
            dispatch(setForm({ type: section.name }));
            setActive?.(false);
          }}
        />
      )}
    </div>
  );
}

function AddLinkButton(props: BBBButtonTypes) {
  const dispatch = useAppDispatch();

  return (
    <BBBButton
      id="add-link-button"
      text={
        <div className="flex justify-center items-center">
          <div>Add Link</div>
          <div className="ml-2.5">
            <BitLinkIconDefault size={15} color="#FFFFFF" />
          </div>
        </div>
      }
      onClick={() => dispatch(setForm({ type: 'link' }))}
      {...props}
    />
  );
}

type ExploreSectionParams = {
  label: string;
  id: string;
  name: string;
  description?: string;
  icon?: ((props: { size?: number }) => JSX.Element) | JSX.Element;
  parent?: string;
};

export function ExploreSection<T extends ExploreSectionParams>({
  active,
  isNewUsers,
  setActive,
  sectionOptions,
  onClickSection,
}: {
  active: boolean;
  isNewUsers?: boolean;
  setActive?: (active: boolean) => void;
  sectionOptions: T[];
  onClickSection?: (section: T) => void;
}) {
  const refOptions = useRef<HTMLDivElement>(null);

  const [activeSection, setActiveSection] = useState<T>();

  useOutsideAlerterv2(
    refOptions,
    (ref) => {
      if (
        ref.closest('#explore-sections-child')?.id !== 'explore-sections-child'
      ) {
        setActive?.(false);
        setActiveSection(undefined);
      }
    },
    true
  );

  const childs = activeSection
    ? sectionOptions.filter((s) => s.parent === activeSection.name)
    : undefined;

  const parents = sectionOptions.filter((section) => !section.parent);

  const parentActive = activeSection?.name
    ? document.getElementById(`explore-section-${activeSection.name}`)
    : undefined;

  return (
    <>
      <>
        {active && (
          <BBBCard
            id="explore-sections"
            className={cx(
              'w-96 overflow-auto absolute  top-[calc(100%+1rem)] z-50 right-0',
              isNewUsers && 'z-[150]'
            )}
            ref={refOptions}
          >
            {parents.map((section, idx) => {
              return (
                <Fragment key={section.id}>
                  <ExploreSectionOption
                    label={section.label}
                    name={section.name}
                    icon={section.icon}
                    isDisabled={isNewUsers}
                    description={section.description}
                    onClick={() => {
                      const _childs = sectionOptions.filter(
                        (s) => s.parent === section.name
                      );

                      if (!_childs?.length) {
                        onClickSection?.(section);
                      }
                    }}
                    onMouseEnter={() => {
                      const _childs = sectionOptions.filter(
                        (s) => s.parent === section.name
                      );

                      if (_childs?.length) {
                        setActiveSection(section);
                      }
                    }}
                    active={activeSection?.name === section.name}
                    last={idx === parents.length - 1}
                    hasChild={
                      !!sectionOptions.filter((s) => s.parent === section.name)
                        .length
                    }
                  />
                </Fragment>
              );
            })}
          </BBBCard>
        )}
      </>
      <AnimatePresence>
        {!!childs?.length && (
          <BBBCardWithMotion
            className={cx('w-96 z-30 overflow-auto absolute')}
            initial={{ opacity: 0, pointerEvents: 'auto' }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0, pointerEvents: 'none' }}
            transition={{ type: 'tween' }}
            id="explore-sections-child"
            style={{
              top: parentActive
                ? parentActive.getBoundingClientRect().top -
                  parentActive.getBoundingClientRect().height
                : undefined,
              ...(refOptions.current
                ? {
                    right:
                      (refOptions.current.getBoundingClientRect().width +
                        refOptions.current.getBoundingClientRect().right >=
                      window.innerWidth
                        ? 1
                        : -1) *
                      (refOptions.current.getBoundingClientRect().width + 8),
                  }
                : {}),
            }}
          >
            {childs.map((section, idx) => {
              return (
                <Fragment key={section.id}>
                  <ExploreSectionOption
                    label={section.label}
                    name={section.name}
                    icon={section.icon}
                    isDisabled={isNewUsers}
                    description={section.description}
                    onClick={() => {
                      onClickSection?.(section);
                      setActiveSection(undefined);
                    }}
                    last={childs.length - 1 === idx}
                  />
                </Fragment>
              );
            })}
          </BBBCardWithMotion>
        )}
      </AnimatePresence>
    </>
  );
}

function ExploreSectionButton(props: BBBButtonTypes) {
  return <BBBButton variant="secondary" text="Add Section" {...props} />;
}

function ExploreSectionOption({
  label,
  description,
  icon,
  onClick,
  onMouseEnter,
  isDisabled,
  active,
  last,
  name,
  hasChild,
}: {
  label: string;
  name: string;
  description?: string;
  icon?: ((props: { size?: number }) => JSX.Element) | JSX.Element;
  onClick?: () => void;
  onMouseEnter?: () => void;
  isDisabled?: boolean;
  active?: boolean;
  last?: boolean;
  hasChild?: boolean;
}) {
  const IconJSX = icon;

  return (
    <>
      <div
        className={cx(
          'flex justify-evenly items-center gap-4 px-2.5 py-3.5 mb-2 hover:bg-neutral-30 transition-colors rounded-lg cursor-pointer',
          isDisabled && 'pointer-events-none',
          active && 'bg-neutral-30'
        )}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        id={`explore-section-${name}`}
      >
        {typeof icon === 'object' ? (
          icon
        ) : (
          //@ts-ignore
          <IconJSX />
        )}
        <div className="flex flex-col flex-1 gap-1">
          <p className="text-sm text-primary-main">{label}</p>
          {description && (
            <p className="text-xs text-neutral-40">{description}</p>
          )}
        </div>
        {hasChild ? (
          <ChevronRight size={16} color="#9E9E9E" />
        ) : (
          <Plus size={16} color="#9E9E9E" />
        )}
      </div>
      {!last && <div className=" border-t"></div>}
    </>
  );
}

function CreateNewSectionOption({
  name,
  label,
  id,
  icon,
  onClick,
  isDisabled = false,
}: {
  name: string;
  label: string;
  id: string;
  icon: JSX.Element;
  onClick?: () => void;
  isDisabled?: boolean;
}) {
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('sm');

  return (
    <div
      className={cx(
        isMobile
          ? 'flex gap-2.5 py-3 mb-2.5'
          : 'flex flex-none w-24 flex-col justify-center items-center overflow-hidden text-ellipsis py-[1em] px-[2em] whitespace-nowrap',
        isDisabled ? 'cursor-default' : 'cursor-pointer hover:text-blue-500'
      )}
      onClick={() => {
        if (!isDisabled) {
          dispatch(setForm({ type: name }));
          return onClick?.();
        }
        return;
      }}
      key={id}
    >
      {icon}
      <span className={cx('text-center', !isMobile && 'mt-2')}>{label}</span>
    </div>
  );
}
