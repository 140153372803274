import { useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';
import { formatPricingRedirection } from './SidebarSubNav';

import { Link } from '@/components/Link';
import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';
import { useAppSelector } from '@/hooks/rtk/store';

interface ISidebarSectionNav {
  data: sidebarDataTypeSectionV2;
  index?: number;
  onClick?: () => void;
  isWrapperHovering: boolean;
}

export default function SidebarSectionNav({
  data,
  index = 0,
  onClick,
  isWrapperHovering,
}: ISidebarSectionNav) {
  const expanded = useAppSelector((state) => state.common.sidebar.expanded);
  const { pathname, search } = useLocation();
  const { enableMapping } = useAppSelector(
    (state) => state.common.sidebar,
    shallowEqual
  );

  const isActive = useMemo(
    () =>
      enableMapping
        ? false
        : data.path.split('/')[index + 1] === pathname.split('/')[index + 1],
    [data.path, enableMapping, index, pathname]
  );

  const Icon = data.icon!;

  const [isHovering, setIsHovering] = useState(false);

  if (data.disabled) return null;

  return (
    <>
      <Link
        to={formatPricingRedirection(
          data.redirectPath || data.path || pathname,
          pathname,
          search
        )}
        className={cx(
          'p-4.5 mb-4 flex gap-4.5 items-center',
          isActive ? 'bg-[#FFE6D8]' : 'hover:bg-gray-100 cursor-pointer'
        )}
        onClick={() => {
          onClick?.();
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="flex-none ml-2">
          <Icon mode={isActive ? 'active' : isHovering ? 'hover' : 'default'} />
        </div>
        {expanded && !isWrapperHovering ? (
          <div className="h-6" />
        ) : (
          <div
            className={cx(
              `text-base font-normal`,
              isActive ? 'font-medium' : ''
            )}
          >
            {data.title}
          </div>
        )}
      </Link>
    </>
  );
}
