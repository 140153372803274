import { Star } from 'react-feather';
import ReviewSource from './ReviewSource';

import { BBBCard } from '@/components';
import BBBUpsellCard from '@/components/BBBUpsellCard/BBBUpsellCard';

function ProductCustomization() {
  return (
    <>
      <BBBUpsellCard
        appType="BITAPP"
        title="Integrate product reviews to boost conversion rates"
        description="Product reviews helps you leverage positive customer reviews to boost conversion rates and strengthen your brand."
        id="product-reviews"
      />
      <BBBCard
        title={'Review Source'}
        desc={
          'Review will appear on the product page directly from your chosen app.'
        }
      >
        <div className="my-10 flex flex-col gap-2.5">
          <p className="text-primary-main text-ls">Preview of user’s review:</p>
          <div className="bg-neutral-20 rounded-xl px-3 py-2.5 flex items-center gap-5">
            <PlaceholderThumbnail />
            <div className="grow flex flex-col py-1.5 gap-2">
              <div className="flex items-center gap-2.5">
                {Array.from({ length: 5 }).map((_, i) => (
                  <Star
                    fill={i === 4 ? 'transparent' : '#F1C21D'}
                    stroke={i === 4 ? '#9E9E9E' : 'transparent'}
                    size={14}
                    key={i}
                  />
                ))}
                <div className="text-xs">John Doe</div>
              </div>
              <div className="text-xs font-medium">Leather Bag</div>
              <div className="w-full flex justify-between items-center">
                <p className="text-neutral-60 text-xs">
                  This is a description of a leather bag.
                </p>
                <p className="text-neutral-40 text-[10px]">seconds ago</p>
              </div>
            </div>
          </div>
        </div>
        <ReviewSource />
      </BBBCard>
    </>
  );
}

function PlaceholderThumbnail() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="69"
        height="69"
        rx="3.5"
        fill="#E6E6E6"
        stroke="#DDDDDD"
      />
      <path
        d="M57.9878 42.0243L51.2434 34.1596L42.729 24.2258C42.4413 23.8904 42.0801 23.6203 41.6711 23.4349C41.2622 23.2495 40.8156 23.1534 40.3635 23.1534C39.9114 23.1534 39.4649 23.2495 39.0559 23.4349C38.647 23.6203 38.2857 23.8904 37.998 24.2258L29.4837 34.1596L29.3784 34.2798L26.3911 30.7935C26.1034 30.4581 25.7422 30.188 25.3332 30.0026C24.9243 29.8172 24.4777 29.7211 24.0256 29.7211C23.5735 29.7211 23.127 29.8172 22.718 30.0026C22.3091 30.188 21.9478 30.4581 21.6601 30.7935L15.962 37.4434L12.014 42.0243C11.6425 42.4547 11.4062 42.9781 11.333 43.533C11.2597 44.0879 11.3525 44.6514 11.6004 45.1575C11.8483 45.6635 12.2411 46.0911 12.7328 46.3901C13.2244 46.6891 13.7945 46.8471 14.3762 46.8457H55.6256C56.2073 46.8471 56.7774 46.6891 57.269 46.3901C57.7607 46.0911 58.1535 45.6635 58.4014 45.1575C58.6493 44.6514 58.7421 44.0879 58.6688 43.533C58.5956 42.9781 58.3593 42.4547 57.9878 42.0243Z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

export default ProductCustomization;
