import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import { useFlowChannel } from '../../hooks';
import useStore from '../../store';
import { ActionData } from '../../types';
import ActionItem from '../ActionItem';
import FlowOptions from '../FlowOptions';

import { SettingsTag } from '@/api/services/whatsApp/settings';
import { BBBModal, BBBSelect } from '@/components';
import {
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
  staticAgents,
} from '@/constants/bitChat/agent';
import {
  ActionItem as ActionItemType,
  actionItems,
} from '@/constants/bitChat/flows';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import { useFlows1 } from '@/hooks/whatsApp/flow';
import AgentOptions, {
  AgentOption,
} from '@/pages/BitChat/components/AgentOptions';
import TagOptions from '@/pages/Customers/Segmentation/TagOptions';
import { Tags } from '@/types/customers';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { Flow } from '@/types/whatsApp/flow';
import { formatUserDisplayName } from '@/utils/auth';

const actionModalSchema = yup.object({
  action: yup.mixed<ActionItemType>().required().label('Action'),
  customerTags: yup
    .array()
    .label('Customer tags')
    .when('action', {
      is: (value: ActionItemType) => value.value.includes('customer'),
      then: (rule) => rule.min(1),
    }),
  ticketCategory: yup
    .mixed<SettingsTag>()
    .label('Ticket category')
    .when('action', {
      is: (value: ActionItemType) => value.value === 'set_ticket_category',
      then: (rule) => rule.required(),
    }),
  agent: yup
    .mixed<UserCompanyWithAssociation>()
    .label('Agent')
    .when('action', {
      is: (value: ActionItemType) => value.value === 'assign_ticket',
      then: (rule) => rule.required(),
    }),
  flow: yup
    .mixed<Flow>()
    .label('Flow')
    .when('action', {
      is: (value: ActionItemType) => value.value === 'run_flow',
      then: (rule) => rule.required(),
    }),
});

type ActionForm = {
  action: ActionItemType | null;
  customerTags: Tags[];
  ticketCategory: SettingsTag | null;
  agent?: AgentOption | null;
  flow?: Flow | null;
};

const actionDefaultValues: ActionForm = {
  action: null,
  customerTags: [],
  ticketCategory: null,
  agent: null,
  flow: null,
};

export default function ActionModal({
  onClose,
  onSave,
  nodeId,
}: {
  onClose?: () => void;
  onSave: (params: ActionData) => void;
  nodeId?: string;
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<ActionForm>({
    resolver: yupResolver(actionModalSchema),
    defaultValues: actionDefaultValues,
  });

  const agent = useStore(
    (s) => s.nodes.find((node) => node.id === nodeId)?.data.agent
  );

  const customerTags = useStore(
    (s) => s.nodes.find((node) => node.id === nodeId)?.data.customerTags
  );

  const actionFromNode = useStore(
    (s) => s.nodes.find((node) => node.id === nodeId)?.data.action
  );

  const ticketCategory = useStore(
    (s) => s.nodes.find((node) => node.id === nodeId)?.data.ticketCategory
  );

  const flowId = useStore(
    (s) => s.nodes.find((node) => node.id === nodeId)?.data.flow?.id
  );

  const source = useFlowChannel();
  const { data: flows } = useFlows1(
    { channel: source! },
    { enabled: !!source }
  );

  const { data } = useSettings();

  const dataFromProps = useMemo(() => {
    return {
      customerTags: customerTags || actionDefaultValues.customerTags,
      action: actionFromNode
        ? actionItems.find((item) => item.value === actionFromNode)
        : actionDefaultValues.action,
      agent: agent
        ? 'formattedName' in agent
          ? agent
          : staticAgents.includes(agent.id)
          ? agent.id === EVERYONE_AGENT_ID
            ? {
                formattedName: `Everyone (${agent.meta.count})`,
                user: { id: EVERYONE_AGENT_ID },
              }
            : generalAiAgentOption
          : {
              formattedName: formatUserDisplayName(agent),
              user: {
                id: agent.id,
              },
            }
        : actionDefaultValues.agent,
      ticketCategory: ticketCategory
        ? ticketCategory
        : data?.ticketTags?.find((tag) => tag.label === 'Regular ticket') ||
          actionDefaultValues.ticketCategory,
      flow: flowId
        ? flows?.find((opt) => opt.id === flowId)
        : actionDefaultValues.flow,
    };
  }, [
    actionFromNode,
    agent,
    customerTags,
    data?.ticketTags,
    flowId,
    flows,
    ticketCategory,
  ]);

  const isDataEqual = isEqual(dataFromProps, watch());

  useEffect(() => {
    reset(dataFromProps);
  }, [dataFromProps, reset]);

  const action = watch('action');

  return (
    <>
      {action === null && (
        <BBBModal
          show
          title="Action"
          footer
          submitText="Save"
          cancelText="Discard"
          onHide={onClose}
          disableSave
        >
          {actionItems.map((item) => (
            <ActionItem
              {...item}
              key={item.value}
              onChange={(val) => setValue('action', val)}
            />
          ))}
        </BBBModal>
      )}
      {action && (
        <BBBModal
          show
          title="Action"
          footer
          submitText="Save"
          cancelText="Discard"
          onHide={onClose}
          handleSave={() => {
            handleSubmit((data) => {
              onSave({
                action: data.action!.value,
                ticketCategory: data.ticketCategory,
                agent: data.agent,
                customerTags: data.customerTags,
                flow: data.flow || null,
              });
              onClose?.();
            })();
          }}
          disableSave={isDataEqual}
        >
          <Controller
            control={control}
            name="action"
            render={({ field }) => (
              <BBBSelect
                //@ts-ignore
                options={actionItems}
                optionLabel="label"
                optionValue="value"
                placeholder="Choose option"
                containerClassName="mb-5"
                value={field.value}
                onValueChange={field.onChange}
                error={errors.action?.message}
              />
            )}
          />
          {action.value === 'run_flow' && (
            <Controller
              control={control}
              name="flow"
              render={({ field }) => {
                return (
                  <FlowOptions
                    value={field.value}
                    onValueChange={field.onChange}
                    error={errors.flow?.message}
                    flows={flows}
                  />
                );
              }}
            />
          )}
          {action.value === 'assign_ticket' && (
            <Controller
              control={control}
              name="agent"
              render={({ field }) => {
                return (
                  <AgentOptions
                    label="Assign ticket"
                    value={field.value || undefined}
                    onValueChange={field.onChange}
                    placeholder="Choose asignee"
                    error={errors.agent?.message}
                    includeSelf
                    withAiAgent
                    withEveryone
                  />
                );
              }}
            />
          )}
          {action.value === 'set_ticket_category' && (
            <>
              <Controller
                control={control}
                name="ticketCategory"
                render={({ field }) => (
                  <BBBSelect
                    label="Set ticket category to"
                    placeholder="Choose ticket category"
                    containerClassName="mb-5"
                    options={data?.ticketTags}
                    optionLabel="label"
                    optionValue="id"
                    value={field.value}
                    onValueChange={field.onChange}
                    isCreatable
                    error={errors.ticketCategory?.message}
                  />
                )}
              />
            </>
          )}
          {(action.value === 'add_customer_tag' ||
            action.value === 'remove_customer_tag') && (
            <Controller
              control={control}
              name="customerTags"
              render={({ field }) => (
                <TagOptions
                  isMulti
                  containerClassName="w-full md:min-w-full"
                  placeholder="Search tag"
                  label={
                    action.value === 'remove_customer_tag'
                      ? 'Remove this customer tag'
                      : 'Add this customer tag'
                  }
                  isSearchable
                  value={field.value}
                  onValueChange={field.onChange}
                  error={errors.customerTags?.message}
                  isCreatable
                  sensitive
                />
              )}
            />
          )}
        </BBBModal>
      )}
    </>
  );
}
