import { useState } from 'react';
import { Link } from 'react-router-dom';
import { env } from 'config/env';
import { useOnboarding } from 'hooks/onboarding/bitcrm';
import { useUpdateOnboarding } from 'hooks/onboarding/onboarding';
import OnboardingPage from 'layouts/OnboardingPage';
import { ShopifyCardBitCRM, WhatsAppCloudApiCard } from 'pages/Integrations';
import { BBBButton, BBBCard } from 'components';
import BBBAccordion from 'components/BBBAccordion';
import { cn } from 'utils/styles';

export default function BitCRMOnboarding() {
  const { keyPoints, dismissed } = useOnboarding();

  const completeCount = keyPoints.filter(
    (point) => point.completed || point.skipped
  ).length;

  return (
    <OnboardingPage
      keyPoints={keyPoints}
      completeCount={completeCount}
      app="BITCRM"
      dismissed={!!dismissed}
    >
      {({ keyPoint, completed, skipped, meta }) => (
        <>
          {keyPoint === 'shopify_integration' && <ShopifyIntegrations />}
          {keyPoint === 'whatsapp_api' && <WhatsAppApi />}
          {keyPoint === 'personalize_message' && (
            <PersonalizeMessage completed={!!completed} skipped={!!skipped} />
          )}
          {keyPoint === 'recover_sales' && (
            <RecoverSales //@ts-ignore
              meta={meta}
            />
          )}
          {keyPoint === 'target_audience' && (
            <TargetRightAudience completed={!!completed} skipped={!!skipped} />
          )}
        </>
      )}
    </OnboardingPage>
  );
}

function ShopifyIntegrations() {
  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-shopify-integrations.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Boost sales with Shopify integration</div>
      <p className="mb-3">
        You&apos;ve connected your favorite chat channels - awesome! Now,
        let&apos;s integrate your Shopify store to unlock the full potential of
        bitCRM.
      </p>
      <p className="mb-3">
        Benefits of connecting Shopify:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Recover Abandoned Cart:</span> Answer
            customer product questions directly within chat using Shopify
            product data.
          </li>
          <li>
            <span className="font-bold">Ask Product Review:</span> Manage orders
            and track fulfillment status right from your bitChat dashboard
          </li>
          <li>
            <span className="font-bold">Blast Product Thousand Customers:</span>{' '}
            Provide a smooth shopping experience with chat support directly
            linked to your products.
          </li>
        </ul>
      </p>
      <p className="mb-3">
        <span className="text-secondary-main font-bold">
          Start boosting your sales! 🚀
        </span>{' '}
      </p>
      <ShopifyCardBitCRM v="v3" />
    </BBBCard>
  );
}

function WhatsAppApi() {
  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitCRM/bitcrm-whatsapp-api.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Reach more customers with WhatsApp API</div>
      <p className="mb-3">Unlock these feature by using WhatsApp API:</p>
      <p className="mb-3">
        <ul className="list-disc ml-4">
          <li>WhatsApp form</li>
          <li>Chatbot with button & option list</li>
          <li>Campaign with CTA button</li>
        </ul>
      </p>
      <p className="mb-3">
        We recommend you to using WhatsApp API for bitCRM for avoiding risk to
        banned by Meta. You can follow{' '}
        <span className="underline text-link cursor-pointer">
          <a
            href="https://www.bitbybit.studio/knowledge-base/how-to-create-and-connect-whatsapp-cloud-api-with-bitbybit"
            target="_blank"
            rel="noreferrer"
          >
            this instruction
          </a>
        </span>{' '}
        to create your own WhatsApp API if you don’t have one.{' '}
        <span className="underline text-link cursor-pointer">
          <a
            href="https://www.bitbybit.studio/knowledge-base/how-to-create-and-connect-whatsapp-cloud-api-with-bitbybit"
            target="_blank"
            rel="noreferrer"
          >
            Click here
          </a>
        </span>
      </p>
      <p className="mb-6">
        <span className="text-secondary-main font-bold">
          Reach your customer now 🔈
        </span>{' '}
      </p>
      <WhatsAppCloudApiCard v="v3" />
    </BBBCard>
  );
}

function PersonalizeMessage({
  completed,
  skipped,
}: {
  completed: boolean;
  skipped: boolean;
}) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCRM');

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitCRM/bitcrm-personalize-message.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Personalize your message</div>
      <p className="mb-3">
        Craft tailored messages using customer names, order details, and
        exclusive voucher codes to engage and convert effectively.
      </p>
      <p className="mb-6">
        You can add these dynamic variable to your message:
        <ul className="list-disc ml-4">
          <li>Customer name</li>
          <li>Payment status</li>
          <li>Order detail</li>
          <li>Product review url</li>
          <li>And many more!</li>
        </ul>
      </p>
      {!completed && (
        <div className="flex justify-end items-center gap-4">
          {!skipped && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({
                  module: 'personalize_message',
                  skipped: true,
                });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/bitcrm/template'}>
            <BBBButton>Create message template</BBBButton>
          </Link>
        </div>
      )}
    </BBBCard>
  );
}

function TargetRightAudience({
  completed,
  skipped,
}: {
  completed: boolean;
  skipped: boolean;
}) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCRM');

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitCRM/bitcrm-target-audience.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Target the right audience effectively</div>
      <p className="mb-3">
        Segment your customers based on their behavior and preferences. Use
        these segments to deliver personalized campaigns that resonate and drive
        engagement.
      </p>
      <p className="mb-6">
        You can create customer segmentation by using these parameters:
        <ul className="list-disc ml-4">
          <li>Customer tag</li>
          <li>AI tag</li>
          <li>Web activity</li>
          <li>And more!</li>
        </ul>
      </p>
      {!completed && (
        <div className="flex justify-end items-center gap-4">
          {!skipped && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({
                  module: 'target_audience',
                  skipped: true,
                });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/customers'}>
            <BBBButton>Create segmentation</BBBButton>
          </Link>
        </div>
      )}
    </BBBCard>
  );
}

type RecoverSalesMeta = {
  completedRecoverSales: boolean;
  completedAbandonedCart: boolean;
  skippedRecoverSales: boolean;
  skippedAbandonedCart: boolean;
};

function RecoverSales({ meta }: { meta: RecoverSalesMeta }) {
  const [collapsed, setCollapsed] = useState<string | undefined>('step1');

  return (
    <BBBAccordion>
      <BBBAccordion.Item
        title="Recover lost sales effortlessly"
        collapse={collapsed === 'step1'}
        onCollapse={(_collapsed) => {
          setCollapsed(_collapsed ? 'step1' : undefined);
        }}
        leftChildren={<Step step={1} active={collapsed === 'step1'} />}
        content={<Step1 {...meta} />}
      />
      <BBBAccordion.Item
        title="Activate your abandoned cart"
        collapse={collapsed === 'step2'}
        onCollapse={(_collapsed) => {
          setCollapsed(_collapsed ? 'step2' : undefined);
        }}
        leftChildren={<Step step={2} active={collapsed === 'step2'} />}
        content={<Step2 {...meta} />}
      />
    </BBBAccordion>
  );
}

function Step2(props: RecoverSalesMeta) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCRM');

  return (
    <>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitCRM/bitcrm-abandoned-cart.png`}
        className="object-contain my-6"
      ></img>
      <p className="mb-6">
        Turn on the abandoned cart automation to start recovering lost sales.
        Once activated, bitCRM will send timely reminders to your customers,
        helping you boost conversions.
      </p>
      {!props.completedAbandonedCart && (
        <div className="flex justify-end items-center gap-4">
          {!props.skippedAbandonedCart && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({ module: 'abandoned_cart', skipped: true });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/bitcrm/automation-new'}>
            <BBBButton>Turn on abandoned cart automaiton</BBBButton>
          </Link>
        </div>
      )}
    </>
  );
}

function Step1(props: RecoverSalesMeta) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCRM');

  return (
    <>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitCRM/bitcrm-recover-sales.png`}
        className="object-contain my-6"
      ></img>
      <p className="mb-6">
        Set up automated messages to remind customers about their abandoned
        carts. Customize your message to encourage them to complete their
        purchase.
      </p>
      {!props.completedRecoverSales && (
        <div className="flex justify-end items-center gap-4">
          {!props.skippedRecoverSales && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({ module: 'recover_sales', skipped: true });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/bitcrm/automation-new/new?prefill_type=abandoned_cart'}>
            <BBBButton>Set up abandoned cart</BBBButton>
          </Link>
        </div>
      )}
    </>
  );
}

function Step({ step, active }: { step: number; active: boolean }) {
  return (
    <div
      className={cn(
        'rounded-full w-6 h-6 flex items-center justify-center bg-primary-main opacity-20',
        active && 'opacity-100'
      )}
    >
      <p className="text-neutral-10 font-bold text-lg">{step}</p>
    </div>
  );
}
