import { ReactNode } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard, IBBBCard } from '@/components';
import useResponsive from '@/hooks/common/useResponsive';

export type BBBNavigatorTabProps<T> = {
  navigationLists: T[];
  value?: T;
  optionValue: keyof T;
  optionLabel: keyof T;
  optionIcon?: keyof T;
  onChange?: (val: T) => void;
  className?: string;
  rightComponent?: ReactNode;
} & Omit<IBBBCard, 'onChange'>;

function BBBNavigatorTab<T>({
  navigationLists,
  value,
  onChange,
  optionLabel,
  optionValue,
  optionIcon,
  className,
  rightComponent,
  ...cardProps
}: BBBNavigatorTabProps<T>) {
  const isMobile = useResponsive('sm');

  return (
    <BBBCard
      className={cx(
        'px-3 py-2 md:px-3 md:py-2 flex items-center gap-3 flex-wrap',
        className
      )}
      {...cardProps}
    >
      {navigationLists.map((item) => (
        <div key={item[optionValue] as string} className="flex-1 lg:flex-none">
          <div
            className={cx(
              `flex justify-center whitespace-nowrap transition-all rounded-lg hover:bg-gray-100 cursor-pointer items-center gap-1 py-2 px-4`,
              isMobile ? 'flex-col' : '',
              item[optionValue] === value?.[optionValue]
                ? 'bg-primary-main pointer-events-none font-bold'
                : ''
            )}
            onClick={() => {
              onChange?.(item);
            }}
          >
            {optionIcon && <span>{item[optionIcon] as ReactNode}</span>}
            <div
              className={
                item[optionValue] === value?.[optionValue]
                  ? 'text-white'
                  : 'text-primary-main'
              }
            >
              {item[optionLabel] as ReactNode}
            </div>
          </div>
        </div>
      ))}
      {rightComponent && (
        <>
          <div className="grow" />
          {rightComponent}
        </>
      )}
    </BBBCard>
  );
}

export default BBBNavigatorTab;
