import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import Options, { DIVIDER_OPTIONS } from './Options';

import { BBBCard, BBBFooterAction, BBBPrimarySwitch } from '@/components';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from '@/hooks/bitLink/links/useLinklistMutation';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setForm } from '@/stores/bitLink';

const schema = yup.object().shape({
  title: yup.mixed().required('Title is required'),
  active: yup.bool().required('Active is required'),
});

type FormDividerInput = {
  title: typeof DIVIDER_OPTIONS[number]['name'];
  active: boolean;
};

export default function FormDivider() {
  const { handleSubmit, reset, setValue, control } =
    useCustomForm<FormDividerInput>({
      resolver: yupResolver(schema),
      defaultValues: {
        title: 'solid',
        active: true,
      },
    });

  const form = useAppSelector((state) => state.bitLink.form);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue(
      'title',
      DIVIDER_OPTIONS.find((option) => option.name === form?.textPrimary)
        ?.name ?? 'solid'
    );
    setValue('active', form?.active ?? true);
  }, [form?.textPrimary, form?.active, form?.textSecondary, setValue]);

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();

  const deleteLinklist = useLinklistDeleteMutation();
  const isMobile = useResponsive('sm');

  const handleDeleteForm = () => {
    if (form?.id) {
      deleteLinklist(form?.id);
    } else {
      setForm(null);
    }
  };

  const onSubmit = async (data: FormDividerInput) => {
    const requestData = {
      type: 'divider' as const,
      textPrimary: data.title,
      active: data.active,
    };

    if (!form?.id) {
      addLinklist(requestData);
    } else {
      editLinklist({ id: form?.id, data: requestData });
    }
  };

  return (
    <BBBCard
      title="Divider type"
      rightButton={
        !isMobile && (
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                checked={field.value}
                onChange={field.onChange}
                containerClassName="flex justify-end mb-3"
              />
            )}
          />
        )
      }
      className={cx('mb-cardBottom', isMobile && 'border-none p-0')}
    >
      <Controller
        control={control}
        name="title"
        render={({ field }) => (
          <Options
            value={field.value}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />
      <BBBFooterAction
        containerClassName="justify-end"
        onCancel={() => {
          reset();
          dispatch(setForm(null));
        }}
        withoutDelete={form?.id ? undefined : true}
        loadingSave={isLoading || isLoadingEdit}
        onSave={handleSubmit(onSubmit)}
        onDelete={handleDeleteForm}
      />
    </BBBCard>
  );
}
