import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { Settings } from '@/api/services/whatsApp/settings';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { SocialButtonAppearance } from '@/types/bitLogin/v2';
import { DeepPartial } from '@/types/utils/deepPartial';
import { toast } from '@/utils/common/toast';

const useUpdateSettings = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (
      payload: DeepPartial<
        Omit<Settings, 'awayMessage' | 'welcomeMessage'> & {
          awayMessageId?: string | null;
          welcomeMessageId?: string | null;
        }
      >
    ) => services.whatsApp.settings.updateSettings(activeCompany, payload),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries(['bitchat-settings', activeCompany]);

        const previousData = queryClient.getQueryData([
          'bitchat-settings',
          activeCompany,
        ]);

        queryClient.setQueryData<Partial<SocialButtonAppearance>>(
          ['bitchat-settings', activeCompany],
          (old) => ({
            ...old,
            ...payload,
          })
        );

        return { previousData };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['bitchat-settings']);
        toast.success('Settings updated successfully');
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['bitchat-settings', activeCompany],
          context?.previousData
        );
      },
    }
  );
};

export default useUpdateSettings;
