import { useEffect, useRef, useState } from 'react';
import { Clock } from 'react-feather';
import { Link } from 'react-router-dom';
import { Node } from 'reactflow';
import AlertIcon3 from 'assets/icons/AlertIcon3';
import PlusIcon from 'assets/icons/PlusIcon';
import ThunderIcon from 'assets/icons/ThunderIcon';
import ThunderIcon2 from 'assets/icons/ThunderIcon2';
import { integrationsMeta } from 'constants/integrations';
import { mapSourceToKnownIntegrations } from 'constants/whatsApp';
import {
  useFlows,
  useToggleFlow,
} from 'hooks/bitCRM/automation/automation-new';
import { AutomationNewNode } from 'types/bitCRM/automation-new';
import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBPrimarySwitch,
  BBBSpinner,
} from 'components';
import {
  actionSchema,
  ActionSource,
  triggerSchema,
  TriggerSource,
} from './types';

import NewBadge from '@/components/Badge/NewBadge';
import { prebuiltAutomationPopular } from '@/constants/bitCRM/automation-new';

export default function AutomationNew() {
  const { data, isLoading: loadingFlows } = useFlows();
  const { mutate: toggleFlow } = useToggleFlow();

  if (loadingFlows) {
    return (
      <div className="h-full flex flex-col justify-center items-center">
        <div>
          <BBBSpinner />
        </div>
        <div>Loading scenario</div>
      </div>
    );
  }

  return (
    <BBBContainer
      pageTitle={
        <div className="flex items-center gap-1">
          Automation <NewBadge />
        </div>
      }
      hasHeading
      pageDescription="Automate your message by creating a flow"
      rightComponent={
        <Link to={`/bitcrm/automation-new/new`}>
          <BBBButton icon={<PlusIcon color="white" />} iconPosition="right">
            Create new scenario
          </BBBButton>
        </Link>
      }
    >
      {!data?.length ? (
        <BBBCard className="h-[15.75rem] flex justify-center items-center flex-col mb-5">
          <div className="rounded-full bg-neutral-30 w-[3.125rem] h-[3.125rem] flex justify-center items-center mb-2">
            <ThunderIcon2 size={'1.5rem'} />
          </div>
          <div className="text-neutral-50 mb-5">
            There is no scenario, create one to boost your customer’s shopping
            experience
          </div>
          <Link to={`/bitcrm/automation-new/new`}>
            <BBBButton variant="secondary">Create a scenario</BBBButton>
          </Link>
        </BBBCard>
      ) : (
        <BBBCard className="mb-5">
          {data.map((automation) => {
            const { icons, remainder } = getUniqueSourceAndChannel(
              automation.nodes
            );

            const isValid = automation.nodes
              .filter((node) => ['trigger', 'action'].includes(node.type!))
              .every((node) => {
                if (node.type === 'trigger') {
                  return triggerSchema.isValidSync(node.data);
                }

                return actionSchema.isValidSync(node.data);
              });

            return (
              <Link
                className="pb-3 border-b border-neutral-30 last:border-none mb-5 last:mb-0 last:pb-0 block"
                key={automation.id}
                to={`/bitcrm/automation-new/${automation.id}`}
              >
                <div className="flex items-center">
                  <ThunderIcon />
                  <div className="text-neutral-600 text-lg grow ml-2">
                    {automation.name}
                  </div>
                  <div className="flex items-center gap-2 mr-4">
                    {icons}
                    {remainder >= 1 && (
                      <div className="w-6 h-6 rounded-[0.375rem] bg-neutral-30 text-neutral-50 text-sm flex items-center justify-center">
                        {' '}
                        +{remainder}{' '}
                      </div>
                    )}
                  </div>
                  <BBBPrimarySwitch
                    tooltip={
                      !isValid ? 'Complete the scenario to enable' : undefined
                    }
                    disabled={!isValid}
                    onClick={(e) => {
                      toggleFlow({
                        id: automation.id,
                        status:
                          automation.status === 'ACTIVE'
                            ? 'INACTIVE'
                            : 'ACTIVE',
                        name: automation.name,
                      });
                      e.stopPropagation();
                    }}
                    checked={automation.status === 'ACTIVE'}
                  />
                </div>
                {!isValid && (
                  <div className="flex items-center gap-1 mt-1">
                    <AlertIcon3 />
                    <div className="text-sm text-neutral-500">
                      Uncompleted scenario
                    </div>
                  </div>
                )}
              </Link>
            );
          })}
        </BBBCard>
      )}

      <div className="text-xl mb-4">Most popular</div>
      <div className="grid grid-cols-3 gap-5">
        {prebuiltAutomationPopular.map((prebuilt, i) => {
          const { icons } = getUniqueSourceAndChannel(prebuilt.nodes);

          return (
            <Link
              key={i}
              to={`/bitcrm/automation-new/new${
                prebuilt.key ? `?prefill_type=${prebuilt.key}` : ''
              }`}
            >
              <BBBCard
                title={prebuilt.title}
                className="hover:border-secondary-main"
              >
                <div className="text-neutral-500 mb-4">
                  {prebuilt.description}
                </div>
                <div className="flex items-center gap-2">{icons}</div>
              </BBBCard>
            </Link>
          );
        })}
      </div>
    </BBBContainer>
  );
}

function getUniqueSourceAndChannel(nodes: AutomationNewNode[] | Node[]) {
  const sources: (TriggerSource | ActionSource)[] = nodes
    //@ts-ignore
    .filter((node) => !!node.data)
    //@ts-ignore
    .map((node) => node.data!.source)
    .filter(Boolean);

  const uniqueArray = Array.from(new Set(sources));

  const icons = uniqueArray.map((source) => {
    if (source === 'time_delay')
      return (
        <Clock className="text-secondary-main" size={'1.75rem'} key={source} />
      );

    const Icon = integrationsMeta[mapSourceToKnownIntegrations[source]].icon;

    if (!Icon)
      return <div className="w-6 h-6 rounded-lg bg-neutral-300" key={source} />;

    return <Icon key={source} />;
  });

  return { icons: icons.slice(0, 4), remainder: icons.length - 3 };
}
