import React, { ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import { createId } from '@paralleldrive/cuid2';
import { AnimatePresence, motion } from 'framer-motion';
import { BBBBottomSheet } from '../BBBBottomSheet';
import { ButtonVariant } from '../BBBButton/BBBButton';

import BBBFooterAction from '@/components/BBBFooterAction/BBBFooterAction';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import { cn } from '@/utils/styles';

const customSize = {
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': ' 42rem',
  '3xl': ' 48rem',
  '4xl': ' 56rem',
  '5xl': ' 64rem',
  '6xl': ' 72rem',
  '7xl': ' 80rem',
};

export type CustomModalProp = {
  onHide?: () => void;
  show: boolean;
  onCancel?: () => void;
  /**
   * @deprecated
   * Use `withoutCancel` instead
   * */
  showCancel?: boolean;
  /**
   * @deprecated
   * Use `withoutCancel` instead
   * */
  disableCancel?: boolean;
  withoutCancel?: boolean;
  handleSave?: () => void;
  submitText?: string | React.ReactNode;
  overrideSubmitBtn?: boolean;
  submitClassName?: string;
  submitVariant?: ButtonVariant;
  submitLink?: string;
  submitHref?: string;
  cancelText?: string | React.ReactNode;
  cancelHref?: string;
  subtitle?: ReactNode;
  subtitleClassName?: string;
  centerBody?: boolean;
  centerTitle?: boolean;
  title?: ReactNode;
  children?: React.ReactNode;
  disableSave?: boolean;
  loadingSave?: boolean;
  footer?: boolean;
  footerClassName?: string;
  size?: keyof typeof customSize;
  handleDelete?: () => void;
  customFooter?: React.ReactNode;
  centerFooter?: boolean;
  customDelete?: React.ReactNode;
  deleteModal?: boolean;
  target?: HTMLElement;
  blurBackdrop?: boolean;
  z?: number;
  id?: string;
  modalId?: string;
  disableVerticallyCentered?: boolean;
  className?: string;
  bodyClassName?: string;
  titleIcon?: ReactNode;
  additionalButton?: (
    props: Partial<
      Pick<CustomModalProp, 'onCancel' | 'handleSave' | 'handleDelete'>
    >
  ) => ReactNode;
  deleteWithButton?: boolean;
  overrideCancelBtn?: boolean;
};

export default function CustomModal({
  onHide = () => ({}),
  show,
  onCancel = onHide,
  withoutCancel,
  handleSave = () => ({}),
  submitText = 'Save Changes',
  overrideSubmitBtn,
  cancelText = 'Cancel',
  cancelHref,
  subtitle,
  subtitleClassName,
  centerBody,
  centerTitle = false,
  title,
  children,
  disableSave = false,
  loadingSave,
  footer,
  footerClassName,
  size = '2xl',
  handleDelete,
  customFooter,
  centerFooter = false,
  customDelete,
  target,
  z = 300,
  blurBackdrop,
  id = createId(),
  modalId,
  disableVerticallyCentered,
  className,
  bodyClassName,
  deleteModal,
  submitLink,
  submitHref,
  submitClassName,
  submitVariant,
  titleIcon,
  additionalButton,
  deleteWithButton,
  overrideCancelBtn,
}: CustomModalProp) {
  const isMobile = useResponsive('sm');

  const ref = useRef<HTMLDivElement>(null);

  useOutsideAlerter(ref, (target) => {
    if (
      target.closest(`[data-modal-id="${id}"]`) ===
      document.querySelector(`[data-modal-id="${id}"]`)
    ) {
      onHide();
    }
  });

  return isMobile ? (
    <BBBBottomSheet show={show} title={title} onClose={onHide}>
      {children}
      {footer ? (
        <BBBFooterAction
          withoutDelete={!customDelete}
          onSave={handleSave}
          onCancel={onCancel}
          discardLabel={cancelText}
          cancelHref={cancelHref}
          disableSave={disableSave}
          loadingSave={loadingSave}
          saveLabel={submitText}
          overrideSubmitBtn={overrideSubmitBtn}
          submitClassName={submitClassName}
          submitVariant={submitVariant}
          onDelete={handleDelete}
          withoutCancel={withoutCancel}
          className={footerClassName}
          additionalButton={() =>
            additionalButton?.({
              onCancel: onHide,
              handleSave,
              handleDelete,
            })
          }
          deleteWithButton={deleteWithButton}
          centerFooter={centerFooter}
          overrideCancelBtn={overrideCancelBtn}
          submitLink={submitLink}
          submitHref={submitHref}
        />
      ) : (
        customFooter
      )}
    </BBBBottomSheet>
  ) : (
    createPortal(
      !show ? null : (
        <div id="modal-root" data-modal-id={id}>
          <div
            className={cn(
              'absolute inset-0',
              blurBackdrop ? 'backdrop-blur-sm' : 'bg-gray-300/40'
            )}
            style={{ zIndex: z }}
          />
          <div
            className={cn('absolute inset-0 overflow-auto')}
            style={{ zIndex: z }}
          >
            <div
              className={cn(
                'min-h-full flex flex-col',
                !disableVerticallyCentered && 'justify-center'
              )}
            >
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div
                    id={modalId}
                    className={cn(
                      'mx-auto my-8 text-left align-middle transition-all transform bg-white border-grayColor2 border-[1.5px] rounded-2xl relative',
                      className
                    )}
                    style={{
                      maxWidth: customSize[size],
                    }}
                    ref={ref}
                  >
                    <div className="px-5 pt-5 flex items-center gap-3">
                      {titleIcon && (
                        <div className="border border-neutral-30 p-2 rounded-lg">
                          {titleIcon}
                        </div>
                      )}
                      <div className="grow">
                        <div
                          className={cn(
                            ` text-2xl text-primary-main`,
                            centerTitle && 'text-center'
                          )}
                        >
                          {title}
                        </div>
                        {subtitle && (
                          <p
                            className={cn(
                              'mt-1 text-sm text-gray-500',
                              centerTitle && 'text-center',
                              subtitleClassName
                            )}
                          >
                            {subtitle}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className={cn(
                        'my-4 px-5',
                        centerBody
                          ? 'text-center flex justify-center items-center flex-col'
                          : '',
                        bodyClassName
                      )}
                    >
                      {children}
                    </div>

                    {footer ? (
                      <BBBFooterAction
                        withoutDelete={!customDelete && !handleDelete}
                        onSave={handleSave}
                        onCancel={onCancel}
                        discardLabel={cancelText}
                        cancelHref={cancelHref}
                        disableSave={disableSave}
                        loadingSave={loadingSave}
                        saveLabel={submitText}
                        overrideSubmitBtn={overrideSubmitBtn}
                        submitClassName={submitClassName}
                        submitVariant={submitVariant}
                        onDelete={handleDelete}
                        containerClassName={cn('flex items-center')}
                        deleteLabel={customDelete}
                        withoutCancel={withoutCancel}
                        deleteModal={deleteModal}
                        submitLink={submitLink}
                        className={footerClassName}
                        submitHref={submitHref}
                        additionalButton={() =>
                          additionalButton?.({
                            onCancel: onHide,
                            handleSave,
                            handleDelete,
                          })
                        }
                        deleteWithButton={deleteWithButton}
                        centerFooter={centerFooter}
                        overrideCancelBtn={overrideCancelBtn}
                      />
                    ) : (
                      customFooter
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      ),
      target || document.body
    )
  );
}
