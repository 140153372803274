import { twMerge as cx } from 'tailwind-merge';

import { Order, OrderLineItem, Product } from '@/api/services/customer/order';
import { BBBTooltip } from '@/components';
import { env } from '@/config/env';
import { formatCurrency } from '@/utils/common/currency';
import { getProductName } from '@/utils/customers';

type Props = {
  setExpandedOrders: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  order: Order;
  expandedOrders: Record<string, boolean>;
  product: Product | null;
  lineItem: OrderLineItem;
};

export default function ProductItem({
  setExpandedOrders,
  order,
  expandedOrders,
  product,
  lineItem,
}: Props) {
  if (!product) return null;

  return (
    <div
      className="flex-1 gap-2 flex items-center cursor-pointer hover:opacity-90"
      onClick={() => {
        setExpandedOrders((prev) => ({
          ...prev,
          [order.id]: !prev[order.id],
        }));
      }}
    >
      <div className="h-16 w-16">
        <img
          className={cx(
            'rounded border',
            product.media ? 'border-neutral-30' : 'border-transparent'
          )}
          src={
            product.media ||
            `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
          }
          alt={`product-${product.id}`}
        />
      </div>
      <div className="relative flex-1 flex flex-col justify-between">
        <BBBTooltip content={lineItem.title} show={lineItem.title.length > 30}>
          <div className="text-primary-main line-clamp-1">{lineItem.title}</div>
        </BBBTooltip>
        <div className="font-medium text-primary-main">
          <p className="line-clamp-1">
            {lineItem.quantity} x{' '}
            {getProductName(lineItem.product, lineItem.productVariant) || `-`}
          </p>
          {order.lineItems.length >= 1 && (
            <div className="flex justify-between text-sm">
              {expandedOrders[order.id] ? (
                order.lineItems.length - 1 === 0 ? (
                  <p className="text-primary-main">
                    {formatCurrency(order.lineItems[0].price, order.currency)}{' '}
                  </p>
                ) : (
                  <p className="text-primary-main">
                    {formatCurrency(lineItem.price, order.currency)}{' '}
                  </p>
                )
              ) : (
                <>
                  {order.lineItems.length - 1 === 0 ? (
                    <p className="text-neutral-50">1 product</p>
                  ) : (
                    <p className="text-neutral-50">
                      +{order.lineItems.length - 1} more products
                    </p>
                  )}
                  <p className="text-neutral-60">
                    Total:{' '}
                    <span className="text-primary-main">
                      {formatCurrency(order.total, order.currency)}{' '}
                    </span>
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
