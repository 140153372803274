import Skeleton from 'react-loading-skeleton';

import { BBBButtonTypes } from '@/components/BBBButton';
import ConnectShopifyButton from '@/components/ConnectShopifyButtons';
import useShopifyConnectModal from '@/hooks/shopify/useShopifyConnectModal';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { AppType } from '@/types/systemLogin';

export const ShopifyAwareWrapperDefaultAsType = 'div' as const;
export type ShopifyAwareWrapperDefaultAsType =
  typeof ShopifyAwareWrapperDefaultAsType;

export type ShopifyAwareWrapperOwnProps<E extends React.ElementType> = {
  appType: AppType;
  className?: string;
  onSuccessConnect?: () => void;
  lazyConnect?: boolean;
  children?: (val: {
    data: { domain: string } | null | undefined;
    connectMiddleware: (fn: (data: { domain: string }) => void) => void;
  }) => React.ReactNode;
  fallbackToChildren?: boolean;
  customThumbnail?: (connectBtn?: React.ReactNode) => React.ReactNode;
  as?: E;
  skipSocketDisconnectOnSuccess?: boolean;
} & Partial<BBBButtonTypes>;

type ShopifyAwareWrapperProps<E extends React.ElementType> =
  ShopifyAwareWrapperOwnProps<E> &
    Omit<React.ComponentProps<E>, keyof ShopifyAwareWrapperOwnProps<E>>;

function ShopifyAwareWrapper<
  E extends React.ElementType = ShopifyAwareWrapperDefaultAsType
>({
  children,
  appType,
  className,
  onSuccessConnect,
  lazyConnect,
  fallbackToChildren,
  customThumbnail,
  as,
  skipSocketDisconnectOnSuccess,
  ...otherProps
}: ShopifyAwareWrapperProps<E>) {
  const Tag = as || ShopifyAwareWrapperDefaultAsType;

  const { data, isInitialLoading } = useShopifyIntegrationByApp(appType);
  const { setShow } = useShopifyConnectModal();

  if (isInitialLoading) return <Skeleton width={50} height={10} />;

  if (!data) {
    if (fallbackToChildren) {
      return (
        <Tag {...otherProps}>
          {children?.({
            data,
            connectMiddleware: (fn: (data: { domain: string }) => void) => {
              if (!data) {
                setShow({
                  appType,
                  onSuccess: fn,
                  skipSocketDisconnectOnSuccess,
                });
              } else {
                fn(data);
              }
            },
          })}
        </Tag>
      );
    }

    if (!lazyConnect) {
      return (
        <Tag {...otherProps}>
          {customThumbnail?.(
            <ConnectShopifyButton
              appType={appType}
              onSuccess={onSuccessConnect}
              {...otherProps}
            />
          ) || (
            <ConnectShopifyButton
              appType={appType}
              onSuccess={onSuccessConnect}
              {...otherProps}
            />
          )}
        </Tag>
      );
    }

    return null;
  }

  return (
    <Tag {...otherProps}>
      {children?.({
        data,
        connectMiddleware: (fn: (data: { domain: string }) => void) => {
          if (!data) {
            setShow({
              appType,
              onSuccess: fn,
            });
          } else {
            fn(data);
          }
        },
      })}
    </Tag>
  );
}

export default ShopifyAwareWrapper;
