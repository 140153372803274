import { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Link, Redirect, useParams } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';
import { sectionOptions } from './FormDetail';

import WhatsAppFormIcon from '@/assets/icons/WhatsAppFormIcon';
import { BBBButton, BBBCard, BBBContainer, BBBSpinner } from '@/components';
import SearchInput from '@/components/SearchInput';
import { useFormElements, useFormResponses } from '@/hooks/whatsApp/form';
import { formatDate2 } from '@/utils/common/date';
import { formatInternational } from '@/utils/common/phone';

export default function Responses() {
  const { id } = useParams<{ id: string }>();

  const formQuery = useFormElements(id);

  if (formQuery.status === 'loading') return <BBBSpinner />;

  if (formQuery.status === 'error' || formQuery.data.status === 'DRAFT')
    return <Redirect to={'/bitchat/whatsapp-form'} />;

  return <_Responses />;
}

function _Responses() {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [after, setAfter] = useState<string>();
  const [before, setBefore] = useState<string>();

  const [search, setSearch] = useState('');

  const { data } = useFormResponses(id, { search, after, before });

  const response = data?.data[0];
  const searchSplitted = search.split(' ');

  const highlightText = (text: string, searchTerms: string[]) => {
    if (!searchTerms || searchTerms.length === 0) return text;

    const regex = new RegExp(`(${searchTerms.join('|')})`, 'gi');
    return text.split(regex).map((part, index) =>
      searchTerms.some((term) => term.toLowerCase() === part.toLowerCase()) ? (
        <span key={index} className="bg-highlight-main">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <BBBContainer
      pageTitle="Response"
      pageDescription="See customer's WhatsApp form response"
      hasHeading
      rightComponent={
        !response ? undefined : (
          <div className="flex items-center gap-2">
            <ChevronLeft
              className={cx(
                'cursor-pointer',
                page === 1 && 'pointer-events-none opacity-50'
              )}
              onClick={() => {
                setPage((prev) => prev - 1);
                setBefore(response.id);
                setAfter(undefined);
              }}
            />
            {page}/{data.meta.count}
            <ChevronRight
              className={cx(
                'cursor-pointer',
                page === data.meta.count && 'pointer-events-none opacity-50'
              )}
              onClick={() => {
                setPage((prev) => prev + 1);
                setAfter(response.id);
                setBefore(undefined);
              }}
            />
          </div>
        )
      }
    >
      <SearchInput
        placeholder="Search response"
        value={search}
        onValueChange={(value) => {
          setSearch(value);
          setAfter(undefined);
          setBefore(undefined);
          setPage(1);
        }}
        containerClassName="mb-5"
      />
      {!response && search ? (
        <EmptyState
          descriptions={
            <>
              There is no result for{' '}
              <span className="font-bold">&quot;{search}&quot;</span>
            </>
          }
        />
      ) : !response ? (
        <EmptyState descriptions="There is no data" />
      ) : (
        <>
          <div className="flex items-center gap-2.5 mb-4">
            <div className="grow">
              <div className="text-neutral-50 mb-2 text-sm">Response from:</div>
              <div className="text-neutral-60 font-bold">
                {formatInternational(response.clientNumber)}
              </div>
            </div>
            {response.destination && response.clientNumber && (
              <Link
                to={`/bitchat/livechat?sources=WHATSAPP_META&chatId=${response.clientNumber}&messageId=${response.destination}`}
              >
                <BBBButton variant="secondary" text="Open in chat" />
              </Link>
            )}
          </div>
          {response.element.map((response) => {
            const sectionData = sectionOptions.find(
              (opt) => opt.name === response.type
            );

            const Icon = sectionData!.icon;

            return (
              <BBBCard
                key={response.id}
                className="mb-4 flex items-center gap-5"
              >
                {typeof Icon === 'function' ? <Icon size={40} /> : Icon}
                <div className="grow">
                  <div className="text-sm text-neutral-40 font-medium mb-1.5">
                    {response.title}
                  </div>
                  <div className="text-sm text-neutral-60">
                    {response.type === 'DatePicker'
                      ? formatDate2(response.answer)
                      : highlightText(response.answer, searchSplitted)}
                  </div>
                </div>
              </BBBCard>
            );
          })}
        </>
      )}
    </BBBContainer>
  );
}

function EmptyState({ descriptions }: { descriptions: React.ReactNode }) {
  return (
    <BBBCard>
      <div className="min-h-[25rem] flex flex-col justify-center gap-2 items-center">
        <div className="rounded-full bg-neutral-30 p-2.5 flex items-center justify-center">
          <WhatsAppFormIcon />
        </div>
        <p className="text-neutral-50">{descriptions}</p>
      </div>
    </BBBCard>
  );
}
