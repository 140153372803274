import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

const useCustomer = (id: string) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['customer', id, activeCompany],
    () => services.customer.customer.getCustomer(id, activeCompany!),
    {
      enabled: !!id,
    }
  );
};

export default useCustomer;
