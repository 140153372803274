import api from '@/config/api';

export type LoginSetting = {
  type: string;
  active: boolean;
  mandatory?: boolean;
};

export default {
  getLoginSetting: (domain: string | undefined) =>
    api.bitLogin
      .get<LoginSetting[]>(`/login/settings/${domain}`)
      .then((res) => res.data),
  upsertLoginSetting: (
    domain: string | undefined,
    payload: UpsertLoginSettingPayload
  ) =>
    api.bitLogin
      .post(`/login/settings/${domain}`, {
        ...payload,
      })
      .then((res) => res.data),
};

export type UpsertLoginSettingPayload = Partial<LoginSetting>;
