import React, { useState } from 'react';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import { MergeStep1Type, MergeType } from './GeneralInfoCard';

import AlertIcon from '@/assets/icons/AlertIcon';
import ArrowRight from '@/assets/icons/ArrowRight';
import SearchIcon from '@/assets/icons/SearchIcon';
import { BBBModal, BBBSelect } from '@/components';
import useResponsive from '@/hooks/common/useResponsive';
import useInfiniteCustomers from '@/hooks/customers/customer/useInfiniteCustomers';
import { CustomerWithAssociation } from '@/types/customers/association';
import { formatDisplayName } from '@/utils/customers';

type Props = {
  merge: MergeStep1Type;
  setMerge: React.Dispatch<React.SetStateAction<MergeType | undefined>>;
};

function MergeStep1({ merge, setMerge }: Props) {
  const [search, setSearch] = useState('');
  const isTablet = useResponsive('lg');
  const isMobile = useResponsive('sm');

  const {
    data: infiniteCustomersData,
    hasNextPage,
    fetchNextPage,
    isLoading: loading,
  } = useInfiniteCustomers({
    search,
  });

  const customersData = infiniteCustomersData?.pages
    .flatMap((page) =>
      page.data.map((customer) => ({
        ...customer,
        label: (
          <>
            <div className="font-semibold text-primary-main">
              {formatDisplayName(
                customer.phoneNumber,
                customer.firstName,
                customer.lastName
              )}
            </div>
            {(customer.phoneNumber ||
              customer.instagramId ||
              customer.email) && (
              <div className="mt-1 text-sm text-neutral-50">
                {customer.phoneNumber ?? customer.instagramId ?? customer.email}
              </div>
            )}
          </>
        ),
        label2: (
          <div className="font-semibold text-primary-main">
            {formatDisplayName(
              customer.phoneNumber,
              customer.firstName,
              customer.lastName
            )}
          </div>
        ),
      }))
    )
    .filter((customer) => customer.id !== merge.data?.id);

  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerWithAssociation & {
      label: React.ReactNode;
      label2: React.ReactNode;
    }
  >();

  return (
    <BBBModal
      title="Merge Customers"
      subtitle="Merge customers across channels to create a single view of their activity"
      size={!isTablet ? '3xl' : undefined}
      footer
      cancelText="Discard"
      submitText="Next"
      show={merge?.step === 1}
      handleSave={() =>
        setMerge({ step: 2, source: selectedCustomer!, target: merge.data! })
      }
      onHide={() => setMerge(undefined)}
      disableSave={!selectedCustomer || !merge.data}
    >
      <div className="flex items-center gap-1 lg:gap-8 mb-7">
        <div className="flex-1 rounded-2xl border border-neutral-30 px-2 lg:px-5 py-6">
          <div className="flex justify-center">
            <ProfilePicture
              imageUrl={selectedCustomer?.imageUrl}
              firstName={selectedCustomer?.firstName}
              lastName={selectedCustomer?.lastName}
              containerClassName="mb-5"
            />
          </div>
          <BBBSelect
            options={customersData}
            optionLabel="label"
            optionValue="id"
            selectedLabel="label2"
            dropdownOptionClassName="line-clamp-none"
            isPaginated
            hasMore={!!hasNextPage}
            fetchNext={fetchNextPage}
            value={selectedCustomer}
            onValueChange={(opt) => setSelectedCustomer(opt!)}
            isSearchable
            search={search}
            onChangeSearch={setSearch}
            loading={loading}
          />
        </div>
        <div className="flex-none">
          <ArrowRight size={isMobile ? 37 : undefined} />
        </div>
        <div className="flex-1 rounded-2xl border border-neutral-30 px-2 lg:px-5 py-6">
          <div className="flex justify-center">
            <ProfilePicture
              imageUrl={merge.data?.imageUrl}
              firstName={merge.data?.firstName}
              lastName={merge.data?.lastName}
              containerClassName="mb-5"
            />
          </div>
          <div className="text-sm lg:text-base text-center mb-1 text-neutral-60 font-semibold">
            {formatDisplayName(
              merge.data?.phoneNumber,
              merge.data?.firstName,
              merge.data?.lastName
            )}
          </div>
          <div className="text-neutral-50 text-center line-clamp-1 text-xs lg:text-base">
            {merge.data?.email ??
              merge.data?.instagramId ??
              merge.data?.phoneNumber}
          </div>
        </div>
      </div>
      <div className="text-center text-primary-main text-2xl mb-7">
        <span className="font-semibold">
          {selectedCustomer
            ? formatDisplayName(
                selectedCustomer?.phoneNumber,
                selectedCustomer?.firstName,
                selectedCustomer?.lastName
              )
            : `____`}
        </span>{' '}
        will be merged into{' '}
        <span className="font-semibold">
          {formatDisplayName(
            merge.data?.phoneNumber,
            merge.data?.firstName,
            merge.data?.lastName
          )}
        </span>
      </div>
      <div className="bg-neutral-20 rounded-2xl p-6 flex items-center gap-6">
        <div className="flex-none">
          <AlertIcon />
        </div>
        <ul className="list-disc">
          <li>
            Timeline activity for the former customer record will be deleted in
            the new record
          </li>
          <li>This action cannot be undone.</li>
        </ul>
      </div>
    </BBBModal>
  );
}

export default MergeStep1;
