import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryMeta,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import ErrorPages from 'pages/Error/Error';

import { env } from '@/config/env';
import { rootStore } from '@/stores';
import { toast } from '@/utils/common/toast';

type Props = {
  children: React.ReactNode;
};

const globalErrorHandler = (err: unknown, queryMeta?: QueryMeta) => {
  if (queryMeta && queryMeta.disableToast) {
    return;
  }

  if (axios.isAxiosError(err)) {
    if (err.response) {
      if (err.response.status >= 400 && err.response.status < 500) {
        toast.error(err.response.data.message || 'bad request');
      } else {
        toast.error('internal server error');
      }
    } else {
      const message =
        err.message === 'Network Error' ? 'internal server error' : err.message;
      toast.error(message);
    }
  }
};

const errorBoundaryHandler = (err: unknown): boolean => {
  const isErrorAxios = axios.isAxiosError(err);

  if (!isErrorAxios) return true;

  if (err.response?.status && err.response.status > 500) {
    return true;
  }

  return false;
};

const queryCache = new QueryCache({
  onError: (err, query) => {
    globalErrorHandler(err, query.meta);
  },
});
const mutationCache = new MutationCache({
  onError: (err, variables, context, mutation) => {
    globalErrorHandler(err, mutation.meta);
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: errorBoundaryHandler,
      refetchOnReconnect: true,
      retry: false,
      meta: {
        disableToast: true,
      },
    },
    mutations: {
      useErrorBoundary: errorBoundaryHandler,
    },
  },
  queryCache,
  mutationCache,
});

function AppProvider({ children }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      {env.REACT_APP_NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Provider store={rootStore}>
          <Sentry.ErrorBoundary fallback={ErrorPages}>
            {children}
          </Sentry.ErrorBoundary>
        </Provider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

export default AppProvider;
