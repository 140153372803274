import ShopifyLogo from '@/assets/icons/ShopifyIcon2';
import { BBBCard } from '@/components';
import ConnectShopifyButton from '@/components/ConnectShopifyButtons';

function AuthorizeShopify() {
  return (
    <BBBCard>
      <div className="w-full h-full py-40 flex flex-col items-center gap-6">
        <div className="px-8 py-8 rounded-full border-[1px] border-[#DDDDDD]">
          <ShopifyLogo />
        </div>
        <div className="flex flex-col items-center justify-center text-primary-main gap-2">
          <h4 className="font-bold text-2xl">Connect an account</h4>
          <p className="font-normal text-xl">
            Connect your Shopify account to activate this integration
          </p>
        </div>
        <ConnectShopifyButton appType="BITAI" />
      </div>
    </BBBCard>
  );
}

export default AuthorizeShopify;
