import { RadioGroup } from '@headlessui/react';
import { twMerge as cx } from 'tailwind-merge';

type Options = {
  value: string;
  label: string;
  description?: string;
}[];

export type BBBRadioProps = {
  containerClassName?: string;
  vertical?: boolean;
  options: Options;
  value: string | null;
  onChange: (value: string) => void;
  label?: string;
  labelClassName?: string;
  optionsClassName?: string;
  disabled?: boolean;
} & Omit<JSX.IntrinsicElements['div'], 'onChange'>;

const BBBRadio = ({
  containerClassName,
  vertical,
  options,
  value,
  onChange,
  label,
  optionsClassName,
  labelClassName,
  disabled,
}: BBBRadioProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={cx('flex flex-col gap-3 cursor-pointer', containerClassName)}
      disabled={disabled}
    >
      <RadioGroup.Label className={cx('text-primary-main', labelClassName)}>
        {label}
      </RadioGroup.Label>
      <div
        className={cx(
          'flex gap-6',
          vertical && 'flex-col gap-2',
          optionsClassName
        )}
      >
        {options.map((item) => (
          <RadioGroup.Option key={item.value} value={item.value}>
            {({ checked }) => {
              return (
                <div className={cx('flex items-center')}>
                  <div className="relative w-[20px] h-[20px] mr-2 rounded-full border border-[#535353]">
                    {checked && (
                      <div className="absolute inset-0 w-[10px] h-[10px] rounded-full bg-[#535353] m-auto" />
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className={cx('text-primary-main', labelClassName)}>
                      {item.label}
                    </div>
                    {item.description && (
                      <div className="text-xs font-medium text-neutral-40">
                        {item.description}
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default BBBRadio;
