import { useMemo } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBRenderHtml } from '@/components';
import { useStartChat } from '@/hooks/whatsApp/chat';
import { MentionMessage } from '@/types/whatsApp/v3';
import { formatMention, toWhatsAppFormat } from '@/utils/bitCRM';

type Props = {
  body: string;
  mentionedContacts: MentionMessage[];
  isQuotedLevel?: boolean;
};

export default function ChatBody({
  body,
  mentionedContacts,
  isQuotedLevel,
}: Props) {
  const memoMention = useMemo(
    () => formatMention(body, mentionedContacts),
    [body, mentionedContacts]
  );

  const { mutate: startChat, isLoading: loadingStartChat } = useStartChat();

  return (
    <div>
      {memoMention.map((chat, index) =>
        chat.type === 'mention' ? (
          <span
            className={cx(
              'link',
              loadingStartChat && 'opacity-50 pointer-events-none'
            )}
            onClick={() => {
              const data = JSON.parse(chat.data);

              startChat({ conversationId: data.number, sources: data.source });
            }}
            key={index}
          >
            {JSON.parse(chat.data).label}
          </span>
        ) : (
          <span
            className={cx(
              isQuotedLevel ? 'line-clamp-1  text-neutral-50' : 'my-1'
            )}
            key={index}
          >
            <BBBRenderHtml
              content={toWhatsAppFormat(
                isQuotedLevel
                  ? chat.data.length > 50
                    ? `${chat.data.slice(0, 50)}...`
                    : chat.data
                  : chat.data,
                undefined
              )}
            />
          </span>
        )
      )}
    </div>
  );
}
