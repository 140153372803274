import { SocialLoginProvider } from 'types/bitLogin/v2';

export const knowledgeBase: Partial<Record<SocialLoginProvider, string>> = {
  GOOGLE: `https://bitbybit.tawk.help/article/set-up-google-oauth-for-bitlogin`,
  MICROSOFT: `https://bitbybit.tawk.help/article/set-up-microsoft-oauth-for-bitlogin`,
  FACEBOOK: `https://bitlogin.tawk.help/article/set-up-facebook-oauth-for-bitlogin`,
  APPLE: `https://bitlogin.tawk.help/article/set-up-apple-oauth-for-bitlogin`,
  TWITTER: `https://bitbybit.tawk.help/article/set-up-twitter-x-oauth-for-bitlogin`,
  AMAZON: `https://bitbybit.tawk.help/article/set-up-amazon-oauth-for-bitlogin`,
};
