import { useHistory } from 'react-router';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  GetAutomationLogParams,
  GetAutomationMessageLogParams,
  GetScenarioListParams,
} from 'api/services/bitCRM/v2/automation';
import axios from 'axios';
import api from 'config/api';
import { DEMO_ID } from 'constants/bitCRM/automation';
import { useActiveCompany } from 'hooks/rtk/selector';
import { AutomationFormSchema } from 'pages/BitCRM/Automation/New/validation';
import {
  Automation,
  AutomationSchedule,
  AutomationTrigger,
} from 'types/bitCRM/automation';
import { formatPhonePayload } from 'utils/common/phone';
import { toast } from 'utils/common/toast';

import services from '@/api/services';
import { TemplateBodyParameters } from '@/types/bitCRM/template';

export const useAutomationLists = ({ page, size }: GetScenarioListParams) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      'bitcrm-automation',
      activeCompany,
      {
        page,
        size,
      },
    ],
    () =>
      services.bitCRM.v2.automation.getScenarioList(activeCompany, {
        page,
        size,
      })
  );
};

export const useAutomation = (id: string) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-template', id],
    () =>
      api.bitCRM.v2
        .get<Automation>(`/automation/${activeCompany}/${id}`)
        .then((res) => res.data),
    {
      enabled: !!id && id !== 'new',
    }
  );
};

export const useAutomationLog = (
  automationId: string | undefined,
  params: GetAutomationLogParams
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-automation-log', activeCompany, automationId, params],
    () =>
      services.bitCRM.v2.automation.getAutomationLog(
        activeCompany!,
        automationId,
        params
      ),
    {
      keepPreviousData: true,
      enabled: !!automationId,
    }
  );
};

export const useAutomationMessageLog = (
  automationId: string | undefined,
  params: GetAutomationMessageLogParams
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitcrm-automation-message-log', activeCompany, automationId, params],
    () =>
      services.bitCRM.v2.automation.getAutomationMessageLog(
        activeCompany!,
        automationId,
        params
      ),
    {
      keepPreviousData: true,
      enabled: !!automationId,
    }
  );
};

export const useDeleteAutomation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const activeCompany = useActiveCompany();

  return useMutation(
    (id: string) => api.bitCRM.v2.delete(`/automation/${activeCompany}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitcrm-automation']);
        history.push('/bitcrm/automation');
        toast.success('Scenario has been deleted');
      },
    }
  );
};

export const useToggleAutomation = () => {
  const activeCompany = useActiveCompany();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      data,
    }: {
      data: { id: string; status: 'ACTIVE' | 'INACTIVE' };
      meta: {
        size: number;
        page: number;
      };
    }) =>
      api.bitCRM.v2.post(`/automation/${activeCompany}/${data.id}/toggle`, {
        status: data.status,
      }),
    {
      onError: (err, newTodo, context) => {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          toast.error(
            `Automation still in draft mode. Please complete it first before turning on`
          );
        }
      },
      onSuccess: (_, payload) => {
        toast.success(
          `Sucesfully ${
            payload.data.status === 'ACTIVE' ? 'activate' : 'disabled'
          } automation`
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['bitcrm-automation']);
      },
    }
  );
};

export const useCreateOrUpdateAutomation = () => {
  const activeCompany = useActiveCompany();
  const history = useHistory();

  return useMutation(
    async ({
      leaveCb,
      ...payload
    }: Partial<AutomationFormSchema> & {
      id: string;
      isDraft?: boolean;
      templateId?: string;
      templateParams?: string[];
      templateParams2?: TemplateBodyParameters[];
      leaveCb?: () => void;
    }) => {
      const {
        id,
        orderStatus,
        sourceType,
        advanceSchedule,
        description: message,
        scheduledDays,
        advanceScheduleWhen,
        advanceScheduleType,
        advanceScheduleTime,
        scheduledFrom,
        scheduledUntil,
        ccNumber,
        imageUrl,
        storeChannel,
        active,
        ...rest
      } = payload;

      const from = advanceSchedule ? scheduledFrom?.value : '00:00';
      const until = advanceSchedule ? scheduledUntil?.value : '23:59';

      const schedule: Partial<AutomationSchedule> = {
        ...(scheduledDays && {
          type: scheduledDays.value,
          timezone: `GMT+${new Date().getTimezoneOffset() / -60}`,
          days: [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
          ],
          ...(scheduledDays.value !== 'immediately' &&
          advanceScheduleTime &&
          advanceScheduleWhen &&
          advanceScheduleType
            ? {
                advanced: {
                  when: advanceScheduleWhen.value,
                  time: advanceScheduleTime,
                  type: advanceScheduleType.value,
                },
              }
            : {}),
          ...{ from, until },
        }),
        from,
        until,
      };

      return api.bitCRM.v2.post('/automation', {
        ...rest,
        schedule,
        message,
        sourceType: sourceType?.name,
        ...(sourceType?.name !== 'Demo' && { shopifyEvent: orderStatus }),
        ...(rest.type === 'WHATS_APP' ||
        rest.type === 'SANDBOX_WHATSAPP' ||
        rest.type === 'WHATSAPP_META'
          ? {
              whatsAppMessage: {
                phoneReceiver: formatPhonePayload(ccNumber),
                ...(imageUrl && {
                  imageUrl: imageUrl?.[0]?.remoteUrl,
                }),
              },
              waGroup: false,
            }
          : {}),
        companyId: activeCompany,
        active,
        ...(storeChannel && {
          channelList: storeChannel.map((store) => store.value),
        }),
        ...(id !== 'new' && {
          id,
        }),
      });
    },
    {
      onSuccess: (_, payload) => {
        const toastMessage =
          payload.sourceType?.name === 'Demo'
            ? `Demo${
                payload.isDraft ? ' draft' : ''
              } scenario has been triggered`
            : `Scenario${payload.isDraft ? 'draft ' : ' '}has been ${
                payload.id !== 'new' ? 'edited' : 'created'
              }`;

        toast.success(toastMessage);

        if (payload.leaveCb) {
          payload.leaveCb();
        } else {
          history.push('/bitcrm/automation');
        }
      },
      onError: () => {
        toast.error('unknown error occured');
      },
    }
  );
};

export const useJubelioChannels = (
  options?: Omit<
    UseQueryOptions<
      { label: string; value: number }[],
      unknown,
      { label: string; value: number }[],
      (string | number)[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-jubelio-channels', activeCompany],
    () => services.bitCRM.v2.automation.getJubelioChannels(activeCompany),
    {
      meta: { disableToast: true },
      ...options,
    }
  );
};

export const useIntegrationTriggers = (integrationId: number | undefined) =>
  useQuery(
    ['bitcrm-integrations-triggers', integrationId],
    () =>
      api.bitCRM.v2
        .get<{ data: AutomationTrigger[] }>(`/integrations/triggers`, {
          params: {
            integrationId,
          },
        })
        .then((res) => res.data.data),
    {
      enabled: !!integrationId && integrationId !== DEMO_ID,
    }
  );
