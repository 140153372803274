import { ReactNode } from 'react';
import { Plus } from 'react-feather';

import { BBBButton } from '@/components';

export default function AddItem({
  label = 'Add a reply',
  className,
  onClick,
  disabled,
}: {
  label?: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <BBBButton
      variant="secondary"
      text={
        <div className="flex items-center gap-2">
          {label} <Plus size={16} />
        </div>
      }
      onClick={onClick}
      className={className}
      disabled={disabled}
    />
  );
}
