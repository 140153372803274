import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { isEqual } from 'lodash-es';
import Cookies from 'universal-cookie';
import * as yup from 'yup';

import CopyIcon from '@/assets/icons/CopyIcon';
import ShareIcon from '@/assets/icons/ShareIcon';
import { BBBButton, BBBCard, BBBModal, BBBTextInput } from '@/components';
import { bitlinkProxyUrl } from '@/constants/api';
import { forbiddenDomains } from '@/constants/bitLink/bitLink';
import { socialShare } from '@/constants/bitLink/header/socialShare';
import useBranding from '@/hooks/bitLink/appearance/useBranding';
import useUpdateProxyLink from '@/hooks/bitLink/links/useUpdateProxyLink';
import useRedirect from '@/hooks/bitLink/shopify/useRedirect';
import useShop from '@/hooks/bitLink/shopify/useShop';
import useUpsertRedirect from '@/hooks/bitLink/shopify/useUpsertRedirect';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { toast } from '@/utils/common/toast';
import { formatUrlSlash } from '@/utils/common/url';

const schema = yup.object().shape({
  link: yup.string().required('Please enter a valid link'),
});

type Schema = {
  link: string;
};

export default function HeaderLink() {
  const isMobile = useResponsive('sm');
  const { data: shopData, isInitialLoading: isLoadingShop } = useShop();
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showShareLink, setShowShareLink] = useState(false);

  const { data: redirectData, isInitialLoading: isLoadingRedirect } =
    useRedirect();

  const { data, isInitialLoading } = useBranding();
  const { toggle } = useConfirmationBanner();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useCustomForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      link: '',
    },
  });

  const domain = useMemo(() => {
    let url = `${bitlinkProxyUrl}`;
    if (redirectData?.length) {
      url = `${shopData?.domain}`;
    }
    return `${url.replace(/https?:\/\//, '')}/`;
  }, [redirectData?.length, shopData?.domain]);

  const originalLink = useMemo(() => {
    let validLink = `${data?.link || ''}`;
    if (redirectData?.length) {
      validLink = `${redirectData?.[0].path.slice(1)}`;
    }
    return validLink;
  }, [data?.link, redirectData]);

  const dataFromApi = useMemo<Schema>(
    () => ({
      link: originalLink || '',
    }),
    [originalLink]
  );

  const { mutate: updateLink, isLoading: isLoadingUpdateProxy } =
    useUpdateProxyLink();

  const errorProxyUrl = forbiddenDomains.includes(watch('link'));

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  useEffect(() => {
    function onSubmit(data: Schema) {
      updateLink(
        {
          link: encodeURIComponent(data?.link),
          redirectId: redirectData?.[0]?.id.toString(),
        },
        {
          onError: (err: any) => {
            if (err?.response?.status === 400) {
              setError('link', {
                message:
                  'Sorry, this URL is already taken. Please choose a different URL name.',
              });
            }
          },
        }
      );
    }

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('bitlink-header', {
      show: !isFormEqual,
      variant: isLoadingUpdateProxy ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: reset,
      isCancelable: true,
      onAccept: () => {
        if (errorProxyUrl) {
          return setError('link', {
            message:
              'Sorry, this URL is not allowed. Please choose a different URL name.',
          });
        } else {
          handleSubmit(onSubmit)();
        }
      },
    });
  }, [
    dataFromApi,
    errorProxyUrl,
    handleSubmit,
    isLoadingUpdateProxy,
    redirectData,
    reset,
    setError,
    toggle,
    updateLink,
    watch(),
  ]);

  useEffect(() => {
    if (redirectData && !redirectData.length) {
      setShowPromptModal(true);
    } else {
      setShowPromptModal(false);
    }
  }, [redirectData]);

  return (
    <>
      {showPromptModal && (
        <PromptModal
          onShow={(show) => (show && showPromptModal ? true : false)}
          onHide={() => setShowPromptModal(false)}
        />
      )}
      <div
        className="relative bg-white py-6 px-10"
        id="header-links"
        onMouseLeave={() => setShowShareLink(false)}
      >
        <div className="flex flex-row items-center gap-6">
          <span className="whitespace-nowrap">{isMobile ? null : 'URL:'}</span>
          <div className="grow">
            {isInitialLoading || isLoadingShop || isLoadingRedirect ? (
              <Skeleton width={300} height={20} />
            ) : (
              <BBBTextInput
                isHookForm
                control={control}
                controlName="link"
                isFixed
                fixedLabel={domain}
                placeholder="brandname"
                containerClassname="!mb-0 self-center"
                error={errors?.link?.message}
              />
            )}

            {isMobile && (
              <div className="flex gap-2 mt-4">
                <BBBButton
                  text="Copy link"
                  variant="secondary"
                  className="flex-1"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(`https://${domain}${originalLink}`)
                      .then(() => {
                        toast.success('Successfully copied to clipboard!');
                      });
                  }}
                  disabled={forbiddenDomains.includes(originalLink)}
                />
                <BBBButton
                  text="See Preview"
                  variant="secondary"
                  className="flex-1"
                  onClick={() =>
                    window.open(`https://${domain}${originalLink}`, '_blank')
                  }
                  disabled={forbiddenDomains.includes(originalLink)}
                />
              </div>
            )}
          </div>
          {isMobile ? null : (
            <>
              <CopyIcon
                cursor="pointer"
                color="#262627"
                size={22}
                onClick={() =>
                  navigator.clipboard
                    .writeText(`https://${domain}${originalLink}`)
                    .then(() => {
                      toast.success('Link copied!');
                    })
                }
              />
              <div
                className="cursor-pointer"
                onMouseEnter={() => setShowShareLink(true)}
              >
                <ShareIcon />
                {showShareLink && (
                  <ShareLink domain={domain} originalLink={originalLink} />
                )}
              </div>
              <BBBButton
                variant="secondary"
                text="See Preview"
                onClick={() =>
                  window.open(`https://${domain}${originalLink}`, '_blank')
                }
                disabled={forbiddenDomains.includes(originalLink)}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

function ShareLink({
  domain,
  originalLink,
}: {
  domain: string;
  originalLink: string;
}) {
  return (
    <AnimatePresence>
      <motion.div
        className="absolute top-full right-4 z-[200] w-96"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
        exit={{ opacity: 0 }}
      >
        <BBBCard>
          <p className="text-xl text-center text-primary-main">Share link</p>
          <div className="my-3.5 flex gap-2 items-center justify-evenly">
            {socialShare(`https://${domain}${originalLink}`).map((social) => (
              <div
                key={social.name}
                className="p-2.5 flex flex-col items-center justify-center gap-2.5 hover:bg-[#F0F0F0] rounded-xl cursor-pointer"
                onClick={() => window.open(social.url, '_blank')}
              >
                {social.logo}
                <p className="text-sm text-primary-main px-2.5">
                  {social.label}
                </p>
              </div>
            ))}
          </div>
          <div
            className="w-full bg-neutral-30 rounded-3xl item-center flex gap-2.5 px-4 py-3"
            onClick={() =>
              navigator.clipboard
                .writeText(`https://${domain}${originalLink}`)
                .then(() => {
                  toast.success('Link copied!');
                })
            }
          >
            <p className="flex-1 text-primary-main text-center text-sm whitespace-nowrap">
              {`https://${domain}${originalLink}`
                .slice(0, 40)
                .concat(
                  `https://${domain}${originalLink}`.length > 40 ? '...' : ''
                )}
            </p>
            <CopyIcon size={20} color="#262627" />
          </div>
        </BBBCard>
      </motion.div>
    </AnimatePresence>
  );
}

type PromptModalProps = {
  onHide?: () => void;
  onShow: (show?: boolean) => boolean;
};

const cookies = new Cookies(null, { path: '/' });

function PromptModal({ onHide, onShow }: PromptModalProps) {
  const MODAL_PROMPT_ID = 'prompt-modal';
  const [link, setLink] = useState('');

  const { mutate: upsertRedirect, isLoading } = useUpsertRedirect();
  const { data: shopData } = useShop();

  const cookieValues = cookies.get(MODAL_PROMPT_ID);

  useEffect(() => {
    if (!cookies.get(MODAL_PROMPT_ID)) {
      cookies.set(
        MODAL_PROMPT_ID,
        JSON.stringify({
          showAt: dayjs().toISOString(),
        })
      );
    }
  }, []);

  return (
    <BBBModal
      footer
      cancelText="Remind me later"
      show={onShow?.(
        cookieValues && !dayjs().isAfter(dayjs(cookieValues.showAt))
          ? false
          : true
      )}
      disableSave={isLoading}
      onCancel={() => {
        cookies.set(
          MODAL_PROMPT_ID,
          JSON.stringify({
            showAt: dayjs().add(1, 'days').toISOString(),
          })
        );
        onHide?.();
      }}
      handleSave={() => upsertRedirect({ link })}
      onHide={onHide}
      title="Create URL with your own domain"
    >
      <div className="mb-3">
        It looks like you have integrated your store with shopify. With bitlink,
        you can choose your own custom domain for your shopify store!
      </div>
      <BBBTextInput
        value={link}
        onChange={({ target: { value } }) => setLink(value)}
        containerClassname="mb-0"
        placeholder="myawesomelink"
        isFixed
        fixedLabel={formatUrlSlash(shopData?.domain)}
      />
      {link && (
        <div className="text-sm text-gray-500">
          Link will be available as{' '}
          <span className="underline">
            {formatUrlSlash(shopData?.domain)}
            {link}
          </span>
        </div>
      )}
    </BBBModal>
  );
}
