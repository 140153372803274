import { AiTag, Tags, VisitedSite } from 'types/customers';

export const sourceOptions = [
  {
    label: 'Add individual',
    value: 'bitbybit_add_individual',
  },
  {
    label: 'Bulk import',
    value: 'bitbybit_bulk_import',
  },
  {
    label: 'Whatsapp inbound',
    value: 'bitbybit_whatsapp_inbound',
  },
];

export const filters = (
  [
    { label: 'First name', value: 'firstName' },
    { label: 'Last name', value: 'lastName' },
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phoneNumber' },
    { label: 'Customer tags', value: 'customerTags' },
    { label: 'AI tags', value: 'aiTags' },
    { label: 'Visited', value: 'visited' },
  ] as const
).filter(
  (option) =>
    option.value === 'customerTags' ||
    option.value === 'aiTags' ||
    option.value === 'visited'
);

export const _operators = [
  {
    label: 'Have',
    value: 'equals',
  },
  {
    label: 'Not have',
    value: 'notEquals',
  },
  {
    label: 'in',
    value: 'in',
  },
  {
    label: 'not in',
    value: 'notIn',
  },
  {
    label: 'contains',
    value: 'contains',
  },
  {
    label: 'starts with',
    value: 'startsWith',
  },
  {
    label: 'ends with',
    value: 'endsWith',
  },
] as const;

export const operators = _operators.filter(
  (operator) => operator.value === 'equals' || operator.value === 'notEquals'
);

export const conjunctions = ['AND', 'OR', 'NOT'] as const;

export type Conjunctions = typeof conjunctions[number];
export type Filter = typeof filters[number];
export type Operator = typeof _operators[number];

export type ConditionFilter = {
  key: Filter | null;
  operator: Operator | null;
  value: string | Tags | Tags[] | AiTag | VisitedSite | null;
  parentId?: string;
  blockId?: string;
  id: string;
};

export type ConditionConjunction = {
  conjunction: Conjunctions;
  parentId?: string;
  id: string;
  blockId?: string;
};

export type Condition = ConditionFilter | ConditionConjunction;

export type Block = { conjunctionId: string; id: string };
export type Errors = {
  name: boolean;
  conditions:
    | boolean
    | {
        id: string;
        key: Filter['value'] | undefined;
      }[];
};
