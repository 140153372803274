import { _PricingInfo } from './PricingInfo';

import { PricingFeatureNames } from '@/api/services/pricing';
import { BBBPercentageBar } from '@/components';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { usePricingFeatures } from '@/hooks/pricing/v2/pricing';

const mapFeatureToTip: Partial<Record<PricingFeatureNames, string>> = {
  conversation:
    'Conversations limit is the maximum number of tickets that your account can handle in a month. Limit will refresh every month.',
  ai_tagging:
    'AI tagging is a powerful feature that automatically generates tags for your customer tickets.',
};

function BitChatStatus() {
  const { data: pricingData } = usePricingByApp('BITCHAT');
  const { data: pricingFeatures } = usePricingFeatures({ app: 'BITCHAT' });

  const { data: whatsappBusinessIntegration } =
    useWhatsappBusinessIntegration();
  const { data: whatsappCloudIntegration } = useWhatsappCloudIntegration();

  if (!pricingData || !pricingFeatures) return null;

  return (
    <_PricingInfo
      app="BITCHAT"
      subscriptionPlan={pricingData.pricingModule.label || undefined}
    >
      {pricingFeatures.map((feature) => (
        <BBBPercentageBar
          usage={feature.usage}
          limit={
            feature.pricingFeature.pricingFeatureDetails.find(
              (detail) => detail.pricingName === pricingData.pricingName
            )?.limit ?? 0
          }
          description={feature.pricingFeature.label}
          className="mb-4"
          key={feature.featureName}
          hasTip={!!mapFeatureToTip[feature.featureName]}
          tip={mapFeatureToTip[feature.featureName]}
        />
      ))}

      <div className="text-[#9E9E9E] text-xs mb-4">
        WhatsApp{' '}
        {whatsappBusinessIntegration?.status === 'CONNECTED' ||
        whatsappCloudIntegration?.status === 'CONNECTED'
          ? 'connected'
          : 'not connected'}
      </div>
    </_PricingInfo>
  );
}

export default BitChatStatus;
