import React, { ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { AnimationControls, motion, useAnimation } from 'framer-motion';
import UnauthorizedScreen from 'pages/401';
import Sidebar from './Sidebars/Sidebar';

import { BBBSpinner } from '@/components';
import BBBConfirmationBanner from '@/components/BBBConfirmationBanner/BBBConfirmationBanner';
import { mapUrlToAppType } from '@/constants/systemLogin/urlRedirect';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useVerifyEmailStatus from '@/hooks/auth/useVerifyEmailStatus';
import useInformationBanner from '@/hooks/common/useInformationBanner';
import { useAppSelector } from '@/hooks/rtk/store';
import { AuthorityKey } from '@/types/systemLogin';

export default function AppLayout({ children }: { children: React.ReactNode }) {
  const loadingChangeCompany = useAppSelector(
    (s) => s.auth.loadingChangeCompany
  );

  const [headerSlotRef, setHeaderSlotRef] = useState<HTMLDivElement | null>(
    null
  );
  const animateControl = useAnimation();

  if (loadingChangeCompany)
    return (
      <div className="bg-neutral-20 flex flex-col items-center justify-center min-h-full">
        <div>
          <BBBSpinner height={24} />
        </div>
        <div>Switching company</div>
      </div>
    );

  return (
    <div className="flex flex-col min-h-full relative" id="app">
      <div id="header-slot" ref={setHeaderSlotRef} />
      <div className="grow relative">
        <div className="absolute inset-0 flex">
          <Sidebar
            headerSlotRef={headerSlotRef}
            animateControl={animateControl}
          />
          <Content animateControl={animateControl}>{children}</Content>
        </div>
      </div>
    </div>
  );
}

type ContentProps = {
  children: React.ReactNode;
  animateControl: AnimationControls;
};

function Content({ children, animateControl }: ContentProps) {
  const animateRootContentControl = useAnimation();

  return (
    <motion.div
      className="grow min-h-full flex-col flex bg-neutral-20 relative"
      style={{
        //@ts-expect-error overlay is deprecated, but we will use this until found another solution in the future
        overflowY: 'overlay',
      }}
      id="content"
      animate={animateControl}
    >
      <div id="banner-section">
        <BBBConfirmationBanner
          animateRootContentControl={animateRootContentControl}
        />
      </div>
      <div className="grow">
        <RootContent animateRootContentControl={animateRootContentControl}>
          {children}
        </RootContent>
      </div>
      <div id="sticky-footer-section" />
    </motion.div>
  );
}

type RootContentProps = {
  children: React.ReactNode;
  animateRootContentControl: AnimationControls;
};

function RootContent(props: RootContentProps) {
  const { pathname: path } = useLocation();

  const app = (path as string).split('/')[1];

  const module = mapUrlToAppType[app];

  if (
    [
      'BITAPP',
      'BITLOGIN',
      'BITCRM',
      'PRICING',
      'CUSTOMERS',
      'ANALYTICS',
      'BITLINK',
    ].includes(module)
  ) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      <AuthorityCheck module={module}>
        <_RootContent {...props} />
      </AuthorityCheck>
    );
  }

  return <_RootContent {...props} />;
}

function _RootContent({
  children,
  animateRootContentControl,
}: RootContentProps) {
  useVerifyEmailStatus();
  useInformationBanner();

  return (
    <motion.div
      className="absolute inset-0"
      animate={animateRootContentControl}
    >
      {children}
    </motion.div>
  );
}

function AuthorityCheck({
  module,
  children,
}: {
  module: Extract<
    AuthorityKey,
    | 'BITCRM'
    | 'BITAPP'
    | 'BITLOGIN'
    | 'BITLINK'
    | 'ANALYTICS'
    | 'CUSTOMERS'
    | 'PRICING'
  >;
  children: ReactNode;
}) {
  const { data: isRestricted, status } = useIsModuleRestricted(module);

  if (status === 'loading') return <BBBSpinner />;

  if (status === 'error' || isRestricted) return <UnauthorizedScreen />;

  return <>{children}</>;
}
