import { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { CompanyPricing, PlanType, PricingApp } from 'api/services/pricing';
import InfoIcon from 'assets/icons/InfoIcon';
import { appNameFromType } from 'constants/app';
import { mapAppTypeToUrl } from 'constants/systemLogin/urlRedirect';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import { useCompanyPricings } from 'hooks/pricing/v2/pricing';
import { v4 } from 'uuid';
import { BBBButton, BBBCard, BBBModal, BBBTooltip } from 'components';
import BBBTableV2 from 'components/BBBTableV2/BBBTableV2';
import { HeadersTypes } from 'components/BBBTableV2/BBBTableV2.type';
import { formatDate2 } from 'utils/common/date';

type AITokenUsageHistory = {
  transactionType: string;
  amount: number;
  price: number | null;
  transactionDate: string;
  id: string;
};

const data: AITokenUsageHistory[] = [
  {
    transactionType: 'Purchased AI Tokens',
    amount: 100,
    price: 50,
    transactionDate: 'May 24, 2024',
    id: v4(),
  },
  {
    transactionType: 'Purchased AI Tokens',
    amount: 100,
    price: 50,
    transactionDate: 'May 24, 2024',
    id: v4(),
  },
  {
    transactionType: 'Purchased AI Tokens',
    amount: 100,
    price: 50,
    transactionDate: 'May 24, 2024',
    id: v4(),
  },
  {
    transactionType: 'AI Token Usage for bitChat',
    amount: -20,
    price: null,
    transactionDate: 'May 24, 2024',
    id: v4(),
  },
];

const columns: HeadersTypes<AITokenUsageHistory> = [
  {
    accesor: 'transactionType',
    isSortable: true,
    renderHeader: () => 'Transaction type',
    render: (row) => {
      return <>{row.transactionType}</>;
    },
  },
  {
    accesor: 'amount',
    isSortable: true,
    renderHeader: () => 'Amount',
    render: (row) => {
      return (
        <span
          className={
            row.amount > 0 ? 'text-success-hover' : 'text-danger-hover'
          }
        >
          {row.amount > 0 ? '+' : '-'}
          {Math.abs(row.amount)} AI Tokens
        </span>
      );
    },
  },
  {
    accesor: 'price',
    isSortable: true,
    renderHeader: () => 'Price',
    render: (row) => {
      return <>{row.price ? `$${row.price}` : '-'}</>;
    },
  },
  {
    accesor: 'transactionDate',
    isSortable: true,
    renderHeader: () => 'Transaction date',
    render: (row) => {
      return <>{row.transactionDate}</>;
    },
  },
];

export default function PlanManagement() {
  const queryParams = useQuerySearchParams();

  const subSection = queryParams.get('sub_section');

  if (subSection === 'ai-token-usage-history') {
    return <AITokenUsage />;
  }

  return <PlanManagementDefault />;
}

function PlanManagementDefault() {
  const [showPlan, setShowPlan] = useState<
    | (CompanyPricing & {
        pricingModule: PricingApp;
      })
    | null
  >(null);

  const { data, isLoading: loading } = useCompanyPricings();

  return (
    <>
      {/* <BBBCard
        title="AI Token Balance"
        footer={
          <div className="flex items-center gap-2">
            <span className="font-semibold">Usage History</span>
            <ArrowRight />
          </div>
        }
        footerClassName="cursor-pointer"
        footerLink="/settings?section=plan-management&sub_section=ai-token-usage-history"
      >
        <div className="flex items-center gap-2 mb-3 ml-2">
          <CoinsIcon />
          <div className="text-3xl text-secondary-main font-semibold">0</div>
        </div>
        <div className="text-neutral-500">
          AI Tokens can be used to access AI features in bitChat.
        </div>
      </BBBCard> */}
      <div className="text-neutral-500 font-medium mt-5 mb-4">ACTIVE PLAN</div>
      {showPlan && (
        <PlanDetailModal
          onHide={() => setShowPlan(null)}
          companyPricing={showPlan}
        />
      )}
      {data && (
        <BBBCard>
          {data.map((companyPricing) => (
            <PlanList
              onClick={() => setShowPlan(companyPricing)}
              key={companyPricing.app}
              companyPricing={companyPricing}
            />
          ))}
        </BBBCard>
      )}
    </>
  );
}

function PlanDetailModal({
  companyPricing,
  onHide,
}: {
  companyPricing: CompanyPricing & {
    pricingModule: PricingApp;
  };
  onHide: () => void;
}) {
  return (
    <BBBModal
      show
      title={appNameFromType[companyPricing.app]}
      subtitle={
        companyPricing.type === 'EVERY_30_DAYS'
          ? 'Recurring monthly payment'
          : 'Recurring annual payment'
      }
      onHide={onHide}
    >
      <div className="text-neutral-500 mb-2">Date subscribed</div>
      <div className="text-neutral-900 mb-5">May 30, 2024</div>
      <div className="text-neutral-500 mb-2">Plan type</div>
      <div className="text-neutral-900 mb-5">
        {companyPricing.pricingModule.label} Plan,{' '}
        {mapPlanTypeText[companyPricing.type]}
      </div>
      <div className="text-neutral-500 mb-2">Amount</div>
      <div className="text-neutral-900 mb-5">
        {companyPricing.pricingName === 'enterprise'
          ? 'Custom'
          : companyPricing.pricingName === 'free' ||
            companyPricing.pricingName === 'freeWithUsageCharge'
          ? 'Free'
          : `${companyPricing.pricingModule.price}/month`}
      </div>
      <div className="text-neutral-500 mb-2">Status</div>
      <div className="text-neutral-900 flex items-center gap-2.5">
        {companyPricing.status === 'frozen' ||
        companyPricing.status === 'cancelled' ? (
          <>
            Expires {formatDate2(companyPricing.lastBillingCycle)}{' '}
            <BBBTooltip
              show
              content="Your plan will expire on this date if not renewed."
              position="right"
            >
              <InfoIcon />
            </BBBTooltip>
          </>
        ) : (
          <>Renews {formatDate2(companyPricing.lastBillingCycle)} </>
        )}
      </div>

      {companyPricing.pricingName === 'free' && (
        <Link to={`/pricing?tab=${mapAppTypeToUrl[companyPricing.app]}`}>
          <BBBButton className="mt-5" width="full">
            Upgrade now
          </BBBButton>
        </Link>
      )}
    </BBBModal>
  );
}

const mapPlanTypeText: Record<PlanType, string> = {
  ANNUAL: 'annual',
  EVERY_30_DAYS: 'monthly',
};

function PlanList({
  onClick,
  companyPricing,
}: {
  onClick: () => void;
  companyPricing: CompanyPricing & {
    pricingModule: PricingApp;
  };
}) {
  return (
    <div
      className="flex items-center gap-5 pb-3 mb-3 last:mb-0 border-b border-neutral-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="grow">
        <div className="text-xl font-semibold mb-1">
          {appNameFromType[companyPricing.app]}
        </div>
        <div className="text-neutral-500 mb-2">
          {companyPricing.pricingModule.label} plan,{' '}
          {mapPlanTypeText[companyPricing.type]}
        </div>
        <div className="text-neutral-900">
          Renews {formatDate2(companyPricing.lastBillingCycle)}
        </div>
      </div>
      <div className="text-neutral-500 text-xl">
        {companyPricing.pricingName === 'enterprise'
          ? 'Custom'
          : companyPricing.pricingName === 'free'
          ? 'Free'
          : `$${companyPricing.pricingModule.price}`}
      </div>
      <ChevronRight className="text-neutral-500" />
    </div>
  );
}

function AITokenUsage() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  return (
    <BBBTableV2
      headers={columns}
      dataId="id"
      data={data}
      isPaginate
      pagination={{
        page: pageIndex + 1,
        limit: pageSize,
        onChange: (page) => setPageIndex(page - 1),
        total: data.length,
      }}
      isShowLimit
      limitValue={pageSize}
      onLimitChange={(page) => {
        setPageSize(page!);
        setPageIndex(0);
      }}
    />
  );
}

function CoinsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="29"
      fill="none"
      viewBox="0 0 14 29"
    >
      <path
        fill="#FD823E"
        d="M0 14.5c0 3.87.584 7.372 1.496 9.86.458 1.244.997 2.23 1.566 2.883.569.653 1.138.967 1.722.967.584 0 1.153-.314 1.722-.967.57-.653 1.108-1.64 1.567-2.883.911-2.488 1.495-5.99 1.495-9.86 0-3.87-.584-7.372-1.495-9.858-.459-1.247-.997-2.232-1.567-2.885C5.937 1.104 5.368.79 4.784.79c-.584 0-1.153.314-1.722.967-.57.653-1.108 1.638-1.566 2.885C.584 7.128 0 10.63 0 14.5zm6.955 13.71h2.528c.317 0 .886-.314 1.455-.967.57-.653 1.108-1.64 1.566-2.883C13.416 21.872 14 18.37 14 14.5c0-3.87-.584-7.372-1.496-9.858-.458-1.247-.997-2.232-1.566-2.885C10.37 1.104 9.8.79 9.483.79H6.955c.065.065.126.13.186.201.685.79 1.274 1.888 1.763 3.223.982 2.671 1.57 6.292 1.57 10.286 0 3.994-.588 7.615-1.57 10.285-.489 1.334-1.078 2.434-1.763 3.223-.06.07-.126.137-.186.202z"
      ></path>
    </svg>
  );
}

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="9"
      fill="none"
      viewBox="0 0 14 9"
    >
      <path
        fill="#1E1E1E"
        fillRule="evenodd"
        d="M0 4.5a.5.5 0 00.5.5h11.793L9.146 8.146a.5.5 0 00.708.708l4-4a.5.5 0 000-.708l-4-4a.5.5 0 10-.708.708L12.293 4H.5a.5.5 0 00-.5.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
