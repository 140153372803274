import { useEffect, useState } from 'react';
import useResponsive from 'hooks/common/useResponsive';
import { useAppSelector } from 'hooks/rtk/store';
import { BBBButton } from 'components/BBBButton';
import { BBBModal } from 'components/BBBModal';
import { Link } from 'components/Link';
import ChooseChannelModal from '../../../components/ChooseChannelModal';
import BackgroundBlob from './BackgroundBlob';
import BackgroundSmallBlob from './BackgroundSmallBlob';
import EmptyIlustration from './EmptyIlustration';

export default function WAEmptyState() {
  return <_WAEmptyState />;
}

function _WAEmptyState() {
  const hasConnectedChannels = useAppSelector(
    (s) => s.bitCRM.hasConnectedChannels
  );

  if (hasConnectedChannels) return null;

  return <__WAEmptyState />;
}

function __WAEmptyState() {
  const isMobile = useResponsive('sm');
  const [show, setShow] = useState(true);
  const [showChannelModal, setShowChannelModal] = useState(false);

  const showModal = useAppSelector((state) =>
    state.common.connectIntegration?.meta?.modalShow === undefined
      ? true
      : state.common.connectIntegration.meta.modalShow
  );

  useEffect(() => {
    if (!showModal) {
      setShow(showModal);
    }
  }, [showModal]);

  const target = document.getElementById('content');

  return (
    <>
      {target && (
        <BBBModal
          show={show}
          target={target}
          blurBackdrop
          z={90}
          className="p-0"
          size="6xl"
          bodyClassName="flex relative items-center min-h-[680px] justify-between my-auto"
        >
          {!isMobile && (
            <>
              <BackgroundBlob className="absolute left-0 " />
              <EmptyIlustration className="absolute left-0  z-50" />
            </>
          )}
          <div />
          <div className="pr-8">
            <div className="w-full h-full relative flex flex-col gap-12">
              {!isMobile && (
                <BackgroundSmallBlob className="absolute right-[10%] top-0" />
              )}
              <p className="text-[32px] font-bold text-primary-main text-center z-50">
                Connect with Customers <br /> Across Channels!{' '}
              </p>
              <p className="text-primary-main text-center max-w-[300px] mx-auto">
                <span className="font-bold">Multi-Agent Chat:</span> Handle
                multiple customer inquiries simultaneously with ease.
                <br />
                <span className="font-bold">Real-Time Responses:</span> Respond
                to customer questions quickly and efficiently.
                <br />
                <span className="font-bold">Shopify Integration:</span>{' '}
                Seamlessly manage your chat messages and orders from your
                Shopify dashboard.
              </p>
              <div className="flex flex-col gap-4 items-center">
                <BBBButton
                  text={'Connect Channel'}
                  size="lg"
                  onClick={() => setShowChannelModal(true)}
                />
              </div>
            </div>
          </div>
        </BBBModal>
      )}
      <ChooseChannelModal
        show={showChannelModal}
        onHide={() => setShowChannelModal(false)}
      />
    </>
  );
}
