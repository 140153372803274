import BBBUpsellCard from '@/components/BBBUpsellCard/BBBUpsellCard';

const upsellContents = {
  'enable-one-tap': {
    title: 'Upgrade to enable Google One Tap!',
    description:
      'Google One Tap allow faster sign-up and sign-in process to reduce friction to signing in & checking out to your store.',
    isNotAdvertise: false,
  },
  'oauth-helper': {
    title: 'Need help finding your OAuth data?',
    description: `If you're struggling to find your App ID, App Secret, or other OAuth data, contact us right away and we'll be happy to assist you.`,
    isNotAdvertise: true,
  },
};

function UpsellCard({
  type = 'enable-one-tap',
}: {
  type?: keyof typeof upsellContents;
}) {
  return (
    <BBBUpsellCard
      title={upsellContents[type].title}
      description={upsellContents[type].description}
      appType="BITLOGIN"
      id={type}
      isNotAdvertise={upsellContents[type].isNotAdvertise}
    />
  );
}

export default UpsellCard;
