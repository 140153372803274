import { useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useActiveCompany } from 'hooks/rtk/selector';
import { socketIntegrations } from 'socket';
import useShopifyIntegrationByApp from '../shopify/useShopifyIntegrationByApp';
import usePricingBillingUrl from './usePricingBillingUrl';
import usePricingByApp from './usePricingByApp';

import { PlanType, PricingPlan } from '@/api/services/pricing';
import { env } from '@/config/env';
import { appNameFromType } from '@/constants/app';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { AppType } from '@/types/systemLogin';
import { toast } from '@/utils/common/toast';

const eligibleForFreeTrial:
  | Record<string, { trialDuration: Partial<Record<AppType, number>> }>
  | undefined = env.REACT_APP_ELIGIBLE_FREE_TRIAL_COMPANY
  ? JSON.parse(env.REACT_APP_ELIGIBLE_FREE_TRIAL_COMPANY)
  : undefined;

const useRedirectUpgradePlan = ({
  onSuccess,
  appType,
  type = 'EVERY_30_DAYS',
}: {
  onSuccess?: () => void;
  appType: AppType;
  type?: PlanType;
}) => {
  const { data: shopifyMeta } = useShopifyIntegrationByApp(appType);

  const { data: pricingData } = usePricingByApp(appType);

  const activeCompany = useActiveCompany();

  const _billingUrl = usePricingBillingUrl({
    appType,
    type,
    domain: shopifyMeta?.domain,
  });

  const { toggle } = useConfirmationBanner();

  const queryClient = useQueryClient();

  const onPricingBegin = useCallback(
    (_app: AppType) => {
      toggle(`${_app}-pricing`, {
        show: true,
        text: 'Waiting for payment',
        variant: 'loading',
      });
    },
    [toggle]
  );

  const onPricingEnd = useCallback(
    (_app: AppType) => {
      toggle(`${_app}-pricing`, {
        show: false,
        text: 'Waiting for payment',
        variant: 'loading',
      });

      onSuccess?.();
      queryClient.invalidateQueries([`pricing-v2`]);
    },
    [onSuccess, queryClient, toggle]
  );

  useEffect(() => {
    socketIntegrations.on(`pricing-begin`, onPricingBegin);
    return () => {
      socketIntegrations.off(`pricing-begin`, onPricingBegin);
    };
  }, [onPricingBegin]);

  useEffect(() => {
    socketIntegrations.on(`pricing-end`, onPricingEnd);
    return () => {
      socketIntegrations.off(`pricing-end`, onPricingEnd);
    };
  }, [onPricingEnd]);

  const { mutate: downgrade, isLoading: loadingDowngrade } = useMutation(
    (url: string) => axios.get(url),
    {
      onSuccess: () => {
        toast.success(
          'We have succesfully receive your request to downgrade the app.'
        );
        // delay by consumer
        setTimeout(() => {
          queryClient.invalidateQueries([`${appType.toLowerCase()}-pricing`]);
        }, 2000);
      },
      onError: () => {
        toast.error('Invalid request');
      },
    }
  );

  const confirm = useConfirmationModal();

  return {
    fn: (plan: PricingPlan, domain: string) => {
      if (!_billingUrl) throw new Error('Billing url must be specified');

      const billingUrl = (trialDays?: number) => {
        const baseUrl = `${_billingUrl}&plan=${plan}`;

        if (trialDays) {
          return baseUrl + `&trialDays=${trialDays}`;
        }

        return baseUrl;
      };

      if (plan === 'enterprise') {
        window.open(
          `https://api.whatsapp.com/send/?phone=6285693142306&text=Hello+bitbybit+Team%21%0D%0A%0D%0AI+want+to+upgrade+my+${appNameFromType[appType]}+plan+to+Enterprise%21+Are+you+open+for+a+discussion%3F+&type=phone_number&app_absent=0`,
          '_blank'
        );
      } else if (plan === 'free') {
        confirm({
          title: 'Are you sure you want to downgrade to the free plan?',
          description:
            'Once you downgrade, you will lose access to some of the features of your current plan',
          submitText: 'Confirm',
          cancelText: 'Cancel',
          onAccept: (hide) => {
            downgrade(billingUrl());
            hide();
          },
        });
      } else {
        if (eligibleForFreeTrial) {
          const companyId = activeCompany.toString();

          const eligible =
            Object.keys(eligibleForFreeTrial).includes(companyId);

          if (
            eligible &&
            eligibleForFreeTrial[companyId].trialDuration[appType] &&
            pricingData?.hasFreeTrialQuota
          ) {
            const days =
              eligibleForFreeTrial[companyId].trialDuration[appType]!;

            const daysToMonths = days / 30;

            confirm({
              title: `${
                daysToMonths > 1 ? daysToMonths + ' Months' : days + ' Days'
              } free trial`,
              description: `Hello Early Adopter! Enjoy a free ${
                daysToMonths > 1 ? daysToMonths + '-month' : days + '-day'
              } trial of our ${plan} Plan, packed with powerful bitbybit features. Start growing your business today!`,
              submitText: 'Upgrade now',
              cancelText: 'Later',
              onAccept: (hide) => {
                _fn(days);
                hide();
              },
            });
          } else {
            _fn();
          }
        } else {
          _fn();
        }
      }

      function _fn(trialDays?: number) {
        socketIntegrations.emit('join-shopify', {
          appType,
          domain,
        });

        window.open(billingUrl(trialDays), '_blank');
      }
    },
    loading: loadingDowngrade,
  };
};

export default useRedirectUpgradePlan;
