import { ChevronLeft } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import BetaBadge from '@/components/Badge/BetaBadge';
import NewBadge from '@/components/Badge/NewBadge';
import { Link } from '@/components/Link';
import { appType } from '@/constants/common/app';
import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';
import { useOnboardingCompletion as useOnboardingCompletionBitAi } from '@/hooks/onboarding/bitai';
import { useOnboardingCompletion as useOnboardingCompletionBitChat } from '@/hooks/onboarding/bitchat';
import { useOnboardingCompletion as useOnboardingCompletionBitCRM } from '@/hooks/onboarding/bitcrm';
import { cn } from '@/utils/styles';

const pagesWithOnboarding = ['/bitchat', '/bitcrm', '/bitai'];

export default function SidebarSubNav({
  data,
  parent,
  onBack,
  onClick,
  expanded,
}: {
  parent?: sidebarDataTypeSectionV2;
  data: sidebarDataTypeSectionV2[];
  onBack?: () => void;
  onClick?: () => void;
  expanded?: boolean;
}) {
  const { pathname, search } = useLocation();

  if (!parent) return null;

  const Icon = parent.icon!;

  return (
    <>
      {expanded ? (
        <>
          <div
            className={cx(
              'flex gap-4 items-center p-6 hover:bg-gray-100 cursor-pointer',
              !expanded ? '' : ' '
            )}
            onClick={onBack}
          >
            <div className="flex-none cursor-pointer">
              <ChevronLeft />
            </div>
          </div>
          <div
            className={cx(
              'text-secondary-main flex justify-center items-center bg-[#FFE6D8] p-4'
            )}
          >
            <Icon mode="active" />
          </div>
        </>
      ) : (
        <>
          <div
            className={cx(
              'flex gap-4 p-6 items-center hover:bg-gray-100 cursor-pointer'
            )}
            onClick={onBack}
          >
            <div className="flex-none cursor-pointer">
              <ChevronLeft />
            </div>
            <div className="font-semibold">{parent.title}</div>
          </div>
          {pagesWithOnboarding.includes(parent.path) && (
            <OnboardingProgress path={parent.path} />
          )}
          {data
            .filter((sub) => !sub.disabled)
            .map((sub) => {
              const isSubPath = isPrefixSplitBySlash(sub.path, pathname);

              return (
                <Link
                  className={cx(
                    'flex items-center gap-2 p-4 transition-[background]',
                    isSubPath
                      ? 'bg-[#FFE6D8] '
                      : 'hover:bg-gray-100 cursor-pointer'
                  )}
                  key={sub.path}
                  to={formatPricingRedirection(sub.path, pathname, search)}
                  onClick={onClick}
                >
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={cx(
                      'flex items-center gap-2',
                      isSubPath && 'font-semibold'
                    )}
                  >
                    {sub.title}
                  </motion.span>
                  {sub.isBeta && <BetaBadge />}
                  {sub.isNew && <NewBadge />}
                </Link>
              );
            })}
        </>
      )}
    </>
  );
}

function OnboardingProgress({ path }: { path: string }) {
  if (path === '/bitchat') return <ProgressBitChat />;

  if (path === '/bitcrm') return <ProgressBitCRM />;

  if (path === '/bitai') return <ProgressBitAi />;

  return null;
}

function ProgressBitCRM() {
  const { progress, dismissed } = useOnboardingCompletionBitCRM();

  return (
    <_OnboardingProgress
      path="/bitcrm"
      progress={progress}
      dismissed={!!dismissed}
    />
  );
}

function ProgressBitChat() {
  const { progress, dismissed } = useOnboardingCompletionBitChat();

  return (
    <_OnboardingProgress
      path="/bitchat"
      progress={progress}
      dismissed={!!dismissed}
    />
  );
}

function ProgressBitAi() {
  const { progress, dismissed } = useOnboardingCompletionBitAi();

  return (
    <_OnboardingProgress
      path="/bitai"
      progress={progress}
      dismissed={!!dismissed}
    />
  );
}

function _OnboardingProgress({
  path,
  progress,
  dismissed,
}: {
  path: string;
  progress: number;
  dismissed: boolean;
}) {
  const { pathname } = useLocation();

  if (dismissed) return null;

  return (
    <Link to={`${path}/onboarding`} className="block p-4">
      <div
        className={cn(
          'rounded-lg px-4 py-3 flex items-center gap-4 transition-colors',
          pathname === `${path}/onboarding` && 'bg-secondary-surface'
        )}
      >
        <CircularProgress percentage={progress} />
        <div>
          <div className="font-medium mb-0.5">Get started</div>
          <div
            className={cn(
              'text-neutral-50 text-sm',
              progress === 100 && 'text-secondary-main'
            )}
          >
            {progress.toFixed(0)}% Complete
          </div>
        </div>
      </div>
    </Link>
  );
}

export function formatPricingRedirection(
  target: string,
  currentPath: string,
  currentSearch: string
) {
  if (target === '/pricing' || target === '/analytics') {
    const splitCurrentPath = currentPath.split('/')[1];
    const isCurrentPathApp = appType.includes(splitCurrentPath);

    if (isCurrentPathApp) {
      return `${target}?tab=${splitCurrentPath}`;
    }

    const searchParams = new URLSearchParams(currentSearch);

    const tab = searchParams.get('tab');

    if (tab) {
      return `${target}?tab=${tab}`;
    }

    return `${target}`;
  }

  return target;
}

function isPrefixSplitBySlash(input1: string, input2: string): boolean {
  const segments1 = input1.split('/').filter(Boolean); // Split and filter out empty strings
  const segments2 = input2.split('/').filter(Boolean); // Split and filter out empty strings

  if (segments1.length > segments2.length) {
    return false;
  }

  for (let i = 0; i < segments1.length; i++) {
    if (segments1[i] !== segments2[i]) {
      return false;
    }
  }

  return true;
}

type CircularProgressProps = {
  percentage: number;
};

const CircularProgress: React.FC<CircularProgressProps> = ({ percentage }) => {
  const radius = 24;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="relative flex items-center justify-center w-6 h-6 flex-none">
      <svg className="absolute w-full h-full" viewBox="0 0 36 36">
        <circle
          className="text-[#9A9A9A]"
          strokeWidth={stroke}
          stroke="currentColor"
          fill="transparent"
          r={normalizedRadius}
          cx="18"
          cy="18"
        />
        <circle
          className="text-secondary-main"
          strokeWidth={stroke}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={normalizedRadius}
          cx="18"
          cy="18"
          transform="rotate(-90 18 18)"
        />
      </svg>
    </div>
  );
};
