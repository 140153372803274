import { Send } from 'react-feather';
import { FiBox } from 'react-icons/fi';
import { yupResolver } from '@hookform/resolvers/yup';
import { isProd } from 'config/env';
import colors from 'constants/common/colors';
import {
  useCustomerTag,
  useCustomerTagResult,
  useUpsertCustomerTagPrompt,
} from 'hooks/ai/customer-tag';
import useCustomForm from 'hooks/common/useCustomForm';
import useIsGroupChat from 'hooks/whatsApp/useIsGroupChat';
import useTicketByChat from 'hooks/whatsApp/useTicketByChat';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import { BBBCard, BBBTag, BBBTextInput } from 'components';

const schema = yup.object({
  prompt_experiment_1: yup.string(),
  prompt_experiment_2: yup.string(),
});

type Schema = yup.InferType<typeof schema>;

export default function SandBox() {
  const isGroup = useIsGroupChat();

  if (isGroup || isProd)
    return (
      <>
        <Header />
        <Info
          message={
            <p className="text-center">
              Sandbox are not <br />
              available for this channel
            </p>
          }
        />
      </>
    );

  return (
    <>
      <Header />
      <_Sandbox />
    </>
  );
}

function _Sandbox() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useCustomForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      prompt_experiment_1: '',
      prompt_experiment_2: '',
    },
  });
  const { data: ticketData, isInitialLoading: loadingTicket } =
    useTicketByChat();

  const { data: customerTagData, isLoading: isLoadingCustomerTag } =
    useCustomerTag({
      enabled: !isProd,
    });

  const { data: customerTagResultData, isLoading: isLoadingCustomerTagResult } =
    useCustomerTagResult(`${ticketData?.id}`, {
      enabled: !isProd && !!ticketData?.id,
    });

  const { mutate, isLoading: isMutating } = useUpsertCustomerTagPrompt();

  const isLoading =
    isLoadingCustomerTag ||
    isLoadingCustomerTagResult ||
    loadingTicket ||
    isMutating;

  return (
    <BBBCard containerTitleClassName="mb-0">
      <p className="text-neutral-50 font-medium text-sm uppercase mb-2.5">
        Prompt
      </p>
      <div className="flex flex-col gap-4">
        <div className="pb-5 border-b border-neutral-40 last:border-transparent flex flex-col gap-2.5">
          <div className="flex gap-2 items-center">
            <BBBTextInput
              placeholder="Prompt experiment 1"
              containerClassname="mb-0 grow"
              isHookForm
              control={control}
              controlName="prompt_experiment_1"
              error={errors.prompt_experiment_1?.message}
            />
            <div
              className={cx(
                'bg-primary-main rounded-md flex justify-center items-center p-2',
                isLoading && 'cursor-not-allowed pointer-events-none opacity-40'
              )}
              onClick={handleSubmit((data) => {
                mutate({
                  prompt_experiment_1: data.prompt_experiment_1,
                });
              })}
            >
              <Send color={colors.neutral[10]} size={16} />
            </div>
          </div>
          {customerTagResultData?.prompt_1_tags?.length ? (
            customerTagResultData?.prompt_1_tags.map((tag) => (
              <BBBTag text={tag} key={tag} />
            ))
          ) : (
            <p className="text-ls text-neutral-40 text-center py-2">No Tags</p>
          )}
        </div>
        <div className="pb-5 border-b border-neutral-40 last:border-transparent flex flex-col gap-2.5">
          <div className="flex gap-2 items-center">
            <BBBTextInput
              placeholder="Prompt experiment 2"
              containerClassname="mb-0 grow"
              isHookForm
              control={control}
              controlName="prompt_experiment_2"
              error={errors.prompt_experiment_2?.message}
            />
            <div
              className={cx(
                'bg-primary-main rounded-md flex justify-center items-center p-2',
                isLoading && 'cursor-not-allowed pointer-events-none opacity-40'
              )}
              onClick={handleSubmit((data) => {
                mutate({
                  prompt_experiment_2: data.prompt_experiment_2,
                });
              })}
            >
              <Send color={colors.neutral[10]} size={16} />
            </div>
          </div>
          {customerTagResultData?.prompt_1_tags?.length ? (
            customerTagResultData?.prompt_1_tags.map((tag) => (
              <BBBTag text={tag} key={tag} />
            ))
          ) : (
            <p className="text-ls text-neutral-40 text-center py-2">No Tags</p>
          )}
        </div>
      </div>
    </BBBCard>
  );
}

function Header() {
  return <BBBCard title="Sandbox" />;
}

function Info({ message }: { message: string | React.ReactNode }) {
  return (
    <BBBCard>
      <div className="flex flex-col gap-2 py-2.5 items-center justify-center">
        <div className="p-4 bg-neutral-30 rounded-full">
          <FiBox size={22} className="text-neutral-50" />
        </div>

        <p className="text-neutral-50">{message}</p>
      </div>
    </BBBCard>
  );
}
