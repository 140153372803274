import { forwardRef, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ArrowLeft } from 'react-feather';
import { motion } from 'framer-motion';

import { IBBBCard } from '@/components';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';

export type BBBBottomSheetProps = IBBBCard & {
  show?: boolean;
  withoutBackdrop?: boolean;
  onClose?: () => void;
};

const BBBBottomSheet = forwardRef<HTMLDivElement, BBBBottomSheetProps>(
  (props, cardRef) => {
    const ref = useRef<HTMLDivElement>(null);
    const backdropRef = useRef<HTMLDivElement>(null);
    const [sheetHeight, setSheetHeight] = useState<number>();
    const contentRef = useRef<HTMLDivElement>(null);

    const startYRef = useRef(0);
    const initialHeightRef = useRef(0);

    const {
      show,
      children,
      withoutBackdrop,
      className,
      onClose,
      ...otherProps
    } = props;

    useOutsideAlerter(ref, (target) => {
      if (target === backdropRef.current) {
        onClose?.();
      }
    });

    useEffect(() => {
      if (contentRef.current) {
        const contentHeight =
          contentRef.current.offsetHeight > 300
            ? contentRef.current.offsetHeight
            : 300;
        setSheetHeight(contentHeight);
        initialHeightRef.current = contentHeight;
      }
    }, []);

    //@ts-ignore
    const startDrag = (e) => {
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      startYRef.current = clientY;
      //@ts-ignore
      initialHeightRef.current = sheetHeight;

      if (e.type === 'mousedown') {
        window.addEventListener('mousemove', handleDrag);
        window.addEventListener('mouseup', stopDrag);
      } else if (e.type === 'touchstart') {
        window.addEventListener('touchmove', handleDrag);
        window.addEventListener('touchend', stopDrag);
      }
    };

    //@ts-ignore
    const handleDrag = (e) => {
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      const diffY = startYRef.current - clientY;
      const newHeight = initialHeightRef.current + diffY;

      setSheetHeight(newHeight > 300 ? newHeight : 300);
    };

    const stopDrag = () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('touchmove', handleDrag);
      window.removeEventListener('touchend', stopDrag);
    };

    if (!show || !document.body) return null;

    return (
      <>
        {createPortal(
          <div id="modal-root">
            {!withoutBackdrop && (
              <motion.div
                className="absolute inset-0 z-[300] bg-black/50"
                ref={backdropRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              />
            )}
            <motion.div
              className="absolute bottom-0 z-[300] left-0 right-0 overflow-hidden"
              ref={ref}
              key="bottom-sheet"
              initial={{ y: '100%', opacity: 1 }}
              animate={{ y: 0 }}
              exit={{ y: '100%', opacity: 1 }}
              transition={{
                type: 'tween',
                duration: 0.3,
              }}
            >
              <div
                className="bg-white border-0 rounded-tl-3xl rounded-tr-3xl p-4"
                style={{
                  boxShadow: '0px -3px 6px 0px #9B9B9B26',
                  height: sheetHeight, // Use auto unless dragging
                  transition: 'height 0.3s ease-in-out', // Disable transition while dragging
                }}
              >
                <div className="flex mb-5">
                  <ArrowLeft onClick={onClose} />
                  <div
                    className="grow flex justify-center -translate-x-3"
                    onMouseDown={startDrag}
                    onTouchStart={startDrag}
                    style={{ cursor: 'grab' }}
                  >
                    <SheetsPushIcon />
                  </div>
                </div>
                <div className="text-center mb-5 font-bold">
                  {otherProps.title}
                </div>
                <div ref={contentRef}>{children}</div>
              </div>
            </motion.div>
          </div>,
          document.body!
        )}
      </>
    );
  }
);

BBBBottomSheet.displayName = 'BBBBottomSheet';

export default BBBBottomSheet;

function SheetsPushIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="6"
      fill="none"
      viewBox="0 0 29 6"
    >
      <rect
        width="28"
        height="5.502"
        x="0.5"
        fill="#262627"
        opacity="0.5"
        rx="2.751"
      ></rect>
    </svg>
  );
}
