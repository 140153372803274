import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { EditorState } from 'draft-js';
import { isEqual } from 'lodash-es';
import { socketPricing } from 'socket';
import { twMerge as cx } from 'tailwind-merge';
import { AccountType, Branding, PublishStatus } from 'types/bitApp/v2';
import * as yup from 'yup';
import Journey from './Journey';
import SubmissionTracker from './SubmissionTracker';

import {
  BBBAlert,
  BBBButton,
  BBBCard,
  BBBColorPicker,
  BBBContainer,
  BBBImageUpload,
  BBBRichTextEditor,
  BBBTextInput,
} from '@/components';
import BBBAccordion from '@/components/BBBAccordion';
import { _ConfirmationModal } from '@/components/BBBConfirmationModal/BBBConfirmationModal';
import { BBBRadio } from '@/components/BBBRadio';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { env } from '@/config/env';
import { submissionStatus } from '@/constants/bitApp/publish';
import useBrandingQuery from '@/hooks/bitApp/design/useBranding';
import {
  useCreateDraftPublish,
  useRecentPublish,
  useRecentPublishRequest,
  useRequestPublish,
} from '@/hooks/bitApp/publish';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomForm from '@/hooks/common/useCustomForm';
import useHistory from '@/hooks/common/useHistory';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useRedirectUpgradePlan from '@/hooks/pricing/useRedirectUpgradePlan';
import { useActiveCompany, useUser } from '@/hooks/rtk/selector';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from '@/utils/common/rich';
import { toast } from '@/utils/common/toast';
import { richValidation } from '@/utils/common/validation';

const disabledEditSubmissionStatus: PublishStatus[] = [
  'approved',
  'review',
  'submittedToStore',
  'submitted',
];

const schema = yup.object().shape({
  name: yup.string().required('App name is required'),
  appIcon: yup.string().nullable().required().label('App icon'),
  primaryColor: yup.string().nullable(),
  description: richValidation,
  splashScreen: yup.string().nullable().required().label('Splash screen'),
  subtitle: yup.string().required().label('Subtitle'),
  accountType: yup.string().required('Account type is required'),
});

export type BrandingForm = Pick<
  Branding,
  'name' | 'appIcon' | 'primaryColor' | 'splashScreen' | 'subtitle'
> & {
  description: EditorState;
  accountType: AccountType;
};

export default function PublishPage() {
  const userData = useUser();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  const pricingQuery = usePricingByApp('BITAPP');
  const { data: shopifyData } = useShopifyIntegrationByApp('BITAPP', {
    enabled: pricingQuery.data?.pricingName === 'free',
  });
  const { fn: redirectUpgradePlan } = useRedirectUpgradePlan({
    appType: 'BITAPP',
  });
  const { data: brandingData } = useBrandingQuery();
  const { mutate: publishApp, isLoading: isLoadingPublish } =
    useRequestPublish();

  const { mutate: createDraftPublish, isLoading: loadingCreateDraftPublish } =
    useCreateDraftPublish();

  const { data: recentPublish } = useRecentPublish();
  const { data: recentPublishRequest } = useRecentPublishRequest();

  const query = useQuerySearchParams();
  const from = query.get('from');
  const history = useHistory();
  const toggleConfirmation = useConfirmationModal();
  const { toggle } = useConfirmationBanner();

  const [collapsed, setCollapsed] = useState<
    'app-information' | 'developer-account' | 'submit-app' | undefined
  >('app-information');

  const showAlertChanges =
    recentPublish &&
    recentPublish.submissionStatusApple === 'live' &&
    recentPublish.submissionStatusGoogle === 'live' &&
    recentPublishRequest &&
    !recentPublishRequest.submissionStatusApple &&
    !recentPublishRequest.submissionStatusGoogle;

  const dataFromApi = useMemo<BrandingForm>(
    () => ({
      name: brandingData?.name || '',
      subtitle: brandingData?.subtitle || '',
      description: brandingData?.description
        ? convertHtmlToEditorState(brandingData?.description)
        : emptyEditor,
      appIcon: brandingData?.appIcon || null,
      splashScreen: brandingData?.splashScreen || null,
      primaryColor: brandingData?.primaryColor || '#000000',
      accountType: 'bitbybit_developer_account',
    }),
    [brandingData]
  );

  const { control, reset, watch, handleSubmit } = useCustomForm<BrandingForm>({
    defaultValues: dataFromApi,
    resolver: yupResolver(schema),
  });

  const submissionGoogleStatus = submissionStatus.find(
    (status) => status.value === recentPublishRequest?.submissionStatusGoogle
  )?.value;

  const submissionAppleStatus = submissionStatus.find(
    (status) => status.value === recentPublishRequest?.submissionStatusApple
  )?.value;

  const generalDisableRule = !!(
    submissionAppleStatus &&
    submissionGoogleStatus &&
    (disabledEditSubmissionStatus.includes(submissionAppleStatus) ||
      disabledEditSubmissionStatus.includes(submissionGoogleStatus))
  );

  const justWentLive =
    recentPublish &&
    recentPublishRequest &&
    isEqual(recentPublish, recentPublishRequest);

  const isSubmitDisabled =
    justWentLive || !schema.isValidSync(dataFromApi) || generalDisableRule;

  const isSubmissionLive =
    submissionAppleStatus === 'live' || submissionGoogleStatus === 'live';

  const onSubmit = () => {
    if (!userData.isVerified) {
      return toast.error(
        `Please verify your email first before publishing your app`
      );
    }

    toggleConfirmation({
      id: 'submit-confirmation',
      title: 'bitApp Submission',
      description:
        'Please recheck the data before submit your mobile app, you cannot change the data during submission process. This submission will take 7 - 14 days',
      cancelText: 'Back to edit',
      centerBody: true,
      centerTitle: true,
      centerFooter: true,
      submitText: 'Submit',
      onAccept: (hide) => {
        if (pricingQuery.data?.pricingName === 'free') {
          if (shopifyData) {
            toggleConfirmation({
              id: 'charge-confirmation',
              title: 'Charge confirmation',
              description:
                'You will be charge 2% of each successful order transaction through the mobile app once it goes live. Are you sure you want to proceed?',
              cancelText: 'Cancel',
              submitText: 'Submit',
              centerBody: true,
              centerTitle: true,
              centerFooter: true,
              onAccept: (hide) => {
                redirectUpgradePlan('freeWithUsageCharge', shopifyData.domain);
                hide();
              },
            });
          }

          return;
        }

        publishApp(undefined, {
          onSuccess: () => {
            toast.success('Your app is submitted');

            toggle('submission-success', {
              text: 'Your submission will reviewed by bitbybit team. You can check the submission status in this page.',
              isCloseable: true,
            });
          },
        });

        hide();
      },
    });
  };

  const handleResubmit = () => {
    const isFormEqual = isEqual(dataFromApi, watch());

    if (!isFormEqual) {
      return toast.error(
        'You have unsaved changes. Please save your changes before submitting your app!'
      );
    }

    toggleConfirmation({
      id: 'resubmit-confirmation',
      title: 'bitApp Submission',
      description:
        'Please recheck the data before submit your mobile app, you cannot change the data during submission process. This submission will take 7 - 14 days',
      cancelText: 'Back to edit',
      centerBody: true,
      centerTitle: true,
      centerFooter: true,
      submitText: 'Submit',
      onAccept: (hide) => {
        publishApp(undefined, {
          onSuccess: () => {
            toast.success('Your app is resubmitted');
            toggle('resubmission-success', {
              text: 'Your resubmission will reviewed by bitbybit team. You can check the submission status in this page.',
              isCloseable: true,
            });
          },
        });
        hide();
      },
    });
  };

  const createDraftPublishFn = useCallback(
    ({
      description,
      name,
      appIcon,
      primaryColor,
      accountType,
      splashScreen,
      subtitle,
    }: BrandingForm) => {
      createDraftPublish({
        userId: userData.id,
        appDescription: description.getCurrentContent().hasText()
          ? convertEditorStateToHtml(description)
          : '',
        appName: name || '',
        appIcon: appIcon || '',
        primaryColor,
        accountType: accountType,
        appSplashScreen: splashScreen || '',
        appSubtitle: subtitle || '',
        id: recentPublishRequest?.id,
      });
    },
    [createDraftPublish, recentPublishRequest?.id, userData.id]
  );

  useEffect(() => {
    const isFormEqual = isEqual(dataFromApi, watch());

    toggle('publish-app-form', {
      show: !isFormEqual,
      variant: loadingCreateDraftPublish ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: reset,
      isCancelable: true,
      onAccept: () => {
        createDraftPublishFn(watch());
      },
    });
  }, [
    createDraftPublishFn,
    dataFromApi,
    loadingCreateDraftPublish,
    reset,
    toggle,
    watch(),
  ]);

  useEffect(() => {
    socketPricing.emit('join', { companyId: activeCompany });
  }, [activeCompany]);

  const invalidatePricing = useCallback(() => {
    queryClient.invalidateQueries(['bitapp-pricing']);
    createDraftPublishFn(watch());
  }, [createDraftPublishFn, queryClient, watch()]);

  useEffect(() => {
    socketPricing.on('pricing-success', invalidatePricing);

    return () => {
      socketPricing.off('pricing-success', invalidatePricing);
    };
  }, [invalidatePricing, queryClient]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  return (
    <>
      <Journey />
      {from === 'notification' && (
        <_ConfirmationModal
          show
          title="Publish your app"
          description="In order to blast notification, you need to publish your app first. Follow the steps as defined in this page to publish your app on Apple App Store and Google Play Store"
          submitText="OK"
          withoutCancel
          onAccept={() => {
            query.delete('from');
            history.replace({
              search: query.toString(),
            });
          }}
        />
      )}
      <BBBContainer
        hasHeading
        pageTitle="Publish App"
        pageDescription="Make your app live and ready to use by your customers!"
        className="flex flex-col gap-5"
      >
        {showAlertChanges && (
          <BBBAlert
            type="secondary"
            className="justify-start"
            message={
              <p>
                You made changes to your app. Please update your app to
                be-resubmitted again!{' '}
                <span
                  className="text-info-main underline font-semibold cursor-pointer"
                  onClick={() => handleSubmit(onSubmit)()}
                >
                  Update app
                </span>
              </p>
            }
          />
        )}
        {recentPublishRequest &&
          recentPublishRequest.submissionStatusGoogle &&
          recentPublishRequest.submissionStatusApple && (
            <SubmissionTracker
              statusAndroid={recentPublishRequest.submissionStatusGoogle}
              statusIos={recentPublishRequest.submissionStatusApple}
              urlAndroid={recentPublishRequest.downloadLinkGoogle}
              urlIos={recentPublishRequest.downloadLinkApple}
              onClickResubmit={handleResubmit}
              rejectedMessageAndroid={recentPublishRequest.rejectedReasonGoogle}
              rejectedMessageIos={recentPublishRequest.rejectedReasonApple}
            />
          )}
        <BBBAccordion>
          <BBBAccordion.Item
            id="app-information"
            title="App information"
            collapse={collapsed === 'app-information'}
            onCollapse={(_collapsed) => {
              setCollapsed(_collapsed ? 'app-information' : undefined);
            }}
            subtitle="Fill out information about your app. Before launching your app, please make sure that you have a Google and/or Apple developer account."
            leftChildren={
              <Step step={1} active={collapsed === 'app-information'} />
            }
            content={
              <AppInformation control={control} disabled={generalDisableRule} />
            }
          />
          <BBBAccordion.Item
            id="developer-account"
            title="Developer account"
            collapse={collapsed === 'developer-account'}
            onCollapse={(_collapsed) => {
              setCollapsed(_collapsed ? 'developer-account' : undefined);
            }}
            subtitle="Choose what account to publish your app."
            leftChildren={
              <Step step={2} active={collapsed === 'developer-account'} />
            }
            content={<DeveloperAccount control={control} isDisabled={true} />}
          />
          <BBBAccordion.Item
            id="submit-app"
            title="Submit your app"
            collapse={collapsed === 'submit-app'}
            onCollapse={(_collapsed) => {
              setCollapsed(_collapsed ? 'submit-app' : undefined);
            }}
            subtitle="Make sure that you have done every step above to be able to publish your app."
            leftChildren={<Step step={3} active={collapsed === 'submit-app'} />}
            content={
              <SubmitApp
                isLoading={isLoadingPublish}
                onSubmit={() => handleSubmit(onSubmit)()}
                isDisabled={isSubmitDisabled}
                submitText={
                  submissionAppleStatus === 'live' ||
                  submissionGoogleStatus === 'live'
                    ? 'Update App'
                    : submissionStatus.find(
                        (submission) =>
                          submission.value === submissionAppleStatus
                      )?.label || 'Submit App'
                }
              />
            }
          />
        </BBBAccordion>
        {(isSubmissionLive ||
          (recentPublish?.downloadLinkGoogle &&
            recentPublish?.downloadLinkApple)) && (
          <DownloadAppCard
            urlAndroid={recentPublish?.downloadLinkGoogle}
            urlIos={recentPublish?.downloadLinkApple}
          />
        )}
      </BBBContainer>
    </>
  );
}

function AppInformation({
  control,
  disabled,
}: {
  control: Control<BrandingForm>;
  disabled: boolean;
}) {
  const { errors } = useFormState({
    control,
  });
  return (
    <div
      className={cx(
        'w-[90%] mx-auto flex flex-col gap-4 mt-6',
        disabled && 'pointer-events-none opacity-50'
      )}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <BBBTextInput
        label={'App name'}
        placeholder="Ex: ThisApp"
        isHookForm
        control={control}
        controlName="name"
        name="appName"
        error={errors.name?.message}
        containerClassname="mb-0"
        requiredLabel
      />
      <BBBTextInput
        label={'App subtitle'}
        placeholder="Ex: Intermitten-fasting tracker"
        name="appSubtitle"
        isHookForm
        control={control}
        controlName="subtitle"
        error={errors.subtitle?.message}
        containerClassname="mb-0"
        requiredLabel
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <BBBRichTextEditor
            label={'App description'}
            placeholder="Describe your app's features and function. Ex: ThisApp can help you to track your intermittent fasting schedule!"
            editorState={field.value}
            onChangeEditorState={(val) => field.onChange(val)}
            rows={2}
            error={errors.description?.message}
            containerClassName="mb-0"
            requiredLabel
          />
        )}
      />
      <Controller
        control={control}
        name="appIcon"
        render={({ field }) => (
          <BBBImageUpload
            label={'App icon'}
            imageUrl={field.value}
            onChangeImage={field.onChange}
            imageClassName="rounded"
            accept="image/png"
            errors={errors.appIcon?.message}
            requiredLabel
            acceptedDimensions={{
              width: 1024,
              height: 1024,
            }}
            uploadOptions={{ original: true }}
          />
        )}
      />
      <Controller
        control={control}
        name="splashScreen"
        render={({ field }) => (
          <BBBImageUpload
            label={'Splash screen'}
            imageUrl={field.value}
            onChangeImage={field.onChange}
            customPlaceholder={<ThumbnailSplashScreen />}
            imageClassName="rounded-lg overflow-clip !w-[3rem] !h-[5.25rem]"
            accept="image/png"
            errors={errors.splashScreen?.message}
            requiredLabel
            acceptedDimensions={{
              width: 1242,
              height: 2436,
            }}
            uploadOptions={{ original: true }}
          />
        )}
      />
      <div className="flex flex-col gap-2">
        <div>Branding color</div>
        <Controller
          control={control}
          name="primaryColor"
          render={({ field }) => (
            <BBBColorPicker
              label="Primary color"
              color={field.value || '#000000'}
              onChangeColor={field.onChange}
              width="60px"
              height="60px"
            />
          )}
        />
      </div>
    </div>
  );
}

function DeveloperAccount({
  control,
  isDisabled,
}: {
  control: Control<BrandingForm>;
  isDisabled: boolean | undefined;
}) {
  return (
    <div
      className="w-[92%] mx-auto grid grid-cols-2 gap-4 mt-6"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="w-full h-full">
        <img
          src={`${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/publish-app/developer-account.png`}
          alt="Developer Account"
          className="w-full h-full object-contain"
          loading="lazy"
        />
      </div>
      <div className="flex flex-col gap-3">
        <p className="break-words text-primary-main">
          At the moment, the app can <strong>only</strong> be published under
          the bitApp developer account. However, we&apos;re working on an update
          that will allow you to publish your app under your own developer
          account. Please stay tuned for further updates from us!
        </p>
        <div className="flex flex-col gap-2 opacity-50 pointer-events-none">
          <Controller
            control={control}
            name="accountType"
            render={({ field }) => (
              <BBBRadio
                vertical
                disabled={isDisabled}
                optionsClassName="gap-2"
                options={[
                  {
                    value: 'bitbybit_developer_account',
                    label: 'bitbybit developer account',
                  },
                  {
                    value: 'user_developer_account',
                    label: 'Your developer account',
                  },
                ]}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

function SubmitApp({
  onSubmit,
  isDisabled,
  submitText,
  isLoading,
}: {
  isDisabled: boolean | undefined;
  submitText: string;
  onSubmit: () => void;
  isLoading: boolean;
}) {
  return (
    <div
      className="w-[92%] mx-auto flex flex-col gap-6 mt-6"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="flex flex-col gap-2">
        <p className="text-ls text-neutral-60">
          1. Complete your app information.
        </p>
        <p className="text-ls text-neutral-60">2. Choose developer account.</p>
      </div>
      <div className="flex w-full justify-end items-end">
        <ShopifyAwareWrapper
          className="mb-4"
          appType="BITAPP"
          fallbackToChildren
        >
          {({ connectMiddleware }) => (
            <BBBButton
              text={submitText}
              onClick={() =>
                connectMiddleware(() => {
                  onSubmit();
                })
              }
              disabled={isDisabled}
              loadingState={isLoading}
            />
          )}
        </ShopifyAwareWrapper>
      </div>
    </div>
  );
}

function DownloadAppCard({
  urlAndroid,
  urlIos,
}: {
  urlAndroid: string | null | undefined;
  urlIos: string | null | undefined;
}) {
  return (
    <BBBCard title="Download your app here:">
      <div className="flex items-center gap-5">
        <div
          className="w-[8.375rem] h-[3rem] overflow-clip cursor-pointer"
          onClick={() => window.open(`${urlAndroid}`, '_blank')}
        >
          <img
            src={`${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/publish-app/download-playstore.png`}
            alt="Download Playstore"
            className="w-full h-full object-contain"
          />
        </div>
        <div
          className="w-[8.375rem] h-[3rem] overflow-clip cursor-pointer"
          onClick={() => window.open(`${urlIos}`, '_blank')}
        >
          <img
            src={`${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/publish-app/download-appstore.png`}
            alt="Download Appstore"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </BBBCard>
  );
}

function Step({ step, active }: { step: number; active: boolean }) {
  return (
    <div
      className={cx(
        'rounded-full w-6 h-6 flex items-center justify-center bg-primary-main opacity-20',
        active && 'opacity-100'
      )}
    >
      <p className="text-neutral-10 font-bold text-lg">{step}</p>
    </div>
  );
}

function ThumbnailSplashScreen() {
  return (
    <svg
      width="56"
      height="84"
      viewBox="0 0 56 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="83"
        rx="5.5"
        stroke="#707070"
        strokeDasharray="7 7"
      />
      <g clipPath="url(#clip0_2164_4861)">
        <path
          d="M41.5992 46.1476L37.7549 41.5008L32.9017 35.6317C32.7377 35.4335 32.5318 35.2739 32.2987 35.1644C32.0656 35.0549 31.8111 34.998 31.5534 34.998C31.2957 34.998 31.0412 35.0549 30.8081 35.1644C30.575 35.2739 30.3691 35.4335 30.2051 35.6317L25.3519 41.5008L25.2919 41.5719L23.5892 39.512C23.4252 39.3139 23.2193 39.1543 22.9862 39.0448C22.7531 38.9352 22.4986 38.8784 22.2409 38.8784C21.9831 38.8784 21.7286 38.9352 21.4955 39.0448C21.2624 39.1543 21.0565 39.3139 20.8925 39.512L17.6446 43.441L15.3943 46.1476C15.1825 46.4019 15.0478 46.7111 15.0061 47.0389C14.9643 47.3668 15.0172 47.6997 15.1585 47.9987C15.2998 48.2977 15.5237 48.5503 15.804 48.727C16.0842 48.9036 16.4091 48.997 16.7407 48.9962H40.2527C40.5843 48.997 40.9093 48.9036 41.1895 48.727C41.4697 48.5503 41.6936 48.2977 41.8349 47.9987C41.9762 47.6997 42.0291 47.3668 41.9874 47.0389C41.9456 46.7111 41.8109 46.4019 41.5992 46.1476Z"
          fill="#707070"
        />
      </g>
      <defs>
        <clipPath id="clip0_2164_4861">
          <rect
            width="27"
            height="14"
            fill="white"
            transform="translate(15 35)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
