import { createSlice } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { v4 } from 'uuid';

import { allAgentOptions } from '@/constants/bitChat/agent';
import {
  formatChatTicket,
  UpdateChatJoin,
  UpdateChatTransfer,
} from '@/hooks/whatsApp/chat';
import { Product } from '@/types/shopify/rest/products';
import { Chat, Media } from '@/types/whatsApp/chat';
import { MessageNewAssociation } from '@/types/whatsApp/v3';
import { emptyEditor } from '@/utils/common/rich';

export type IBitCRMLivechatMemo = {
  selectedReply: MessageNewAssociation | null;
  messageToSend: EditorState;
  mediaUrl: Media[];
  selectedMedia: string | null;
  orderDetailSearch: string;
  orderDetailStatus: 'all' | 'ongoing' | 'history';
  hideCopilot: boolean;
  recommendedProduct:
    | (Product & {
        url?: string;
      })
    | null;
  isTyping?: boolean;
};

export type SelectedChat = Chat & {
  messageId?: string;
};

export interface BitCRMStore {
  selectedChat: SelectedChat | null;
  liveChatMemo: { [k: string]: Partial<IBitCRMLivechatMemo> };
  activeStatus: 'waiting' | 'active' | null;
  zoom: {
    status: boolean;
    chatId?: string;
  };
  searchChat?: string;
  focusedChatId?: string;
  searchedMessageId?: { id: string };
  chatFilter:
    | {
        label: string;
        value: string;
        parentValue?: string;
        isNestedDropdown?: boolean;
        hasMore?: boolean;
        fetchNext?: () => void;
      }[]
    | undefined;
  apiKey: string | null;
  hasConnectedChannels: boolean | null;
  selectedInfoPanel?: string | null;
  activeSeparator: {
    label: string;
    show: boolean;
  } | null;
}

const activeStatusFromStorage = localStorage.getItem('activeStatus1');

const initialState: BitCRMStore = {
  selectedChat: null,
  liveChatMemo: {},
  //@ts-ignore
  activeStatus: activeStatusFromStorage,
  zoom: {
    status: false,
    chatId: undefined,
  },
  searchChat: undefined,
  searchedMessageId: undefined,
  chatFilter: [...allAgentOptions],
  selectedInfoPanel: 'customer-details',
  apiKey: null,
  hasConnectedChannels: null,
  activeSeparator: null,
};

export const bitCRMSlice = createSlice({
  name: 'bitCRM',
  initialState,
  reducers: {
    resetState: (state: BitCRMStore) => {
      Object.assign(state, initialState);
    },
    setSelectedLivechat: (
      state: BitCRMStore,
      { payload }: { payload: SelectedChat | null }
    ) => {
      state.selectedChat = payload;
      state.selectedInfoPanel = 'customer-details';
    },
    setZoom: (
      state: BitCRMStore,
      {
        payload,
      }: {
        payload: {
          status: boolean;
          chatId?: string;
        };
      }
    ) => {
      state.zoom = payload;
    },
    upsertLivechatMemo: (
      state: BitCRMStore,
      {
        payload: payloadFromProps,
      }: {
        payload: Partial<IBitCRMLivechatMemo>;
      }
    ) => {
      if (!state.selectedChat) return;

      const { ...payload } = payloadFromProps;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      state.liveChatMemo = {
        ...state.liveChatMemo,
        [id]: {
          ...state.liveChatMemo[id],
          ...payload,
        },
      };
    },
    applyQuickReplyWithMedia: (
      state: BitCRMStore,
      {
        payload,
      }: {
        payload: {
          message: EditorState;
          media: string;
          mimeType: string;
        };
      }
    ) => {
      if (!state.selectedChat) return;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      state.liveChatMemo = {
        ...state.liveChatMemo,
        [id]: {
          ...state.liveChatMemo[id],
          messageToSend: emptyEditor,
          mediaUrl: [
            {
              message: payload.message,
              loading: false,
              id: v4(),
              value: payload.media,
              type: payload.mimeType,
            },
          ],
        },
      };
    },
    addMedia: (
      state: BitCRMStore,
      {
        payload,
      }: {
        payload: Pick<IBitCRMLivechatMemo, 'mediaUrl'> & {
          defaultSelectAdded?: boolean;
          retainSelectedMedia?: boolean;
        };
      }
    ) => {
      if (!state.selectedChat) return;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      const mediaUrls = [
        ...(state.liveChatMemo[id].mediaUrl || []).filter(
          (prevMedia) =>
            !payload.mediaUrl.map((_media) => _media.id).includes(prevMedia.id)
        ),
        ...payload.mediaUrl.map((_media) => ({
          ...state.liveChatMemo[id].mediaUrl?.find(
            (previousMedia) => previousMedia.id === _media.id
          ),
          ..._media,
        })),
      ];

      state.liveChatMemo = {
        ...state.liveChatMemo,
        [id]: {
          ...state.liveChatMemo[id],
          mediaUrl: mediaUrls,
          ...(!payload.retainSelectedMedia && {
            selectedMedia: payload.defaultSelectAdded
              ? payload.mediaUrl[0].id
              : mediaUrls[0].id,
          }),
        },
      };
    },
    initUploadMedia: (
      state: BitCRMStore,
      {
        payload,
      }: {
        payload: string[];
      }
    ) => {
      if (!state.selectedChat) return;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      const appendedMedias: Media[] = payload.map((_mediaId) => ({
        id: _mediaId,
        loading: true as const,
        progress: 0,
      }));

      if (state.liveChatMemo[id]) {
        const updatedMedias = [
          ...(state.liveChatMemo[id].mediaUrl || []),
          ...appendedMedias,
        ];

        if (state.liveChatMemo[id].messageToSend) {
          updatedMedias[0].message = state.liveChatMemo[id].messageToSend;
          state.liveChatMemo[id].messageToSend = emptyEditor;
        }

        state.liveChatMemo = {
          ...state.liveChatMemo,
          [id]: {
            ...state.liveChatMemo[id],
            mediaUrl: updatedMedias,
            selectedMedia: updatedMedias[0].id,
          },
        };
      } else {
        state.liveChatMemo = {
          ...state.liveChatMemo,
          [id]: {
            mediaUrl: appendedMedias,
            selectedMedia: appendedMedias[0].id,
          },
        };
      }
    },
    updateMediaCaption: (
      state: BitCRMStore,
      { payload }: { payload: EditorState | undefined }
    ) => {
      if (!state.selectedChat) return;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      const mediaUrls = state.liveChatMemo[id].mediaUrl;

      state.liveChatMemo[id].mediaUrl = mediaUrls?.map((_media) => {
        if (_media.id === state.liveChatMemo[id].selectedMedia) {
          return {
            ..._media,
            message: payload || emptyEditor,
          };
        }
        return _media;
      });
    },
    filterLiveChatMemo: (
      state: BitCRMStore,
      { payload }: { payload: string }
    ) => {
      delete state.liveChatMemo[payload];
    },
    updateTypingStatus: (
      state: BitCRMStore,
      {
        payload,
      }: {
        payload: {
          clientNumber: string;
          source: Chat['sources'];
          isTyping: boolean;
        };
      }
    ) => {
      const id = `${payload.clientNumber}-${payload.source}`;

      state.liveChatMemo = {
        ...state.liveChatMemo,
        [id]: state.liveChatMemo[id]
          ? {
              ...state.liveChatMemo[id],
              isTyping: payload.isTyping,
            }
          : {
              isTyping: payload.isTyping,
            },
      };
    },
    setActiveStatus: (
      state: BitCRMStore,
      { payload }: { payload: BitCRMStore['activeStatus'] }
    ) => {
      state.activeStatus = payload;
    },
    setFocusedChatId: (state, action) => {
      state.focusedChatId = action.payload;
    },
    setSearchChat: (state, action) => {
      state.searchChat = action.payload;
    },
    setSearchedMessageId: (
      state,
      action: { payload: BitCRMStore['searchedMessageId'] }
    ) => {
      if (state.selectedChat) {
        const { messageId, ...selectedChat } = state.selectedChat;
        state.selectedChat = {
          ...selectedChat,
          messageId: action.payload?.id,
        };
      }
    },
    setChatFilter: (state, action) => {
      state.chatFilter = action.payload;
    },
    updateSelectedChat: (
      state,
      {
        payload: { payload, currentUser },
      }: {
        payload: {
          payload: UpdateChatJoin | UpdateChatTransfer;
          currentUser: {
            userId: number;
            userEmail: string;
            userAccent: string | null;
            userDisplayName: string;
            userProfilePicture: string | null;
          };
        };
      }
    ) => {
      if (state.selectedChat) {
        state.selectedChat = formatChatTicket(
          state.selectedChat,
          payload,
          currentUser
        );
      }
    },
    updateIncomingChat: (
      state,
      {
        payload,
      }: {
        payload: Chat;
      }
    ) => {
      if (state.selectedChat) {
        state.selectedChat = {
          ...state.selectedChat,
          ...payload,
        };
      }
    },
    setApiKey: (state, action: { payload: string | null }) => {
      state.apiKey = action.payload;
    },
    setHasConnectedChannels: (state, action: { payload: boolean | null }) => {
      state.hasConnectedChannels = action.payload;
    },
    setSelectedInfoPanel: (state, { payload }: { payload: string | null }) => {
      state.selectedInfoPanel = payload;
    },
    updateMediaUploadProgress: (
      state,
      action: { payload: Record<string, number> }
    ) => {
      if (!state.selectedChat) return;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      state.liveChatMemo = {
        ...state.liveChatMemo,
        [id]: {
          ...state.liveChatMemo[id],
          mediaUrl: state.liveChatMemo[id].mediaUrl?.map((media) => ({
            ...media,
            progress: action.payload[media.id],
          })),
        },
      };
    },
    updateMediaCompressionProgress: (
      state,
      action: { payload: Record<string, number> }
    ) => {
      if (!state.selectedChat) return;

      const id = `${state.selectedChat.clientNumber}-${state.selectedChat.sources}`;

      state.liveChatMemo = {
        ...state.liveChatMemo,
        [id]: {
          ...state.liveChatMemo[id],
          mediaUrl: state.liveChatMemo[id].mediaUrl?.map((media) => ({
            ...media,
            compressionProgress: action.payload[media.id],
          })),
        },
      };
    },
    setActiveSeparator: (
      state,
      action: { payload: { label: string; show: boolean } | null }
    ) => {
      state.activeSeparator = action.payload;
    },
    hideSeparator: (state) => {
      state.activeSeparator = state.activeSeparator
        ? {
            ...state.activeSeparator,
            show: false,
          }
        : null;
    },
  },
});

export const {
  setActiveStatus,
  setFocusedChatId,
  setSearchChat,
  setSearchedMessageId,
  filterLiveChatMemo,
  upsertLivechatMemo,
  setSelectedLivechat,
  resetState,
  setZoom,
  setChatFilter,
  addMedia,
  initUploadMedia,
  updateMediaCaption,
  updateTypingStatus,
  applyQuickReplyWithMedia,
  updateSelectedChat,
  updateIncomingChat,
  setApiKey,
  setHasConnectedChannels,
  setSelectedInfoPanel,
  updateMediaUploadProgress,
  updateMediaCompressionProgress,
  setActiveSeparator,
  hideSeparator,
} = bitCRMSlice.actions;

export default bitCRMSlice.reducer;
