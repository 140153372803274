import { useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { BBBModal, BBBTelInput, BBBTelInputValue } from '@/components';
import { useTestUnofficialTemplate } from '@/hooks/bitCRM/template/template';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import { formatPhonePayload } from '@/utils/common/phone';

type TestMessageModalProps = {
  show: boolean;
  onHide?: () => void;
  id?: string;
};

function TestMessageModal({ show, onHide, id }: TestMessageModalProps) {
  const phoneDefaultValue = useDefaultCountryCode();
  const [step, setStep] = useState(0);
  const [telInputValue, setTelInputValue] = useState<BBBTelInputValue>();

  const { mutate: test, isLoading } = useTestUnofficialTemplate();

  useEffect(() => {
    setTelInputValue(phoneDefaultValue || undefined);
  }, [phoneDefaultValue]);

  const onSave = () => {
    if (step === 0) {
      setStep(1);
    } else {
      test(
        {
          templateId: id!,
          number: formatPhonePayload(telInputValue)!,
        },
        {
          onSuccess: () => onHide?.(),
        }
      );
    }
  };

  return (
    <BBBModal
      show={show}
      onHide={onHide}
      title={step === 0 ? 'Test message' : 'Send test'}
      centerTitle
      footer
      submitText={step === 0 ? 'Test message' : 'Send test'}
      cancelText={step === 0 ? 'Cancel' : 'Change number'}
      onCancel={step === 0 ? undefined : () => setStep((prev) => prev - 1)}
      handleSave={onSave}
      disableSave={
        (step === 0 && !telInputValue?.countryCode) ||
        !telInputValue?.phoneNumber ||
        (step === 1 && isLoading)
      }
      centerFooter
    >
      {step === 0 ? (
        <BBBTelInput
          value={telInputValue}
          onChange={setTelInputValue}
          label="Phone number"
          error={
            !isPossiblePhoneNumber(`+${formatPhonePayload(telInputValue)}`)
              ? 'Field is required'
              : ''
          }
        />
      ) : (
        'Check your phone, and make sure the message from the test received'
      )}
    </BBBModal>
  );
}

export default TestMessageModal;
