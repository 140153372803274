import { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router';
import { twMerge as cx } from 'tailwind-merge';

import { PricingPlan } from '@/api/services/pricing';
import CheckCircle from '@/assets/icons/CheckCircle';
import { BBBButton, BBBButtonTypes, BBBNavigatorTab } from '@/components';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { highlightedCardText as highlightedCardTextBitAI } from '@/constants/pricing/bitAi';
import { highlightedCardText as highlightedCardTextBitApp } from '@/constants/pricing/bitApp';
import { highlightedCardText as highlightedCardTextBitChat } from '@/constants/pricing/bitChat';
import { highlightedCardText as highlightedCardTextBitCrm } from '@/constants/pricing/bitCRM';
import { highlightedCardText as highlightedCardTextBitLink } from '@/constants/pricing/bitLink';
import { highlightedCardText as highlightedCardTextBitLogin } from '@/constants/pricing/bitLogin';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import useIsPricingActive from '@/hooks/pricing/useIsPricingActive';
import usePricingOptions from '@/hooks/pricing/usePricingOptionsByApp';
import useRedirectUpgradePlan from '@/hooks/pricing/useRedirectUpgradePlan';
import {
  mapPlanTypeToHumanReadable,
  usePlanTypeQueryParams,
} from '@/hooks/pricing/v2/pricing';
import { AppType } from '@/types/systemLogin';

const mapHighlightedCardDescription: Record<
  AppType,
  Partial<Record<PricingPlan, string[]>>
> = {
  BITLINK: highlightedCardTextBitLink,
  BITAPP: highlightedCardTextBitApp,
  BITCHAT: highlightedCardTextBitChat,
  BITCRM: highlightedCardTextBitCrm,
  BITLOGIN: highlightedCardTextBitLogin,
  BITAI: highlightedCardTextBitAI,
};

type Props = {
  active: AppType;
};

export default function CardSection(props: Props) {
  const { data: isRestricted } = useIsModuleRestricted(
    'PRICING_UPGRADE_DOWNGRADE'
  );

  return <_CardSection {...props} isRestricted={!!isRestricted} />;
}

function _CardSection({
  active,
  isRestricted,
}: Props & {
  active: AppType;
  isRestricted: boolean;
}) {
  const { isMaximumLevel, pricingName, isLoading } = useIsMaximumLevel(active);

  const { isPlanActive, index } = useIsPricingActive(active);

  const planType = usePlanTypeQueryParams();

  const { data: renderedPlan } = usePricingOptions(active, planType);

  const { fn: redirectUpgradePlan } = useRedirectUpgradePlan({
    appType: active,
    type: planType,
  });

  const filteredPlan =
    (isMaximumLevel && pricingName === 'enterprise'
      ? //@ts-ignore
        renderedPlan?.filter((plan) => plan.name === 'enterprise')
      : renderedPlan) || [];

  return (
    <Wrapper appType={active}>
      <div className="flex justify-center flex-wrap gap-5">
        {isLoading ? (
          <div className="flex flex-wrap gap-4 justify-center">
            {Array.from({ length: 5 }).map((_, idx) => (
              <Skeleton
                height={450}
                width={288}
                borderRadius={'1rem'}
                key={idx}
              />
            ))}
          </div>
        ) : (
          //@ts-ignore
          filteredPlan.map((pricing, idx) => (
            <Card
              isActive={isPlanActive(pricing.name)}
              pricingName={pricing.name}
              active={active}
              pricingLabel={pricing.label}
              pricingPrice={pricing.price}
              isRestrictedToUpgrade={!!isRestricted}
              descriptionTitle={
                pricing.name === 'free'
                  ? 'Features:'
                  : pricing.name !== 'enterprise'
                  ? `Everything in ${filteredPlan[idx - 1].label}, plus:`
                  : undefined
              }
              key={pricing.name}
              onUpgrade={(connectMiddleware) =>
                connectMiddleware((data) =>
                  redirectUpgradePlan(pricing.name, data.domain)
                )
              }
              variant={idx > index ? 'primary' : 'secondary'}
            />
          ))
        )}
      </div>
    </Wrapper>
  );
}

const mapAppHeadline: Record<
  AppType,
  { headline: ReactNode; description: string }
> = {
  BITLOGIN: {
    headline: (
      <>
        Faster Login, Higher{' '}
        <span className="text-secondary-main">Conversion</span>
      </>
    ),
    description: 'Hassle-free shopping experience. Set up in minutes.',
  },
  BITAPP: {
    headline: (
      <>
        Mobile App builder with best{' '}
        <span className="text-secondary-main">ROI</span>
      </>
    ),
    description: 'Start with Free. Only Upgrade when you are ready.',
  },
  BITLINK: {
    headline: (
      <>
        Transform your follower into loyal{' '}
        <span className="text-secondary-main">customer</span>
      </>
    ),
    description: 'Bring your store to social media.',
  },
  BITCRM: {
    headline: (
      <>
        Power <span className="text-secondary-main">Smarter</span> Digital
        Relationships
      </>
    ),
    description:
      'Create personalized customer experiences across WhatsApp, Instagram, mobile push with real-time data and AI-driven insights.',
  },
  BITCHAT: {
    headline: (
      <>
        Make the most out of{' '}
        <span className="text-secondary-main">WhatsApp + AI</span> to grow your
        business
      </>
    ),
    description: 'The all-in-one AI Customer Support Platform.',
  },
  BITAI: {
    headline: (
      <>
        Ecommerce Sales on{' '}
        <span className="text-secondary-main">Autopilot</span>: Automate &
        Optimize Your Merchandising Strategy
      </>
    ),
    description:
      'Stop wasting time on manual tasks. Our AI-powered tool sets you up for success.',
  },
};

function Wrapper({
  children,
  appType,
}: {
  children: ReactNode;
  appType: AppType;
}) {
  const { headline, description } = mapAppHeadline[appType];
  const queryParam = useQuerySearchParams();
  const location = useLocation();

  const plan = queryParam.get('duration') || 'monthly';

  const history = useHistory();

  const planDuration = [
    { label: 'Monthly', value: 'monthly' },
    ...(!['BITAPP'].includes(appType)
      ? [{ label: 'Annual', value: 'annual' }]
      : []),
  ];

  return (
    <div className="mb-20">
      <div className="flex justify-center">
        <div className="text-4xl font-medium text-center mb-3 max-w-3xl">
          {headline}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="text-[#757575] text-xl mb-6 max-w-2xl text-center">
          {description}
        </div>
      </div>

      <div className="flex justify-center mb-12">
        <BBBNavigatorTab
          navigationLists={planDuration}
          optionLabel="label"
          optionValue="value"
          value={planDuration.find((list) => list.value === plan)}
          onChange={({ value }) => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('duration', value);

            const newSearch = searchParams.toString();

            history.push({ pathname: '/pricing', search: newSearch });
          }}
        />
      </div>

      {children}
    </div>
  );
}

function Card({
  isActive,
  pricingName,
  active,
  pricingLabel,
  pricingPrice,
  isRestrictedToUpgrade,
  onUpgrade,
  variant,
  descriptionTitle,
}: {
  isActive: boolean;
  pricingName: PricingPlan;
  active: AppType;
  pricingLabel: string | null;
  pricingPrice: string | number;
  isRestrictedToUpgrade: boolean;
  onUpgrade?: (
    connectMiddleware: (fn: (data: { domain: string }) => void) => void
  ) => void;
  variant?: BBBButtonTypes['variant'];
  descriptionTitle?: string;
}) {
  const planType = usePlanTypeQueryParams();

  const highlights = mapHighlightedCardDescription[active]?.[pricingName];

  return (
    <div
      className={cx(
        `flex-none w-72 outline outline-transparent outline-2 hover:outline-black transition-[outline] duration-500 relative flex flex-col bg-white shadow px-5 pt-10 pb-8 rounded-2xl`,
        isActive && 'outline-black'
      )}
    >
      {isActive && (
        <div className="absolute top-0 right-0 rounded-2xl rounded-tl-none rounded-br-none bg-black  text-white font-medium px-4 py-2">
          Your current plan
        </div>
      )}
      <div className="text-xl font-medium mb-5">{pricingLabel}</div>
      <div className="mb-5">
        <span className="text-[40px] font-semibold text-secondary-main">
          {pricingName !== 'enterprise' ? `$${pricingPrice}` : 'Custom'}
        </span>{' '}
        {pricingName !== 'enterprise' && (
          <span className="text-[#9E9E9E] text-xl">
            /{mapPlanTypeToHumanReadable[planType]}
          </span>
        )}
      </div>
      {!isRestrictedToUpgrade && !isActive && (
        <ShopifyAwareWrapper
          className="mb-4"
          appType={active}
          fallbackToChildren
          skipSocketDisconnectOnSuccess
        >
          {({ connectMiddleware }) => (
            <BBBButton
              onClick={() => onUpgrade?.(connectMiddleware)}
              variant={variant}
              text={
                pricingName === 'enterprise'
                  ? 'Contact us'
                  : `Choose ${pricingLabel}`
              }
              width="full"
            />
          )}
        </ShopifyAwareWrapper>
      )}
      <div className="border outline-t outline-[#DDDDDD] mb-4" />
      {highlights?.length && (
        <>
          {descriptionTitle && (
            <div className="font-medium mb-4.5">{descriptionTitle}</div>
          )}
          {highlights.map((desc) => (
            <div
              key={`${pricingName}-${desc}`}
              className="flex gap-2 mb-4 last:mb-0"
            >
              <div className="flex-none">
                <CheckCircle />
              </div>
              {desc}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
