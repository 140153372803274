import React, { Ref, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router';
import { FormSchema } from '..';
import Params from './Params';

import { BBBSelect } from '@/components';
import { useInfiniteTemplates } from '@/hooks/bitCRM/template/template';
import useHistory from '@/hooks/common/useHistory';
import { AvailableSources } from '@/hooks/whatsApp/useAvailableSources';
import { AutomationFormSchema } from '@/pages/BitCRM/Automation/New/validation';
import { typePayload } from '@/pages/BitCRM/Template/WhatsApp/New';
import { CampaignTemplate } from '@/types/bitCRM/template';

type Props = {
  channel: AvailableSources;
  control: Control<FormSchema, any> | Control<AutomationFormSchema, any>;
  setValue: UseFormSetValue<FormSchema> | UseFormSetValue<AutomationFormSchema>;
  type1?: 'campaign' | 'automation' | 'chat';
  formContext: 'automation' | 'campaign';
  onClickRedirect?: (cb: () => void) => void;
  isMenuOpen?: boolean;
  setIsMenuOpen?: (value: React.SetStateAction<boolean>) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  enabled?: boolean;
};

const TemplateOptions = (
  {
    channel,
    setValue,
    control,
    type1 = 'campaign',
    formContext,
    onClickRedirect,
    error,
    enabled,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>
) => {
  //@ts-ignore
  const data = useWatch({ control });
  //@ts-ignore
  const { errors } = useFormState({ control });

  const history = useHistory();
  const { pathname } = useLocation();

  const [search, setSearch] = useState('');

  const {
    data: templateData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteTemplates(
    {
      size: 10,
      search,
      type: typePayload(type1, channel),
      status: channel === 'whatsapp-cloud' ? 'APPROVED' : undefined,
    },
    { enabled }
  );

  const unifiedTemplateData = useMemo<CampaignTemplate[] | undefined>(
    () => templateData?.pages.flatMap((page) => page.content),
    [templateData?.pages]
  );

  const redirectCb = () => {
    history.push({
      pathname: `/bitcrm/template/${channel}/new`,
      search: '?type=campaign',
      state: {
        key: 'shortcut-create-template',
        from: pathname,
        data,
      },
    });
  };

  return (
    <>
      <Controller
        //@ts-ignore
        control={control}
        //@ts-ignore
        name={formContext === 'campaign' ? 'template' : 'body.template'}
        render={({ field }) => (
          <BBBSelect
            options={unifiedTemplateData || []}
            optionValue="id"
            optionLabel="templateName"
            label="Message template"
            ref={ref}
            withCreateRedirectOption
            onClickCreateRedirect={() => {
              if (typeof onClickRedirect !== 'undefined') {
                onClickRedirect(redirectCb);
              } else {
                redirectCb();
              }
            }}
            persistCreateRedirectOption
            createRedirectLabel="Create new template"
            search={search}
            isSearchable
            onChangeSearch={setSearch}
            isPaginated
            hasMore={!!hasNextPage}
            fetchNext={fetchNextPage}
            value={field.value || null}
            onValueChange={field.onChange}
            error={
              error ||
              (formContext === 'campaign'
                ? //@ts-ignore
                  errors.template?.message
                : //@ts-ignore
                  errors.body?.template.message)
            }
            {...props}
          />
        )}
      />

      <Params
        control={control}
        formContext={formContext}
        channel={channel}
        setValue={setValue}
      />
    </>
  );
};

export default React.forwardRef(TemplateOptions);
