import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '..';

import ChatIcon from '@/assets/icons/ChatIcon';
import WhatsAppIcon2 from '@/assets/icons/WhatsAppIcon2';
import {
  BBBCard,
  BBBCheckbox,
  BBBColorPicker,
  BBBTextInput,
} from '@/components';
import { BBBRadio } from '@/components/BBBRadio';
import {
  buttonPositionOptions,
  buttonSizeOptions,
  buttonWidgetCategories,
} from '@/constants/bitChat/waWidget';

type Props = {
  control: Control<FormSchema>;
  error: FieldErrors<FormSchema>;
  loading: boolean;
};

export default function ButtonWidget({ control, error, loading }: Props) {
  const textButtonWidgetEnabled = useWatch({
    control,
    name: 'showWidgetText',
  });

  return (
    <BBBCard title="Button Widget" className="mb-cardBottom">
      <div className="mb-4">Style</div>
      <div className="flex gap-10 mb-6">
        {buttonWidgetCategories.map((category, index) => (
          <div key={index}>
            <Controller
              control={control}
              name="widgetButton"
              render={({ field }) => {
                return (
                  <ButtonStyle
                    selected={
                      category.icon === field.value.iconType &&
                      category.shape === field.value.radiusType
                    }
                    shape={category.shape}
                    onClick={function (): void {
                      field.onChange({
                        iconType: category.icon,
                        radiusType: category.shape,
                      });
                    }}
                    icon={category.icon}
                  />
                );
              }}
            />
          </div>
        ))}
      </div>
      <Controller
        control={control}
        name="showWidgetText"
        render={({ field }) => (
          <BBBCheckbox
            label="Show text button widget"
            checked={field.value}
            onValueChange={field.onChange}
            disabled={loading}
            className="mb-3"
          />
        )}
      />
      <BBBTextInput
        isHookForm
        control={control}
        containerClassname="mb-6"
        error={error?.widgetText?.message}
        controlName="widgetText"
        placeholder="Get in Touch"
        disabled={loading || !textButtonWidgetEnabled}
        hasMaxCharLabel
        maxChar={20}
      />
      <div className="mb-3 text-neutral-60">Widget color</div>
      <Controller
        control={control}
        name="themeColor"
        render={({ field }) => (
          <BBBColorPicker
            color={field.value}
            onChangeColor={field.onChange}
            width="40px"
            height="40px"
            className="mb-6"
            withInput
          />
        )}
      />
      <Controller
        control={control}
        name="position"
        render={({ field }) => (
          <BBBRadio
            label="Button position"
            options={buttonPositionOptions}
            value={field.value === 'left' ? 'left' : 'right'}
            onChange={(value) => field.onChange(value)}
            containerClassName="mb-6"
          />
        )}
      />
      <Controller
        control={control}
        name="size"
        render={({ field }) => (
          <>
            <div className="mb-6">Button size</div>
            <div className="flex gap-4">
              {buttonSizeOptions.map((size) => (
                <div
                  key={size.value}
                  className={cx(
                    'rounded-lg flex-1 px-4 h-[6.25rem] border-[1.5px] cursor-pointer transition-colors border-neutral-30 flex items-center gap-4',
                    field.value === size.value && 'border-secondary-main'
                  )}
                  onClick={() => {
                    field.onChange(size.value);
                  }}
                >
                  <ButtonStyle shape="circle" size={size.value} />
                  {size.label}
                </div>
              ))}
            </div>
          </>
        )}
      />
    </BBBCard>
  );
}

function ButtonStyle({
  selected,
  shape,
  onClick,
  icon = 'chat',
  size = 'regular',
}: {
  selected?: boolean;
  shape: string;
  onClick?: () => void;
  icon?: string;
  size?: string;
}) {
  return (
    <div
      className={cx(
        'bg-primary-main cursor-pointer',
        selected ? 'border-4 border-secondary-main' : 'border-none',
        shape === 'circle' ? 'rounded-full' : 'rounded-lg',
        typeof onClick === 'undefined' && 'pointer-events-none',
        size === 'regular' ? 'p-5' : size === 'small' ? 'p-4' : 'p-7'
      )}
      onClick={onClick}
    >
      {icon === 'whatsapp' ? (
        <WhatsAppIcon2 width={25} height={25} fill="#FFFF" />
      ) : (
        <ChatIcon width={25} height={25} />
      )}
    </div>
  );
}
