import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import services from '@/api/services';
import { UpsertLoginSettingPayload } from '@/api/services/bitLogin/login/setting';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { toast } from '@/utils/common/toast';

export const useLoginSetting = () => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    ['bitlogin-login-setting', data?.domain],
    () => services.bitLogin.login.setting.getLoginSetting(data?.domain),
    {
      enabled: status === 'success',
    }
  );
};

export const useLoginSetting2 = (domain: string | undefined) => {
  return useQuery(
    ['bitlogin-login-setting-with-domain', domain],
    () => services.bitLogin.login.setting.getLoginSetting(domain),
    {
      enabled: !!domain,
    }
  );
};

export const useUpsertLoginSetting = (
  options?: Omit<
    UseMutationOptions<void, unknown, UpsertLoginSettingPayload, unknown>,
    'mutationFn'
  >
) => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UpsertLoginSettingPayload, unknown>(
    (payload: UpsertLoginSettingPayload) =>
      services.bitLogin.login.setting.upsertLoginSetting(data?.domain, payload),
    {
      ...options,
      onMutate: async (payload) => {
        await queryClient.cancelQueries([
          'bitlogin-login-setting',
          data?.domain,
        ]);

        const previousLoginSetting = queryClient.getQueryData([
          'bitlogin-login-setting',
          data?.domain,
        ]);

        queryClient.setQueryData<UpsertLoginSettingPayload[]>(
          ['bitlogin-login-setting', data?.domain],
          (old: UpsertLoginSettingPayload[] = []) => {
            return old.map((setting) =>
              setting.type === payload.type
                ? { ...setting, ...payload }
                : setting
            );
          }
        );

        return { previousLoginSetting };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-login-setting']);
        toast.success('Successfully update login setting');
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['bitlogin-login-setting', data?.domain],
          (context as { previousLoginSetting?: UpsertLoginSettingPayload[] })
            ?.previousLoginSetting
        );
      },
    }
  );
};
