import { PricingPlan } from 'api/services/pricing';

export const highlightedCardText: Partial<Record<PricingPlan, string[]>> = {
  free: [
    'Unlimited logins per day',
    'Customizable login button',
    'Sign in with Facebook',
    'Sign in with Google',
    'Implement social login in mobile app with bitApp',
  ],
  standard: ['Use your own Google token', 'Google One Tap', 'Analytics'],
};
