import { BBBCard } from '@/components';

type AuthCardProps = {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  withoutLogo?: boolean;
};

function AuthCard({
  children,
  title,
  description,
  withoutLogo,
}: AuthCardProps) {
  return (
    <BBBCard className="grow h-full min-h-full px-5 py-2 w-full md:w-[614px] md:px-[64px] flex flex-col justify-center">
      {!withoutLogo && (
        <img
          src={'/bbb-logo.png'}
          alt="logo-bitbybit"
          width={123}
          height={39}
          className="mb-10"
        />
      )}
      <div className="flex flex-col mb-10">
        {title && (
          <div className="text-4.3xl text-primary-main font-medium">
            {title}
          </div>
        )}
        {description && (
          <div className="mt-3 text-xl text-primary-main/50">{description}</div>
        )}
      </div>

      {children}
    </BBBCard>
  );
}

export default AuthCard;
