import { Component, ReactNode, useEffect, useState } from 'react';
import { FileText } from 'react-feather';
import { FiDownload } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import { Document, Page, pdfjs } from 'react-pdf';
import { twMerge as cx } from 'tailwind-merge';
import ChatInfo from '../Info';
import { MediaLoading } from '..';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { BBBTooltip } from '@/components';
import colors from '@/constants/common/colors';
import { fileNameFromUrl, getMimeType } from '@/utils/common/file';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ChatBubbleDocument({
  mediaUrl,
  isLoading,
  error,
  fromMe,
}: {
  mediaUrl?: string | null;
  isLoading?: boolean;
  error?: unknown;
  fromMe: boolean;
}) {
  return (
    <>
      {isLoading ? (
        <MediaLoading fromMe={!!fromMe} />
      ) : error ? (
        <ChatInfo />
      ) : (
        mediaUrl && <_ChatBubbleDocument fromMe={fromMe} mediaUrl={mediaUrl} />
      )}
    </>
  );
}

function _ChatBubbleDocument({
  fromMe,
  mediaUrl,
}: {
  fromMe?: boolean;
  mediaUrl: string;
}) {
  const [mimeType, setMimeType] = useState();

  useEffect(() => {
    (async () => {
      try {
        const mimetype = await getMimeType(encodeURI(mediaUrl));
        setMimeType(mimetype);
      } catch (err) {
        /** */
      }
    })();
  }, [mediaUrl]);

  const fileName = fileNameFromUrl(mediaUrl);

  const handleDownloadDocument = () => {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('target', '_blank');
    downloadLink.href = mediaUrl!;
    downloadLink.click();
  };

  const downloadFile = (url: string, filename = ''): void => {
    if (filename.length === 0) filename = url.split('/').pop()!;
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'blob';
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: 'application/pdf',
      });

      // @ts-expect-error
      const isIE = false || !!document.documentMode;
      if (isIE) {
        (window.navigator as any).msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', filename);
        a.setAttribute('href', href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  return (
    <ErrorBoundary>
      <div
        className={cx(
          'text-sm rounded-lg transition-[background] flex flex-col cursor-pointer',
          fromMe ? 'hover:bg-secondary-surface' : 'hover:bg-gray-50'
        )}
        onClick={handleDownloadDocument}
      >
        <div className="w-96 rounded-t-md cursor-pointer overflow-hidden scrollbar-hide">
          {mimeType === 'application/pdf' && (
            <PDFRenderer mediaUrl={mediaUrl} />
          )}
        </div>
        <div
          className={cx(
            'w-full flex justify-between items-center py-2.5 px-2 rounded-b-md gap-2',
            fromMe ? 'bg-secondary-border' : 'bg-neutral-20'
          )}
        >
          <div className="text-sm flex items-center truncate">
            <div className="mr-3 mb-1">
              <FileText />
            </div>
            {fileName && (
              <BBBTooltip content={decodeURIComponent(fileName)} show>
                <div className="line-clamp-1">
                  {decodeURIComponent(fileName)}
                </div>
              </BBBTooltip>
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              downloadFile(
                mediaUrl,
                fileNameFromUrl(mediaUrl) || 'document.pdf'
              );
            }}
          >
            <FiDownload color={colors.neutral[60]} size={16} />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

function PDFRenderer({ mediaUrl }: { mediaUrl: string }) {
  return (
    <Document
      file={mediaUrl}
      loading={<Skeleton height={'13rem'} />}
      className="h-52 w-96"
    >
      <Page pageNumber={1} scale={0.6} />
    </Document>
  );
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Error caught by Error Boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <span className="text-red-500 text-sm">
          Something went wrong when rendering component. This is likely server
          issues.
        </span>
      );
    }

    return this.props.children;
  }
}
