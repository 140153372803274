import { useMemo, useState } from 'react';

import { BBBButton, BBBModal, BBBTextInput } from '@/components';
import useDeleteUser from '@/hooks/auth/useDeleteUser';

type DeleteCompanyModalProps = {
  show: boolean;
  onChangeShow?: (val: boolean) => void;
};

function DeleteUserModal({ show, onChangeShow }: DeleteCompanyModalProps) {
  const [text, setText] = useState('');
  const { mutate: deleteUser, isLoading: loadingDelete } = useDeleteUser();

  const truthyText = useMemo(() => `Yes, I want to delete my account`, []);

  const isTextTruthy = useMemo(() => text === truthyText, [text, truthyText]);

  return (
    <BBBModal
      title="Delete account"
      show={show}
      onHide={() => onChangeShow?.(false)}
      centerTitle
      centerBody
      size="md"
      footer
      centerFooter
      submitText={'Delete user'}
      disableSave={!isTextTruthy || loadingDelete}
      handleSave={() => deleteUser()}
      submitVariant={'danger'}
    >
      <div className="font-light text-center">
        Are you sure to delete your account?
      </div>
      <div className="font-light text-center mb-4">
        Your action cannot be undone.
      </div>
      <div className="font-light text-center">Type to continue:</div>
      <div className="font-bold text-center mb-6">{truthyText}</div>
      <BBBTextInput
        value={text}
        onChange={({ target: { value } }) => setText(value)}
        placeholder="Type the sentence here"
      />
    </BBBModal>
  );
}

export default DeleteUserModal;
