import { useHistory, useLocation } from 'react-router';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import services from '@/api/services';
import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany, useCompany } from '@/hooks/rtk/selector';
import { FormSchema } from '@/pages/BitCRM/Campaign/Whatsapp';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { toast } from '@/utils/common/toast';

const TEMPLATE_QUERY_KEY = 'bitcrm-template' as const;

export const useTemplate = (id?: string | null) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-template', id],
    () =>
      services.bitCRM.v2.template.getTemplate(id!, {
        companyId: activeCompany,
      }),
    {
      enabled: !!id && id !== 'new',
    }
  );
};

export const useTemplates = (
  params?: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    type?: CampaignTemplate['type'];
    status?: string;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-templates', activeCompany, params],
    () =>
      services.bitCRM.v2.template.getTemplates(activeCompany, {
        ...params,
        status,
      }),
    {
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useAIGeneratedTemplate = () => {
  const company = useCompany();

  return useMutation((payload: { language: string; context: string }) =>
    services.bitCRM.v2.template.generateAiTemplate({
      ...payload,
      companyId: company.id,
      companyName: company.name,
      companyIndustry: company.industry,
    })
  );
};

export const useTestUnofficialTemplate = () =>
  useMutation(
    async (payload: { templateId: string; number: string }) =>
      api.bitCRM.v2.post(`/whatsapp_template/test`, {
        templateId: payload.templateId,
        number: payload.number,
      }),
    {
      onSuccess: () => {
        toast.success(`Test campaign sent`);
      },
    }
  );

export const useCreateOrUpdateTemplate = () => {
  const client = useQueryClient();
  const activeCompany = useActiveCompany();
  const history = useHistory();
  const location = useLocation<{
    from: string;
    data: Pick<
      FormSchema,
      'campaignName' | 'isScheduled' | 'customerSegment' | 'date'
    >;
  }>();

  return useMutation(
    ({
      type,
      leaveCb,
      ...payload
    }: Partial<CampaignTemplate> & {
      leaveCb?: () => void;
    }) =>
      api.bitCRM.v2.post(
        `/${
          type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
          type === 'AUTOMATION_WHATSAPP_CLOUD_API'
            ? 'whatsapp_cloud_api'
            : 'campaign_template'
        } `,
        {
          ...payload,
          companyId: activeCompany,
          type,
        }
      ),
    {
      onSuccess: (_, payload) => {
        client.invalidateQueries(['bitcrm-template']);
        client.invalidateQueries(['bitcrm-templates']);
        toast.success(
          `Sucesfully ${payload.id ? 'update' : 'create'}${
            payload.type === 'CAMPAIGN_WHATSAPP'
              ? payload.isDraft
                ? ' draft'
                : ''
              : ''
          } template`
        );

        if (payload.leaveCb) {
          payload.leaveCb();
        } else {
          if (location.state?.from) {
            history.push(location.state.from, {
              data: location.state.data,
            });
          } else {
            history.push('/bitcrm/template');
          }
        }
      },
    }
  );
};

export const useInfiniteTemplates = (
  {
    size = 10,
    sort,
    search,
    type,
    status,
  }: {
    size?: number;
    sort?: string;
    search?: string;
    type?: CampaignTemplate['type'];
    status?: string;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    [
      'infinite-bitcrm-templates',
      activeCompany,
      {
        size,
        sort,
        search,
        type,
        status,
      },
    ],
    ({ pageParam = 0 }) =>
      services.bitCRM.v2.template.getTemplates(activeCompany!, {
        size,
        page: pageParam,
        sort,
        search,
        type,
        status,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.empty
          ? undefined
          : lastPage.last
          ? undefined
          : lastPage.number + 1,
      ...options,
    }
  );
};

export const useDeleteTemplate = () => {
  const toggleConfirmation = useConfirmationModal();
  const queryClient = useQueryClient();
  const activeCompany = useActiveCompany();

  const deleteTemplateMutation = useMutation(
    (payload: { id: string }) =>
      services.bitCRM.v2.template.deleteTemplate(payload.id, {
        companyId: activeCompany,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success('Your template has been deleted');
        queryClient.invalidateQueries(['bitcrm-template']);
        queryClient.invalidateQueries(['bitcrm-templates']);
      },
    }
  );

  function deleteTemplate(withConfirmation: true): (param: string) => void;
  function deleteTemplate(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<
        AxiosResponse<unknown>,
        unknown,
        { id: string },
        unknown
      > {
    return withConfirmation
      ? (id) =>
          toggleConfirmation({
            title: 'Delete template?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteTemplateMutation.mutate(
                {
                  id,
                },
                {
                  onSuccess: () => {
                    hide();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteTemplateMutation;
  }

  return deleteTemplate;
};

export const useParametersOptions = (
  type: 'campaign' | 'automation',
  options?: { enabled?: boolean }
) => {
  return useQuery(
    [`${TEMPLATE_QUERY_KEY}-parameters-options`, type],
    () => services.bitCRM.v2.template.getParametersOptions(type),
    options
  );
};

export const useParametersOptionsHash = (
  type: 'campaign' | 'automation',
  options?: { enabled?: boolean }
) => {
  return useQuery(
    [`${TEMPLATE_QUERY_KEY}-parameters-options`, type],
    () => services.bitCRM.v2.template.getParametersOptions(type),
    {
      ...options,
      select: (data) =>
        Object.fromEntries(
          data.map((parameter) => [parameter.value, parameter]) || []
        ),
    }
  );
};

export const useLanguages = (options?: { enabled?: boolean }) =>
  useQuery(
    ['bitcrm-language'],
    () =>
      api.bitCRM.v2
        .get<
          {
            code: string;
            language: string;
          }[]
        >(`/whatsapp_cloud_api/language-list`)
        .then((res) =>
          res.data
            .sort((a, b) => a.language.localeCompare(b.language))
            .map((lang) => ({
              label: lang.language,
              value: lang.code,
            }))
        ),
    options
  );

export const useHasNewTemplate = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['count-new-template', activeCompany],
    () => services.bitCRM.v2.template.getCountNewTemplate(activeCompany),
    {
      select: (data) => data > 0,
    }
  );
};

export type DynamicVariables = {
  group: string;
  label: string;
  value: string;
  placeholder: string;
};

export const useDynamicVariables = (onlyCustomer?: boolean) => {
  return useQuery(['bitcrm-dynamic-variables'], () =>
    api.bitCRM.v2
      .get<DynamicVariables[]>(`/app/variables`)
      .then((res) =>
        onlyCustomer
          ? res.data.filter(
              (_data) =>
                _data.value.toLowerCase().includes('customer') &&
                _data.value.toLowerCase().includes('name')
            )
          : res.data
      )
  );
};
