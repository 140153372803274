import { useInfiniteQuery } from '@tanstack/react-query';
import getProducts from 'graphql/query/getProducts';
import useGraphQLRequest from '../useGraphQLRequest';

import ShopifyConnection from '@/types/shopify/ShopifyConnection';
import ShopifyProducts from '@/types/shopify/ShopifyProduct';

const useProducts = (query: Record<string, string | undefined>) => {
  const { request, enabled } = useGraphQLRequest();

  const requestQuery =
    Object.entries(query)
      .filter(([key, value]) => !!value?.length)
      ?.map(([key, val]) => `${key}:${val}`)
      ?.join(' AND ') || undefined;

  return useInfiniteQuery<ShopifyConnection<ShopifyProducts>>(
    ['bitapp-infinite-products', query],
    async ({ pageParam }) => {
      const data = await request({
        query: getProducts,
        variables: {
          first: 10,
          after: pageParam,
          query: requestQuery,
        },
      });
      return data?.products;
    },
    {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.pageInfo.hasNextPage) {
          return lastPage?.pageInfo.endCursor;
        }
        return null;
      },
    }
  );
};

export default useProducts;
