import { useState } from 'react';
import { Link } from 'react-router-dom';
import { env } from 'config/env';
import { KnownIntegration } from 'constants/integrations';
import useConnectIntegration from 'hooks/common/useConnectIntegration';
import { useOnboarding } from 'hooks/onboarding/bitchat';
import { useUpdateOnboarding } from 'hooks/onboarding/onboarding';
import OnboardingPage from 'layouts/OnboardingPage';
import {
  mapKnownIntegrationsToCard,
  ShopifyCardBitChat,
} from 'pages/Integrations';
import { BBBButton, BBBCard } from 'components';
import BBBAccordion from 'components/BBBAccordion';
import { cn } from 'utils/styles';

export default function BitChatOnboarding() {
  const { keyPoints, dismissed } = useOnboarding();

  const completeCount = keyPoints.filter(
    (point) => point.completed || point.skipped
  ).length;

  return (
    <OnboardingPage
      keyPoints={keyPoints}
      completeCount={completeCount}
      app="BITCHAT"
      dismissed={!!dismissed}
    >
      {({ keyPoint, completed, skipped, meta }) => (
        <>
          {keyPoint === 'unified_messaging' && <UnifiedMessaging />}
          {keyPoint === 'shopify_integration' && <ShopifyIntegrations />}
          {keyPoint === 'team_collaboration' && (
            <TeamCollab completed={!!completed} skipped={!!skipped} />
          )}
          {keyPoint === 'chatbot' && (
            <Chatbot //@ts-ignore
              meta={meta!}
            />
          )}
          {keyPoint === 'chat_widget' && (
            <Widget completed={!!completed} skipped={!!skipped} />
          )}
        </>
      )}
    </OnboardingPage>
  );
}

const unifiedMessagingIntegrations: Exclude<KnownIntegration, 'shopify'>[] = [
  'whatsapp_meta',
  'whatsapp',
  'instagram',
  'facebook',
];

function ShopifyIntegrations() {
  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-shopify-integrations.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Boost sales with Shopify integration</div>
      <p className="mb-3">
        You&apos;ve connected your favorite chat channels - awesome! Now,
        let&apos;s integrate your Shopify store to unlock the full potential of
        bitChat.
      </p>
      <p className="mb-3">
        Benefits of connecting Shopify:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Effortless Product Info:</span> Answer
            customer product questions directly within chat using Shopify
            product data.
          </li>
          <li>
            <span className="font-bold">Streamlined Orders:</span> Manage orders
            and track fulfillment status right from your bitChat dashboard.
          </li>
          <li>
            <span className="font-bold">Unified Customer Experience:</span>{' '}
            Provide a smooth shopping experience with chat support directly
            linked to your products.
          </li>
        </ul>
      </p>
      <p className="mb-3">
        <span className="text-secondary-main font-bold">
          Start boosting your sales! 🚀
        </span>{' '}
      </p>
      <ShopifyCardBitChat v="v3" />
    </BBBCard>
  );
}

function UnifiedMessaging() {
  const connectIntegration = useConnectIntegration();

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-unified-messaging.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Unlock the power of unified messaging!</div>
      <p className="mb-3">
        Managing chats across platforms is a hassle. bitChat centralizes
        everything: WhatsApp, Instagram, Facebook - all in one place.
      </p>
      <p className="mb-3">
        Benefits:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Unified Inbox:</span> Say goodbye to
            app-switching!
          </li>
          <li>
            <span className="font-bold">Boost Efficiency:</span> Automate tasks,
            respond faster & leverage AI suggestions.
          </li>
          <li>
            <span className="font-bold">Stronger Relationships:</span> Build
            connections with ticketing, chat history & customer profiles.
          </li>
        </ul>
      </p>
      <p className="mb-3">
        <span className="text-secondary-main font-bold">
          Ready to unlock better customer service? ✨🔑
        </span>{' '}
      </p>
      <p className="mb-6">
        <span>Click your preferred chat platform to connect:</span>
      </p>
      {unifiedMessagingIntegrations.map((integration) => {
        const Card = mapKnownIntegrationsToCard[integration];

        if (!Card) return null;

        return (
          <Card
            key={integration}
            v="v3"
            className="mb-4 last:mb-0"
            onConnect={() => {
              connectIntegration({
                name: integration,
              });
            }}
          />
        );
      })}
    </BBBCard>
  );
}

function Widget({
  completed,
  skipped,
}: {
  completed: boolean;
  skipped: boolean;
}) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCHAT');

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-widget.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Increase engagement with Chat Widget </div>
      <p className="mb-3">
        Enhance your customer experience with a live chat widget on your Shopify
        store. Instantly connect with visitors and provide real-time support.
      </p>
      <p className="mb-3">
        Benefits:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Improved customer satisfaction</span>
          </li>
          <li>
            <span className="font-bold">Increased sales and conversions</span>
          </li>
          <li>
            <span className="font-bold">Stronger customer relationships</span>
          </li>
        </ul>
      </p>
      <p className="mb-6">
        <span className="text-secondary-main font-bold">
          Ready to take your customer engagement to the next level? ✨
        </span>{' '}
      </p>
      {!completed && (
        <div className="flex justify-end items-center gap-4">
          {!skipped && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({ module: 'chat_widget', skipped: true });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/bitchat/wa-widget'}>
            <BBBButton>Activate chat widget</BBBButton>
          </Link>
        </div>
      )}
    </BBBCard>
  );
}

function TeamCollab({
  completed,
  skipped,
}: {
  completed: boolean;
  skipped: boolean;
}) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCHAT');

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-team-collab.png`}
        className="object-contain mb-6"
      ></img>
      <div className="text-xl mb-6">Collaborate effectively with your team</div>
      <p className="mb-3">
        Bring your team on board and seamlessly integrate them into your
        workflow. To get your team to bitChat, start by adding admin, regular
        user, or agent.
      </p>
      <p className="mb-3">
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Admins:</span> will have complete access
            to all bitbybit page and settings, handling tickets, and can adjust
            the subscription plan.
          </li>
          <li>
            <span className="font-bold">Agents:</span> can handle and edit
            tickets, along with limited access to pages and settings.
          </li>
          <li>
            <span className="font-bold">Regular users:</span> will be able to
            have access to handle tickets, and access to all page.
          </li>
        </ul>
      </p>
      <p className="mb-6">
        <span>Ready to boost your team&apos;s productivity?</span>{' '}
        <span className="text-secondary-main font-bold">
          Invite your colleagues now! 🎉
        </span>{' '}
      </p>
      {!completed && (
        <div className="flex justify-end items-center gap-4">
          {!skipped && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({
                  module: 'team_collaboration',
                  skipped: true,
                });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/settings?section=user-authority'}>
            <BBBButton>Invite team members</BBBButton>
          </Link>
        </div>
      )}
    </BBBCard>
  );
}

type ChatbotMeta = {
  completedChatbot: boolean;
  completedRating: boolean;
  skippedChatbot: boolean;
  skippedRating: boolean;
};

function Chatbot({ meta }: { meta: ChatbotMeta }) {
  const [collapsed, setCollapsed] = useState<string | undefined>(
    'virtual-assistant'
  );

  return (
    <BBBAccordion>
      <BBBAccordion.Item
        title="Meet your virtual assistant: Chatbot!"
        collapse={collapsed === 'virtual-assistant'}
        onCollapse={(_collapsed) => {
          setCollapsed(_collapsed ? 'virtual-assistant' : undefined);
        }}
        leftChildren={
          <Step step={1} active={collapsed === 'virtual-assistant'} />
        }
        content={<VirtualAssistant {...meta} />}
      />
      <BBBAccordion.Item
        title="Improve your service with customer feedback"
        collapse={collapsed === 'customer-feedback'}
        onCollapse={(_collapsed) => {
          setCollapsed(_collapsed ? 'customer-feedback' : undefined);
        }}
        leftChildren={
          <Step step={2} active={collapsed === 'customer-feedback'} />
        }
        content={<CustomerFeedback {...meta} />}
      />
    </BBBAccordion>
  );
}

function CustomerFeedback(props: ChatbotMeta) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCHAT');

  return (
    <>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-rating.png`}
        className="object-contain my-6"
      ></img>
      <p className="mb-3">
        Discover how your customers feel about your business. With our Ask
        Rating feature, you can gather valuable feedback after every interaction
        for your chatbot and customer service.
      </p>
      <p className="mb-3">
        How it works:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Automatic rating prompt:</span>{' '}
            automatically send a rating prompt to customers after each chat.
          </li>
          <li>
            <span className="font-bold">Collect feedback:</span> collect
            feedback on both chatbot and customer service interactions.
          </li>
          <li>
            <span className="font-bold">Analytics:</span> Use analytics to
            analyze rating to identify areas for improvement.
          </li>
        </ul>
      </p>
      <p className="mb-6">
        <span>
          By understanding your customers’ perspectives, you can enhance your
          service and build stronger relationships.
        </span>{' '}
        <span className="text-secondary-main font-bold">
          Improve your service with customer ratings!🌟
        </span>{' '}
      </p>
      {!props.completedRating && (
        <div className="flex justify-end items-center gap-4">
          {!props.skippedRating && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({ module: 'rating', skipped: true });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/bitchat/settings?section=chatbot'}>
            <BBBButton>Enable ask rating</BBBButton>
          </Link>
        </div>
      )}
    </>
  );
}

function VirtualAssistant(props: ChatbotMeta) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITCHAT');

  return (
    <>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-chatbot-va.png`}
        className="object-contain my-6"
      ></img>
      <p className="mb-3">
        Tired of missed messages and long wait times? Introducing Chatbots, your
        new AI-powered sidekick. With Chatbot, you can turn your WhatsApp into a
        powerful business tool. Handle multiple customers at once, automate
        responses, and boost engagement.
      </p>
      <p className="mb-3">
        Benefits:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">24/7 AI chatbot customer support</span>
          </li>
          <li>
            <span className="font-bold">Increased sales and leads</span>
          </li>
          <li>
            <span className="font-bold">Improved efficiency</span>
          </li>
        </ul>
      </p>
      <p className="mb-6">
        <span>
          By assigning specific roles, you ensure everyone has the right level
          of access to efficiently manage your business.
        </span>{' '}
        <span className="text-secondary-main font-bold">
          Step up your game with chatbot now!🏆
        </span>{' '}
      </p>
      {!props.completedChatbot && (
        <div className="flex justify-end items-center gap-4">
          {!props.skippedChatbot && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({ module: 'chatbot', skipped: true });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'/bitchat/flows'}>
            <BBBButton>Try out chatbot now</BBBButton>
          </Link>
        </div>
      )}
    </>
  );
}

function Step({ step, active }: { step: number; active: boolean }) {
  return (
    <div
      className={cn(
        'rounded-full w-6 h-6 flex items-center justify-center bg-primary-main opacity-20',
        active && 'opacity-100'
      )}
    >
      <p className="text-neutral-10 font-bold text-lg">{step}</p>
    </div>
  );
}
