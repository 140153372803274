import React, { useEffect } from 'react';
import { Minus, Plus } from 'react-feather';
import ReactFlow, {
  FitViewOptions,
  ReactFlowProps,
  useReactFlow,
  Viewport,
} from 'reactflow';
import FitScreenIcon from 'assets/icons/FitScreenIcon';
import { BBBTooltip } from '../BBBTooltip';
import 'reactflow/dist/style.css';
import './react-flow-custom-theming.css';

const defaultViewport: Viewport = {
  zoom: 0.75,
  x: 100,
  y: 200,
};

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};

export default function Flow({ children, ...props }: ReactFlowProps) {
  return (
    <ReactFlow
      {...props}
      fitViewOptions={fitViewOptions}
      defaultViewport={defaultViewport}
      panOnScroll
    >
      <div className="absolute pointer-events-none top-6 right-6 z-20">
        <CustomControl />
      </div>
      {children}
    </ReactFlow>
  );
}

function CustomControl() {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  // useEffect(() => {
  //   const handleWheel = (event: WheelEvent) => {
  //     if (event.deltaY < 0) {
  //       zoomIn();
  //     } else {
  //       zoomOut();
  //     }
  //   };

  //   window.addEventListener('wheel', handleWheel);

  //   return () => {
  //     window.removeEventListener('wheel', handleWheel);
  //   };
  // }, [zoomIn, zoomOut]);

  return (
    <div className="flex justify-end">
      <div className="flex items-center gap-4">
        <div
          className="border-neutral-30 border-[2px] bg-white w-28 h-14 rounded-lg flex"
          style={{ boxShadow: '0px 3px 10px 0px #94949440' }}
        >
          <BBBTooltip
            show
            content="Zoom out"
            position="bottom"
            tooltipClassName="w-auto"
            className="flex-1"
          >
            <div
              onClick={() => zoomOut()}
              className="pointer-events-auto cursor-pointer w-full h-full flex justify-center border-neutral-30 border-r-[2px] items-center"
            >
              <Minus />
            </div>
          </BBBTooltip>
          <BBBTooltip
            show
            content="Zoom in"
            position="bottom"
            tooltipClassName="w-auto"
            className="flex-1"
          >
            <div
              onClick={() => zoomIn()}
              className="pointer-events-auto cursor-pointer w-full h-full flex justify-center items-center"
            >
              <Plus />
            </div>
          </BBBTooltip>
        </div>
        <BBBTooltip
          show
          content="Fit to default"
          position="bottom"
          tooltipClassName="w-auto text-center"
        >
          <div
            onClick={() => fitView()}
            className="pointer-events-auto cursor-pointer w-14 h-14 bg-white flex justify-center items-center border-[2px] border-neutral-30 rounded-lg"
            style={{
              boxShadow: '0px 3px 10px 0px #94949440',
            }}
          >
            <FitScreenIcon />
          </div>
        </BBBTooltip>
      </div>
    </div>
  );
}
