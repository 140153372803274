import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';
import useStore from '../../store';
import { MessageData } from '../../types';
import {
  MessageForm,
  messageSchema,
  Replies,
  useMessage,
} from './TriggerMessageModal';

import {
  BBBFileUpload,
  BBBModal,
  BBBRichTextEditor,
  BBBSelect,
} from '@/components';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from '@/utils/common/rich';

const messageModalSchema = yup.object(messageSchema);

export default function MessageModal({
  onClose,
  onSave,
  nodeId,
}: {
  onClose?: () => void;
  onSave: (val: MessageData) => void;
  nodeId?: string;
}) {
  const { defaultValues } = useMessage();

  const { data } = useSettings();
  const history = useHistory();

  const methods = useForm<MessageForm>({
    resolver: yupResolver(messageModalSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = methods;

  const node = useStore((s) => s.nodes.find((node) => node.id === nodeId));
  const childNodes = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === nodeId),
    shallow
  );

  const dataFromProps = useMemo(
    () =>
      node
        ? {
            message:
              convertHtmlToEditorState(node.data.message) ||
              defaultValues.message,
            replies: childNodes.length
              ? childNodes.map((childNode) => ({
                  replyId: childNode.id,
                  reply: childNode.data.reply,
                }))
              : defaultValues.replies,
            fallback: node.data.fallback || defaultValues.fallback,
            file: node.data.media
              ? convertRemoteUrlToFileType(node.data.media)
              : defaultValues.file,
            optionButtonText:
              node.data.optionButtonText || defaultValues.optionButtonText,
            type:
              typeof node.data.type !== 'undefined'
                ? node.data.type
                : childNodes.length
                ? 'reply'
                : defaultValues.type,
            whatsappForm: node.data.form || null,
            whatsappFormText: node.data.formButtonText || '',
          }
        : undefined,
    [
      childNodes,
      defaultValues.fallback,
      defaultValues.file,
      defaultValues.message,
      defaultValues.optionButtonText,
      defaultValues.replies,
      defaultValues.type,
      node,
    ]
  );

  const isDataEqual = isEqual(dataFromProps, watch());

  useEffect(() => {
    if (dataFromProps) {
      reset(dataFromProps);
    }
  }, [dataFromProps, reset]);

  return (
    <BBBModal
      show
      title="Message"
      submitText="Save"
      cancelText="Discard"
      footer
      onHide={onClose}
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            message: convertEditorStateToHtml(data.message) || '',
            replies: data.replies
              .filter(({ reply }) => !!reply)
              .map(({ reply, replyId }, index) => ({
                id: replyId,
                reply,
                index,
              })),
            fallback: data.fallback,
            media: data.file?.remoteUrl || null,
            type: data.type,
            optionButtonText:
              data.type === 'option-list' ? data.optionButtonText : undefined,
            form: data.whatsappForm
              ? {
                  id: data.whatsappForm.id,
                  metaId: data.whatsappForm.metaId,
                  name: data.whatsappForm.name,
                }
              : null,
            formButtonText: data.whatsappFormText,
          });
          onClose?.();
        })();
      }}
      disableSave={isDataEqual}
    >
      <FormProvider {...methods}>
        <Controller
          control={control}
          name="fallback"
          render={({ field }) => (
            <BBBSelect
              label="Fallback"
              options={data?.fallback}
              optionLabel="name"
              optionValue="id"
              containerClassName="mb-5"
              value={field.value}
              onValueChange={field.onChange}
              //@ts-ignore
              error={errors.fallback?.message}
              placeholder="Choose fallback"
              withCreateRedirectOption
              createRedirectLabel="Create new fallback"
              onClickCreateRedirect={() => {
                history.push(
                  `/bitchat/settings?section=chatbot&sub_section=fallback`
                );
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="file"
          render={({ field }) => (
            <BBBFileUpload
              containerClassName="mb-5"
              isSingle
              files={field.value}
              onChangeFile={field.onChange}
              accept="*"
              withLabel
            />
          )}
        />
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <BBBRichTextEditor
              label="Message"
              placeholder="Input message that will sent to customer"
              rows={5}
              containerClassName="mb-4"
              editorState={field.value}
              onChangeEditorState={(val) => field.onChange(val)}
              error={errors.message?.message}
            />
          )}
        />
        {/**@ts-ignore */}
        <Replies control={control} />
      </FormProvider>
    </BBBModal>
  );
}
