import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard, Link } from '@/components';
import { mapLimitAutomaticUpdates } from '@/constants/bitAI/pricing-limit';
import { useGetScheduleImporting } from '@/hooks/bitAi/importing';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

type Props = {
  className?: string;
};

export default function AutomaticUpdates({ className }: Props) {
  const pricingQuery = usePricingByApp('BITAI');
  const { data } = useGetScheduleImporting();

  if (!pricingQuery.data) return null;

  return (
    <BBBCard className={cx(className)}>
      <div className="flex justify-between items-center">
        <div className="flex flex-col grow gap-2">
          <p className="text-primary-main font-medium text-xl">
            Automatic Updates
          </p>
          <p className="text-neutral-60">
            Your plan is updated{' '}
            <span className="font-bold">
              {' '}
              {mapLimitAutomaticUpdates[pricingQuery.data.pricingName]}x / Week.
            </span>{' '}
            {pricingQuery.data.pricingName === 'free' && (
              <Link
                className="text-info-main underline cursor-pointer"
                to="/pricing?tab=bitai"
              >
                Upgrade for more
              </Link>
            )}
          </p>
        </div>
        <div className="flex items-center gap-8">
          <StatusUpdate
            title="Last Update"
            time={
              data?.lastUpdate
                ? dayjs(data.lastUpdate).format('h:mm A')
                : undefined
            }
            date={
              data?.lastUpdate
                ? dayjs(data.lastUpdate).format('D MMMM, YYYY')
                : undefined
            }
          />
          <StatusUpdate
            title="Next Update"
            time={
              data?.nextUpdate
                ? dayjs(data.nextUpdate).format('h:mm A')
                : undefined
            }
            date={
              data?.nextUpdate
                ? dayjs(data.nextUpdate).format('D MMMM, YYYY')
                : undefined
            }
          />
        </div>
      </div>
    </BBBCard>
  );
}

function StatusUpdate({
  title,
  time,
  date,
}: {
  title: string;
  time?: string;
  date?: string;
}) {
  return (
    <div className="flex flex-col gap-2.5">
      <p className="uppercase text-neutral-50">{title}</p>
      <div className="flex flex-col text-primary-main">
        {time || date ? (
          <>
            <p>{time}</p>
            <p>{date}</p>
          </>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
}
