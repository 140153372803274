type Props = {
  mode?: 'default' | 'hover' | 'active';
};

function PricingIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' ? (
        <PricingIconDefault />
      ) : mode === 'hover' ? (
        <PricingIconHover />
      ) : (
        <PricingIconActive />
      )}
    </>
  );
}

function PricingIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#404040" clipPath="url(#clip0_7758_71238)">
        <path d="M11.999 23.998a12 12 0 1111.999-12 12.013 12.013 0 01-12 12zm0-22.046A10.047 10.047 0 1022.045 12 10.06 10.06 0 0012 1.952z"></path>
        <path d="M15.154 13.934c0-2.811-3.882-2.684-3.882-4.09 0-.661.383-.947 1.09-.947.615 0 1.07.256 1.604.732l1.08-1.237a3.685 3.685 0 00-2.18-1.198V5.713H11.43v1.52c-1.383.276-2.228 1.271-2.228 2.714 0 2.652 3.883 2.625 3.883 4.148 0 .669-.369 1.015-1.2 1.015-.696 0-1.35-.293-2.097-.866l-.944 1.464a4.881 4.881 0 002.586 1.115v1.464h1.435v-1.542c1.508-.322 2.289-1.403 2.289-2.811z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7758_71238">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function PricingIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_7758_71246)">
        <circle cx="12" cy="12" r="11" fill="#404040"></circle>
        <path
          fill="#404040"
          d="M11.999 23.998a12 12 0 1111.999-12 12.013 12.013 0 01-12 12zm0-22.046A10.047 10.047 0 1022.045 12 10.06 10.06 0 0012 1.952z"
        ></path>
        <path
          fill="#fff"
          d="M15.154 13.934c0-2.811-3.882-2.684-3.882-4.09 0-.661.383-.947 1.09-.947.615 0 1.07.256 1.604.732l1.08-1.237a3.685 3.685 0 00-2.18-1.198V5.713H11.43v1.52c-1.383.276-2.228 1.271-2.228 2.714 0 2.652 3.883 2.625 3.883 4.148 0 .669-.369 1.015-1.2 1.015-.696 0-1.35-.293-2.097-.866l-.944 1.464a4.881 4.881 0 002.586 1.115v1.464h1.435v-1.542c1.508-.322 2.289-1.403 2.289-2.811z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7758_71246">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
function PricingIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#FD823E" clipPath="url(#clip0_7758_71250)">
        <path d="M11.999 23.998a12 12 0 1111.999-12 12.013 12.013 0 01-12 12zm0-22.046A10.047 10.047 0 1022.045 12 10.06 10.06 0 0012 1.952z"></path>
        <path d="M15.154 13.934c0-2.811-3.882-2.684-3.882-4.09 0-.661.383-.947 1.09-.947.615 0 1.07.256 1.604.732l1.08-1.237a3.685 3.685 0 00-2.18-1.198V5.713H11.43v1.52c-1.383.276-2.228 1.271-2.228 2.714 0 2.652 3.883 2.625 3.883 4.148 0 .669-.369 1.015-1.2 1.015-.696 0-1.35-.293-2.097-.866l-.944 1.464a4.881 4.881 0 002.586 1.115v1.464h1.435v-1.542c1.508-.322 2.289-1.403 2.289-2.811z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7758_71250">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PricingIcon;
