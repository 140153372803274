import { useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useCustomForm from 'hooks/common/useCustomForm';
import { useBulkCustomerTag } from 'hooks/customers/customer/bulk-action';
import useCreateTag from 'hooks/customers/customer/useCreateTag';
import useTags from 'hooks/customers/customer/useTags';
import { CustomerWithAssociation } from 'types/customers/association';
import * as yup from 'yup';
import { BBBModal, BBBSelect } from 'components';
import TruncatedTags from 'components/TruncatedTags/TruncatedTags';

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  selectedRows: CustomerWithAssociation[];
  isAllRowSelected: boolean;
};

const schema = yup.object().shape({
  tag: yup
    .mixed<
      {
        name: string;
        companyId: number;
      }[]
    >()
    .required(),
  customerIds: yup.mixed<CustomerWithAssociation[]>().required(),
});

type Schema = {
  tag: {
    name: string;
    companyId: number;
  }[];
  customerIds: CustomerWithAssociation[];
};

function AddBulkCustomerTagModal({
  show,
  onClose,
  onSuccess,
  selectedRows,
  isAllRowSelected,
}: Props) {
  const customerTags =
    selectedRows
      .flatMap((customer) => customer.customerTags)
      ?.map((tag) => ({
        name: tag.tagName,
        companyId: tag.tagCompanyId,
      })) ?? [];

  const { handleSubmit, control, watch, setValue } = useCustomForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      tag: customerTags,
      customerIds: selectedRows,
    },
  });

  const { mutate, isLoading } = useBulkCustomerTag();

  const tag = watch('tag');
  const tagLength = tag?.filter(
    (tag) =>
      !customerTags.some(
        (customerTag) =>
          customerTag.name === tag.name &&
          customerTag.companyId === tag.companyId
      )
  ).length;

  const onSubmit = (data: Schema) => {
    mutate(
      {
        tagName: data.tag.map((tag) => tag.name),
        customerIds: data.customerIds.map((customer) => customer.id),
        isAllRowSelected,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        },
      }
    );
  };

  return (
    <BBBModal
      show={show}
      onHide={onClose}
      title={`Add customer tag`}
      submitText={
        tagLength
          ? `Add ${tagLength} tag${tagLength > 1 ? 's' : ''}`
          : `Add tag`
      }
      footer
      handleSave={handleSubmit(onSubmit)}
      loadingSave={isLoading}
      size="md"
      disableSave={!tagLength}
    >
      <BulkCustomerTag control={control} setValue={setValue} />
    </BBBModal>
  );
}

function BulkCustomerTag({
  control,
  setValue,
}: {
  control: Control<Schema>;
  setValue: UseFormSetValue<Schema>;
}) {
  const { errors } = useFormState({
    control,
    name: 'tag',
  });

  const tags = useWatch({
    control,
    name: 'tag',
  });

  const [addedTags, setAddedTags] = useState<typeof tags>([]);

  const {
    data: tagsData,
    isInitialLoading: loadingTags,
    hasNextPage,
    fetchNextPage,
  } = useTags();

  const { mutate: createTag } = useCreateTag();

  const flattenedTags = tagsData?.pages.flatMap((page) => page.data);

  return (
    <>
      <Controller
        control={control}
        name="tag"
        render={({ field }) => (
          <BBBSelect
            placeholder="Enter tag name"
            isMulti
            options={flattenedTags}
            optionLabel="name"
            optionValue="name"
            loading={loadingTags}
            isPaginated
            hasMore={!!hasNextPage}
            fetchNext={fetchNextPage}
            isCreatable
            onCreateOption={(val) =>
              createTag(
                { name: val },
                {
                  onSuccess: (data) => {
                    field.onChange([
                      ...field.value,
                      {
                        name: data.name,
                        companyId: data.companyId,
                      },
                    ]);
                    setAddedTags((prev) => [...prev, data]);
                  },
                }
              )
            }
            value={field.value}
            onValueChange={(val) => {
              field.onChange(val);
              setAddedTags((prev) => [
                ...prev,
                ...(val as typeof tags).filter(
                  (tag) =>
                    !tags?.some(
                      (t) =>
                        t.name === tag.name && t.companyId === tag.companyId
                    )
                ),
              ]);
            }}
            isSearchable
            containerClassName="mb-5"
            fallbackToPlaceholder
            error={errors.tag?.message}
          />
        )}
      />
      {!!addedTags?.length && (
        <TruncatedTags
          items={addedTags.map((tag) => tag.name)}
          onDelete={(tagName) => {
            setValue(
              'tag',
              (tags as typeof tags).filter((tag) => tag.name !== tagName)
            );
            setAddedTags((prev) => prev.filter((tag) => tag.name !== tagName));
          }}
        />
      )}
    </>
  );
}

export default AddBulkCustomerTagModal;
