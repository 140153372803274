import React, { useMemo, useRef, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import { MultipleOnValueChangeParam } from '../BBBSelect.type';
import BBBSelectOptionIcon from '../BBBSelectOptionIcon/BBBSelectOptionIcon';
import { DropdownTypes } from '..';

import CheckIcon2 from '@/assets/icons/CheckIcon2';
import { BBBTooltip } from '@/components';
import getDescendantProp from '@/types/utils/getDescendantProp';

export type BBBSelectDropdownOptionTypes<T extends Record<string, any>> = {
  data: T;
  id: string;
  style?: React.CSSProperties;
  onSelectOption: (val: T, parentOptions?: T) => void;
  level?: number;
  index?: number;
  parentIndex?: number;
} & DropdownTypes<T>;

function BBBSelectDropdownOption<T extends Record<string, any>>(
  props: BBBSelectDropdownOptionTypes<T>
) {
  const {
    data,
    id,
    style,
    onSelectOption,
    level = 0,
    dropdownOptionClassName,
    enableToggleOption,
    boldRecurringParentOption = true,
    parentIndex,
    index,
    isMulti,
    value,
    optionValue,
    optionLabel,
    optionIcon,
    isGrouped,
    optionGroupKey,
    options,
    parentOptions,
    optionDisabled,
    bypassDisableClickHandler,
  } = props;

  const [manualFocus, setManualFocus] = useState(false);

  const filteredValues = isMulti
    ? value?.filter((opt) =>
        !optionGroupKey || !parentOptions
          ? true
          : getDescendantProp(opt, optionGroupKey) ===
            getDescendantProp(parentOptions, optionValue)
      )
    : !parentOptions
    ? value
    : value &&
      optionGroupKey &&
      getDescendantProp(value, optionGroupKey) ===
        getDescendantProp(parentOptions, optionValue)
    ? value
    : undefined;

  const isDisabled = optionDisabled && getDescendantProp(data, optionDisabled);

  const isOptionActive = useMemo(() => {
    if (!filteredValues) return false;
    if (!isMulti) {
      if (
        getDescendantProp(filteredValues, optionValue) ===
        getDescendantProp(data, optionValue)
      ) {
        return true;
      }
      return false;
    }
    if (
      (filteredValues as MultipleOnValueChangeParam<T>)?.some(
        (v) =>
          (!isGrouped || !parentOptions
            ? true
            : getDescendantProp(parentOptions, optionValue) ===
              getDescendantProp(v, optionGroupKey)) &&
          getDescendantProp(v, optionValue) ===
            getDescendantProp(data, optionValue)
      )
    ) {
      return true;
    }
    return false;
  }, [
    filteredValues,
    isMulti,
    optionValue,
    data,
    isGrouped,
    parentOptions,
    optionGroupKey,
  ]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.key === 'Enter' && onSelectOption(data, parentOptions);

  const childOptions = useMemo(
    () =>
      (Array.isArray(options) ? options : options?.())?.filter((opt) =>
        optionGroupKey
          ? getDescendantProp(opt, optionGroupKey) ===
            getDescendantProp(data, optionValue)
          : true
      ),
    [data, optionGroupKey, optionValue, options]
  );

  const isLastInHierarchy = isGrouped ? !childOptions?.length : true;

  const optionsRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <BBBTooltip
        content={getDescendantProp(data, optionLabel) || '-'}
        targetRef={optionsRef}
        className="block w-full p-0"
        position="right"
      >
        <div
          onClick={() => {
            if (bypassDisableClickHandler || !isDisabled) {
              onSelectOption(data, parentOptions);
            }
            setManualFocus(true);
          }}
          onBlur={(e) => {
            if (
              e.relatedTarget &&
              (e.relatedTarget as HTMLDivElement).closest('#bbb-single-option')
                ?.id === 'bbb-single-option'
            ) {
              setManualFocus(false);
            } else {
              setManualFocus(true);
            }
          }}
          onKeyPress={handleKeyPress}
          className={cx(
            `hover:bg-secondary-surface transition-colors duration-300 relative active:bg-secondary-border cursor-pointer flex items-center gap-2 py-2`,
            isOptionActive
              ? cx(
                  'bg-neutral-20',
                  !enableToggleOption && 'pointer-events-none'
                )
              : '',
            !isLastInHierarchy && 'pointer-events-none',
            isDisabled &&
              'opacity-40 hover:bg-transparent active:bg-transparent focus:bg-transparent',
            !manualFocus && 'focus:bg-secondary-surface focus:outline-none'
          )}
          tabIndex={-1}
          id={id}
          data-parent-index={parentIndex ?? -1}
          data-index={index}
          style={{ ...style, paddingLeft: (level + 1) * 16, paddingRight: 16 }}
        >
          <BBBSelectOptionIcon value={data} accesor={optionIcon} />
          <div
            ref={optionsRef}
            className={cx(
              'line-clamp-1 grow text-neutral-70',
              boldRecurringParentOption &&
                isGrouped &&
                level === 0 &&
                'font-medium',
              dropdownOptionClassName
            )}
          >
            {getDescendantProp(data, optionLabel) || '-'}{' '}
          </div>
          {isOptionActive && (
            <div className="flex-none">
              <CheckIcon2 />
            </div>
          )}
        </div>
      </BBBTooltip>

      {isGrouped &&
        childOptions?.map((opt, _index) => {
          const value = getDescendantProp(opt, optionValue);
          return (
            <BBBSelectDropdownOption
              {...props}
              key={
                isGrouped && optionGroupKey
                  ? `${getDescendantProp(opt, optionGroupKey)}_${value}`
                  : value
              }
              data={opt}
              id={id}
              onSelectOption={onSelectOption}
              level={level + 1}
              parentOptions={data}
              index={_index}
              parentIndex={index}
            />
          );
        })}
    </>
  );
}

export default BBBSelectDropdownOption;
