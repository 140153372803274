import { KnownIntegration } from '@/constants/integrations';
import { ChannelIntegrations } from '@/types/bitChat/integrations';
import { BusinessType } from '@/types/whatsApp/chat';
import { IntegrationSources } from '@/types/whatsApp/shape';

export const mapSourceToKnownIntegrations: Record<string, KnownIntegration> = {
  WHATSAPP: 'whatsapp',
  WHATSAPP_OFFICIAL: 'whatsapp_meta',
  WHATSAPP_CLOUD_API: 'whatsapp_meta',
  WHATSAPP_META: 'whatsapp_meta',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  SANDBOX_WHATSAPP: 'whatsapp',
  Shopify: 'shopify',
  shopify: 'shopify',
  Stamped: 'stamped',
  Yotpo: 'yotpo',
  GOOGLE_MY_BUSINESS: 'google_my_business',
  jubelio: 'jubelio',
  STAMPED: 'stamped',
  YOTPO: 'yotpo',
};

export const mapRoutesToSource = {
  'whatsapp-business': 'WHATSAPP',
  'whatsapp-official': 'WHATSAPP_OFFICIAL',
  'whatsapp-cloud': 'WHATSAPP_CLOUD_API',
  instagram: 'INSTAGRAM',
  facebook: 'FACEBOOK',
};

export const mapSourceToRoutes = {
  WHATSAPP: 'whatsapp-business',
  WHATSAPP_OFFICIAL: 'whatsapp-official',
  WHATSAPP_CLOUD_API: 'whatsapp-cloud',
  WHATSAPP_META: 'whatsapp-cloud',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  SANDBOX_WHATSAPP: 'whatsapp-sandbox',
};

export type ChannelRoutes = keyof typeof mapRoutesToSource;
export type ChannelSource = keyof typeof mapSourceToRoutes;

export const mapIntegrationChannels: Record<
  ChannelIntegrations,
  IntegrationSources
> = {
  whatsapp: 'WHATSAPP',
  whatsapp_meta: 'WHATSAPP_META',
  facebook: 'FACEBOOK',
  google_my_business: 'GOOGLE_MY_BUSINESS',
  instagram: 'INSTAGRAM',
};

export const mapIntegrationChannels2: Record<
  IntegrationSources,
  ChannelIntegrations
> = {
  WHATSAPP: 'whatsapp',
  WHATSAPP_META: 'whatsapp_meta',
  FACEBOOK: 'facebook',
  GOOGLE_MY_BUSINESS: 'google_my_business',
  INSTAGRAM: 'instagram',
  SANDBOX_WHATSAPP: 'whatsapp',
};

export const businessTypeOptions: { label: string; value: BusinessType }[] = [
  { label: 'Other', value: 'OTHER' },
  { label: 'Auto', value: 'AUTO' },
  { label: 'Beauty', value: 'BEAUTY' },
  { label: 'Apparel', value: 'APPAREL' },
  { label: 'Education', value: 'EDU' },
  { label: 'Entertainment', value: 'ENTERTAIN' },
  { label: 'Event Planning', value: 'EVENT_PLAN' },
  { label: 'Finance', value: 'FINANCE' },
  { label: 'Grocery', value: 'GROCERY' },
  { label: 'Government', value: 'GOVT' },
  { label: 'Hotel', value: 'HOTEL' },
  { label: 'Health', value: 'HEALTH' },
  { label: 'Nonprofit', value: 'NONPROFIT' },
  { label: 'Professional Services', value: 'PROF_SERVICES' },
  { label: 'Retail', value: 'RETAIL' },
  { label: 'Travel', value: 'TRAVEL' },
  { label: 'Restaurant', value: 'RESTAURANT' },
  { label: 'Not a Business', value: 'NOT_A_BIZ' },
];
