import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import { Redirect } from '@/components/Redirect';
import { useAccessToken } from '@/hooks/rtk/selector';

export default function FallbackRoute(props: RouteComponentProps) {
  const accessToken = useAccessToken();

  if (!accessToken) {
    return <Redirect to={'/login'} />;
  }

  return (
    <Redirect
      to={
        (queryString.parse(props.location.search).redirectTo as string) ||
        '/home'
      }
    />
  );
}
