import { formatUserDisplayName } from './auth';

import { SettingsTag } from '@/api/services/whatsApp/settings';
import { MessageTypeValue } from '@/pages/BitChat/Settings/components/ChatbotSettings/MessageType';
import {
  AutoReply,
  ChatbotType,
  ChatbotType1,
  mapChatbotType1ToChatbotType,
} from '@/types/bitChat/chatbot';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { DeepPartial } from '@/types/utils/deepPartial';
import { MessageNewAssociation } from '@/types/whatsApp/v3';
import { formatInternational } from '@/utils/common/phone';
import { convertEditorStateToHtml } from '@/utils/common/rich';

export const scrollToBottom = (timeout = 0) =>
  setTimeout(
    () => document.getElementById('bottom-bubble')?.scrollIntoView(),
    timeout
  );

export const handleDisableSection = (value: boolean) =>
  value ? 'opacity-100' : 'opacity-25 cursor-not-allowed pointer-events-none';

export const formatSender = (chat: MessageNewAssociation) =>
  chat.fromMe
    ? chat.sources === 'INSTAGRAM'
      ? formatInternational(
          chat.clientName ?? chat.clientNumber.replace(/\D/g, '')
        )
      : 'You'
    : formatInternational(
        chat.clientName ?? chat.clientNumber.replace(/\D/g, '')
      );

export const arrowFn = (
  type: 'up' | 'down',
  e:
    | React.KeyboardEvent<HTMLDivElement>
    | React.KeyboardEvent<HTMLTextAreaElement>
) => {
  const activeElement = document.activeElement;
  const options = document.querySelectorAll('#bbb-single-option');

  const activeElementIndex = Number(activeElement?.getAttribute('data-index'));

  const activeElementParentIndex = Number(
    activeElement?.getAttribute('data-parent-index')
  );

  let idx = 0;

  options.forEach((option, _idx) => {
    const index = Number(option.getAttribute('data-index'));
    const parentIndex = Number(option.getAttribute('data-parent-index'));

    if (
      activeElement?.id === `bbb-single-option` &&
      index === activeElementIndex &&
      parentIndex === activeElementParentIndex
    ) {
      idx = type === 'down' ? _idx + 1 : _idx - 1;
    }
  });

  if (idx >= 0 && idx < options.length) {
    (options[idx] as HTMLElement | null)?.focus();
    e.preventDefault();
  }
};

export const formatChatbotPayload = ({
  message: { messageType, files, userMessage: message },
  id,
  active,
  module,
  chatbotType,
  groupName,
  keys,
  ticketTag,
  agent,
}: {
  id?: string;
  keys?: { label: string; value: string }[];
  active: boolean;
  ticketTag?: SettingsTag | null;
  agent?: UserCompanyWithAssociation & {
    formattedName?: string;
  };
  message: MessageTypeValue;
} & (
  | {
      module?: never;
      chatbotType: ChatbotType;
      groupName: string;
    }
  | {
      module: ChatbotType1;
      chatbotType?: never;
      groupName?: never;
    }
)): DeepPartial<AutoReply> => ({
  id,
  keywords: keys?.map((key) => key.value),
  groupName: groupName || module,
  chatbotType: chatbotType || mapChatbotType1ToChatbotType[module!],
  reply: {
    type: messageType!.value,
    status: active,
    message: convertEditorStateToHtml(message) ?? '',
    ...(messageType?.value === 'MEDIA' &&
      files && {
        imageUrl: files.remoteUrl,
      }),
    ticketTag: ticketTag?.id,
    ...(agent && {
      agentId: agent.userId,
      agentDisplayName: formatUserDisplayName(agent.user),
      agentEmail: agent.user.email,
      agentAccent: agent.user.accent,
      agentProfilePicture: agent.user.profile?.profilePicture || null,
    }),
  },
});
