import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Link2, ShoppingBag, Type, Youtube } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import Text from './Text';

import DraggableIcon from '@/assets/icons/BBBDraggableIcon';
import LayoutIcon from '@/assets/icons/LayoutIcon';
import PointerIcon from '@/assets/icons/PointerIcon';
import WhatsAppSectionIcon from '@/assets/icons/WhatsAppSectionIcon';
import { BBBCard, BBBPrimarySwitch } from '@/components';
import { useLinkClick } from '@/hooks/bitLink/analytics';
import { useLinklistEditMutation } from '@/hooks/bitLink/links/useLinklistMutation';
import useShopifyConnectModal from '@/hooks/shopify/useShopifyConnectModal';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { Linklists } from '@/types/bitLink/v2';
import { Nullable } from '@/types/utils/nullable';

type Props = {
  index: number;
  item: Linklists;
  onClick?: (item: Linklists) => void;
  disableDrag?: boolean;
  hideSwitch?: boolean;
};

export default function Link({
  index,
  item,
  onClick,
  disableDrag,
  hideSwitch,
}: Props) {
  return (
    <motion.div
      transition={{ type: 'spring', damping: 20, stiffness: 150 }}
      layout
    >
      {!disableDrag ? (
        <Draggable draggableId={item.id.toString()} index={index}>
          {(providedDraggable) => (
            <_Link
              index={index}
              item={item}
              onClick={onClick}
              providedDraggable={providedDraggable}
            />
          )}
        </Draggable>
      ) : (
        <_Link
          index={index}
          item={item}
          onClick={onClick}
          hideSwitch={hideSwitch}
        />
      )}
    </motion.div>
  );
}

function _Link({
  item,
  onClick,
  providedDraggable,
  hideSwitch,
}: Props & {
  providedDraggable?: DraggableProvided;
}) {
  return (
    <BBBCard
      className={cx(
        'flex gap-4 items-center mb-3 hover:bg-neutral-20 hover:border-secondary-border transition-all duration-300 ease-in-out'
      )}
      ref={providedDraggable?.innerRef}
      {...providedDraggable?.draggableProps}
      onClick={(e) => {
        if (
          !(e.target as Element).id.includes('headlessui-switch') &&
          !(e.target as Element).id
        ) {
          onClick?.(item);
        }
      }}
      style={{
        ...providedDraggable?.draggableProps.style,
        cursor: 'pointer',
        height: item.type === 'shop' ? '110px' : '84px',
      }}
    >
      <div className="self-center">
        <Logo type={item.type} media={item.media} />
      </div>
      <div className="grow overflow-clip">
        <Text
          type={item.type}
          productId={item.items}
          textPrimary={item.textPrimary || ''}
          textSecondary={item.textSecondary}
        />
      </div>
      <Analytic id={item.id} />
      {!hideSwitch && (
        <Actionable id={item.id} type={item.type} active={item.active} />
      )}
      {providedDraggable && (
        <div {...providedDraggable?.dragHandleProps}>
          <DraggableIcon />
        </div>
      )}
    </BBBCard>
  );
}

type LinkProps = {
  type: Linklists['type'];
  media?: Nullable<string>;
};

function Logo({ type, media }: LinkProps) {
  return (
    <>
      {type === 'image' && (
        <div>
          <img
            alt=""
            src={media || undefined}
            className="w-[2em] h-[2em] !max-w-none object-cover rounded"
            loading="lazy"
          />
        </div>
      )}
      {type === 'shop' && <ShoppingBag />}
      {type === 'divider' && <LayoutIcon />}
      {type === 'youtube' && <Youtube />}
      {type === 'link' &&
        (media ? (
          <img
            src={media}
            alt="link"
            className="w-[2em] h-[2em] !max-w-none object-cover rounded"
            loading="lazy"
          />
        ) : (
          <Link2 />
        ))}
      {type === 'text' && <Type />}
      {type === 'whatsapp' && <WhatsAppSectionIcon />}
    </>
  );
}

type AnalyticProps = { id: number };

function Analytic({ id }: AnalyticProps) {
  const {
    data: clickCounts,
    isInitialLoading: isLoadingClick,
    isError: errorClickCounts,
  } = useLinkClick(id);

  return (
    <div className="flex items-center gap-5">
      {isLoadingClick ? (
        <Skeleton width={20} />
      ) : (
        !errorClickCounts &&
        clickCounts! > 0 && (
          <div className="flex items-center gap-1">
            <PointerIcon />
            <div className="font-medium">{clickCounts}</div>
          </div>
        )
      )}
    </div>
  );
}

type ActionableProps = {
  id: number;
  active: Linklists['active'];
  type: Linklists['type'];
};

function Actionable({ id, active, type }: ActionableProps) {
  const { data: shopifyIntegration, isInitialLoading } =
    useShopifyIntegrationByApp('BITLINK');

  const { setShow } = useShopifyConnectModal();

  const { mutate: updateLinklist, isLoading: isLoadingEdit } =
    useLinklistEditMutation();

  const handleToggle = (id: number, currentActive: Linklists['active']) => {
    updateLinklist({ id, data: { active: !currentActive } });
  };

  const handleChangeSwitch = () => {
    const changeFn = () => handleToggle(id, active);
    if (type === 'shop' && !shopifyIntegration) {
      setShow({
        appType: 'BITLINK',
        onSuccess: changeFn,
      });
    } else {
      changeFn();
    }
  };

  return (
    <>
      <BBBPrimarySwitch
        checked={active || false}
        onChange={handleChangeSwitch}
        disabled={isInitialLoading || isLoadingEdit}
        onClick={(e) => e.preventDefault()}
      />
    </>
  );
}
