import { useEffect, useMemo, useState } from 'react';
import CryptoJS from 'crypto-js';
import GeneralLayout from 'layouts/GeneralLayout';
import { mapRoleToBadgeProps } from 'pages/Settings/components/RoleBadge';
import CompanyInfo from 'pages/ShopifyOauthConnect/components/ChooseCompany/CompanyInfo';

import { BBBAlert, BBBButton, BBBCard, BBBSpinner } from '@/components';
import AuthCard from '@/components/Auth/Card';
import LoginCard from '@/components/Auth/LoginCard';
import ShowMore from '@/components/Auth/ShowMore';
import { Redirect } from '@/components/Redirect';
import { env } from '@/config/env';
import { mapAppTypeToUrl } from '@/constants/systemLogin/urlRedirect';
import useAuthenticatedUser from '@/hooks/auth/useAuthenticatedUser';
import useCompanyUsersByCompanyId from '@/hooks/auth/useCompanyUsersByCompanyId';
import useCompanyUsersByUserAndCompanyId from '@/hooks/auth/useCompanyUsersByUserAndCompanyId';
import useIntegratedCompaniesByShop from '@/hooks/auth/useIntegratedCompaniesByShop';
import useLogin from '@/hooks/auth/useLogin';
import useHistory from '@/hooks/common/useHistory';
import { AppType, User } from '@/types/systemLogin';
import { formatUserDisplayName } from '@/utils/auth';
import { _localStorage } from '@/utils/common/localStorage';
import { toast } from '@/utils/common/toast';

export default function ShopifyOauthLoginV2() {
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const code = urlParams.get('bitbybit-code');

  const decryptedCode:
    | {
        domain: string;
        app: AppType;
      }
    | undefined = code
    ? JSON.parse(
        CryptoJS.AES.decrypt(
          decodeURIComponent(code),
          env.REACT_APP_SHOPIFY_LOGIN_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      )
    : undefined;

  if (!decryptedCode)
    return <BBBAlert message="Invalid param provided" type="danger" />;

  return <_ShopifyOauthLoginV2 decryptedCode={decryptedCode} />;
}

function _ShopifyOauthLoginV2({
  decryptedCode,
}: {
  decryptedCode: {
    domain: string;
    app: AppType;
  };
}) {
  const { data: authenticatedUserData } = useAuthenticatedUser();
  const { mutate: login } = useLogin();

  const history = useHistory();

  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<User>();

  const { data, isInitialLoading } = useIntegratedCompaniesByShop(
    decryptedCode.app,
    decryptedCode.domain
  );

  const query = useCompanyUsersByCompanyId(selectedCompany);

  const {
    data: userCompanies,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading: isLoadingUserCompanies,
    fetchNextPage,
  } = query;

  const flattenedUserCompanies = userCompanies?.pages.flatMap(
    (page) => page.data
  );

  const { data: userCompany, isInitialLoading: isLoadingUserCompany } =
    useCompanyUsersByUserAndCompanyId({
      companyId: selectedCompany,
      userId: authenticatedUserData?.id,
    });

  useEffect(() => {
    const activeCompanyFromLocalStorage =
      _localStorage.getItem('activeCompany');

    if (
      activeCompanyFromLocalStorage &&
      data?.some(
        (company) => company.id === parseInt(activeCompanyFromLocalStorage, 10)
      )
    ) {
      setSelectedCompany(parseInt(activeCompanyFromLocalStorage, 10));
    }
  }, [data]);

  const redirectedUrl = `/${mapAppTypeToUrl[decryptedCode.app]}`;

  if (
    (authenticatedUserData?.id &&
      selectedUser?.id &&
      authenticatedUserData.id === selectedUser.id) ||
    !!userCompany
  ) {
    if (selectedCompany) {
      _localStorage.setItem('activeCompany', selectedCompany.toString());
    }
    return <Redirect to={redirectedUrl} />;
  }

  if (isInitialLoading || isLoadingUserCompany) {
    return <BBBSpinner />;
  }

  return (
    <GeneralLayout>
      {!data?.length ? (
        <BBBAlert message="No companies found for this domain" type="danger" />
      ) : (
        <>
          {!selectedCompany ? (
            <AuthCard title="Choose company to login">
              {data.map((company) => (
                <CompanyInfo
                  name={company.name}
                  key={company.id}
                  onClick={() => setSelectedCompany(company.id)}
                />
              ))}
            </AuthCard>
          ) : !selectedUser ? (
            <>
              <AuthCard title="Choose user to login">
                {isLoadingUserCompanies ? (
                  <BBBSpinner />
                ) : (
                  flattenedUserCompanies?.map((userCompany) => (
                    <BBBCard
                      key={userCompany.user.id}
                      className="flex items-center gap-4 mb-6"
                    >
                      {userCompany.user.profile?.profilePicture && (
                        <img
                          src={userCompany.user.profile?.profilePicture}
                          className="w-8 h-8 flex-none rounded-full"
                        />
                      )}
                      <div className="grow">
                        <div className="text-primary text-lg truncate">
                          {formatUserDisplayName(userCompany.user)}
                        </div>
                        <div className="text-primary text-gray-400">
                          {mapRoleToBadgeProps[userCompany.role].text}
                        </div>
                      </div>
                      <BBBButton
                        onClick={() => setSelectedUser(userCompany.user)}
                        className="truncate w-36"
                        text={`Login as ${formatUserDisplayName(
                          userCompany.user
                        )}`}
                      ></BBBButton>
                    </BBBCard>
                  ))
                )}
                {hasNextPage &&
                  (isFetchingNextPage ? (
                    <BBBSpinner />
                  ) : (
                    <ShowMore onClick={() => fetchNextPage()} />
                  ))}
                <BBBButton
                  variant="secondary"
                  text="Back"
                  className="mt-5"
                  onClick={() => setSelectedCompany(undefined)}
                />
              </AuthCard>
            </>
          ) : (
            <LoginCard
              onSubmit={function (data): void {
                if ('provider' in data && data.email !== selectedUser.email) {
                  toast.error(
                    `Email not match. Please authenticate with ${selectedUser.email}`
                  );
                } else {
                  login(data, {
                    onSuccess: () => {
                      _localStorage.setItem(
                        'activeCompany',
                        selectedCompany.toString()
                      );
                      history.push(
                        `/` + `?redirectTo=${encodeURIComponent(redirectedUrl)}`
                      );
                    },
                  });
                }
              }}
              hideRegisterCTA
              data={{ email: selectedUser.email }}
              withBack
              onBack={() => setSelectedUser(undefined)}
            />
          )}
        </>
      )}
    </GeneralLayout>
  );
}
