import { Handle, NodeProps, Position } from 'reactflow';
import { twMerge as cx } from 'tailwind-merge';
import { useDeleteState } from '../../hooks';
import useStore from '../../store';
import TrashWithTransition from '../TrashWithTransition';
import Connector from './Connector';
import CreateConnection from './CreateConnection';

import ArrowBack from '@/assets/icons/ArrowBack';
import { BBBCard } from '@/components';
import colors from '@/constants/common/colors';

export default function BackNode({
  id,
}: NodeProps<{
  /** */
}>) {
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );
  const deleteNode = useDeleteState();

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);

  return (
    <BBBCard
      className={cx(
        'flex items-center gap-2 group w-96 relative transition-colors',
        expandState === 'hover' && 'border-secondary-main',
        expandState === 'clicked' && 'border-secondary-main'
      )}
      onMouseEnter={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, 'hover');
        }
      }}
      onMouseLeave={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, null);
        }
      }}
    >
      <ArrowBack size={24} color={colors.primary.main} />
      <div className="grow">Back to previous messages</div>
      <TrashWithTransition
        onClick={(e) => {
          deleteNode(id);
          e.stopPropagation();
        }}
      />
      <CreateConnection
        state={expandState}
        onChangeState={(state) => setExpandState(id, state)}
        nodeId={id}
      />
      <Handle
        type="target"
        position={Position.Left}
        className={!hasEdges ? 'opacity-0 pointer-events-none' : ''}
      />
    </BBBCard>
  );
}
