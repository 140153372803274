import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import PricingInfo from './PricingInfo/PricingInfo';
import CompanyList from './CompanyList';
import UserZone from './UserZone';

import BBBIconSmall from '@/assets/icons/BBBIconSmall';
import BBBLogo from '@/assets/icons/BBBLogo';
import ExpandIcon from '@/assets/icons/ExpandIcon';
import { Link } from '@/components/Link';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setSidebar } from '@/stores/common';
import { AppType } from '@/types/systemLogin';

type Props = {
  isHovering?: boolean;
  onChangeHover?: (val: boolean) => void;
  children?: React.ReactNode;
  withUserProfile?: boolean;
  expandedVersion?: boolean;
  app: string;
};

const mapAppRouteToAppType: Record<string, AppType> = {
  bitapp: 'BITAPP',
  bitlink: 'BITLINK',
  bitchat: 'BITCHAT',
  bitlogin: 'BITLOGIN',
  bitcrm: 'BITCRM',
  bitai: 'BITAI',
};

function SidebarWrapper({
  isHovering,
  onChangeHover,
  children,
  withUserProfile,
  expandedVersion,
  app,
}: Props) {
  const expanded = useAppSelector((state) => state.common.sidebar.expanded);
  const isMobile = useResponsive('sm');
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col h-full">
      <div className="grow relative">
        <div
          className={cx(
            'absolute inset-0 overflow-x-hidden',
            expanded && !isHovering && 'overflow-y-hidden'
          )}
        >
          <motion.div>
            {!expanded || (expanded && isHovering) ? (
              <div className="mt-8 mb-8 flex gap-6 items-center justify-center">
                <Link to="/home">
                  <BBBLogo />
                </Link>
                {!isMobile && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        setSidebar({
                          expanded: !expanded,
                        })
                      );
                      onChangeHover?.(true);
                    }}
                  >
                    <ExpandIcon expanded={expanded} />
                  </div>
                )}
              </div>
            ) : (
              <div
                className="h-14 flex justify-center items-center mt-8 mb-8 cursor-pointer"
                onClick={() =>
                  dispatch(
                    setSidebar({
                      expanded: !expanded,
                    })
                  )
                }
              >
                <BBBIconSmall />
              </div>
            )}
          </motion.div>
          <motion.div>
            {!expanded || (expanded && isHovering) ? (
              <CompanyList />
            ) : (
              <div className="h-[58px]" />
            )}
          </motion.div>
          {children}
        </div>
      </div>
      {expandedVersion ? (
        <motion.div>
          {!(expanded && !isHovering) && (
            <PricingInfo app={mapAppRouteToAppType[app]} />
          )}
        </motion.div>
      ) : (
        <UserZone expanded={expanded && !isHovering} />
      )}
    </div>
  );
}

export default SidebarWrapper;
