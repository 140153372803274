import { useActiveCompany } from '../rtk/selector';

import { PlanType } from '@/api/services/pricing';
import { pricingApiBaseUrl } from '@/constants/api';
import { AppType } from '@/types/systemLogin';

const usePricingBillingUrl = ({
  appType,
  type,
  domain,
}: {
  appType: AppType;
  type: PlanType;
  domain?: string;
}) => {
  const activeCompany = useActiveCompany();

  if (!domain) return;

  return `${pricingApiBaseUrl}/pricing/company/${activeCompany}/billing?app=${appType}&type=${type}&domain=${domain}`;
};

export default usePricingBillingUrl;
