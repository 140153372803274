import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';
import Cookies from 'universal-cookie';

import UpsellIcon from '@/assets/icons/UpsellIcon';
import { BBBButton, BBBCard, Link } from '@/components';
import { env } from '@/config/env';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { AppType } from '@/types/systemLogin';

const cookies = new Cookies(null, { path: '/' });

type Props = {
  title: string;
  description?: string;
  appType: AppType;
  className?: string;
  id: string;
  isNotAdvertise?: boolean;
};

function BBBUpsellCard(props: Props) {
  const { data, status } = usePricingByApp(props.appType);

  if (status === 'error' || status === 'loading') return null;

  if (data?.pricingName === 'free' && props.isNotAdvertise) return null;

  if (data?.pricingName === 'standard' && !props.isNotAdvertise) return null;

  return <_BBBUpsellCard {...props} />;
}

function _BBBUpsellCard({
  title,
  description,
  appType,
  className,
  id: _id,
  isNotAdvertise = false,
}: Props) {
  const isMobile = useResponsive('sm');
  const [show, setShow] = useState(true);

  const id = `upsell-banner-${appType.toLowerCase()}-${_id}`;

  useEffect(() => {
    const BANNER_DELAY: [number, dayjs.ManipulateType] = [
      isNotAdvertise ? 0 : 2,
      'days',
    ];
    if (!cookies.get(id)) {
      cookies.set(
        id,
        JSON.stringify({
          showAt: dayjs()
            .add(...BANNER_DELAY)
            .toISOString(),
        })
      );
    }
  }, [id, isNotAdvertise]);

  const cookieValues = cookies.get(id);

  const handleCancelAdvertisement = () => {
    cookies.remove(id, { path: '/' });
    setShow(false);
  };

  const handleCancelNotAdvertisement = () => {
    cookies.set(
      id,
      JSON.stringify({
        showAt: dayjs().add(1, 'days').toISOString(),
      })
    );
    setShow(false);
  };

  if (!show || (cookieValues && !dayjs().isAfter(dayjs(cookieValues.showAt))))
    return null;

  return (
    <>
      <BBBCard
        className={cx(
          'mb-cardBottom flex lg:flex-row flex-col items-center gap-4 border-2 border-secondary-main',
          className
        )}
      >
        {!isNotAdvertise && !isMobile ? (
          <div className="flex-none">
            <UpsellIcon />
          </div>
        ) : null}
        <div className="grow">
          <div className="font-bold mb-1">{title}</div>
          <div className="text-sm">{description}</div>
        </div>
        <div className="flex lg:items-center gap-2.5">
          <BBBButton
            text="Not now"
            size={isMobile ? 'sm' : 'md'}
            variant="secondary"
            onClick={
              isNotAdvertise
                ? handleCancelNotAdvertisement
                : handleCancelAdvertisement
            }
          />
          {!isNotAdvertise ? (
            <Link to={`/pricing?tab=${appType.toLowerCase()}`}>
              <BBBButton size={isMobile ? 'sm' : 'md'} text="Upgrade" />
            </Link>
          ) : (
            <BBBButton
              text="Contact us"
              size={isMobile ? 'sm' : 'md'}
              onClick={() =>
                window.open(
                  `https://wa.me/${env.REACT_APP_BBB_CS_NUMBER}?text=
                    ${encodeURIComponent(`I have questions about ${appType}`)}
                  `,
                  '_blank'
                )
              }
            />
          )}
        </div>
      </BBBCard>
    </>
  );
}

export default BBBUpsellCard;
