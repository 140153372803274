import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components/BBBCard';
import { useAppSelector } from '@/hooks/rtk/store';
import { useStartChat } from '@/hooks/whatsApp/chat';
import useWAGroupParticipants from '@/hooks/whatsApp/useWAGroupParticipants';

const LIMIT_PARTICIPANTS = 5;

export default function GroupDetail() {
  const activeChat = useAppSelector((state) => {
    const _selectedChat = state.bitCRM.selectedChat!;

    return {
      clientName: _selectedChat.clientName,
      clientNumber: _selectedChat.clientNumber,
    };
  }, shallowEqual);

  const [showParticipants, setShowParticipants] = useState(false);

  const { data: participantsData, isInitialLoading: loadingParticipants } =
    useWAGroupParticipants();

  const dataParticipants = participantsData?.participants;

  const hasMore = (dataParticipants?.length ?? 0) > LIMIT_PARTICIPANTS;

  const previewParticipants = dataParticipants?.slice(0, LIMIT_PARTICIPANTS);
  const restParticipants = dataParticipants?.slice(
    LIMIT_PARTICIPANTS,
    dataParticipants.length
  );

  const { mutate: startChat, isLoading: loadingStartChat } = useStartChat();

  return (
    <BBBCard
      title={activeChat.clientName || activeChat.clientNumber}
      desc={`${
        loadingParticipants
          ? 'loading...'
          : participantsData?.participantTotal
          ? `${participantsData.participantTotal} Participants`
          : ''
      }`}
    >
      <>
        <div className="flex flex-col gap-2 mt-2">
          {previewParticipants?.map((item) => (
            <div
              key={item.id}
              className={cx(
                'text-primary-main line-clamp-1 hover:underline hover:cursor-pointer',
                loadingStartChat && 'opacity-50 pointer-events-none'
              )}
              onClick={() =>
                startChat({
                  conversationId: item.clientNumber,
                  sources: item.sources,
                })
              }
            >
              {item.clientName} (+{item.clientNumber})
            </div>
          ))}
          <AnimatePresence>
            {showParticipants && (
              <motion.div
                initial={{
                  height: 0,
                }}
                animate={{
                  height: 'auto',
                }}
                exit={{
                  height: 0,
                }}
                className="flex flex-col gap-2"
                transition={{
                  type: 'tween',
                  duration: 0.3,
                }}
              >
                {restParticipants?.map((item) => (
                  <div
                    key={item.id}
                    className={cx(
                      'text-primary-main line-clamp-1 hover:underline hover:cursor-pointer',
                      loadingStartChat && 'opacity-50 pointer-events-none'
                    )}
                    onClick={() => {
                      startChat({
                        conversationId: item.clientNumber,
                        sources: item.sources,
                      });
                    }}
                  >
                    {item.clientName} (+{item.clientNumber})
                  </div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>

          {hasMore && (
            <div
              className="text-primary-main underline cursor-pointer"
              onClick={() => setShowParticipants(!showParticipants)}
            >
              {showParticipants
                ? 'Show Less'
                : `${
                    (participantsData?.participantTotal ?? 0) -
                    LIMIT_PARTICIPANTS
                  } More`}
            </div>
          )}
        </div>
      </>
    </BBBCard>
  );
}
