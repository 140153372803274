import React, { useState } from 'react';
import { env } from 'config/env';
import { useCollection, useCollections } from 'hooks/bitCRM/shopify';
import ShopifyCollection from 'types/shopify/ShopifyCollections';
import { BBBSelect } from 'components';

type Props = {
  onChange: (val?: string | null) => void;
  onClick?: () => void;
  isMenuOpen?: boolean;
  value?: string;
  label: string | React.ReactNode;
  error?: string;
  className?: string;
};

function ShopifyCollectionListsOptions({
  onChange,
  onClick,
  label,
  error,
  value,
  isMenuOpen,
  className,
}: Props) {
  const [search, setSearch] = useState<string>();

  const query = useCollections({ search });

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = query;

  const parseCollectionId = value
    ? parseInt(value.replace('gid://shopify/Collection/', ''), 10)
    : undefined || 0;

  const { data: collectionData } = useCollection(parseCollectionId, {
    enabled: !!value,
  });

  const orderedCollections = data?.pages.flatMap((page) =>
    page.collections.sort((a, b) =>
      a.title.localeCompare(b.title, 'en', {
        sensitivity: 'base',
      })
    )
  );

  const collections = orderedCollections?.map((collection) => ({
    id: collection.id,
    title: <CollectionOption collection={collection} />,
  }));

  return (
    <BBBSelect
      containerClassName={className}
      label={label}
      options={collections}
      loading={isInitialLoading}
      optionValue="id"
      optionLabel="title"
      isSearchable
      search={search}
      onChangeSearch={setSearch}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      placeholder="Choose collection"
      value={
        collectionData
          ? {
              id: collectionData.id,
              title: <CollectionOption collection={collectionData} />,
            }
          : null
      }
      // @s-ignore
      onValueChange={(val) => onChange(val?.id)}
      onClick={() => onClick?.()}
      isMenuOpen={isMenuOpen}
      error={error}
    />
  );
}

function CollectionOption({ collection }: { collection: ShopifyCollection }) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          collection.image
            ? collection?.image?.url
            : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={collection.id}
        loading="lazy"
      />
      <p className="text-sm text-primary-main">{collection.title}</p>
    </div>
  );
}

export default ShopifyCollectionListsOptions;
