import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { mapSourceToLabel } from '../CustomerDetail/CustomerDetail';

import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';
import useDeleteTag from '@/hooks/customers/customer/useDeleteTag';
import { CustomerWithAssociation } from '@/types/customers/association';
import { formatDate2 } from '@/utils/common/date';
import { formatInternational } from '@/utils/common/phone';
import { formatDisplayName } from '@/utils/customers';

const useColumn = () => {
  const { segmentId } = useParams<{ segmentId: string }>();
  const { mutate: deleteTag } = useDeleteTag();

  return useMemo<HeadersTypes<CustomerWithAssociation>>(() => {
    return [
      {
        accesor: 'firstName',
        renderHeader: () => 'Name',
        isSortable: true,
        render: (info) => {
          return (
            <div className="text-primary-main group-hover:underline w-fit">
              {formatDisplayName(
                info.phoneNumber,
                info.firstName,
                info.lastName
              )}
            </div>
          );
        },
      },
      {
        accesor: 'phoneNumber',
        renderHeader: () => 'Phone Number',
        isSortable: false,
        render: (info) => {
          return (
            <p className="text-primary-main">
              {formatInternational(info.phoneNumber)}
            </p>
          );
        },
      },
      {
        accesor: 'customerTags',
        renderHeader: () => 'Tags',
        isSortable: false,
        render: (info) => (
          <TruncatedTags
            items={info.customerTags.map((tag) => tag.tagName)}
            maxLines={2}
            containerWidth={120}
            onDelete={(tag, e) => {
              deleteTag({
                tagName: tag,
                customerId: info.id,
              });
              e.stopPropagation();
            }}
          />
        ),
      },
      ...(!segmentId
        ? ([
            {
              accesor: 'source',
              renderHeader: () => 'Source',
              isSortable: false,
              render: (info) => {
                return (
                  <p className="text-primary-main">
                    {info.sources
                      .map((source) => mapSourceToLabel[source])
                      .join(', ')}
                  </p>
                );
              },
            },
          ] as HeadersTypes<CustomerWithAssociation>)
        : []),
      {
        accesor: 'updatedAt',
        renderHeader: () => 'Last updated',
        isSortable: true,
        sortOrder: 'desc',
        render: (info) => {
          return (
            <p className="text-primary-main">{formatDate2(info.updatedAt)}</p>
          );
        },
      },
    ];
  }, [deleteTag, segmentId]);
};

export default useColumn;
