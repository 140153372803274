import { useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useHistory } from 'react-router';
import { callToActionsOptions } from 'constants/bitCRM/template';
import { useShopifyIntegrations } from 'hooks/bitCRM/integration/integrations';
import { useSuggestedKeywords } from 'hooks/whatsApp/flow';
import { useForms } from 'hooks/whatsApp/form';
import ShopifyCollectionListsOptions from 'pages/BitCRM/components/Shopify/CollectionListOptions';
import ShopifyProductListsOptions from 'pages/BitCRM/components/Shopify/ProductListOptions';
import { BBBSelect, BBBTextInput } from 'components';
import ConnectShopifyButton from 'components/ConnectShopifyButtons';
import { FormSchema } from '..';

type Props = {
  control: Control<FormSchema>;
  setValue?: UseFormSetValue<FormSchema>;
};

export default function ButtonType({ ...props }: Props) {
  const type = useWatch({
    control: props.control,
    name: 'buttonType.value',
  });

  return (
    <>
      {type === 'reply_button' ? (
        <ReplyButton {...props} />
      ) : type === 'whatsapp_form' ? (
        <WhatsAppForm {...props} />
      ) : type === 'call_to_action' ? (
        <CallToAction {...props} />
      ) : null}
    </>
  );
}

function ReplyButton({ control }: Props) {
  const { data } = useSuggestedKeywords();

  const { errors } = useFormState({ control });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-lg text-neutral-900">What is reply button?</p>
        <p className="text-sm text-neutral-500">
          Use the Reply Button to enable customers to respond instantly to your
          campaign. This feature is perfect for quick interactions and immediate
          feedback.
        </p>
      </div>
      <BBBTextInput
        label="Reply button text"
        placeholder="Input reply button text"
        maxChar={20}
        hasMaxCharLabel
        isHookForm
        control={control}
        controlName="buttonText"
        containerClassname="mb-0"
        error={errors.buttonText?.message}
      />
      {data && data?.length > 0 && (
        <p className="text-neutral-500">
          These are example words that will trigger a chatbot: <br />
          {data?.map((item, index) => (
            <span key={index}>
              {item}
              {index !== data.length - 1 && ', '}
            </span>
          ))}
        </p>
      )}
    </div>
  );
}

function WhatsAppForm({ control }: Props) {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const whatsappFormQuery = useForms({ search, status: 'PUBLISHED' });

  const { errors } = useFormState({
    control,
  });

  const redirectCb = () => {
    history.push({
      pathname: `/bitchat/whatsapp-form`,
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-lg text-neutral-900">What is WhatsApp Form?</p>
        <p className="text-ls text-neutral-500">
          The WhatsApp Form Button displays a form directly within WhatsApp.
          Ideal for collecting data such as reservations, NPS surveys, and more,
          making it easy for customers to provide the information you need.
        </p>
      </div>
      <div className="flex item-center gap-2">
        <Controller
          control={control}
          name="whatsAppForm"
          render={({ field }) => (
            <BBBSelect
              containerClassName="grow"
              options={whatsappFormQuery.data}
              placeholder="Select WhatsApp form"
              onValueChange={field.onChange}
              value={field.value}
              label="WhatApp form"
              optionLabel="name"
              optionValue="metaId"
              isSearchable
              search={search}
              onChangeSearch={setSearch}
              error={errors.whatsAppForm?.message}
              withCreateRedirectOption
              onClickCreateRedirect={redirectCb}
              persistCreateRedirectOption
              createRedirectLabel="Create new WhatsApp form"
            />
          )}
        />
        <BBBTextInput
          containerClassname="grow"
          label="Button text"
          placeholder="Input button text"
          isHookForm
          control={control}
          hasMaxCharLabel
          maxChar={20}
          controlName="buttonText"
          error={errors.buttonText?.message}
        />
      </div>
    </div>
  );
}

function CallToAction({ control, setValue }: Props) {
  const { data: shopifyData } = useShopifyIntegrations();
  const { errors } = useFormState({
    control,
  });

  const bringCustomerToValue = useWatch({
    control,
    name: 'bringCustomerTo.value',
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-lg text-neutral-900">What is call to action?</p>
        <p className="text-ls text-neutral-500">
          The CTA Button takes customers to a specified URL in their browser.
          Use this to direct traffic to your website, landing pages, or any
          online resource.
        </p>
      </div>
      <Controller
        control={control}
        name="bringCustomerTo"
        render={({ field }) => (
          <BBBSelect
            options={callToActionsOptions}
            placeholder="Select type"
            onValueChange={(val) => {
              field.onChange(val);
              setValue?.('buttonText', '');
            }}
            value={field.value}
            label="Bring customer to"
            optionLabel="label"
            optionValue="value"
            error={errors.bringCustomerTo?.message}
          />
        )}
      />
      <div className="flex item-center gap-2">
        {bringCustomerToValue === 'web_url' ? (
          <BBBTextInput
            containerClassname="grow"
            label="URL"
            placeholder="Input URL"
            isHookForm
            control={control}
            isUrl
            controlName="customUrl"
            error={errors.customUrl?.message}
          />
        ) : bringCustomerToValue === 'product' ? (
          <>
            {shopifyData?.status === 'DISCONNECTED' ? (
              <ConnectShopifyHandler />
            ) : (
              <Controller
                control={control}
                name="product"
                render={({ field }) => (
                  <ShopifyProductListsOptions
                    label="Product"
                    value={field.value?.toString()}
                    onChange={(val) => field.onChange(val)}
                    className="grow"
                    error={errors.product?.message}
                    {...(!shopifyData || shopifyData?.status === 'DISCONNECTED'
                      ? {
                          isSearchable: false,
                        }
                      : {
                          isSearchable: true,
                        })}
                  />
                )}
              />
            )}
          </>
        ) : bringCustomerToValue === 'collections' ? (
          <>
            {shopifyData?.status === 'DISCONNECTED' ? (
              <ConnectShopifyHandler />
            ) : (
              <Controller
                control={control}
                name="collection"
                render={({ field }) => (
                  <ShopifyCollectionListsOptions
                    label="Collections"
                    value={field.value?.toString()}
                    onChange={(val) => field.onChange(val)}
                    error={errors.collection?.message}
                    className="grow"
                    {...(!shopifyData || shopifyData?.status === 'DISCONNECTED'
                      ? {
                          isMenuOpen: false,
                        }
                      : {})}
                  />
                )}
              />
            )}
          </>
        ) : null}
        {shopifyData?.status === 'DISCONNECTED' &&
        bringCustomerToValue === 'collections' ? null : shopifyData?.status ===
            'DISCONNECTED' && bringCustomerToValue === 'product' ? null : (
          <BBBTextInput
            containerClassname="grow"
            label="Button text"
            placeholder="Input button text"
            isHookForm
            control={control}
            controlName="buttonText"
            hasMaxCharLabel
            maxChar={20}
            error={errors.buttonText?.message}
          />
        )}
      </div>
    </div>
  );
}

function ConnectShopifyHandler() {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-neutral-900">
        Integrate with Shopify to link the CTA button to specific products or
        collections.
      </p>
      <ConnectShopifyButton
        className="w-fit"
        appType="BITCRM"
        text="Connect with shopify"
      />
    </div>
  );
}
