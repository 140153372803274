import api from '@/config/api';
import { AnalyticsResponse } from '@/types/analytics';
import {
  AnalyticAgents,
  AnalyticCharts,
  Analytics,
} from '@/types/bitChat/analytics';
import { convertToLocalTime } from '@/utils/common/date';

export default {
  getChats: (
    companyId: number | undefined,
    {
      startDate,
      endDate,
    }: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitChat
      .get<AnalyticsResponse<Analytics>>(`/analytics/${companyId}`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then((res) => res.data),
  getAgents: (
    companyId: number | undefined,
    { startDate, endDate, search }: AnalyticParams
  ) =>
    api.bitChat
      .get<AnalyticsResponse<AnalyticAgents[]>>(
        `/analytics/${companyId}/agent`,
        {
          params: {
            startDate,
            endDate,
            search,
          },
        }
      )
      .then((res) => res.data),
  downloadAgents: (
    companyId: number | undefined,
    { startDate, endDate, search }: AnalyticParams
  ) =>
    api.bitChat
      .post(
        `/analytics/${companyId}/agent?startDate=${startDate}&endDate=${endDate}&search=${search}`,
        null,
        {
          responseType: 'blob',
        }
      )
      .then((res) => res.data),
  getAnalyticCharts: (
    companyId: number | undefined,
    params: AnalyticParamsPerChannel
  ) =>
    api.whatsApp.v3
      .get<AnalyticsResponse<AnalyticCharts>>(
        `/analytics/${companyId}/graphic`,
        {
          params,
        }
      )
      .then((res) => ({
        ...res.data.data,
        peakHours: res.data.data.peakHours.map((peakHour) => ({
          ...peakHour,
          time: convertToLocalTime(peakHour.time),
        })),
        totalMessages: res.data.data.totalMessages.map((totalMessage) => ({
          ...totalMessage,
          label: convertToLocalTime(totalMessage.label),
        })),
        totalConversation: res.data.data.totalConversation.map(
          (totalConversation) => ({
            ...totalConversation,
            label: convertToLocalTime(totalConversation.label),
          })
        ),
      })),
};

export type AnalyticParams = {
  startDate?: string;
  endDate?: string;
  search?: string;
};

export type AnalyticParamsPerChannel = {
  startDate?: string;
  endDate?: string;
  search?: string;
};
