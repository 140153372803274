import { twMerge as cx } from 'tailwind-merge';

import { BBBTooltip } from '@/components';
import {
  UserProfilePicture1,
  UserProfilePictureProps,
} from '@/components/Auth/UserProfilePicture';
import {
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
  staticAgents,
} from '@/constants/bitChat/agent';

type Props = {
  agentId: number;
  withoutImage?: boolean;
  textClassName?: string;
} & Omit<UserProfilePictureProps, 'size' | 'fontSize'>;

export default function AgentInfo(props: Props) {
  if (staticAgents.includes(props.agentId)) {
    if (props.agentId === EVERYONE_AGENT_ID) return null;

    return <>{generalAiAgentOption.formattedName}</>;
  }

  return <_AgentInfo {...props} />;
}

function _AgentInfo({ withoutImage, textClassName, ...props }: Props) {
  return (
    <div className="flex gap-1 items-center">
      {!withoutImage && (
        <UserProfilePicture1
          {...props}
          displayName={props.displayName
            ?.split(' ')
            .slice(0, 2)
            .map((name) => name.slice(0, 1))
            .join('')}
          size={16}
        />
      )}
      <div className="max-w-[8rem] overflow-hidden">
        <AgentName
          agentName={props.displayName || 'Agent'}
          className={textClassName}
        />
      </div>
    </div>
  );
}

function AgentName({
  agentName,
  className,
}: {
  agentName: string;
  className?: string;
}) {
  return (
    <BBBTooltip show content={agentName}>
      <p
        className={cx(
          'w-fit line-clamp-1 overflow-anywhere text-sm',
          className
        )}
      >
        {agentName}
      </p>
    </BBBTooltip>
  );
}
