import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components';
import colors from '@/constants/common/colors';
import useResponsive from '@/hooks/common/useResponsive';

export default function BBBNavigationCard<
  T extends { label: string; value: string }
>({
  selected,
  onChange,
  options,
  title,
  link,
}: {
  options: T[];
  selected: T['value'];
  onChange?: (val: T['value']) => void;
  title?: string;
  link?: (val: T['value']) => string;
}) {
  const isMobile = useResponsive('sm');
  const Comp = typeof link !== 'undefined' ? Link : 'div';

  return (
    <BBBCard title={title} className="flex-none lg:w-[18.5rem] w-full">
      {options.map((setting) => (
        //@ts-ignore
        <Comp
          className={cx(
            'p-2 rounded-lg cursor-pointer mb-6 hover:bg-neutral-20 hover:text-neutral-60 lg:text-neutral-50 text-primary-main last:mb-0 ',
            selected === setting.value &&
              'text-neutral-70 bg-secondary-surface',
            isMobile && 'w-full justify-between',
            typeof link !== 'undefined' && 'lg:block flex'
          )}
          key={setting.value}
          onClick={() => onChange?.(setting.value)}
          {...(typeof link !== 'undefined' && {
            to: link(setting.value),
          })}
        >
          {setting.label}
          {isMobile && <ChevronRight color={colors.grayColor2} />}
        </Comp>
      ))}
    </BBBCard>
  );
}
