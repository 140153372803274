import { useMemo, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { BBBSelect } from '@/components';
import { env } from '@/config/env';
import useProduct from '@/hooks/bitApp/shopify/useProduct';
import useProducts from '@/hooks/bitApp/shopify/useProducts';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';
import ShopifyProducts from '@/types/shopify/ShopifyProduct';

type Props = {
  className?: string;
  label: string | React.ReactNode;
  value: string | null | undefined;
  onChange: (val?: string | null | undefined) => void;
  error?: string;
};

function ShopifyProductListsOptions({
  className,
  label,
  value,
  onChange,
  error,
}: Props) {
  const [title, setTitle] = useState<string>();

  const client = useQueryClient();

  const memoizedParams = useMemo(
    () => ({
      title,
    }),
    [title]
  );

  const query = useProducts(memoizedParams);

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = query;

  const { data: productData, isInitialLoading: isLoadingProduct } = useProduct(
    value,
    {
      initialData: client
        .getQueryData<
          InfiniteData<ShopifyConnection<ShopifyProducts>> | undefined
        >(['bitapp-infinite-products', memoizedParams])
        ?.pages.flatMap((page) => page.edges.map((edge) => edge.node))
        .find((data) => data.id === value),
    }
  );

  const memoizedCurrentPages = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.edges.map((edge) => ({
          id: edge.node.id,
          title: <ProductOption product={edge.node} />,
        }))
      ),
    [data?.pages]
  );

  return (
    <BBBSelect
      containerClassName={className}
      label={label}
      options={memoizedCurrentPages}
      optionLabel="title"
      optionValue="id"
      placeholder="Choose product"
      loading={isInitialLoading || isLoadingProduct}
      search={title}
      isSearchable
      onChangeSearch={setTitle}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      //@ts-ignore
      value={productData}
      onValueChange={(val) => onChange(val?.id)}
      error={error}
    />
  );
}
function ProductOption({ product }: { product: ShopifyProducts }) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          product?.featuredImage?.url ||
          `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={product.id}
        loading="lazy"
      />
      <p className="text-sm text-primary-main">{product.title}</p>
    </div>
  );
}

export default ShopifyProductListsOptions;
