/* eslint-disable react/require-default-props */
import { ForwardedRef, forwardRef, Ref } from 'react';
import { FieldValues } from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';

import SearchIcon from '@/assets/icons/SearchIcon';
import { BBBDebounceInput, BBBDebounceInputProps } from '@/components';

type SearchInputProps<T extends FieldValues> = {
  containerClassName?: string;
  placeholder?: string;
  className?: string;
} & BBBDebounceInputProps<T>;

const SearchInput = <T extends FieldValues>(
  {
    value = '',
    containerClassName,
    onValueChange = () => ({}),
    placeholder = 'Search',
    inputClassName,
    isFixed,
    fixedLabel,
    fixedLabelClassname,
    ...props
  }: SearchInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <BBBDebounceInput
      placeholder={placeholder}
      value={value}
      onValueChange={(value) => onValueChange?.(value)}
      containerClassname={containerClassName}
      isFixed
      fixedLabel={
        <div className="flex items-center justify-center pl-4">
          <SearchIcon />
        </div>
      }
      inputClassName={cx('px-2.5', inputClassName)}
      {...props}
      ref={ref}
    />
  );
};

export default forwardRef(SearchInput) as <T extends FieldValues>(
  p: SearchInputProps<T> & { ref?: Ref<HTMLInputElement> }
) => React.ReactElement;
