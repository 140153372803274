import { ApiResponse } from 'api/types';
import api from 'config/api';
import { ChannelIntegrationValues } from 'constants/integrations';

export default {
  getTotalMessages: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<ApiResponse<number>>(`/analytics/${companyId}/total-messages`, {
        params,
      })
      .then((res) => res.data.data),
  getUniqueCustomers: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<ApiResponse<number>>(`/analytics/${companyId}/unique-customers`, {
        params,
      })
      .then((res) => res.data.data),
  getNewCustomers: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<ApiResponse<number>>(`/analytics/${companyId}/new-customers`, {
        params,
      })
      .then((res) => res.data.data),
  getTotalConversations: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<ApiResponse<number>>(`/analytics/${companyId}/total-conversations`, {
        params,
      })
      .then((res) => res.data.data),
  getTicketResolved: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<ApiResponse<number>>(`/analytics/${companyId}/ticket-resolved`, {
        params,
      })
      .then((res) => res.data.data),
  getTicketOpened: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<ApiResponse<number>>(`/analytics/${companyId}/ticket-opened`, {
        params,
      })
      .then((res) => res.data.data),
  getMessagePeakHours: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<
        ApiResponse<
          {
            hour: string;
            count: number;
          }[]
        >
      >(`/analytics/${companyId}/message-peak-hours`, {
        params,
      })
      .then((res) =>
        res.data.data.map((data) => ({ label: data.hour, value: data.count }))
      ),
  getConversationPeakHours: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<
        ApiResponse<
          {
            hour: number;
            label: string;
            total: number;
          }[]
        >
      >(`/analytics/${companyId}/conversation-peak-hours`, {
        params,
      })
      .then((res) =>
        res.data.data.map((data) => ({ label: data.label, value: data.total }))
      ),
  getTopTicketCategories: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<
        ApiResponse<
          {
            label: string;
            value: number;
          }[]
        >
      >(`/analytics/${companyId}/top-ticket-categories`, {
        params,
      })
      .then((res) => res.data.data),
  getTopAiTags: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<
        ApiResponse<
          {
            tag: string;
            total: number;
          }[]
        >
      >(`/analytics/${companyId}/top-ai-tags`, {
        params,
      })
      .then((res) =>
        res.data.data.map((data) => ({ label: data.tag, value: data.total }))
      ),
  getCustomerSatisfactions: (companyId: number, params: AnalyticParams) =>
    api.whatsApp.v3
      .get<
        ApiResponse<
          {
            label: string;
            value: number;
          }[]
        >
      >(`/analytics/${companyId}/customer-satisfactions`, {
        params,
      })
      .then((res) => res.data.data),
};

export type AnalyticParams = {
  startDate: string;
  endDate: string;
  channel: ChannelIntegrationValues;
};
