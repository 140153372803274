import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import services from '@/api/services';
import api from '@/config/api';
import { useActiveCompany, useUserId } from '@/hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setConnectIntegration } from '@/stores/common';
import { IntegrationExtra, IntegrationName } from '@/types/bitApp/v2';
import { CompanyIntegrationAssociation } from '@/types/bitApp/v2/association';
import { toast } from '@/utils/common/toast';

export const useIntegrations = () =>
  useQuery(['bitapp-integrations'], () =>
    services.bitApp.integrations.getIntegrations()
  );

export const useIntegrationDetail = (id: number | undefined) =>
  useQuery(
    ['bitapp-integration', id],
    () => services.bitApp.integrations.getIntegrationDetail(id),
    {
      enabled: !!id,
    }
  );

export const useCompanyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation[],
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitapp-company-integrations', activeCompany],
    () => services.bitApp.integrations.getCompanyIntegrations(activeCompany),
    { enabled: enabled, ...options }
  );
};

export const useReviewIntegration = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitapp-company-integrations', 'review', activeCompany],
    () => services.bitApp.integrations.getReviewIntegration(activeCompany)
  );
};

export const useShopifyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(
    ['bitapp-company-integrations', activeCompany],
    () => services.bitApp.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'shopify'),
      ...options,
    }
  );
};

export const useShopifyIntegrationExtra = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    IntegrationExtra | null,
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(
    ['bitapp-company-integrations', activeCompany],
    () => services.bitApp.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) =>
        companyIntegrationSelector(data, 'shopify')?.extra || null,
      ...options,
    }
  );
};

export const useStampedIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitapp-company-integrations', activeCompany],
    () => services.bitApp.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'stamped'),
      ...options,
    }
  );
};

export const useYotpoIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitapp-company-integrations', activeCompany],
    () => services.bitApp.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'yotpo'),
      ...options,
    }
  );
};

export const useConnectStamped = () => {
  const dispatch = useAppDispatch();
  const companyId = useActiveCompany();
  const userId = useUserId();
  const queryClient = useQueryClient();

  return useMutation(
    ({ privateKey, publicKey, storeHash }: ReviewIntegrationPayload) =>
      api.bitApp.post(`/integrations/stamped/auth`, {
        companyId,
        userId,
        apiKey: publicKey,
        apiSecret: privateKey,
        storeHash,
      }),
    {
      onSuccess: () => {
        toast.success('Connect succesfully');
        queryClient.invalidateQueries([`bitapp-company-integrations`]);
        dispatch(setConnectIntegration(null));
      },
    }
  );
};

export const useConnectYotpo = () => {
  const dispatch = useAppDispatch();
  const companyId = useActiveCompany();
  const userId = useUserId();
  const queryClient = useQueryClient();

  return useMutation(
    ({ privateKey, publicKey }: ReviewIntegrationPayload) =>
      api.bitApp.post(`/integrations/yotpo/auth`, {
        companyId,
        userId,
        apiKey: publicKey,
        apiSecret: privateKey,
      }),
    {
      onSuccess: () => {
        toast.success('Connect succesfully');
        queryClient.invalidateQueries([`bitapp-company-integrations`]);
        dispatch(setConnectIntegration(null));
      },
    }
  );
};

export const useToggleIntegration = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const userId = useUserId();

  return useMutation(
    (
      data: Omit<
        Parameters<
          typeof services.bitApp.integrations.toggleCompanyIntegrations
        >[1],
        'userId'
      >
    ) =>
      services.bitApp.integrations.toggleCompanyIntegrations(activeCompany, {
        ...data,
        userId,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries([
          'bitapp-company-integrations',
          activeCompany,
        ]);
        client.invalidateQueries(['bitapp-shopify', activeCompany]);
        toast.success('Integration disconnected successfully!');
      },
    }
  );
};

export const useSetReviewIntegration = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useMutation(
    async (
      payload: Parameters<
        typeof services.bitApp.integrations.setReviewIntegration
      >[1]
    ) =>
      services.bitApp.integrations.setReviewIntegration(activeCompany, {
        ...payload,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries([
          'bitapp-company-integrations',
          'review',
          activeCompany,
        ]);
        client.invalidateQueries([
          'bitapp-company-integrations',
          activeCompany,
        ]);
      },
    }
  );
};

function companyIntegrationSelector(
  data: CompanyIntegrationAssociation[],
  name: IntegrationName,
  options?: { includeDisconnected?: boolean }
) {
  return (
    options?.includeDisconnected
      ? data
      : data.filter((integration) => integration.status === 'CONNECTED')
  ).find((integration) => integration.integration.name === name);
}

type ReviewIntegrationPayload = {
  publicKey: string;
  privateKey: string;
  storeHash?: string;
};
