import { useParams } from 'react-router';
import GeneralLayout from 'layouts/GeneralLayout';

import ExpiredIcon from '@/assets/icons/ExpiredIcon';
import PartyIcon from '@/assets/icons/PartyIcon';
import { BBBSpinner } from '@/components';
import AuthCard from '@/components/Auth/Card';
import useVerifyEmailInfo from '@/hooks/auth/useVerifyEmailByInfo';

function EmailVerificationRedirectionPage() {
  const { id } = useParams<{ id: string }>();
  const { isInitialLoading, error } = useVerifyEmailInfo(id);

  return (
    <GeneralLayout>
      {isInitialLoading ? (
        <BBBSpinner />
      ) : error && error?.response?.status === 400 ? (
        <AuthCard withoutLogo>
          <div className="text-[40px] text-center font-medium text-primary-main mb-10">
            Email verification was expired
          </div>
          <div className="flex justify-center">
            <ExpiredIcon className="mb-8" />
          </div>
          <p className="text-base text-primary-main text-center mb-10">
            Please request for a new verification email
          </p>
        </AuthCard>
      ) : (
        <>
          <AuthCard withoutLogo>
            <div className="overflow-y-auto scrollbar-hide flex flex-col items-center">
              <div className="text-[40px] font-medium text-center text-primary-main mb-10">
                Email has been verified
              </div>
              <div className="flex justify-center">
                <PartyIcon className="mb-10" />
              </div>
              <p className="text-base text-primary-main text-center mb-10">
                Your email has been verified. You can now login to your account
              </p>
            </div>
          </AuthCard>
        </>
      )}
    </GeneralLayout>
  );
}

export default EmailVerificationRedirectionPage;
