import { ShoppingCart } from 'react-feather';

import { MessageNewAssociation } from '@/types/whatsApp/v3';

type ChatBubbleLocationProps = {
  chat: MessageNewAssociation;
  isQuotedLevel?: boolean;
};

function ChatBubbleOrder({ chat }: ChatBubbleLocationProps) {
  return (
    <>
      <div className="flex gap-4">
        <img
          className="w-24 h-24 rounded-lg"
          src={`data:image/jpeg;base64, ${chat.orderMsg?.orderThumbnail}`}
        />
        <div>
          <div className="flex gap-2 mb-1 font-bold text-gray-500">
            <ShoppingCart /> {chat.orderMsg?.orderItemsCount} items
          </div>
          <div className="text-sm text-gray-500">
            {chat.orderMsg?.orderCurrency}{' '}
            {chat.orderMsg!.orderTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{' '}
            (estimated total)
          </div>
        </div>
      </div>
      <div className="text-neutral-40 italic text-sm mt-3">
        View cart not available, please check your phone
      </div>
    </>
  );
}

export default ChatBubbleOrder;
