import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Clock } from 'react-feather';
import { Handle, NodeProps, Position } from 'reactflow';
import CopyIcon from 'assets/icons/CopyIcon';
import ThunderIcon2 from 'assets/icons/ThunderIcon2';
import { integrationsMeta } from 'constants/integrations';
import { mapSourceToKnownIntegrations } from 'constants/whatsApp';
import TrashWithTransition from 'pages/BitChat/Chatbot/components/TrashWithTransition';
import { twMerge as cx } from 'tailwind-merge';
import { BBBCard, BBBTooltip } from 'components';
import { useDeleteState } from '../../../hooks';
import useStore from '../../../store';
import { ActionData } from '../../../types';
import { NodeCreator } from '../Edges/CustomEdge';
import { actionOptions1, actionOptions2 } from '../Modals/ActionModal';
import NeedAction from './NeedAction';

import { useConnectChannelConfirmation } from '@/hooks/bitCRM/automation/automation-new';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';

export default function ActionNode({ data, id }: NodeProps<ActionData | null>) {
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );
  const isHeld = useStore((s) => s.connectingNode === id);
  const nodeErrors = useStore((s) => s.errorNode[id] || []);

  const isNodeError = !!nodeErrors.length;

  const connect = useConnectIntegration();

  const connectConfirm = useConnectChannelConfirmation();

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);
  const isDragging = useStore((state) => state.draggedNode === id);
  const isDragGlobal = useStore((state) => !!state.draggedNode);

  const setDraggedNode = useStore((state) => state.setDraggedNode);
  const onDrag = useStore((state) => state.onDrag);
  const onDragFinish = useStore((state) => state.onDragFinish);
  const setPanOnDrag = useStore((state) => state.setPanOnDrag);

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  const meta = data?.source
    ? integrationsMeta[mapSourceToKnownIntegrations[data.source]]
    : undefined;

  const Icon = meta?.icon;

  const [clonePosition, setClonePosition] = useState({ x: 0, y: 0 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });

  const clickTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleMouseUp = () => {
      onDragFinish();
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
      }
    };

    if (isDragging) {
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, onDragFinish]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        setClonePosition({
          x: e.clientX - initialPosition.x,
          y: e.clientY - initialPosition.y,
        });
        onDrag({ x: e.clientX, y: e.clientY });
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [initialPosition.x, initialPosition.y, isDragging, onDrag]);

  const cardProps = {
    icon: data?.source ? (
      data.source !== 'time_delay' ? (
        Icon ? (
          <Icon size={'1.5rem'} />
        ) : null
      ) : (
        <Clock className="text-secondary-main" size={'1.75rem'} />
      )
    ) : (
      <ThunderIcon2 size={'1.5rem'} />
    ),
    title: data?.source
      ? data.source !== 'time_delay'
        ? meta!.title
        : 'Time delay'
      : 'Action',
    description: data?.source
      ? data.source !== 'time_delay'
        ? data.action
          ? data.source === 'STAMPED' || data.source === 'YOTPO'
            ? actionOptions2.find((opt) => opt.value === data.action)?.label
            : actionOptions1.find((opt) => opt.value === data.action)?.label
          : 'Select an option'
        : data.delayCount && data.delayUnit
        ? `Delay ${data.delayCount} ${data.delayUnit}`
        : 'Select an option'
      : 'Select an option',
  };

  return (
    <div className="relative">
      {isDragging &&
        createPortal(
          <ActionCard
            style={{
              position: 'absolute',
              left: clonePosition.x,
              top: clonePosition.y,
            }}
            id={id}
            {...cardProps}
            className="opacity-50 pointer-events-none z-[1000] -rotate-6"
          />,
          document.body!
        )}
      <ActionCard
        className={cx(
          isNodeError && 'border-red-500',
          expandState === 'hover' &&
            'border-secondary-main outline outline-[1px] outline-secondary-border',
          expandState === 'clicked' && 'border-secondary-main',
          isHeld && 'border-secondary-main'
        )}
        onClick={() => {
          if (clickTimeout.current) {
            setPanOnDrag(true);
            clearTimeout(clickTimeout.current);
          }

          if (nodeErrors.includes('not-connected')) {
            connectConfirm('whatsapp_meta', () => {
              connect({ name: 'whatsapp_meta' });
            });
          } else {
            onChangeStateModal({ nodeId: id, type: 'action' });
          }
        }}
        onMouseEnter={() => {
          if (!isDragGlobal && expandState !== 'clicked') {
            setExpandState(id, 'hover');
          }
          setPanOnDrag(false);
        }}
        onMouseLeave={(e) => {
          if (expandState !== 'clicked') {
            setExpandState(id, null);
          }
          setPanOnDrag(true);
        }}
        onMouseDown={(e) => {
          clickTimeout.current = setTimeout(() => {
            const nodeRef = document
              .querySelector(`.react-flow__node[data-id="${id}"]`)!
              .getBoundingClientRect();

            setInitialPosition({
              x: e.clientX - nodeRef.left,
              y: e.clientY - nodeRef.top,
            });
            setClonePosition({ x: nodeRef.x, y: nodeRef.y });
            setDraggedNode(id);
          }, 100);
        }}
        id={id}
        {...cardProps}
      />
      {isNodeError && <NeedAction />}
      {!hasEdges && (
        <div className="absolute left-full top-1/2 -translate-y-1/2 flex items-center">
          <div className="h-[1px] w-24 bg-[#9E9E9E]" />
          <NodeCreator source={id} />
        </div>
      )}
    </div>
  );
}

function ActionCard({
  className,
  onClick,
  onMouseEnter,
  onMouseDown,
  onMouseLeave,
  id,
  icon,
  title,
  description,
  style,
}: {
  onClick?: () => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  id: string;
  icon: ReactNode;
  title: string;
  description: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <BBBCard
      className={cx('w-96 group transition-colors rounded-[16px]', className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      style={style}
    >
      <ActionTip id={id} />
      <div className="flex items-center gap-5">
        {icon}
        <div>
          <div className="text-xl text-neutral-60 mb-1.5">{title}</div>
          <div className="text-neutral-50">{description}</div>
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        className={'opacity-0 pointer-events-none'}
      />
      <Handle
        type="source"
        position={Position.Right}
        className={'opacity-0 pointer-events-none'}
      />
    </BBBCard>
  );
}

function ActionTip({ id }: { id: string }) {
  const deleteNode = useDeleteState();
  const expandState = useStore((state) => state.expandState?.[id] || null);
  const duplicateNode = useStore((state) => state.duplicateNode);

  return (
    <div
      className={cx(
        'absolute top-0 -translate-y-4 right-2 opacity-0 pointer-events-none transition-opacity',
        expandState === 'hover' && 'opacity-100 pointer-events-auto'
      )}
    >
      <div className="rounded-md flex items-center gap-1.5 px-2 py-1.5 bg-white border border-neutral-300">
        <BBBTooltip position="top" content="Duplicate" show>
          <CopyIcon
            size={'1rem'}
            onClick={(e) => {
              e.stopPropagation();
              duplicateNode(id);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            className="text-neutral-40 hover:text-secondary-hover"
          />
        </BBBTooltip>
        <BBBTooltip position="top" content="Delete" show>
          <TrashWithTransition
            size={'1rem'}
            className="text-neutral-40"
            onClick={(e) => {
              deleteNode(id);
              e.stopPropagation();
            }}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </BBBTooltip>
      </div>
    </div>
  );
}
