import { useRef, useState } from 'react';
import { Smile } from 'react-feather';
import Picker from '@emoji-mart/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EditorState } from 'draft-js';

import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import { insertText } from '@/utils/common/rich';

type EmojiPickerProps = {
  editorState: EditorState;
  onChangeEditorState: (val?: EditorState) => void;
};

function EmojiPicker(props: EmojiPickerProps) {
  const [showEmoji, setShowEmoji] = useState(false);

  return (
    <div className="relative">
      <Smile
        size={18}
        onClick={() => setShowEmoji(true)}
        className="cursor-pointer text-neutral-50"
      />
      {showEmoji && <_Picker onClose={() => setShowEmoji(false)} {...props} />}
    </div>
  );
}

function _Picker({
  onClose,
  editorState,
  onChangeEditorState,
}: { onClose?: () => void } & EmojiPickerProps) {
  const ref = useRef<HTMLDivElement>(null);

  const handleInsertEmoji = (data: {
    id: string;
    name: string;
    native: string;
    unified: string;
    keywords: string[];
    shortcodes: string;
  }) => {
    onChangeEditorState(insertText(editorState, data.native));
  };

  useOutsideAlerter(ref, onClose);

  const { data, isLoading: loadingEmoji } = useQuery(['emoji-mart'], () =>
    axios
      .get('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
      .then((res) => res.data)
  );

  return (
    <div
      className="absolute bottom-full right-0 z-20 bg-white rounded-lg"
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      {loadingEmoji ? (
        'fetching emoji...'
      ) : (
        <Picker data={data} onEmojiSelect={handleInsertEmoji} />
      )}
    </div>
  );
}

export default EmojiPicker;
