import { Fragment, ReactNode, useEffect } from 'react';
import { Clock } from 'react-feather';
import { Control, Controller, DefaultValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { timeUnitOptions, timeUnitOptionsObj } from 'constants/bitChat';
import { automationVariables } from 'constants/bitCRM/automation-new';
import { integrationsMeta, KnownIntegration } from 'constants/integrations';
import { mapSourceToKnownIntegrations } from 'constants/whatsApp';
import { EditorState } from 'draft-js';
import { useInfiniteTemplates } from 'hooks/bitCRM/template/template';
import { twMerge as cx } from 'tailwind-merge';
import { CampaignTemplate, TemplateBody } from 'types/bitCRM/template';
import {
  BBBButton,
  BBBModal,
  BBBRichTextEditor,
  BBBSelect,
  BBBTextInput,
  SingleOnValueChangeParam,
} from 'components';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from 'utils/common/rich';
import useStore from '../../../store';
import { ActionData } from '../../../types';

import ConditionIcon from '@/assets/icons/ConditionIcon';

type TemplateForm = Pick<CampaignTemplate, 'id' | 'templateName'> & {
  _message: TemplateBody['message'];
};

type ActionFormSource =
  | 'whatsapp'
  | 'whatsapp_meta'
  | 'yotpo'
  | 'stamped'
  | 'time_delay'
  | 'instagram'
  | null;

type ActionForm = Omit<
  ActionData,
  | 'source'
  | 'message'
  | 'templateName'
  | 'templateId'
  | 'templateMessage'
  | 'delayCount'
> & {
  source: ActionFormSource;
  message: EditorState;
  template: (CampaignTemplate | TemplateForm) | null;
  delayCount: string | null;
};

const actionDefaultValues: DefaultValues<ActionForm> = {
  source: null,
  action: null,
  message: emptyEditor,
  delayCount: '',
  delayUnit: null,
  params: null,
  template: null,
};

const shownIntegrations: {
  category: string;
  integrations: KnownIntegration[];
}[] = [
  {
    category: 'Communication channel',
    integrations: ['whatsapp_meta', 'whatsapp', 'instagram'],
  },
  {
    category: 'Product review',
    integrations: ['stamped', 'yotpo'],
  },
];

export const actionOptions1 = [
  { label: 'Send a message', value: 'send_message' },
];
export const actionOptions2 = [
  { label: 'Ask a product review', value: 'ask_review' },
];

export default function ActionModal({
  onSave,
  onClose,
  nodeId,
  sourceId,
}: {
  onSave: (val: ActionData) => void;
  onClose: () => void;
  nodeId?: string;
  sourceId?: string;
}) {
  const history = useHistory();

  const { handleSubmit, control, reset, watch, setValue } = useForm<ActionForm>(
    {
      defaultValues: actionDefaultValues,
    }
  );

  const nodeData = useStore(
    (s) =>
      s.nodes.find((node) => node.id === nodeId)?.data as ActionData | undefined
  );

  const insertConditions = useStore((s) => s.insertConditions);
  const replaceWithCondition = useStore((s) => s.replaceWithCondition);
  const hasNextNode = useStore((s) =>
    s.edges.some((edge) => edge.source === nodeId)
  );

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  useEffect(() => {
    if (nodeData) {
      reset({
        source: nodeData.source
          ? nodeData?.source === 'STAMPED'
            ? 'stamped'
            : nodeData?.source === 'WHATSAPP'
            ? 'whatsapp'
            : nodeData?.source === 'WHATSAPP_META'
            ? 'whatsapp_meta'
            : nodeData?.source === 'YOTPO'
            ? 'yotpo'
            : nodeData.source === 'INSTAGRAM'
            ? 'instagram'
            : 'time_delay'
          : null,
        action: nodeData.action,
        template:
          nodeData.templateId && nodeData.templateName
            ? {
                id: nodeData.templateId,
                templateName: nodeData.templateName,
                _message: nodeData.templateMessage || undefined,
              }
            : null,
        message: nodeData.message
          ? convertHtmlToEditorState(nodeData.message)
          : emptyEditor,
        params: nodeData.params,
        delayCount: nodeData.delayCount ? nodeData.delayCount.toString() : '',
        delayUnit: nodeData.delayUnit,
      });
    }
  }, [nodeData, reset]);

  const action = watch('action');
  const source = watch('source');

  return (
    <BBBModal
      show
      title="Action"
      footer
      submitText="Save"
      cancelText="Discard"
      onHide={() => onChangeStateModal(null)}
      handleSave={() => {
        handleSubmit(
          ({ template, source, message, delayCount, delayUnit, ...data }) => {
            onSave({
              ...data,
              templateName: template?.templateName || null,
              templateId: template?.id || null,
              templateMessage: template
                ? '_message' in template
                  ? template._message || null
                  : template.body?.message || null
                : null,
              source: source
                ? source === 'whatsapp'
                  ? 'WHATSAPP'
                  : source === 'whatsapp_meta'
                  ? 'WHATSAPP_META'
                  : source === 'time_delay'
                  ? 'time_delay'
                  : source === 'stamped'
                  ? 'STAMPED'
                  : source === 'instagram'
                  ? 'INSTAGRAM'
                  : 'YOTPO'
                : null,
              message: convertEditorStateToHtml(message) || null,
              delayCount: delayCount ? Number(delayCount) : null,
              delayUnit: delayUnit,
            });
            onClose();
          }
        )();
      }}
      bodyClassName="px-2"
    >
      {!source ? (
        <Controller
          control={control}
          name="source"
          render={({ field: { onChange } }) => (
            <>
              {shownIntegrations.map(({ integrations, category }) => {
                return (
                  <Fragment key={category}>
                    <div className="mb-2 mx-3">{category}</div>
                    {integrations.map((integration) => {
                      const meta = integrationsMeta[integration];
                      const Icon = meta.icon;

                      return (
                        <Source
                          key={integration}
                          onClick={() => {
                            onChange(integration);
                          }}
                          Icon={Icon}
                          title={meta.title}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
              <div className="mb-2 mx-3">Built-in tools</div>
              <Source
                onClick={() => {
                  onChange('time_delay');
                }}
                Icon={
                  <Clock className="text-secondary-main" size={'1.75rem'} />
                }
                title={'Time delay'}
              />
              {!hasNextNode && (
                <Source
                  onClick={() => {
                    if (nodeId) {
                      replaceWithCondition(nodeId);
                    }

                    if (sourceId) {
                      insertConditions(sourceId);
                    }

                    onClose();
                  }}
                  Icon={<ConditionIcon size={'1.75rem'} />}
                  title={'Conditions'}
                />
              )}
            </>
          )}
        />
      ) : (
        <>
          {source !== 'time_delay' ? (
            <>
              <Source
                title={integrationsMeta[source].title}
                Icon={integrationsMeta[source].icon}
                withChange
                onChange={() => {
                  reset(actionDefaultValues);
                }}
                className="mb-5"
              />
              <div className="mx-3">
                <Controller
                  control={control}
                  name="action"
                  render={({ field }) => {
                    const options =
                      source === 'stamped' || source === 'yotpo'
                        ? actionOptions2
                        : actionOptions1;

                    const value = options.find(
                      (opt) => opt.value === field.value
                    );

                    return (
                      <BBBSelect
                        options={options}
                        optionLabel="label"
                        optionValue="value"
                        value={value}
                        onValueChange={(val) => field.onChange(val?.value)}
                        label="Action"
                        placeholder="Choose action"
                        containerClassName="mb-5"
                      />
                    );
                  }}
                />
                {action === 'send_message' &&
                  (source === 'whatsapp_meta' ? (
                    <Controller
                      control={control}
                      name="template"
                      render={({ field }) => {
                        const value = field.value as
                          | (CampaignTemplate | TemplateForm)
                          | null;

                        const message = value
                          ? '_message' in value
                            ? value._message
                            : value.body?.message
                          : null;

                        const regex = /{{\d+}}/g;
                        const matches = message
                          ? message.match(regex)
                          : undefined;
                        const numberOfDynamicVariables = matches
                          ? matches.length
                          : 0;

                        return (
                          <>
                            <TemplateOptions
                              source={source}
                              value={value}
                              onChange={(val) => {
                                field.onChange(val);
                                setValue(
                                  'params',
                                  val?.params?.map((param) => param.value) || []
                                );
                              }}
                            />
                            {message && (
                              <>
                                <div className="text-sm text-neutral-500 mb-2">
                                  Message preview
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: message,
                                  }}
                                />
                                {Array.from({
                                  length: numberOfDynamicVariables,
                                }).map((_data, index) => (
                                  <DynamicVariableOptions
                                    control={control}
                                    key={`${value!.templateName}-${index}`}
                                    index={index}
                                    sourceId={sourceId}
                                    nodeId={nodeId}
                                  />
                                ))}
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name="message"
                      render={({ field }) => (
                        <BBBRichTextEditor
                          editorState={field.value}
                          onChangeEditorState={field.onChange}
                          placeholder="Input your message here"
                          label="Message"
                        />
                      )}
                    />
                  ))}
                {action === 'ask_review' && (
                  <>
                    Add communication channel action in next action and choose
                    variable with {integrationsMeta[source].title} logo
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <Source
                Icon={
                  <Clock className="text-secondary-main" size={'1.75rem'} />
                }
                title={'Time delay'}
                withChange
                onChange={() => {
                  reset(actionDefaultValues);
                }}
                className="mb-5"
              />
              <div className="flex items-center gap-2 mx-3">
                <BBBTextInput
                  isHookForm
                  control={control}
                  controlName={'delayCount'}
                  containerClassname="flex-1 mb-0"
                  placeholder="Number of time"
                />
                <Controller
                  control={control}
                  name="delayUnit"
                  render={({ field }) => {
                    const value = field.value
                      ? timeUnitOptionsObj[field.value]
                      : null;

                    return (
                      <BBBSelect
                        placeholder="Select unit"
                        value={value}
                        onValueChange={(val) => field.onChange(val!.value)}
                        options={timeUnitOptions}
                        optionLabel="label"
                        optionValue="value"
                        containerClassName="flex-1"
                      />
                    );
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </BBBModal>
  );
}

export function Source({
  onClick,
  title,
  Icon,
  withChange,
  onChange,
  className,
}: {
  onClick?: () => void;
  title: string;
  Icon:
    | ReactNode
    | (({
        size,
      }: {
        size?: string | number | undefined;
        className?: string | undefined;
      }) => JSX.Element)
    | undefined;
  withChange?: boolean;
  onChange?: () => void;
  className?: string;
}) {
  return (
    <div
      className={cx(
        'flex items-center gap-4 mb-2 last:mb-0  rounded-md p-3',
        typeof onClick !== 'undefined' &&
          'hover:bg-secondary-surface transition-colors cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      <div className="w-12 h-12 rounded-lg border border-neutral-30 flex items-center justify-center">
        {typeof Icon === 'function' ? Icon && <Icon size={'1.75rem'} /> : Icon}
      </div>
      <div className="grow">{title}</div>
      {withChange && (
        <BBBButton
          variant="secondary"
          className="pointer-events-auto"
          onClick={onChange}
        >
          Change
        </BBBButton>
      )}
    </div>
  );
}

function TemplateOptions({
  source,
  value,
  onChange,
}: {
  source: ActionFormSource;
  onChange: (val: SingleOnValueChangeParam<CampaignTemplate>) => void;
  value: CampaignTemplate | TemplateForm | null;
}) {
  const { data: _templateData, isInitialLoading: loadingTemplate } =
    useInfiniteTemplates(
      {
        type: 'AUTOMATION_WHATSAPP_CLOUD_API',
        status: 'APPROVED',
      },
      {
        enabled: source === 'whatsapp_meta',
      }
    );

  const templates = _templateData?.pages.flatMap((page) => page.content);

  const history = useHistory();

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  return (
    <BBBSelect
      label="Message template"
      placeholder="Select template"
      //@ts-ignore
      value={value}
      //@ts-ignore
      onValueChange={onChange}
      options={templates}
      optionLabel="templateName"
      optionValue="id"
      containerClassName="mb-5"
      loading={loadingTemplate}
      withCreateRedirectOption
      createRedirectLabel="Create new template"
      onClickCreateRedirect={() => {
        history.push(`/bitcrm/template/whatsapp-cloud/new?type=automation`, {
          key: 'shortcut-automation-new',
        });
        onChangeStateModal(null);
      }}
    />
  );
}

function DynamicVariableOptions({
  control,
  index,
  sourceId,
  nodeId,
}: {
  control: Control<ActionForm>;
  index: number;
  nodeId?: string;
  sourceId?: string;
}) {
  const previousSource = useStore((s) => {
    const sourceNode =
      sourceId || s.edges.find((edge) => edge.target === nodeId)?.source;

    if (!sourceNode) return;

    const prevNodeData = s.nodes.find((node) => node.id === sourceNode);

    if (!prevNodeData) return;

    if (prevNodeData.type === 'trigger') return;

    return (prevNodeData.data as ActionData | null)?.source;
  });

  const SourceIcon =
    previousSource && previousSource !== 'time_delay'
      ? integrationsMeta[mapSourceToKnownIntegrations[previousSource]].icon
      : undefined;

  const dynamicVariableOptions = [
    ...automationVariables,
    ...(previousSource === 'STAMPED' || previousSource === 'YOTPO'
      ? [
          {
            label: 'Review URL',
            value: 'review_url',
            icon: SourceIcon ? <SourceIcon /> : null,
          },
        ]
      : []),
  ];

  const automationVariableObj = Object.fromEntries(
    dynamicVariableOptions.map((automationVariable) => [
      automationVariable.value,
      automationVariable,
    ])
  );

  return (
    <Controller
      control={control}
      name={`params.${index}`}
      render={({ field }) => {
        const value = field.value ? automationVariableObj[field.value] : null;

        return (
          <BBBSelect
            containerClassName="mt-5"
            options={dynamicVariableOptions}
            optionLabel="label"
            optionValue="value"
            label={`Variable ${index + 1}`}
            placeholder="Select parameter"
            value={value}
            onValueChange={(val) => field.onChange(val!.value)}
            optionIcon="icon"
          />
        );
      }}
    />
  );
}
