import React, { useEffect } from 'react';

export default function useOutsideAlerter(
  ref:
    | React.MutableRefObject<HTMLElement | null | undefined>
    | HTMLElement
    | null,
  onClick?: (target: HTMLElement) => void,
  ignoreModal?: boolean,
  containerRef: Element | Document = document
) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      const actualRef = ref
        ? 'current' in ref
          ? ref.current
          : ref
        : undefined;
      if (actualRef) {
        if (ignoreModal) {
          if (
            document.getElementById('modal-root') &&
            (event.target as HTMLElement).closest('#modal-root') ===
              document.getElementById('modal-root')
          ) {
            return;
          }
        }
        if (!actualRef.contains(event.target as Node)) {
          onClick?.(event.target as HTMLElement);
        }
      }
    }
    if (containerRef) {
      containerRef.addEventListener('mousedown', handleClickOutside);
      return () => {
        containerRef.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [containerRef, ignoreModal, onClick, ref]);
}
