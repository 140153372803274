import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';
import OverviewBox from './Box';
import Statistics from './Statistics';

import { Link } from '@/components/Link';
import { useTotalConversations } from '@/hooks/bitChat/analytics/analytics-v3';
import useBitCRMAnalytics from '@/hooks/bitCRM/analytics/useAnalytics';
import { usePageView } from '@/hooks/bitLink/analytics';
import { useLoginSession } from '@/hooks/bitLogin/analytics';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { selectedDate } from '@/types/datePicker';
import { formulaOverviewPercentage } from '@/utils/common/analytics';

export default function Overview() {
  const isMobile = useResponsive('sm');
  const currentDate = dayjs();

  const [startDate] = useState<selectedDate>(
    currentDate.startOf('day').subtract(7, 'day').toDate()
  );
  const [endDate] = useState<selectedDate>(
    currentDate.endOf('day').subtract(1, 'day').toDate()
  );
  const thisWeekDate = currentDate.startOf('day').subtract(14, 'day').toDate();
  const thisEndWeekDate = currentDate.endOf('day').subtract(8, 'day').toDate();

  const { data: bitLoginPricing } = usePricingByApp('BITLOGIN');
  const { data: bitCRMPricing } = usePricingByApp('BITCRM');
  const { data: bitChatPricing } = usePricingByApp('BITCHAT');
  const { data: bitLinkPricing } = usePricingByApp('BITLINK');

  const isAllAppNotPaid =
    bitLoginPricing?.pricingName === 'free' &&
    bitCRMPricing?.pricingName === 'free' &&
    bitChatPricing?.pricingName === 'free' &&
    bitLinkPricing?.pricingName === 'free';

  const props = {
    startDate: dayjs(startDate).toISOString(),
    endDate: dayjs(endDate).toISOString(),
  };

  const lengthDay = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

  const { data: loginAnalyticsData, isInitialLoading: loadingLoginAnalytics } =
    useLoginSession(
      {
        startDate: props.startDate,
        endDate: props.endDate,
      },
      {
        enabled: bitLoginPricing?.pricingName !== 'free',
      }
    );
  const { data: bitCRMAnalytics, isInitialLoading: loadingBitCRMAnalytics } =
    useBitCRMAnalytics(
      {
        startDate: props.startDate,
        endDate: props.endDate,
      },
      {
        enabled: bitCRMPricing?.pricingName !== 'free',
      }
    );
  const { data: totalConversations, isLoading: loadingTotalConversations } =
    useTotalConversations(
      {
        startDate: props.startDate,
        endDate: props.endDate,
        channel: 'whatsapp_meta',
      },
      { enabled: bitChatPricing?.pricingName !== 'free' }
    );
  const {
    data: totalConversationsLastWeek,
    isLoading: loadingTotalConversationsLastWeek,
  } = useTotalConversations(
    {
      startDate: dayjs(thisWeekDate).toISOString(),
      endDate: dayjs(thisEndWeekDate).toISOString(),
      channel: 'whatsapp_meta',
    },
    { enabled: bitChatPricing?.pricingName !== 'free' }
  );
  const { data: pageViewsBitLink, isInitialLoading: loadingPageViewsBitLink } =
    usePageView(
      {
        startDate: props.startDate,
        endDate: props.endDate,
      },
      {
        enabled: bitLinkPricing?.pricingName !== 'free',
      }
    );

  const formattedRangeDate = `${dayjs(startDate).format('DD MMMM')} - ${dayjs(
    endDate
  ).format('DD MMMM')}`;
  const weekToDate = `${dayjs(thisWeekDate).format('DD MMMM')} - ${dayjs(
    thisEndWeekDate
  ).format('DD MMMM')}`;

  const diff = (current: number, previous: number) => {
    return Math.abs(current - previous);
  };

  if (isAllAppNotPaid) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 mb-6 sm:px-0 px-4">
      <div
        className={cx(
          `flex gap-1 items-center`,
          isMobile && 'flex-col items-start'
        )}
      >
        <p className={cx('text-2xl text-primary-main', isMobile && 'text-xl')}>
          Performance overview in this week
        </p>
        <p className={cx('text-[#757575] text-xl', isMobile && 'text-base')}>
          ({formattedRangeDate})
        </p>
      </div>
      <div className="w-full flex items-center gap-5">
        {loadingTotalConversations || loadingTotalConversationsLastWeek ? (
          <Skeleton width={272} height={180} containerClassName="w-[17rem]" />
        ) : bitChatPricing?.pricingName !== 'free' ? (
          <Link to="/analytics?tab=bitchat" className="w-[17rem]">
            <OverviewBox title="bitChat" date={weekToDate}>
              <Statistics
                label="Total conversation"
                percentage={formulaOverviewPercentage(
                  totalConversations,
                  diff(totalConversations || 0, totalConversationsLastWeek || 0)
                )}
                current={totalConversations}
                previous={diff(
                  totalConversations || 0,
                  totalConversationsLastWeek || 0
                )}
              />
              <Statistics
                isFixed
                label="Average per day"
                current={(totalConversations || 0) / lengthDay}
              />
            </OverviewBox>
          </Link>
        ) : null}

        {loadingBitCRMAnalytics ? (
          <Skeleton width={272} height={180} containerClassName="w-[17rem]" />
        ) : bitCRMPricing?.pricingName !== 'free' ? (
          <Link to="/analytics?tab=bitcrm" className="w-[17rem]">
            <OverviewBox title="bitCRM" date={weekToDate}>
              <Statistics
                label="Automation sent"
                percentage={formulaOverviewPercentage(
                  bitCRMAnalytics?.data.automationSent.count,
                  diff(
                    bitCRMAnalytics?.data.automationSent.count || 0,
                    bitCRMAnalytics?.data.automationSent.detail.before || 0
                  )
                )}
                current={bitCRMAnalytics?.data.automationSent.count}
                previous={diff(
                  bitCRMAnalytics?.data.automationSent.count || 0,
                  bitCRMAnalytics?.data.automationSent.detail.before || 0
                )}
              />
              <Statistics
                isFixed
                label="Campaign sent"
                current={bitCRMAnalytics?.data.campaignSent.count}
              />
            </OverviewBox>
          </Link>
        ) : null}

        {loadingPageViewsBitLink ? (
          <Skeleton width={272} height={215} containerClassName="w-[17rem]" />
        ) : bitLinkPricing?.pricingName !== 'free' ? (
          <Link to="/analytics?tab=bitlink" className="w-[17rem]">
            <OverviewBox title="bitLink" date={weekToDate}>
              <Statistics
                percentage={formulaOverviewPercentage(
                  pageViewsBitLink?.current,
                  diff(
                    pageViewsBitLink?.current || 0,
                    pageViewsBitLink?.previous || 0
                  )
                )}
                current={pageViewsBitLink?.current}
                previous={diff(
                  pageViewsBitLink?.current || 0,
                  pageViewsBitLink?.previous || 0
                )}
                label="Page views"
              />
              <Statistics
                isFixed
                label="Average per day"
                current={pageViewsBitLink?.average}
              />
            </OverviewBox>
          </Link>
        ) : null}

        {loadingLoginAnalytics ? (
          <Skeleton width={272} height={215} containerClassName="w-[17rem]" />
        ) : bitLoginPricing?.pricingName !== 'free' ? (
          <Link to="/analytics?tab=bitlogin" className="w-[17rem]">
            <OverviewBox title="bitLogin" date={weekToDate}>
              <Statistics
                label="Login sessions"
                percentage={formulaOverviewPercentage(
                  loginAnalyticsData?.detail?.after,
                  diff(
                    loginAnalyticsData?.detail?.after || 0,
                    loginAnalyticsData?.detail?.before || 0
                  )
                )}
                current={loginAnalyticsData?.detail?.after || 0}
                previous={diff(
                  loginAnalyticsData?.detail?.after || 0,
                  loginAnalyticsData?.detail?.before || 0
                )}
              />
              <Statistics
                isFixed
                label="Average per day"
                current={loginAnalyticsData?.average || 0}
              />
            </OverviewBox>
          </Link>
        ) : null}
      </div>
    </div>
  );
}
