import { createId } from '@paralleldrive/cuid2';
import { createSlice } from '@reduxjs/toolkit';

import { PricingPlan } from '@/api/services/pricing';
import {
  BBBCropImageModalTypes,
  CustomModalProp,
  IBBBBannerTypes,
} from '@/components';
import { IBBBToastProps } from '@/components/BBBToast';
import { KnownIntegration } from '@/constants/integrations';
import { AppType } from '@/types/systemLogin';
import { ToastFunctionProps } from '@/utils/common/toast';

export type ToastTypes = Omit<IBBBToastProps, 'index'>[];

export type ShopifyConnectDefaultMode =
  | 'ask-permission'
  | 'input-store'
  | 'pending-success';

export type ConfirmationModalStoreTypes = Partial<
  Omit<CustomModalProp, 'handleSave' | 'disableSave' | 'footer'> & {
    description?: string;
    onAccept?: (hide: () => void) => void;
    loadingSubmit?: boolean;
    withoutFooter?: boolean;
  }
>;

export type ConfirmationBannerStoreTypes = Partial<
  Omit<IBBBBannerTypes, 'onAccept' | 'showBanner'>
> & {
  show?: boolean;
  onAccept?: (hide: () => void) => void;
};

export type CropImageModal = Pick<
  BBBCropImageModalTypes,
  | 'handleDownload'
  | 'customRatio'
  | 'circular'
  | 'aspect'
  | 'whitelistedRatio'
  | 'uploadOptions'
> & {
  file: File;
};

export interface CommonStore {
  cropImageModal: CropImageModal | null;
  confirmationModal: ConfirmationModalStoreTypes;
  confirmationBanner: { id: string; props: ConfirmationBannerStoreTypes }[];
  shopifyConnectModalShow: {
    appType: AppType;
    defaultMode?: ShopifyConnectDefaultMode;
    onSuccess?: (data: { domain: string }) => void;
    skipSocketDisconnectOnSuccess?: boolean;
  } | null;
  sidebar: Partial<{
    expanded: boolean;
    enableMapping: boolean;
  }>;
  pricingAlert: {
    appType: AppType;
    show: boolean;
    type?: 'prompt' | 'redirect';
    desiredPlan: PricingPlan;
    onSuccess?: () => void;
  } | null;
  toasts: ToastTypes;
  connectIntegration?:
    | ((
        | {
            appType: AppType;
            name: Extract<KnownIntegration, 'shopify'>;
          }
        | {
            name: Exclude<KnownIntegration, 'shopify'>;
          }
      ) & { meta?: Record<string, any> })
    | null;
}

const initialState: CommonStore = {
  confirmationModal: {
    show: false,
    title: 'Are you sure want to do this action?',
    description: 'Any action can not be undone',
  },
  shopifyConnectModalShow: null,
  sidebar: {
    enableMapping: false,
    expanded: false,
  },
  confirmationBanner: [],
  pricingAlert: null,
  cropImageModal: null,
  toasts: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    trigger: (
      state,
      { payload }: { payload: Omit<ConfirmationModalStoreTypes, 'show'> }
    ) => {
      //@ts-expect-error assume type is true
      state.confirmationModal = { ...payload, show: true };
    },
    hideConfirmationModal: (state) => {
      state.confirmationModal.show = false;
    },
    toggleShopifyConnectModal: (
      state,
      { payload }: { payload: CommonStore['shopifyConnectModalShow'] }
    ) => {
      state.shopifyConnectModalShow = payload
        ? {
            appType: payload.appType,
            defaultMode: payload.defaultMode,
            onSuccess: payload.onSuccess,
            skipSocketDisconnectOnSuccess:
              payload.skipSocketDisconnectOnSuccess,
          }
        : null;
    },
    setSidebar: (
      state,
      { payload }: { payload: Partial<CommonStore['sidebar']> }
    ) => {
      state.sidebar = { ...state.sidebar, ...payload };
    },
    hideConfirmationBanner: (state, { payload }: { payload: string }) => {
      state.confirmationBanner = state.confirmationBanner.filter(
        (banner) => banner.id !== payload
      );
    },
    setPricingAlert: (
      state,
      {
        payload,
      }: {
        payload:
          | CommonStore['pricingAlert']
          | {
              show: false;
            };
      }
    ) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.pricingAlert = payload
        ? { ...state.pricingAlert, ...payload }
        : null;
    },
    triggerConfirmationBanner: (
      state,
      { payload }: { payload: { [k: string]: ConfirmationBannerStoreTypes } }
    ) => {
      const [[key, value]] = Object.entries(payload);

      const bannerIndex = state.confirmationBanner.findIndex(
        (banner) => banner.id === key
      );

      if (!value.show && bannerIndex === -1) return;

      if (!value.show && bannerIndex > -1) {
        state.confirmationBanner.splice(bannerIndex, 1);
        return;
      }

      if (bannerIndex > -1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.confirmationBanner = state.confirmationBanner.map((banner, i) =>
          i !== bannerIndex ? banner : { id: key, props: value }
        );
      } else {
        state.confirmationBanner.unshift({
          id: key,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          props: value,
        });
      }
    },
    setCropImageModal: (
      state,
      { payload }: { payload: CommonStore['cropImageModal'] }
    ) => {
      state.cropImageModal = payload;
    },
    addToast: (state, { payload }: { payload: ToastFunctionProps }) => {
      state.toasts.push({
        ...payload,
        id: payload.id || createId(),
      });
    },
    destroyingToast: (state, { payload }: { payload: { id: string } }) => {
      const { id } = payload;

      const prevToasts = Array.from(state.toasts);

      state.toasts = prevToasts.map((toast) => {
        if (toast.id === id) {
          return { ...toast, destroying: true };
        }

        return toast;
      });
    },
    deleteToast: (state, { payload }: { payload: { id: string } }) => {
      const { id } = payload;

      const prevToasts = Array.from(state.toasts);

      const updatedToast = prevToasts.filter((toast) => toast.id !== id);

      state.toasts = updatedToast;
    },
    setConnectIntegration: (
      state,
      { payload }: { payload: CommonStore['connectIntegration'] }
    ) => {
      return {
        ...state,
        connectIntegration: payload ? { ...payload, isLoading: false } : null,
      };
    },
    setConnectIntegrationMeta: (
      state,
      { payload }: { payload: Record<string, any> }
    ) => {
      return {
        ...state,
        connectIntegration: state.connectIntegration
          ? {
              ...state.connectIntegration,
              meta: { ...state.connectIntegration.meta, ...payload },
            }
          : null,
      };
    },
  },
});

export const {
  trigger,
  hideConfirmationModal,
  setSidebar,
  toggleShopifyConnectModal,
  hideConfirmationBanner,
  triggerConfirmationBanner,
  setPricingAlert,
  setCropImageModal,
  addToast,
  deleteToast,
  destroyingToast,
  setConnectIntegration,
  setConnectIntegrationMeta,
} = commonSlice.actions;

export default commonSlice.reducer;
