import React, { useMemo } from 'react';
import { CheckCircle, Info } from 'react-feather';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import MinusIcon from '@/assets/icons/MinusIcon';
import { BBBButton, BBBTooltip } from '@/components';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { appNameFromType } from '@/constants/app';
import {
  dataStaticPlanComparionBitAI,
  dataStaticPlanComparionBitChat2,
  dataStaticPlanComparisonBitApp,
  dataStaticPlanComparisonBitCRM,
  dataStaticPlanComparisonBitLink,
  dataStaticPlanComparisonBitLogin,
} from '@/constants/pricing';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useSticky from '@/hooks/common/useSticky';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import useIsPricingActive from '@/hooks/pricing/useIsPricingActive';
import usePricingOptions from '@/hooks/pricing/usePricingOptionsByApp';
import useRedirectUpgradePlan from '@/hooks/pricing/useRedirectUpgradePlan';
import {
  mapPlanTypeToHumanReadable,
  usePlanTypeQueryParams,
} from '@/hooks/pricing/v2/pricing';
import {
  IBBBPricingFeaturesComparisonData,
  IBBBTableComparisonData,
} from '@/types/pricing';
import { AppType } from '@/types/systemLogin';

type Props = {
  active: AppType;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export default function PlanComparison(props: Props) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return <_PlanComparison {...props} />;
}

function _PlanComparison(
  props: Props & {
    active: AppType;
  }
) {
  const { isMaximumLevel, pricingName, isLoading } = useIsMaximumLevel(
    props.active
  );

  if (isLoading || (isMaximumLevel && pricingName === 'enterprise'))
    return null;

  return <__PlanComparison {...props} />;
}

function __PlanComparison({
  active,
  containerRef,
}: Props & {
  active: AppType;
}) {
  const memoizedTableData = useMemo(
    () =>
      active === 'BITLINK'
        ? dataStaticPlanComparisonBitLink()
        : active === 'BITAPP'
        ? dataStaticPlanComparisonBitApp()
        : active === 'BITCRM'
        ? dataStaticPlanComparisonBitCRM()
        : active === 'BITCHAT'
        ? dataStaticPlanComparionBitChat2()
        : active === 'BITLOGIN'
        ? dataStaticPlanComparisonBitLogin()
        : active === 'BITAI'
        ? dataStaticPlanComparionBitAI()
        : null,
    [active]
  );
  const { isPlanActive, index } = useIsPricingActive(active);

  const sticky = useSticky(`#pricing-feature-row`, containerRef?.current);

  const planType = usePlanTypeQueryParams();

  const { fn: redirectUpgradePlan, loading } = useRedirectUpgradePlan({
    appType: active,
    type: planType,
  });

  const { data: pricingOptions } = usePricingOptions(active, planType);

  const { data: isRestricted } = useIsModuleRestricted(
    'PRICING_UPGRADE_DOWNGRADE'
  );

  const filteredHiddenPlan = memoizedTableData?.plan;

  return (
    <>
      <div className="text-3xl font-medium text-center mb-5">
        Detail Plan Comparison
      </div>
      <table className="w-full border-separate border-spacing-0 mb-72">
        <tbody className="relative">
          <tr className="sticky top-0">
            <th></th>
            {filteredHiddenPlan?.map((item) => (
              <th
                className="bg-white py-4 text-center text-xl font-medium px-4"
                scope="col"
                key={`plan-${item.id}`}
              >
                {item.title}
              </th>
            ))}
          </tr>
          {sticky !== undefined && (
            <tr className="sticky top-0 bg-white border-b">
              <motion.th
                className="py-5 px-4 text-left text-xl font-medium"
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{
                  duration: 1,
                }}
                key={sticky}
              >
                {memoizedTableData?.features[sticky].title}
              </motion.th>
              {filteredHiddenPlan?.map((item) => (
                <th
                  className="py-4 text-center text-xl font-medium px-4"
                  scope="col"
                  key={`plan-${item.id}`}
                >
                  {item.title}
                </th>
              ))}
            </tr>
          )}

          {memoizedTableData?.features.map((item, index) => (
            <PlanFeature
              comparisonData={memoizedTableData}
              featuresComparisonData={item}
              key={index}
              index={index}
            />
          ))}

          <tr className="bg-white">
            <td className="border-l rounded-bl-lg border-b" />
            {pricingOptions &&
              filteredHiddenPlan?.map((item, idx) => {
                const pricingAppData = pricingOptions.find(
                  (opt) => opt.name === item.name
                )!;

                return (
                  <td
                    className={cx(
                      'border-b py-5 px-3',
                      idx === filteredHiddenPlan.length - 1 &&
                        'border-r rounded-br-lg'
                    )}
                    key={item.id}
                  >
                    <div
                      className={cx(
                        'mb-2.5 whitespace-nowrap',
                        item.name === 'enterprise' &&
                          'opacity-0 pointer-events-none'
                      )}
                    >
                      <span className="text-[40px] font-semibold text-secondary-main">
                        ${pricingAppData.price}
                      </span>{' '}
                      <span className="text-[#9E9E9E] text-xl">
                        /{mapPlanTypeToHumanReadable[planType]}
                      </span>
                    </div>
                    {!isRestricted && !isPlanActive(item.name) ? (
                      item.name === 'enterprise' ? (
                        <EnterpriseContactUs app={active} />
                      ) : (
                        <ShopifyAwareWrapper
                          appType={active}
                          fallbackToChildren
                        >
                          {({ connectMiddleware }) => (
                            <BBBButton
                              onClick={() => {
                                connectMiddleware((data) =>
                                  redirectUpgradePlan(item.name, data.domain)
                                );
                              }}
                              loadingState={loading}
                              text={`Choose ${pricingAppData.label}`}
                              variant={idx > index ? 'primary' : 'secondary'}
                              width="full"
                            />
                          )}
                        </ShopifyAwareWrapper>
                      )
                    ) : (
                      <BBBButton className="opacity-0 pointer-events-none" />
                    )}
                  </td>
                );
              })}
          </tr>
        </tbody>
      </table>
    </>
  );
}

type PlanFeatureProps = {
  comparisonData: IBBBTableComparisonData;
  featuresComparisonData: IBBBPricingFeaturesComparisonData;
  containerRef?: React.RefObject<HTMLDivElement>;
  onChangeSticky?: () => void;
  index: number;
};

function PlanFeature({
  comparisonData,
  featuresComparisonData,
  index,
}: PlanFeatureProps) {
  const filteredComparison = comparisonData.plan;

  return (
    <>
      <tr className="bg-white" id="pricing-feature-row">
        <th
          className={cx(
            'py-5 px-4 text-left text-xl font-medium border-l',
            index === 0 && 'border-t rounded-tl-xl'
          )}
        >
          {featuresComparisonData.title}
        </th>
        {filteredComparison.map((item, idx) => (
          <th
            className={cx(
              'py-4 text-center text-xl font-medium px-4 bg-white',
              index === 0 && 'border-t',
              idx === filteredComparison.length - 1 && 'border-r'
            )}
            scope="col"
            key={item.id}
          ></th>
        ))}
      </tr>
      {featuresComparisonData.data.map((data, index) => (
        <tr
          key={data.id}
          className={cx(index % 2 === 0 ? 'bg-neutral-20' : 'bg-white')}
        >
          <td className={cx('p-4 border-l')}>
            <div className="flex items-center gap-1">
              {data.title}{' '}
              {data.isSoon && (
                <span className="ml-4 bg-secondary-surface text-secondary-main rounded px-2 py-1 font-light">
                  Soon
                </span>
              )}
              {data.description && (
                <BBBTooltip show content={data.description}>
                  <Info size={12} />
                </BBBTooltip>
              )}
            </div>
          </td>
          {'unifiedDescription' in data ? (
            <td colSpan={5} className="p-4 border-r">
              {data.unifiedDescription}
            </td>
          ) : (
            filteredComparison.map((param, idx) => {
              const name = param.name;
              const desc = data[name];

              return (
                <td
                  className={cx(
                    'p-4',
                    idx === filteredComparison.length - 1 && 'border-r'
                  )}
                  key={name}
                >
                  <div className="flex justify-center text-center">
                    {typeof desc === 'boolean' ? (
                      desc ? (
                        <CheckCircle />
                      ) : (
                        <MinusIcon />
                      )
                    ) : (
                      <>
                        {typeof desc === 'string' ||
                        typeof desc === 'number' ? (
                          desc
                        ) : (
                          <>
                            {desc?.label}{' '}
                            <BBBTooltip show content={desc?.description}>
                              <Info size={12} className="ml-1" />
                            </BBBTooltip>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </td>
              );
            })
          )}
        </tr>
      ))}
    </>
  );
}

function EnterpriseContactUs({ app }: { app: AppType }) {
  return (
    <a
      target="__blank"
      href={`https://api.whatsapp.com/send/?phone=6285693142306&text=Hello+bitbybit+Team%21%0D%0A%0D%0AI+want+to+upgrade+my+${appNameFromType[app]}+plan+to+Enterprise%21+Are+you+open+for+a+discussion%3F+&type=phone_number&app_absent=0`}
    >
      <BBBButton text="Contact us" width="full"></BBBButton>
    </a>
  );
}
