import { useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import ShopifyArticleListsOptions from './ArticleLists';
import ShopifyBlogListsOptions from './BlogLists';
import ShopifyCollectionListsOptions from './CollectionLists';
import ShopifyProductListsOptions from './ProductLists';

import { BBBAlert, BBBSelect, BBBTextInput } from '@/components';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import redirectNotificationOptions, {
  IRedirectNotificationOptions,
  IRedirectNotificationOptionsParam,
} from '@/constants/bitApp/redirectNotificationOptions';
import { Nullable } from '@/types/utils/nullable';

type Props = {
  label?: string | React.ReactNode;
  withRequiredLabel?: boolean;
  value: IRedirectNotificationOptions | null;
  onChange: (val?: IRedirectNotificationOptions | null) => void;
  errorValue?: string;
  errorValueParam?: string[];
  destinationPagePlaceholder?: string;
  containerClassName?: string;
  withoutDynamicVariables?: boolean;
};

function PageRedirectionFields({
  withRequiredLabel,
  label = (
    <p>
      Destination Page
      {withRequiredLabel && <span className="text-danger-main">*</span>}
    </p>
  ),
  value,
  onChange,
  errorValue,
  errorValueParam,
  withoutDynamicVariables,
  containerClassName = '',
  destinationPagePlaceholder = 'Choose destination page',
}: Props) {
  const [selectedPage, setSelectedPage] =
    useState<IRedirectNotificationOptions | null>(value);
  const [isOtherExpanded, setIsOtherExpanded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const trackedRedirectOptions = useMemo(() => {
    let clonedOptions = Array.from(redirectNotificationOptions);
    if (withoutDynamicVariables) {
      clonedOptions = clonedOptions.filter(
        (opt) => !opt.params?.some((param) => param.type === 'dynamic-variable')
      );
    }
    const indexOfOthers = clonedOptions.findIndex((o) => o.value === 'others');
    if (isOtherExpanded) {
      clonedOptions = clonedOptions.filter((opt) => opt.value !== 'others');
    } else {
      clonedOptions = clonedOptions.slice(0, indexOfOthers + 1);
    }
    return clonedOptions.map((opt) => ({
      ...opt,
      label: `${opt.label} ${opt.label === 'None' ? '' : 'page'}`,
    }));
  }, [isOtherExpanded, withoutDynamicVariables]);

  useEffect(() => {
    setSelectedPage(value);
  }, [value]);

  const handleChangeBasePath = (
    opt: Nullable<IRedirectNotificationOptions>
  ) => {
    if (opt?.value === 'others') {
      setIsOtherExpanded(true);
    } else {
      const resettedSelectedPage = opt
        ? ({
            ...opt,
            params:
              (opt.params?.map((param) => ({
                ...param,
                data: param.data || '',
              })) as IRedirectNotificationOptionsParam[]) || null,
          } as IRedirectNotificationOptions)
        : null;
      setSelectedPage(resettedSelectedPage);
      onChange(resettedSelectedPage);
    }
  };

  const handleChangeParamsValue = (
    value: string | null | undefined,
    index: number
  ) => {
    const modified = produce(selectedPage, (draft) => {
      if (draft?.params) {
        draft.params[index].data = value || '';
      }
    });
    setSelectedPage(modified);
    onChange(modified as IRedirectNotificationOptions);
  };

  return (
    <div className={`mt-2 ${containerClassName}`}>
      <div>
        <div className="text-primary-main">{label}</div>
        <BBBSelect
          options={trackedRedirectOptions}
          placeholder={destinationPagePlaceholder}
          value={selectedPage}
          onValueChange={handleChangeBasePath}
          optionLabel="label"
          optionValue="value"
          isMenuOpen={isMenuOpen}
          onChangeMenuOpen={setIsMenuOpen}
          closeMenuOnSelect={(opt) => opt?.value !== 'others'}
          error={errorValue}
          isClearable
        />
      </div>
      {selectedPage?.params
        ?.filter((param) => param.type !== 'dynamic-variable')
        .map((param, paramIndex: number) => (
          <div className="mt-3" key={paramIndex}>
            {param.name === 'collection' && (
              <ShopifyAwareWrapper
                appType="BITAPP"
                size="full"
                customThumbnail={(connectButton) => (
                  <div className="flex flex-col gap-6">
                    <BBBAlert type="info">
                      <p className="text-center text-primary-main leading-5">
                        Connect to Shopify to start adding your collections!
                      </p>
                    </BBBAlert>
                    {connectButton}
                  </div>
                )}
              >
                {() => (
                  <ShopifyCollectionListsOptions
                    label={
                      <div>
                        {param.label}
                        {withRequiredLabel && (
                          <span className="text-danger-main">*</span>
                        )}
                      </div>
                    }
                    value={selectedPage?.params?.[paramIndex].data}
                    onChange={(val) => {
                      handleChangeParamsValue(val, paramIndex);
                    }}
                    isSearchable
                  />
                )}
              </ShopifyAwareWrapper>
            )}
            {param.name === 'product' && (
              <ShopifyAwareWrapper
                appType="BITAPP"
                size="full"
                customThumbnail={(connectButton) => (
                  <div className="flex flex-col gap-6">
                    <BBBAlert type="info">
                      <p className="text-center text-primary-main leading-5">
                        Connect to Shopify to start adding your products!
                      </p>
                    </BBBAlert>
                    {connectButton}
                  </div>
                )}
              >
                {() => (
                  <ShopifyProductListsOptions
                    label={
                      <div>
                        {param.label}
                        {withRequiredLabel && (
                          <span className="text-danger-main">*</span>
                        )}
                      </div>
                    }
                    value={selectedPage?.params?.[paramIndex].data}
                    onChange={(val) => {
                      handleChangeParamsValue(val, paramIndex);
                    }}
                  />
                )}
              </ShopifyAwareWrapper>
            )}
            {param.name === 'blog' && (
              <ShopifyAwareWrapper
                appType="BITAPP"
                size="full"
                customThumbnail={(connectButton) => (
                  <div className="flex flex-col gap-6">
                    <BBBAlert type="info">
                      <p className="text-center text-primary-main leading-5">
                        Connect to Shopify to start adding your blogs!
                      </p>
                    </BBBAlert>
                    {connectButton}
                  </div>
                )}
              >
                {() => (
                  <ShopifyBlogListsOptions
                    label={
                      <div>
                        {param.label}
                        {withRequiredLabel && (
                          <span className="text-danger-main">*</span>
                        )}
                      </div>
                    }
                    value={selectedPage?.params?.[paramIndex].data}
                    onChange={(val) => {
                      handleChangeParamsValue(val, paramIndex);
                    }}
                  />
                )}
              </ShopifyAwareWrapper>
            )}
            {param.name === 'article' && (
              <ShopifyAwareWrapper
                appType="BITAPP"
                size="full"
                customThumbnail={(connectButton) => (
                  <div className="flex flex-col gap-6">
                    <BBBAlert type="info">
                      <p className="text-center text-primary-main leading-5">
                        Connect to Shopify to start adding your articles!
                      </p>
                    </BBBAlert>
                    {connectButton}
                  </div>
                )}
              >
                {() => (
                  <ShopifyArticleListsOptions
                    label={
                      <div>
                        {param.label}
                        {withRequiredLabel && (
                          <span className="text-danger-main">*</span>
                        )}
                      </div>
                    }
                    value={selectedPage?.params?.[paramIndex].data}
                    onChange={(val) => {
                      handleChangeParamsValue(val, paramIndex);
                    }}
                  />
                )}
              </ShopifyAwareWrapper>
            )}
            {param.type === 'url' && (
              <>
                <BBBTextInput
                  label={
                    <div>
                      {param.label}
                      {withRequiredLabel && (
                        <span className="text-danger-main">*</span>
                      )}
                    </div>
                  }
                  value={
                    param.data ||
                    (selectedPage?.params?.[paramIndex].data as string) ||
                    ''
                  }
                  onChange={({ target: { value } }) => {
                    handleChangeParamsValue(value, paramIndex);
                  }}
                  isUrl
                  placeholder={param.label}
                  containerClassname="!mb-0"
                />
              </>
            )}
            {errorValueParam?.[paramIndex] && (
              <div className="text-danger-main">
                {errorValueParam?.[paramIndex]}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default PageRedirectionFields;
