import { useRef, useState } from 'react';
import { Bell, Plus } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import BBBChannelIcon from '@/assets/icons/BBBChannelIcon';
import WhatsAppCloudApiIcon from '@/assets/icons/WhatsAppCloudApiIcon';
import WhatsAppIcon4 from '@/assets/icons/WhatsAppIcon4';
import { BBBBottomSheet, BBBButton, BBBModal } from '@/components';
import BBBLimitAlert from '@/components/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/BBBLimitAlert/UpgradeText';
import { Link } from '@/components/Link';
import StickyFooter from '@/components/StickyFooter/StickyFooter';
import colors from '@/constants/common/colors';
import { useRecentPublish } from '@/hooks/bitApp/publish';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { useConnectChannelConfirmation } from '@/hooks/whatsApp/channels';
import { templateTypes } from '@/pages/BitCRM/Template';

type Props = {
  containerClassName?: string;
  type: 'campaign' | 'template';
};

export default function CampaignCreateButton({ ...props }: Props) {
  if (props.type === 'campaign') {
    return <_CampaignCreateButton {...props} type="campaign" />;
  }

  if (props.type === 'template') {
    return <_CampaignCreateButton {...props} type="template" />;
  }

  return <_CampaignCreateButton {...props} />;
}

function _CampaignCreateButton({ type, containerClassName }: Props) {
  const isMobile = useResponsive('sm');
  const [showChooseChannel, setShowChooseChannel] = useState<
    'automation' | 'campaign' | 'default' | undefined
  >();

  const [showOptions, setShowOptions] = useState(false);

  const optionRef = useRef<HTMLDivElement>(null);
  const createBtnRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(optionRef, (e) => {
    if (
      createBtnRef.current &&
      e.closest(`#${type}-create-btn`) !== createBtnRef.current
    ) {
      setShowOptions(false);
    }
  });

  return (
    <>
      {showChooseChannel && (
        <ChooseChannelModal
          setShowChooseChannel={setShowChooseChannel}
          showChooseChannel={showChooseChannel}
          type={type}
        />
      )}
      {isMobile ? (
        <CreateButtonMobile
          showOptions={type === 'template' && showOptions}
          setShowOptions={setShowOptions}
          setShowChooseChannel={setShowChooseChannel}
          type={type}
        />
      ) : (
        <div
          className={cx(`relative`, containerClassName)}
          id={`${type}-create-btn`}
          ref={createBtnRef}
        >
          <CreateButton
            type={type}
            setShowOptions={setShowOptions}
            setShowChooseChannel={setShowChooseChannel}
          />
          {type === 'template' && showOptions && (
            <CreateTemplateOptions
              optionRef={optionRef}
              setShowChooseChannel={setShowChooseChannel}
            />
          )}
        </div>
      )}
    </>
  );
}

function CreateTemplateOptions({
  optionRef,
  setShowChooseChannel,
}: {
  optionRef: React.RefObject<HTMLDivElement>;
  setShowChooseChannel: (
    show: 'automation' | 'campaign' | 'default' | undefined
  ) => void;
}) {
  return (
    <>
      <div className="absolute right-0 top-[110%] z-10 w-full" ref={optionRef}>
        <div className="rounded-lg bg-white overflow-hidden shadow-sm border border-neutral-30">
          {templateTypes.map((option) => (
            <div
              className="px-4.5 py-4 hover:bg-secondary-surface cursor-pointer"
              key={option}
              onClick={() =>
                setShowChooseChannel(
                  option === 'Automation' ? 'automation' : 'campaign'
                )
              }
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function ChooseChannelModal({
  type,
  showChooseChannel,
  setShowChooseChannel,
}: Pick<Props, 'type'> & {
  setShowChooseChannel: (
    show: 'automation' | 'campaign' | 'default' | undefined
  ) => void;
  showChooseChannel: 'automation' | 'campaign' | 'default' | undefined;
}) {
  const { data: whatsAppBusinessIntegrationData } =
    useWhatsappBusinessIntegration();
  const { data: whatsAppCloudApiIntegrationData } =
    useWhatsappCloudIntegration();

  const isWhatsAppBusinessConnected =
    whatsAppBusinessIntegrationData?.status === 'CONNECTED';

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  const confirm = useConnectChannelConfirmation();

  const { data: companyPricing } = usePricingByApp('BITCRM');

  const { data: publishData } = useRecentPublish();

  const isEitherLive = !!publishData;

  const isFreePlan = companyPricing?.pricingName === 'free';
  const pricingDisabled = type === 'campaign' && isFreePlan;

  const disabledWaBusiness =
    type === 'campaign' && isWhatsAppBusinessConnected && pricingDisabled;
  const disabledCloudApi = isWhatsAppCloudConnected && pricingDisabled;

  const channelOptions = [
    {
      label: 'WhatsApp Business App',
      icon: (
        <WhatsAppIcon4
          color={disabledWaBusiness ? colors.neutral['30'] : undefined}
        />
      ),
      slug: 'whatsapp-business',
      disabled: disabledWaBusiness,
    },
    {
      label: 'WhatsApp Cloud API',
      icon: (
        <WhatsAppCloudApiIcon
          color={disabledCloudApi ? colors.neutral['30'] : undefined}
        />
      ),
      slug: 'whatsapp-cloud',
      disabled: disabledCloudApi,
    },
    ...(type === 'campaign'
      ? [
          {
            label: 'Demo',
            icon: <BBBChannelIcon />,
            slug: 'whatsapp-sandbox',
          },
        ]
      : []),
    ...(type === 'campaign' && isEitherLive
      ? [
          {
            label: 'Notification',
            slug: 'notification',
            icon: <Bell size={24} />,
          },
        ]
      : []),
  ];

  return (
    <BBBModal
      modalId={`choose-channel-${type}-modal`}
      title="Choose channel"
      subtitle={`Select the channel that you want for your ${type}`}
      show
      onHide={() => setShowChooseChannel(undefined)}
    >
      <div className="relative flex flex-wrap items-center justify-center gap-5">
        {channelOptions.map((channel) => {
          if (
            (channel.slug === 'whatsapp-business' &&
              type === 'campaign' &&
              !isWhatsAppBusinessConnected) ||
            (channel.slug === 'whatsapp-cloud' && !isWhatsAppCloudConnected)
          ) {
            return (
              <div
                className={cx(
                  'h-36 hover:border-secondary-main cursor-pointer transition-[border] w-36 text-center rounded-2xl px-4 flex items-center flex-col justify-center gap-3 py-5 border-[1.5px] border-neutral-30',
                  channel.disabled && 'pointer-events-none text-neutral-30'
                )}
                key={channel.label}
                onClick={() => {
                  confirm(
                    channel.slug === 'whatsapp-business'
                      ? 'whatsapp'
                      : 'whatsapp_meta',
                    type === 'campaign' ? 'campaign' : 'template'
                  );
                }}
              >
                {channel.icon}
                {channel.label}
              </div>
            );
          }

          return (
            <Link
              className={cx(
                'h-36 hover:border-secondary-main cursor-pointer transition-[border] w-36 text-center rounded-2xl px-4 flex items-center flex-col justify-center gap-3 py-5 border-[1.5px] border-neutral-30',
                channel.disabled && 'pointer-events-none text-neutral-30'
              )}
              key={channel.label}
              to={{
                pathname: `/bitcrm/${type}/${channel.slug}/new`,
                search:
                  channel.slug !== 'notification' &&
                  showChooseChannel !== 'default'
                    ? `type=${showChooseChannel}`
                    : undefined,
              }}
            >
              {channel.icon}
              {channel.label}
            </Link>
          );
        })}
      </div>
      {type === 'campaign' && isFreePlan && (
        <BBBLimitAlert
          appType="BITCRM"
          className="rounded-lg mt-4"
          customLabel={
            <>
              <UpgradeText appType="BITCRM" /> to Standard plan to use your own
              number!
            </>
          }
          withoutTip
          forceCloseable
        />
      )}
    </BBBModal>
  );
}

function CreateButton({
  type,
  setShowOptions,
  setShowChooseChannel,
}: Pick<Props, 'type'> & {
  setShowOptions: (show: boolean) => void;
  setShowChooseChannel: (
    show: 'automation' | 'campaign' | 'default' | undefined
  ) => void;
}) {
  return (
    <BBBButton
      id={`create-new-${type}`}
      onClick={() =>
        type === 'template'
          ? setShowOptions(true)
          : setShowChooseChannel('default')
      }
      text={
        <div className="flex justify-center items-center gap-2">
          <div> Create new {type}</div>
          <Plus />
        </div>
      }
      className={cx(`w-full`)}
    />
  );
}

function CreateButtonMobile({
  setShowOptions,
  setShowChooseChannel,
  type,
  showOptions,
}: Pick<Props, 'type'> & {
  showOptions: boolean;
  setShowOptions: (show: boolean) => void;
  setShowChooseChannel: (
    show: 'campaign' | 'automation' | 'default' | undefined
  ) => void;
}) {
  return (
    <>
      {showOptions && (
        <BBBBottomSheet
          show
          onClose={() => setShowOptions(false)}
          title={`Create new ${type}`}
        >
          {templateTypes.map((option) => (
            <div
              className="px-4.5 py-4 hover:bg-secondary-surface cursor-pointer"
              key={option}
            >
              {option}
            </div>
          ))}
        </BBBBottomSheet>
      )}
      <StickyFooter>
        <div className="p-3 bg-white">
          <CreateButton
            type={type}
            setShowOptions={setShowOptions}
            setShowChooseChannel={setShowChooseChannel}
          />
        </div>
      </StickyFooter>
    </>
  );
}
