import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function BitAiIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' ? (
        <BitAiIconDefault />
      ) : mode === 'hover' ? (
        <BitAiIconHover />
      ) : (
        <BitAiIconActive />
      )}
    </>
  );
}

function BitAiIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M9.2 1a1 1 0 10-2 0v2.4H7A3.6 3.6 0 003.4 7v.2H1a1 1 0 000 2h2.4v7.6H2.2a1 1 0 00-.707.293l-1.2 1.2a1 1 0 101.414 1.414l.907-.907H3.4v.2A3.6 3.6 0 007 22.6h1.4V25a1 1 0 102 0v-2.4h6.4V25a1 1 0 102 0v-2.4h.2a3.6 3.6 0 003.6-3.6v-.2H25a1 1 0 100-2h-2.4V9.2H25a1 1 0 100-2h-2.4V7A3.6 3.6 0 0019 3.4h-.2v-.786l.907-.907A1 1 0 0018.293.293l-1.2 1.2a1 1 0 00-.293.707v1.2H9.2V1zM5.8 7A1.2 1.2 0 017 5.8h12A1.2 1.2 0 0120.2 7v12a1.2 1.2 0 01-1.2 1.2H7A1.2 1.2 0 015.8 19V7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function BitAiIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#404040"
        fillRule="evenodd"
        d="M8.2 0a1 1 0 011 1v2.4h7.6V2.2a1 1 0 01.293-.707l1.2-1.2a1 1 0 111.414 1.414l-.907.907V3.4h.2A3.6 3.6 0 0122.6 7v.2H25a1 1 0 110 2h-2.4v7.6H25a1 1 0 110 2h-2.4v.2a3.6 3.6 0 01-3.6 3.6h-.2V25a1 1 0 11-2 0v-2.4h-6.4V25a1 1 0 11-2 0v-2.4H7A3.6 3.6 0 013.4 19v-.2h-.786l-.907.907a1 1 0 01-1.414-1.414l1.2-1.2A1 1 0 012.2 16.8h1.2V9.2H1a1 1 0 110-2h2.4V7A3.6 3.6 0 017 3.4h.2V1a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function BitAiIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#404040"
        fillRule="evenodd"
        d="M9.2 1a1 1 0 10-2 0v2.4H7A3.6 3.6 0 003.4 7v.2H1a1 1 0 000 2h2.4v7.6H2.2a1 1 0 00-.707.293l-1.2 1.2a1 1 0 101.414 1.414l.907-.907H3.4v.2A3.6 3.6 0 007 22.6h1.4V25a1 1 0 102 0v-2.4h6.4V25a1 1 0 102 0v-2.4h.2a3.6 3.6 0 003.6-3.6v-.2H25a1 1 0 100-2h-2.4V9.2H25a1 1 0 100-2h-2.4V7A3.6 3.6 0 0019 3.4h-.2v-.786l.907-.907A1 1 0 0018.293.293l-1.2 1.2a1 1 0 00-.293.707v1.2H9.2V1zM5.8 7A1.2 1.2 0 017 5.8h12A1.2 1.2 0 0120.2 7v12a1.2 1.2 0 01-1.2 1.2H7A1.2 1.2 0 015.8 19V7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BitAiIcon;
