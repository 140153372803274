import { useMemo, useState } from 'react';

import { BBBModal, BBBTextInput } from '@/components';
import useDeleteCompany from '@/hooks/auth/useDeleteCompany';
import { useCompany } from '@/hooks/rtk/selector';

type DeleteCompanyModalProps = {
  show: boolean;
  onChangeShow?: (val: boolean) => void;
};

function DeleteCompanyModal({ show, onChangeShow }: DeleteCompanyModalProps) {
  const [text, setText] = useState('');
  const activeCompanyData = useCompany();

  const { mutate: deleteCompany, isLoading: loadingDelete } =
    useDeleteCompany();

  const truthyText = useMemo(
    () => `Yes, I want to delete ${activeCompanyData.name}`,
    [activeCompanyData.name]
  );

  const isTextTruthy = useMemo(() => text === truthyText, [text, truthyText]);

  return (
    <BBBModal
      title="Delete company"
      show={show}
      onHide={() => onChangeShow?.(false)}
      centerTitle
      centerBody
      size="md"
      footer
      centerFooter
      submitText={'Delete company'}
      disableSave={!isTextTruthy || loadingDelete}
      submitVariant="danger"
      handleSave={() => {
        deleteCompany(undefined, {
          onSuccess: () => {
            setText('');
            onChangeShow?.(false);
          },
        });
      }}
    >
      <div className="font-light text-center">
        Are you sure to delete this company{' '}
        <span>({activeCompanyData?.name})</span>?
      </div>
      <div className="font-light text-center mb-4">
        Your action cannot be undone.
      </div>
      <div className="font-light text-center">Type to continue:</div>
      <div className="font-bold text-center mb-6">{truthyText}</div>
      <BBBTextInput
        value={text}
        onChange={({ target: { value } }) => setText(value)}
        placeholder="Type the sentence here"
      />
    </BBBModal>
  );
}

export default DeleteCompanyModal;
