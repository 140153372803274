export default function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 6a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
      ></path>
      <path
        stroke="#757575"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 8l7.501 6.001a4 4 0 004.998 0L22 8"
      ></path>
    </svg>
  );
}
