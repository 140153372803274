import React, { useEffect, useState } from 'react';

import CompleteOnboardingIcon from '@/assets/icons/CompleteOnboardingIcon';
import BBBJourneyV2, { Step } from '@/components/BBBJourneyV2/BBBJourneyV2';
import useCreateOrUpdateOnboarding from '@/hooks/bitLink/onboarding/useCreateOrUpdateOnboarding';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';

function Journey() {
  const { data, status } = useOnboarding('appearance');

  if (status !== 'success') return null;

  if (data?.lastStep && data.lastStep > 5) return null;

  return <_Journey lastStep={data?.lastStep || 0} />;
}

function _Journey({ lastStep }: { lastStep: number }) {
  const { mutate: updateOnboarding } = useCreateOrUpdateOnboarding();

  const joyrideSteps: Step[] = [
    {
      title: 'bitLink Appearance',
      content:
        'In 2 minutes, you can learn on how to customize your bitLink appearance.',
      isModal: true,
      icon: <StartIcon />,
    },
    {
      target: '#store-profile-appearance',
      title: 'Store Profile',
      content: `To personalize your bitLink, you can add your store logo, store name and bio in this section.`,
      placement: 'right',
      withoutBack: true,
    },
    {
      target: '#personalize-appearance',
      title: `Personalizing your bitLink`,
      content: `You can choose colors, buttons style, and font style that suits your personal theme.`,
      placement: 'right',
      offset: {
        top: -420,
      },
    },
    {
      target: '#presets-appearance',
      title: `Try it out!`,
      content: `Choose any presets that you like.`,
      placement: 'right',
      clickSpotlightToNext: true,
      withoutPulse: true,
      scrollOffset: {
        top: 557,
      },
    },
    {
      target: '#bbb-device-frame',
      title: `Nice choice! Preview for your link here`,
      content: `You can track changes and quickly see how your link will appear in visitor’s screen`,
      placement: 'left',
      withoutBack: true,
    },
    {
      title: 'Finished',
      content: 'You’ve successfully created your own link!',
      isModal: true,
      icon: <CompleteOnboardingIcon />,
    },
  ];

  const [stepIndex, setStepIndex] = useState(lastStep);

  useEffect(() => {
    setStepIndex(lastStep);
  }, [lastStep]);

  return (
    <BBBJourneyV2
      steps={joyrideSteps}
      stepIndex={stepIndex}
      onChangeStep={(val) =>
        updateOnboarding({
          moduleKey: 'appearance',
          lastStep: val,
        })
      }
    />
  );
}

function StartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="860.367"
      height="608.84"
      className="w-[280px] h-[191px]"
      data-name="Layer 1"
      viewBox="0 0 860.367 608.84"
    >
      <path
        fill="#f2f2f2"
        d="M50.852 513.601L24.475 491.28a111.491 111.491 0 0121.38-49.199c.887 32.25 33.943 53.847 45.318 84.037a67.062 67.062 0 01-5.547 57.862l-2.654 24.364A112.375 112.375 0 0125.4 533.92a108.55 108.55 0 01-2.47-27.12c13.505 4.11 27.923 6.8 27.923 6.8zM790.244 556.297l18.42-29.236a111.49 111.49 0 0151.703 14.3c-31.81 5.382-48.58 41.13-76.885 56.61a67.062 67.062 0 01-58.07 2.587l-24.496.775a112.375 112.375 0 0165.653-67.402 108.549 108.549 0 0126.508-6.233c-2.183 13.947-2.833 28.6-2.833 28.6z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M207.198 460.992L105.065 455 119.065 356 209.065 453 207.198 460.992z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M194.198 561.992L90.198 554.992 104.065 465 194.667 555.008 194.198 561.992z"
      ></path>
      <path
        fill="#3f3d56"
        d="M775.18 144.373V52.927A52.927 52.927 0 00722.252 0H528.51a52.927 52.927 0 00-52.927 52.927v501.682a52.927 52.927 0 0052.927 52.927h193.742a52.927 52.927 0 0052.926-52.926V209.466a3.338 3.338 0 003.338-3.338v-58.417a3.338 3.338 0 00-3.338-3.338z"
      ></path>
      <path
        fill="#fff"
        d="M763.915 53.29v500.958a39.5 39.5 0 01-39.088 39.503 3.47 3.47 0 01-.433.018H529.71a39.525 39.525 0 01-39.521-39.52V53.29a39.525 39.525 0 0139.52-39.52h23.627a18.792 18.792 0 0017.39 25.878H681.72a18.805 18.805 0 0017.372-25.879h25.302a39.525 39.525 0 0139.521 39.521z"
      ></path>
      <path
        fill="#3f3d56"
        d="M721.053 581.54q0 7.785-.49 15.44a3.022 3.022 0 01-.43.02h-194.68a39.534 39.534 0 01-38.34-29.9 37.34 37.34 0 01-.85-4.53 38.852 38.852 0 01-.33-5.09V334.23a247.294 247.294 0 0144.46 6.28c1.43.33 2.85.68 4.26 1.04.09.02.18.05.27.07 1.43.36 2.85.74 4.26 1.13a246.328 246.328 0 0183.25 40.99c1.43 1.07 2.85 2.17 4.26 3.28l.27.21q3.54 2.79 6.97 5.72c.51.43 1.02.87 1.52 1.32 1.23 1.06 2.46 2.14 3.66 3.23a248.14 248.14 0 0161.8 86.06c.47 1.07.92 2.15 1.37 3.23l.54 1.32c.17.4.33.8.49 1.2a247.055 247.055 0 0117.74 92.23zM476.655 195.532a4.51 4.51 0 01-4.506-4.505V160.39a4.505 4.505 0 119.011 0v30.637a4.51 4.51 0 01-4.505 4.505zM475.754 136.061a4.51 4.51 0 01-4.506-4.505V100.92a4.505 4.505 0 019.01 0v30.636a4.51 4.51 0 01-4.504 4.505z"
      ></path>
      <path
        fill="#fff"
        d="M595.596 18.922a4.51 4.51 0 014.505-4.505h30.636a4.505 4.505 0 110 9.01h-30.636a4.51 4.51 0 01-4.505-4.505z"
      ></path>
      <path
        fill="#3f3d56"
        d="M476.655 247.794a4.51 4.51 0 01-4.506-4.505v-30.637a4.505 4.505 0 119.011 0v30.637a4.51 4.51 0 01-4.505 4.505z"
      ></path>
      <circle cx="647.858" cy="18.922" r="4.505" fill="#fff"></circle>
      <path
        d="M631.223 559.34a71.22 71.22 0 0171.23-71.23h.37l-.54-1.32c-.45-1.08-.9-2.16-1.37-3.23h-69.69v-89.29h4.23c-.5-.45-1.01-.89-1.52-1.32q-3.435-2.925-6.97-5.72l-.27-.21v2.7h-87.51v-46.97c-1.41-.39-2.83-.77-4.26-1.13-.09-.02-.18-.05-.27-.07v48.17h-44.46v4.55h44.46v89.29h-44.46v4.55h44.46v71.23h-44.13a37.34 37.34 0 00.85 4.53h43.28v29.9h4.53v-29.9h87.51v29.9h4.53v-29.9h76.35v-4.53zm-4.53 0h-87.51v-71.23h87.51zm0-75.78h-87.51v-1.78a87.51 87.51 0 0187.51-87.51z"
        opacity="0.2"
      ></path>
      <path
        fill="#e5e5e5"
        d="M238.653 232.79L190.083 606.41 189.773 608.79 187.753 608.79 188.063 606.41 236.673 232.53 238.653 232.79z"
      ></path>
      <path
        fill="#e5e5e5"
        d="M137.533 233.26L83.973 606.45 83.633 608.83 81.613 608.83 81.953 606.45 135.553 232.98 137.533 233.26z"
      ></path>
      <path
        fill="#3f3d56"
        d="M314.173 608.74L312.093 608.75 311.453 606.37 207.843 221.52 209.773 221 313.523 606.37 314.173 608.74z"
      ></path>
      <path
        fill="#3f3d56"
        d="M532.233 359.249H534.233V759.578H532.233z"
        transform="rotate(-15.827 -75.326 1097.453)"
      ></path>
      <path
        fill="#3f3d56"
        d="M468.094 691.82H569.197V693.82H468.094z"
        transform="rotate(-11.688 -277.409 1449.57)"
      ></path>
      <path
        fill="#3f3d56"
        d="M450.928 631.74H552.031V633.74H450.928z"
        transform="rotate(-11.688 -294.574 1389.49)"
      ></path>
      <path
        fill="#3f3d56"
        d="M434.589 570.933H534.73V572.932H434.589z"
        transform="rotate(-10.953 -359.454 1384.742)"
      ></path>
      <path
        fill="#3f3d56"
        d="M419.549 510.853H517.584V512.8530000000001H419.549z"
        transform="rotate(-11.178 -360.166 1306.722)"
      ></path>
      <path
        fill="#3f3d56"
        d="M135.842 233.19a37.476 37.476 0 1174.09-11.34l-1.976.302a35.477 35.477 0 10-70.137 10.736zM236.003 233.638a37.477 37.477 0 1173.935-12.311l-1.972.328a35.477 35.477 0 00-69.99 11.655z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M339.339 315.25L320.12 362.005 382.739 361.551 376.371 315.146 339.339 315.25z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M354.63 529.884L363.918 531.239 373.562 496.059 359.854 494.06 354.63 529.884z"
      ></path>
      <path
        fill="#2f2e41"
        d="M352.995 524.967l14.81 1.265-.918 6.29L379.44 544.2a3.97 3.97 0 01-3.278 6.837l-17.48-2.55-2.105-6.661-2.084 6.05-6.59-.961z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M247.292 503.901L254.702 509.663 280.452 483.824 269.516 475.321 247.292 503.901z"
      ></path>
      <path
        fill="#2f2e41"
        d="M248.297 498.817l12.257 8.41-3.903 5.018 5.155 16.35a3.97 3.97 0 01-6.224 4.33l-13.945-10.845 1.456-6.833-4.798 4.234-5.258-4.088zM323.656 350.911l-6.167 8.17-16.415 71.418-37.312 50.392 12.543 12.043 47.59-46.168 23.354-49.33L359.065 443l-3 61 20-1c5.42-70.79 13.9-143.629 3.912-164.084a64.225 64.225 0 01-48.374 15.53z"
      ></path>
      <path
        fill="#fd823e"
        d="M356.667 239.64l15.778 17.254a24.655 24.655 0 016.28 13.653 24.655 24.655 0 01-.14 6.953l-3.402 24.92 6 38s-8.55 5.216-25 16c-6.713 4.401-38.038 4.33-38 3 .178-6.162 5.69-22.769 7-43 1.618-24.971-.503-54.775 5-69 6.719-17.366 26.484-7.78 26.484-7.78z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M302.521 350.201a8.313 8.313 0 00-3.417-12.28l.617-18.986-11.446-3.188-.495 26.824a8.358 8.358 0 0014.741 7.63z"
      ></path>
      <path
        fill="#fd823e"
        d="M287.254 273.559a6.839 6.839 0 013.863-3.916c6.092-2.56 32.43-24.083 33.4-23.678l8.362-4.127c.309.003 8.546.198 12.586 4.78a8.877 8.877 0 012.078 7.125c-1.183 10.912-38.52 35.422-42.478 35.257l-4.192 30.982 1.631 11.836-15.27.434-4.704-17.428c-.676-1.27-1.529-25.344 4.724-41.265z"
      ></path>
      <ellipse
        cx="505.423"
        cy="400.713"
        fill="#fd823e"
        rx="15.5"
        ry="13"
        transform="rotate(-81.7 336.336 426.116)"
      ></ellipse>
      <path
        fill="#3f3d56"
        d="M233.692 312.393L233.305 310.431 326.183 289.42 326.183 291.42 233.692 312.393z"
      ></path>
      <rect
        width="63.447"
        height="27.826"
        x="595.328"
        y="171.07"
        fill="#e6e6e6"
        rx="13.913"
      ></rect>
      <rect
        width="195.621"
        height="27.826"
        x="529.241"
        y="226.722"
        fill="#e6e6e6"
        rx="13.913"
      ></rect>
      <rect
        width="195.621"
        height="27.826"
        x="457.241"
        y="268.374"
        fill="#fd823e"
        rx="13.913"
      ></rect>
      <path
        fill="#cacaca"
        d="M860.13 607.34a1.186 1.186 0 01-1.19 1.19l-592.69.21-2.08.01-122.32.04h-2.02l-104.12.04h-2.02l-32.5.01a1.19 1.19 0 010-2.38l32.84-.01h2.02l104.09-.04h2.02l121.37-.04h2.07l103.42-.04h1.36l488.56-.18a1.186 1.186 0 011.19 1.19z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M373.363 222.411a21.845 21.845 0 113.785-18.255c.063.262.116.522.167.793a21.815 21.815 0 01-3.952 17.462z"
      ></path>
      <path
        fill="#2f2e41"
        d="M379.047 205.201c-.55-2.738-.343-1.323-.904-4.053a6.539 6.539 0 01-.828 3.8l-25.737-3.754 2.182-14.961c7.976-1.889 15.72-3.357 19.365 2.824a15.12 15.12 0 0112.78 17.145z"
      ></path>
      <path
        fill="#2f2e41"
        d="M363.856 245.46c.021-.28.033-.571.034-.854a29.447 29.447 0 00-.806-6.181c-.438-2.196-.884-4.404-1.331-6.601a18.152 18.152 0 01-2.504 11.276c-3.85-4.017-1.305-9.833-6.985-10.662-13.655-1.992-24.767-16.637-22.774-30.302 1.992-13.656 9.34-23.013 22.996-21.021 13.665 1.993 23.835 9.864 22.11 23.558-17.399 1.505-7.361 39.865-10.74 40.787z"
      ></path>
      <circle cx="333.812" cy="184.459" r="9.121" fill="#2f2e41"></circle>
      <path
        fill="#2f2e41"
        d="M344.63 176.928a12.33 12.33 0 00-9.274-7.007 9.442 9.442 0 012.606 15.455l1.844-1.585a3.097 3.097 0 003.661.262 4.37 4.37 0 001.916-3.295 7.585 7.585 0 00-.752-3.83z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M460.735 273.398a8.313 8.313 0 00-11.716 5.02l-18.9 1.915-1.637 11.768 26.651-3.078a8.358 8.358 0 005.602-15.625z"
      ></path>
      <path
        fill="#fd823e"
        d="M386.805 298.726a6.84 6.84 0 01-4.395-3.308c-3.347-5.697-28.183-28.938-27.912-29.953l-5.202-7.739c-.038-.306-.94-8.495 3.063-13.11a8.877 8.877 0 016.785-3.007c10.973-.28 38.558 34.447 38.921 38.391l32.939-.948 11.513-3.192 2.462 15.076-16.647 6.982c-1.17.839-24.916 4.887-41.527.808z"
      ></path>
    </svg>
  );
}

export default Journey;
