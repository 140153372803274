import { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useColumn from './hooks/useColumnManage';

import {
  CollectionSortingRow,
  Product,
} from '@/api/services/bitAi/v2-new/sorting';
import {
  BBBAlert,
  BBBButton,
  BBBCheckbox,
  BBBContainer,
  BBBModal,
} from '@/components';
import BBBTableV2 from '@/components/BBBTableV2/BBBTableV2';
import SearchInput from '@/components/SearchInput';
import { env } from '@/config/env';
import useSearchProducts from '@/hooks/bitAi/sorting/useSearchProducts';
import useSortingCollection from '@/hooks/bitAi/sorting/useSortingCollection';
import useUpdateSortingRules from '@/hooks/bitAi/sorting/useUpdateSortingRules';
import useCustomForm from '@/hooks/common/useCustomForm';
import useHistory from '@/hooks/common/useHistory';
import { arrayMove } from '@/utils/common/array';
import { toast } from '@/utils/common/toast';

const schema = yup.object().shape({
  rules: yup.array().of(yup.object().shape({})),
});

type FormSchema = {
  rules: CollectionSortingRow[];
};

export default function ManageSorting() {
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const { handleSubmit, reset, watch, setValue } = useCustomForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      rules: [],
    },
  });

  const rules = watch('rules');

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string>();

  const { data: dataCollection } = useSortingCollection(id);
  const { mutate: updateCollection, isLoading: isUpdating } =
    useUpdateSortingRules();

  const handleAddProduct = useCallback(
    (selectedProduct: Product[]) => {
      setValue(
        'rules',
        rules.map((rule) => {
          if (rule.id === selectedRowId) {
            return {
              ...rule,
              featuredProduct: [...rule.featuredProduct, ...selectedProduct],
            };
          }

          return rule;
        })
      );
    },
    [rules, selectedRowId, setValue]
  );

  const columns = useColumn({
    onShowAddProductModal: (id) => {
      setSelectedRowId(id);
      setShowAddProductModal(true);
    },
    onDeleteProduct: ({ productId, rowId }) => {
      setValue(
        'rules',
        rules.map((rule) => {
          if (rule.id === rowId) {
            return {
              ...rule,
              featuredProduct: rule.featuredProduct.filter(
                (product) => product.id !== productId
              ),
            };
          }

          return rule;
        })
      );
    },
    onRearrangeProducts: ({ startIndex, endIndex, draggableId }) => {
      const [rowId] = draggableId.split('-');

      setValue(
        'rules',
        rules.map((rule) => {
          if (rule.id === rowId) {
            const updatedProducts = [...rule.featuredProduct];

            arrayMove(updatedProducts, startIndex, endIndex);

            return {
              ...rule,
              featuredProduct: updatedProducts,
            };
          }

          return rule;
        })
      );
    },
  });

  const onSubmit = (data: FormSchema) => {
    updateCollection({
      collectionId: id,
      rules: data.rules,
    });
  };

  useEffect(() => {
    if (id !== 'new') {
      reset({
        rules:
          dataCollection?.sorting?.model?.rules.map((data, index) => ({
            ...data,
            featuredProduct:
              dataCollection.sorting?.features
                .find((feature) => feature.index === index)
                ?.featureProducts.map(
                  (featureProduct) => featureProduct.product
                ) || [],
          })) || [],
      });
    }
  }, [
    dataCollection?.sorting?.features,
    dataCollection?.sorting?.model?.rules,
    id,
    reset,
  ]);

  const selectedRule = rules.find((item) => item.id === selectedRowId);

  const selectedProductIds = rules
    .map((rule) =>
      rule.featuredProduct.map((featureProduct) => featureProduct.id)
    )
    .flat();

  return (
    <BBBContainer
      hasHeading
      pageTitle={dataCollection?.title}
      hasBack
      backUrl={'/bitai/sorting'}
    >
      {showAddProductModal && (
        <AddProductModal
          show={showAddProductModal}
          onChangeShow={setShowAddProductModal}
          collectionId={id}
          onSelectProducts={handleAddProduct}
          limitSelectProduct={
            dataCollection?.sorting?.model?.type === 'CUSTOM'
              ? selectedRule?.qty || 0
              : undefined
          }
          selectedProductIds={selectedProductIds}
        />
      )}
      <div className="flex flex-col gap-4">
        <p className="text-primary-main text-xl">
          {dataCollection?.sorting?.model?.name}
        </p>
        <BBBTableV2 data={rules} headers={columns} dataId="id" />
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <BBBButton
          variant="secondary"
          text="Discard"
          onClick={() => history.push('/bitai/sorting')}
        />
        <BBBButton
          variant="primary"
          text="Save"
          onClick={() => handleSubmit(onSubmit)()}
          disabled={!rules.length || isUpdating}
        />
      </div>
    </BBBContainer>
  );
}

function AddProductModal({
  show,
  onChangeShow,
  onSelectProducts,
  collectionId,
  limitSelectProduct,
  selectedProductIds,
}: {
  show: boolean;
  onChangeShow: (data: boolean) => void;
  onSelectProducts?: (selectedProduct: Product[]) => void;
  collectionId: string;
  limitSelectProduct?: number;
  selectedProductIds?: string[];
}) {
  const [searchProduct, setSearchProduct] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<Product[]>([]);

  const { data: dataProducts, isInitialLoading: loadingProducts } =
    useSearchProducts(collectionId, {
      search: searchProduct,
      except: selectedProductIds,
    });

  const handleAddProduct = () => {
    if (!selectedProduct.length) return;

    if (
      typeof limitSelectProduct === 'undefined' ||
      selectedProduct.length <= limitSelectProduct
    ) {
      onSelectProducts?.(selectedProduct);
      onChangeShow(false);
    } else {
      toast.error(`You can only select ${limitSelectProduct} product`);
    }
  };

  const handleCheckProduct = (_product: Product) => {
    if (selectedProduct.some((product) => product.id === _product.id)) {
      setSelectedProduct(
        selectedProduct.filter((item) => item.id !== _product.id)
      );
    } else {
      setSelectedProduct([...selectedProduct, _product]);
    }
  };

  const countSelectedProduct = selectedProduct?.length;

  return (
    <BBBModal
      id="modal-add-product"
      title="Add Product"
      show={show}
      onHide={() => onChangeShow(false)}
      customFooter={
        <div className="flex justify-between items-center px-5 pb-5">
          <p className="text-primary-main">Selected: {countSelectedProduct}</p>
          <div className="flex gap-4">
            <BBBButton
              variant="secondary"
              text="Cancel"
              onClick={() => onChangeShow(false)}
            />
            <BBBButton
              variant="primary"
              text="Add Product"
              onClick={() => handleAddProduct()}
            />
          </div>
        </div>
      }
    >
      <SearchInput
        placeholder="Search product"
        value={searchProduct}
        onValueChange={(value) => setSearchProduct(value)}
      />
      <div className="w-full overflow-auto h-80">
        {!dataProducts?.length && (
          <BBBAlert message="No product found" className="mt-4" />
        )}
        {loadingProducts ? (
          <ProductSkeleton />
        ) : (
          dataProducts?.map(({ product: item }) => (
            <div
              key={item.id}
              className="py-3 px-3 flex gap-4 items-center cursor-pointer"
              onClick={() => handleCheckProduct(item)}
            >
              <BBBCheckbox
                className="w-6 h-6"
                checked={selectedProduct.includes(item)}
                onValueChange={() => handleCheckProduct(item)}
              />
              <img
                className="rounded-md w-14 h-14 object-cover"
                src={
                  item.imageUrl ||
                  `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
                }
                alt={`${item.title}`}
              />
              <div className="flex-1 relative">
                <p className="text-primary-main font-base line-clamp-1">
                  {item.title}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </BBBModal>
  );
}

function ProductSkeleton() {
  return (
    <div className="py-3 px-3 flex gap-4 items-center">
      <Skeleton width={20} height={20} />
      <Skeleton width={50} height={50} circle />
      <Skeleton width={200} height={20} />
    </div>
  );
}
