import React, { useMemo, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { BBBSelect } from '@/components';
import useBlog from '@/hooks/bitApp/shopify/useBlog';
import useBlogs from '@/hooks/bitApp/shopify/useBlogs';
import ShopifyBlogs from '@/types/shopify/ShopifyBlogs';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';

type Props = {
  label: string | React.ReactNode;
  value: string | undefined | null;
  onChange: (val?: string | undefined | null) => void;
};

function ShopifyBlogListsOptions({ label, value, onChange }: Props) {
  const [title, setTitle] = useState<string>();

  const blogsQuery = useBlogs({
    title,
  });

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = blogsQuery;

  const memoizedParams = useMemo(
    () => ({
      title,
    }),
    [title]
  );

  const client = useQueryClient();

  const { data: blogData, isInitialLoading: isLoadingBlog } = useBlog(value, {
    initialData: client
      .getQueryData<InfiniteData<ShopifyConnection<ShopifyBlogs>> | undefined>([
        'bitapp-infinite-blogs',
        memoizedParams,
      ])
      ?.pages.flatMap((page) => page.edges.map((edge) => edge.node))
      .find((data) => data.id === value),
  });

  const memoizedCurrentPages = useMemo(
    () => data?.pages.flatMap((page) => page.edges.map((edge) => edge.node)),
    [data?.pages]
  );

  return (
    <BBBSelect
      label={label}
      options={memoizedCurrentPages}
      optionLabel="title"
      loading={isInitialLoading || isLoadingBlog}
      optionValue="id"
      search={title}
      isSearchable
      onChangeSearch={setTitle}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      value={blogData}
      onValueChange={(val) => onChange(val?.id)}
    />
  );
}

export default ShopifyBlogListsOptions;
