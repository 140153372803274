import { Transition, Variants } from 'framer-motion';
import { startCase } from 'lodash-es';
import { AutomationFormSchema } from 'pages/BitCRM/Automation/New/validation';
import { Automation } from 'types/bitCRM/automation';

export const advanceScheduleWhenOptions = [
  {
    label: 'Before',
    value: 'before',
  },
  {
    label: 'After',
    value: 'after',
  },
];

export const advanceScheduleTypeOptions = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

export const DEMO_ID = 999;

export const demoOptions = [
  {
    label: 'Demo Trigger - Send Immediately',
    event: 'default',
  },
];

export const sectionForm = [
  {
    id: 'automation',
    title: 'your automation',
    description: '',
  },
  {
    id: 'source',
    title: 'Source & trigger',
    description:
      'What is the source of order activity and what will trigger this automation?',
  },
  {
    id: 'communication',
    title: 'Communication channel',
    description: 'What channel do you want to use?',
  },
  {
    id: 'schedule',
    title: 'Schedule',
    description: 'When specific time the message will send?',
  },
  {
    id: 'message',
    title: 'Message',
    description: '',
  },
] as const;

export const mapNearestCollapse: Partial<
  Record<keyof AutomationFormSchema, typeof sectionForm[number]['id']>
> = {
  scenarioName: 'automation',
  description: 'automation',
  scheduledDays: 'schedule',
  advanceScheduleTime: 'schedule',
  advanceScheduleType: 'schedule',
  advanceSchedule: 'schedule',
  scheduledFrom: 'schedule',
  scheduledUntil: 'schedule',
  sourceType: 'source',
  storeChannel: 'source',
  orderStatus: 'source',
  type: 'communication',
  body: 'message',
  imageUrl: 'message',
};

export const voucherTypeOptions = [
  {
    label: 'Discount',
    value: 'discount',
  },
  {
    label: 'Free Shipping',
    value: 'free_shipping',
  },
];

export const validForOptions = [
  {
    label: '12 hours',
    value: '12 hours',
  },
  {
    label: '24 hours',
    value: '24 hours',
  },
  {
    label: '3 Days',
    value: '3 Days',
  },
  {
    label: '7 days',
    value: '7 days',
  },
  {
    label: '14 Days',
    value: '14 Days',
  },
  {
    label: '21 Days',
    value: '21 Days',
  },
  {
    label: '30 Days',
    value: '30 Days',
  },
];

export const animateTransition: Transition = {
  duration: 0.3,
  ease: [0.04, 0.62, 0.23, 0.98],
};

export const animateVariants: Variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 150 },
};

export const PREFILLED_ONBOARDING: Automation = {
  id: '7a73fdf4-a197-440d-aaf6-b7c8d1b35b17',
  companyId: 15,
  sourceType: 'Demo',
  channelList: [],
  type: 'SANDBOX_WHATSAPP',
  scenarioName: 'Order Confirmation',
  message: `Sent automatically to the customer after they place their order`,
  preBuilt: false,
  debug: false,
  whatsAppMessage: null,
  schedule: {
    type: 'immediately',
    from: '00:00',
    until: '23:59',
    timezone: 'GMT+7',
    days: [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ],
  },
  waGroup: false,
  active: true,
  isDraft: false,
  shopifyEvent: '',
  automationTitle: '',
  automationBody: '',
  templateParams: [],
  channels: null,
  channelId: null,
  templateId: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  analytics: {
    delivered: 0,
    failed: 0,
    read: 0,
    sent: 0,
    replied: 0,
  },
  templateParams2: null,
};

export const scheduleOptions = [
  'immediately',
  'weekdays_only',
  'weekend_only',
  'advance_schedule',
].map((data) => ({
  label: startCase(data),
  value: data,
}));
