import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { WACloudTemplate } from '@/api/services/whatsApp/settings';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useWACloudTemplates = (
  params?: {
    status?: WACloudTemplate['status'];
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-wa-cloud-templates', activeCompany],
    () => services.bitChat.template.getWACloudTemplates(activeCompany, params),
    options
  );
};

export default useWACloudTemplates;
