import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronUp } from 'react-feather';
import { BeatLoader } from 'react-spinners';
import colors from 'constants/common/colors';
import { motion } from 'framer-motion';
import { useCategory } from 'hooks/bitApp/category';
import { twMerge as cx } from 'tailwind-merge';
import { BBBSpinner } from 'components';

function CategoryPage() {
  const {
    isInitialLoading: loadingCategory,
    data: dataCategory,
    error: errorCategory,
  } = useCategory();

  const flattenCategory = dataCategory?.pages.flatMap((page) => page.data);
  const defaultActiveCategory =
    flattenCategory?.find((category) => category.subCategories.length)?.id ||
    null;

  const [collapse, setCollapse] = useState<number[] | null>(
    defaultActiveCategory ? [defaultActiveCategory] : null
  );

  useEffect(() => {
    if (defaultActiveCategory) {
      setCollapse([defaultActiveCategory]);
    }
  }, [defaultActiveCategory]);

  if (loadingCategory) {
    return <BBBSpinner />;
  }

  if (errorCategory) return null;

  return (
    <div className="w-full flex flex-col gap-2.5 p-4">
      <div className="flex gap-3.5 items-center mt-3.5 mb-2.5">
        <ChevronLeft color={colors.primary.main} />
        <p className="text-primary-main font-bold">Shop by Category</p>
      </div>
      {loadingCategory ? (
        <BeatLoader color={colors.primary.main} />
      ) : (
        <div className="flex flex-col gap-3">
          {flattenCategory
            ?.filter((item) => item.active)
            ?.map((category) => (
              <React.Fragment key={category.title}>
                <div
                  className={cx(
                    'pb-3 border-b border-neutral-30',
                    category?.subCategories?.length
                      ? 'flex items-center gap-1 cursor-pointer'
                      : ''
                  )}
                  onClick={() =>
                    setCollapse((prev) =>
                      prev?.includes(category.id)
                        ? prev.filter((id) => id !== category.id)
                        : [...(prev || []), category.id]
                    )
                  }
                >
                  <p className="text-neutral-70">{category.title}</p>
                  {!!category?.subCategories?.length &&
                    (collapse?.includes(category.id) ? (
                      <ChevronUp color={colors.neutral[60]} width={16} />
                    ) : (
                      <ChevronDown color={colors.neutral[60]} width={16} />
                    ))}
                </div>
                {collapse?.includes(category.id) &&
                  category?.subCategories?.map((subCategory) => (
                    <motion.div
                      key={subCategory.title}
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="pl-6">
                        <p className="text-neutral-70">{subCategory.title}</p>
                      </div>
                      <div className="bg-neutral-30 h-[1px] w-full mt-3"></div>
                    </motion.div>
                  ))}
              </React.Fragment>
            ))}
        </div>
      )}
    </div>
  );
}

export default CategoryPage;
