import { useState } from 'react';
import { Calendar } from 'react-feather';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import AutomationLogAnalytics from './components/AutomationLogAnalytics';
import MessageLog from './components/MessageLog';

import { BBBCard, BBBContainer } from '@/components';
import { useAutomationLog } from '@/hooks/bitCRM/automation/automation';
import SelectRange from '@/pages/Analytics/SelectRange';
import { selectedDate } from '@/types/datePicker';
import getRangeValue from '@/utils/common/date';

export default function AutomationLog() {
  const { id } = useParams<{
    id: string;
  }>();

  const [selectedDay, setSelectedDay] = useState<selectedDate>(
    dayjs().startOf('day').subtract(7, 'day').toDate()
  );
  const [selectedEndDay, setSelectedEndDay] = useState<selectedDate>(
    dayjs().endOf('day').subtract(1, 'day').toDate()
  );

  const [activeRange, setActiveRange] = useState(false);
  const rangeValue = getRangeValue(selectedDay, selectedEndDay);

  const toggleShowRange = () => {
    setActiveRange((prev) => !prev);
  };

  const props = {
    startDate: dayjs(selectedDay).toISOString(),
    endDate: dayjs(selectedEndDay).toISOString(),
  };

  const { data, isLoading } = useAutomationLog(id, props);

  return (
    <BBBContainer
      hasHeading
      pageTitle={data?.scenarioName || '-'}
      hasBack
      backUrl="/bitcrm/automation"
      pageDescription={data?.description || '-'}
      rightComponent={
        <div className="relative cursor-pointer">
          <BBBCard onClick={toggleShowRange} className="!px-4 !py-[10px]">
            <div className="flex gap-3">
              <Calendar width={24} height={24} />
              <div className="font-bold text-primary-main">
                {rangeValue?.label}
              </div>
            </div>
          </BBBCard>
          {activeRange && (
            <SelectRange
              selectedDay={selectedDay}
              selectedEndDay={selectedEndDay}
              setSelectedDay={setSelectedDay}
              setSelectedEndDay={setSelectedEndDay}
              active={activeRange}
              onChangeActive={setActiveRange}
            />
          )}
        </div>
      }
    >
      <AutomationLogAnalytics data={data} isLoading={isLoading} />
      <MessageLog id={id} {...props} />
    </BBBContainer>
  );
}
