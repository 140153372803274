import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import UserList from './UserList';

import UserAddIcon from '@/assets/icons/UserAddIcon';
import {
  BBBAlert,
  BBBCard,
  BBBCheckbox,
  BBBModal,
  BBBSelect,
  BBBSpinner,
  BBBTextInput,
} from '@/components';
import ShowMore from '@/components/Auth/ShowMore';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useDeleteUserCompany from '@/hooks/auth/useDeleteUserCompany';
import useInvitePeople from '@/hooks/auth/useInvitePeople';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useUpsertAuthority from '@/hooks/auth/useUpsertAuthority';
import useUserCompanyDetail from '@/hooks/auth/useUserCompanyDetail';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useUserCompany } from '@/hooks/rtk/selector';
import { RoleTypes } from '@/types/systemLogin';

function SettingsUserAuthority() {
  const query = useCompanyUsers({ order: 'firstName:asc' });

  const {
    data: userCompanies,
    isInitialLoading: loadingUserCompanies,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = query;

  const userCompaniesData = useMemo(
    () => userCompanies?.pages.flatMap((page) => page.data),
    [userCompanies]
  );

  const authorizedUserCompany = useUserCompany();

  const {
    data: isManageStaffRestricted,
    isInitialLoading: isLoadingManageStaffAccessRights,
  } = useIsModuleRestricted('SETTINGS_MANAGE_USER');

  const [showDetail, setShowDetail] = useState<number | 'new' | null>(null);
  const [showLess, setShowLess] = useState(false);

  return (
    <>
      {showDetail && (
        <UserDetail id={showDetail} onClose={() => setShowDetail(null)} />
      )}

      <BBBCard
        title={
          <p className="text-primary-main text-xl font-medium">
            Users & Authority{' '}
            <span className="subtitle__2">
              ({userCompanies?.pages[0]?.meta.total})
            </span>
          </p>
        }
        className={cx('mb-cardBottom')}
        rightButton={
          <>
            {(authorizedUserCompany?.role === 'SUPER_ADMIN'
              ? true
              : !isManageStaffRestricted) && (
              <div
                className="text-info-main hover:underline flex cursor-pointer items-center gap-2"
                onClick={() => {
                  setShowDetail('new');
                }}
              >
                Add user <UserAddIcon />
              </div>
            )}
          </>
        }
      >
        {loadingUserCompanies || isLoadingManageStaffAccessRights ? (
          <BBBSpinner />
        ) : !userCompaniesData?.length ? (
          <BBBAlert message='No user found for this company. Try by clicking the "Add Staff" button" below' />
        ) : (
          <>
            {(showLess ? userCompaniesData.slice(0, 4) : userCompaniesData).map(
              (userCompany) => (
                <UserList
                  key={`${userCompany.user.id}-${userCompany.company.id}`}
                  {...userCompany}
                  disabled={
                    isManageStaffRestricted ||
                    userCompany.role === 'SUPER_ADMIN'
                  }
                  onClick={setShowDetail}
                />
              )
            )}
            {userCompanies &&
              userCompanies?.pages[0].meta.total > 5 &&
              (showLess || hasNextPage ? (
                isFetchingNextPage ? (
                  <BBBSpinner />
                ) : (
                  <ShowMore
                    onClick={() =>
                      !showLess ? fetchNextPage() : setShowLess(false)
                    }
                  />
                )
              ) : (
                <div
                  className="text-info-main cursor-pointer text-center"
                  onClick={() => setShowLess(true)}
                >
                  Show less
                </div>
              ))}
          </>
        )}
        {isLoadingManageStaffAccessRights ? (
          <Skeleton width={150} height={20} />
        ) : null}
      </BBBCard>
    </>
  );
}

export default SettingsUserAuthority;

const roleOptions: {
  label: string;
  value: Extract<RoleTypes, 'ADMIN' | 'STAFF' | 'AGENT'>;
}[] = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Regular user', value: 'STAFF' },
  { label: 'Agent', value: 'AGENT' },
];

type UserDetailForm = {
  email: string;
  role: typeof roleOptions[number] | null;
  isAgent: boolean;
};

const schema = yup.object().shape({
  email: yup.string().label('Email').email().required(),
  role: yup.mixed<typeof roleOptions[number] | null>().label('Role').required(),
  isAgent: yup.boolean().required('Agent').label('Agent status'),
});

function UserDetail({
  id,
  onClose,
}: {
  id: number | 'new';
  onClose?: () => void;
}) {
  const { handleSubmit, formState, control, watch, reset } =
    useCustomForm<UserDetailForm>({
      resolver: yupResolver(schema),
      defaultValues: { email: '', role: null, isAgent: false },
    });

  const { data: userCompanyData } = useUserCompanyDetail(id.toString());

  const { mutate: deleteUserCompany } = useDeleteUserCompany();

  const { mutate: upsertAuthority, isLoading: loadingUpsertAuthority } =
    useUpsertAuthority();
  const { mutate: invitePeople, isLoading: isLoadingInvitePeople } =
    useInvitePeople();

  const toggleConfirmation = useConfirmationModal();

  useEffect(() => {
    reset({
      email: userCompanyData?.user.email || '',
      role:
        roleOptions.find(
          (opt) =>
            opt.value ===
            (userCompanyData?.role === 'STAFF_AGENT'
              ? 'STAFF'
              : userCompanyData?.role)
        ) || null,
      isAgent: userCompanyData?.role === 'STAFF_AGENT',
    });
  }, [reset, userCompanyData]);

  return (
    <BBBModal
      title={`${id === 'new' ? 'Add' : 'Edit'} user`}
      subtitle="Bring your team on board and seamlessly integrate them into your workflow"
      show
      footer
      submitText={id === 'new' ? 'Send invite' : 'Save'}
      handleDelete={
        id !== 'new' && userCompanyData?.role !== 'SUPER_ADMIN'
          ? () => {
              toggleConfirmation({
                title: 'Remove user',
                description: `Are you sure to remove ${userCompanyData?.user.profile?.firstName}
            from your team? Your action cannot 
            be undone.`,
                size: 'md',
                submitText: 'Remove',
                onAccept: (hide) => {
                  deleteUserCompany(id);
                  hide();
                  onClose?.();
                },
                deleteModal: true,
              });
            }
          : undefined
      }
      loadingSave={loadingUpsertAuthority || isLoadingInvitePeople}
      onHide={onClose}
      handleSave={() =>
        handleSubmit((data) => {
          const role =
            data.role!.value === 'STAFF'
              ? data.isAgent
                ? 'STAFF_AGENT'
                : 'STAFF'
              : data.role!.value;
          if (id !== 'new') {
            upsertAuthority(
              {
                userId: id,
                role,
              },
              {
                onSuccess: () => {
                  onClose?.();
                },
              }
            );
          } else {
            invitePeople(
              {
                invitedEmail: data.email,
                role,
              },
              {
                onSuccess: () => {
                  onClose?.();
                },
              }
            );
          }
        })()
      }
    >
      <BBBTextInput
        label="Email"
        placeholder="Enter user email"
        isHookForm
        control={control}
        controlName="email"
        disabled={id !== 'new'}
        error={formState.errors.email?.message}
      />
      <Controller
        control={control}
        name="role"
        render={({ field: { value, onChange } }) => (
          <BBBSelect
            label="Role"
            placeholder="Choose user role"
            options={roleOptions}
            optionLabel="label"
            optionValue="value"
            containerClassName="mb-2.5"
            value={value}
            onValueChange={onChange}
            error={formState.errors.role?.message}
          />
        )}
      />
      {watch('role')?.value === 'STAFF' && (
        <Controller
          control={control}
          name="isAgent"
          render={({ field }) => (
            <BBBCheckbox
              label="Agent access (user can handle a ticket)"
              className="mb-5"
              checked={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
      )}
    </BBBModal>
  );
}
