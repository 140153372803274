import { useParams } from 'react-router';
import CampaignLogAnalytics from './components/CampaignLogAnalytics';
import MessageLog from './components/MessageLog';
import WhatsappMessageAnalytics from './components/WhatsappMessageAnalytics';

import { BBBContainer } from '@/components';
import { Redirect } from '@/components/Redirect';
import useCampaignDetail from '@/hooks/bitCRM/campaign/useCampaignDetail';

function BitCRMCampaignLog() {
  const { id } = useParams<{
    id: string;
  }>();

  const {
    data: campaignData,
    isInitialLoading: loadingCampaign,
    status,
  } = useCampaignDetail(id);

  if (status === 'success' && campaignData.status !== 'SENT') {
    return <Redirect to="/bitcrm/campaign" />;
  }

  return (
    <BBBContainer
      hasHeading
      pageTitle={campaignData?.campaignName.toString()}
      hasBack
      backUrl="/bitcrm/campaign"
      pageDescription="Track all message and mail that you sent to your customers"
    >
      <WhatsappMessageAnalytics
        campaignData={campaignData}
        loadingCampaign={loadingCampaign}
      />
      <CampaignLogAnalytics
        campaignId={id}
        campaignData={campaignData}
        loadingCampaign={loadingCampaign}
      />
      <MessageLog id={id} />
    </BBBContainer>
  );
}

export default BitCRMCampaignLog;
