import React, { useEffect, useState } from 'react';

import BBBJourneyV2, { Step } from '@/components/BBBJourneyV2/BBBJourneyV2';
import useOnboarding from '@/hooks/bitApp/onboarding/useOnboarding';
import useUpdateOnboarding from '@/hooks/bitApp/onboarding/useUpdateOnboarding';

function Journey() {
  const { data, status } = useOnboarding('preview');

  if (status !== 'success') return null;

  if (data?.lastStep && data.lastStep > 0) return null;

  return <_Journey lastStep={data?.lastStep || 0} />;
}

function _Journey({ lastStep }: { lastStep: number }) {
  const { mutate: updateOnboarding } = useUpdateOnboarding();

  const joyrideSteps: Step[] = [
    {
      title: 'Preview your app',
      content:
        'You can preview your app directly from your phone. You can feel the real experience before submitting it to the app store.',
      submitText: 'Get started',
      isModal: true,
      withoutSkip: true,
      icon: <StartIcon />,
    },
  ];

  const [stepIndex, setStepIndex] = useState(lastStep);

  useEffect(() => {
    setStepIndex(lastStep);
  }, [lastStep]);

  return (
    <BBBJourneyV2
      steps={joyrideSteps}
      stepIndex={stepIndex}
      onChangeStep={(val) =>
        updateOnboarding({
          moduleKey: 'preview',
          lastStep: val,
        })
      }
    />
  );
}

function StartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="724.346"
      height="519"
      className="w-[269px] h-[190px]"
      data-name="Layer 1"
      viewBox="0 0 724.346 519"
    >
      <path
        fill="#3f3d56"
        d="M723.346 519h-301a1 1 0 010-2h301a1 1 0 010 2z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M535.418 507.548L523.159 507.548 517.326 460.26 535.421 460.261 535.418 507.548z"
      ></path>
      <path
        fill="#2f2e41"
        d="M514.401 504.045h23.644v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M624.374 493.661L613.222 498.754 588.269 458.165 604.727 450.648 624.374 493.661z"
      ></path>
      <path
        fill="#2f2e41"
        d="M603.801 499.206l21.507-9.823 6.185 13.541-35.048 16.009a14.887 14.887 0 017.356-19.727z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M588.671 348.105a10.056 10.056 0 00.801-15.399l14.412-32.7-18.309 3.103-10.878 30.577a10.11 10.11 0 0013.974 14.419z"
      ></path>
      <path
        fill="#2f2e41"
        d="M534.637 495.08h-11.712a4.517 4.517 0 01-4.488-4.174L506.05 320.347a4.5 4.5 0 014.925-4.805l73.735 7.194a4.501 4.501 0 014.062 4.393l1.42 75.236a3.492 3.492 0 00.234 1.193l27.785 72.053a4.499 4.499 0 01-2.643 5.84l-11.869 4.374a4.501 4.501 0 01-5.557-2.165l-34.133-66.369a4.475 4.475 0 01-.404-1.142l-5.83-28.094a3.5 3.5 0 00-6.905.312l-11.762 102.724a4.499 4.499 0 01-4.47 3.988z"
      ></path>
      <circle cx="540.469" cy="162.426" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#fd823e"
        d="M510.724 321.332c3.144 2.17 38.566 25.512 78.942 6.946-.523-4.51-5.561-49.78-1.16-83.153 2.087-15.834-4.218-30.503-17.3-40.246a49.733 49.733 0 00-50.02-5.401q-.994.445-2.002.927z"
      ></path>
      <path
        fill="#fd823e"
        d="M590.05 327.906a4.486 4.486 0 01-3.313-1.46l-5.372-5.862a4.523 4.523 0 01-.998-4.315l12.304-41.648-18.722-39.316a8.333 8.333 0 01-.57-5.546 8.31 8.31 0 0115.388-1.966l25.31 41.068a11.451 11.451 0 01.69 10.73l-20.61 45.665a4.489 4.489 0 01-3.39 2.592 4.567 4.567 0 01-.718.058z"
      ></path>
      <path
        d="M528.9 322.58h-9.812l17.128-80.604.988.129-3.81 76.2a4.497 4.497 0 01-4.494 4.274z"
        opacity="0.2"
      ></path>
      <path
        fill="#ffb8b8"
        d="M519.821 342.619a10.056 10.056 0 00-2.413-15.23l7.308-34.979-17.266 6.837-4.293 32.169a10.11 10.11 0 0016.664 11.203z"
      ></path>
      <path
        fill="#fd823e"
        d="M518.831 322.68a4.693 4.693 0 01-.507-.028l-10.465-1.163a4.515 4.515 0 01-4-4.659l4.33-104.782a13.528 13.528 0 0126.735-2.315l2.79 12.83-.014.09-14.43 96.193a4.485 4.485 0 01-4.439 3.834z"
      ></path>
      <path
        fill="#2f2e41"
        d="M560.705 184.08c-2.547.436-4.642-3.11-4.96-5.603s.592-4.88.692-7.358-1.07-5.488-3.643-6.223c-2.697-.77-5.198 1.394-7.959 1.134-3.848-.363-5.78-4.866-6.246-8.509s-.832-7.956-4.165-10.034c-3.322-2.07-7.432-.564-11.249-.5-6.452.11-13.02-4.81-14.527-10.882a3.66 3.66 0 004.175-1.672 3.704 3.704 0 00-1.115-4.378l7.313 3.581c-1-2.034.51-4.35 2.588-5.121s4.495-.425 6.788-.068c11.45 1.779 24.713.902 32.308 9.632 5.261 6.048 9.592 17.555 9 25s-9 21-9 21z"
      ></path>
      <path
        fill="#ccc"
        d="M282 461H19a7.008 7.008 0 01-7-7L0 7.027A7.013 7.013 0 017 0h263a7.008 7.008 0 017 7l2.174 80.973-2 .054-2.174-81A5.012 5.012 0 00270 2H7a5.006 5.006 0 00-5 5l12 446.973A5.012 5.012 0 0019 459h263a5.006 5.006 0 005-5l-2.336-86.973 2-.054 2.335 87a7.013 7.013 0 01-7 7.027z"
      ></path>
      <path
        fill="#ccc"
        d="M518.738 398.493H520.738V436.893H518.738z"
        transform="rotate(-1.538 -6696.404 9182.876)"
      ></path>
      <path
        fill="#ccc"
        d="M258.4 421.61H40.54a5.977 5.977 0 01-5.97-5.97L24.63 45.387a5.982 5.982 0 015.97-5.997h217.86a5.977 5.977 0 015.97 5.97l1.8 42.597-1.998.084-1.802-42.64a3.985 3.985 0 00-3.97-4.011H30.6a3.975 3.975 0 00-3.97 3.97l9.94 370.251a3.981 3.981 0 003.97 3.997H258.4a3.975 3.975 0 003.97-3.97l-1.288-48.613 2-.052 1.287 48.639a5.982 5.982 0 01-5.97 5.996z"
      ></path>
      <path
        fill="#ccc"
        d="M495.229 399.493H497.229V437.506H495.229z"
        transform="rotate(-1.516 -6820.795 9309.628)"
      ></path>
      <path
        fill="#3f3d56"
        d="M316.37 209h-255a7.008 7.008 0 01-7-6.942L50.37 94a7.008 7.008 0 017-7h255a7.008 7.008 0 017 6.942l4 108.058a7.008 7.008 0 01-7 7zm-264-115.053l4 108.053a5.006 5.006 0 005 5h255a5.006 5.006 0 005-4.947L317.37 94a5.006 5.006 0 00-5-5h-255a5.006 5.006 0 00-5 4.947zM364.37 368h-255a7.008 7.008 0 01-7-6.942L98.37 253a7.008 7.008 0 017-7h255a7.008 7.008 0 017 6.942l4 108.058a7.008 7.008 0 01-7 7zm-264-115.053l4 108.053a5.006 5.006 0 005 5h255a5.006 5.006 0 005-4.947l-4-108.053a5.006 5.006 0 00-5-5h-255a5.006 5.006 0 00-5 4.947z"
      ></path>
      <path
        fill="#fd823e"
        d="M169.87 269.5a6.5 6.5 0 000 13h130a6.5 6.5 0 000-13zM169.87 300.5a6.5 6.5 0 000 13h130a6.5 6.5 0 000-13zM169.87 331.5a6.5 6.5 0 000 13h130a6.5 6.5 0 000-13zM121.87 110.5a6.5 6.5 0 000 13h130a6.5 6.5 0 000-13zM121.87 141.5a6.5 6.5 0 000 13h130a6.5 6.5 0 000-13zM121.87 172.5a6.5 6.5 0 000 13h130a6.5 6.5 0 000-13z"
      ></path>
      <circle cx="140.37" cy="20" r="10" fill="#e6e6e6"></circle>
    </svg>
  );
}

export default Journey;
