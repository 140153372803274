import { useMemo, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBRenderHtml } from '@/components';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { QuickReply } from '@/types/bitChat/quickReply';

const useColumnMobile = (
  setEditedData: React.Dispatch<React.SetStateAction<QuickReply | undefined>>,
  isRestricted: boolean | undefined
) => {
  const [showReadMore, setShowReadMore] = useState('');

  return useMemo<HeadersTypes<QuickReply>>(() => {
    return [
      {
        accesor: 'command',
        render: (info) => (
          <div className="px-2 flex flex-col gap-[10px]">
            <p
              className={cx(
                'text-primary-main group-hover:underline cursor-pointer w-fit',
                isRestricted && 'pointer-events-none'
              )}
              onClick={() => setEditedData(info)}
            >
              {info.command}
            </p>
            <div className="max-w-[280px] text-base text-primary-main">
              <span
                className={`${showReadMore !== info.id ? 'line-clamp-2' : ''}`}
              >
                <BBBRenderHtml content={info.message} />
              </span>
              {info.message.length < 150 ? null : (
                <span className="underline font-medium cursor-pointer hover:text-[#767676] transition-all">
                  {showReadMore !== info.id ? (
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowReadMore(info.id);
                      }}
                    >
                      Read more
                    </p>
                  ) : (
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowReadMore('');
                      }}
                    >
                      Read less
                    </p>
                  )}
                </span>
              )}
            </div>
          </div>
        ),
      },
    ];
  }, [setEditedData, showReadMore]);
};

export default useColumnMobile;
