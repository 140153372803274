import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function BitLoginIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' ? (
        <BitLoginIconDefault />
      ) : mode === 'hover' ? (
        <BitLoginIconHover />
      ) : (
        <BitLoginIconActive />
      )}
    </>
  );
}

function BitLoginIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      fill="none"
      viewBox="0 0 20 25"
    >
      <g fill="#404040" clipPath="url(#clip0_6824_92275)">
        <path d="M15.873 8.43V5.895a5.91 5.91 0 00-1.72-4.17 5.86 5.86 0 00-4.152-1.727c-1.557 0-3.05.621-4.152 1.727a5.91 5.91 0 00-1.72 4.17V8.43a4.584 4.584 0 00-2.943 1.492 4.621 4.621 0 00-1.188 3.089v7.376a4.62 4.62 0 001.344 3.26 4.58 4.58 0 003.247 1.35h10.83a4.58 4.58 0 003.246-1.35 4.62 4.62 0 001.344-3.26v-7.376a4.621 4.621 0 00-1.19-3.09 4.584 4.584 0 00-2.946-1.49zm-9.73-.02V5.895c0-1.028.406-2.014 1.13-2.74a3.85 3.85 0 015.457 0 3.884 3.884 0 011.13 2.74V8.41H6.143zm11.846 4.61v7.377a2.59 2.59 0 01-.753 1.825 2.568 2.568 0 01-1.818.756H4.584a2.568 2.568 0 01-1.817-.756 2.59 2.59 0 01-.754-1.825V13.02a2.594 2.594 0 01.749-1.834 2.575 2.575 0 011.822-.762h10.834a2.563 2.563 0 011.82.759 2.584 2.584 0 01.751 1.828v.009z"></path>
        <path d="M11.307 18.55A1.31 1.31 0 0010 17.236a1.31 1.31 0 00-1.307 1.314v2.003A1.31 1.31 0 0010 21.867a1.31 1.31 0 001.307-1.314V18.55z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92275">
          <path fill="#fff" d="M0 0H20V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BitLoginIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      fill="none"
      viewBox="0 0 20 25"
    >
      <g fill="#FD823E" clipPath="url(#clip0_6824_92288)">
        <path d="M15.873 8.43V5.895a5.91 5.91 0 00-1.72-4.17 5.86 5.86 0 00-4.152-1.727c-1.557 0-3.05.621-4.152 1.727a5.91 5.91 0 00-1.72 4.17V8.43a4.584 4.584 0 00-2.943 1.492 4.621 4.621 0 00-1.188 3.089v7.376a4.62 4.62 0 001.344 3.26 4.58 4.58 0 003.247 1.35h10.83a4.58 4.58 0 003.246-1.35 4.62 4.62 0 001.344-3.26v-7.376a4.621 4.621 0 00-1.19-3.09 4.584 4.584 0 00-2.946-1.49zm-9.73-.02V5.895c0-1.028.406-2.014 1.13-2.74a3.85 3.85 0 015.457 0 3.884 3.884 0 011.13 2.74V8.41H6.143zm11.846 4.61v7.377a2.59 2.59 0 01-.753 1.825 2.568 2.568 0 01-1.818.756H4.584a2.568 2.568 0 01-1.817-.756 2.59 2.59 0 01-.754-1.825V13.02a2.594 2.594 0 01.749-1.834 2.575 2.575 0 011.822-.762h10.834a2.563 2.563 0 011.82.759 2.584 2.584 0 01.751 1.828v.009z"></path>
        <path d="M11.307 18.55A1.31 1.31 0 0010 17.236a1.31 1.31 0 00-1.307 1.314v2.003A1.31 1.31 0 0010 21.867a1.31 1.31 0 001.307-1.314V18.55z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92288">
          <path fill="#fff" d="M0 0H20V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BitLoginIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      fill="none"
      viewBox="0 0 20 25"
    >
      <g clipPath="url(#clip0_6824_92280)">
        <path
          fill="#404040"
          fillRule="evenodd"
          d="M15.873 5.895V8.43a4.584 4.584 0 012.946 1.49 4.621 4.621 0 011.19 3.09v7.377a4.62 4.62 0 01-1.344 3.26 4.58 4.58 0 01-3.247 1.35H4.588a4.58 4.58 0 01-3.246-1.35 4.62 4.62 0 01-1.344-3.26v-7.376a4.621 4.621 0 011.188-3.089A4.584 4.584 0 014.13 8.43V5.895a5.91 5.91 0 011.72-4.17A5.859 5.859 0 0110-.002a5.86 5.86 0 014.152 1.727 5.91 5.91 0 011.72 4.17zm-9.73 0V8.41h7.717V5.895a3.884 3.884 0 00-1.13-2.74 3.85 3.85 0 00-5.457 0 3.883 3.883 0 00-1.13 2.74zm5.164 12.655A1.31 1.31 0 0010 17.236a1.31 1.31 0 00-1.307 1.314v2.003A1.31 1.31 0 0010 21.867a1.31 1.31 0 001.307-1.314V18.55z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92280">
          <path fill="#fff" d="M0 0H20V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default BitLoginIcon;
