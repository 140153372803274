import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { AnimatePresence, AnimationControls } from 'framer-motion';

import { BBBBanner } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { hideConfirmationBanner } from '@/stores/common';

type Props = {
  animateRootContentControl: AnimationControls;
};

function BBBConfirmationBanner({ animateRootContentControl }: Props) {
  const confirmationBanner = useAppSelector(
    (state) => state.common.confirmationBanner,
    shallowEqual
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    animateRootContentControl.start({
      marginTop: confirmationBanner.length * 72,
      transition: {
        type: 'tween',
        duration: 0.3,
      },
    });
  }, [animateRootContentControl, confirmationBanner.length]);

  if (!document.getElementById('banner-section')) return null;

  return (
    <>
      <div
        className="relative"
        style={{
          height: confirmationBanner.length * 72,
        }}
      >
        <AnimatePresence>
          {confirmationBanner.map(
            (
              {
                id: k,
                props: { show, onAccept, onCancel, onClose, ...bannerProps },
              },
              index
            ) => (
              <BBBBanner
                showBanner={!!show}
                onAccept={() =>
                  onAccept?.(() => dispatch(hideConfirmationBanner(k)))
                }
                onCancel={() => {
                  onCancel?.();
                  dispatch(hideConfirmationBanner(k));
                }}
                onClose={() => {
                  onClose?.();
                  dispatch(hideConfirmationBanner(k));
                }}
                idx={index}
                key={k}
                id={k}
                {...bannerProps}
              />
            )
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default BBBConfirmationBanner;
