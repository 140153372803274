import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function CustomerIcon({
  mode = 'default',
  size,
}: Props & {
  size?: number;
}) {
  return (
    <>
      {mode === 'default' ? (
        <CustomerIconDefault size={size} />
      ) : mode === 'hover' ? (
        <CustomerIconHover />
      ) : (
        <CustomerIconActive />
      )}
    </>
  );
}

function CustomerIconDefault({ size = 25 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="#404040" clipPath="url(#clip0_6824_92331)">
        <path d="M12.5 11.806a4.86 4.86 0 100-9.722 4.86 4.86 0 000 9.722zm0-8.334a3.472 3.472 0 110 6.945 3.472 3.472 0 010-6.945zM21.16 16.924a11.917 11.917 0 00-17.313 0 1.389 1.389 0 00-.375.95v3.654a1.389 1.389 0 001.39 1.389h15.277a1.389 1.389 0 001.389-1.39v-3.652a1.388 1.388 0 00-.368-.951zm-1.021 4.604H4.86v-3.66a10.535 10.535 0 0115.278 0v3.66z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92331">
          <path fill="#fff" d="M0 0H25V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function CustomerIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#404040"
        fillRule="evenodd"
        d="M17.216 14.168c1.488.641 2.83 1.579 3.944 2.756.239.259.37.599.368.951v3.653a1.389 1.389 0 01-1.39 1.389H4.862a1.389 1.389 0 01-1.389-1.39v-3.652c0-.353.134-.693.375-.951a11.915 11.915 0 0113.369-2.756zM15.2 10.986a4.86 4.86 0 11-5.4-8.082 4.86 4.86 0 015.4 8.082z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function CustomerIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="#FD823E" clipPath="url(#clip0_6824_92347)">
        <path d="M12.5 11.806a4.86 4.86 0 100-9.722 4.86 4.86 0 000 9.722zm0-8.334a3.472 3.472 0 110 6.945 3.472 3.472 0 010-6.945zM21.16 16.924a11.917 11.917 0 00-17.313 0 1.389 1.389 0 00-.375.95v3.654a1.389 1.389 0 001.39 1.389h15.277a1.389 1.389 0 001.389-1.39v-3.652a1.388 1.388 0 00-.368-.951zm-1.021 4.604H4.86v-3.66a10.535 10.535 0 0115.278 0v3.66z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92347">
          <path fill="#fff" d="M0 0H25V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CustomerIcon;
