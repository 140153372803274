import React, { useEffect, useMemo } from 'react';
import { PlusCircle } from 'react-feather';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { EditorState } from 'draft-js';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';

import {
  BBBFileUpload,
  BBBModal,
  BBBRichTextEditor,
  BBBTextInput,
} from '@/components';
import { acceptedCrmFileTypes } from '@/constants/crm';
import {
  useDeleteQuickReply,
  useUpsertQuickReply,
} from '@/hooks/bitChat/quick-reply';
import useCustomForm from '@/hooks/common/useCustomForm';
import { QuickReply } from '@/types/bitChat/quickReply';
import { FileType } from '@/types/utils/file';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
  insertText,
} from '@/utils/common/rich';

const schema = yup.object().shape({
  id: yup.string(),
  command: yup.string().label('Command').required(),
  message: yup.mixed<EditorState>().label('Message').required(),
});

type FormSchema = Omit<
  QuickReply,
  'companyId' | 'updatedAt' | 'message' | 'mediaUrl'
> & {
  message: EditorState;
  mediaUrl: FileType | null;
};

export default function AddQuickReplyModal({
  show,
  onHide,
  editedData,
  setEditedData,
}: {
  show: boolean;
  onHide: () => void;
  editedData?: QuickReply | undefined;
  setEditedData: React.Dispatch<React.SetStateAction<QuickReply | undefined>>;
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
    watch,
  } = useCustomForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: '',
      message: emptyEditor,
      command: '',
    },
  });

  const { mutate: upsertQuickReply, isLoading: isLoadingUpsert } =
    useUpsertQuickReply();

  const deleteQuickReply = useDeleteQuickReply();

  const onSubmit = ({ id, message, command, mediaUrl }: FormSchema) => {
    upsertQuickReply(
      {
        id,
        message: convertEditorStateToHtml(message) || '',
        command: !command.startsWith('/') ? `/${command}` : command,
        ...(mediaUrl && {
          mediaUrl: mediaUrl.remoteUrl,
          mimeType: mediaUrl.fileData?.type,
        }),
      },
      {
        onSuccess: () => {
          onHide();
          setEditedData(undefined);
          reset({ id: '', message: '', command: '' });
        },
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            err.response?.data.errors.forEach(
              (error: { field: 'command' | 'message'; message: string }) => {
                setError(error.field, {
                  message: error.message,
                });
              }
            );
          }
        },
      }
    );
  };

  const dataFromApi = useMemo(
    () => ({
      id: editedData?.id,
      command: editedData?.command || '',
      message: convertHtmlToEditorState(editedData?.message),
      mediaUrl: convertRemoteUrlToFileType(editedData?.mediaUrl),
    }),
    [
      editedData?.command,
      editedData?.id,
      editedData?.mediaUrl,
      editedData?.message,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [
    dataFromApi,
    editedData?.command,
    editedData?.id,
    editedData?.mediaUrl,
    editedData?.message,
    reset,
  ]);

  const isFormEqual = isEqual(watch(), dataFromApi);

  return (
    <BBBModal
      show={show}
      onHide={onHide}
      title={editedData ? 'Edit quick reply' : 'Create quick reply'}
      footer
      submitText="Save"
      handleSave={() => {
        handleSubmit(onSubmit)();
      }}
      disableSave={isFormEqual}
      cancelText="Discard"
      loadingSave={isLoadingUpsert}
      handleDelete={
        editedData
          ? () => {
              deleteQuickReply(true)(editedData.id, {
                onSuccess: () => {
                  onHide();
                },
              });
            }
          : undefined
      }
      deleteWithButton
    >
      <BBBTextInput
        isHookForm
        control={control}
        controlName="command"
        label="Quick reply command"
        placeholder="Input your command here"
        error={errors?.command?.message}
      />
      <Controller
        control={control}
        name="message"
        render={({ field }) => (
          <div className="flex flex-col mb-5">
            <BBBRichTextEditor
              editorState={field.value}
              onChangeEditorState={field.onChange}
              label="Message"
              placeholder="Input your message here"
            />
            <div className="flex">
              <div
                className="flex items-center gap-1 cursor-pointer"
                onClick={() => {
                  field.onChange(insertText(field.value, '{{customer.name}}'));
                }}
              >
                <PlusCircle size={12} />
                <p className="text-neutral-900 text-sm underline">
                  Add dynamic customer name
                </p>
              </div>
            </div>
          </div>
        )}
      />
      <Controller
        control={control}
        name="mediaUrl"
        render={({ field }) => (
          <BBBFileUpload
            isSingle
            files={field.value}
            onChangeFile={field.onChange}
            accept={acceptedCrmFileTypes}
            withLabel
          />
        )}
      />
    </BBBModal>
  );
}
