import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useCountProgressLive from '../hooks/useCountProgressLive';
import { mapStatusToText } from './StatusBadge';

import CheckIcon from '@/assets/icons/CheckIcon';
import CircleX from '@/assets/icons/CircleX';
import DoubleCheckIcon from '@/assets/icons/DoubleCheckIcon';
import RepliedIcon from '@/assets/icons/RepliedIcon';
import { BBBButton } from '@/components';
import useDeleteCampaign from '@/hooks/bitCRM/campaign/useDeleteCampaign';
import usePauseCampaign from '@/hooks/bitCRM/campaign/usePauseCampaign';
import useResendCampaign from '@/hooks/bitCRM/campaign/useResendCampaign';
import useResumeCampaign from '@/hooks/bitCRM/campaign/useResumeCampaign';
import { Campaign } from '@/types/bitCRM/campaign';
import { formatDate2 } from '@/utils/common/date';

export default function Progress(props: Campaign) {
  const { mutate: resumeCampaign } = useResumeCampaign();
  const { mutate: resendCampaign } = useResendCampaign();
  const deleteCampaign = useDeleteCampaign();

  return (
    <>
      {props.status === 'SCHEDULED' && props.campaignSchedule2 ? (
        <p className="text-primary-main">
          Scheduled for {formatDate2(props.campaignSchedule2)}
        </p>
      ) : props.status === 'SENDING' ? (
        <SendingCounter {...props} />
      ) : props.status === 'CANCELLED' || props.status === 'DRAFT' ? (
        <p className="text-primary-main">
          {mapStatusToText[props.status as keyof typeof mapStatusToText]}{' '}
          {props.status === 'CANCELLED' && (
            <BBBButton
              className="ml-4 py-1 px-3 text-sm"
              size="sm"
              variant="danger-outline"
              text="Delete"
              onClick={(e) => {
                deleteCampaign(true)(props.id);
                e.stopPropagation();
              }}
            />
          )}
        </p>
      ) : (
        <div className="flex gap-2.5 items-center">
          <div className="flex items-center justify-center gap-1">
            <CheckIcon color="#262627" />
            <span>{props.sentCount || props.analytics.sent}</span>
          </div>
          <div className="flex items-center justify-center gap-1">
            <DoubleCheckIcon color="#787878" />
            <span>{props.deliveredCount || props.analytics.delivered}</span>
          </div>
          <div className="flex items-center justify-center gap-1">
            <DoubleCheckIcon color="#53BDEB" />
            <span>{props.readCount || props.analytics.read}</span>
          </div>
          <div className="flex items-center justify-center gap-1">
            <RepliedIcon />
            <span>{props.replyCount || props.analytics.replied}</span>
          </div>
          <div className="flex items-center justify-center gap-1">
            <CircleX />
            <span>{props.failCount || props.analytics.failed}</span>
          </div>
          {props.status === 'ERROR' && (
            <BBBButton
              className="py-1 px-3 text-sm"
              variant="secondary"
              size="sm"
              text="Resend"
              onClick={(e) => {
                resendCampaign(props.id);
                e.stopPropagation();
              }}
            />
          )}
          {props.status === 'PAUSED' && (
            <BBBButton
              className="py-1 px-3 text-sm"
              variant="secondary"
              size="sm"
              text="Resume"
              onClick={(e) => {
                resumeCampaign({ id: props.id });
                e.stopPropagation();
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

function SendingCounter(props: Campaign) {
  const realtimeSent = useCountProgressLive(props.id, props.analytics.sent);
  const client = useQueryClient();
  const { mutate: pauseCampaign } = usePauseCampaign();

  useEffect(() => {
    if (realtimeSent === props.expectedReceivers) {
      client.invalidateQueries(['bitcrm-campaigns']);
    }
  }, [client, props.expectedReceivers, realtimeSent]);

  return (
    <p className="text-primary-main">
      Sending{' '}
      <span className="font-bold">
        {realtimeSent}/{props.expectedReceivers}
      </span>
      <BBBButton
        className="ml-4 py-1 px-3 text-sm"
        variant="secondary"
        size="sm"
        text="Pause"
        onClick={() => pauseCampaign(props.id)}
      />
    </p>
  );
}
