import { NotificationFormSchema } from '../Notification';
import { FormSchema } from '../Whatsapp';

import { BBBAlert, BBBModal } from '@/components';
import BBBLimitAlert from '@/components/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/BBBLimitAlert/UpgradeText';
import { Link } from '@/components/Link';
import { useWhatsappBusinessIntegration } from '@/hooks/bitChat/integration/integrations';
import useHistory from '@/hooks/common/useHistory';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { AvailableSources } from '@/hooks/whatsApp/useAvailableSources';

type Props = {
  source?: AvailableSources;
  showReviewModal: boolean;
  setShowReviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: () => Promise<void> | void;
  loadingSave?: boolean;
} & (
  | {
      type: 'wa';
      tempFinal: FormSchema;
    }
  | {
      type: 'notification';
      tempFinal: NotificationFormSchema;
    }
);

export default function ReviewChecklist({
  showReviewModal,
  setShowReviewModal,
  tempFinal,
  onConfirm,
  loadingSave,
  type,
  source,
}: Props) {
  const { data: pricingData } = usePricingByApp('BITCRM', {
    enabled: type === 'wa',
  });
  const { isMaximumLevel } = useIsMaximumLevel('BITCRM');

  const { limit, data, isReachingTreshold } = useWithCRMPricing(
    'BITCRM',
    'campaign_messages',
    {
      enabled: type === 'wa' && pricingData?.pricingName !== 'free',
    }
  );

  const history = useHistory();

  const { data: whatsAppIntegration } = useWhatsappBusinessIntegration({
    enabled: type === 'wa',
  });

  const isRestricted =
    type === 'wa' && pricingData?.pricingName !== 'free'
      ? isReachingTreshold ||
        (data?.usage ?? 0) +
          parseInt(tempFinal.customerSegment!.label.split(' - ')[1], 10) >
          limit
      : false;

  return (
    <BBBModal
      title="Review Checklist"
      centerTitle
      show={showReviewModal}
      onHide={() => setShowReviewModal(false)}
      size="md"
      centerFooter
      footer
      cancelText="Back to edit"
      handleSave={() =>
        isRestricted ? history.push('/pricing?tab=bitcrm') : onConfirm?.()
      }
      submitText={
        isRestricted
          ? 'Upgrade plan'
          : `${tempFinal.isScheduled ? 'Save message' : 'Send campaign now'}`
      }
      loadingSave={loadingSave}
    >
      <Checklist label="Campaign Name" description={tempFinal.campaignName} />
      {type === 'wa' ? (
        <>
          <Checklist
            label="Customer Segmentation"
            description={tempFinal.customerSegment!.label}
          />
          <Checklist
            label="WhatsApp number"
            description={whatsAppIntegration?.extra?.number ?? '-'}
            withoutSeparator
          />
        </>
      ) : (
        <>
          <Checklist
            label="Notification Headline"
            description={tempFinal.title}
          />
          <Checklist
            label="Notification Message"
            description={tempFinal.message}
            withoutSeparator
          />
        </>
      )}
      {source === 'whatsapp-business' && (
        <BBBAlert
          message={
            <>
              Sending large number of messages may be considered spam with
              potential risk of being banned by meta. For large volume, please
              use{' '}
              <Link
                className="font-semibold underline"
                to={{
                  pathname: '/integrations',
                }}
              >
                WhatsApp API.
              </Link>
            </>
          }
          type="secondary"
          className="leading-6 my-5"
        />
      )}
      {type === 'wa' && pricingData && (
        <BBBLimitAlert
          usage={data?.usage}
          limit={limit}
          appType="BITCRM"
          className="rounded-lg mt-4"
          customExceedTresholdLabel={
            pricingData.pricingName === 'free' ? (
              <>
                This demo campaign will only be sent to your number.{' '}
                <UpgradeText appType="BITCRM" /> plan to send campaigns to your
                customers and unlock other features.
              </>
            ) : (
              <>
                This segmentations exceeds your campaign broadcast limit this
                month.{' '}
                <span className="font-semibold">
                  To send this campaign, choose different segmentations
                </span>{' '}
                {!isMaximumLevel && (
                  <>
                    or <span className="font-semibold">upgrade your plan</span>.
                  </>
                )}
              </>
            )
          }
          customReachingTresholdLabel={
            data?.usage ? (
              <>
                Heads up!{' '}
                <span className="font-semibold">
                  You have {limit - data.usage} campaign broadcast left.
                </span>{' '}
                {!isMaximumLevel && (
                  <>
                    <UpgradeText appType="BITCRM" /> for unlimited campaign.
                  </>
                )}
              </>
            ) : undefined
          }
          customTip={
            'When limit is reached, you will not be able to send any campaign broadcast. Limit will refresh every month'
          }
        />
      )}
    </BBBModal>
  );
}

function Checklist({
  label,
  description,
  withoutSeparator,
}: {
  label: string;
  description: string;
  withoutSeparator?: boolean;
}) {
  return (
    <>
      <div className="font-light mb-3">{label}</div>
      <div className="flex items-center gap-4">
        <div className="w-3 h-3 rounded-full bg-success-main" />
        <span className="font-bold">{description}</span>
      </div>
      {!withoutSeparator && (
        <div className="my-4 border-neutral-40 border-t-[0.5px]"></div>
      )}
    </>
  );
}
