import AuthorizeShopify from '../components/AuthorizeShopify';
import Sortings from './Sorting';

import { BBBContainer, BBBSpinner } from '@/components';
import { useCompanyIntegrations } from '@/hooks/bitAi/integration/integrations';

export default function Sorting() {
  const { data: companyIntegrationsData, isInitialLoading } =
    useCompanyIntegrations();

  const integrationStatus =
    companyIntegrationsData?.find(
      (integration) => integration.integration.name === 'shopify'
    )?.status === 'CONNECTED';

  return (
    <BBBContainer
      hasHeading
      pageTitle="Sorting"
      pageDescription={
        !isInitialLoading &&
        (integrationStatus
          ? 'Customize sorting rules for this collection specifically'
          : 'All sorting types will be updated every time a collection, order or product changes, and you can create new sorting categories according to your needs.')
      }
    >
      {isInitialLoading ? (
        <BBBSpinner />
      ) : integrationStatus ? (
        <Sortings />
      ) : (
        <AuthorizeShopify />
      )}
    </BBBContainer>
  );
}
