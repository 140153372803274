import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

type Props = Parameters<Required<sidebarDataTypeSectionV2>['icon']>[0];

function BitLinkIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitLinkIconDefault />
      ) : (
        <BitLinkIconActive />
      )}
    </>
  );
}

export function BitLinkIconDefault({
  size = 24,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  size?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill={props.color || '#404040'} clipPath="url(#clip0_6824_92212)">
        <path d="M13.169 16.58l-2.956 2.85a4.571 4.571 0 01-1.515.96 4.71 4.71 0 01-3.556-.067 4.553 4.553 0 01-1.476-1.017c-1.672-1.738-1.538-4.468.204-6.145l2.873-2.766c.23-.222.36-.523.36-.837 0-.313-.13-.614-.36-.836a1.253 1.253 0 00-.868-.346c-.326 0-.638.124-.869.346l-2.958 2.845a6.739 6.739 0 00-1.535 2.236 6.53 6.53 0 00.082 5.253 6.76 6.76 0 001.605 2.19c2.771 2.514 7.16 2.35 9.854-.243l2.855-2.753c.23-.222.359-.523.358-.837 0-.313-.13-.614-.36-.835a1.253 1.253 0 00-.87-.346c-.326 0-.638.126-.868.348zM21.797 1.794c-2.782-2.512-7.16-2.345-9.853.248L9.091 4.788a1.18 1.18 0 00-.265.384 1.144 1.144 0 00.268 1.288 1.27 1.27 0 001.737-.002l2.956-2.85a4.57 4.57 0 011.515-.961 4.709 4.709 0 013.556.066c.558.24 1.06.586 1.476 1.017 1.676 1.737 1.543 4.468-.197 6.145l-2.878 2.77c-.23.222-.36.523-.36.837 0 .313.13.614.36.836a1.266 1.266 0 001.735 0l2.96-2.85c.661-.639 1.182-1.4 1.533-2.235a6.53 6.53 0 00-.085-5.25 6.76 6.76 0 00-1.605-2.189z"></path>
        <path d="M14.514 7.105l-7.1 6.834a1.309 1.309 0 000 1.9l.1.096a1.435 1.435 0 001.973 0l7.1-6.834a1.309 1.309 0 000-1.9l-.099-.096a1.435 1.435 0 00-1.974 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92212">
          <path fill="#fff" d="M0 0H24V23.04H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BitLinkIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#FD823E" clipPath="url(#clip0_6824_92214)">
        <path d="M13.169 16.58l-2.956 2.85a4.571 4.571 0 01-1.515.96 4.71 4.71 0 01-3.556-.067 4.553 4.553 0 01-1.476-1.017c-1.672-1.738-1.538-4.468.204-6.145l2.873-2.766c.23-.222.36-.523.36-.837 0-.313-.13-.614-.36-.836a1.253 1.253 0 00-.868-.346c-.326 0-.638.124-.869.346l-2.958 2.845a6.739 6.739 0 00-1.535 2.236 6.53 6.53 0 00.082 5.253 6.76 6.76 0 001.605 2.19c2.771 2.514 7.16 2.35 9.854-.243l2.855-2.753c.23-.222.359-.523.358-.837 0-.313-.13-.614-.36-.835a1.253 1.253 0 00-.87-.346c-.326 0-.638.126-.868.348zM21.797 1.794c-2.782-2.512-7.16-2.345-9.853.248L9.091 4.788a1.18 1.18 0 00-.265.384 1.144 1.144 0 00.268 1.288 1.27 1.27 0 001.737-.002l2.956-2.85a4.57 4.57 0 011.515-.961 4.709 4.709 0 013.556.066c.558.24 1.06.586 1.476 1.017 1.676 1.737 1.543 4.468-.197 6.145l-2.878 2.77c-.23.222-.36.523-.36.837 0 .313.13.614.36.836a1.266 1.266 0 001.735 0l2.96-2.85c.661-.639 1.182-1.4 1.533-2.235a6.53 6.53 0 00-.085-5.25 6.76 6.76 0 00-1.605-2.189z"></path>
        <path d="M14.514 7.105l-7.1 6.834a1.309 1.309 0 000 1.9l.1.096a1.435 1.435 0 001.973 0l7.1-6.834a1.309 1.309 0 000-1.9l-.099-.096a1.435 1.435 0 00-1.974 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92214">
          <path fill="#fff" d="M0 0H24V23.04H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default BitLinkIcon;
