import { useMemo, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBRenderHtml } from '@/components';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { QuickReply } from '@/types/bitChat/quickReply';

const useColumn = (
  setEditedData: React.Dispatch<React.SetStateAction<QuickReply | undefined>>,
  isRestricted: boolean | undefined
) => {
  const [showReadMore, setShowReadMore] = useState('');

  return useMemo<HeadersTypes<QuickReply>>(() => {
    return [
      {
        accesor: 'command',
        isSortable: true,
        renderHeader: () => 'Command',
        render: (info) => (
          <div
            className={cx(
              'text-primary-main group-hover:underline cursor-pointer w-fit',
              isRestricted && 'pointer-events-none'
            )}
            onClick={() => setEditedData(info)}
          >
            {info.command}
          </div>
        ),
        columnWidth: 300,
      },
      {
        accesor: 'message',
        isSortable: false,
        renderHeader: () => 'Message',
        render: (info) => (
          <div className="text-base text-primary-main">
            <span
              className={cx(showReadMore !== info.id ? 'line-clamp-2' : '')}
            >
              <BBBRenderHtml content={info.message} />
            </span>
            {info.message.length < 150 ? null : (
              <span className="underline font-medium cursor-pointer hover:text-[#767676] transition-all">
                {showReadMore !== info.id ? (
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowReadMore(info.id);
                    }}
                  >
                    Read more
                  </p>
                ) : (
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowReadMore('');
                    }}
                  >
                    Read less
                  </p>
                )}
              </span>
            )}
          </div>
        ),
      },
    ];
  }, [setEditedData, showReadMore]);
};

export default useColumn;
