import { useMemo, useState } from 'react';
import { ChevronRight } from 'react-feather';
import CompanyInfo from './CompanyInfo';

import { BBBButton, BBBSpinner } from '@/components';
import AuthCard from '@/components/Auth/Card';
import CompanyCreationCard, {
  CompanyCreationFormSchema,
} from '@/components/Auth/CompanyCreationCard';
import ShowMore from '@/components/Auth/ShowMore';
import useAddUserCompanyByUserId from '@/hooks/auth/useAddUserCompanyByUserId';
import useAuthorizeShopifyConnect from '@/hooks/auth/useAuthorizeShopifyConnect';
import useUserCompanies from '@/hooks/auth/useUserCompanies';
import { AppType } from '@/types/systemLogin';

type ChooseCompanyBack =
  | {
      showBack?: false;
      onBack?: never;
    }
  | {
      showBack: true;
      onBack?: () => void;
    };

type ChooseCompanyProps = {
  id: number;
  companyDomain: string;
  app: AppType;
  authenticatedAccessToken: string;
} & ChooseCompanyBack;

function ChooseCompany({
  id,
  showBack,
  onBack,
  companyDomain,
  app,
  authenticatedAccessToken,
}: ChooseCompanyProps) {
  const { mutate: addUserCompanyByUserId, isLoading: isLoadingAddUserCompany } =
    useAddUserCompanyByUserId();

  const [mode, setMode] = useState<'default' | 'create-company'>('default');

  const query = useUserCompanies(id, {
    order: 'companyName:asc',
  });

  const {
    data: userCompaniesData,
    isInitialLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = query;

  const data = useMemo(
    () => userCompaniesData?.pages.flatMap((page) => page.data),
    [userCompaniesData]
  );

  const {
    mutate: authorizeShopifyConnect,
    isLoading: isLoadingAuthorizeShopifyConnect,
  } = useAuthorizeShopifyConnect();

  const onAddCompany = (data: CompanyCreationFormSchema) => {
    addUserCompanyByUserId(
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: id,
        country: data.country?.value as string,
        industry: data.industries?.label as string,
        name: data.company_name,
      },
      {
        onSuccess: (data) => {
          authorizeShopifyConnect({
            companyDomain,
            userId: id,
            app,
            companyId: data.id,
            authenticatedAccessToken,
          });
        },
      }
    );
  };

  if (isInitialLoading) return <BBBSpinner />;

  if (!data?.length)
    return (
      <CompanyCreationCard
        onSubmit={onAddCompany}
        loadingSubmit={isLoadingAddUserCompany}
      />
    );

  return mode === 'default' ? (
    <AuthCard title="Choose company to connect">
      {data?.map(({ company }) => (
        <CompanyInfo
          name={company.name}
          key={company.id}
          onClick={() =>
            authorizeShopifyConnect({
              companyDomain,
              userId: id,
              app,
              companyId: company.id,
              authenticatedAccessToken,
            })
          }
          disabled={
            isInitialLoading ||
            isLoadingAddUserCompany ||
            isLoadingAuthorizeShopifyConnect
          }
        />
      ))}
      {hasNextPage &&
        (isFetchingNextPage ? (
          <BBBSpinner />
        ) : (
          <ShowMore onClick={() => fetchNextPage()} />
        ))}
      <div className="text-center my-5">Or</div>
      <div
        className="rounded-xl cursor-pointer hover:bg-gray-100 flex justify-between items-center border-grayColor2 p-5 border"
        onClick={() => {
          setMode('create-company');
        }}
      >
        <div>Create new company</div>
        <ChevronRight className="text-grayColor2" />
      </div>
      {showBack && (
        <BBBButton
          text="Login to another account"
          variant="secondary"
          onClick={onBack}
          className="mt-8"
          loadingState={isLoadingAuthorizeShopifyConnect}
        />
      )}
    </AuthCard>
  ) : (
    <CompanyCreationCard
      showBack
      onBack={() => setMode('default')}
      loadingSubmit={isLoadingAddUserCompany}
      onSubmit={(data) =>
        addUserCompanyByUserId(
          {
            userId: id,
            name: data.company_name,
            industry: data.industries?.label as string,
            country: data.country?.value as string,
          },
          {
            onSuccess: (data) => {
              authorizeShopifyConnect({
                companyDomain,
                userId: id,
                app,
                companyId: data.id,
                authenticatedAccessToken,
              });
            },
          }
        )
      }
    />
  );
}

export default ChooseCompany;
