import { suggestionContent } from 'constants/home/suggestion';
import useResponsive from 'hooks/common/useResponsive';
import { twMerge as cx } from 'tailwind-merge';
import { BBBButton, BBBCard } from 'components';
import { Link } from 'components/Link';

type Props = {
  className?: string;
};

export default function Suggestion({ className }: Props) {
  return (
    <div className={cx('mb-6', className)}>
      <p className="text-2xl mb-6 text-primary-main">Suggestion For You</p>
      <_Suggestion />
    </div>
  );
}

function _Suggestion() {
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('xl');

  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap gap-5">
      {suggestionContent.map((item) => (
        <BBBCard
          key={item.id}
          className={cx(
            'bg-home-card-gradient border-transparent',
            isMobile || isTablet ? 'w-full' : 'w-[37.5rem]'
          )}
        >
          <div className="flex gap-4 justify-between h-full">
            <div className="grow flex flex-col justify-between min-h-full gap-6">
              <div className="flex flex-col gap-3.5">
                <p className="text-2xl text-primary-main font-semibold">
                  {item.title}
                </p>
                <p className="text-neutral-60 text-lg">{item.subtitle}</p>
              </div>
              <div className="w-[9.6rem]">
                <Link to={item.link}>
                  <BBBButton text={`Open ${item.app}`} width="full" />
                </Link>
              </div>
            </div>
            <img
              className="w-[188px] h-[179px] object-fill rounded items-center"
              src={item.image}
              alt={`card-${item.app}-${item.id}`}
            />
          </div>
        </BBBCard>
      ))}
    </div>
  );
}
