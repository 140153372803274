import { useCallback, useMemo, useState } from 'react';
import CampaignCreateButton from '../components/CreateButton';
import useColumn from './hooks/useColumn';
import useColumnMobile from './hooks/useColumnMobile';
import TestMessageModal from './WhatsApp/New/components/TestMessageModal';

import { BBBButton, BBBContainer, BBBSelect } from '@/components';
import BBBTableV2 from '@/components/BBBTableV2/BBBTableV2';
import { env } from '@/config/env';
import { statusOptions } from '@/constants/bitCRM/template';
import { useTemplatesBulkAction } from '@/hooks/bitCRM/template/bulk-action';
import { useTemplates } from '@/hooks/bitCRM/template/template';
import { isEmbed } from '@/hooks/common/useAppEmbed';
import useHistory from '@/hooks/common/useHistory';
import useResponsive from '@/hooks/common/useResponsive';
import useAvailableSources from '@/hooks/whatsApp/useAvailableSources';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { getTemplateUrl, isTemplateNoAction } from '@/utils/bitCRM';
import { toast } from '@/utils/common/toast';

export const templateTypes = [
  'Automation',
  ...(isEmbed && env.REACT_APP_EMBED_PREVIEW_MODE ? [] : ['Campaign']),
];

const mapTypeFilter = {
  'automation-whatsapp-business': 'AUTOMATION_WHATSAPP',
  'campaign-whatsapp-business': 'CAMPAIGN_WHATSAPP',
  'campaign-whatsapp-cloud': 'CAMPAIGN_WHATSAPP_CLOUD_API',
  'automation-whatsapp-cloud': 'AUTOMATION_WHATSAPP_CLOUD_API',
} as const;

export default function Template() {
  return <_Template />;
}

function _Template() {
  const history = useHistory();
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('lg');

  const availableSources = useAvailableSources();

  const availableWhatsAppSources = useMemo(() => {
    const finalData = [];
    for (
      let availableNumber = 0;
      availableNumber < availableSources.length;
      availableNumber += 1
    ) {
      for (
        let templateNumber = 0;
        templateNumber < templateTypes.length;
        templateNumber += 1
      ) {
        finalData.push({
          ...availableSources[availableNumber],
          label: `${templateTypes[templateNumber]} ${availableSources[availableNumber].label}`,
          name: `${templateTypes[templateNumber].toLowerCase()}-${
            availableSources[availableNumber].name
          }`,
        });
      }
    }
    return finalData;
  }, [availableSources]);

  const [templateId, setTemplateId] = useState<string>();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<string | undefined>('updatedAt:desc');

  const [source, setSource] = useState<
    typeof availableWhatsAppSources[number] | null
  >();
  const [status, setStatus] = useState<typeof statusOptions[number] | null>();
  const [selected, setSelected] = useState<CampaignTemplate[]>([]);

  const { data: _templateData, isInitialLoading: loadingTemplate } =
    useTemplates({
      page: pageIndex,
      size: pageSize,
      search,
      type: source?.name
        ? mapTypeFilter[source.name as keyof typeof mapTypeFilter]
        : undefined,
      status: status?.value,
      sort,
    });

  const bulkAction = useTemplatesBulkAction();

  const newColumns = useColumn();
  const columnMobile = useColumnMobile();

  const handleClickRow = useCallback(
    (row: CampaignTemplate) => history.push(getTemplateUrl(row.type, row.id)),
    [history]
  );

  const templateData: CampaignTemplate[] = useMemo(() => {
    return _templateData?.content || [];
  }, [_templateData?.content]);

  return (
    <>
      <BBBContainer
        hasHeading
        pageTitle="Template"
        pageDescription="Prepare your campaign & automation message before you send it to customers"
        rightComponent={<CampaignCreateButton type="template" />}
      >
        {!!templateId && (
          <TestMessageModal
            show={!!templateId}
            onHide={() => setTemplateId('')}
            id={templateId}
          />
        )}

        <BBBTableV2
          containerProps={{
            id: 'template-table',
          }}
          headers={isMobile ? columnMobile : newColumns}
          data={templateData ?? []}
          dataId="id"
          loadingBody={loadingTemplate}
          isPaginate
          pagination={{
            page: pageIndex + 1,
            limit: pageSize,
            onChange: (page) => setPageIndex(page - 1),
            total: _templateData?.totalElements || 0,
            maxShown: 3,
          }}
          isShowLimit
          limitValue={pageSize}
          onLimitChange={(page) => {
            setPageSize(page!);
            setPageIndex(0);
          }}
          isSelectable={!isMobile}
          withoutActionOption
          onChangeSelectable={setSelected}
          selected={selected}
          isSearchable
          searchValue={search}
          onChangeSearch={setSearch}
          searchPlaceholder="Search on campaign template"
          renderEmptyMessage={() => 'No template found'}
          isColumnHeightFixed={isMobile || isTablet ? false : true}
          isFilterable
          renderFilterSection={() =>
            selected.length ? (
              <>
                <BBBButton
                  variant="danger-outline"
                  text={`Delete${
                    selected.length > 1 ? ` (${selected.length})` : ''
                  }`}
                  onClick={() =>
                    bulkAction({
                      onSuccess: () => {
                        setSelected([]);
                      },
                      action: 'delete',
                      selectedRows: selected.map((row) => row.id),
                    })
                  }
                  size="sm"
                />
                <BBBButton
                  variant="secondary"
                  text={`Duplicate${
                    selected.length > 1 ? ` (${selected.length})` : ''
                  }`}
                  onClick={() => {
                    if (selected.length > 5) {
                      toast.error(
                        `Maximum 5 templates can be duplicated at a time.`
                      );
                    } else {
                      bulkAction({
                        onSuccess: () => {
                          setSelected([]);
                        },
                        action: 'duplicate',
                        selectedRows: selected.map((row) => row.id),
                      });
                    }
                  }}
                  size="sm"
                />
              </>
            ) : (
              <>
                <BBBSelect
                  placeholder="Type"
                  containerClassName={isMobile ? 'col-span-4' : 'w-48'}
                  options={availableWhatsAppSources}
                  optionLabel="label"
                  optionValue="name"
                  optionIcon="icon"
                  value={source}
                  onValueChange={setSource}
                  enableToggleOption
                />
                <BBBSelect
                  placeholder="Status"
                  options={statusOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={status}
                  onValueChange={setStatus}
                />
              </>
            )
          }
          onClickRow={handleClickRow}
          disabledRow={isTemplateNoAction}
          isAsyncSort
          onClickSortHeader={(key, sort) => {
            setSort(`${key}:${sort}`);
          }}
        />
      </BBBContainer>
    </>
  );
}
