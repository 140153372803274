type Props = {
  mode?: 'default' | 'hover' | 'active';
};

function HomeIcon({ mode = 'default' }: Props) {
  return (
    <>
      {mode === 'default' ? (
        <HomeIconDefault />
      ) : mode === 'hover' ? (
        <HomeIconHover />
      ) : (
        <HomeIconActive />
      )}
    </>
  );
}

function HomeIconDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#404040"
        d="M5 22h14a2 2 0 002-2v-9a1 1 0 00-.29-.71l-8-8a1 1 0 00-1.41 0l-8 8A1 1 0 003 11v9a2 2 0 002 2zm5-2v-5h4v5h-4zm-5-8.59l7-7 7 7V20h-3v-5a2 2 0 00-2-2h-4a2 2 0 00-2 2v5H5v-8.59z"
      ></path>
    </svg>
  );
}

function HomeIconHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#404040"
        fillRule="evenodd"
        d="M19 22a2 2 0 002-2v-9a1 1 0 00-.29-.71l-8-8a1 1 0 00-1.41 0l-8 8A1 1 0 003 11v9a2 2 0 002 2h14zm-9-2v-5h4v7h-4v-2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
function HomeIconActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FD823E"
        d="M5 22h14a2 2 0 002-2v-9a1 1 0 00-.29-.71l-8-8a1 1 0 00-1.41 0l-8 8A1 1 0 003 11v9a2 2 0 002 2zm5-2v-5h4v5h-4zm-5-8.59l7-7 7 7V20h-3v-5a2 2 0 00-2-2h-4a2 2 0 00-2 2v5H5v-8.59z"
      ></path>
    </svg>
  );
}

export default HomeIcon;
