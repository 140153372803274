import { useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Info } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';
import BBBBarChart, { BBBBarChartProps } from './BBBBarChart';

import { BBBTooltip } from '@/components';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import { Paths } from '@/types/utils/nestedObjectPath';

type Option = {
  label: string;
  value: string;
};

type Props<T> = {
  options: Option[];
  xAxisDataKey: (activeTab: Option) => Paths<T>;
  yAxisDataKey?: (activeTab: Option) => Paths<T>;
  lineDataKey: (activeTab: Option) => Paths<T>;
  data: (activeTab: Option) => T[] | undefined;
};

export type BBBBarChartMultiTabProps<
  T extends {
    [k: string]: unknown;
  }
> = Omit<
  BBBBarChartProps<T>,
  'data' | 'lineDataKey' | 'xAxisProps' | 'yAxisProps'
> &
  Props<T>;

const BBBBarChartMultiTab = <
  T extends {
    [k: string]: unknown;
  }
>({
  data,
  xAxisDataKey,
  yAxisDataKey,
  lineDataKey,
  tooltip,
  ...props
}: BBBBarChartMultiTabProps<T>) => {
  const [showOptions, setShowOptions] = useState(false);
  const [activeTab, setActiveTab] = useState<Option>(props.options?.[0]);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideAlerter(ref, () => {
    setShowOptions(false);
  });

  const handleChangeTab = (option: Option) => {
    setShowOptions(false);
    setActiveTab(option);
    xAxisDataKey?.(activeTab);
    yAxisDataKey?.(activeTab);
    lineDataKey?.(activeTab);
    data?.(activeTab);
  };

  return (
    <BBBBarChart
      customHeaders={
        <div
          className="relative flex gap-1 items-center w-fit cursor-pointer"
          onClick={() => setShowOptions(!showOptions)}
        >
          <p className="text-base font-normal whitespace-nowrap">
            {activeTab.label}
          </p>
          {showOptions ? (
            <ChevronUp size={14} color="#262627" />
          ) : (
            <ChevronDown size={14} color="#262627" />
          )}
          <BBBTooltip
            show={!!tooltip}
            content={tooltip}
            className="bottom-[95%]"
          >
            <Info size={14} color="#9E9E9E" />
          </BBBTooltip>
          {showOptions && (
            <div
              ref={ref}
              className="absolute top-6 w-48 bg-neutral-10 overflow-clip rounded-lg transition-all shadow-md flex flex-col z-[999] border border-grayColor2"
            >
              {props.options?.map((option) => (
                <div
                  key={option.value}
                  className={cx(
                    'text-base text-primary-main hover:bg-secondary-surface px-4 py-2',
                    option.value === activeTab.value && 'bg-secondary-surface'
                  )}
                  onClick={() => handleChangeTab(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      }
      data={data?.(activeTab)}
      xAxisProps={{
        dataKey: xAxisDataKey?.(activeTab),
      }}
      yAxisProps={{
        dataKey: yAxisDataKey?.(activeTab),
      }}
      lineDataKey={lineDataKey?.(activeTab)}
      {...props}
    />
  );
};

export default BBBBarChartMultiTab;
