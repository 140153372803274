import { useEffect, useRef, useState } from 'react';
import { Calendar, ChevronDown, ChevronUp, Info } from 'react-feather';
import dayjs from 'dayjs';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { CurveType } from 'recharts/types/shape/Curve';
import { twMerge as cx } from 'tailwind-merge';
import BBBAnalyticsSelectRange from '../../SelectRange';

import { TagType } from '@/api/services/whatsApp/analytics';
import ArrowGraphicIcon from '@/assets/icons/ArrowGraphIcon';
import LineTypeIcon from '@/assets/icons/LineTypeIcon';
import SparklesIcon from '@/assets/icons/SparklesIcon';
import TagIcon from '@/assets/icons/TagIcon';
import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBGraphChart,
  BBBNavigatorTab,
  BBBOverviewBox,
  BBBPieChart,
  BBBSpinner,
  BBBStackedBarChart,
  BBBTooltip,
} from '@/components';
import { ChannelIntegrationValues } from '@/constants/integrations';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import {
  useAiTags,
  useAiTagsSuggested,
  useAiTagsUserDefined,
  useDownloadAiTags,
} from '@/hooks/whatsApp/analytics';
import { selectedDate } from '@/types/datePicker';
import { SettingsAITagging } from '@/types/whatsApp/settings';
import getRangeValue from '@/utils/common/date';

const chartOptions: {
  label: string;
  value: TagType;
}[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'User-defined',
    value: 'user-defined',
  },
];

export default function AiTags() {
  const query = useQuerySearchParams();

  const _startDate = query.get('startDate') || '';
  const _endDate = query.get('endDate') || '';
  const channel = query.get('channel') as ChannelIntegrationValues;

  if (!channel) throw new Error('Channel must be specified');

  const startDate = dayjs(_startDate).toDate();
  const endDate = dayjs(_endDate).toDate();

  const [selectedDay, setSelectedDay] = useState<selectedDate>(startDate);
  const [selectedEndDay, setSelectedEndDay] = useState<selectedDate>(endDate);
  const [activeRange, setActiveRange] = useState(false);

  const rangeValue = getRangeValue(selectedDay, selectedEndDay);

  const { mutate, isLoading: isMutating } = useDownloadAiTags({
    startDate: dayjs(selectedDay).toISOString(),
    endDate: dayjs(selectedEndDay).toISOString(),
    type: 'all',
    channel,
  });

  const props = {
    startDate: dayjs(selectedDay).toISOString(),
    endDate: dayjs(selectedEndDay).toISOString(),
    channel,
  };

  const toggleShowRange = () => {
    setActiveRange((prev) => !prev);
  };

  return (
    <BBBContainer
      hasHeading
      pageTitle="AI Tagging"
      pageDescription="Track your AI Tagging data"
      hasBack
      backUrl="/analytics?tab=bitchat"
      rightComponent={
        <div className="flex gap-4 items-center">
          <BBBButton
            text="Download CSV"
            variant="secondary"
            iconPosition="right"
            onClick={() => mutate()}
            loadingState={isMutating}
            size="sm"
          />
          <div className="relative cursor-pointer">
            <BBBCard onClick={toggleShowRange} className="!px-4 !py-[10px]">
              <div className="flex gap-3">
                <Calendar width={24} height={24} />
                <div className="font-bold text-primary-main">
                  {rangeValue?.label}
                </div>
              </div>
            </BBBCard>
            {activeRange && (
              <BBBAnalyticsSelectRange
                selectedDay={selectedDay}
                selectedEndDay={selectedEndDay}
                setSelectedDay={setSelectedDay}
                setSelectedEndDay={setSelectedEndDay}
                active={activeRange}
                onChangeActive={setActiveRange}
              />
            )}
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-5">
        <CombinedAiTags {...props} />
        <UserDefinedTags {...props} />
        <AiSuggestedTags {...props} />
      </div>
    </BBBContainer>
  );
}

function AiSuggestedTags({
  startDate,
  endDate,
  channel,
}: {
  startDate: string;
  endDate: string;
  channel: ChannelIntegrationValues;
}) {
  const { data, isLoading } = useAiTags({
    startDate,
    endDate,
    type: 'all',
    channel,
  });

  const { data: aiTags, fetchNextPage } = useAiTagsSuggested({
    startDate,
    endDate,
    channel,
  });

  const flattenedData = aiTags?.pages.flatMap((page) =>
    page.data?.map((item) => item)
  );

  const hasNextPage = aiTags?.pages.flatMap((item) => item.meta?.hasNextPage);
  const endCursor =
    flattenedData &&
    aiTags?.pages.flatMap((item) => item.meta?.endCursor)[
      flattenedData.length - 1
    ];

  return (
    <div className="flex flex-col gap-5 mt-4">
      <p className="text-xl text-primary-main">AI suggested tags</p>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="grid grid-cols-2 gap-4">
            <BBBOverviewBox
              title={
                <div className="flex items-center gap-1">
                  <SparklesIcon size={16} color="#8F68C0" />
                  <p className="text-primary-main">Total AI suggested tags</p>
                </div>
              }
              value={data?.totalAiGenerated.total}
            />
            <BBBOverviewBox
              title={
                <div className="flex items-center gap-1">
                  <SparklesIcon size={16} color="#8F68C0" />
                  <p className="text-primary-main">
                    Avg. AI suggested tags per day{' '}
                  </p>
                </div>
              }
              value={data?.averageAiSuggested}
            />
          </div>
          <BBBStackedBarChart
            title="Total of AI suggested tags"
            tooltip="This bar graph shows the number of tickets where AI automatically assigned each tag."
            data={flattenedData || []}
            isPaginated
            fetchNext={() =>
              hasNextPage &&
              fetchNextPage({
                pageParam: endCursor,
              })
            }
          />
        </>
      )}
    </div>
  );
}

const defaultTags: SettingsAITagging[] = [];

function UserDefinedTags({
  startDate,
  endDate,
  channel,
}: {
  startDate?: string;
  endDate?: string;
  channel: ChannelIntegrationValues;
}) {
  const settingsQuery = useSettings();

  const aiTags = settingsQuery.data?.aiTags || defaultTags;

  const [tabUserDefined, setTabUserDefined] = useState<SettingsAITagging>();

  const { data, isLoading } = useAiTagsUserDefined(
    tabUserDefined?.label ?? 'all',
    {
      startDate,
      endDate,
      type: 'user-defined',
      channel,
    },
    {
      enabled: true,
    }
  );

  const isHasUserDefinedTags = aiTags && aiTags?.length > 0;

  useEffect(() => {
    if (isHasUserDefinedTags) {
      setTabUserDefined(aiTags?.[0]);
    }
  }, [aiTags, isHasUserDefinedTags]);

  return (
    <div className="flex flex-col gap-5 mt-4">
      <p className="text-xl text-primary-main">User-defined Tags</p>
      <BBBNavigatorTab
        optionLabel="label"
        optionValue="id"
        navigationLists={aiTags}
        value={tabUserDefined}
        onChange={setTabUserDefined}
      />
      {isLoading ? (
        <Loading className="min-h-[50rem]" />
      ) : (
        <>
          <div className="grid grid-cols-3 gap-4">
            <BBBOverviewBox
              title={
                <div className="flex items-center gap-1">
                  <TagIcon size={16} />
                  <p className="text-primary-main">
                    Total &apos;{data?.name}&apos; Tags
                  </p>
                </div>
              }
              value={data?.total.total}
            />
            <BBBOverviewBox
              title={
                <div className="flex items-center gap-1">
                  <TagIcon size={16} />
                  <p className="text-primary-main">
                    Avg. &apos;{data?.name}&apos; tags per day
                  </p>
                </div>
              }
              value={data?.average || 0}
            />
            <BBBOverviewBox
              title={
                <div className="flex items-center gap-1">
                  <TagIcon size={16} />
                  <p className="text-primary-main">Currently active values</p>
                </div>
              }
              value={data?.currentlyActive}
            />
          </div>
          {data?.topTagsLabel?.length === 0 ? (
            <Empty
              title={`Top 5 tags values ${data?.name}`}
              tooltip="This line chart shows the trends in the 5 most frequent values within the user-defined tag."
            />
          ) : (
            <BBBGraphChart
              containerClassName="w-full col-span-3"
              title={`Top 5 tags values ${data?.name}`}
              tooltip="This line chart shows the trends in the 5 most frequent values within the user-defined tag."
              customHeader={(onKeyChange) => (
                <CustomHeaderTag
                  onKeyChange={onKeyChange}
                  analyticsKeys={data?.topTagsLabel || []}
                />
              )}
              data={data?.topTags || []}
              xAxisDataKey="date"
              isMultipleLine
              lineDataKey={data?.topTagsLabel || []}
              tooltipProps={{
                isCustomTooltip: true,
                className:
                  'bg-white p-2.5 border border-neutral-30 opacity-100',
                customContent: (payload) => (
                  <TooltipGraphChart
                    data={payload}
                    startDate={startDate || ''}
                  />
                ),
              }}
            />
          )}
          <div className="grid grid-cols-2 gap-4">
            {data?.valuesBreakdown?.length === 0 ? (
              <Empty
                title="Top 5 values"
                tooltip="This pie chart shows the percentage distribution of values within the user-defined tag. Here, you can see the proportion of tickets categorized using each specific value (e.g., Birthday, Wedding)."
              />
            ) : (
              <BBBPieChart
                title="Top 5 values"
                tooltip="This pie chart shows the percentage distribution of values within the user-defined tag. Here, you can see the proportion of tickets categorized using each specific value (e.g., Birthday, Wedding)."
                data={data?.valuesBreakdown}
                dataKey="value"
                renderLegend={(data, colors) => (
                  <div className="max-h-[10rem] overflow-auto flex flex-col gap-2">
                    {data?.map((entry, index) => (
                      <div
                        key={`item-${index}`}
                        className="flex items-center gap-2"
                      >
                        <div
                          className="w-5 h-5 rounded-md"
                          style={{
                            backgroundColor: colors[index % colors.length],
                          }}
                        />
                        <p className="text-primary-main">
                          {/* @ts-ignore */}
                          {entry.label} ({entry?.value})
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                tooltipProps={{
                  isCustomTooltip: true,
                  customContent: (payload) => (
                    <>
                      {payload?.[0]?.payload?.label} -{' '}
                      {`${payload?.[0]?.payload?.value}`}
                    </>
                  ),
                }}
              />
            )}
            <BBBStackedBarChart
              title="Total values"
              tooltip="This bar graph shows the raw number of tickets categorized with each value within the user-defined tag"
              data={data?.valuesBreakdown || []}
            />
          </div>
        </>
      )}
    </div>
  );
}

function CombinedAiTags({
  startDate,
  endDate,
  channel,
}: {
  startDate?: string;
  endDate?: string;
  channel: ChannelIntegrationValues;
}) {
  const optionRef = useRef<HTMLDivElement>(null);
  const [showChartOptions, setShowChartOptions] = useState(false);
  const [activeTab, setActiveTab] = useState<typeof chartOptions[number]>({
    label: 'All',
    value: 'all',
  });

  const { data: aiTagsAll, isLoading: isLoadingTagsAll } = useAiTags({
    startDate,
    endDate,
    type: 'all',
    channel,
  });

  const { data: aiTags, isLoading: isLoadingTags } = useAiTags({
    startDate,
    endDate,
    type: activeTab?.value,
    channel,
  });

  const isLoading = isLoadingTagsAll || isLoadingTags;

  const handleChangeTabChart = (option: typeof chartOptions[number]) => {
    setActiveTab(option);
    setShowChartOptions(false);
  };

  useOutsideAlerter(optionRef, () => {
    setShowChartOptions(false);
  });

  return isLoading ? (
    <div className="col-span-3">
      <Loading />
    </div>
  ) : (
    <>
      <div className="grid grid-cols-4 gap-4">
        <BBBOverviewBox
          title={
            <div className="flex items-center gap-1">
              <TagIcon size={16} />
              <p className="text-primary-main">Total tags generated (all)</p>
            </div>
          }
          tooltip={
            <p>
              This number represents the total of{' '}
              <span className="font-bold">both</span> user-defined tags and
              AI-suggested tags automatically assigned by AI.
            </p>
          }
          value={aiTagsAll?.totalGenerated.total}
        />
        <BBBOverviewBox
          title={
            <div className="flex items-center gap-1">
              <TagIcon size={16} />
              <p className="text-primary-main">Total user-defined tags</p>
            </div>
          }
          tooltip="Total number of user-defined tags. User-defined tags are the custom labels that you defined with intentions and values, and automatically assigned by AI to all tickets."
          value={aiTagsAll?.totalUserDefinedGenerated?.total}
        />
        <BBBOverviewBox
          title={
            <div className="flex items-center gap-1">
              <SparklesIcon size={16} color="#8F68C0" />
              <p className="text-primary-main">Total AI suggested tags</p>
            </div>
          }
          tooltip="Total number of tags automatically suggested by AI based on the content of analyzed chats. These tags are single words describing key topics or actions within the chat, and are not predefined by you."
          value={aiTagsAll?.totalAiGenerated?.total}
        />
        <BBBOverviewBox
          title="Avg. AI tags per day"
          value={aiTagsAll?.averageTotalGenerated}
        />
      </div>
      <BBBGraphChart
        containerClassName="w-full col-span-3"
        customTitle={
          <div
            className="relative flex gap-1 items-center w-fit cursor-pointer"
            onClick={() => setShowChartOptions(!showChartOptions)}
          >
            <p className="text-base font-normal whitespace-nowrap">
              {activeTab.label}
            </p>
            {showChartOptions ? (
              <ChevronUp size={14} color="#262627" />
            ) : (
              <ChevronDown size={14} color="#262627" />
            )}
            {showChartOptions && (
              <div
                ref={optionRef}
                className="absolute top-6 w-48 bg-neutral-10 overflow-clip rounded-lg transition-all shadow-md flex flex-col z-[999] border border-grayColor2"
              >
                {chartOptions?.map((option) => (
                  <div
                    key={option.value}
                    className={cx(
                      'text-base text-primary-main hover:bg-secondary-surface px-4 py-2',
                      option.value === activeTab.value && 'bg-secondary-surface'
                    )}
                    onClick={() => handleChangeTabChart(option)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        }
        tooltip="This chart shows the trends in your most frequent user-defined and AI-suggested tags over time."
        customHeader={(onKeyChange) => (
          <CustomHeaderTag
            onKeyChange={onKeyChange}
            analyticsKeys={aiTags?.topTagsLabel || []}
          />
        )}
        data={aiTags?.topTags || []}
        xAxisDataKey="date"
        isMultipleLine
        lineDataKey={aiTags?.topTagsLabel || []}
        tooltipProps={{
          isCustomTooltip: true,
          className: 'bg-white p-2.5 border border-neutral-30 opacity-100',
          customContent: (payload) => (
            <TooltipGraphChart data={payload} startDate={startDate || ''} />
          ),
        }}
      />
    </>
  );
}

function TooltipGraphChart({
  data,
  startDate,
}: {
  data: Payload<ValueType, NameType>[] | undefined;
  startDate: string;
}) {
  if (data?.length === 0) {
    return null;
  }

  return (
    <div className="min-w-[13rem] flex flex-col w-full gap-2 bg-neutral-10 p-4 shadow-md rounded-lg">
      <p className="text-xs text-neutral-40">
        {data?.[0].payload.date}, {dayjs(startDate).format('YYYY')}
      </p>
      {data
        // @ts-ignore
        ?.sort((a, b) => b.value - a.value)
        ?.map((item, idx) => (
          <div key={idx} className="flex justify-between gap-4 items-center">
            <div className="flex items-center gap-1">
              <LineTypeIcon
                color={item.color}
                isDashed={item.strokeDasharray === '5 5'}
              />
              <p className="text-sm text-primary-main">{item.name}</p>
            </div>
            <div className="flex items-center gap-2.5">
              <p className="text-sm text-primary-main">{item.value}</p>
              {item.payload.percentage !== 0 && (
                <div className="flex items-center gap-1">
                  <ArrowGraphicIcon isStonks={item.payload.percentage > 0} />
                  <p className="text-[0.625rem] text-neutral-40">
                    {item.payload.percentage}%
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

function CustomHeaderTag({
  onKeyChange,
  analyticsKeys,
}: {
  onKeyChange: (key: string | undefined) => void;
  analyticsKeys: {
    color: string;
    key: string;
    type: CurveType;
  }[];
}) {
  const [key, setKey] = useState<string>('');

  const handleHover = (key: string) => {
    setKey(key);
    onKeyChange?.(key);
  };

  const handleHoverLeave = () => {
    setKey('');
    onKeyChange?.('');
  };

  return (
    <div className="flex bg-neutral-20 rounded-lg px-2.5 py-1 items-center gap-4">
      {analyticsKeys.map((item) => (
        <div
          key={item.key}
          onMouseEnter={() => handleHover(item.key)}
          onMouseLeave={() => handleHoverLeave()}
          className={cx(
            'flex items-center gap-2 cursor-pointer',
            key === ''
              ? 'opacity-100'
              : item.key === key
              ? 'opacity-100'
              : 'opacity-50'
          )}
        >
          <LineTypeIcon color={item.color} isDashed={item.type === 'basis'} />
          <p className="text-neutral-70 text-xs whitespace-nowrap">
            {item.key}
          </p>
        </div>
      ))}
    </div>
  );
}

function Empty({ title, tooltip }: { title: string; tooltip?: string }) {
  return (
    <BBBCard
      title={
        tooltip ? (
          <div className="flex gap-1 items-center w-fit">
            <p className="text-base font-normal whitespace-nowrap">{title}</p>
            <BBBTooltip
              show={!!tooltip}
              content={tooltip}
              position="top"
              className="bottom-[95%]"
            >
              <Info size={14} color="#9E9E9E" />
            </BBBTooltip>
          </div>
        ) : (
          <p className="text-base font-normal whitespace-nowrap">{title}</p>
        )
      }
    >
      <div className="min-h-[20rem] h-full flex flex-col gap-1 items-center justify-center">
        <div className="text-primary-main font-medium">
          No sessions in this date range
        </div>
        <div className="text-neutral-50 text-sm">
          Try selecting a different date range
        </div>
      </div>
    </BBBCard>
  );
}

function Loading({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'min-h-[20rem] h-full flex flex-col gap-1 items-center justify-center',
        className
      )}
    >
      <BBBSpinner />
    </div>
  );
}
