import React from 'react';
import { Helmet } from 'react-helmet';
import { Control, Controller, useWatch } from 'react-hook-form';
import { AppearanceForm } from '../..';

import { BBBCard, BBBColorPicker, BBBSelect } from '@/components';
import optionFonts, {
  systemDefaultFonts,
} from '@/constants/bitLink/appearance/fonts';

type Props = {
  control: Control<AppearanceForm>;
};

function Font({ control }: Props) {
  const typography = useWatch({
    control,
    name: 'presetScope.typography',
  });

  const mappedFonts = optionFonts.map((option) => ({
    ...option,
    preview: (
      <div
        style={{
          fontFamily: option.value,
        }}
      >
        {option.label}
      </div>
    ),
  }));
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href={`https://fonts.googleapis.com/css2?${optionFonts
            .filter((option) => option.value !== systemDefaultFonts)
            .map((option) => `family=${encodeURIComponent(option.label)}`)
            .join('&')}&display=swap`}
          rel="stylesheet"
        />
      </Helmet>
      <BBBCard
        title={<p className="text-neutral-70 font-medium">Font</p>}
        className="mb-cardBottom"
      >
        <div className="w-full flex gap-6 items-center">
          <Controller
            control={control}
            name="presetScope.typography"
            render={({ field }) => (
              <BBBSelect
                options={mappedFonts}
                value={mappedFonts.find(
                  (option) =>
                    option.value === (typography || systemDefaultFonts)
                )}
                onValueChange={(opt) => field.onChange(opt?.value)}
                optionLabel="preview"
                optionValue="value"
                placeholder="Choose fonts"
                containerClassName="flex-1"
              />
            )}
          />

          <Controller
            control={control}
            name="presetScope"
            render={({ field }) => (
              <BBBColorPicker
                color={field.value.fontColorTitleBio || '#000000'}
                onChangeColor={(fontColorTitleBio) =>
                  field.onChange({
                    ...field.value,
                    fontColorTitleBio,
                  })
                }
                classNamePicker="-left-[calc(16em)]"
                label="Text color"
              />
            )}
          />
        </div>
      </BBBCard>
    </>
  );
}

export default Font;
