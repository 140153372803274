import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { toLower, upperFirst } from 'lodash-es';

import ChatIcon2 from '@/assets/icons/ChatIcon2';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import { MessageHistory } from '@/types/bitCRM/message-history';

const useColumn = () => {
  return useMemo<HeadersTypes<MessageHistory>>(() => {
    return [
      {
        accesor: 'destination',
        isSortable: true,
        renderHeader: () => 'Customer',
        columnWidth: '25%',
        render: (info) => (
          <p className="text-primary-main">{info.destination}</p>
        ),
      },
      {
        accesor: 'status',
        isSortable: true,
        renderHeader: () => 'Status',
        columnWidth: '15%',
        render: (info) => (
          <p className="text-primary-main">
            {upperFirst(toLower(info.status))}
          </p>
        ),
      },
      {
        accesor: 'sendAt',
        isSortable: true,
        renderHeader: () => 'Sent date',
        columnWidth: '30%',
        render: (info) => {
          return (
            <p className="text-primary-main">
              {dayjs(info.sendAt).format('dddd, MMM DD YYYY [at] hh:mm A')}
            </p>
          );
        },
      },
      {
        isAdditionalColumn: true,
        headerClassName: 'item-center justify-center',
        renderHeader: () => 'Action',
        headerAlign: 'center',
        columnWidth: '30%',
        render: (row) => (
          <Link
            to={`/bitchat/livechat?sources=${row.destinationSource}&chatId=${row.destination}`}
            className="w-fit flex items-center p-2 mx-auto justify-center border border-primary-main rounded"
          >
            <ChatIcon2 />
          </Link>
        ),
      },
    ];
  }, []);
};

export default useColumn;
