import React, { useMemo, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { BBBSelect } from '@/components';
import { env } from '@/config/env';
import useCollection from '@/hooks/bitApp/shopify/useCollection';
import useCollections from '@/hooks/bitApp/shopify/useCollections';
import ShopifyCollection from '@/types/shopify/ShopifyCollections';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';

type Props = {
  value: string | null | undefined;
  onChange: (val?: string | null) => void;
  onClick?: () => void;
  isMenuOpen?: boolean;
  isSearchable?: boolean;
  label: string | React.ReactNode;
  error?: string;
  className?: string;
};

function ShopifyCollectionListsOptions({
  value,
  onChange,
  onClick,
  label,
  error,
  isSearchable,
  isMenuOpen,
  className,
}: Props) {
  const [title, setTitle] = useState<string>();

  const memoizedParams = useMemo(
    () => ({
      title,
    }),
    [title]
  );

  const query = useCollections(memoizedParams);

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = query;

  const client = useQueryClient();

  const { data: collectionData, isInitialLoading: isLoadingCollection } =
    useCollection(value, undefined, {
      initialData: client
        .getQueryData<
          InfiniteData<ShopifyConnection<ShopifyCollection>> | undefined
        >(['bitapp-infinite-collections', memoizedParams])
        ?.pages.flatMap((page) => page.edges.map((edge) => edge.node))
        .find((data) => data.id === value),
    });

  const sorderCurrentPages = data?.pages.flatMap((page) =>
    page.edges.sort((a, b) =>
      a.node.title.localeCompare(b.node.title, 'en', {
        sensitivity: 'base',
      })
    )
  );

  const memoizedCurrentPages = useMemo(
    () =>
      sorderCurrentPages?.map((edge) => ({
        id: edge.node.id,
        title: <CollectionOption collection={edge.node} />,
      })),
    [sorderCurrentPages]
  );

  return (
    <BBBSelect
      containerClassName={className}
      label={label}
      options={memoizedCurrentPages}
      loading={isInitialLoading || isLoadingCollection}
      optionValue="id"
      optionLabel="title"
      isSearchable={isSearchable}
      search={title}
      onChangeSearch={setTitle}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      placeholder="Choose collection"
      //@ts-ignore
      value={collectionData}
      // @ts-ignore
      onValueChange={(val) => onChange(val?.id)}
      onClick={() => onClick?.()}
      isMenuOpen={isMenuOpen}
      error={error}
    />
  );
}

function CollectionOption({ collection }: { collection: ShopifyCollection }) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          collection.image
            ? collection?.image?.url
            : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={collection.id}
        loading="lazy"
      />
      <p className="text-sm text-primary-main">{collection.title}</p>
    </div>
  );
}

export default ShopifyCollectionListsOptions;
