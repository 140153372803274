import { useRef } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { FormSchema } from '../..';

import CopyIcon from '@/assets/icons/CopyIcon';
import { BBBCard } from '@/components/BBBCard';
import { env } from '@/config/env';
import { DeepPartial } from '@/types/utils/deepPartial';
import { formatPhonePayload } from '@/utils/common/phone';
import { toast } from '@/utils/common/toast';

export default function EmbedCode({
  control,
}: {
  control: Control<FormSchema>;
}) {
  const data = useWatch({ control });

  const embeddedJsRef = useRef<HTMLDivElement | null>(null);
  const embeddedCssRef = useRef<HTMLDivElement | null>(null);

  return (
    <BBBCard title="Embed code">
      <div className="bg-neutral-20 rounded-lg p-3 flex items-center justify-center gap-3">
        <div className="grow text-primary-main/50" ref={embeddedCssRef}>
          <span>
            <EmbeddedCss />
          </span>
        </div>
        <div className="flex">
          <CopyIcon
            onClick={() => {
              if (embeddedCssRef.current) {
                navigator.clipboard
                  .writeText(
                    (embeddedCssRef.current.childNodes[0] as HTMLSpanElement)
                      .textContent!
                  )
                  .then(() => {
                    toast.success('Copied to clipboard');
                  });
              }
            }}
            color="#262627"
            className="text-primary-main cursor-pointer"
          />
        </div>
      </div>
      <p className="text-primary-main mb-4">
        Copy and paste this code right after the {'<head>'} tag
      </p>
      <div className="bg-neutral-20 rounded-lg p-3 flex items-center justify-center gap-3">
        <div className="grow text-primary-main/50" ref={embeddedJsRef}>
          <span>
            <EmbeddedJs2 {...data} />
          </span>
        </div>
        <div className="flex">
          <CopyIcon
            onClick={() => {
              if (embeddedJsRef.current) {
                navigator.clipboard
                  .writeText(
                    (embeddedJsRef.current.childNodes[0] as HTMLSpanElement)
                      .textContent!
                  )
                  .then(() => {
                    toast.success('Copied to clipboard');
                  });
              }
            }}
            color="#262627"
            className="text-primary-main cursor-pointer"
          />
        </div>
      </div>
      <p className="text-primary-main">
        Copy and paste this code right before the start of the last element in
        the {'<body>'} tag
      </p>
    </BBBCard>
  );
}

const EmbeddedJs2 = (props: DeepPartial<FormSchema>) => {
  const config = {
    showQuickResponse: props.quickResponseEnabled,
    quickResponsesData: props.quickResponses!.filter(
      (quickResponse) => !!quickResponse!.chatbot
    ).length
      ? props
          .quickResponses!.filter((quickResponse) => !!quickResponse!.chatbot)
          .map((quickResponse) => ({
            label: quickResponse!.buttonText,
            href: `https://wa.me/${formatPhonePayload(
              props.whatsappPhone
            )}?text=${quickResponse!.chatbot!.keywords}`,
          }))
      : [],
    channelsData: [
      {
        channel: props.whatsappButtonText,
        number: props.whatsappPhone
          ? `https://wa.me/${formatPhonePayload(props.whatsappPhone)}?text=${
              props.whatsappPrefilledText
            }`
          : '',
      },
    ],
    themeColor: props.themeColor,
    showWidgetText: props.showWidgetText,
    widgetText: props.widgetText,
    radiusType: props.widgetButton!.radiusType, // rounded or circle
    widgetIconType: props.widgetButton!.iconType, // whatsapp or chat
    position: props.position, // right or left
    storeLogo: props.storeLogo ? props.storeLogo : undefined,
    storeName: props.storeName,
    storeDescription: props.storeDescription,
    size: props.size, // small or regular or large,
    showCard: props.showCard,
    whatsappPhone: formatPhonePayload(props.whatsappPhone),
    whatsappPrefilledText: props.whatsappPrefilledText,
  };

  return (
    <>
      {`<script src="${env.REACT_APP_CDN_URL}/bitbybit/static/bitChat_widget/widget.js"></script>`}
      <br />
      {`<script>`}
      <br />
      {`  BBBWidget.init(`}
      <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
        <p>{JSON.stringify(config, null, 2)}</p>
      </div>
      {`  );`}
      <br />
      {`</script>`}
    </>
  );
};

const EmbeddedCss = () => (
  <>{`<link rel="stylesheet" href="${env.REACT_APP_CDN_URL}/bitbybit/static/bitChat_widget/widget.css" />`}</>
);
