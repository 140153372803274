import { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import isEqual from 'lodash-es/isEqual';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';

import {
  BBBCard,
  BBBColorPicker,
  BBBImageUpload,
  BBBRichTextEditor,
  BBBTextInput,
} from '@/components';
import useBranding from '@/hooks/bitApp/design/useBranding';
import useCustomForm from '@/hooks/common/useCustomForm';
import { Branding } from '@/types/bitApp/v2';
import { convertHtmlToEditorState, emptyEditor } from '@/utils/common/rich';

type Props = {
  className?: string;
  title?: string;
  onDetectDiff?: (val: boolean, context: UseFormReturn<BrandingForm>) => void;
};

const schema = yup.object().shape({
  name: yup.string().required('App name is required'),
  appIcon: yup.string().required('App icon is required'),
  primaryColor: yup.string(),
  description: yup.mixed<EditorState>(),
  splashScreen: yup.string(),
  subtitle: yup.string(),
});

export type BrandingForm = Omit<Branding, 'description'> & {
  description: EditorState;
};

function BrandingCard({ className, title, onDetectDiff }: Props) {
  const { data: brandingData } = useBranding();

  const formContext = useCustomForm<BrandingForm>({
    defaultValues: {
      name: '',
      subtitle: '',
      description: emptyEditor,
      appIcon: null,
      splashScreen: null,
      primaryColor: undefined,
    },
    resolver: yupResolver(schema),
  });

  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = formContext;

  const serializedBrandingData = useMemo<Partial<BrandingForm>>(
    () => ({
      id: brandingData?.id || undefined,
      name: brandingData?.name || '',
      subtitle: brandingData?.subtitle || '',
      description: convertHtmlToEditorState(brandingData?.description || ''),
      appIcon: brandingData?.appIcon || null,
      splashScreen: brandingData?.splashScreen || null,
      primaryColor: brandingData?.primaryColor || '#000000',
    }),
    [brandingData]
  );

  useEffect(() => {
    reset(serializedBrandingData);
  }, [reset, serializedBrandingData]);

  useEffect(() => {
    const isFormEqual = isEqual(watch(), serializedBrandingData);
    onDetectDiff?.(isFormEqual, formContext);
  }, [formContext, onDetectDiff, serializedBrandingData, watch()]);

  return (
    <div id="design-branding" className={cx('flex flex-col gap-5', className)}>
      <BBBCard
        title={title || 'App Branding'}
        desc={'Use your own branding to personalize your app'}
      >
        <div className="my-5 flex flex-col gap-5">
          <Controller
            control={control}
            name="appIcon"
            render={({ field }) => (
              <BBBImageUpload
                label={
                  <p className="text-primary-main text-ls">
                    App icon <span className="text-danger-main">*</span>
                  </p>
                }
                imageUrl={field.value}
                onChangeImage={field.onChange}
                shape="square"
                accept="image/png"
                errors={errors.appIcon?.message}
                acceptedDimensions={{
                  width: 1024,
                  height: 1024,
                }}
                uploadOptions={{ original: true }}
              />
            )}
          />
          <BBBTextInput
            label={
              <p className="text-primary-main text-ls">
                App name <span className="text-danger-main">*</span>
              </p>
            }
            placeholder="Ex: ThisApp"
            isHookForm
            control={control}
            controlName="name"
            name="appName"
            error={errors.name?.message}
            containerClassname="mb-0"
          />
          <BBBTextInput
            label={<p className="text-primary-main text-ls">App subtitle</p>}
            placeholder="Ex: Intermitten-fasting tracker"
            name="appSubtitle"
            isHookForm
            control={control}
            controlName="subtitle"
            error={errors.subtitle?.message}
            containerClassname="mb-0"
          />
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <BBBRichTextEditor
                label={
                  <p className="text-primary-main text-ls">App description</p>
                }
                placeholder="Describe your app's features and function. Ex: ThisApp can help you to track your intermittent fasting schedule!"
                editorState={field.value}
                onChangeEditorState={(val) => field.onChange(val)}
                rows={2}
                error={errors.description?.message}
                containerClassName="mb-0"
              />
            )}
          />
          <div className="flex flex-col gap-2.5">
            <p className="text-primary-main text-ls">Branding color</p>
            <Controller
              control={control}
              name="primaryColor"
              render={({ field }) => (
                <BBBColorPicker
                  label="Primary color"
                  color={field.value || '#000000'}
                  onChangeColor={field.onChange}
                  width="60px"
                  height="60px"
                />
              )}
            />
          </div>
        </div>
      </BBBCard>
      <BBBCard
        title={<p className="text-xl text-primary-main">Splash screen</p>}
      >
        <Controller
          control={control}
          name="splashScreen"
          render={({ field }) => (
            <BBBImageUpload
              customPlaceholder={<ThumbnailSplashScreen />}
              imageUrl={field.value}
              imageClassName="rounded !w-[150px] !h-[233px]"
              onChangeImage={field.onChange}
              buttonText="Upload image"
              accept="image/png"
              containerClassName="mt-5"
              acceptedDimensions={{
                width: 1242,
                height: 2436,
              }}
              uploadOptions={{ original: true }}
            />
          )}
        />
      </BBBCard>
    </div>
  );
}

function ThumbnailSplashScreen() {
  return (
    <svg
      width="150"
      height="232"
      viewBox="0 0 150 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="150" height="232" rx="4" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="149"
        height="231"
        rx="3.5"
        stroke="#262627"
        strokeOpacity="0.5"
        strokeDasharray="8 8"
      />
      <path
        d="M99.2556 123.116L92.139 115.149L83.1546 105.086C82.8511 104.747 82.4699 104.473 82.0384 104.285C81.6069 104.097 81.1357 104 80.6586 104C80.1815 104 79.7104 104.097 79.2788 104.285C78.8473 104.473 78.4661 104.747 78.1626 105.086L69.1782 115.149L69.0671 115.271L65.915 111.739C65.6114 111.4 65.2302 111.126 64.7987 110.938C64.3672 110.75 63.896 110.653 63.4189 110.653C62.9419 110.653 62.4707 110.75 62.0392 110.938C61.6077 111.126 61.2265 111.4 60.9229 111.739L54.9102 118.476L50.7444 123.116C50.3523 123.552 50.103 124.082 50.0257 124.644C49.9484 125.206 50.0463 125.777 50.3079 126.29C50.5695 126.802 50.984 127.236 51.5028 127.538C52.0215 127.841 52.6231 128.001 53.2369 128H96.7631C97.3769 128.001 97.9785 127.841 98.4972 127.538C99.016 127.236 99.4305 126.802 99.6921 126.29C99.9537 125.777 100.052 125.206 99.9743 124.644C99.897 124.082 99.6477 123.552 99.2556 123.116Z"
        fill="#707070"
      />
    </svg>
  );
}

export default BrandingCard;
