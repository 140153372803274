import { ChevronLeft } from 'react-feather';
import { shallowEqual } from 'react-redux';

import { BBBButton } from '@/components';
import useBrandingQuery from '@/hooks/bitApp/design/useBranding';
import useCartSettingsTemplate from '@/hooks/bitApp/design/useCartSettingsTemplate';
import { useAppSelector } from '@/hooks/rtk/store';

export default function CartPage() {
  const cartSettings = useAppSelector(
    (state) => state.bitApp.cartSettings,
    shallowEqual
  );

  const { data: cartSettingsTemplate } = useCartSettingsTemplate();

  const { data } = useBrandingQuery();

  return (
    <div className="pt-3 h-full flex flex-col relative">
      <div className="relative mt-8">
        <ChevronLeft />
        <h6 className="absolute left-1/2 top-0 transform -translate-x-1/2">
          My Bag
        </h6>
      </div>
      <div className="absolute flex px-3 justify-center items-center flex-col gap-2 top-1/2 left-0 right-0 transform -translate-y-1/2">
        {cartSettings?.mode === 'CUSTOM' && (
          <img
            src={cartSettings?.image || ''}
            className="rounded-full w-[120px] h-[120px] object-cover"
            alt="cart-preview"
          />
        )}
        {cartSettings?.mode === 'TEMPLATE' && (
          <img
            src={
              cartSettingsTemplate?.find(
                (data) => data.id === cartSettings.emptyCartTemplateId
              )?.image
            }
            alt="template"
          />
        )}
        <h5
          className="text-center"
          style={{
            color: cartSettings?.isPrimaryColorApplied
              ? data?.primaryColor || undefined
              : cartSettings?.color || '#000000',
            opacity: 0.5,
          }}
        >
          {cartSettings?.message || 'Enter empty message'}
        </h5>
        <BBBButton
          text="Go shopping"
          className="pointer-events-none"
          backgroundColor={data?.primaryColor || undefined}
        />
      </div>
    </div>
  );
}
