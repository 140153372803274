import { useEffect } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { integrationsMeta, KnownIntegration } from 'constants/integrations';
import { useJubelioChannels } from 'hooks/bitCRM/automation/automation';
import { BBBModal, BBBSelect } from 'components';
import useStore from '../../../store';
import { TriggerData } from '../../../types';
import { Source } from './ActionModal';

type TriggerForm = {
  source: TriggerData['source'] | null;
  trigger: { label: string; event: string } | null;
  jubelioChannel: { label: string; value: number } | null;
};

const defaultValues: DefaultValues<TriggerForm> = {
  source: null,
  trigger: null,
  jubelioChannel: null,
};

const shownIntegrations: KnownIntegration[] = ['shopify', 'jubelio'];

export const shopifyTriggerOptions = [
  { label: 'Order Fulfilled', event: 'orders/fulfilled' },
  { label: 'Order Created', event: 'orders/create' },
  { label: 'Order Paid', event: 'orders/paid' },
  { label: 'Order Created (unpaid)', event: 'orders/unpaid' },
  { label: 'Abandoned Cart', event: 'carts/create' },
];

export const jubelioTriggerOptions = [
  {
    label: 'Order Created (Unpaid)',
    event: 'order/create',
  },
  {
    label: 'Order Paid',
    event: 'order/paid',
  },
  {
    label: 'Order Shipped',
    event: 'order/fulfilled',
  },
  {
    label: 'Order Completed',
    event: 'order/completed',
  },
];

export default function TriggerModal({
  onSave,
  onClose,
  nodeId,
}: {
  onSave: (val: TriggerData) => void;
  onClose: () => void;
  nodeId?: string;
}) {
  const { handleSubmit, control, reset, watch } = useForm<TriggerForm>({
    defaultValues,
  });

  const nodeData = useStore(
    (s) =>
      s.nodes.find((node) => node.id === nodeId)?.data as
        | TriggerData
        | undefined
  );
  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );

  useEffect(() => {
    if (nodeData) {
      reset({
        source: nodeData.source,
        trigger:
          !!nodeData.triggerName && !!nodeData.triggerValue
            ? { label: nodeData.triggerName, event: nodeData.triggerValue }
            : undefined,
        jubelioChannel:
          !!nodeData.jubelioChannelLabel && !!nodeData.jubelioChannelValue
            ? {
                label: nodeData.jubelioChannelLabel,
                value: nodeData.jubelioChannelValue,
              }
            : undefined,
      });
    }
  }, [nodeData, reset]);

  const source = watch('source');

  const { data: jubelioChannelsData } = useJubelioChannels({
    enabled: source === 'jubelio',
  });

  const triggerOptions =
    source === 'jubelio' ? jubelioTriggerOptions : shopifyTriggerOptions;

  return (
    <BBBModal
      show
      title="Trigger"
      footer
      submitText="Save"
      cancelText="Discard"
      onHide={() => onChangeTriggerModalState(null)}
      handleSave={() => {
        handleSubmit(({ source, trigger, jubelioChannel }) => {
          onSave({
            source: source!,
            triggerName: trigger?.label || null,
            triggerValue: trigger?.event || null,
            jubelioChannelLabel: jubelioChannel?.label || null,
            jubelioChannelValue: jubelioChannel?.value || null,
          });
          onClose();
        })();
      }}
      bodyClassName="px-2"
    >
      {!source ? (
        <Controller
          control={control}
          name="source"
          render={({ field: { onChange } }) => (
            <>
              {shownIntegrations.map((integration) => {
                const meta = integrationsMeta[integration];

                const Icon = meta.icon;

                return (
                  <Source
                    key={integration}
                    onClick={() => {
                      onChange(integration);
                    }}
                    Icon={Icon}
                    title={meta.title}
                  />
                );
              })}
            </>
          )}
        />
      ) : (
        <>
          <Source
            title={integrationsMeta[source].title}
            Icon={integrationsMeta[source].icon}
            withChange
            onChange={() => {
              reset(defaultValues);
            }}
            className="mb-5"
          />
          <div className="mx-3">
            {source === 'jubelio' && (
              <Controller
                control={control}
                name="jubelioChannel"
                render={({ field }) => (
                  <BBBSelect
                    options={jubelioChannelsData}
                    value={field.value}
                    onValueChange={field.onChange}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select store channel"
                    label="Jubelio store channel"
                    containerClassName="mb-5"
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="trigger"
              render={({ field }) => {
                return (
                  <BBBSelect
                    options={triggerOptions}
                    optionLabel="label"
                    optionValue="event"
                    value={field.value}
                    onValueChange={field.onChange}
                    label="Trigger"
                    placeholder="Select trigger"
                    containerClassName="mb-5"
                  />
                );
              }}
            />
          </div>
        </>
      )}
    </BBBModal>
  );
}
