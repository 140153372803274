import { Control, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '..';

import { BBBButton, BBBFooterAction } from '@/components';
import StickyFooter from '@/components/StickyFooter/StickyFooter';
import useResponsive from '@/hooks/common/useResponsive';
import { useDiscardOrLeaveModal } from '@/hooks/modal';

type Props = {
  control: Control<FormSchema>;
  dataFromApi: FormSchema;
  onSubmit: () => void;
  onSubmitDraft: (cb?: () => void) => void;
  loadingSave: boolean;
};

export default function Footer(props: Props) {
  const { control, dataFromApi, ..._props } = props;
  const watch = useWatch({
    control,
  });

  const isFormEqual = isEqual(watch, dataFromApi);

  const [handleDiscard] = useDiscardOrLeaveModal({
    isFormEqual,
    module: dataFromApi.id ? 'changes' : 'template',
    onSave: (hide, leaveCb) => {
      hide();
      props.onSubmitDraft?.(leaveCb);
    },
    loadingSave: props.loadingSave,
  });

  const isMobile = useResponsive('sm');

  return (
    <>
      {isMobile ? (
        <FooterMobile
          {...props}
          isFormEqual={isFormEqual}
          onDiscard={handleDiscard}
        />
      ) : (
        <FooterDesktop
          {...props}
          isFormEqual={isFormEqual}
          onDiscard={handleDiscard}
        />
      )}
    </>
  );
}

function FooterMobile({
  onDiscard,
  onSubmit,
  isFormEqual,
}: Props & {
  isFormEqual: boolean;
  onDiscard: () => void;
}) {
  const { id } = useParams<{ id: string }>();

  return (
    <StickyFooter>
      <div className="p-3 bg-white">
        <BBBFooterAction
          saveLabel={id === 'new' ? 'Save template' : 'Save changes'}
          discardLabel="Discard"
          onSave={onSubmit}
          onCancel={onDiscard}
          withoutDelete
          containerClassName="flex-col mt-0"
          disableSave={isFormEqual}
        />
      </div>
    </StickyFooter>
  );
}

function FooterDesktop({
  onDiscard,
  onSubmit,
  loadingSave,
  isFormEqual,
  onSubmitDraft,
}: Props & {
  isFormEqual: boolean;
  onDiscard: () => void;
}) {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="justify-end mt-4 gap-2 sm:flex hidden">
      <div id="template-footer" className="flex gap-2 items-center">
        <div
          className={cx(
            'underline cursor-pointer text-neutral-60',
            isFormEqual && 'opacity-50 pointer-events-none'
          )}
          onClick={() => onSubmitDraft()}
        >
          Save as draft
        </div>
        <BBBButton
          variant="secondary"
          text="Discard changes"
          onClick={onDiscard}
          disabled={isFormEqual}
        />
        <BBBButton
          variant="primary"
          loadingState={loadingSave}
          text={id === 'new' ? 'Save template' : 'Save changes'}
          disabled={isFormEqual}
          onClick={() => onSubmit?.()}
        />
      </div>
    </div>
  );
}
