import { useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '..';

import CalendarIcon from '@/assets/icons/CalendarIcon';
import { BBBDatePickerModal, BBBSelect } from '@/components';
import { BBBRadio } from '@/components/BBBRadio';
import { generateTimeOptions } from '@/constants/bitChat/settings';

type Props = {
  control: Control<FormSchema>;
};

const unitOptions = [
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
];

const typeOptions = [
  { label: 'Send immediately', value: 'immediately' },
  { label: 'Schedule send', value: 'schedule' },
];

export default function ScheduleMessage({ control }: Props) {
  const [showCalendar, setShowCalendar] = useState(false);

  const isScheduled = useWatch({
    control,
    name: 'isScheduled',
  });

  return (
    <>
      {showCalendar && (
        <Controller
          control={control}
          name="campaignSchedule2"
          render={({ field }) => {
            const selectedDay = dayjs(field.value).toDate();
            return (
              <BBBDatePickerModal
                selectedDay={selectedDay}
                onChangeDate={(date) =>
                  field.onChange(dayjs(date as Date).toISOString())
                }
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                showTimePicker={false}
              />
            );
          }}
        />
      )}
      <div className="mb-5">
        <div className={cx('flex gap-4', isScheduled && 'mb-5')}>
          <Controller
            control={control}
            name="isScheduled"
            render={({ field }) => (
              <BBBRadio
                options={typeOptions}
                value={!field.value ? 'immediately' : 'schedule'}
                onChange={(val) =>
                  val === 'immediately'
                    ? field.onChange(false)
                    : field.onChange(true)
                }
                labelClassName="text-base font-normal"
              />
            )}
          />
        </div>
        {isScheduled && (
          <div className="flex items-end gap-2">
            <Controller
              control={control}
              name="campaignSchedule2"
              render={({ field }) => (
                <div>
                  Select date and time
                  <div
                    onClick={() => {
                      setShowCalendar(true);
                    }}
                    className="px-4 py-2 w-44 cursor-pointer border rounded-lg border-neutral-30 flex items-center gap-2.5"
                  >
                    <div className="grow">
                      {dayjs(field.value).isTomorrow()
                        ? 'Tommorow'
                        : dayjs(field.value).isSame(dayjs(), 'day')
                        ? 'Today'
                        : dayjs(field.value).format('MM/DD/YYYY')}
                    </div>
                    <CalendarIcon />
                  </div>
                </div>
              )}
            />
            <Controller
              control={control}
              name="campaignSchedule2"
              render={({ field }) => {
                const campaignSchedule2Date = dayjs(field.value);
                const timeFormat = campaignSchedule2Date.format('hh:mm a');
                const [time, unit] = timeFormat.split(' ');

                const timeUnit = unitOptions.find((opt) => opt.value === unit);

                const timeValue = { label: time, value: time };

                return (
                  <>
                    <BBBSelect
                      options={generateTimeOptions()}
                      optionLabel="label"
                      optionValue="value"
                      isSearchable
                      value={timeValue}
                      onValueChange={(opt) => {
                        const [hour, minute] = opt!.value.split(':');

                        field.onChange(
                          dayjs(field.value)
                            .set('hour', parseInt(hour, 10))
                            .set('minute', parseInt(minute, 10))
                        );
                      }}
                    />
                    <BBBSelect
                      options={unitOptions}
                      optionLabel="label"
                      optionValue="value"
                      value={timeUnit}
                      onValueChange={(opt) => {
                        const hour = dayjs(field.value).get('hour');
                        field.onChange(
                          dayjs(field.value).set(
                            'hour',
                            opt?.value === 'am' ? hour - 12 : hour + 12
                          )
                        );
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
