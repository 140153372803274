import { ApiResponse } from 'api/types';
import api from 'config/api';
import ShopifyCollection from 'types/shopify/ShopifyCollections';
import ShopifyProducts from 'types/shopify/ShopifyProduct';

export default {
  getProducts: (companyId: number | undefined, params: GetProductsParams) =>
    api.bitCRM.v2
      .get<GetProductsPayload>(`/shopify/products/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  getProduct: (companyId: number | undefined, productId: number) =>
    api.bitCRM.v2
      .get<ApiResponse<ShopifyProducts>>(
        `/shopify/products/${companyId}/${productId}`
      )
      .then((res) => res.data.data),
  getCollections: (
    companyId: number | undefined,
    params: GetCollectionsParams
  ) =>
    api.bitCRM.v2
      .get<GetCollectionsPayload>(`/shopify/collections/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  getCollection: (companyId: number | undefined, collectionId: number) =>
    api.bitCRM.v2
      .get<ApiResponse<ShopifyCollection>>(
        `/shopify/collections/${companyId}/${collectionId}`
      )
      .then((res) => res.data.data),
};

export type GetProductsParams = {
  search?: string;
  after?: string;
  before?: string;
};
export type GetProductsPayload = {
  products: ShopifyProducts[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};
export type GetCollectionsParams = {
  search?: string;
  after?: string;
  before?: string;
};
export type GetCollectionsPayload = {
  collections: ShopifyCollection[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};
