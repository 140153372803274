import React, { isValidElement, ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import DraggableIcon from '@/assets/icons/BBBDraggableIcon';
import { BBBCard, BBBPrimarySwitch } from '@/components';

type Props = {
  id: string;
  index: number;
  logo: string | ReactNode;
  title: string | ReactNode;
  description?: string | ReactNode;
  isActive?: boolean;
  onChangeActive?: (val: boolean | undefined) => void;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export default function Link2({
  logo,
  title,
  description,
  onClick,
  isActive = false,
  onChangeActive,
  id,
  index,
}: Props) {
  return (
    <Draggable draggableId={`social-${id.toString()}`} index={index}>
      {(providedDraggable) => (
        <BBBCard
          className="flex gap-5 select-none items-center cursor-pointer !mb-4 !last:mb-0"
          onClick={onClick}
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          {logo}
          <div className="grow">
            {title && isValidElement(title) ? title : <div>{title}</div>}
            {description && isValidElement(description) ? (
              description
            ) : (
              <div>{description}</div>
            )}
          </div>
          <BBBPrimarySwitch
            onClick={(ev) => ev.stopPropagation()}
            checked={isActive}
            onChange={onChangeActive}
          />
          <div className="cursor-grab" {...providedDraggable.dragHandleProps}>
            <DraggableIcon />
          </div>
        </BBBCard>
      )}
    </Draggable>
  );
}
