import { ReactNode } from 'react';

import BBBChannelIcon from '@/assets/icons/BBBChannelIcon';
import FacebookIcon2 from '@/assets/icons/FacebookIcon2';
import GoogleMyBusinessIcon from '@/assets/icons/GoogleMyBusinessIcon';
import GoogleSheetsIcon from '@/assets/icons/GoogleSheetsIcon';
import InstagramIcon2 from '@/assets/icons/InstagramIcon2';
import ShopifyIcon from '@/assets/icons/ShopifyIcon';
import StampedIcon from '@/assets/icons/StampedIcon';
import WhatsAppCloudApiIcon from '@/assets/icons/WhatsAppCloudApiIcon';
import WhatsAppIcon4 from '@/assets/icons/WhatsAppIcon4';
import YotpoIcon from '@/assets/icons/YotpoIcon';
import { IntegrationName } from '@/types/bitChat/integrations';
import { AppType } from '@/types/systemLogin';
import { Chat } from '@/types/whatsApp/chat';

export type KnownIntegration =
  | 'whatsapp'
  | 'whatsapp_meta'
  | 'stamped'
  | 'yotpo'
  | 'instagram'
  | 'facebook'
  | 'shopify'
  | 'google_my_business'
  | 'app-notification'
  | 'sandbox-whatsapp'
  | 'google_sheets'
  | 'jubelio';

export const channelIntegrations = [
  'whatsapp_meta',
  'whatsapp',
  'instagram',
  'facebook',
] as const;

const specifiedChannelOrders = Array.from(channelIntegrations);
export const orderChannelMap = new Map(
  specifiedChannelOrders.map((item, index) => [item, index])
);

export const shownIntegrations = [...channelIntegrations] as const;

export const getOrderIndex = (name: IntegrationName) => {
  //@ts-ignore
  return orderChannelMap.get(name) ?? specifiedChannelOrders.length;
};

export const integrationsMeta: Record<
  KnownIntegration,
  {
    title: string;
    guides?: string[];
    description?: string | ReactNode;
    icon?: ({
      size,
    }: {
      size?: number | string | undefined;
      className?: string;
    }) => JSX.Element;
  }
> = {
  whatsapp: {
    guides: [
      'Open WhatsApp on your Phone',
      'Tap Menu or Settings and select Linked Devices',
      'Scan the QR code and wait for your WhatsApp to connect',
    ],
    description:
      'Simple, secure messaging, and calling for free. Easy connect with WhatsApp Business App',
    icon: WhatsAppIcon4,
    title: 'WhatsApp Business App',
  },
  whatsapp_meta: {
    description:
      'Leverage full feature on WhatsApp & broadcast promotion to your customer with WhatsApp Cloud API',
    icon: WhatsAppCloudApiIcon,
    title: 'WhatsApp Cloud API',
  },
  stamped: {
    guides: [
      'Go to your Shopify dashboard',
      'Open the Apps tab and select Stamped.io',
      'Open the Settings page of Stamped.io',
      'Navigate to the API Keys section, copy the API Key Public and Store Hash, and paste it below',
    ],
    description:
      'Capture review with Stamped. Leverage customer reviews to boost social proof and trust',
    icon: StampedIcon,
    title: 'Stamped',
  },
  yotpo: {
    guides: [
      'Go to your Shopify dashboard',
      'Open the Apps tab and select Yotpo',
      'Open the Store Settings page of Yotpo',
      'Navigate to the General Settings section, copy the App Key, and paste it below',
    ],
    description:
      'Capture review with Yotpo. Leverage customer reviews to boost social proof and trust',
    icon: YotpoIcon,
    title: 'Yotpo',
  },
  instagram: {
    description: 'Connect Instagram direct message to one dashboard',
    icon: InstagramIcon2,
    title: 'Instagram',
  },
  facebook: {
    description:
      'Instant messaging app and platform developed by Meta Platforms',
    icon: FacebookIcon2,
    title: 'Facebook',
  },
  shopify: {
    description:
      'No.1 eCommerce Platform for All Businesses. Start, Run + Grow Your Business with Shopify®. Sync customers and product to focus on the important',
    icon: ShopifyIcon,
    title: 'Shopify',
  },
  google_my_business: {
    description: 'Enhance local engagement with Google My Business',
    title: 'Google My Business',
    icon: GoogleMyBusinessIcon,
  },
  'app-notification': {
    icon: BBBChannelIcon,
    title: 'Mobile app push notification',
  },
  'sandbox-whatsapp': {
    icon: BBBChannelIcon,
    title: 'bitbybit sandbox (+1 415 936-4114)',
  },
  google_sheets: {
    icon: GoogleSheetsIcon,
    title: 'Google Sheets',
    description: 'Spreadsheet application included as part of the Google Docs',
  },
  jubelio: {
    title: 'Jubelio',
  },
};

export const channelIntegrationsOptions = [
  ...channelIntegrations.map((channel) => {
    const integrationData = integrationsMeta[channel];

    const Icon = integrationData.icon;

    return {
      label: integrationData.title,
      value: channel,
      label2: (
        <div className="flex items-center gap-2">
          {Icon && (
            <div className="flex-none">
              <Icon />
            </div>
          )}{' '}
          <span className="truncate font-medium">{integrationData.title}</span>
        </div>
      ),
    };
  }),
] as const;

export type ChannelIntegration = typeof channelIntegrationsOptions[number];
export type ChannelIntegrationValues = ChannelIntegration['value'];

export const mapKnownIntegrationsToSources: Partial<
  Record<KnownIntegration, Chat['sources']>
> = {
  facebook: 'FACEBOOK',
  instagram: 'INSTAGRAM',
  whatsapp: 'WHATSAPP',
  whatsapp_meta: 'WHATSAPP_META',
};

export const mapDescriptionIntegrationApp: Record<AppType, string> = {
  BITCHAT:
    'Seamlessly integrate Shopify customer data for streamlined communication',
  BITLINK:
    'Display Shopify collections and bring your store to social media in one link-in-bio',
  BITLOGIN:
    'Enable fast, secure logins with seamless integration of Shopify data',
  BITCRM:
    'Integrate Shopify data to humanize and optimize your customer relationships',
  BITAPP:
    'Connect your Shopify store to build and manage a custom mobile app effortlessly',
  BITAI:
    'Leverage Shopify data to automate and optimize your customer interactions',
};
