import { useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { AnalyticParams } from 'api/services/whatsApp/v3/analytics';
import { ChannelIntegrationValues } from 'constants/integrations';
import { useActiveCompany } from 'hooks/rtk/selector';

export const useTotalMessages = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-total-messages', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getTotalMessages(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useUniqueCustomers = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-unique-customers', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getUniqueCustomers(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useNewCustomers = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-new-customers', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getNewCustomers(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useTotalConversations = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-total-conversations', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getTotalConversations(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useTicketResolved = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-ticket-resolved', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getTicketResolved(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useTicketOpened = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-ticket-opened', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getTicketOpened(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useMessagePeakHours = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-message-peak-hours', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getMessagePeakHours(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useConversationPeakHours = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-conversation-peak-hours', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getConversationPeakHours(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useTopTicketCategories = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-top-ticket-categories', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getTopTicketCategories(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useTopAiTags = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-top-ai-tags', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getTopAiTags(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
    }
  );
};

export const useCustomerSatisfactions = (
  params: {
    startDate: string;
    endDate: string;
    channel?: ChannelIntegrationValues;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-customer-satisfactions', activeCompany, params],
    () =>
      services.whatsApp.v3.analytics.getCustomerSatisfactions(
        activeCompany,
        params as AnalyticParams
      ),
    {
      enabled:
        (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
        !!params.channel,
      select: (data) => {
        if (data.every(({ value }) => !value)) {
          return [];
        }

        return data.sort((a, b) => parseInt(b.label) - parseInt(a.label));
      },
    }
  );
};
