import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import UpsellCard from '../components/UpsellCard';
import Background from './components/Background';
import ButtonStyle from './components/ButtonStyle';
import Font from './components/Font';
import Journey from './components/Journey';
import Presets from './components/Presets';
import StoreProfile from './components/StoreProfile';

import { BBBContainer } from '@/components';
import BBBLogoMark from '@/components/BBBLogoMark/BBBLogoMark';
import useAppearance from '@/hooks/bitLink/appearance/useAppearance';
import useBranding from '@/hooks/bitLink/appearance/useBranding';
import useEditAppearance from '@/hooks/bitLink/appearance/useEditAppearance';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import { Appearances, Branding } from '@/types/bitLink/v2';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from '@/utils/common/rich';

export type AppearanceForm = (Partial<Omit<Branding, 'bio' | 'id'>> & {
  bio: EditorState;
}) &
  Partial<
    Omit<
      Appearances,
      | 'id'
      | 'bgImage'
      | 'presets'
      | 'bgColor'
      | 'btnBgColor'
      | 'btnStyle'
      | 'btnShadowColor'
      | 'btnFontColor'
      | 'fontColorTitleBio'
      | 'bgType'
      | 'typography'
      | 'bgColorBottom'
    >
  > & {
    presetScope: Pick<
      Appearances,
      | 'bgColor'
      | 'btnBgColor'
      | 'btnStyle'
      | 'btnShadowColor'
      | 'btnFontColor'
      | 'fontColorTitleBio'
      | 'bgType'
      | 'bgColorBottom'
      | 'typography'
    > & {
      bgImage: Nullable<FileType>;
    };
  };

const appearanceSchema = yup.object({
  logo: yup.mixed<Nullable<FileType>>()?.optional(),
  title: yup.string().optional(),
  bio: yup.mixed<EditorState>()?.optional(),
  bgImageOpacity: yup.number()?.nullable().optional(),
  presetScope: yup.object({
    bgColor: yup.string().optional(),
    btnStyle: yup.string()?.nullable().optional(),
    btnBgColor: yup.string()?.nullable().optional(),
    btnFontColor: yup.string()?.nullable().optional(),
    btnShadowColor: yup.string()?.nullable().optional(),
    fontColorTitleBio: yup.string()?.nullable().optional(),
    bgType: yup.string().optional(),
    bgImage: yup.mixed<Nullable<FileType>>().when('presetScope', {
      is: (presetScope: AppearanceForm['presetScope']) =>
        presetScope?.bgType === 'IMAGE',
      then: (rule) => rule.required('Image is required'),
    }),
    bgColorBottom: yup.string().optional(),
    typography: yup.string()?.nullable().optional(),
  }),
  mark: yup.boolean().optional(),
});

export const defaultAppearanceData: AppearanceForm = {
  logo: null,
  title: '',
  bio: emptyEditor,
  bgImageOpacity: 100,
  presetScope: {
    bgColor: '#DDDDDD',
    btnStyle: null,
    btnBgColor: null,
    btnFontColor: null,
    btnShadowColor: null,
    fontColorTitleBio: null,
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography: 'system-ui, sans-serif',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  mark: true,
};

export default function Appearance() {
  const { data, status } = useOnboarding('appearance');

  if (status === 'success' && data?.lastStep) {
    if (data?.lastStep < 5) return <_Appearance isNewUsers />;
  }

  return <_Appearance />;
}

function _Appearance({ isNewUsers = false }: { isNewUsers?: boolean }) {
  const { data: appearanceData, status: statusAppearance } = useAppearance();
  const { data: brandingData, status: statusBranding } = useBranding();

  const { mutate: editAppearance, isLoading: loadingEditAppearance } =
    useEditAppearance();

  const dataFromApi = useMemo<AppearanceForm>(
    () => ({
      logo: brandingData?.logo ?? defaultAppearanceData.logo,
      title: brandingData?.title ?? defaultAppearanceData.title,
      bio: brandingData?.bio
        ? convertHtmlToEditorState(brandingData?.bio)
        : defaultAppearanceData.bio,
      bgImageOpacity:
        appearanceData?.bgImageOpacity ?? defaultAppearanceData.bgImageOpacity,
      presetScope: {
        bgColor:
          appearanceData?.bgColor ?? defaultAppearanceData.presetScope.bgColor,
        btnStyle:
          appearanceData?.btnStyle ??
          defaultAppearanceData.presetScope.btnStyle,
        btnBgColor:
          appearanceData?.btnBgColor ??
          defaultAppearanceData.presetScope.btnBgColor,
        btnFontColor:
          appearanceData?.btnFontColor ??
          defaultAppearanceData.presetScope.btnFontColor,
        btnShadowColor:
          appearanceData?.btnShadowColor ??
          defaultAppearanceData.presetScope.btnShadowColor,
        fontColorTitleBio:
          appearanceData?.fontColorTitleBio ??
          defaultAppearanceData.presetScope.fontColorTitleBio,
        bgType:
          appearanceData?.bgType ?? defaultAppearanceData.presetScope.bgType,
        bgImage: appearanceData?.bgImage
          ? convertRemoteUrlToFileType(appearanceData.bgImage)
          : defaultAppearanceData.presetScope.bgImage,
        typography:
          appearanceData?.typography ??
          defaultAppearanceData.presetScope.typography,
        bgColorBottom:
          appearanceData?.bgColorBottom ??
          defaultAppearanceData.presetScope.bgColorBottom,
      },
      mark: appearanceData?.mark ?? defaultAppearanceData.mark,
    }),
    [
      appearanceData?.bgColor,
      appearanceData?.bgColorBottom,
      appearanceData?.bgImage,
      appearanceData?.bgImageOpacity,
      appearanceData?.bgType,
      appearanceData?.btnBgColor,
      appearanceData?.btnFontColor,
      appearanceData?.btnShadowColor,
      appearanceData?.btnStyle,
      appearanceData?.fontColorTitleBio,
      appearanceData?.mark,
      appearanceData?.typography,
      brandingData?.bio,
      brandingData?.logo,
      brandingData?.title,
    ]
  );

  const { watch, reset, control, handleSubmit, setValue } =
    useCustomForm<AppearanceForm>({
      resolver: yupResolver(appearanceSchema),
      defaultValues: defaultAppearanceData,
    });

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    function onSubmit(data: AppearanceForm) {
      const { title, bio, logo, presetScope, ...appearanceForm } = data;

      editAppearance({
        appearance: {
          id: appearanceData?.id,
          ...presetScope,
          ...appearanceForm,
          bgImage:
            typeof presetScope.bgImage !== undefined
              ? presetScope.bgImage?.remoteUrl
              : undefined,
          typography: presetScope.typography,
          bgColorBottom: presetScope.bgColorBottom,
        },
        branding: {
          id: brandingData?.id,
          title,
          bio: convertEditorStateToHtml(bio),
          logo,
        },
      });
    }

    if (statusAppearance === 'success' && statusBranding === 'success') {
      const isFormEqual = isEqual(dataFromApi, watch());

      toggle('appearance-form', {
        show: !isFormEqual,
        variant: loadingEditAppearance ? 'loading' : 'actionable',
        text: 'You have unsaved changes',
        cancelLabel: 'Discard changes',
        onCancel: reset,
        isCancelable: true,
        onAccept: () => handleSubmit(onSubmit)(),
      });
    }
  }, [
    appearanceData?.id,
    brandingData?.id,
    dataFromApi,
    editAppearance,
    handleSubmit,
    loadingEditAppearance,
    reset,
    statusAppearance,
    statusBranding,
    toggle,
    watch(),
  ]);

  return (
    <BBBContainer
      hasHeading
      pageTitle="Appearance"
      pageDescription="Customize appearance or choose a preset for a quick setup"
    >
      <Journey />
      <StoreProfile control={control} />
      <div id="personalize-appearance">
        <Presets control={control} />
        {!isNewUsers && <UpsellCard />}
        <Background control={control} setValue={setValue} />
        <ButtonStyle control={control} />
        <Font control={control} />
        <BBBLogoMark appType="BITLINK" />
      </div>
    </BBBContainer>
  );
}
