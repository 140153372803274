import { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import FacebookIcon from '@/assets/icons/FacebookIcon';
import GoogleRainbowIcon from '@/assets/icons/GoogleRainbowIcon';
import { BBBButton } from '@/components/BBBButton';
import { env } from '@/config/env';
import { SocialLoginPayload } from '@/hooks/auth/useLogin';
import { toast } from '@/utils/common/toast';

type Props = {
  onSubmit: (payload: SocialLoginPayload) => void;
  loading?: boolean;
  type?: 'login' | 'register';
};

function SocialLogin({ onSubmit, loading, type = 'login' }: Props) {
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoadingGoogle(true);
      try {
        const { data } = await axios.get<{
          email: string;
          email_verified: boolean;
          family_name: string;
          given_name: string;
          hd: string;
          locale: string;
          name: string;
          picture: string;
          sub: string;
        }>('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        onSubmit({
          email: data.email,
          provider: 'GOOGLE',
          firstName: data.given_name,
          lastName: data.family_name,
          profilePicture: data.picture,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingGoogle(false);
      }
    },
    onError: (errorResponse) => console.error(errorResponse),
  });

  return (
    <>
      <BBBButton
        text={`${type === 'login' ? 'Login' : 'Sign up'} with Google`}
        width="full"
        variant={null}
        onClick={() => loginGoogle()}
        className={'shadow-none font-normal mb-2'}
        icon={<GoogleRainbowIcon size={20} />}
        loadingState={loadingGoogle || loading}
      />
      <FacebookLogin
        appId={env.REACT_APP_FACEBOOK_APP_ID}
        callback={(userInfo) => {
          if ('id' in userInfo) {
            if (userInfo.email) {
              const name = userInfo.name?.split(' ') ?? [];
              onSubmit({
                email: userInfo.email,
                provider: 'FACEBOOK',
                profilePicture: userInfo.picture?.data.url,
                lastName: name[name.length - 1],
                firstName: name.slice(0, name.length - 1).join(' '),
              });
            } else {
              toast.error('Email is not available in this facebook acount.');
            }
          }
        }}
        fields="name,email,picture"
        render={(renderProps) => (
          <BBBButton
            text={`${type === 'login' ? 'Login' : 'Sign up'} with Facebook`}
            width="full"
            variant={null}
            onClick={renderProps.onClick}
            className="shadow-none border-transparent text-white bg-[#1877F2] font-normal mb-6"
            icon={<FacebookIcon size={20} />}
            loadingState={loading || renderProps.isProcessing}
            loadingColor="white"
          />
        )}
      />
      <div className="text-center mb-10">Or</div>
    </>
  );
}

export default SocialLogin;
