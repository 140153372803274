import { useParams } from 'react-router-dom';
import GeneralLayout from 'layouts/GeneralLayout';
import ExpiredIcon from '../../assets/icons/ExpiredIcon';
import InvitationRedirection from './InvitationRedirection';

import { BBBAlert, BBBSpinner } from '@/components';
import AuthCard from '@/components/Auth/Card';
import useInvitation from '@/hooks/auth/useInvitation';

function InvitationPage() {
  const { id } = useParams<{ id: string }>();

  const { data, isInitialLoading, error } = useInvitation(id);

  return (
    <GeneralLayout>
      {isInitialLoading ? (
        <BBBSpinner />
      ) : error ? (
        error?.response?.status === 400 ? (
          <AuthCard withoutLogo>
            <h1 className="text-[40px] font-medium text-primary-main mb-10">
              Your invitation was expired
            </h1>
            <ExpiredIcon className="mb-8" />
            <p className="text-base text-primary-main text-center mb-10">
              Please contact your team leader for a new invitation. The link is
              valid for 24 hours.
            </p>
          </AuthCard>
        ) : (
          <BBBAlert message={error.response?.data} type="danger" />
        )
      ) : (
        <InvitationRedirection data={data} id={id} />
      )}
    </GeneralLayout>
  );
}

export default InvitationPage;
