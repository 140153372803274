import { useRef, useState } from 'react';
import { ChevronLeft, MoreVertical } from 'react-feather';
import { shallowEqual } from 'react-redux';
import { twMerge as cx } from 'tailwind-merge';
import AgentInfo from '../../ChatPanel/ChatPreview/Chat/AgentInfo';
import ChatPreviewProfilePicture from '../../ChatPanel/ChatPreview/Chat/ProfilePicture';
import HandleTicket from '../../HandleTicket';
import AssignChat from './AssignChat';

import CommentIcon from '@/assets/icons/CommentIcon';
import LoveIcon from '@/assets/icons/LoveIcon';
import { BBBCard, BBBTooltip } from '@/components';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import useCustomerByPhoneNumber from '@/hooks/customers/customer/useCustomerByPhoneNumber';
import useInstagramInfo from '@/hooks/integrations/useInstagramInfo';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { useUpdateChat } from '@/hooks/whatsApp/chat';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import AddCustomerModal from '@/pages/Customers/AddCustomerModal';
import { ChatTicketHumanAgent } from '@/types/whatsApp/chat';
import { formatTicketAgentPayload } from '@/utils/chat';
import { formatDate2 } from '@/utils/common/date';
import { formatInternational } from '@/utils/common/phone';

type Props = {
  onEventChange?: (val: 'back' | 'customer-click') => void;
  expandedBoard?: boolean;
};

export default function HeaderMessagePanel({
  onEventChange,
  expandedBoard,
}: Props) {
  const selectedChat = useAppSelector((state) => !!state.bitCRM.selectedChat);

  const isMobile = useResponsive('sm');

  return (
    <div
      className="bg-white border-b border-transparent flex-none h-[84px]"
      id="message-panel-header"
    >
      <div className="flex items-center px-4 py-2 h-full">
        {selectedChat && (
          <>
            {isMobile && (
              <div className="flex-none">
                <ChevronLeft
                  className="mr-3"
                  onClick={() => onEventChange?.('back')}
                />
              </div>
            )}
            <CustomerProfile onEventChange={onEventChange} />
            <CustomerActions expandedBoard={expandedBoard} />
          </>
        )}
      </div>
    </div>
  );
}

function CustomerProfile(props: Pick<Props, 'onEventChange'>) {
  return <_CustomerProfileOnboarded {...props} />;
}

function _CustomerProfileOnboarded({
  onEventChange,
}: Pick<Props, 'onEventChange'>) {
  const isTablet = useResponsive('lg');
  const [showDetailModal, setShowDetailModal] = useState(false);

  const { data: customerData } = useCustomerByPhoneNumber();

  const selectedChat = useAppSelector((s) => {
    const _selectedChat = s.bitCRM.selectedChat!;
    return {
      clientNumber: _selectedChat.clientNumber,
      sources: _selectedChat.sources,
      clientName: _selectedChat.clientName,
      type: _selectedChat.type,
      lastMessageTimestamp: _selectedChat.lastMessageTimestamp,
    };
  }, shallowEqual);

  const isGroup = useIsGroupChat();

  const isTyping = useAppSelector(
    (s) =>
      s.bitCRM.liveChatMemo[
        `${selectedChat.clientNumber}-${selectedChat.sources}`
      ]?.isTyping || false
  );

  const { data: instagramInfo } = useInstagramInfo(selectedChat.clientNumber, {
    enabled: selectedChat.sources === 'INSTAGRAM',
  });

  return (
    <>
      {showDetailModal && customerData && (
        <AddCustomerModal
          show={showDetailModal}
          onClose={() => setShowDetailModal(false)}
          data={customerData}
        />
      )}

      <div
        className={cx(
          `flex items-center group grow gap-4 cursor-pointer`,
          !customerData && 'pointer-events-none'
        )}
        onClick={() => setShowDetailModal(true)}
      >
        {selectedChat && (
          <>
            <ChatPreviewProfilePicture {...selectedChat} />{' '}
            <div className="max-w-sm overflow-hidden flex gap-3 relative">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <CustomerName
                    profileName={formatInternational(
                      selectedChat.clientName || selectedChat.clientNumber
                    )}
                  />
                </div>
                {selectedChat.type === 'comment' && (
                  <div className="text-sm font-light flex items-center space-x-2 text-neutral-60">
                    <span>
                      {formatDate2(selectedChat.lastMessageTimestamp)}
                    </span>
                    <span className="flex items-center">
                      <LoveIcon
                        width={15}
                        height={15}
                        color="#404040"
                        className="mr-1"
                      />{' '}
                      {instagramInfo?.likeCount} Likes
                    </span>
                    <span className="flex items-center">
                      <CommentIcon
                        width={13}
                        height={13}
                        color="#404040"
                        className="mr-1"
                      />{' '}
                      {instagramInfo?.commentCount} Comments
                    </span>
                  </div>
                )}
                {selectedChat &&
                  (isTablet ? (
                    isTyping ? (
                      <span className="text-xs text-neutral-50 italic">
                        User is typing...
                      </span>
                    ) : (
                      <div
                        className="text-xs font-light line-clamp-1"
                        onClick={() => onEventChange?.('customer-click')}
                      >
                        Tap here to customer detail
                      </div>
                    )
                  ) : isGroup ||
                    selectedChat.clientName ===
                      selectedChat.clientNumber ? null : (
                    <div className="text-xs font-light">
                      {customerData?.customerTags
                        .map((customerTag) => customerTag.tagName)
                        .join(', ')}
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function CustomerName({ profileName }: { profileName: string }) {
  return (
    <div className="overflow-anywhere line-clamp-1 text-xl group-hover:underline transition-all font-semibold text-primary-main">
      <BBBTooltip show content={profileName}>
        {profileName}
      </BBBTooltip>
    </div>
  );
}

function CustomerActions(props: Pick<Props, 'expandedBoard'>) {
  return <_CustomerActionsOnboarded {...props} />;
}

function _CustomerActionsOnboarded({
  expandedBoard,
}: Pick<Props, 'expandedBoard'>) {
  const [showInfo, setShowInfo] = useState(false);
  const [showAssignOptions, setShowAssignOptions] = useState(false);

  const userId = useUserId();

  const { mutate: updateChat, isLoading: loadingUpdateChat } = useUpdateChat();

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const activeChat = useAppSelector((s) => {
    const selectedChat = s.bitCRM.selectedChat!;

    return {
      ticket: selectedChat.ticket,
      status: selectedChat.status,
    };
  }, shallowEqual);

  const destroyPopup = () => {
    setShowInfo((prev) => !prev);
    setShowAssignOptions(false);
  };

  useOutsideAlerter(dropdownRef, destroyPopup, true);

  return (
    <>
      <>
        {!activeChat.ticket?.userId ? (
          <HandleTicket
            className="self-center"
            ticket={activeChat.ticket || null}
            status={activeChat.status}
          />
        ) : (
          <div className="flex items-center relative px-3">
            <div className="flex-none mr-2 w-[9px] h-[9px] rounded-full bg-success-main" />
            <div className="mr-5 line-clamp-1">
              <AgentInfo
                agentId={activeChat.ticket.userId}
                accent={(activeChat.ticket as ChatTicketHumanAgent).agentAccent}
                displayName={
                  (activeChat.ticket as ChatTicketHumanAgent).agentName
                }
                profilePicture={
                  (activeChat.ticket as ChatTicketHumanAgent)
                    .agentProfilePicture
                }
                withoutImage
                textClassName="text-base"
              />
            </div>
            <div
              className="hover:bg-neutral-100 p-1 rounded-lg cursor-pointer transition-[background]"
              onClick={() => setShowInfo(true)}
            >
              <MoreVertical />
            </div>

            {showInfo && (
              <BBBCard
                ref={dropdownRef}
                className={cx(
                  `w-56 border-none absolute transition-opacity shadow top-full select-none right-0 z-10 p-0 md:p-0`,
                  loadingUpdateChat && 'pointer-events-none'
                )}
              >
                {activeChat.ticket.userId !== userId ? (
                  <div
                    className={cx('px-4 hover:bg-gray-100 py-1 cursor-pointer')}
                    onClick={() => {
                      updateChat(
                        {
                          status: 'took_over',
                          oldUser: formatTicketAgentPayload(activeChat.ticket!),
                        },
                        {
                          onSuccess: () => {
                            setShowInfo(false);
                          },
                        }
                      );
                    }}
                  >
                    Take over chat
                  </div>
                ) : (
                  <>
                    <div
                      className="px-4 flex mb-2 cursor-pointer items-center hover:bg-gray-100 rounded-tl-lg rounded-tr-lg py-1 relative"
                      onClick={() => setShowAssignOptions(true)}
                    >
                      <div className="whitespace-nowrap">Transfer ticket</div>
                      {showAssignOptions && (
                        <AssignChat
                          onSuccess={destroyPopup}
                          expandedBoard={expandedBoard}
                        />
                      )}
                    </div>
                    <div
                      className="px-4 hover:bg-gray-100 py-1 mb-2 cursor-pointer"
                      onClick={() => {
                        updateChat(
                          { status: 'unread' },
                          {
                            onSuccess: () => {
                              setShowInfo(false);
                            },
                          }
                        );
                      }}
                    >
                      Mark as unread
                    </div>
                    <div
                      className={cx(
                        'px-4 hover:bg-gray-100 py-1 mb-2 cursor-pointer'
                      )}
                      onClick={() => {
                        updateChat(
                          { status: 'spam' },
                          {
                            onSuccess: () => {
                              setShowInfo(false);
                            },
                          }
                        );
                      }}
                    >
                      Mark as spam
                    </div>
                    <div
                      className={cx(
                        'px-4 hover:bg-gray-100 rounded-bl-lg rounded-br-lg py-1 text-red-500 cursor-pointer'
                      )}
                      onClick={() => {
                        updateChat(
                          { status: 'close' },
                          {
                            onSuccess: () => {
                              setShowInfo(false);
                            },
                          }
                        );
                      }}
                    >
                      Resolve ticket
                    </div>
                  </>
                )}
              </BBBCard>
            )}
          </div>
        )}
      </>
    </>
  );
}
