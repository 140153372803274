import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { BBBCropImageModal } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setCropImageModal } from '@/stores/common';

export default function GlobalCropImageModal() {
  const cropImageModal = useAppSelector(
    (state) => state.common.cropImageModal,
    shallowEqual
  );
  const dispatch = useAppDispatch();
  const [upImg, setUpImg] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const file = cropImageModal?.file;

    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpImg(reader.result as string);
        setShowModal(true);
      });
      reader.readAsDataURL(file);
    }
  }, [cropImageModal?.file, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setCropImageModal(null));
    };
  }, [dispatch]);

  if (!showModal || !cropImageModal) return null;

  return (
    <BBBCropImageModal
      show={showModal}
      setShow={setShowModal}
      upImg={upImg}
      fileName={cropImageModal.file.name}
      {...cropImageModal}
    />
  );
}
