import BBBUpsellCard from '@/components/BBBUpsellCard/BBBUpsellCard';

function UpsellCard() {
  return (
    <BBBUpsellCard
      title="Make your link professional without bitbybit logo"
      description="With paid plan, you can remove bitLink by bitbybit logo from your link
    dashboard, making it more polished & professional. Start from $4.95!"
      appType="BITLINK"
      id="bitbybit-logo"
    />
  );
}

export default UpsellCard;
