import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { textColorOptions } from 'constants/bitChat/waWidget';
import { useWhatsappBusinessIntegration } from 'hooks/bitChat/integration/integrations';
import useEditWaWidget from 'hooks/bitChat/waWidget/useEditWaWidget';
import useWidget from 'hooks/bitChat/waWidget/useWidget';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useCustomForm from 'hooks/common/useCustomForm';
import useDefaultCountryCode from 'hooks/common/useDefaultCountryCode';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import { phoneValidator } from 'utils/common/phone';
import Appearance from './components/Appearance';
import ButtonWidget from './components/ButtonWidget';
import EmbedCode from './components/EmbedCode';
import HideWidget from './components/HideWidget';
import QuickResponses from './components/QuickResponse';
import WhatsAppNumber from './components/WhatsAppNumber';
import Wrapper from './components/Wrapper';

import { BBBTelInputValue } from '@/components';
import useResponsive from '@/hooks/common/useResponsive';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import DesktopOptimized from '@/layouts/DesktopOptimized';
import { AutoReply } from '@/types/bitChat/chatbot';
import {
  WaWidget2 as WaWidgetType,
  WidgetIconType,
  WidgetRadiusType,
  WidgetTextColor,
} from '@/types/bitChat/waWidget';
import { formatPhonePayload } from '@/utils/common/phone';

export type QuickResponseForm = {
  buttonText: string;
  chatbot: AutoReply | Pick<AutoReply, 'id' | 'keywords' | 'groupName'> | null;
};

export type FormSchema = Pick<
  WaWidgetType,
  | 'whatsappButtonText'
  | 'whatsappPrefilledText'
  | 'showWidgetText'
  | 'widgetText'
  | 'themeColor'
  | 'position'
  | 'size'
  | 'storeName'
  | 'storeDescription'
  | 'storeLogo'
  | 'showCard'
  | 'quickResponseEnabled'
> & {
  widgetButton: Pick<WaWidgetType, 'iconType' | 'radiusType'>;
  whatsappPhone: BBBTelInputValue | null;
  textColor: { label: string; value: WidgetTextColor } | null;
  hiddenPage: {
    label: string;
    label2: string;
    value: string;
    parentValue?: string;
  }[];
  quickResponses: QuickResponseForm[];
};

export const defaultValues: FormSchema = {
  whatsappPhone: null,
  whatsappButtonText: '',
  whatsappPrefilledText: '',
  quickResponses: [{ buttonText: '', chatbot: null }],
  showWidgetText: false,
  widgetText: '',
  themeColor: '#008069',
  position: 'right',
  size: 'regular',
  storeName: '',
  storeDescription: '',
  storeLogo: '',
  showCard: true,
  widgetButton: {
    iconType: 'whatsapp',
    radiusType: 'rounded',
  },
  quickResponseEnabled: false,
  textColor: null,
  hiddenPage: [],
};

const schema = yup.object().shape({
  enable: yup.boolean(),
  whatsappPhone: phoneValidator(),
  whatsappButtonText: yup.string().required('This field cannot be empty'),
  whatsappPrefilledText: yup.string().required('This field cannot be empty'),
  quickResponseEnabled: yup.boolean(),
  quickResponses: yup.array().when('quickResponseEnabled', {
    is: true,
    then: (rule) =>
      rule.of(
        yup.object().shape({
          buttonText: yup
            .string()
            .required('Trigger button text cannot be empty'),
          chatbot: yup.mixed<AutoReply>().required().label('Chatbot'),
        })
      ),
  }),
  storeLogo: yup.string(),
  storeName: yup.string(),
  storeDescription: yup.string(),
  themeColor: yup.string().required('This field cannot be empty'),
  textColor: yup.object().required('This field cannot be empty'),
  position: yup.string().required('This field cannot be empty'),
  showWidgetText: yup.boolean(),
  widgetText: yup.string(),
  widgetButton: yup.object().shape({
    iconType: yup
      .mixed<WidgetIconType>()
      .required('This field cannot be empty'),
    radiusType: yup
      .mixed<WidgetRadiusType>()
      .required('This field cannot be empty'),
  }),
  hiddenPage: yup.mixed<FormSchema['hiddenPage']>(),
});

export default function WaWidget() {
  const isMobile = useResponsive('sm');

  if (isMobile) {
    return (
      <DesktopOptimized
        backLink="/home"
        description="Chat widget is more optimized in desktop website. Switch over to be able to set up your chat widget."
      />
    );
  }

  return <_WaWidget />;
}

function _WaWidget() {
  const { data, isInitialLoading: loadingWidget } = useWidget();
  const { mutate: updateWidget, isLoading: loadingEdit } = useEditWaWidget();
  const { toggle } = useConfirmationBanner();

  const { data: whatsAppIntegration, isInitialLoading: loadingIntegrations } =
    useWhatsappBusinessIntegration();

  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITCHAT');

  const loading = loadingWidget || loadingIntegrations;

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useCustomForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const phoneNumberWhatsApp = useDefaultCountryCode(
    data?.whatsappPhone || whatsAppIntegration?.extra?.number
  );

  const dataFromApi = useMemo<Partial<FormSchema>>(
    () => ({
      id: data?.id,
      whatsappPhone: phoneNumberWhatsApp || defaultValues.whatsappPhone,
      whatsappButtonText:
        data?.whatsappButtonText || defaultValues.whatsappButtonText,
      whatsappPrefilledText:
        data?.whatsappPrefilledText || defaultValues.whatsappPrefilledText,
      quickResponseEnabled:
        data?.quickResponseEnabled || defaultValues.quickResponseEnabled,
      quickResponses: data?.quickResponses?.length
        ? data.quickResponses.map(({ buttonText, ...data }) => ({
            buttonText: buttonText,
            chatbot: {
              id: data.chatbotId,
              groupName: data.chatbotName,
              keywords: [data.chatbotMsg],
            },
          }))
        : defaultValues.quickResponses,
      storeLogo: data?.storeLogo || defaultValues.storeLogo,
      storeDescription:
        data?.storeDescription || defaultValues.storeDescription,
      showWidgetText: data?.showWidgetText || defaultValues.showWidgetText,
      widgetText: data?.widgetText || defaultValues.widgetText,
      textColor:
        textColorOptions.find(
          (opt) => opt.value === (data?.textColor || defaultValues.textColor)
        ) || null,
      themeColor: data?.themeColor || defaultValues.themeColor,
      position: data?.position || defaultValues.position,
      widgetButton: {
        iconType: data?.iconType || defaultValues.widgetButton.iconType,
        radiusType: data?.radiusType || defaultValues.widgetButton.radiusType,
      },
      size: data?.size || defaultValues.size,
      hiddenPage:
        data?.hiddenPage?.map((_page) => ({
          label: _page,
          label2: _page,
          value: _page.split(': ')[1],
          parentValue: _page.startsWith('Mobile:') ? 'Mobile' : 'Desktop',
        })) || defaultValues.hiddenPage,
      storeName: data?.storeName || defaultValues.storeName,
      showCard: data?.showCard,
    }),
    [
      data?.hiddenPage,
      data?.iconType,
      data?.id,
      data?.position,
      data?.quickResponseEnabled,
      data?.quickResponses,
      data?.radiusType,
      data?.showCard,
      data?.showWidgetText,
      data?.size,
      data?.storeDescription,
      data?.storeLogo,
      data?.storeName,
      data?.textColor,
      data?.themeColor,
      data?.whatsappButtonText,
      data?.whatsappPrefilledText,
      data?.widgetText,
      phoneNumberWhatsApp,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  useEffect(() => {
    function onSubmit({
      widgetButton,
      whatsappPhone,
      textColor,
      hiddenPage,
      quickResponses,
      ...data
    }: FormSchema) {
      updateWidget({
        ...data,
        iconType: widgetButton.iconType,
        radiusType: widgetButton.radiusType,
        whatsappPhone: formatPhonePayload(whatsappPhone),
        textColor: textColor?.value,
        hiddenPage: hiddenPage.map(
          (_page) => `${_page.parentValue}: ${_page.value}`
        ),
        quickResponses: quickResponses
          .filter((quickResponse) => !!quickResponse.chatbot)
          .map((quickResponse) => ({
            buttonText: quickResponse.buttonText,
            chatbotId: quickResponse.chatbot!.id,
            chatbotMsg: quickResponse.chatbot!.keywords[0],
            chatbotName: quickResponse.chatbot!.groupName,
          })),
        shopifyDomain: shopifyIntegration?.domain,
      });
    }

    const isFormEqual = isEqual(dataFromApi, watch());

    toggle('wa-widget-form', {
      show: !isFormEqual,
      variant: loadingWidget || loadingEdit ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: () => {
        reset(dataFromApi);
      },
      isCancelable: true,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    updateWidget,
    handleSubmit,
    loadingEdit,
    loadingWidget,
    reset,
    toggle,
    watch(),
    shopifyIntegration?.domain,
  ]);

  return (
    <Wrapper
      title="Chat Widget Settings"
      description="Enhance customer engagement by adding chat widget to your store."
      control={control}
    >
      <WhatsAppNumber
        error={errors}
        control={control}
        loading={loading}
        setValue={setValue}
      />
      <QuickResponses control={control} loading={loading} />
      <ButtonWidget control={control} error={errors} loading={loading} />
      <Appearance control={control} error={errors} loading={loading} />
      <HideWidget control={control} error={errors} loading={loading} />
      {!shopifyIntegration && <EmbedCode control={control} />}
    </Wrapper>
  );
}
