import { useEffect, useMemo, useRef } from 'react';
import {
  Control,
  Controller,
  useForm,
  UseFormReset,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore from '../../../store';
import { ConditionData, ConditionForm, conditionSchema } from '../../../types';

import { BBBModal, BBBSelect, BBBTextInput } from '@/components';
import {
  dataFieldEntries,
  dataFieldOptions,
  runIfOptionsAll,
} from '@/constants/bitCRM/automation-new';
import {
  currencies,
  currencyOptions,
  formatCurrency,
} from '@/constants/common/currency';
import { isSubset } from '@/utils/common/object';

const defaultValues: ConditionForm = {
  dataField: null,
  runIf: null,
  currency: null,
  totalPrice: '',
  totalProductVariant: '',
  totalQuantity: '',
  delay: '',
  starsTreshold: '',
};

export default function ConditionModal({
  onSave,
  onClose,
  edgeId,
}: {
  onSave: (val: ConditionData) => void;
  onClose: () => void;
  edgeId?: string;
}) {
  const edgeData = useStore<ConditionData | undefined>(
    (s) => s.edges.find((edge) => edge.id === edgeId)?.data
  );

  const { handleSubmit, control, reset } = useForm<ConditionForm>({
    defaultValues,
    resolver: yupResolver(conditionSchema),
  });

  useEffect(() => {
    if (edgeData) {
      reset({
        dataField: edgeData.dataField || null,
        runIf: edgeData.runIf || null,
        //@ts-ignore
        currency: edgeData.currency ? currencies[edgeData.currency] : null,
        totalPrice: edgeData.totalPrice ? edgeData.totalPrice.toString() : '',
        totalProductVariant: edgeData.totalProductVariant
          ? edgeData.totalProductVariant.toString()
          : '',
        totalQuantity: edgeData.totalQuantity
          ? edgeData.totalQuantity.toString()
          : '',
        delay: edgeData.delay ? edgeData.delay.toString() : '',
        starsTreshold: edgeData.starsTreshold
          ? edgeData.starsTreshold.toString()
          : '',
      });
    }
  }, [edgeData, reset]);

  return (
    <BBBModal
      show
      onHide={onClose}
      title="Condition"
      footer
      submitText="Save"
      cancelText="Cancel"
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            dataField:
              typeof data.dataField === 'object'
                ? data.dataField!.value
                : data.dataField,
            runIf:
              typeof data.runIf === 'object' ? data.runIf!.value : data.runIf,
            currency: data.currency?.code || null,
            totalPrice: data.totalPrice ? Number(data.totalPrice) : null,
            totalProductVariant: data.totalProductVariant
              ? Number(data.totalProductVariant)
              : null,
            totalQuantity: data.totalQuantity
              ? Number(data.totalQuantity)
              : null,
            delay: data.delay ? Number(data.delay) : null,
            starsTreshold: data.starsTreshold
              ? Number(data.starsTreshold)
              : null,
          });
          onClose();
        })();
      }}
    >
      <DataFieldOptions control={control} edgeId={edgeId} reset={reset} />
      <RunIfOptions control={control} />
      <DynamicInput control={control} />
    </BBBModal>
  );
}

function RunIfOptions({ control }: { control: Control<ConditionForm, any> }) {
  const _dataField = useWatch({ control, name: 'dataField' });
  const dataField = _dataField
    ? typeof _dataField === 'object'
      ? _dataField.value
      : _dataField
    : null;

  const runIfOptions = useMemo(
    () => (dataField ? runIfOptionsAll[dataField] : []),
    [dataField]
  );

  const { errors } = useFormState({ control });

  return (
    <Controller
      control={control}
      name="runIf"
      render={({ field }) => {
        const value = field.value
          ? typeof field.value === 'object'
            ? field.value
            : runIfOptions.find((opt) => opt.value === field.value)
          : null;

        return (
          <BBBSelect
            options={runIfOptions}
            optionLabel="label"
            optionValue="value"
            label="Run this path if"
            placeholder="Select condition"
            value={value}
            onValueChange={field.onChange}
            isDisabled={!dataField}
            error={errors.runIf?.message}
          />
        );
      }}
    />
  );
}

function DataFieldOptions({
  control,
  edgeId,
  reset,
}: {
  control: Control<ConditionForm, any>;
  edgeId?: string;
  reset: UseFormReset<ConditionForm>;
}) {
  const matchingDataFieldOptions = useStore((s) => {
    const source = s.edges.find((edge) => edge.id === edgeId)!.source;

    let pointerNode = s.nodes.find(
      (node) =>
        node.id === s.edges.find((edge) => edge.target === source)!.source
    );

    const whitelistedDataFields: string[] = [];

    while (pointerNode && pointerNode.type !== 'conditions') {
      const subsetMatch = dataFieldEntries.find((entry) =>
        isSubset(entry[1], pointerNode)
      );

      if (subsetMatch) {
        whitelistedDataFields.push(subsetMatch[0]);
      }

      const previous = s.nodes.find(
        (node) =>
          node.id ===
          s.edges.find((edge) => edge.target === pointerNode!.id)?.source
      );
      pointerNode = previous;
    }

    return dataFieldOptions.filter((opt) =>
      whitelistedDataFields.includes(opt.value)
    );
  });

  const { errors } = useFormState({ control });

  return (
    <Controller
      control={control}
      name="dataField"
      render={({ field }) => {
        const value = field.value
          ? typeof field.value === 'object'
            ? field.value
            : matchingDataFieldOptions.find((opt) => opt.value === field.value)
          : null;

        return (
          <BBBSelect
            options={matchingDataFieldOptions}
            optionLabel="label"
            optionValue="value"
            label="Data field"
            placeholder="Select data field"
            containerClassName="mb-5"
            value={value}
            onValueChange={(opt) => {
              reset({
                ...defaultValues,
                dataField: opt,
              });
            }}
            error={errors.dataField?.message}
          />
        );
      }}
    />
  );
}

function DynamicInput({ control }: { control: Control<ConditionForm, any> }) {
  const _runIf = useWatch({ control, name: 'runIf' });
  const currency = useWatch({ control, name: 'currency' });

  const runIf = _runIf
    ? typeof _runIf === 'object'
      ? _runIf.value
      : _runIf
    : null;

  const { errors } = useFormState({ control });

  if (!runIf) return null;

  return (
    <>
      {(runIf === 'product_not_purchased' ||
        runIf === 'review_not_submitted') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Until X hours"
          placeholder="Input X value"
          isHookForm
          control={control}
          controlName="delay"
          error={errors.delay?.message}
        />
      )}
      {runIf.startsWith('total_price') && (
        <div className="flex items-center gap-2 mt-5">
          <Controller
            control={control}
            name="currency"
            render={({ field }) => (
              <BBBSelect
                options={currencyOptions}
                optionLabel="code"
                optionValue="code"
                label="Currency"
                placeholder="Currency"
                isSearchable
                value={field.value}
                onValueChange={field.onChange}
                error={errors.currency?.message}
                containerClassName="w-32"
              />
            )}
          />
          <Controller
            control={control}
            name="totalPrice"
            render={({ field }) => {
              const currencyCode = currency?.code || 'USD';

              return (
                <PriceInput
                  error={errors.totalPrice?.message}
                  value={formatCurrency(field.value as string, currencyCode)}
                  onChange={(value) => {
                    field.onChange(value.replace(/\D/g, ''));
                  }}
                  currency={currencyCode}
                />
              );
            }}
          />
        </div>
      )}
      {runIf.startsWith('product_variant') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Product variant value"
          placeholder="Input total product variant value"
          isHookForm
          control={control}
          controlName="totalProductVariant"
          error={errors.totalProductVariant?.message}
        />
      )}
      {runIf.startsWith('quantity') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Quantity value"
          placeholder="Input quantity value"
          isHookForm
          control={control}
          controlName="totalQuantity"
          error={errors.totalQuantity?.message}
        />
      )}
      {runIf.startsWith('review_submitted_stars') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Stars"
          placeholder="Input stars treshold"
          isHookForm
          control={control}
          controlName="starsTreshold"
          error={errors.starsTreshold?.message}
        />
      )}
    </>
  );
}

function PriceInput({
  error,
  value,
  onChange,
  currency,
}: {
  error?: string;
  value: string;
  onChange: (value: string) => void;
  currency?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = inputRef.current?.selectionStart ?? 0;
    const unformattedValue = value.replace(/\D/g, '');

    const prevValue = value as string;
    onChange(unformattedValue);

    // Difference in length after formatting
    const formattedValue = formatCurrency(unformattedValue, currency || 'USD');
    const lengthDifference = formattedValue.length - prevValue.length;

    // Restore cursor position with adjustment for formatting
    requestAnimationFrame(() => {
      if (inputRef.current) {
        const adjustedPosition = cursorPosition + lengthDifference;
        inputRef.current.selectionStart = adjustedPosition;
        inputRef.current.selectionEnd = adjustedPosition;
      }
    });
  };

  return (
    <BBBTextInput
      ref={inputRef}
      containerClassname="grow mb-0"
      label="Total price value"
      placeholder="Input total price value"
      error={error}
      value={value}
      onChange={handleChange}
    />
  );
}
