import { useLocation } from 'react-router';

import { BBBSpinner } from '@/components';
import CompanyCreationCard, {
  CompanyCreationFormSchema,
} from '@/components/Auth/CompanyCreationCard';
import { Redirect } from '@/components/Redirect';
import useAddUserCompany from '@/hooks/auth/useAddUserCompany';
import useAuthenticatedUserCompanies from '@/hooks/auth/useAuthenticatedUserCompanies';
import { useAccessToken } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import GeneralLayout from '@/layouts/GeneralLayout';
import { logout } from '@/stores/auth';

export default function Company() {
  const accessToken = useAccessToken();

  if (!accessToken) {
    return <Redirect to="/" />;
  }

  return <_Company />;
}

function _Company() {
  const { search } = useLocation();

  const { data: companiesData, isInitialLoading } =
    useAuthenticatedUserCompanies();

  if (isInitialLoading) {
    return <BBBSpinner />;
  }

  if (
    companiesData?.pages.length &&
    companiesData.pages.flatMap((page) => page.data).length
  ) {
    return <Redirect push to={'/' + search} />;
  }

  return <__Company />;
}

function __Company() {
  const { mutate: addCompany, isLoading: isLoadingAddCompany } =
    useAddUserCompany();

  const dispatch = useAppDispatch();

  const submitHandler = (data: CompanyCreationFormSchema) => {
    addCompany({
      name: data.company_name,
      industry: data.industries?.label as string,
      country: data.country?.value as string,
    });
  };

  return (
    <GeneralLayout>
      <CompanyCreationCard
        showBack
        onBack={() => {
          dispatch(logout());
        }}
        onSubmit={submitHandler}
        loadingSubmit={isLoadingAddCompany}
      />
    </GeneralLayout>
  );
}
