import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import { BBBComposedChart, BBBOverviewBox } from '@/components';
import {
  previewBitCRMAnalytics,
  previewViewChartAutomation,
  previewViewChartCampaign,
} from '@/constants/analytics/bitCRM';
import useAnalytics from '@/hooks/bitCRM/analytics/useAnalytics';
import useAnalyticsChart from '@/hooks/bitCRM/analytics/useAnalyticsChart';

type Props = {
  className?: string;
  startDate?: string;
  endDate?: string;
  containerClassName?: string;
  isPreview?: boolean;
  loading?: boolean;
};

export default function BitCRMAnalytics({
  containerClassName,
  startDate,
  endDate,
  isPreview,
  loading,
}: Props) {
  const { data: bitCRMAnalytics, isInitialLoading: loadingAnalytics } =
    useAnalytics(
      {
        startDate,
        endDate,
      },
      {
        enabled: !loading && !isPreview,
      }
    );

  const { data: bitCRMChartAnalytics } = useAnalyticsChart(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  return (
    <div
      className={cx(
        'grid grid-cols-1 md:grid-cols-6 gap-3',
        containerClassName
      )}
    >
      <div className={cx('md:col-span-2')}>
        <BBBOverviewBox
          title="Campaign Sent"
          tooltip="Total campaign message sent"
          value={
            isPreview
              ? previewBitCRMAnalytics.campaignSent.count
              : bitCRMAnalytics?.data.campaignSent?.count.toLocaleString()
          }
          loading={loading || loadingAnalytics}
        />
      </div>
      <div className={cx('md:col-span-2')}>
        <BBBOverviewBox
          title="Automation Sent"
          tooltip="Total automation message sent"
          value={
            isPreview
              ? previewBitCRMAnalytics.automationSent.count
              : bitCRMAnalytics?.data.automationSent?.count.toLocaleString()
          }
          loading={loading || loadingAnalytics}
        />
      </div>
      <div className={cx('md:col-span-2')}>
        <BBBOverviewBox
          title="Unsubscribe"
          tooltip="Your customers who unsubscribe from your campaign"
          value={
            isPreview
              ? previewBitCRMAnalytics.unsubscribe.count
              : bitCRMAnalytics?.data.unsubscribe?.count.toLocaleString()
          }
          loading={loading || loadingAnalytics}
        />
      </div>
      <div className={cx('md:col-span-3')}>
        <BBBOverviewBox
          title="Abandoned cart"
          tooltip="Abandoned cart from your Shopify store"
          value={
            isPreview
              ? previewBitCRMAnalytics.abandonedCart.count
              : bitCRMAnalytics?.data?.abandonedCart?.count.toLocaleString()
          }
          loading={loading || loadingAnalytics}
        />
      </div>
      <div className={cx('md:col-span-3')}>
        <BBBOverviewBox
          title="Order created"
          tooltip="Order created from your Shopify store"
          value={
            isPreview
              ? previewBitCRMAnalytics.orderCreated.count
              : bitCRMAnalytics?.data?.orderCreated?.count.toLocaleString()
          }
          loading={loading || loadingAnalytics}
        />
      </div>
      <div className={cx('md:col-span-3')}>
        <BBBComposedChart
          title={<p className="text-primary-main">Campaign</p>}
          data={
            isPreview
              ? previewViewChartCampaign
              : bitCRMChartAnalytics?.data?.campaign
          }
          lineDataKeyBar="message"
          xAxisProps={{
            dataKey: 'label',
          }}
          isHasGraph
          lineDataKeyGraph="replies"
          tooltipProps={{
            isCustomTooltip: true,
            customContent: (payload) => (
              <div className="flex flex-col gap-1">
                {payload?.map((item, idx) => (
                  <p key={idx} className="text-white">
                    {item.value} {item.name}
                  </p>
                ))}
              </div>
            ),
          }}
        />
      </div>
      <div className={cx('md:col-span-3')}>
        <BBBComposedChart
          title={<p className="text-primary-main">Automation</p>}
          data={
            isPreview
              ? previewViewChartAutomation
              : bitCRMChartAnalytics?.data?.automation
          }
          lineDataKeyBar="message"
          xAxisProps={{
            dataKey: 'label',
          }}
          isHasGraph
          lineDataKeyGraph="replies"
          tooltipProps={{
            isCustomTooltip: true,
            customContent: (payload) => (
              <div className="flex flex-col gap-1">
                {payload?.map((item, idx) => (
                  <p key={idx} className="text-white">
                    {item.value} {item.name}
                  </p>
                ))}
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
}
