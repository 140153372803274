type GeneralLayoutProps = {
  children: React.ReactNode;
};

export default function GeneralLayout({ children }: GeneralLayoutProps) {
  return (
    <div className="min-h-full flex flex-col items-center px-2 md:px-4 py-[2em] relative bg-[#f8f2ef]">
      <EllipseSolid className="absolute top-0 right-0" />
      {children}
    </div>
  );
}

function EllipseSolid({ className }: { className: string }) {
  return (
    <svg
      width="923"
      height="548"
      viewBox="0 0 923 548"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="613" cy="-65" r="613" fill="#F2E9E4" />
    </svg>
  );
}
