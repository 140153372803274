import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import isEqual from 'lodash-es/isEqual';
import EmptyCartIcon from './icon/EmptyIcon';

import {
  BBBButton,
  BBBCard,
  BBBCheckbox,
  BBBColorPicker,
  BBBModal,
  BBBTextInput,
} from '@/components';
import useCartSettings from '@/hooks/bitApp/design/useCartSettings';
import useCartSettingsTemplate from '@/hooks/bitApp/design/useCartSettingsTemplate';
import useSaveCartSettings from '@/hooks/bitApp/design/useSaveCartSettings';
import useCropImageModal from '@/hooks/common/useCropImageModal';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setCartSettings } from '@/stores/bitApp';

function CartPage() {
  const [showImageTemplate, setShowImageTemplate] = useState(false);
  const dispatch = useAppDispatch();
  const toggleCropImageModal = useCropImageModal();

  const cartSettingsForm = useAppSelector(
    (state) => state.bitApp.cartSettings,
    shallowEqual
  );

  const inputFileRef = useRef<HTMLInputElement>(null);

  const { data: cartSettingsTemplate } = useCartSettingsTemplate({
    enabled: showImageTemplate,
  });

  const { data: cartSettings } = useCartSettings();

  const { mutate: saveCartSettings, isLoading: isLoadingSaveCartSettings } =
    useSaveCartSettings();

  useEffect(() => {
    dispatch(setCartSettings(cartSettings || null));
  }, [cartSettings, dispatch]);

  const onFilechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      toggleCropImageModal({
        handleDownload: handleDownloadCroppedImage,
        file: e.target.files[0],
        circular: true,
      });
    }
  };

  const handleDownloadCroppedImage = (url: string) =>
    dispatch(
      setCartSettings({
        image: url,
        mode: 'CUSTOM',
      })
    );

  return (
    <BBBCard
      title="Customization"
      rightButton={
        <BBBButton
          text="Save"
          disabled={
            isEqual(cartSettingsForm, cartSettings) || isLoadingSaveCartSettings
          }
          onClick={() => {
            saveCartSettings();
          }}
        />
      }
    >
      <div className="mb-2 text-[18px]">Empty Cart Image</div>

      {showImageTemplate && (
        <BBBModal
          title="Choose Image Template"
          show={showImageTemplate}
          onHide={() => {
            setShowImageTemplate(false);
          }}
        >
          <div className="grid grid-cols-3 gap-3">
            {(cartSettingsTemplate || []).map((template) => (
              <div
                key={template.id}
                className={`cursor-pointer hover:border-black ${
                  template.id === cartSettingsForm?.emptyCartTemplateId
                    ? 'border-black'
                    : ''
                } flex items-center justify-center border rounded-lg h-[190px]`}
                onClick={() => {
                  dispatch(
                    setCartSettings({
                      template,
                      emptyCartTemplateId: template.id,
                      mode: 'TEMPLATE',
                    })
                  );
                  setShowImageTemplate(false);
                }}
              >
                <img src={template.image} alt="template" />
              </div>
            ))}
          </div>
        </BBBModal>
      )}
      <div className="mb-5">
        <BBBTextInput
          value={cartSettingsForm?.message || ''}
          onChange={({ target: { value } }) =>
            dispatch(
              setCartSettings({
                message: value,
              })
            )
          }
          placeholder="Empty cart message"
          label="Empty Cart Message"
          hasMaxCharLabel
          maxChar={60}
        />
      </div>
      <div className="mb-5">
        <div className="mb-2">Empty Cart Image</div>
        <div className="flex items-center gap-2">
          {cartSettingsForm?.mode === 'CUSTOM' ? (
            <img
              src={cartSettingsForm.image || ''}
              className="rounded-full w-12 h-12"
              alt="preview"
            />
          ) : (
            <EmptyCartIcon />
          )}
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFilechange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <BBBButton
            text="Upload Image"
            variant="secondary"
            size="sm"
            onClick={() => {
              inputFileRef.current?.click();
            }}
          />
          <BBBButton
            text="Use Template"
            size="sm"
            onClick={() => {
              setShowImageTemplate(true);
            }}
          />
        </div>
      </div>
      <BBBCheckbox
        label="Set image and message color same as primary color"
        checked={cartSettingsForm?.isPrimaryColorApplied || false}
        onValueChange={(value) => {
          dispatch(
            setCartSettings({
              isPrimaryColorApplied: value,
            })
          );
        }}
        disabled={cartSettingsForm?.mode === 'CUSTOM'}
      />
      {!cartSettingsForm?.isPrimaryColorApplied && (
        <div className="mt-2">
          <div className="mb-1">Image and Message Color</div>
          <BBBColorPicker
            color={cartSettingsForm?.color || '#000000'}
            width={40}
            height={40}
            onChangeColor={(value) => {
              dispatch(
                setCartSettings({
                  color: value,
                })
              );
            }}
          />
        </div>
      )}
    </BBBCard>
  );
}

export default CartPage;
