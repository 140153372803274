import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';

import { Settings } from '@/api/services/whatsApp/settings';
import MailIcon from '@/assets/icons/MailIcon';
import MailOpenIcon from '@/assets/icons/MailOpenIcon';
import SoundIcon from '@/assets/icons/SoundIcon';
import SoundMutedIcon from '@/assets/icons/SoundMutedIcon';
import {
  BBBCard,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
} from '@/components';
import { timeUnitOptions, timeUnitOptionsObj } from '@/constants/bitChat';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';

type SettingsForm = Pick<
  Settings,
  | 'notificationEmail'
  | 'abandonedTicketEmail'
  | 'notificationPopUp'
  | 'notificationSound'
  | 'individualChat'
  | 'groupChat'
  | 'ticketChat'
  | 'assignedTicket'
  | 'assignedTicketEmail'
> & {
  abandonedTicketTime: string;
  abandonedTicketUnit: {
    label: string;
    value: string;
  } | null;
};

const schema = yup.object().shape({
  abandonedTicketTime: yup.string().when('abandonedTicketEmail', {
    is: (value: boolean) => !!value,
    then: (rule) => rule.required('This field is required'),
  }),
  abandonedTicketUnit: yup
    .mixed<typeof timeUnitOptions[number]>()
    .when('abandonedTicketEmail', {
      is: (value: boolean) => !!value,
      then: (rule) => rule.required('This field is required'),
    }),
});

export default function SoundNotification() {
  const { data } = useSettings();
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useCustomForm<SettingsForm>({
    resolver: yupResolver(schema),
  });

  const isEmailAllowed = watch('notificationEmail');

  const abandonedTicketEmail = watch('abandonedTicketEmail');

  const abandonedTicketTime = watch('abandonedTicketTime');

  const abandonedTicketUnit = watch('abandonedTicketUnit.value');

  const dataFromApi = useMemo<Partial<SettingsForm>>(
    () => ({
      notificationPopUp: data?.notificationPopUp || false,
      notificationSound: data?.notificationSound || false,
      notificationEmail: data?.notificationEmail || false,
      individualChat: data?.individualChat || false,
      ticketChat: data?.ticketChat || false,
      groupChat: data?.groupChat || false,
      assignedTicket: data?.assignedTicket || false,
      assignedTicketEmail: data?.assignedTicketEmail || false,
      abandonedTicketEmail: data?.abandonedTicketEmail || false,
      abandonedTicketTime: data?.abandonedTicketTime
        ? data.abandonedTicketTime.toString()
        : '',
      abandonedTicketUnit: data?.abandonedTicketUnit
        ? timeUnitOptionsObj[data.abandonedTicketUnit]
        : null,
    }),
    [
      data?.notificationPopUp,
      data?.notificationSound,
      data?.notificationEmail,
      data?.individualChat,
      data?.ticketChat,
      data?.groupChat,
      data?.assignedTicket,
      data?.assignedTicketEmail,
      data?.abandonedTicketEmail,
      data?.abandonedTicketTime,
      data?.abandonedTicketUnit,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const onSubmit = ({
      abandonedTicketTime,
      abandonedTicketUnit,
      ...data
    }: Partial<SettingsForm>) => {
      updateSettings({
        ...data,
        abandonedTicketTime: Number(abandonedTicketTime),
        abandonedTicketUnit: abandonedTicketUnit?.value,
      });
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('save-settings', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant: loadingUpdateSettings ? 'loading' : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSettings,
    reset,
    toggle,
    watch(),
    updateSettings,
  ]);

  return (
    <BBBCard
      title="Sound & notification"
      desc="Set your team operational hours to fit your customer needs"
      className="mb-cardBottom"
    >
      <div className="mb-5">
        <div className="flex gap-6 justify-center">
          <Controller
            control={control}
            name="notificationPopUp"
            render={({ field }) => (
              <div
                className={cx(
                  'px-8 py-4 border-neutral-30 border-[1.5px] transition-[border-color] cursor-pointer rounded-2xl',
                  field.value && 'border-secondary-main'
                )}
                onClick={() => field.onChange(!field.value)}
              >
                <div className="w-20 h-12 relative bg-[#D9D9D9] rounded-lg mb-2">
                  <div className="absolute top-1.5 right-1.5 bg-secondary-main w-6 h-2.5 rounded"></div>
                </div>
                <div className="text-neutral-60 mb-2 text-center">Pop-up</div>
                <div className="text-xs text-neutral-40 text-center">
                  {field.value ? 'Allowed' : 'Not allowed'}
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="notificationEmail"
            render={({ field }) => (
              <div
                className={cx(
                  'px-8 py-4 border-neutral-30 border-[1.5px] transition-[border-color] cursor-pointer rounded-2xl',
                  field.value && 'border-secondary-main'
                )}
                onClick={() => field.onChange(!field.value)}
              >
                <div className="w-20 h-12 relative bg-[#D9D9D9] rounded-lg mb-2 flex justify-center items-center">
                  {field.value ? <MailOpenIcon /> : <MailIcon />}
                  {/* <div className="absolute top-1.5 right-1.5 bg-secondary-main w-6 h-2.5 rounded"></div> */}
                </div>
                <div className="text-neutral-60 mb-2 text-center">Email</div>
                <div className="text-xs text-neutral-40 text-center">
                  {field.value ? 'Allowed' : 'Not allowed'}
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="notificationSound"
            render={({ field }) => (
              <div
                className={cx(
                  'px-8 py-4 border-neutral-30 border-[1.5px] transition-[border-color] cursor-pointer rounded-2xl',
                  field.value && 'border-secondary-main'
                )}
                onClick={() => field.onChange(!field.value)}
              >
                <div className="w-20 h-12 relative bg-[#D9D9D9] rounded-lg mb-2 flex items-center justify-center">
                  {field.value ? <SoundIcon /> : <SoundMutedIcon />}
                </div>
                <div className="text-neutral-60 mb-2 text-center">Sound</div>
                <div className="text-xs text-neutral-40 text-center">
                  {field.value ? 'Allowed' : 'Not allowed'}
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="mb-5 flex justify-between items-center">
        <div>
          <div className="text-primary-main">Individual chat</div>
          <div className="text-neutral-50 text-xs">
            Notify when there is a new chat from all customers
          </div>
        </div>
        <Controller
          control={control}
          name="individualChat"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="mb-5 flex justify-between items-center">
        <div>
          <div className="text-primary-main">Ticket chat</div>
          <div className="text-neutral-50 text-xs">
            Notify when there is a new chat from all ticket you handled
          </div>
        </div>
        <Controller
          control={control}
          name="ticketChat"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="mb-5 flex justify-between items-center">
        <div>
          <div className="text-primary-main">Group chat</div>
          <div className="text-neutral-50 text-xs">
            Notify when there is a new group chat
          </div>
        </div>
        <Controller
          control={control}
          name="groupChat"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="flex justify-between items-center">
        <div>
          <div className="text-primary-main">Assigned ticket (Pop-up)</div>
          <div className="text-neutral-50 text-xs">
            Notify when there is a new ticket assigned to other agent
          </div>
        </div>
        <Controller
          control={control}
          name="assignedTicket"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      {isEmailAllowed && (
        <>
          <div className="mt-5 mb-5 flex justify-between items-center">
            <div>
              <div className="text-primary-main">
                Assigned ticket (Email notification)
              </div>
              <div className="text-neutral-50 text-xs">
                Notify when there is a new ticket assigned to other agent
              </div>
            </div>
            <Controller
              control={control}
              name="assignedTicketEmail"
              render={({ field }) => (
                <BBBPrimarySwitch
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex justify-between items-center">
            <div>
              <div className="text-primary-main">
                Abandoned ticket (Email notification)
              </div>
              <div className="text-neutral-50 text-xs">
                Notify when there is a ticket abandoned for{' '}
                {abandonedTicketTime} {abandonedTicketUnit}
              </div>
            </div>
            <Controller
              control={control}
              name="abandonedTicketEmail"
              render={({ field }) => (
                <BBBPrimarySwitch
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          {abandonedTicketEmail && (
            <div className="mt-5 flex items-center gap-2">
              <div>Ticket is abandoned after</div>
              <BBBTextInput
                placeholder="Time value"
                containerClassname="mb-0"
                control={control}
                isHookForm
                controlName="abandonedTicketTime"
                type="number"
                error={errors.abandonedTicketTime?.message}
              />
              <Controller
                control={control}
                name="abandonedTicketUnit"
                render={({ field }) => (
                  <BBBSelect
                    options={timeUnitOptions}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Time unit"
                    value={field.value}
                    onValueChange={field.onChange}
                    error={errors.abandonedTicketUnit?.message}
                  />
                )}
              />
              without activity
            </div>
          )}
        </>
      )}
    </BBBCard>
  );
}
