import { useState } from 'react';
import { GetAiTagsPayloads } from 'api/services/whatsApp/analytics';
import ArrowGraphicIcon from 'assets/icons/ArrowGraphIcon';
import LineTypeIcon from 'assets/icons/LineTypeIcon';
import {
  previewBitChatAnalytics,
  previewTicketCategories,
} from 'constants/analytics/bitChat';
import {
  dummyAiTagsGraphData,
  dummyAnalyticsKey,
} from 'constants/analytics/bitChat/aiTags';
import { ChannelIntegrationValues } from 'constants/integrations';
import dayjs from 'dayjs';
import {
  useTicketOpened,
  useTicketResolved,
  useTopTicketCategories,
  useTotalConversations,
} from 'hooks/bitChat/analytics/analytics-v3';
import { useAiTags } from 'hooks/whatsApp/analytics';
import queryString from 'query-string';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { CurveType } from 'recharts/types/shape/Curve';
import { twMerge as cx } from 'tailwind-merge';
import { BBBBarChart, BBBGraphChart, BBBOverviewBox } from 'components';
import { Link } from 'components/Link';
import { formatNumber } from 'utils/common/analytics';

type Props = {
  isPreview?: boolean;
  startDate: string;
  endDate: string;
  channel: ChannelIntegrationValues;
  loading?: boolean;
};

export default function Tickets({
  isPreview,
  startDate,
  endDate,
  channel,
  loading,
}: Props) {
  const _queryParams = queryString.stringify({ startDate, endDate, channel });

  const queryParams = _queryParams ? '?' + _queryParams : '';

  const { data: aiTags } = useAiTags(
    {
      startDate,
      endDate,
      type: 'all',
      channel,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const { data: totalConversations, isLoading: loadingTotalConversations } =
    useTotalConversations(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: ticketResolved, isLoading: loadingTicketResolved } =
    useTicketResolved(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: ticketOpened, isLoading: loadingTicketOpened } =
    useTicketOpened(
      {
        startDate,
        endDate,
        channel,
      },
      { enabled: !loading && !isPreview }
    );

  const { data: topTicketCategories } = useTopTicketCategories(
    {
      startDate,
      endDate,
      channel,
    },
    { enabled: !loading && !isPreview }
  );

  return (
    <>
      <p className="text-primary-color text-xl">Tickets</p>
      <div className="w-full gap-4 grid grid-cols-3">
        <BBBOverviewBox
          title="Ticket created"
          value={
            isPreview
              ? previewBitChatAnalytics.ticketCreated.count
              : totalConversations
          }
          loading={isPreview ? false : loading || loadingTotalConversations}
        />
        <BBBOverviewBox
          title="Ticket Resolved"
          value={
            isPreview
              ? previewBitChatAnalytics.ticketResolved.count
              : ticketResolved
          }
          loading={isPreview ? false : loading || loadingTicketResolved}
        />
        <BBBOverviewBox
          title="Open tickets"
          value={
            isPreview ? previewBitChatAnalytics.ticketOpen.count : ticketOpened
          }
          loading={loading || loadingTicketOpened}
        />
        <BBBBarChart
          title="Ticket categories"
          tooltip="Showing you top ticket categories from your tickets."
          layout="vertical"
          containerClassName="w-full col-span-3"
          lineDataKey="value"
          data={isPreview ? previewTicketCategories : topTicketCategories}
          xAxisProps={{
            dataKey: 'value',
          }}
          yAxisProps={{
            dataKey: 'label',
            width: 150,
            interval: 0,
          }}
        />
        <BBBGraphChart
          containerClassName="w-full col-span-3"
          customTitle={
            <Link
              show
              isHasIcon
              to={
                isPreview
                  ? `/analytics/bitchat/aitags${queryParams}&preview=true`
                  : `/analytics/bitchat/aitags${queryParams}`
              }
            >
              Top 5 AI tags - All
            </Link>
          }
          customHeader={() => <CustomHeaderGraphChart aiTags={aiTags} />}
          data={isPreview ? dummyAiTagsGraphData : aiTags?.topTags}
          xAxisDataKey="date"
          isMultipleLine
          lineDataKey={
            isPreview ? dummyAnalyticsKey : aiTags?.topTagsLabel || []
          }
          customFooter={(onKeyChange) => (
            <CustomFooterGraphChart
              onKeyChange={onKeyChange}
              analyticsKeys={
                isPreview ? dummyAnalyticsKey : aiTags?.topTagsLabel || []
              }
            />
          )}
          tooltipProps={{
            isCustomTooltip: true,
            className:
              'bg-white p-2.5 rounded-lg border border-neutral-30 opacity-100 shadow-[0px_3px_6px_0px_#9B9B9B26]',
            customContent: (payload) => (
              <TooltipGraphChart data={payload} aiTags={aiTags} />
            ),
          }}
        />
      </div>
    </>
  );
}

function TooltipGraphChart({
  data,
  aiTags,
}: {
  data: Payload<ValueType, NameType>[] | undefined;
  aiTags: GetAiTagsPayloads | undefined;
}) {
  if (data?.length === 0) {
    return null;
  }

  return (
    <div className="min-w-[13rem] flex flex-col w-full gap-2 bg-neutral-10 p-4 shadow-md rounded-lg">
      <p className="text-xs text-neutral-40">
        {data?.[0].payload.date}, {dayjs(aiTags?.startDate).format('YYYY')}
      </p>
      {data
        // @ts-ignore
        ?.sort((a, b) => b.value - a.value)
        ?.map((item, idx) => (
          <div key={idx} className="flex justify-between gap-4 items-center">
            <div className="flex items-center gap-1">
              <LineTypeIcon
                color={item.color}
                isDashed={item.strokeDasharray === '5 5'}
              />
              <p className="text-sm text-primary-main">{item.name}</p>
            </div>
            <div className="flex items-center gap-2.5">
              <p className="text-sm text-primary-main">{item.value}</p>
              {item.payload.percentage !== 0 && (
                <div className="flex items-center gap-1">
                  <ArrowGraphicIcon isStonks={item.payload.percentage > 0} />
                  <p className="text-[0.625rem] text-neutral-40">
                    {item.payload.percentage}%
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

function CustomHeaderGraphChart({
  aiTags,
}: {
  aiTags: GetAiTagsPayloads | undefined;
}) {
  return (
    <div className="flex items-center gap-3">
      <div className="flex justify-center items-center gap-3 px-3 py-1 rounded-lg bg-neutral-20">
        <p className="text-neutral-60 text-xs">Total AI tags generated:</p>{' '}
        <div className="flex items-center gap-2">
          <p className="font-bold text-neutral-70 text-base">
            {aiTags?.totalGenerated.total.toLocaleString()}
          </p>
          <div className="flex items-center gap-1">
            <ArrowGraphicIcon
              isStonks={
                aiTags?.totalAiGenerated.description === 'UP' ? true : false
              }
            />
            <p className="text-[0.625rem] text-neutral-40">
              {aiTags?.totalAiGenerated.percentage}%
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-3 px-3 py-1 rounded-lg bg-neutral-20">
        <p className="text-neutral-60 text-xs">Avg. AI tags per day:</p>{' '}
        <div className="flex items-center gap-2">
          <p className="font-bold text-neutral-70 text-base">
            {formatNumber(aiTags?.averageTotalGenerated)}
          </p>
          <div className="flex items-center gap-1">
            <ArrowGraphicIcon
              isStonks={
                aiTags?.totalAiGenerated.description === 'UP' ? true : false
              }
            />
            <p className="text-[0.625rem] text-success-main">
              {aiTags?.totalAiGenerated.percentage}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomFooterGraphChart({
  onKeyChange,
  analyticsKeys,
}: {
  onKeyChange: (key: string | undefined) => void;
  analyticsKeys: {
    color: string;
    key: string;
    type: CurveType;
  }[];
}) {
  const [key, setKey] = useState<string>('');

  const handleHover = (key: string) => {
    setKey(key);
    onKeyChange?.(key);
  };

  const handleHoverLeave = () => {
    setKey('');
    onKeyChange?.('');
  };

  return (
    <div className="flex bg-neutral-20 rounded-lg px-2.5 py-1 items-center gap-4">
      {analyticsKeys
        ?.sort((a, b) => a.key.localeCompare(b.key))
        .map((item) => (
          <div
            key={item.key}
            onMouseEnter={() => handleHover(item.key)}
            onMouseLeave={() => handleHoverLeave()}
            className={cx(
              'flex items-center gap-2 cursor-pointer',
              key === ''
                ? 'opacity-100'
                : item.key === key
                ? 'opacity-100'
                : 'opacity-50'
            )}
          >
            <LineTypeIcon color={item.color} isDashed={item.type === 'basis'} />
            <p className="text-neutral-70 text-xs">{item.key}</p>
          </div>
        ))}
    </div>
  );
}
