import { useState } from 'react';
import { PlusCircle, Trash2 } from 'react-feather';
import { Control, useFieldArray, useFormState } from 'react-hook-form';
import { createId } from '@paralleldrive/cuid2';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { AISettingsForm } from './AiSettings';

import { BBBCard } from '@/components/BBBCard';
import BBBLimitAlert from '@/components/BBBLimitAlert/BBBLimitAlert';
import { BBBTextAreaInput, BBBTextInput } from '@/components/BBBTextInput';
import useDeleteAiTag from '@/hooks/bitChat/settings/useDeleteAiTag';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';

type Props = {
  control: Control<AISettingsForm>;
};

function AiTagging({ control }: Props) {
  const [hoverAiTags, setHoverAiTags] = useState<number | undefined>(undefined);
  const { append, fields } = useFieldArray({
    control,
    name: 'aiTags',
  });
  const { errors } = useFormState({ control });

  const { data: pricingData } = usePricingByApp('BITCHAT');
  const {
    data: pricingCRM,
    limit,
    isReachingTreshold,
  } = useWithCRMPricing('BITCHAT', 'ai_tagging');
  const deleteAiTag = useDeleteAiTag();

  return (
    <BBBCard title="AI tagging" desc="Optimize your own AI, fit what you need">
      <div className="flex flex-col gap-6">
        {fields?.map((field, index) => (
          <div key={field.id}>
            <div
              className={cx(
                'text-ls text-neutral-60 cursor-pointer relative inline-block mb-2',
                hoverAiTags === index && 'underline'
              )}
              onMouseEnter={() => setHoverAiTags(index)}
              onMouseLeave={() => {
                setHoverAiTags(undefined);
              }}
              onClick={() => deleteAiTag(true)(field.aiTagId)}
            >
              Label {index > 0 && index + 1}
              <motion.div
                animate={{
                  opacity: hoverAiTags === index ? 1 : 0,
                  x: hoverAiTags === index ? 'calc(100% + 0.25rem)' : 0,
                }}
                className="absolute z-10 top-1 right-0"
                transition={{ duration: 0.3 }}
              >
                <Trash2 size={12} color="#9E9E9E" />
              </motion.div>
            </div>
            <div className="flex gap-2 mb-2">
              <BBBTextInput
                hasMaxCharLabel
                maxChar={12}
                isHookForm
                control={control}
                controlName={`aiTags.${index}.label`}
                containerClassname="mb-0"
                placeholder="Label name"
                error={errors.aiTags?.[index]?.label?.message}
              />
              <BBBTextInput
                hasMaxCharLabel
                maxChar={60}
                isHookForm
                control={control}
                controlName={`aiTags.${index}.description`}
                containerClassname="mb-0 grow"
                placeholder="Label description"
                error={errors.aiTags?.[index]?.description?.message}
              />
            </div>
            <BBBTextAreaInput
              placeholder="Input values for this label"
              rows={5}
              isHookForm
              control={control}
              controlName={`aiTags.${index}.values`}
              error={errors.aiTags?.[index]?.values?.message}
            />
          </div>
        ))}
      </div>
      <div
        className={cx(
          'mt-5 flex items-center gap-2 cursor-pointer text-neutral-40 hover:text-neutral-50 transition-colors',
          isReachingTreshold && 'opacity-50 pointer-events-none'
        )}
        onClick={() =>
          append({
            aiTagId: createId(),
            label: '',
            values: '',
            description: '',
          })
        }
      >
        <PlusCircle />
        <div>Add new label</div>
      </div>
      {isReachingTreshold && pricingData && (
        <BBBLimitAlert
          usage={pricingCRM?.usage}
          appType="BITCHAT"
          module={pricingCRM?.pricingFeature.label}
          limit={limit}
          currentPlan={{
            label: pricingData.pricingModule.label,
            name: pricingData.pricingName,
          }}
          className="mt-6"
          customExceedTresholdLabel={
            <p className="text-primary-main font-semibold text-sm">
              You reached your limit. Upgrade plan to create more label.
            </p>
          }
          withoutTip
          forceCloseable
        />
      )}
    </BBBCard>
  );
}

export default AiTagging;
