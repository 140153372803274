import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';

import { BBBDeviceFrame, BBBSpinner } from '@/components';
import { Link } from '@/components/Link';
import { useLinkClicks, usePageView } from '@/hooks/bitLink/analytics';
import useIframeQuery from '@/hooks/bitLink/links/useIframeQuery';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';
import { useAppSelector } from '@/hooks/rtk/store';

export default function LivePreview() {
  const refreshIframe = useAppSelector((state) => state.bitLink.refreshIframe);

  const [loadingIframe, setLoadingIframe] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { data: clicksCount, isInitialLoading: loadingClicksCount } =
    useLinkClicks({
      startDate: dayjs().startOf('day').subtract(365, 'day').toISOString(),
      endDate: dayjs().endOf('day').subtract(1, 'day').toISOString(),
    });
  const { data: pageViewCount, isInitialLoading: loadingPageView } =
    usePageView({
      startDate: dayjs().startOf('day').subtract(365, 'day').toISOString(),
      endDate: dayjs().endOf('day').subtract(1, 'day').toISOString(),
    });
  const { data } = useOnboarding('links');

  const iframeData = useIframeQuery();

  const handleIframeLoad = () => {
    setLoadingIframe(false);
  };

  useEffect(() => {
    const prevSrc = iframeRef.current?.src;
    if (iframeRef.current) {
      iframeRef.current.src = prevSrc || '';
    }
  }, [refreshIframe]);

  return (
    <div className="m-4">
      <div
        id="live-track-bitlink"
        className="flex py-4 px-8 bg-white rounded-lg mb-8 border items-center justify-center"
      >
        <div className="flex-1">
          <div className="text-sm">Views:</div>
          {loadingPageView ? (
            <Skeleton width={30} />
          ) : (
            <div>{pageViewCount?.current}</div>
          )}
        </div>
        <div className="flex-1">
          <div className="text-sm">Clicks:</div>
          {loadingClicksCount ? (
            <Skeleton width={30} />
          ) : (
            <div>{clicksCount?.current}</div>
          )}
        </div>
        <Link
          className="text-xs text-info-main text-center hover:underline transition-all duration-200 ease-in-out"
          to={{
            pathname: '/analytics',
            search: `?tab=bitlink`,
          }}
        >
          See full
          <p>analytics</p>
        </Link>
      </div>
      <BBBDeviceFrame
        withoutNotch
        className={cx('flex-none', data?.lastStep === 6 ? 'z-[150]' : '')}
      >
        {iframeData &&
          (iframeData.loading ? (
            <BBBSpinner />
          ) : (
            <div className="h-full relative">
              <iframe
                referrerPolicy="strict-origin"
                sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
                loading="lazy"
                ref={iframeRef}
                onLoad={handleIframeLoad}
                src={iframeData.data}
                title="Bitlink"
                className="w-full h-full"
              />
              {loadingIframe && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <BBBSpinner />
                </div>
              )}
            </div>
          ))}
      </BBBDeviceFrame>
    </div>
  );
}
