import { ReactNode, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from 'pages/404/404';
import BBBAnalytics from 'pages/Analytics';
import ShopPerformance from 'pages/Analytics/BitLinkAnalytics/ShopPerformance';
import Login from 'pages/Auth/Login';
import Register from 'pages/Auth/Register';
import BetaSubscriptionRedirect from 'pages/BetaSubscriptionRedirect';
import BitAppDesign from 'pages/BitApp/Design';
import BitAppDesignLayout from 'pages/BitApp/Design/Layout';
import LoginOptions from 'pages/BitApp/LoginOptions';
import BitAppNotification from 'pages/BitApp/NotificationApp';
import BitAppPreview from 'pages/BitApp/PreviewApp';
import BitAppPublish from 'pages/BitApp/PublishApp';
import LiveChat from 'pages/BitChat/Livechat';
import BitCRMLivechatQuickReply from 'pages/BitChat/QuickReply';
import BitChatSettings from 'pages/BitChat/Settings';
import Ticket from 'pages/BitChat/Ticket';
import WaWidget from 'pages/BitChat/WaWidget';
import AutomationLog from 'pages/BitCRM/Automation/Log';
import Campaign from 'pages/BitCRM/Campaign';
import BitCRMCampaignLog from 'pages/BitCRM/Campaign/Log';
import CampaignNotification from 'pages/BitCRM/Campaign/Notification';
import CampaignWhatsApp from 'pages/BitCRM/Campaign/Whatsapp';
import BitCRMTemplate from 'pages/BitCRM/Template';
import WhatsAppTemplate from 'pages/BitCRM/Template/WhatsApp/New';
import BitLinkWrapper from 'pages/BitLink';
import BitLinkAppearance from 'pages/BitLink/Appearance';
import BitLinkLinks from 'pages/BitLink/Link';
import BitLinkSocialMedia from 'pages/BitLink/SocialMedia';
import BitLinkSubscription from 'pages/BitLink/Subscription';
import BitLoginApperance from 'pages/BitLogin/Appearance';
import BitLoginProviderSetting from 'pages/BitLogin/ProviderSetting';
import BitLoginSignUp from 'pages/BitLogin/SignUp';
import BitLoginSocialLogin from 'pages/BitLogin/SocialLogin';
import WhatsAppLoginDesktopFlow from 'pages/BitLogin/WhatsAppLogin/DesktopFlow';
import WhatsAppLoginMobileFlow from 'pages/BitLogin/WhatsAppLogin/MobileFlow';
import CompanyPage from 'pages/Company';
import Customers from 'pages/Customers';
import CustomerDetail from 'pages/Customers/CustomerDetail/CustomerDetail';
import SegmentationPage from 'pages/Customers/SegmentationPage';
import EmailVerificationRedirectionPage from 'pages/EmailVerification/RedirectionPage';
import NetworkError from 'pages/Error/NetworkError';
import Home from 'pages/Home';
import Integrations from 'pages/Integrations';
import InvitationPage from 'pages/Invitation';
import Pricing from 'pages/Pricing';
import ResetPassword from 'pages/ResetPassword';
import ResetPasswordNewCredentials from 'pages/ResetPassword/NewCredentials';
import Settings from 'pages/Settings';
import ShopifyOauthConnect from 'pages/ShopifyOauthConnect';
import ShopifyOAuthConnectSuccess from 'pages/ShopifyOAuthConnectSuccess';
import ShopifyOauthLogin from 'pages/ShopifyOauthLogin';
import ShopifyOauthLoginV2 from 'pages/ShopifyOauthLogin/ShopifyOauthLoginV2';
import FallbackRoute from './FallbackRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import ConfirmationModal from '@/components/BBBConfirmationModal/BBBConfirmationModal';
import PricingModalAlert from '@/components/BBBPricingModalAlert/BBBPricingModalAlert';
import { Redirect } from '@/components/Redirect';
import ShopifyIntegrationModal from '@/components/ShopifyIntegrationModal';
import { env } from '@/config/env';
import { isEmbed } from '@/hooks/common/useAppEmbed';
import AiTags from '@/pages/Analytics/BitChatAnalytics/ai-tags';
import TicketPerformance from '@/pages/Analytics/BitChatAnalytics/ticket-performance';
import Model from '@/pages/BitAI/Model';
import ManageModel from '@/pages/BitAI/Model/ManageModel';
import BitAiOnboarding from '@/pages/BitAI/Onboarding';
import Sorting from '@/pages/BitAI/Sorting';
import ManageSorting from '@/pages/BitAI/Sorting/ManageSorting';
import Submission from '@/pages/BitApp/Submission';
import Flows from '@/pages/BitChat/Chatbot';
import Flow from '@/pages/BitChat/Chatbot/Flow';
import BitChatOnboarding from '@/pages/BitChat/Onboarding';
import WhatsAppForm from '@/pages/BitChat/WhatsAppForm';
import FormDetail from '@/pages/BitChat/WhatsAppForm/FormDetail';
import Responses from '@/pages/BitChat/WhatsAppForm/Responses';
import AutomationNew from '@/pages/BitCRM/AutomationNew/AutomationNew';
import AutomationNewDetail from '@/pages/BitCRM/AutomationNew/Create/AutomationNewDetail';
import BitCRMOnboarding from '@/pages/BitCRM/Onboarding';
import SignupProxy from '@/pages/BitLogin/SignUpProxy';
import { toast } from '@/utils/common/toast';

function Routes() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setCount((prev) => prev + 1);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setCount((prev) => prev + 1);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!isOnline && count) {
      toast.error('Connection lost');
    }
  }, [count, isOnline]);

  return !isOnline && count === 0 ? (
    <NetworkError />
  ) : (
    <Router>
      <Switch>
        <Route exact path="/" component={FallbackRoute} />
        <PublicRoute path="/register" component={Register} title="Register" />
        <PublicRoute path="/login" component={Login} title="Login" />
        <Route
          path="/reset-password/:id"
          component={ResetPasswordNewCredentials}
        />
        <Route
          path="/beta-subscription/:hash"
          component={BetaSubscriptionRedirect}
        />
        <Route path="/reset-password" component={ResetPassword} />
        <Route
          path="/email-verification/:id"
          component={EmailVerificationRedirectionPage}
        />
        <Route path="/invitation/:id" component={InvitationPage} />
        <Route path="/company" component={CompanyPage} />
        <Route
          path="/oauth/shopify/connect/success"
          component={ShopifyOAuthConnectSuccess}
        />
        <Route path="/oauth/shopify/connect" component={ShopifyOauthConnect} />
        <Route path="/oauth/shopify/login/v2" component={ShopifyOauthLoginV2} />
        <Route path="/oauth/shopify/login" component={ShopifyOauthLogin} />
        <PrivateRoute
          path="/bitapp/publish"
          component={() => <BitAppPublish />}
          title="Publish App - bitApp"
        />

        <PrivateRoute
          path="/bitapp/notification"
          component={() => <BitAppNotification />}
          title="Notification App - bitApp"
        />

        <PrivateRoute
          path="/bitapp/login-options"
          title="Login Options - bitApp"
          component={() => <LoginOptions />}
        />

        <PrivateRoute
          path="/bitapp/submission"
          title="Submission (Internal) - bitApp"
          component={() => <Submission />}
        />

        <PrivateRoute
          path="/bitapp/preview"
          component={() => <BitAppPreview />}
          title="Preview App - bitApp"
        />

        <PrivateRoute exact path="/bitlink" title="bitLink">
          <Redirect to="/bitlink/links" />
        </PrivateRoute>

        <PrivateRoute exact path="/bitchat" title="bitChat">
          <Redirect to="/bitchat/livechat" />
        </PrivateRoute>

        <PrivateRoute exact path="/bitai" title="bitAI">
          <Redirect to="/bitai/sorting" />
        </PrivateRoute>

        <PrivateRoute exact path="/bitapp" title="bitApp">
          <Redirect to="/bitapp/design" />
        </PrivateRoute>

        <PrivateRoute exact path="/bitlogin" title="bitLogin">
          <Redirect to="/bitlogin/social" />
        </PrivateRoute>

        <PrivateRoute exact path="/bitcrm" title="bitCRM">
          <Redirect
            to={`/bitcrm/${
              isEmbed && env.REACT_APP_EMBED_PREVIEW_MODE
                ? 'automation'
                : 'campaign'
            }`}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/bitapp/design"
          component={(props: JSX.IntrinsicAttributes) => (
            <BitAppDesign {...props} />
          )}
          title="Design - bitApp"
        />
        <PrivateRoute
          path="/bitchat/onboarding"
          component={() => <BitChatOnboarding />}
          title="Onboarding - bitChat"
        />
        <PrivateRoute
          path="/bitchat/quickreply"
          component={() => <BitCRMLivechatQuickReply />}
          title="Quickreply - bitChat"
        />
        <PrivateRoute
          path="/bitchat/livechat"
          component={() => <LiveChat />}
          title="Livechat - bitChat"
        />
        <PrivateRoute
          path="/bitchat/ticket"
          component={() => <Ticket />}
          title="Ticket - bitChat"
        />
        <PrivateRoute
          path="/bitchat/settings/wa-cloud-template/:id"
          component={() => (
            <WhatsAppTemplate type="chat" source="whatsapp-cloud" />
          )}
          title="Template - WhatsApp Cloud Api - bitChat"
        />
        <PrivateRoute
          path="/bitchat/settings"
          component={(props: JSX.IntrinsicAttributes) => (
            <BitChatSettings {...props} />
          )}
          title="Settings - bitChat"
        />
        <PrivateRoute
          path="/bitchat/settings"
          component={(props: any) => <BitChatSettings />}
          title="Settings - bitChat"
        />
        <PrivateRoute
          path="/pricing"
          component={() => <Pricing />}
          title="Pricing"
        />
        <PrivateRoute path="/home" component={Home} title="Home" />
        <PrivateRoute
          path="/customers/segment/:segmentId"
          component={(props: JSX.IntrinsicAttributes) => (
            <SegmentationPage {...props} />
          )}
          title="Customer"
        />
        <PrivateRoute
          path="/customers/:id"
          component={(props: JSX.IntrinsicAttributes) => (
            <CustomerDetail {...props} />
          )}
          title="Customer Detail"
        />
        <PrivateRoute
          path="/customers"
          component={(props: JSX.IntrinsicAttributes) => (
            <Customers {...props} />
          )}
          title="Customers"
        />
        <PrivateRoute
          path="/integrations"
          component={Integrations}
          title="Integrations"
        />
        <PrivateRoute
          path="/analytics/bitchat/aitags"
          component={(props: JSX.IntrinsicAttributes) => <AiTags {...props} />}
          title="Analytics - Ai Tags - bitChat"
        />
        <PrivateRoute
          path="/analytics/bitchat/ticket-performance"
          component={(props: JSX.IntrinsicAttributes) => (
            <TicketPerformance {...props} />
          )}
          title="Analytics - Ticket Performance - bitChat"
        />
        <PrivateRoute
          path="/analytics/bitlink/:id"
          component={(props: JSX.IntrinsicAttributes) => (
            <ShopPerformance {...props} />
          )}
          title="Analytics - Shop Performance - bitLink"
        />
        <PrivateRoute
          path="/analytics"
          component={(props: JSX.IntrinsicAttributes) => (
            <BBBAnalytics {...props} />
          )}
          title="Analytics"
        />
        <PrivateRoute
          exact
          path="/bitlogin/appearance"
          component={() => <BitLoginApperance />}
          title="Appearance - bitLogin"
        />
        <PrivateRoute
          exact
          path="/bitlogin/signup"
          component={() => <BitLoginSignUp />}
          title="Sign Up - bitLogin"
        />
        <PrivateRoute
          exact
          path="/bitlogin/social"
          component={() => <BitLoginSocialLogin />}
          title="Social Login - bitLogin"
        />
        <PrivateRoute
          exact
          path="/bitlogin/oauth-settings"
          component={() => <BitLoginProviderSetting />}
          title="OAuth Settings - bitLogin"
        />
        <PublicRoute
          path="/bitlogin/auth-whatsapp"
          component={() => <WhatsAppLoginDesktopFlow />}
          title="Authentication WhatsApp - bitLogin"
          bypassAuth
        />
        <PublicRoute
          path="/bitlogin/auth-whatsapp-mobile"
          component={() => <WhatsAppLoginMobileFlow />}
          title="Authentication WhatsApp Success - bitLogin"
          bypassAuth
        />
        <PublicRoute
          path="/bitlogin/auth"
          component={SignupProxy}
          title="Authentication"
          bypassAuth
        />
        <PrivateRoute
          path="/bitlink/links/settings"
          component={(
            props: JSX.IntrinsicAttributes & { children: ReactNode }
          ) => (
            <BitLinkWrapper {...props}>
              <BitAppDesignLayout />
            </BitLinkWrapper>
          )}
          title="Links Settings - bitLink"
        />

        <PrivateRoute
          path="/bitlink/links"
          component={(
            props: JSX.IntrinsicAttributes & { children: ReactNode }
          ) => (
            <BitLinkWrapper {...props}>
              <BitLinkLinks />
            </BitLinkWrapper>
          )}
          title="Links - bitLink"
        />

        <PrivateRoute
          path="/bitlink/social"
          component={(
            props: JSX.IntrinsicAttributes & { children: ReactNode }
          ) => (
            <BitLinkWrapper {...props}>
              <BitLinkSocialMedia />
            </BitLinkWrapper>
          )}
          title="Social Media - bitLink"
        />

        <PrivateRoute
          path="/bitlink/subscription"
          component={(
            props: JSX.IntrinsicAttributes & { children: ReactNode }
          ) => (
            <BitLinkWrapper {...props}>
              <BitLinkSubscription />
            </BitLinkWrapper>
          )}
          title="Subscription - bitLink"
        />

        <PrivateRoute
          path="/bitlink/appearance"
          component={(
            props: JSX.IntrinsicAttributes & { children: ReactNode }
          ) => (
            <BitLinkWrapper {...props}>
              <BitLinkAppearance />
            </BitLinkWrapper>
          )}
          title="Appearance - bitLink"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/whatsapp-business/:id/log"
          component={() => <BitCRMCampaignLog />}
          title="Campaign Log - WhatsApp Business App - bitCRM"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/whatsapp-cloud/:id/log"
          component={() => <BitCRMCampaignLog />}
          title="Campaign Log - WhatsApp Cloud API - bitCRM"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/whatsapp-official/:id/log"
          component={() => <BitCRMCampaignLog />}
          title="Campaign Log - WhatsApp Official API - bitCRM"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/whatsapp-sandbox/:id"
          component={() => (
            <CampaignWhatsApp channel="whatsapp-business" isSandbox />
          )}
          title="WhatsApp Business App - Campaign - bitCRM"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/whatsapp-business/:id"
          component={() => <CampaignWhatsApp channel="whatsapp-business" />}
          title="WhatsApp Business App - Campaign - bitCRM"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/whatsapp-cloud/:id"
          component={() => <CampaignWhatsApp channel="whatsapp-cloud" />}
          title="WhatsApp Cloud API - Campaign - bitCRM"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign/notification/:id"
          component={() => <CampaignNotification />}
          title="Notification - Campaign"
        />
        <PrivateRoute
          path="/bitcrm/template/whatsapp-business/:id"
          component={() => <WhatsAppTemplate source="whatsapp-business" />}
          title="Template - WhatsApp Business App - bitCRM"
        />
        <PrivateRoute
          path="/bitcrm/template/whatsapp-cloud/:id"
          component={() => <WhatsAppTemplate source="whatsapp-cloud" />}
          title="Template - WhatsApp Cloud API - bitCRM"
        />
        <PrivateRoute
          path="/bitcrm/onboarding"
          component={() => <BitCRMOnboarding />}
          title="Onboarding - bitCRM"
        />
        <PrivateRoute
          path="/bitcrm/template"
          component={() => <BitCRMTemplate />}
          title="Template - bitCRM"
        />
        <PrivateRoute
          path="/bitchat/flows/:id"
          component={() => <Flow />}
          title="Flows - bitChat"
        />
        <PrivateRoute
          path="/bitchat/flows"
          component={() => <Flows />}
          title="Flows - bitChat"
        />
        <PrivateRoute
          exact
          path="/bitcrm/campaign"
          component={() => <Campaign />}
          title="Campaign - bitCRM"
        />
        {/* <PrivateRoute
          path="/bitcrm/automation/:id"
          component={() => <CreateNewAutomation />}
          title="Automation - bitCRM"
        /> */}
        <PrivateRoute
          path="/bitcrm/automation-log/:id"
          component={() => <AutomationLog />}
          title="Automation Log - bitCRM"
        />
        {/* <PrivateRoute
          path="/bitcrm/automation"
          component={() => <Scenario />}
          title="Automation - bitCRM"
        /> */}
        <PrivateRoute
          path="/bitcrm/automation-new/:id"
          component={() => <AutomationNewDetail />}
          title="Automation (New) - bitCRM"
        />
        <PrivateRoute
          path="/bitcrm/automation-new"
          component={() => <AutomationNew />}
          title="Automation (New) - bitCRM"
        />
        <PrivateRoute
          path="/bitchat/wa-widget"
          component={() => <WaWidget />}
          title="Chat widget - bitChat"
        />
        <PrivateRoute
          path="/bitchat/whatsapp-form/:id/responses"
          component={() => <Responses />}
          title="WhatsApp Form Responses - bitChat"
        />
        <PrivateRoute
          path="/bitchat/whatsapp-form/:id"
          component={() => <FormDetail />}
          title="WhatsApp Form - bitChat"
        />
        <PrivateRoute
          path="/bitchat/whatsapp-form"
          component={() => <WhatsAppForm />}
          title="WhatsApp Form - bitChat"
        />
        <PrivateRoute
          path="/bitai/onboarding"
          component={() => <BitAiOnboarding />}
          title="Onboarding - bitAi"
        />
        <PrivateRoute
          path="/bitai/sorting/:id"
          component={() => <ManageSorting />}
          title="Sorting - bitAI"
        />
        <PrivateRoute
          path="/bitai/sorting"
          component={() => <Sorting />}
          title="Sorting - bitAI"
        />
        <PrivateRoute
          path="/bitai/model/:id"
          component={() => <ManageModel />}
          title="Create Model - bitAI"
        />
        <PrivateRoute
          path="/bitai/model"
          component={() => <Model />}
          title="Model - bitAI"
        />
        <PrivateRoute
          path="/settings"
          exact
          component={Settings}
          title="Store - Settings"
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <PricingModalAlert />
      <ConfirmationModal />
      <ShopifyIntegrationModal />
    </Router>
  );
}

export default Routes;
