type Props = {
  isCampaign?: boolean;
};

export default function DynamicVariableLink({ isCampaign }: Props) {
  return (
    <a
      href={`https://bitbybit.tawk.help/article/dynamic-variable${
        isCampaign ? '-campaign' : ''
      }`}
      className="text-sm underline"
      target="_blank"
      rel="noreferrer"
    >
      View dynamic variable lists
    </a>
  );
}
