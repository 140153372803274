import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import services from '@/api/services';
import { AnalyticParams } from '@/api/services/bitChat/analytics';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { AnalyticsResponse } from '@/types/analytics';
import { AnalyticAgents } from '@/types/bitChat/analytics';

const useAnalyticAgents = (
  params: AnalyticParams,
  options?:
    | Omit<
        UseQueryOptions<
          AnalyticsResponse<AnalyticAgents[]>,
          unknown,
          AnalyticsResponse<AnalyticAgents[]>,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`bitchat-agent-analytics`, activeCompany, params],
    () =>
      services.bitChat.analytics.getAgents(activeCompany, {
        startDate: params.startDate,
        endDate: params.endDate,
        search: params.search,
      }),
    options
  );
};

export default useAnalyticAgents;
