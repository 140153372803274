import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useDeleteWACloudTemplate = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: string) =>
      services.bitChat.template.deleteWACloudTemplate(activeCompany, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitchat-settings']);
        queryClient.invalidateQueries(['bitchat-wa-cloud-templates']);
        toast.success('WA Cloud deleted successfully');
      },
    }
  );
};

export default useDeleteWACloudTemplate;
