import { Control, Controller, useWatch } from 'react-hook-form';
import {
  AppearanceForm,
  defaultAppearanceData,
} from 'pages/BitLink/Appearance';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components';
import {
  getThemeAppearanceFromStyle,
  mapThemeAppearance,
  presets,
} from '@/constants/bitLink/appearance/presets';
import useBranding from '@/hooks/bitLink/appearance/useBranding';
import useEditAppearance from '@/hooks/bitLink/appearance/useEditAppearance';
import useCreateOrUpdateOnboarding from '@/hooks/bitLink/onboarding/useCreateOrUpdateOnboarding';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';
import useResponsive from '@/hooks/common/useResponsive';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

type Props = {
  control: Control<AppearanceForm>;
};

function Presets({ control }: Props) {
  const { data, status } = useOnboarding('appearance');

  if (status === 'success' && data?.lastStep) {
    if (data?.lastStep === 3) {
      return <_Presets isNewUsers control={control} />;
    }
  }

  return <_Presets control={control} />;
}

function _Presets({
  isNewUsers = false,
  control,
}: {
  isNewUsers?: boolean;
  control: Control<AppearanceForm>;
}) {
  const isMobile = useResponsive('sm');

  const { mutate: editAppearance } = useEditAppearance();
  const { data: brandingData } = useBranding();
  const { mutate: updateOnboarding } = useCreateOrUpdateOnboarding();

  const presetScope = useWatch({
    control,
    name: 'presetScope',
  });

  const currentPreset = getThemeAppearanceFromStyle({
    bgColor: presetScope.bgColor ?? defaultAppearanceData.presetScope.bgColor,
    btnBgColor:
      presetScope.btnBgColor ?? defaultAppearanceData.presetScope.btnBgColor,
    btnStyle:
      presetScope.btnStyle ?? defaultAppearanceData.presetScope.btnStyle,
    btnShadowColor:
      presetScope.btnShadowColor ??
      defaultAppearanceData.presetScope.btnShadowColor,
    btnFontColor:
      presetScope.btnFontColor ??
      defaultAppearanceData.presetScope.btnFontColor,
    fontColorTitleBio:
      presetScope.fontColorTitleBio ??
      defaultAppearanceData.presetScope.fontColorTitleBio,
    bgType: presetScope.bgType ?? defaultAppearanceData.presetScope.bgType,
    bgImage: presetScope.bgImage ?? defaultAppearanceData.presetScope.bgImage,
    typography:
      presetScope.typography ?? defaultAppearanceData.presetScope.typography,
    bgColorBottom:
      presetScope.bgColorBottom ??
      defaultAppearanceData.presetScope.bgColorBottom,
  });

  const handleChangePreset = (preset: typeof presets[number]) => {
    if (isNewUsers) {
      editAppearance({
        appearance: {
          ...mapThemeAppearance[
            preset.value as Exclude<
              typeof presets[number]['value'],
              'theme-custom'
            >
          ],
          bgImage:
            mapThemeAppearance[
              preset.value as Exclude<
                typeof presets[number]['value'],
                'theme-custom'
              >
            ]?.bgImage?.remoteUrl,
        },
        branding: {
          ...brandingData,
        },
      });
      updateOnboarding({
        moduleKey: 'appearance',
        lastStep: 4,
      });
      return;
    }

    return mapThemeAppearance[
      preset.value as Exclude<typeof presets[number]['value'], 'theme-custom'>
    ];
  };

  return (
    <BBBCard
      id="presets-appearance"
      className={cx('mb-cardBottom', isNewUsers ? 'z-[150]' : 'z-50')}
      title={<p className="text-neutral-70 font-medium text-xl">Presets</p>}
    >
      <div
        className={cx('grid gap-5 ', isMobile ? 'grid-cols-2' : 'grid-cols-5')}
      >
        {presets.map((preset) => (
          <Controller
            control={control}
            name="presetScope"
            key={preset.value}
            render={({ field: { onChange } }) => {
              return (
                <div className="flex flex-col items-center">
                  <div
                    className={cx(
                      `cursor-pointer h-full w-full`,
                      currentPreset === preset.value
                        ? 'outline outline-1 outline-primary-main outline-offset-4 rounded-lg'
                        : ''
                    )}
                    onClick={() =>
                      preset.value !== 'theme-custom' && isNewUsers
                        ? handleChangePreset(preset)
                        : onChange(handleChangePreset(preset))
                    }
                  >
                    {preset.value === 'theme-custom' ? (
                      <div className="w-full h-full flex justify-center items-center text-lg font-bold uppercase text-primary-main text-center rounded-lg bg-custom-border-preset">
                        Custom <br /> your <br /> own
                      </div>
                    ) : (
                      <img
                        src={preset.image}
                        alt="preset-appearance"
                        className="object-cover rounded-lg"
                      />
                    )}
                  </div>
                  <div
                    className={cx(
                      'mt-2',
                      preset.value === 'theme-custom' &&
                        currentPreset !== 'theme-custom' &&
                        'opacity-50 pointer-events-none'
                    )}
                  >
                    {preset.label}
                  </div>
                </div>
              );
            }}
          />
        ))}
      </div>
    </BBBCard>
  );
}

export default Presets;
