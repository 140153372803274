import { Control, useWatch } from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema, QuickResponseForm } from '../..';

import BBBLogo from '@/assets/icons/BBBLogo';
import ChatIcon from '@/assets/icons/ChatIcon';
import { BBBTelInputValue } from '@/components';
import { useCompany } from '@/hooks/rtk/selector';
import { formatPhonePayload } from '@/utils/common/phone';

export type PreviewWidgetProps = {
  control: Control<FormSchema>;
};

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const PreviewWidget = ({ control }: PreviewWidgetProps) => {
  const data = useCompany();

  const profilePicture = useWatch({
    control,
    name: 'storeLogo',
  });

  const headerWidget = useWatch({
    control,
    name: 'storeName',
  });

  const subtitleText = useWatch({
    control,
    name: 'storeDescription',
  });

  const widgetColor = useWatch({
    control,
    name: 'themeColor',
  });

  const quickResponses = useWatch({
    control,
    name: 'quickResponses',
  });

  const shape = useWatch({
    control,
    name: 'widgetButton.radiusType',
  });

  const icon = useWatch({
    control,
    name: 'widgetButton.iconType',
  });

  const textButtonWidgetEnabled = useWatch({
    control,
    name: 'showWidgetText',
  });

  const textButtonWidget = useWatch({
    control,
    name: 'widgetText',
  });

  const whatsappContactPhone = useWatch({
    control,
    name: 'whatsappPhone',
  });

  const whatsappContactPrefilledMessage = useWatch({
    control,
    name: 'whatsappPrefilledText',
  });

  const whatsappContactButtonText = useWatch({
    control,
    name: 'whatsappButtonText',
  });

  const enabled = useWatch({
    control,
    name: 'showCard',
  });

  const hex = hexToRgb(widgetColor);

  return (
    <div className="flex justify-center">
      <div className="flex flex-col">
        {enabled && (
          <WidgetCard
            storeName={headerWidget || data?.name}
            widgetColor={widgetColor}
            profilePicture={profilePicture}
            subtitleText={subtitleText}
            whatsappContactPhone={whatsappContactPhone}
            whatsappContactPrefilledMessage={whatsappContactPrefilledMessage}
            whatsappContactButtonText={whatsappContactButtonText}
            quickResponses={quickResponses}
            hex={hex}
          />
        )}
        <ButtonWidget
          shape={shape}
          textButtonWidget={textButtonWidget}
          textButtonWidgetEnabled={textButtonWidgetEnabled}
          icon={icon}
          widgetColor={widgetColor}
        />
      </div>
    </div>
  );
};

function WidgetCard({
  widgetColor,
  profilePicture,
  storeName,
  subtitleText,
  whatsappContactButtonText,
  whatsappContactPhone,
  whatsappContactPrefilledMessage,
  quickResponses,
  hex,
}: {
  widgetColor: string;
  profilePicture: string | null;
  storeName: string | undefined;
  subtitleText: string;
  whatsappContactPhone: BBBTelInputValue | null;
  whatsappContactPrefilledMessage: string;
  whatsappContactButtonText: string;
  quickResponses: QuickResponseForm[];
  hex: {
    r: number;
    g: number;
    b: number;
  } | null;
}) {
  return (
    <div
      className="mb-5 rounded-xl w-96 self-end"
      style={{
        boxShadow: '0px 3px 6px 0px #9B9B9B26',
      }}
    >
      <div
        className="h-28 w-full rounded-tl-xl rounded-tr-xl p-4"
        style={{
          backgroundColor: widgetColor,
        }}
      >
        <div className="flex items-center gap-2 mb-3">
          {profilePicture ? (
            <img src={profilePicture} className="w-12 h-12 rounded-full" />
          ) : (
            <div className="bg-neutral-20 w-12 h-12 rounded-full" />
          )}
          <div>
            <div className="text-white font-medium text-xl">{storeName}</div>
            <div className="text-white">{subtitleText}</div>
          </div>
        </div>
        <div className="relative w-full ">
          <div
            className="absolute z-30 left-0 right-0 rounded-lg overflow-hidden"
            style={{
              boxShadow: '0px 3px 6px 0px #9B9B9B26',
            }}
          >
            <div
              className="h-1.5 w-full"
              style={{
                backgroundColor: hex
                  ? `rgba(${hex.r}, ${hex.g}, ${hex.b}, 0.15)`
                  : undefined,
                boxShadow: '0px 3px 6px 0px #9B9B9B26',
              }}
            />
            <div className="bg-white rounded-lg rounded-tl-none rounded-tr-none px-3 py-4">
              <div className="font-semibold mb-3">Contact us</div>
              {whatsappContactPhone && whatsappContactPrefilledMessage ? (
                <a
                  href={`https://wa.me/${formatPhonePayload(
                    whatsappContactPhone
                  )}?text=${whatsappContactPrefilledMessage}`}
                  className="px-3 hover:border-primary-main transition-colors cursor-pointer py-2.5 border border-neutral-30 flex items-center gap-2.5 rounded"
                  rel="noreferrer"
                  target="_blank"
                >
                  <WhatsAppIcon />
                  <div className="text-sm">{whatsappContactButtonText}</div>
                </a>
              ) : (
                <div className="px-3 hover:border-primary-main transition-colors cursor-pointer py-2.5 border border-neutral-30 flex items-center gap-2.5 rounded">
                  <WhatsAppIcon />
                  <div className="text-sm">{whatsappContactButtonText}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-4 pt-24 pb-6">
        {!!quickResponses.filter((quickResponse) => quickResponse.buttonText)
          .length && (
          <>
            <div className="font-semibold mb-3">Quick responses</div>
            {quickResponses.map((quickResponse) =>
              whatsappContactPhone && quickResponse.chatbot ? (
                <a
                  className="px-3 py-2.5 mb-2 hover:border-primary-main transition-colors border border-neutral-30 flex items-center gap-2.5 rounded"
                  key={quickResponse.buttonText}
                  rel="noreferrer"
                  target="_blank"
                  href={`https://wa.me/${formatPhonePayload(
                    whatsappContactPhone
                  )}?text=${whatsappContactPrefilledMessage}`}
                >
                  <div className="text-sm grow">{quickResponse.buttonText}</div>
                  <SendIcon />
                </a>
              ) : (
                <div
                  className="px-3 py-2.5 mb-2 hover:border-primary-main transition-colors border border-neutral-30 flex items-center gap-2.5 rounded"
                  key={quickResponse.buttonText}
                >
                  <div className="text-sm grow">{quickResponse.buttonText}</div>
                  <SendIcon />
                </div>
              )
            )}
          </>
        )}
      </div>
      <div className="py-2 rounded-bl-xl rounded-br-xl bg-neutral-20 flex justify-center items-center gap-1">
        <div className="text-[0.625rem]">Powered by</div>
        <BBBLogo className="h-7" />
      </div>
    </div>
  );
}

function ButtonWidget({
  shape,
  textButtonWidget,
  textButtonWidgetEnabled,
  icon,
  widgetColor,
}: {
  shape: string | undefined;
  textButtonWidgetEnabled: boolean;
  textButtonWidget: string;
  widgetColor: string;
  icon: string | undefined;
}) {
  return (
    <div className="flex justify-end">
      <div
        className={cx(
          'flex justify-center items-center rounded-lg',
          shape === 'circle' && 'rounded-full',
          textButtonWidgetEnabled && textButtonWidget ? 'p-4.5' : 'w-16 h-16'
        )}
        style={{
          backgroundColor: widgetColor,
        }}
      >
        <div className="flex-none">
          {icon === 'whatsapp' ? (
            <WhatsAppIcon color="white" size={26} />
          ) : (
            <ChatIcon />
          )}
        </div>

        {textButtonWidgetEnabled && textButtonWidget && (
          <div className="text-xl text-white ml-2">{textButtonWidget}</div>
        )}
      </div>
    </div>
  );
}

function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
    >
      <path
        stroke="#0A0A0A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M8.26 9H3.334L1.686 2.446a.552.552 0 01-.02-.117c-.017-.6.644-1.018 1.218-.743L18.334 9l-15.45 7.413c-.567.273-1.22-.132-1.217-.722a.55.55 0 01.027-.155L2.917 11.5"
      ></path>
    </svg>
  );
}

function WhatsAppIcon({
  color = '#262627',
  size = 20,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.512 11.985c-.248-.124-1.465-.723-1.692-.806-.227-.082-.392-.123-.558.125-.165.247-.639.805-.783.97-.145.166-.289.186-.536.063-.248-.125-1.046-.386-1.992-1.23-.736-.656-1.234-1.467-1.378-1.715-.144-.248-.016-.382.109-.505.111-.111.247-.29.371-.434.124-.145.165-.248.248-.414.083-.165.041-.309-.021-.433s-.557-1.343-.763-1.84c-.201-.482-.405-.416-.557-.424-.145-.007-.31-.009-.475-.009a.907.907 0 00-.66.31c-.228.248-.867.847-.867 2.066 0 1.219.887 2.396 1.011 2.562.124.165 1.746 2.666 4.23 3.739a14.17 14.17 0 001.412.52c.593.19 1.133.163 1.56.1.475-.072 1.465-.6 1.671-1.178.206-.579.206-1.075.145-1.178-.062-.103-.227-.165-.475-.289zm-4.518 6.17h-.003a8.225 8.225 0 01-4.193-1.15l-.3-.177-3.119.818.833-3.04-.196-.312a8.217 8.217 0 01-1.26-4.383c.002-4.542 3.697-8.237 8.242-8.237 2.2 0 4.268.859 5.823 2.415a8.188 8.188 0 012.41 5.828c-.002 4.541-3.697 8.237-8.237 8.237zm7.01-15.248A9.847 9.847 0 009.994 0C4.531 0 .084 4.446.083 9.91a9.887 9.887 0 001.322 4.954L0 20l5.253-1.378a9.901 9.901 0 004.737 1.206h.004c5.462 0 9.909-4.445 9.91-9.91a9.85 9.85 0 00-2.9-7.011z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PreviewWidget;
