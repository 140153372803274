import { useMemo } from 'react';
import { AlertCircle, Trash2 } from 'react-feather';

import { ModelCondition, ModelRules } from '@/api/services/bitAi/v2-new/model';
import {
  BBBSelect,
  BBBTextInput,
  SingleOnValueChangeParam,
} from '@/components';
import { HeadersTypes } from '@/components/BBBTableV2/BBBTableV2.type';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

const useColumnManage = ({
  onUpdate,
  onRemove,
}: {
  onUpdate: (
    rowId: string,
    columnId: string,
    value:
      | number
      | string
      | SingleOnValueChangeParam<{ value: string; label: string }>
  ) => void;
  onRemove: (rowId: string) => void;
}) => {
  const toggleConfirmation = useConfirmationModal();

  return useMemo<
    HeadersTypes<Pick<ModelRules, 'id' | 'qty' | 'index' | 'condition'>>
  >(
    () => [
      {
        accesor: 'qty',
        renderHeader: () => <div className="ml-6">Product Qty</div>,
        render: (row) => (
          <div className="flex items-center gap-4">
            <BBBTextInput
              value={row.qty}
              onChange={(e) => {
                onUpdate(row.id, 'qty', e.target.value);
              }}
              placeholder="Please input with minimum 1 size"
              inputClassName={`${!row.qty && 'border-danger-main'}`}
              containerClassname="mb-0"
            />
            {!row.qty && <AlertCircle fill="#FF6060" color="#FFFF" />}
          </div>
        ),
      },
      {
        accesor: 'condition',
        renderHeader: () => 'Product Ordering',
        render: (row) => (
          <ModelRuleOptions
            value={row.condition}
            onValueChange={(value) => {
              onUpdate(row.id, 'condition', value);
            }}
          />
        ),
      },
      {
        isAdditionalColumn: true,
        renderHeader: () => 'Action',
        render: (row) => (
          <div className="flex py-4">
            <Trash2
              className="text-danger-main cursor-pointer hover:text-red-400"
              onClick={() => {
                toggleConfirmation({
                  title: 'Delete section',
                  description:
                    'Are you sure to delete section? Once you deleted the action cannot be undone',
                  onAccept: async (hide) => {
                    onRemove(row.id);
                    hide();
                  },
                  deleteModal: true,
                  submitText: 'Delete',
                  cancelText: 'Cancel',
                });
              }}
            />
          </div>
        ),
      },
    ],
    [onRemove, onUpdate, toggleConfirmation]
  );
};

export default useColumnManage;

const modelRuleOptions: (
  | {
      label: string;
      value: ModelCondition;
      parentValue: 'basic' | 'advance';
    }
  | {
      label: string;
      value: string;
    }
)[] = [
  { label: 'Basic', value: 'basic' },
  {
    label: 'Top seller',
    value: 'TOP_SELLER',
    parentValue: 'basic',
  },
  {
    label: 'Top revenue',
    value: 'TOP_REVENUE',
    parentValue: 'basic',
  },
  {
    label: 'Highest discount',
    value: 'HIGHEST_DISCOUNT',
    parentValue: 'basic',
  },
  {
    label: 'Highest discount percentage',
    value: 'HIGHEST_DISCOUNT_PERCENTAGE',
    parentValue: 'basic',
  },
  {
    label: 'Highest stock',
    value: 'HIGHEST_STOCK',
    parentValue: 'basic',
  },
  {
    label: 'New product',
    value: 'NEWEST_PRODUCT',
    parentValue: 'basic',
  },
  { label: 'Advance', value: 'advance' },
  {
    label: 'Highest Margin (%)',
    value: 'HIGHEST_MARGIN_PERCENTAGE',
    parentValue: 'advance',
  },
  {
    label: 'Highest Margin ($)',
    value: 'HIGHEST_MARGIN',
    parentValue: 'advance',
  },
];

const modelRuleHash = Object.fromEntries(
  modelRuleOptions.map((ruleOpt) => [ruleOpt.value, ruleOpt])
);

function ModelRuleOptions({
  value: _value,
  onValueChange,
}: {
  value: ModelCondition;
  onValueChange: (val: ModelCondition) => void;
}) {
  const { data: pricingData } = usePricingByApp('BITAI');

  const pricingName = pricingData?.pricingName || 'free';

  const options =
    pricingName === 'free'
      ? modelRuleOptions.map((rule) => ({
          ...rule,
          //@ts-ignore
          disabled: rule.parentValue === 'advance' || rule.value === 'advance',
        }))
      : modelRuleOptions;

  const value = modelRuleHash[_value];

  const confirm = useConfirmationModal();

  return (
    <BBBSelect
      options={options}
      optionLabel="label"
      optionValue="value"
      optionGroupKey="parentValue"
      isGrouped
      value={value}
      onValueChange={(opt) => {
        //@ts-ignore
        if (opt!.value === 'advance' || opt!.parentValue === 'advance') {
          confirm({
            title: 'Proceed to bitAI Pricing',
            description:
              "Are you sure want to visit the bitAI Pricing page? You'll see our latest plans and pricing details.",
            submitText: 'Yes',
            submitLink: '/pricing?tab=bitai',
            onAccept: (hide) => hide(),
          });
        } else {
          onValueChange(opt!.value as ModelCondition);
        }
      }}
      //@ts-ignore
      optionDisabled="disabled"
      bypassDisableClickHandler
    />
  );
}
