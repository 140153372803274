import ai from './ai';
import bitAi from './bitAi';
import bitApp from './bitApp';
import bitChat from './bitChat';
import bitCRM from './bitCRM';
import bitLink from './bitLink';
import bitLogin from './bitLogin';
import customers from './customers';
import facebook from './facebook';
import instagram from './instagram';
import integrations from './integrations';
import pixel from './pixel';
import pricing from './pricing';
import systemLogin from './systemLogin';
import whatsApp from './whatsApp';

export default {
  ai,
  systemLogin,
  bitLink,
  bitApp,
  bitChat,
  integrations,
  bitLogin,
  whatsApp,
  bitCRM,
  bitAi,
  customers,
  pixel,
  facebook,
  instagram,
  pricing,
};
