import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mapSourceToLabel } from '../CustomerDetail';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import MergeStep1 from './MergeStep1';
import MergeStep2 from './MergeStep2';

import FlagIcon from '@/assets/icons/FlagIcon';
import UnsubscribeIcon from '@/assets/icons/UnsubscribeIcon';
import { BBBButton, BBBCard, BBBTag, BBBTooltip } from '@/components';
import InfoList from '@/components/InfoList/InfoList';
import useHistory from '@/hooks/common/useHistory';
import useResponsive from '@/hooks/common/useResponsive';
import useDeleteCustomer from '@/hooks/customers/customer/useDeleteCustomer';
import { CustomerWithAssociation } from '@/types/customers/association';
import { formatDate2 } from '@/utils/common/date';
import { formatDisplayName } from '@/utils/customers';

export type GeneralInfoCardProps = CustomerWithAssociation;
export type MergeStep1Type = {
  step: 1;
  data?: CustomerWithAssociation | undefined;
};
export type MergeStep2Type = {
  step: 2;
  source: CustomerWithAssociation;
  target: CustomerWithAssociation;
};
export type MergeType = MergeStep1Type | MergeStep2Type;

function GeneralInfoCard(props: GeneralInfoCardProps) {
  const {
    firstName,
    lastName,
    email,
    imageUrl,
    phoneNumber,
    sources,
    updatedAt,
    subscriptionStatus,
    shortId,
  } = props;

  const [merge, setMerge] = useState<MergeType>();

  const isTablet = useResponsive('lg');
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const deleteCustomer = useDeleteCustomer();

  const isUnsubscribed = subscriptionStatus?.find(
    (item) => item.app === 'BITCRM' && item.status === 'unsubscribe'
  );

  return (
    <>
      {merge?.step === 2 && <MergeStep2 merge={merge} setMerge={setMerge} />}
      {merge?.step === 1 && <MergeStep1 merge={merge} setMerge={setMerge} />}
      <BBBCard className="mb-5">
        <div className="flex gap-4">
          <ProfilePicture
            firstName={firstName}
            email={email}
            lastName={lastName}
            imageUrl={imageUrl}
          />
          <div className="grow">
            <div className="flex flex-col lg:flex-row mb-2.5 lg:items-center gap-2.5">
              <div className="flex gap-2.5 grow items-center">
                <div className="text-2xl font-medium">
                  {formatDisplayName(phoneNumber, firstName, lastName)}
                </div>
                <div className="grow">
                  <div className="flex gap-1 items-center">
                    {isUnsubscribed ? (
                      <BBBTooltip
                        show
                        content={`Unsubscribed on ${formatDate2(
                          isUnsubscribed.updatedAt
                        )}`}
                      >
                        <UnsubscribeIcon />
                      </BBBTooltip>
                    ) : (
                      <FlagIcon />
                    )}
                  </div>
                </div>
              </div>
              <div>
                {sources?.map((source) => (
                  <div
                    className="inline-block mr-2 bg-neutral-20 px-2 py-1 rounded text-sm"
                    key={source}
                  >
                    {mapSourceToLabel[source as keyof typeof mapSourceToLabel]}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex lg:flex-row flex-col">
              <div className="grow">
                <InfoList
                  containerClassName="mb-2"
                  label="Last updated"
                  labelClassName="xl:w-auto"
                >
                  <div className="line-clamp-1">
                    {updatedAt ? formatDate2(updatedAt) : '-'}
                  </div>
                </InfoList>
                <InfoList
                  containerClassName="mb-0"
                  label="Customer ID"
                  labelClassName="xl:w-auto"
                >
                  <div className="line-clamp-1">{shortId || id}</div>
                </InfoList>
              </div>
              {!isTablet && (
                <div className="flex lg:self-end">
                  <BBBButton
                    variant="secondary"
                    text="Merge customer data"
                    className="mr-2"
                    onClick={() => setMerge({ step: 1, data: props })}
                  />
                  <BBBButton
                    variant="danger-outline"
                    text="Delete customer"
                    onClick={() =>
                      deleteCustomer(true)(id, {
                        onSuccess: () => {
                          history.push(`/customers`);
                        },
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {isTablet && (
          <div className="flex justify-between mt-3.5">
            <BBBButton
              variant="secondary"
              text="Merge customer data"
              className="mr-2"
              onClick={() => setMerge({ step: 1, data: props })}
              size="sm"
            />
            <BBBButton
              size="sm"
              variant="danger-outline"
              text="Delete customer"
              onClick={() =>
                deleteCustomer(true)(id, {
                  onSuccess: () => {
                    history.push(`/customers`);
                  },
                })
              }
            />
          </div>
        )}
      </BBBCard>
    </>
  );
}

export default GeneralInfoCard;
