import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import TicketCategory from './TicketCategory/TicketCategory';
import AutoResolveTicket from './AutoResolveTicket';

import { Settings } from '@/api/services/whatsApp/settings';
import { autoResolveOptions } from '@/constants/bitChat/settings';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import { SettingsTag } from '@/types/whatsApp/settings';

const livechatSchema = yup.object().shape({
  autoResolveTime: yup
    .string()
    .label('Time')
    .when('autoResolveTicket', {
      is: (value: boolean) => !!value,
      then: (rule) => rule.required(),
    }),
  autoResolveType: yup
    .mixed<typeof autoResolveOptions[number]>()
    .label('Type')
    .when('autoResolveTicket', {
      is: (value: boolean) => !!value,
      then: (rule) => rule.required(),
    }),
});

export type LiveChatSettings = Pick<Settings, 'autoResolveTicket'> & {
  autoResolveType: typeof autoResolveOptions[number] | null;
  autoResolveTime: string;
  ticketTags: Pick<SettingsTag, 'id' | 'label' | 'color'>[];
};

const preBuiltTicketTags: LiveChatSettings['ticketTags'] = [
  { label: 'Regular ticket', color: 'neutral', id: createId() },
  { label: 'Complaint', color: 'danger', id: createId() },
  { label: 'Potential customer', color: 'success', id: createId() },
];

export default function LivechatSettings() {
  const { data } = useSettings();
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const { control, reset, watch, handleSubmit } = useForm<LiveChatSettings>({
    resolver: yupResolver(livechatSchema),
  });

  const dataFromApi = useMemo<Partial<LiveChatSettings>>(
    () => ({
      autoResolveTicket: data?.autoResolveTicket || false,
      autoResolveType: data?.autoResolveType
        ? autoResolveOptions.find((opt) => opt.label === data.autoResolveType)
        : null,
      autoResolveTime: data?.autoResolveTime
        ? data.autoResolveTime.toString()
        : '',
      ticketTags: data?.ticketTags?.length
        ? data.ticketTags
        : preBuiltTicketTags,
    }),
    [
      data?.autoResolveTicket,
      data?.autoResolveTime,
      data?.autoResolveType,
      data?.ticketTags,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const onSubmit = (data: Partial<LiveChatSettings>) => {
      updateSettings({
        ...data,
        autoResolveType: data.autoResolveType?.label,
        autoResolveTime: Number(data.autoResolveTime),
      });
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('save-livechat', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant: loadingUpdateSettings ? 'loading' : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSettings,
    reset,
    toggle,
    watch(),
    updateSettings,
  ]);

  return (
    <>
      <AutoResolveTicket control={control} />
      <TicketCategory control={control} />
    </>
  );
}
