import { useRef } from 'react';
import { NodeProps } from 'reactflow';
import useStore from '../../store';
import { TriggerNodeData } from '../../types';
import AutoPilotInfo from '../AutoPilotInfo';

import { BBBCard, BBBRenderHtml, BBBTag } from '@/components';

export default function TriggerNode({ data, id }: NodeProps<TriggerNodeData>) {
  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );
  const childLength = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === id).length
  );
  const cardRef = useRef<HTMLDivElement | null>(null);
  const conditionsSlotRef = useRef<HTMLDivElement | null>(null);

  return (
    <BBBCard
      title="Trigger"
      className="w-96"
      ref={cardRef}
      id={`trigger-${id}`}
      onClick={() => {
        onChangeTriggerModalState({
          type: data.action === 'ai_auto_pilot' ? 'ai' : 'message',
          id,
        });
      }}
    >
      {data.action === 'ai_auto_pilot' && (
        <>
          <AutoPilotInfo className="mb-4" />
        </>
      )}
      {(data.action === 'message_exact' ||
        data.action === 'message_contain') && (
        <>
          <div className="text-neutral-50 mb-4">
            If message{' '}
            <span className="font-bold text-neutral-60">
              {data.action === 'message_exact' ? 'is exact' : 'is contain'}:
            </span>
          </div>
          {!!data.keywords?.length && (
            <div className="flex flex-wrap gap-1">
              {data.keywords?.map((keyword) => (
                <BBBTag text={keyword} key={keyword} />
              ))}
            </div>
          )}
          <div className="bg-neutral-20 border-neutral-30 text-neutral-50 rounded-lg border p-2 mt-4 mb-2">
            <BBBRenderHtml
              className="line-clamp-5 break-words"
              content={data.message}
            />
          </div>
          {data.fallback?.name && (
            <div className="mt-4 text-neutral-50">
              Fallback: {data.fallback?.name}
            </div>
          )}
        </>
      )}
      {!!childLength && (
        <div
          style={{ height: childLength * 64 }}
          className="relative mt-4"
          ref={conditionsSlotRef}
          id={`child1-slot-${id}`}
        />
      )}
    </BBBCard>
  );
}
