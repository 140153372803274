import { useEffect, useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import CheckIcon2 from '@/assets/icons/CheckIcon2';
import { BBBOnboardingModal } from '@/components';
import BBBAccordion from '@/components/BBBAccordion';
import { BBBButton } from '@/components/BBBButton';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { env } from '@/config/env';
import useSettings from '@/hooks/bitLogin/useSettings';
import useUpsertSettings from '@/hooks/bitLogin/useUpsertSettings';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { toast } from '@/utils/common/toast';

type Props = {
  className?: string;
};

export default function InitialSteps(props: Props) {
  const { status, fetchStatus } = useShopifyIntegrationByApp('BITLOGIN');

  if (status === 'loading' && fetchStatus === 'idle')
    return <__InitialSteps {...props} />;

  return <_InitialSteps {...props} />;
}

function _InitialSteps(props: Props) {
  const { status: statusEnabled, data } = useSettings();

  if (statusEnabled !== 'success') return null;

  if (data?.verified) return null;

  return <__InitialSteps {...props} />;
}

function __InitialSteps({ className }: Props) {
  const { data: shopifyData, isInitialLoading: loadingIntegration } =
    useShopifyIntegrationByApp('BITLOGIN');

  const { data: settingsData, status: statusSettings } = useSettings();

  const { mutate: updateSettings, isLoading: loadingUpsertSettings } =
    useUpsertSettings();

  const adminUrl = `https://${shopifyData?.domain}/admin/settings/customer_accounts`;
  const themeUrl = `https://${shopifyData?.domain}/admin/themes/current/editor?context=apps&activateAppId=${env.REACT_APP_BITLOGIN_THEME_EXTENSION_UUID}/app-block`;
  const storeUrl = `https://${shopifyData?.domain}/account/login`;

  const [collapsed, setCollapsed] = useState<
    | 'enable-social-login'
    | 'enable-bitlogin-on-shopify-theme'
    | 'check-social-login-on-your-site'
    | undefined
  >('enable-social-login');

  const [connectState, setConnectState] = useState<typeof collapsed>();
  const [showEnableShopify, setShowEnableShopify] = useState(false);
  const [_completeVerified, _setCompleteVerified] = useState(false);

  const completedSocialSettings = !!settingsData?.enabledShopifySettings;

  const completedShopifyTheme = !!settingsData?.enabled;

  const completeVerified = _completeVerified;

  useEffect(() => {
    if (connectState && shopifyData) {
      if (connectState === 'enable-social-login') {
        updateSettings(
          {
            visitedShopifySettings: true,
            enabledShopifySettings: true,
          },
          {
            onSuccess: () => {
              window.open(adminUrl, '_blank');
              setConnectState(undefined);
            },
          }
        );
      } else if (connectState === 'enable-bitlogin-on-shopify-theme') {
        updateSettings(
          {
            visitedShopifyTheme: true,
            enabled: true,
          },
          {
            onSuccess: () => {
              window.open(themeUrl, '_blank');
              setConnectState(undefined);
            },
          }
        );
      } else {
        window.open(storeUrl, '_blank');
        setConnectState(undefined);
      }
    }
  }, [adminUrl, connectState, shopifyData, storeUrl, themeUrl, updateSettings]);

  useEffect(() => {
    if (completedSocialSettings) {
      setCollapsed('enable-bitlogin-on-shopify-theme');
    }

    if (completedShopifyTheme) {
      setCollapsed('check-social-login-on-your-site');
    }

    if (completeVerified) {
      setCollapsed('check-social-login-on-your-site');
    }
  }, [completeVerified, completedShopifyTheme, completedSocialSettings]);

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <BBBAccordion className={className}>
            <BBBAccordion.Item
              id="enable-social-login"
              title="1. Enable ‘Classic customer accounts’ in Shopify"
              collapse={collapsed === 'enable-social-login'}
              onCollapse={(_collapsed) => {
                setCollapsed(_collapsed ? 'enable-social-login' : undefined);
              }}
              subtitle="Change your Shopify store login settings. Finish this step to continue to the other steps."
              leftChildren={
                <CheckedItems
                  step={1}
                  completed={completedSocialSettings}
                  active={collapsed === 'enable-social-login'}
                />
              }
              content={
                <div className="w-full my-6 flex flex-col gap-6 justify-end items-end">
                  <FirstContent />
                  <ShopifyAwareWrapper
                    className="w-full"
                    appType="BITLOGIN"
                    fallbackToChildren
                  >
                    {({ connectMiddleware }) => (
                      <div className="flex items-center gap-4 w-full justify-end">
                        <BBBButton
                          size="lg"
                          text="Go to Shopify admin"
                          loadingState={loadingIntegration}
                          onClick={(e) => {
                            connectMiddleware(() => {
                              setConnectState('enable-social-login');
                            });
                            e.stopPropagation();
                          }}
                        />
                      </div>
                    )}
                  </ShopifyAwareWrapper>
                </div>
              }
            />
            <BBBAccordion.Item
              id="enable-bitlogin-on-shopify-theme"
              title="2. Activate bitLogin on your Shopify theme's app embeds"
              collapse={collapsed === 'enable-bitlogin-on-shopify-theme'}
              subtitle="Turn on bitLogin in your Shopify theme’s app embeds. Finish this step to continue to last step."
              leftChildren={
                <CheckedItems
                  step={2}
                  active={collapsed === 'enable-bitlogin-on-shopify-theme'}
                  completed={completedShopifyTheme}
                />
              }
              onCollapse={(_collapsed) => {
                setCollapsed(
                  _collapsed ? 'enable-bitlogin-on-shopify-theme' : undefined
                );
              }}
              content={
                <div className="w-full my-6 flex flex-col gap-6 justify-center items-center">
                  <img
                    src="/gif/social_enablebitLogin.gif"
                    alt="initial-steps-1"
                    className="w-full h-auto object-cover"
                  />

                  <div
                    className={cx(
                      'flex items-center gap-4  w-full justify-end'
                    )}
                  >
                    <ShopifyAwareWrapper
                      className="w-full"
                      appType="BITLOGIN"
                      fallbackToChildren
                    >
                      {({ connectMiddleware }) => (
                        <div className="flex items-center gap-4 w-full justify-end">
                          <BBBButton
                            size="lg"
                            text="Go to Shopify theme"
                            loadingState={loadingIntegration}
                            onClick={(e) => {
                              connectMiddleware(() => {
                                setConnectState(
                                  'enable-bitlogin-on-shopify-theme'
                                );
                              });
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      )}
                    </ShopifyAwareWrapper>
                  </div>
                </div>
              }
            />
            <BBBAccordion.Item
              id="check-social-login-on-your-site"
              title="3. Verify bitLogin integration in your store"
              subtitle="Confirm that the social login button is displayed on your store. Make sure you’ve done 2 steps above."
              collapse={collapsed === 'check-social-login-on-your-site'}
              onCollapse={(_collapsed) => {
                setCollapsed(
                  _collapsed ? 'check-social-login-on-your-site' : undefined
                );
              }}
              leftChildren={
                <CheckedItems
                  step={3}
                  active={collapsed === 'check-social-login-on-your-site'}
                  completed={completeVerified}
                />
              }
              content={
                <div className="w-full my-6 flex flex-col gap-6 justify-center items-start">
                  <ul className="flex flex-col gap-2 pl-16 list-decimal">
                    <li className="text-ls text-neutral-60">
                      Enable ‘Classic customer accounts’ in Shopify
                    </li>
                    <li className="text-ls text-neutral-60">
                      Activate bitLogin on your Shopify theme&apos;s app embeds
                    </li>
                  </ul>
                  <div className="flex w-full justify-end items-center">
                    <ShopifyAwareWrapper
                      className="w-full"
                      appType="BITLOGIN"
                      fallbackToChildren
                    >
                      {({ connectMiddleware }) => (
                        <div
                          className={cx('flex items-center justify-end gap-4')}
                        >
                          <p
                            className="underline text-neutral-60 cursor-pointer"
                            onClick={(e) => {
                              connectMiddleware(() => {
                                _setCompleteVerified(true);
                                setTimeout(() => {
                                  updateSettings(
                                    {
                                      verified: true,
                                    },
                                    {
                                      onSuccess: () => {
                                        toast.success(
                                          'Congratulations! You have setup your social login button.'
                                        );
                                      },
                                    }
                                  );
                                }, 1000);
                              });
                              e.stopPropagation();
                            }}
                          >
                            I can see bitLogin on my site
                          </p>
                          <BBBButton
                            size="lg"
                            text="Preview on my store site"
                            loadingState={loadingIntegration}
                            onClick={(e) => {
                              connectMiddleware(() =>
                                setConnectState(
                                  'check-social-login-on-your-site'
                                )
                              );
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      )}
                    </ShopifyAwareWrapper>
                  </div>
                </div>
              }
            />
          </BBBAccordion>
        </motion.div>
      </AnimatePresence>
      {showEnableShopify && (
        <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
          {({ connectMiddleware }) => (
            <BBBOnboardingModal
              show={showEnableShopify}
              onCancel={() => setShowEnableShopify(false)}
              steps={[
                {
                  id: createId(),
                  title: 'Make sure you enable and save it!',
                  desc: 'To add social login to your site, you have to enable bitLogin on Shopify theme editor and save it. You can check your login page after you’ve done that step',
                  gif: '/gif/social_enablebitLogin.gif',
                },
              ]}
              loadingSave={loadingUpsertSettings}
              buttonText="Go to Shopify theme editor"
              handleButton={() =>
                connectMiddleware(() => {
                  if (statusSettings === 'success') {
                    if (!settingsData?.enabled) {
                      updateSettings(
                        {
                          enabled: true,
                        },
                        {
                          onSuccess: () => {
                            toast.success('Button enabled.');
                          },
                        }
                      );
                    } else {
                      setShowEnableShopify(false);
                    }
                  }
                  window.open(themeUrl, '_blank');
                })
              }
              onHide={() => setShowEnableShopify(false)}
            />
          )}
        </ShopifyAwareWrapper>
      )}
    </>
  );
}

function CheckedItems({
  completed,
  step,
  active,
}: {
  completed: boolean;
  step: number;
  active: boolean;
}) {
  return (
    <div
      className={cx(
        'rounded-full w-6 h-6 flex items-center justify-center bg-primary-main opacity-20',
        completed && 'bg-success-main opacity-100',
        active && 'opacity-100'
      )}
    >
      {completed ? (
        <CheckIcon2 color="#FFF" />
      ) : (
        <p className="text-neutral-10 font-bold text-lg">{step}</p>
      )}
    </div>
  );
}

function FirstContent() {
  const contentData = [
    {
      preview: '/bitLogin/initialSteps/first-steps-frame.png',
      desciption: (
        <p className="text-xs text-primary-main text-center">
          In your shopify store, go to{' '}
          <span className="text-info-main font-bold">Settings </span>&gt;{' '}
          <span className="text-info-main font-bold">Customer accounts</span>,
          or search &quot;Customer accounts&quot; in the search bar
        </p>
      ),
    },
    {
      preview: '/bitLogin/initialSteps/first-steps-frame(2).png',
      desciption: (
        <p className="text-xs text-primary-main text-center">
          Click <span className="text-info-main font-bold">Edit </span>In the
          &apos;Accounts in online store and checkout&apos;
        </p>
      ),
    },
    {
      preview: '/bitLogin/initialSteps/first-steps-frame(3).png',
      desciption: (
        <p className="text-xs text-primary-main text-center">
          Check the{' '}
          <span className="text-info-main font-bold">
            &apos;Show login link...{' '}
          </span>{' '}
          check box, and choose{' '}
          <span className="text-info-main font-bold">
            &apos;Classic customer accounts&apos;
          </span>
        </p>
      ),
    },
  ];

  return (
    <div className="w-full grid grid-cols-3 gap-10 items-center justity-center">
      {contentData.map((item, idx) => (
        <div key={idx} className="flex flex-col items-center gap-2">
          <div className="w-full h-[165px]">
            <img
              src={item.preview}
              className="w-full h-full object-contain"
              alt="preview"
            />
          </div>
          {item.desciption}
        </div>
      ))}
    </div>
  );
}
